import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { MapEditorApiActions } from '@spog-ui/map-editor/actions';
import * as CoreState from '.';

export function mapPositioningMetaReducer(reducer: any) {
  return (state: any | undefined, action: Action) => {
    switch (action.type) {
      case MapEditorApiActions.savePositionsSuccess.type: {
        const typedAction: ReturnType<typeof MapEditorApiActions.savePositionsSuccess> =
          action as ReturnType<typeof MapEditorApiActions.savePositionsSuccess>;

        const updates = typedAction.result.positions.map(
          (update): Update<any> => ({
            id: update.id,
            changes: { floorPlanX: update.floorPlanX, floorPlanY: update.floorPlanY },
          }),
        );

        switch (typedAction.result.thingType) {
          case 'light': {
            const lights = CoreState.fromLights.adapter.updateMany(updates, state.lights);

            const updatedState = Object.assign({}, state, {
              lights,
            });

            return reducer(updatedState, action);
          }

          case 'industrial-sensor': {
            const indieSensors = CoreState.fromIndieSensors.adapter.updateMany(
              updates,
              state.indieSensors,
            );

            const updatedState = Object.assign({}, state, { indieSensors });

            return reducer(updatedState, action);
          }

          case 'thermostat': {
            const thermostats = CoreState.fromThermostats.adapter.updateMany(
              updates,
              state.thermostats,
            );

            const updatedState = Object.assign({}, state, { thermostats });

            return reducer(updatedState, action);
          }

          case 'equipment': {
            const resourceGroups = CoreState.fromResourceGroups.adapter.updateMany(
              updates,
              state.resourceGroups,
            );

            const updatedState = Object.assign({}, state, { resourceGroups });

            return reducer(updatedState, action);
          }

          default: {
            return reducer(state, action);
          }
        }
      }
      default: {
        return reducer(state, action);
      }
    }
  };
}
