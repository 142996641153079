import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as MapState from '@spog-ui/shared/state/map';
import * as MapPositionerState from './map-positioner.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    MapState.MapStateModule,
    StoreModule.forFeature(MapPositionerState.STATE_KEY, MapPositionerState.reducers),
  ],
})
export class MapPositionerStateModule {}
