import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ThermostatFanMode } from '@spog-ui/thermodial';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: `sui-bf-static-climate`,
  template: `
    Specify the control points the thermostats in these zones should be set to when this
    routine runs.
    <div [formGroup]="form">
      <spog-thermodial controlGroupName="controlMode"></spog-thermodial>
    </div>
  `,
  styles: [
    `
      div {
        display: flex;
        justify-content: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BFStaticClimateComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    controlMode: new UntypedFormGroup({
      coolF: new UntypedFormControl(85),
      heatF: new UntypedFormControl(55),
      fanMode: new UntypedFormControl(ThermostatFanMode.AUTO),
    }),
  });

  getBehaviorParameters() {
    const formValue = this.form.value;
    return {
      type: 'Thermostat',
      heatSetpoint: formValue.controlMode.heatF,
      coolSetpoint: formValue.controlMode.coolF,
      fan: toBehaviorParameterFanMode(formValue.controlMode.fanMode),
    };
  }

  writeBehaviorParameters(parameters: any) {
    if (parameters === null) return;

    this.form.setValue(
      {
        controlMode: {
          coolF: parameters.coolSetpoint,
          heatF: parameters.heatSetpoint,
          fanMode: fromBehaviorParameterFanMode(parameters.fan),
        },
      },
      {
        onlySelf: true,
        emitEvent: false,
      },
    );
  }
}

/**
 * Note: Cloud API standard is all-caps for string enums.
 * OnPrem API standard is title-case for behavior parameters.
 * This means that, for scenes (which contain behaviors),
 * we need to use the OnPrem API standard.
 */
function toBehaviorParameterFanMode(fanMode: ThermostatFanMode) {
  switch (fanMode) {
    case ThermostatFanMode.AUTO:
      return 'Auto';
    case ThermostatFanMode.ON:
      return 'On';
  }
}

/**
 * Note: Cloud API standard is all-caps for string enums.
 * OnPrem API standard is title-case for behavior parameters.
 * This means that, for scenes (which contain behaviors),
 * we need to use the OnPrem API standard.
 */
function fromBehaviorParameterFanMode(fanMode: 'Auto' | 'On') {
  return fanMode.toUpperCase();
}
