import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
/**
 * Re-import when SS-21086 lands
 */
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ZoneMultiselectComponent } from './zone-multiselect.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatSelectModule, MatFormFieldModule],
  declarations: [ZoneMultiselectComponent],
  exports: [ZoneMultiselectComponent],
})
export class ZoneMultiselectComponentModule {}
