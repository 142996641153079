import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { LightMapLayerApiActions } from '@spog-ui/light-map-layer/actions';
import { LightZoneDetailsAPIActions } from '@spog-ui/light-zone-details/actions';
import { LightZoneInternalModel } from '@spog-ui/shared/models/light-zones';
import { mapValues } from 'lodash';

export type Shape = EntityState<LightZoneInternalModel>;

export const adapter = createEntityAdapter<LightZoneInternalModel>();

export const initialState = adapter.getInitialState();

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const reducer = createReducer(
  initialState,
  on(
    LightZoneDetailsAPIActions.loadLightZoneDetailsPageModelsSuccessAction,
    LightMapLayerApiActions.loadLightMapLayerDeferredModelsSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.lightZones, state);
    },
  ),
);

export const selectZoneIdsByLightId = createSelector(selectAll, lightZones => {
  return lightZones.reduce<{ [lightId: string]: string[] }>(
    (zoneIdsDictionary, { lightId, zoneId }) => {
      if (zoneIdsDictionary[lightId]) {
        zoneIdsDictionary[lightId].push(zoneId);
      } else {
        zoneIdsDictionary[lightId] = [zoneId];
      }

      return zoneIdsDictionary;
    },
    {},
  );
});

export const selectZoneSetLookupTableByLightId = createSelector(
  selectZoneIdsByLightId,
  (zoneIdsByLightId): { [lightId: string]: { [zoneId: string]: true } } => {
    return mapValues(zoneIdsByLightId, zoneIds => {
      return zoneIds.reduce<{ [zoneId: string]: true }>((zoneSetTable, zoneId) => {
        zoneSetTable[zoneId] = true;

        return zoneSetTable;
      }, {});
    });
  },
);
