import { createAction } from '@ngrx/store';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';

import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const loadSiteDetailsSuccessAction = createAction(
  '[Site Details API] Load Site Details Success',
  (site: SiteInternalModel | null, siteControllers: SiteControllerInternalModel[]) => ({
    site,
    siteControllers,
  }),
);
export const loadSiteDetailsFailureAction = createAction(
  '[Site Details API] Load Site Details Failure',
  (error: any) => ({ error }),
);
export const deleteSuccessAction = createAction(
  '[Site Details API] Delete Success',
  (siteId: string) => ({ siteId }),
);
export const deleteFailureAction = createAction(
  '[Site Details API] Delete Failure',
  (error: string) => {
    return { error };
  },
);
export const getSiteUsersSuccessAction = createAction(
  '[Site Details API] Get User Sites Success',
  (users: { name: string; email: string }[]) => ({ users }),
);
export const getSiteUsersFailureAction = createAction(
  '[Site Details API] Get User Sites Failure',
  (error: any) => ({ error }),
);
