import { createAction } from '@ngrx/store';

export const createEmailGroupSuccessAction = createAction(
  '[Email Group / API] Create Email Group Success',
);

export const createEmailGroupFailureAction = createAction(
  '[Email Group / API] Create Email Group Failure',
  (error?: any) => ({ error }),
);

export const deleteEmailGroupFailureAction = createAction(
  '[Email Group / API] Delete Email Group Failure',
  (error?: any) => ({ error }),
);

export const updateEmailGroupSuccessAction = createAction(
  '[Email Group / API] Update Email Group Success',
);

export const updateEmailGroupFailureAction = createAction(
  '[Email Group / API] Update Email Group Failure',
  (error?: any) => ({ error }),
);
