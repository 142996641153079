import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

import { ShortDurationControlComponent } from './short-duration-control.component';

@NgModule({
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule],
  declarations: [ShortDurationControlComponent],
  exports: [ShortDurationControlComponent],
})
export class ShortDurationControlComponentModule {}
