import { DateTime, Interval } from 'luxon';
import {
  ScheduledSceneViewModel,
  ScheduledSceneOccurrenceModel,
  Day,
  ScheduledEventViewModel,
} from './parser.model';
import { getSolarEvents } from './astral';
import { LocationSettings } from '@spog-ui/shared/models/location';

function getDay(weekday: 1 | 2 | 3 | 4 | 5 | 6 | 7): Day {
  switch (weekday) {
    case 1:
      return Day.Monday;
    case 2:
      return Day.Tuesday;
    case 3:
      return Day.Wednesday;
    case 4:
      return Day.Thursday;
    case 5:
      return Day.Friday;
    case 6:
      return Day.Saturday;
    case 7:
      return Day.Sunday;
  }
}

type DateRange = {
  start: DateTime;
  end: DateTime;
  interval: Interval;
  days: Interval[];
};

export function getDateRange(
  startDateTime: DateTime,
  endDateTime: DateTime,
  locationSettings: LocationSettings,
): DateRange {
  const timeZone = locationSettings.timeZone;
  const start = startDateTime.setZone(timeZone).startOf('day');
  /**
   * endDateTime is actually the start of the final day so we need to
   * pad 1 day on the interval to include actual day.
   */
  const end = endDateTime.setZone(timeZone).plus({ days: 1 }).startOf('day');
  const interval = Interval.fromDateTimes(start, end);
  const days = interval.splitBy({ days: 1 });

  return { start, end, interval, days };
}

export function findRRULESet(
  scheduledScene: ScheduledSceneViewModel | ScheduledEventViewModel,
  range: DateRange,
  locationSettings: LocationSettings,
): DateTime[] {
  const recurrence = scheduledScene.recurrence;
  const start = scheduledScene.start;
  const astroTime = scheduledScene.astroTime;
  const timeZone = locationSettings.timeZone;
  const { interval: occurrenceInterval, days: dayIntervals } = range;

  /**
   * If we don't have a recurrence rule for the event we can eagerly
   * calculate a single occurrence for the event and check to see
   * if it falls within our occurrence interval.
   */
  if (!recurrence) {
    let occurrenceDateTime: DateTime;

    if (astroTime) {
      const solarTime = getSolarEvents(
        scheduledScene.start.setZone(timeZone),
        astroTime,
        locationSettings,
      );

      occurrenceDateTime = solarTime
        .setZone(timeZone)
        .plus({ minutes: scheduledScene.astroTimeOffset || 0 });
    } else {
      occurrenceDateTime = start.setZone(timeZone);
    }

    return occurrenceInterval.contains(occurrenceDateTime) ? [occurrenceDateTime] : [];
  }

  /**
   * If the event does have a recurrence then we need to split the occurrence
   * interval up into individual days and generate any valid occurrences
   * for each day in the interval.
   *
   * If we ever have events that recur on a different frequency then this
   * will need to be refactored.
   */
  return dayIntervals
    .reduce((occurrences: DateTime[], interval: Interval) => {
      const until = recurrence.until;

      /**
       * Step One: Find the occurrence date time.
       *
       * If there is a startTime, we assume that there is not an astroTime
       * and initialize the occurrenceDateTime to the day of the start of the
       * interval and the time of the startTime.
       */
      let occurrenceDateTime: DateTime = start.setZone(timeZone).set({
        year: interval.start.year,
        month: interval.start.month,
        day: interval.start.day,
      });

      /**
       * Step Two: Verify the event has started
       *
       * We need to make sure the interval doesn't start before the startDate. If
       * it has, then we return occurrences without generating a new occurrence.
       */
      if (occurrenceDateTime < scheduledScene.start) {
        return occurrences;
      }

      /**
       * Step Three: Verify the event has notended
       *
       * If the recurrence rule has an until set we need to make sure the the event
       * has not ended before the interval. If so, we return the occurrences
       * without generating a new occurrence.
       */
      if (until && occurrenceDateTime > until) {
        return occurrences;
      }

      /**
       * Step Four: Verify the event includes the occurrence weekday
       *
       * Recurrence rules specifiy the week days an occurrence is allowed to start
       * on. If the weekday of the occurrence is not in the list of permitted
       * week days then we return the occurrences without generating a new
       * occurrence.
       */
      if (!recurrence.days.includes(getDay(occurrenceDateTime.weekday as any))) {
        return occurrences;
      }

      /**
       * Step Five: Verify the occurrence has not been deleted
       *
       * Recurrences rules may include a set of dates that should be excluded from
       * the
       */
      if (
        recurrence.excludedDates.some(
          date => date.setZone(timeZone).valueOf() === occurrenceDateTime.valueOf(),
        )
      ) {
        return occurrences;
      }

      /**
       * Step Six: Calculating the Astro Time
       *
       * At this point we have a valid occurrence. Now we need to determine the
       * astro times for the occurrence, pick the right astro time, and apply
       * an astro time offset if one exists.
       */
      if (astroTime) {
        const astroDateTime = getSolarEvents(
          occurrenceDateTime,
          astroTime,
          locationSettings,
        );

        occurrenceDateTime = astroDateTime.setZone(timeZone).plus({
          minutes: scheduledScene.astroTimeOffset || 0,
        });
      }

      return [...occurrences, occurrenceDateTime];
    }, [])
    .filter(occurrence => occurrenceInterval.contains(occurrence));
}

export function getScheduledSceneOccurrences(
  scheduledScenes: ScheduledSceneViewModel[],
  startDateTime: DateTime,
  endDateTime: DateTime,
  locationSettings: LocationSettings,
): ScheduledSceneOccurrenceModel[] {
  const range = getDateRange(startDateTime, endDateTime, locationSettings);
  const scheduledScenesWithOccurrences = scheduledScenes.flatMap(scheduledScene =>
    findRRULESet(scheduledScene, range, locationSettings).map(occurrenceDateTime => ({
      ...scheduledScene,
      occurrenceDateTime,
    })),
  );

  return scheduledScenesWithOccurrences;
}
