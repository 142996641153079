import { Component, Output, EventEmitter } from '@angular/core';
import { DateTime } from 'luxon';
@Component({
  selector: 'spog-landing-page-footer',
  template: `
    <div class="copyright">
      <a href="https://www.synapsewireless.com/"
        >Synapse Wireless &copy; {{ currentYear }}
      </a>
    </div>
    <div class="links">
      <span class="linkItem">
        <a (click)="onShowEula()"> Terms of Use </a>
      </span>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        padding: 24px 16px;
        align-items: center;
        justify-content: space-between;
      }

      a,
      .copyright {
        color: var(--color-foreground-secondary-text);
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
      }

      .links {
        align-self: flex-end;
      }

      .linkItem {
        padding: 10px;
      }

      @media screen and (max-width: 699px) {
        :host {
          flex-direction: column;
        }

        .linkItem {
          padding: 10px 0px;
        }
      }
    `,
  ],
})
export class LandingPageFooterComponent {
  @Output() showEula = new EventEmitter();

  currentYear = DateTime.now().year;

  onShowEula() {
    this.showEula.emit();
  }
}
