import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UtilityServicesApiActions } from '@spog-ui/utility-services/actions';
import { IndieSensorsApi } from '@spog-ui/indie-sensors/actions';
import {
  toUtilityServiceInternalModelFromGQL,
  UtilityServiceGQLModel,
  UtilityServiceInternalModel,
} from '@spog-ui/shared/models/utility-services';

export type Shape = EntityState<UtilityServiceInternalModel>;

export const adapter = createEntityAdapter<UtilityServiceInternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    UtilityServicesApiActions.loadSuccessAction,
    UtilityServicesApiActions.loadUtilityServicesAndThingsSuccessAction,
    IndieSensorsApi.loadSuccessAction,
    (state, action): Shape => {
      const utilityServices = (action.utilityServices as UtilityServiceGQLModel[]).map(
        toUtilityServiceInternalModelFromGQL,
      );

      return adapter.setAll(utilityServices, state);
    },
  ),
);

export const { selectAll } = adapter.getSelectors();
