import { createReducer, on } from '@ngrx/store';
import {
  EquipmentDetailsPageActions,
  EquipmentDetailsApiActions,
} from '@spog-ui/equipment-details/actions';

export interface Shape {
  isLoading: boolean;
}

export const initialState: Shape = {
  isLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(EquipmentDetailsPageActions.enterAction, (): Shape => {
    return {
      ...initialState,
    };
  }),
  on(EquipmentDetailsPageActions.loadPageModelsFromGQL, (state): Shape => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(
    EquipmentDetailsApiActions.loadEquipmentDetailsModelsSuccessAction,
    (state): Shape => {
      return { ...state, isLoading: false };
    },
  ),
  on(
    EquipmentDetailsApiActions.loadEquipmentDetailsModelsFailureAction,
    (state): Shape => {
      return { ...state, isLoading: false };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
