import { DateSelection } from "@spog-ui/shared/models/date-selections";
import { DateTime } from "luxon";

export type DatePickerSelection = {
  startDate: Date,
  endDate: Date,
}

export type DateRange = [DateTime, DateTime];

export function getInitialDateRange(): DateRange {
    return getDateRangeFromSelection(DateSelection.LastDay);
}

export function getDateRangeFromSelection(selection: DateSelection): DateRange  {
    const today = DateTime.local().set({ second: 0, millisecond: 0 });

    let amountToSubtract = {};

    switch (selection) {
      case DateSelection.LastDay: {
        amountToSubtract = { days: 1 };
        break;
      }
      case DateSelection.LastWeek: {
        amountToSubtract = { days: 7 };
        break;
      }
      case DateSelection.LastMonth: {
        amountToSubtract = { months: 1 };
        break;
      }
      case DateSelection.LastYear: {
        amountToSubtract = { years: 1 };
        break;
      }
    }

    return [today.minus(amountToSubtract), today];
}