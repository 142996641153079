import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { MapApiActions } from '@spog-ui/map/actions';
import { AlarmsApiActions } from '@spog-ui/alarms/actions';
import { SocketActions } from '@spog-ui/socket/actions';
import { toAlarmModelFromGQL } from '@spog-ui/shared/models/alarms';
import { SuiAlarmModel } from '@shared/alarms';

export type Shape = EntityState<SuiAlarmModel>;

export const adapter = createEntityAdapter<SuiAlarmModel>();

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(AlarmsApiActions.loadAlarmsSuccess, (state, action): Shape => {
    return adapter.setAll(action.alarms.map(toAlarmModelFromGQL), state);
  }),
  on(MapApiActions.loadEssentialModelsSuccessAction, (state, action) => {
    return adapter.setAll(action.models.alarms, state);
  }),
  on(SocketActions.updateAlarmsAction, (state, action) => {
    if (action.alarm.cleared) {
      return adapter.removeOne(action.alarm.id, state);
    } else {
      return adapter.upsertOne(action.alarm, state);
    }
  }),
  on(SocketActions.deleteAlarmAction, (state, action) => {
    return adapter.removeOne(action.id, state);
  }),
);

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectAlarmsByControllerId = createSelector(selectAll, alarms => {
  return alarms.reduce<{ [controllerId: string]: SuiAlarmModel[] }>(
    (alarmsDictionary, alarm) => {
      if (alarm.controllerId) {
        if (!alarmsDictionary[alarm.controllerId]) {
          alarmsDictionary[alarm.controllerId] = [];
        }
        alarmsDictionary[alarm.controllerId].push(alarm);
      }
      return alarmsDictionary;
    },
    {},
  );
});
