import { Inject, Injectable, InjectionToken, NgZone, Provider } from '@angular/core';

declare let global: any;

export const RAF = new InjectionToken<(fn: () => void) => void>('requestAnimationFrame');

export interface AnimationFrameFactory {
  (fn: () => void): void;
}

@Injectable()
export class AnimatorService {
  constructor(private zone: NgZone, @Inject(RAF) private animationFrameFactory: any) {}

  run(fn: () => void) {
    this.zone.runOutsideAngular(() => {
      this.animationFrameFactory(fn);
    });
  }
}

export function rafFactory(): any {
  return (
    global['requestAnimationFrame'] || global['setImmediate'] || global['setTimeout']
  );
}

export const RAF_PROVIDER: Provider = {
  provide: RAF,
  useFactory: rafFactory,
};

export const ANIMATOR_SERVICE_PROVIDER: Provider = AnimatorService;
