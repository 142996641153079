import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as MapState from '@spog-ui/shared/state/map';
import * as AssignerState from './assigner';
import * as FreeformToolState from './freeform-tool';
import * as GridToolState from './grid-tool';
import {
  MapPositionerFormModel,
  MapPositionerResult,
  MapPositionableThingType,
  PositionedThingModel,
  PositionModel,
  UnpositionedThingModel,
} from '@spog-ui/shared/models/map-positioners';

export const STATE_KEY = 'mapPositioner';

export interface Shape {
  assigner: AssignerState.Shape;
  freeformTool: FreeformToolState.Shape;
  gridTool: GridToolState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  assigner: AssignerState.reducer,
  freeformTool: FreeformToolState.reducer,
  gridTool: GridToolState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Assigner State
 */
export const selectAssignerState = createSelector(
  selectFeatureState,
  state => state.assigner,
);
export const selectAssignerActivePositionId = createSelector(
  selectAssignerState,
  AssignerState.selectActivePositionId,
);
export const selectAssignerSearchTerm = createSelector(
  selectAssignerState,
  AssignerState.selectSearchTerm,
);

/**
 * Freeform Tool State
 */
export const selectFreeformToolState = createSelector(
  selectFeatureState,
  state => state.freeformTool,
);
export const selectFreeformPositions = createSelector(
  selectFreeformToolState,
  FreeformToolState.selectAll,
);

/**
 * Grid Tool State
 */
export const selectGridToolState = createSelector(
  selectFeatureState,
  state => state.gridTool,
);
export const selectGridToolRows = createSelector(
  selectGridToolState,
  GridToolState.selectRows,
);
export const selectGridToolCols = createSelector(
  selectGridToolState,
  GridToolState.selectCols,
);
export const selectGridToolArea = createSelector(
  selectGridToolState,
  GridToolState.selectArea,
);
export const selectGridToolIsAreaSelectToolEnabled = createSelector(
  selectGridToolState,
  GridToolState.selectIsAreaToolEnabled,
);
export const selectGridPositions = createSelector(
  selectGridToolState,
  GridToolState.selectAll,
);

/**
 * Custom Map Positioner Selectors
 */
export const selectActiveGridTool = createSelector(
  selectGridToolIsAreaSelectToolEnabled,
  isAreaToolEnabled => {
    return isAreaToolEnabled ? 'area-select' : 'pan-and-zoom';
  },
);

export const selectFreeformSelectToolEnabled = createSelector(
  selectGridToolState,
  GridToolState.selectIsFreeformToolEnabled,
);

export const selectMapPositionerThingType = createSelector(
  CoreState.selectRouterData,
  (routerData): MapPositionableThingType => {
    const thingType: MapPositionableThingType | undefined = routerData.thingType;

    if (thingType === 'light') return 'light';
    if (thingType === 'industrial-sensor') return 'industrial-sensor';
    if (thingType === 'thermostat') return 'thermostat';
    if (thingType === 'equipment') return 'equipment';

    return 'light';
  },
);

export const selectMpPositionedLights = createSelector(
  CoreState.selectAllLights,
  (lights): PositionedThingModel[] => {
    return lights.reduce((positionedThings, light) => {
      if (!light.floorPlanId) return positionedThings;

      positionedThings.push({
        id: light.id,
        name: light.name,
        type: 'light',
        floorPlanX: light.floorPlanX,
        floorPlanY: light.floorPlanY,
      });

      return positionedThings;
    }, [] as PositionedThingModel[]);
  },
);

export const selectMpUnpositionedLights = createSelector(
  CoreState.selectAllLights,
  (lights): UnpositionedThingModel[] => {
    return lights.reduce((unpositionedThings, light) => {
      if (light.floorPlanId) return unpositionedThings;

      unpositionedThings.push({
        id: light.id,
        name: light.name,
        type: 'light',
      });

      return unpositionedThings;
    }, [] as UnpositionedThingModel[]);
  },
);

export const selectMpPositionedIndieSensors = createSelector(
  CoreState.selectAllIndieSensors,
  (indieSensors): PositionedThingModel[] => {
    return indieSensors.reduce((positionedThings, indieSensor) => {
      if (!indieSensor.floorPlanId) return positionedThings;

      positionedThings.push({
        id: indieSensor.id,
        name: indieSensor.name,
        type: 'industrial-sensor',
        floorPlanX: indieSensor.floorPlanX!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        floorPlanY: indieSensor.floorPlanY!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      });

      return positionedThings;
    }, [] as PositionedThingModel[]);
  },
);

export const selectMpUnpositionedIndieSensors = createSelector(
  CoreState.selectAllIndieSensors,
  (indieSensors): UnpositionedThingModel[] => {
    return indieSensors.reduce((unpositionedThings, indieSensor) => {
      if (indieSensor.floorPlanId) return unpositionedThings;

      unpositionedThings.push({
        id: indieSensor.id,
        name: indieSensor.name,
        type: 'industrial-sensor',
      });

      return unpositionedThings;
    }, [] as UnpositionedThingModel[]);
  },
);

export const selectMpPositionedThermostats = createSelector(
  CoreState.selectAllThermostats,
  (thermostats): PositionedThingModel[] => {
    return thermostats.reduce((positionedThings, thermostat) => {
      if (!thermostat.floorPlanId) return positionedThings;

      positionedThings.push({
        id: thermostat.id,
        name: thermostat.name ?? '',
        type: 'thermostat',
        floorPlanX: thermostat.floorPlanX!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        floorPlanY: thermostat.floorPlanY!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      });

      return positionedThings;
    }, [] as PositionedThingModel[]);
  },
);

export const selectMpUnpositionedThermostats = createSelector(
  CoreState.selectAllThermostats,
  (thermostats): UnpositionedThingModel[] => {
    return thermostats.reduce((unpositionedThings, thermostat) => {
      if (thermostat.floorPlanId) return unpositionedThings;

      unpositionedThings.push({
        id: thermostat.id,
        name: thermostat.name ?? '',
        type: 'thermostat',
      });

      return unpositionedThings;
    }, [] as UnpositionedThingModel[]);
  },
);

export const selectMpPositionedEquipment = createSelector(
  CoreState.selectNonchildEquipment,
  (equipment): PositionedThingModel[] =>
    equipment.reduce((positionedThings, equipment) => {
      if (!equipment.floorPlanId) return positionedThings;

      positionedThings.push({
        id: equipment.id,
        name: equipment.name,
        type: 'equipment',
        floorPlanX: equipment.floorPlanX!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        floorPlanY: equipment.floorPlanY!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      });

      return positionedThings;
    }, [] as PositionedThingModel[]),
);

export const selectMpUnpositionedEquipment = createSelector(
  CoreState.selectNonchildEquipment,
  (equipment): UnpositionedThingModel[] =>
    equipment.reduce((unpositionedThings, equipment) => {
      if (equipment.floorPlanId) return unpositionedThings;

      unpositionedThings.push({
        id: equipment.id,
        name: equipment.name,
        type: 'equipment',
      });

      return unpositionedThings;
    }, [] as UnpositionedThingModel[]),
);

export const selectMpPositionedThings = createSelector(
  selectMpPositionedLights,
  selectMpPositionedIndieSensors,
  selectMpPositionedThermostats,
  selectMpPositionedEquipment,
  selectMapPositionerThingType,
  (
    positionedLights,
    positionedIndieSensors,
    positionedThermostats,
    positionedEquipment,
    thingType,
  ) => {
    if (thingType === 'light') {
      return positionedLights;
    }

    if (thingType === 'industrial-sensor') {
      return positionedIndieSensors;
    }

    if (thingType === 'thermostat') {
      return positionedThermostats;
    }

    if (thingType === 'equipment') {
      return positionedEquipment;
    }

    return [];
  },
);

export const selectMpUnpositionedThings = createSelector(
  selectMpUnpositionedLights,
  selectMpUnpositionedIndieSensors,
  selectMpUnpositionedThermostats,
  selectMpUnpositionedEquipment,
  selectMapPositionerThingType,
  (
    unpositionedLights,
    unpositionedIndieSensors,
    unpositionedThermostats,
    unpositionedEquipment,
    thingType,
  ) => {
    if (thingType === 'light') {
      return unpositionedLights;
    }

    if (thingType === 'industrial-sensor') {
      return unpositionedIndieSensors;
    }

    if (thingType === 'thermostat') {
      return unpositionedThermostats;
    }

    if (thingType === 'equipment') {
      return unpositionedEquipment;
    }

    return [];
  },
);

export const selectMpPositions = createSelector(
  selectFreeformPositions,
  selectGridPositions,
  (freeformPositions, gridPositions) => {
    if (freeformPositions.length > 0) {
      return freeformPositions;
    } else {
      return gridPositions;
    }
  },
);

export const selectMpActivePosition = createSelector(
  selectAssignerActivePositionId,
  selectMpPositions,
  (activePositionId, positions) => {
    return positions.find(position => position.id === activePositionId) || null;
  },
);

export const selectMpThingsWithChanges = createSelector(
  selectMpPositions,
  selectMpUnpositionedThings,
  selectMpPositionedThings,
  (positions, unpositionedThings, positionedThings) => {
    const newlyPositionedThings = [...positionedThings];
    const filledPositionsByThingId = positions.reduce(
      (filledPositionsByThingId, position) => {
        if (!position.thingId) return filledPositionsByThingId;

        filledPositionsByThingId[position.thingId] = position;

        return filledPositionsByThingId;
      },
      {} as { [thingId: string]: PositionModel },
    );

    const newlyUnpositionedThings = unpositionedThings.filter(thing => {
      const position = filledPositionsByThingId[thing.id];

      if (!position) return true;

      newlyPositionedThings.push({
        ...thing,
        floorPlanX: position.floorPlanX,
        floorPlanY: position.floorPlanY,
      });

      return false;
    });

    return { newlyPositionedThings, newlyUnpositionedThings };
  },
);

export const selectMpUpcomingUnpositionedThings = createSelector(
  selectMpThingsWithChanges,
  selectAssignerSearchTerm,
  ({ newlyUnpositionedThings }, searchTerm) => {
    return newlyUnpositionedThings.filter(thing =>
      thing.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  },
);

export const selectMpUpcomingPositionedThings = createSelector(
  selectMpThingsWithChanges,
  selectAssignerSearchTerm,
  ({ newlyPositionedThings }, searchTerm) => {
    return newlyPositionedThings.filter(thing =>
      thing.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  },
);
export const selectMpValue = createSelector(
  MapState.selectActiveFloorPlanId,
  selectMpPositions,
  (activeFloorPlanId, positions): MapPositionerFormModel | null => {
    if (activeFloorPlanId === null) return null;

    const thingPositions = positions.reduce((lightPositions, position) => {
      const { thingId, floorPlanX, floorPlanY } = position;

      if (thingId === null) return lightPositions;

      lightPositions.push({
        id: thingId,
        floorPlanX: Math.round(floorPlanX),
        floorPlanY: Math.round(floorPlanY),
      });

      return lightPositions;
    }, [] as MapPositionerFormModel['positions']);

    if (thingPositions.length === 0) return null;

    return {
      floorPlanId: activeFloorPlanId,
      positions: thingPositions,
    };
  },
);
export const selectMpResult = createSelector(
  selectMapPositionerThingType,
  selectMpValue,
  (thingType, value): MapPositionerResult | null => {
    if (value === null) return null;

    return { thingType, value };
  },
);
export const selectMpHasAvailablePositions = createSelector(
  selectMpPositions,
  positions => positions.length > 0,
);
export const selectMpHasResult = createSelector(
  selectMpResult,
  result => result !== null,
);
