import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { merge, fromEvent, interval, Observable } from 'rxjs';
import { mapTo, map, pairwise, filter } from 'rxjs/operators';
import { BrowserActions } from '@spog-ui/shared/effects/actions';
import { DateTime } from 'luxon';

export const TIMEOUT = 20_000; // How often to check for lost time?
export const FUDGE_FACTOR = 2000; // How far off can the expected vs. actual time be?

@Injectable()
export class BrowserEffects {
  onlineOffline$ = createEffect(() =>
    merge(
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false)),
    ).pipe(
      map(isOnline =>
        isOnline ? BrowserActions.onlineAction() : BrowserActions.offlineAction(),
      ),
    ),
  );

  wake$ = createEffect(
    () =>
      (tick$ = interval(TIMEOUT)) =>
        tick$.pipe(
          map(() => DateTime.utc().toMillis()),
          pairwise(),
          filter(([then, now]) => {
            const expectedTime = then + TIMEOUT;
            const actualTime = now;
            return actualTime - expectedTime > FUDGE_FACTOR;
          }),
          map(() => BrowserActions.wakeAction()),
        ),
  );
}
