import { createReducer, on } from '@ngrx/store';
import { EditEmailGroupPageActions, EmailGroupApiActions } from '../actions';
export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  error?: any;
  emailGroupId: string | null;
}

export const STATE_KEY = 'edit-email-group-page';

export const initialState: Shape = {
  isLoading: true,
  isSubmitting: false,
  emailGroupId: null,
};

export const reducer = createReducer(
  initialState,
  on(EditEmailGroupPageActions.enterAction, (_state, action): Shape => {
    return {
      ...initialState,
      emailGroupId: action.emailGroupId,
    };
  }),
  on(EditEmailGroupPageActions.loadPageSuccessAction, (state): Shape => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(EditEmailGroupPageActions.loadPageFailureAction, (state, action): Shape => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
  on(EditEmailGroupPageActions.editEmailGroupAction, (state): Shape => {
    return {
      ...state,
      isSubmitting: true,
    };
  }),
  on(EmailGroupApiActions.updateEmailGroupFailureAction, (state): Shape => {
    return {
      ...state,
      isSubmitting: false,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
export const selectActiveId = (state: Shape) => state.emailGroupId;
