import { Component, OnInit, Injectable } from '@angular/core';
import { AddSequenceScenePageActions } from '@spog-ui/scenes/actions';
import { SequenceSceneInternalModel } from '@spog-ui/shared/models/sequence-scenes';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';
import { Observable } from 'rxjs';

@Injectable()
export abstract class AddSequenceScenePageState {
  abstract ready$: Observable<boolean>;
  abstract saving$: Observable<boolean>;
  abstract sequenceScenes$: Observable<SequenceSceneInternalModel[]>;
  abstract staticScenes$: Observable<SceneInternalModel[]>;
  abstract dispatch(action: AddSequenceScenePageActions.Union): void;
}

@Component({
  selector: 'scn-add-sequence-scene-page',
  template: `
    @if (state.ready$ | async) {
      <scn-sequence-scene-form
        [sequenceScenes]="state.sequenceScenes$ | async"
        [staticScenes]="state.staticScenes$ | async"
        [disabled]="state.saving$ | async"
        (save)="onSave($event)"
        >
      </scn-sequence-scene-form>
    } @else {
      <sui-spinner></sui-spinner>
    }
    
    `,
  styles: [
    `
      sui-spinner {
        display: block;
        width: 50px;
        margin: 0 auto;
      }
    `,
  ],
})
export class AddSequenceScenePageComponent implements OnInit {
  constructor(public state: AddSequenceScenePageState) {}

  ngOnInit(): void {
    this.state.dispatch(AddSequenceScenePageActions.enterAction());
  }

  onSave($event: SequenceSceneInternalModel): void {
    this.state.dispatch(AddSequenceScenePageActions.addSequenceSceneAction($event));
  }
}
