import {
  EventEmitter,
  Inject,
  Injectable,
  InjectionToken,
  Optional,
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ComponentStore } from '@ngrx/component-store';
import { animationFrameScheduler, Observable, SchedulerLike } from 'rxjs';
import { observeOn, tap } from 'rxjs/operators';

export const PANEL_STATE_SCHEDULER = new InjectionToken<SchedulerLike>(
  '@sui/panel/state-scheduler',
);

export const DESKTOP_MEDIA_QUERY = '(min-width: 1260px)';
export const TABLET_MEDIA_QUERY = '(min-width: 500px) and (max-width: 1259.98px)';
export const PHONE_MEDIA_QUERY = '(max-width: 499.98px)';

interface PanelStateShape {
  isDesktop: boolean;
  isTablet: boolean;
  isPhone: boolean;
  isOpen: boolean;
}

const initialPanelState: PanelStateShape = {
  isDesktop: true,
  isTablet: false,
  isPhone: false,
  isOpen: false,
};

const selectIsOpen = (state: PanelStateShape) => state.isOpen;
const selectIsLayoutPushed = (state: PanelStateShape) => state.isDesktop && state.isOpen;
const selectIsOverlayVisible = (state: PanelStateShape) =>
  (state.isPhone || state.isTablet) && state.isOpen;
const selectIsPanelCoveringThePage = (state: PanelStateShape) =>
  state.isPhone && state.isOpen;

@Injectable({ providedIn: 'root' })
export class SuiPanelState extends ComponentStore<PanelStateShape> {
  private closeEvents = new EventEmitter();
  readonly isOpen$ = this.selectAsync(selectIsOpen);
  readonly isLayoutPushed$ = this.selectAsync(selectIsLayoutPushed);
  readonly isOverlayVisible$ = this.selectAsync(selectIsOverlayVisible);
  readonly isPanelCoveringThePage$ = this.selectAsync(selectIsPanelCoveringThePage);

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(PANEL_STATE_SCHEDULER) @Optional() private scheduler: SchedulerLike,
  ) {
    super(initialPanelState);

    this.observeMediaQuery('isDesktop', DESKTOP_MEDIA_QUERY);
    this.observeMediaQuery('isTablet', TABLET_MEDIA_QUERY);
    this.observeMediaQuery('isPhone', PHONE_MEDIA_QUERY);
  }

  /**
   * This entire state class uses ComponentStore in a slightly
   * atypical way. Instead of this being contained directly
   * within a single component tree, adjacent component
   * trees can inject the single instance of this class
   * so that they can respond to changes in layout.
   *
   * For example, a toolbar may want to add extra padding to the
   * right of itself when a panel is open on desktop.
   *
   * Wrapping up selected values to use the animationFrameScheduler
   * will let adjacenet component trees consume state from this
   * instance while avoid ExpressionChangedAfterItHasBeenChecked
   * errors.
   */
  private selectAsync<V>(selector: (state: PanelStateShape) => V): Observable<V> {
    return this.select(selector).pipe(
      observeOn(this.scheduler ?? animationFrameScheduler),
    );
  }

  private observeMediaQuery(stateKey: keyof PanelStateShape, mediaQuery: string) {
    this.effect(() => {
      return this.breakpointObserver.observe(mediaQuery).pipe(
        tap((result: BreakpointState) => {
          this.setState(state => ({ ...state, [stateKey]: result.matches }));
        }),
      );
    });
  }

  disableScroll(bodyElement: HTMLElement, flag: boolean) {
    flag
      ? bodyElement.style.setProperty('overflow-y', 'hidden', 'important')
      : bodyElement.style.setProperty('overflow-y', 'visible', 'important');
  }

  markPanelAsOpen() {
    this.setState(state => ({ ...state, isOpen: true }));
  }

  markPanelAsClosed() {
    this.setState(state => ({ ...state, isOpen: false }));
  }

  emitCloseEvent() {
    this.closeEvents.emit();
  }

  observeCloseEvents() {
    return this.closeEvents.asObservable();
  }
}
