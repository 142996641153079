import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DateRangeFormComponent, SuiDialogModule } from '@spog-ui/shared/components';
import { DateControlComponent } from './date-control.component';
import { CustomDateSelectComponent } from './custom-date-select.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    DateRangeFormComponent,
    SuiDialogModule,
  ],
  declarations: [DateControlComponent, CustomDateSelectComponent],
  exports: [DateControlComponent, CustomDateSelectComponent],
})
export class DateControlComponentModule {}
