import {
  AddScheduledEventPageActions,
  ScheduleApiActions,
} from '@spog-ui/schedule/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  error: string | null;
  saving: boolean;
  loading: boolean;
}

const initialState: Shape = {
  error: null,
  saving: false,
  loading: true,
};

export const reducer = createReducer(
  initialState,
  on(AddScheduledEventPageActions.enterAction, () => {
    return initialState;
  }),
  on(AddScheduledEventPageActions.addScheduledEventAction, state => {
    return {
      ...state,
      error: null,
      saving: true,
      loading: false,
    };
  }),
  on(ScheduleApiActions.addScheduledEventSuccessAction, state => {
    return {
      ...state,
      error: null,
      saving: false,
      loading: false,
    };
  }),
  on(ScheduleApiActions.addScheduledEventFailureAction, (state, action) => {
    return {
      ...state,
      error: action.error,
      saving: false,
      loading: false,
    };
  }),
  on(ScheduleApiActions.loadSuccessAction, state => {
    return {
      ...state,
      error: null,
      saving: false,
      loading: false,
    };
  }),
  on(ScheduleApiActions.loadFailureAction, (state, action) => {
    return {
      ...state,
      error: action.error,
      saving: false,
      loading: false,
    };
  }),
);

export const selectAdding = (state: Shape) => state.saving;
export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
