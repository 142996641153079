import { createAction, props } from '@ngrx/store';
import { MetricsType } from './user-interaction.models';

export const logEventAction = createAction(
  '[user-interaction] Log Event',
  props<{
    message: string;
    detail?: string;
    metricsType: MetricsType;
    success?: boolean;
    startTime?: number;
    endTime?: number;
    timeTaken?: number;
  }>(),
);
