/**
 * Before we get to the implementation, let me talk a bit about the
 * Map Canvas. At a high level this is just an abstraction over
 * creating HTMLCanvasElements, getting a drawing context for them,
 * and doing rendering work in a shared animation loop.
 *
 * It is written as an Angular component so that you can isolate
 * rendering work inside of specialized Angular elements. The benefit
 * of this API is that you could easily break rendering work out
 * into conditional chunks:
 *
 * ```html
 * <map-canvas>
 *  <ng-container *ngIf="activeLayer === 'lights'">
 *    <map-canvas-light-alarms></map-canvas-light-alarms>
 *    <map-canvas-lights></map-canvas-lights>
 *  </ng-container>
 *
 *  <map-canvas-senses *ngIf="activeLayer === 'sense420'">
 *  </map-canvas-senses>
 * </map-canvas>
 * ```
 */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Injectable,
  NgZone,
  OnDestroy,
} from '@angular/core';
import {
  IRenderingLoop,
  ChildRenderer,
  ChildRendererOptions,
  MapRenderFn,
  RootRenderer,
  TransformModel,
} from '@spog-ui/map-tools/models';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { MapToolsState } from '../../services';

/**
 * The rendering loop is shared across all components. However, there
 * tend to be stretches of time where the user is not interacting
 * with the map at all. It isn't necessary to repaint the entire
 * canvas during these periods of time.
 *
 * Children may need to repaint regardless of user interaction. For instance,
 * a list of lights may need to repaint if a new light is added to the map.
 * It needs a way to tell the canvas that some rework may need to happen.
 *
 * canBeSkipped - Nothing will be redrawn
 * requestedFromChild - Some child requested a re-render
 * mustHappen - All children must re-render
 */
const enum NextRender {
  canBeSkipped = 0b00,
  requestedFromChild = 0b01,
  mustHappen = 0b10,
}

/**
 * For retina quality displays the map is drawn at 2x scale and then resized
 * down to the device's dimensions using CSS.
 */
export const MAP_CANVAS_SCALE = 2;
export const TOOLBAR_HEIGHT = 64;

@Injectable({ providedIn: 'root' })
export class MapRendererRef {
  renderer: RootRenderer;
}

/**
 * Simple implementation of a requestAnimationFrame-based rendering loop.
 * This is abstracted out from the MapCanvasComponent so that tests can
 * implement a manual rendering loop.
 */
@Injectable({ providedIn: 'root' })
export class RenderingLoop implements IRenderingLoop {
  constructor(public ngZone: NgZone) {}

  start(tick: () => void) {
    let animationFrameRef: number | null = null;

    function render() {
      tick();

      animationFrameRef = requestAnimationFrame(render);
    }

    this.ngZone.runOutsideAngular(render);

    return () => {
      if (animationFrameRef !== null) {
        cancelAnimationFrame(animationFrameRef);
      }
    };
  }
}

@Component({
  selector: 'map-canvas',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        pointer-events: none;
        position: fixed;
        top: 64px;
        left: 0px;
        transform: translate3d(0, 0, 0);
      }
    `,
  ],
  providers: [
    {
      provide: RootRenderer,
      useExisting: MapCanvasComponent,
    },
  ],
})
export class MapCanvasComponent implements AfterViewInit, OnDestroy, RootRenderer {
  private subscriptions = new Subscription();

  /**
   * Child rendering loops are just map render functions. Each rendering
   * loop gets its own Canvas to draw on. Canvases are layered on top
   * of each other and are stacked in the order they are created.
   */
  private childRenderFunctions: MapRenderFn[] = [];
  private childRenderCanvases: HTMLCanvasElement[] = [];

  /**
   * Each canvas shares the same width, height, and transformation
   */
  private canvasWidth = 0;
  private canvasHeight = 0;
  private canvasTransform: TransformModel = { k: 1, x: 0, y: 0 };

  /**
   * Rendering flag used to determine how much work (if any) should
   * be performed on the next iteration of the rendering loop.
   */
  private shouldRender: NextRender = NextRender.canBeSkipped;

  /**
   * Placeholder function used to render each child.
   */
  private renderChildren: (fns: MapRenderFn[], canvases: HTMLCanvasElement[]) => void =
    () => void 0;

  constructor(
    private state: MapToolsState,
    private loop: RenderingLoop,
    private elementRef: ElementRef<HTMLElement>,
    ref: MapRendererRef,
  ) {
    ref.renderer = this;
  }

  ngAfterViewInit() {
    /**
     * Every time the browser window changes dimensions we need
     * to resize the canvases to the fill the viewport and then
     * we need to force render each child canvas.
     */
    const updateCanvasSizes = (size: { width: number; height: number }) => {
      this.canvasWidth = size.width;
      this.canvasHeight = size.height - TOOLBAR_HEIGHT;

      this.childRenderCanvases.forEach(canvas => {
        canvas.width = this.canvasWidth * MAP_CANVAS_SCALE;
        canvas.height = this.canvasHeight * MAP_CANVAS_SCALE;
        canvas.style.width = `${this.canvasWidth}px`;
        canvas.style.height = `${this.canvasHeight}px`;
      });

      this.shouldRender |= NextRender.mustHappen;
    };

    /**
     * Similarly each time the user pans and/or zooms the map we
     * need to capture the transform and then force each child
     * canvas to re-render.
     */
    const updateTransform = (nextTransform: TransformModel) => {
      this.canvasTransform = nextTransform;

      this.shouldRender |= NextRender.mustHappen;
    };

    /**
     * This is the actual loop of the rendering engine. If we don't need
     * to render then we can simply skip doing any work. Otherwise we
     * check each child to see if it needs to render. Finally, we reset
     * the flag to potentially skip the next loop.
     */
    const tick = () => {
      this.renderChildren(this.childRenderFunctions, this.childRenderCanvases);

      this.shouldRender = NextRender.canBeSkipped;
    };

    const resizeSub = this.state.browserSize$.subscribe(updateCanvasSizes);
    const transformSub = this.state.transform$
      .pipe(distinctUntilChanged((a, b) => a.k === b.k && a.x === b.x && a.y === b.y))
      .subscribe(updateTransform);
    const loopSub = this.loop.start(tick);

    this.subscriptions.add(resizeSub);
    this.subscriptions.add(transformSub);
    this.subscriptions.add(loopSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  createChildRenderer(
    fn: MapRenderFn,
    {
      order = 100,
      shouldRender: shouldRenderOverrideFn,
    }: Partial<ChildRendererOptions> = {},
  ): ChildRenderer {
    let markedSelfForRender = true;

    /**
     * By default children will only render if they:
     *  - Requested the render themselves, or
     *  - The entire map needs to be re-rendered because of pan & zoom, or
     *  - The browser has been resized.
     *
     * If none of these are the case then we know we don't have to render anything.
     */
    const defaultShouldRenderFn = () => {
      return markedSelfForRender || this.shouldRender & NextRender.mustHappen;
    };

    const shouldRender = shouldRenderOverrideFn || defaultShouldRenderFn;

    /**
     * Each render function is wrapped with some logic that is shared across
     * all layers.
     */
    this.childRenderFunctions.push((ctx: CanvasRenderingContext2D) => {
      if (!shouldRender()) return;

      /**
       * Clear the child canvas and then set the scale based on the current
       * pan & zoom transform. This way children don't have to really think
       *
       * about the transform or the size of the canvas. They can just draw
       * to it and the right thing will happen.
       */
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(
        0,
        0,
        this.canvasWidth * MAP_CANVAS_SCALE,
        this.canvasHeight * MAP_CANVAS_SCALE,
      );
      ctx.translate(
        this.canvasTransform.x * MAP_CANVAS_SCALE,
        this.canvasTransform.y * MAP_CANVAS_SCALE,
      );
      ctx.scale(this.canvasTransform.k, this.canvasTransform.k);

      /**
       * Finally call the child render function and reset the render flag.
       */
      fn(ctx);

      markedSelfForRender = false;
    });

    /**
     * Each child renderer gets its own canvas. These are stacked on top of
     * each other in creation order.
     */
    const canvas = document.createElement('canvas');
    canvas.width = this.canvasWidth * MAP_CANVAS_SCALE;
    canvas.height = this.canvasHeight * MAP_CANVAS_SCALE;
    canvas.style.width = `${this.canvasWidth}px`;
    canvas.style.height = `${this.canvasHeight}px`;
    canvas.style.position = 'absolute';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.zIndex = `${order}`;
    this.elementRef.nativeElement.appendChild(canvas);
    this.childRenderCanvases.push(canvas);

    this.updateRenderChildrenFn();

    return {
      /**
       * Marking a child renderer will tell the MapCanvasComponent that
       * children need to be rechecked.
       */
      markForRender: () => {
        markedSelfForRender = true;
        this.shouldRender |= NextRender.requestedFromChild;
      },
      /**
       * When we tear down we need to release the child render fn, the
       * child renderer's canvas, and update the render children fn to
       * no longer run the child renderer.
       */
      teardown: () => {
        this.childRenderFunctions.filter(childRenderFn => childRenderFn !== fn);
        this.childRenderCanvases.filter(childCanvas => childCanvas !== canvas);
        this.elementRef.nativeElement.removeChild(canvas);
        this.updateRenderChildrenFn();
      },
    };
  }

  /**
   * You know how it can be expensive to iterate over an array but accessing
   * each individual item is pretty quick? This generates a new function
   * each time we modify the child render fn array that has pre-iterated
   * over each element.
   *
   * The result is a dynamically generated function that very quickly calls
   * each registered child function.
   */
  private updateRenderChildrenFn() {
    const renderFnsArg = 'fns';
    const canvasesArg = 'canvases';
    const expressions = this.childRenderFunctions.map(
      (_, i) => `${renderFnsArg}[${i}](${canvasesArg}[${i}].getContext('2d'));`,
    );
    this.renderChildren = new Function(
      renderFnsArg,
      canvasesArg,
      expressions.join(''),
    ) as any;
  }
}
