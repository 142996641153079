import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';

import {
  SuiColorPreviewModule,
  DimmerControlComponent,
  ColorHueControlComponent,
  ColorLuminanceControlComponent,
  ColorSaturationControlComponent,
} from '@spog-ui/shared/components';
import { SpeedControlComponentModule } from '../speed-control';
import { BFPaparazziComponent } from './bf-paparazzi.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    SpeedControlComponentModule,
    ColorHueControlComponent,
    ColorLuminanceControlComponent,
    ColorSaturationControlComponent,
    SuiColorPreviewModule,
    DimmerControlComponent,
  ],
  declarations: [BFPaparazziComponent],
  exports: [BFPaparazziComponent],
})
export class BFPaparazziComponentModule {}
