import { createAction } from '@ngrx/store';
import { OrganizationFormModel, OrganizationInternalModel } from '@spog-ui/shared/models/organizations';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const enterAction = createAction(
  '[Admin / Edit Organization Page] Enter',
  (organizationId: string) => ({
    organizationId,
  }),
);

export const editOrganizationAction = createAction(
  '[Edit Organization] Edit Organization',
  (organization: OrganizationFormModel) => ({ organization }),
);

export const loadPageSuccessAction = createAction(
  '[Edit Organization] Load Edit Page Success',
  (sites: SiteInternalModel[], organizations: OrganizationInternalModel[]) => ({
    sites,
    organizations,
  }),
);

export const loadPageFailureAction = createAction(
  '[Edit Organization] Load Edit Page Failure',
  (error: string) => ({ error }),
);