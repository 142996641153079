import { createReducer, on } from '@ngrx/store';
import { SiteControllerAdminPage } from '@spog-ui/shared/effects/actions';
import { AdminPanelActions } from '@spog-ui/admin/actions';

export interface Shape {
  adminPanelRedirectUrl: string;
  siteControllerRedirectUrl: string;
}

export const initialState: Shape = {
  adminPanelRedirectUrl: '/',
  siteControllerRedirectUrl: '/admin/site-controllers',
};

export const reducer = createReducer(
  initialState,
  on(AdminPanelActions.openAdminPanel, (state, action) => {
    return {
      ...state,
      adminPanelRedirectUrl: action.redirectUrl,
    };
  }),
  on(
    SiteControllerAdminPage.addAction,
    SiteControllerAdminPage.editAction,
    (state, action) => {
      return {
        ...state,
        siteControllerRedirectUrl: action.redirectUrl,
      };
    },
  ),
);

export const selectAdminPanelRedirectUrl = (state: Shape) => state.adminPanelRedirectUrl;
export const selectSiteControllerRedirectUrl = (state: Shape) =>
  state.siteControllerRedirectUrl;
