import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { MapToolsState } from './services';

@NgModule()
export class MapToolsFeatureModule {
  static connect(
    StateAdapterClass: Type<MapToolsState>,
  ): ModuleWithProviders<MapToolsFeatureModule> {
    return {
      ngModule: MapToolsFeatureModule,
      providers: [
        {
          provide: MapToolsState,
          useClass: StateAdapterClass,
        },
      ],
    };
  }
}

export { MapToolsState };
