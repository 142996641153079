import { DateTime } from 'luxon';

export interface OnPremScheduledEventModel {
  id: string;
  scene_id: string;
  description: string;
  astrotime: AstroTime | null;
  offset: string | null;
  dtstart: string;
  rrule: string | null;
}

export interface ScheduledSceneModel extends ScheduledEventModel {
  sceneId: string;
}

export interface ScheduledEventModel {
  id: string;
  name: string;
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  start: string;
  rrule: string | null;
}

export interface CloudScheduledEventModel extends ScheduledEventModel {
  sceneIds: string[];
}

export interface RecurrenceModel {
  frequency: Frequency | null;
  days: Day[];
  excludedDates: DateTime[];
  until: DateTime | null;
}
export interface ScheduledSceneViewModel {
  id: string;
  name: string;
  sceneId: string;
  start: DateTime;
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  recurrence: RecurrenceModel | null;
}
export interface ScheduledSceneOccurrenceModel {
  id: string;
  name: string;
  sceneId: string;
  start: DateTime;
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  recurrence: RecurrenceModel | null;
  occurrenceDateTime: DateTime;
}

export interface ScheduledEventViewModel {
  id: string;
  name: string;
  sceneIds: string[];
  start: DateTime;
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  recurrence: RecurrenceModel | null;
}

export enum AstroTime {
  Dawn = 'dawn',
  Sunrise = 'sunrise',
  Sunset = 'sunset',
  Dusk = 'dusk',
}

export enum Day {
  Sunday = 'SU',
  Monday = 'MO',
  Tuesday = 'TU',
  Wednesday = 'WE',
  Thursday = 'TH',
  Friday = 'FR',
  Saturday = 'SA',
}

export enum Frequency {
  Weekly = 'WEEKLY',
}
