import { createSelector } from '@ngrx/store';
import { selectFeature } from './constants';
import { STATE_KEY, selectEntities, selectIds, selectAll } from './sites.reducer';

export const selectOrgAdminSitesState = createSelector(selectFeature, state => state[STATE_KEY]);

export const selectSiteEntities = createSelector(
  selectOrgAdminSitesState,
  selectEntities,
);

export const selectSiteIds = createSelector(selectOrgAdminSitesState, selectIds);

export const selectAllOrgAdminSites = createSelector(selectOrgAdminSitesState, selectAll);
