import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  inject,
  effect,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeSelectOption } from './tree-select.models';
import { TreeSelectStore } from './tree-select.store';
import { TreeSelectFilterComponent } from './tree-select-filter.component';
import { TreeSelectOptionsComponent } from './tree-select-options.component';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'sui-tree-select',
  template: `
    <div class="treeSelectHeader">
      <h3>{{ title }}</h3>
      <sui-tree-select-filter
        [filterIsExpanded]="store.filterIsExpanded()"
        [filter]="store.filter()"
        (filterChange)="filterChange($event)"
        (filterFocused)="filterFocused($event)"
      />
    </div>
    <sui-tree-select-options
      [filter]="store.filter()"
      [options]="store.combinedList()"
      [hiddenCount]="store.numberRejectedByFilter()"
      (toggleOption)="toggleOption($event)"
      (toggleExpand)="toggleExpand($event)"
    />
  `,
  styles: [
    `
      .treeSelectHeader {
        height: 64px;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 200px;
        background-color: var(--color-background-app-bar);
        border-bottom: 1px solid var(--color-foreground-divider);
      }

      h3 {
        padding-left: 16px;
        font-weight: normal;
      }
    `,
  ],
  providers: [
    TreeSelectStore,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TreeSelectComponent,
    },
  ],
  standalone: true,
  imports: [TreeSelectFilterComponent, TreeSelectOptionsComponent],
})
export class TreeSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  readonly store = inject(TreeSelectStore);

  filterChanges = new Subject<string>();

  initialized = false;

  @Input() title = '';
  @Input() options: TreeSelectOption[] = [];

  @Output() changed = new EventEmitter<string[]>();
  @Output() optionSelected = new EventEmitter<string>();
  @Output() optionDeselected = new EventEmitter<string>();

  onChangeFn: (value: string[]) => void = () => void 0;
  onTouchedFn: () => void = () => void 0;

  triggerUpdate() {
    this.store.setOptions(this.options);
  }

  toggleOption(option: TreeSelectOption) {
    this.store.toggleOption(option);

    // Option.checked won't have changed in state yet, so negate the logic
    // for which output to call
    if (option.checked) {
      // About to be unchecked
      this.optionDeselected.emit(option.value);
    } else {
      // About to be checked
      this.optionSelected.emit(option.value);
    }
  }

  toggleExpand(option: TreeSelectOption) {
    this.store.toggleExpand(option);
  }

  filterChange(filter: string) {
    this.filterChanges.next(filter);
  }

  filterFocused(filterIsFocused: boolean) {
    this.store.setIsFocused(filterIsFocused);
  }

  constructor() {
    effect(() => {
      this.changed.emit(this.store.checkedValues());
      this.onChangeFn(this.store.checkedValues());
      this.onTouchedFn();
    });
  }

  ngOnInit(): void {
    this.initialized = true;

    this.filterChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filter => {
        this.store.setFilter(filter);
      });

    this.triggerUpdate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialized) return;

    console.assert(!changes.title, 'Tree Select cannot have dynamic title');

    this.triggerUpdate();
  }

  writeValue(value: string[]): void {
    this.triggerUpdate();
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
}
