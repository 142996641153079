import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SceneApplication, SceneStates } from '@spog-ui/shared/models/scenes';

@Component({
  selector: 'scn-apply-scene-button',
  template: `
    <sui-pending-button
      [isPending]="state === 'pending'"
      [isSuccess]="state === 'success'"
      (click)="emitApply($event)"
      [colourVariant]="application === eApplication.CLIMATE ? 'blue' : 'orange'"
    >
      {{ application === eApplication.CLIMATE ? 'Run Now' : 'Apply' }}
    </sui-pending-button>
  `,
})
export class ApplySceneButtonComponent {
  eApplication = SceneApplication;

  @Input() application: SceneApplication = SceneApplication.LIGHTING;
  @Input() state: SceneStates = 'error';
  @Output() apply = new EventEmitter<Event>();

  emitApply($event: any) {
    this.apply.emit($event);
    $event.stopPropagation();
  }
}
