import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppUpdateComponent } from './app-update.component';

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, MatProgressSpinnerModule],
  declarations: [AppUpdateComponent],
  exports: [AppUpdateComponent],
})
export class AppUpdateComponentModule {}
