import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { merge, Observable, ReplaySubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AreaModel, TransformModel, unapplyTransform } from '@spog-ui/map-tools/models';
import { MovementService, StartPosition } from '../../services';
import { MapLayersRef } from '../map-layers/map-layers.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg:g[map-movable-rect]',
  template: `
    <svg:rect
      #rect
      class="area"
      [attr.x]="x"
      [attr.y]="y"
      [attr.width]="width"
      [attr.height]="height"
    />

    <svg:rect class="resizeControlNub" [attr.x]="x - 5" [attr.y]="y - 5"></svg:rect>
    <svg:rect
      class="resizeControlNub"
      [attr.x]="x + width - 5"
      [attr.y]="y - 5"
    ></svg:rect>
    <svg:rect
      class="resizeControlNub"
      [attr.x]="x - 5"
      [attr.y]="y + height - 5"
    ></svg:rect>
    <svg:rect
      class="resizeControlNub"
      [attr.x]="x + width - 5"
      [attr.y]="y + height - 5"
    ></svg:rect>

    <svg:rect
      #resizeControlNW
      class="resizeControl resizeControlNW"
      [attr.x]="x - 17"
      [attr.y]="y - 17"
    ></svg:rect>
    <svg:rect
      #resizeControlNE
      class="resizeControl resizeControlNE"
      [attr.x]="x + width - 17"
      [attr.y]="y - 17"
    ></svg:rect>
    <svg:rect
      #resizeControlSW
      class="resizeControl resizeControlSW"
      [attr.x]="x - 17"
      [attr.y]="y + height - 17"
    ></svg:rect>
    <svg:rect
      #resizeControlSE
      class="resizeControl resizeControlSE"
      [attr.x]="x + width - 17"
      [attr.y]="y + height - 17"
    ></svg:rect>
  `,
  styles: [
    `
      @keyframes marchingAnts {
        0% {
          stroke-dashoffset: 0;
        }
        100% {
          stroke-dashoffset: 16;
        }
      }

      .area {
        fill: var(--color-accent-300);
        fill-opacity: 0.1;
        stroke: var(--color-accent-300);
        stroke-width: 2px;
        animation: marchingAnts 0.3s steps(12) infinite;
        stroke-dasharray: 8;
        cursor: move;
      }

      .resizeControlNub {
        width: 10px;
        height: 10px;
        rx: 10px;
        ry: 10px;
        fill: var(--color-accent-300);
      }

      .resizeControl {
        width: 34px;
        height: 34px;
        fill: rgba(0, 0, 0, 0);
      }

      .resizeControlNE {
        cursor: ne-resize;
      }

      .resizeControlNW {
        cursor: nw-resize;
      }

      .resizeControlSE {
        cursor: se-resize;
      }

      .resizeControlSW {
        cursor: sw-resize;
      }
    `,
  ],
})
export class MovableRectComponent implements AfterViewInit, OnChanges {
  afterViewInit$ = new ReplaySubject<boolean>();

  @Input()
  rect: AreaModel = { x: 0, y: 0, width: 0, height: 0 };
  @Input()
  transform: TransformModel = { k: 1, x: 0, y: 0 };
  @ViewChild('rect', { static: true })
  rectRef: ElementRef<SVGRectElement>;
  @ViewChild('resizeControlNW', { static: true })
  resizeControlNWRef: ElementRef<SVGRectElement>;
  @ViewChild('resizeControlNE', { static: true })
  resizeControlNERef: ElementRef<SVGRectElement>;
  @ViewChild('resizeControlSE', { static: true })
  resizeControlSERef: ElementRef<SVGRectElement>;
  @ViewChild('resizeControlSW', { static: true })
  resizeControlSWRef: ElementRef<SVGRectElement>;

  @Output()
  commitMove = new EventEmitter();

  @Output()
  move: Observable<{ x: number; y: number }> = this.afterViewInit$.pipe(
    mergeMap(() => {
      const svg = this.layersRef.mapLayers.wrapper;

      return this.movement.createMoveBehavior({
        targetElement: this.rectRef.nativeElement,
        drawObserver: {
          onStart: () => void 0,
          onEnd: () => this.commitMove.emit(),
        },
        calculateStartingPosition: event => ({
          startX: event.clientX,
          startY: event.clientY,
        }),
        getTransform: () => this.transform,
        getContainerRect: () => svg.getBoundingClientRect(),
      });
    }),
  );

  @Output()
  commitResize = new EventEmitter();

  @Output() resized: Observable<AreaModel> = this.afterViewInit$.pipe(
    mergeMap(() => {
      const svg = this.layersRef.mapLayers.wrapper;

      const createResizeBehavior = (
        elementRef: ElementRef<SVGRectElement>,
        start: () => StartPosition,
      ) =>
        this.movement.createDragBehavior({
          targetElement: elementRef.nativeElement,
          drawObserver: {
            onStart: () => void 0,
            onEnd: () => this.commitResize.emit(),
          },
          calculateStartingPosition: () => {
            const { startX, startY } = start();
            const { left: offsetX, top: offsetY } = svg.getBoundingClientRect();
            return {
              startX: unapplyTransform('x', startX, this.transform) + offsetX,
              startY: unapplyTransform('y', startY, this.transform) + offsetY,
            };
          },
          getTransform: () => this.transform,
          getContainerRect: () => svg.getBoundingClientRect(),
        });

      return merge(
        createResizeBehavior(this.resizeControlNWRef, () => ({
          startX: this.rect.x + this.rect.width,
          startY: this.rect.y + this.rect.height,
        })),
        createResizeBehavior(this.resizeControlNERef, () => ({
          startX: this.rect.x,
          startY: this.rect.y + this.rect.height,
        })),
        createResizeBehavior(this.resizeControlSWRef, () => ({
          startX: this.rect.x + this.rect.width,
          startY: this.rect.y,
        })),
        createResizeBehavior(this.resizeControlSERef, () => ({
          startX: this.rect.x,
          startY: this.rect.y,
        })),
      );
    }),
  );

  x = 0;
  y = 0;
  width = 0;
  height = 0;
  showRect = false;

  constructor(private movement: MovementService, private layersRef: MapLayersRef) {}

  ngOnChanges() {
    this.x = unapplyTransform('x', this.rect.x, this.transform);
    this.y = unapplyTransform('y', this.rect.y, this.transform);
    this.width = this.rect.width * this.transform.k;
    this.height = this.rect.height * this.transform.k;
    this.showRect = this.width !== 0 && this.height !== 0;
  }

  ngAfterViewInit() {
    this.afterViewInit$.next(true);
  }
}
