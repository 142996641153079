import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { animate, style, transition, trigger } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SuiPanelState } from '../panel';

export const SuiAnimateFab = trigger('suiAnimateFab', [
  transition(':enter', [
    style({ transform: 'translate3d(0,96px,0)' }),
    animate('120ms', style({ transform: 'translate3d(0,0,0)' })),
  ]),
]);

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[suiExtendedFab],a[suiExtendedFab]',
  template: `
    <ng-template #buttonContent>
      <span class="suiFabButtonIcon">
        <i class="material-icons">{{ icon }}</i>
      </span>
      <span class="suiFabButtonContent">
        <ng-content></ng-content>
      </span>
    </ng-template>

    <div class="suiExtendedFabButtonWrapper" *ngIf="isMobile$ | async; else desktop">
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </div>

    <ng-template #desktop>
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--color-accent-contrast-500);
        background-color: var(--color-accent-500);
        height: 48px;
        vertical-align: middle;
        padding: 0 20px 0 12px;
        border-radius: 24px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.24);
        transition: color 160ms, background-color 160ms;
        line-height: initial;
        border-width: 0;
        font-family: inherit;
        font-weight: inherit;
      }

      :host:hover {
        color: var(--color-accent-contrast-400);
        background-color: var(--color-accent-400);
      }

      .suiFabButtonIcon {
        display: inline-block;
        width: 24px;
        height: 24px;
        font-size: 24px;
        vertical-align: middle;
        margin: 0 12px 0 0;
      }

      .suiFabButtonContent {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
      }

      :host(.suiFabFloating) {
        position: fixed;
        z-index: 100;
        right: 24px;
        bottom: 24px;
        transition: right 200ms;
      }

      :host(.suiFabFloating.suiFabPushed) {
        right: 384px;
      }

      @media screen and (max-width: 500px) {
        :host {
          width: calc(100vw - 32px);
          justify-content: center;
          position: fixed;
          z-index: 100;
          left: 16px;
          bottom: 16px;
          transition: bottom 160ms;
        }

        .suiExtendedFabButtonWrapper {
          display: flex;
          align-items: center;
        }
      }
    `,
  ],
})
export class ExtendedFabComponent implements OnDestroy {
  isMobile$: Observable<boolean>;
  isPushedSubscription: Subscription;
  @Input() icon: string;
  @HostBinding('class.suiFabFloating') floating = true;
  @HostBinding('class.suiFabPushed') pushed = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('suiExtendedFabDocked') set isDocked(value: any) {
    this.floating = !coerceBooleanProperty(value);
  }

  constructor(breakpointObserver: BreakpointObserver, panelState: SuiPanelState) {
    this.isMobile$ = breakpointObserver
      .observe('screen and (max-width: 500px)')
      .pipe(map(state => state.matches));

    this.isPushedSubscription = panelState.isLayoutPushed$.subscribe(
      isPushed => (this.pushed = isPushed),
    );
  }

  ngOnDestroy(): void {
    this.isPushedSubscription?.unsubscribe();
  }
}
