import { createReducer, on } from '@ngrx/store';
import {
  LightZoneDetailsAPIActions,
  LightZoneDetailsPageActions,
} from '@spog-ui/light-zone-details/actions';

export interface Shape {
  error?: string;
  isLoading: boolean;
}

export const initialState: Shape = {
  isLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(LightZoneDetailsPageActions.enterAction, () => {
    return { ...initialState };
  }),
  on(LightZoneDetailsAPIActions.loadLightZoneDetailsPageModelsSuccessAction, state => {
    return { ...state, isLoading: false };
  }),
  on(
    LightZoneDetailsAPIActions.loadLightZoneDetailsPageModelsFailureAction,
    (state, { error }) => {
      return { ...state, error, isLoading: false };
    },
  ),
);

export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.isLoading;
