import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as LightingZonesState from './lighting-zones.state';

@NgModule({
  imports: [
    StoreModule.forFeature(LightingZonesState.STATE_KEY, LightingZonesState.reducers),
  ],
})
export class LightingZonesStateModule {}
