import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { BigSelectModule, SuiFormsModule } from '@spog-ui/shared/components';
import {
  DoublyAssignedSensorsValidatorDirective,
  RequiresOneMainValidatorDirective,
  TakenLightsValidatorDirective,
  TakenPowerSensorsValidatorDirective,
  UtilityServiceFormComponent,
} from './utility-service-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    SuiFormsModule,
    BigSelectModule,
  ],
  declarations: [
    UtilityServiceFormComponent,
    RequiresOneMainValidatorDirective,
    TakenPowerSensorsValidatorDirective,
    TakenLightsValidatorDirective,
    DoublyAssignedSensorsValidatorDirective,
  ],
  exports: [UtilityServiceFormComponent],
})
export class UtilityServiceFormComponentModule {}
