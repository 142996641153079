import { createAction } from '@ngrx/store';
import { ZoneFormModel } from '@spog-ui/shared/models/zones';

export const saveAction = createAction(
  '[Zone Control] Save',
  (intendedZoneBehavior: ZoneFormModel, originalZoneBehavior: ZoneFormModel) => {
    return { intendedZoneBehavior, originalZoneBehavior };
  },
);
export const cancelAction = createAction('[Zone Control] Cancel');
