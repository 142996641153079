import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  map,
  catchError,
  withLatestFrom,
  exhaustMap,
  tap,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import {
  OrgAdminUsersPageActions,
  OrgAdminUsersApiActions,
  OrgAdminUsersStateActions,
  OrgAdminAddUserPageActions,
  OrgAdminEditUserPageActions,
} from '../actions';
import { OrganizationUsersApiService } from '../services';
import { Store } from '@ngrx/store';
import * as OrganizationsState from '@spog-ui/shared/state/organizations';
import { PromptService } from '@sui/prompt';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Injectable()
export class OrganizationUserApiEffects {
  constructor(
    private actions$: Actions,
    readonly organizationUsersApiService: OrganizationUsersApiService,
    private store: Store,
    readonly prompt: PromptService,
    readonly snackbar: MatSnackBar,
    readonly location: Location,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminUsersPageActions.enterAction),
      withLatestFrom(
        defer(() => {
          return this.store.select(OrganizationsState.selectActiveOrgId);
        }),
      ),
      exhaustMap(([, activeOrgId]) => {
        if (!activeOrgId) {
          throw new Error('Active organization id not found!');
        }
        return this.organizationUsersApiService.getUsersByOrganization(activeOrgId).pipe(
          map(users => OrgAdminUsersApiActions.loadPageSuccessAction(users)),
          catchError((response: HttpErrorResponse) =>
            of(
              OrgAdminUsersApiActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        );
      }),
    ),
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminUsersApiActions.loadPageSuccessAction),
      map(action => {
        return OrgAdminUsersStateActions.loadUsersSuccessAction(action.users);
      }),
    ),
  );

  removeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminUsersPageActions.removeOrganizationUserAction),
      withLatestFrom(
        defer(() => {
          return this.store.select(OrganizationsState.selectActiveOrgId);
        }),
      ),
      exhaustMap(([action, activeOrgId]) => {
        if (!activeOrgId) {
          throw new Error('Active organization id not found!');
        }
        return this.organizationUsersApiService
          .removeOrganizationUser(activeOrgId, action.user.id)
          .pipe(
            map(updatedOrganization =>
              OrgAdminUsersStateActions.removeOrganizationUserSuccessAction(
                action.user.id,
              ),
            ),
            catchError((response: HttpErrorResponse) =>
              of(
                OrgAdminUsersApiActions.removeOrganizationUserFailureAction(
                  retrieveErrorMessage(response),
                ),
              ),
            ),
          );
      }),
    ),
  );

  notifyOnRemoveUserError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgAdminUsersApiActions.removeOrganizationUserFailureAction),
        tap(() =>
          this.snackbar.open(
            'Failed to remove the user from organization. Please try again',
            'Dismiss',
            {
              duration: 7_500,
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminAddUserPageActions.addUserAction),
      withLatestFrom(
        defer(() => this.store.select(OrganizationsState.selectActiveOrgId)),
      ),
      mergeMap(([action, activeOrgId]) => {
        return this.organizationUsersApiService
          .inviteOrganizationUser(
            activeOrgId || '',
            action.User.email,
            action.User.organizationRole,
          )
          .pipe(
            map(user => OrgAdminUsersApiActions.addUserSuccessAction(user)),
            catchError(error => {
              return of(OrgAdminUsersApiActions.addUserFailureAction(error));
            }),
          );
      }),
    ),
  );

  redirectToUsersPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OrgAdminUsersApiActions.addUserSuccessAction,
          OrgAdminUsersApiActions.updateUserSuccessAction,
        ),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  addUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgAdminUsersApiActions.addUserFailureAction),
        tap(action => {
          const message = retrieveErrorMessage(action.error);
          this.snackbar.open(message, 'Dismiss', {});
        }),
      ),
    { dispatch: false },
  );

  editUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminEditUserPageActions.editUserAction),
      withLatestFrom(
        defer(() => this.store.select(OrganizationsState.selectActiveOrgId)),
      ),
      switchMap(([action, activeOrgId]) => {
        return this.organizationUsersApiService
          .updateOrganizationUser(
            activeOrgId || '',
            action.user.id,
            action.user.name,
            action.user.organizationRole,
          )
          .pipe(
            map(user => OrgAdminUsersApiActions.updateUserSuccessAction(user)),
            catchError(error => {
              return of(OrgAdminUsersApiActions.updateUserFailureAction(error));
            }),
          );
      }),
    ),
  );

  editUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgAdminUsersApiActions.updateUserFailureAction),
        tap(action => {
          const message = retrieveErrorMessage(action.error);
          this.snackbar.open(message, 'Dismiss', {});
        }),
      ),
    { dispatch: false },
  );
}
