import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-random-monochrome',
  template: `
    <div>
      <p class="bfDescription">All lights in the zone cycle through random levels.</p>
    </div>
  `,
})
export class BFRandomMonochromeComponent implements BehaviorForm {
  form = new UntypedFormControl(null);
}
