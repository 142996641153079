import { createAction } from '@ngrx/store';
import { ZoneFormModel } from '@spog-ui/shared/models/zones';

export const saveBehaviorSuccessAction = createAction(
  '[Zone Control API] Save Behavior Success',
);
export const saveBehaviorFailureAction = createAction(
  '[Zone Control API] Save Behavior Failure',
  (
    intendedZoneBehavior: ZoneFormModel,
    originalZoneBehavior: ZoneFormModel,
    error?: any,
  ) => {
    return { intendedZoneBehavior, originalZoneBehavior, error };
  },
);
