import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UtilityServicesPageActions } from '@spog-ui/utility-services/actions';
import { SuiAnimateFab } from '@spog-ui/shared/components';
import * as EnergyInfoSystemState from '@spog-ui/shared/state/energy-info-system';
import * as CoreState from '@spog-ui/shared/state/core';
import { UtilityServiceViewModel } from '@spog-ui/shared/models/utility-services';

@Component({
  selector: 'spog-utility-services-list-page',
  template: `
    @if (isLoading$ | async) {
      <sui-spinner></sui-spinner>
    } @else {
      @if (error$ | async) {
        <spog-utility-service-loading-error
        ></spog-utility-service-loading-error>
      } @else {
        @if (showGettingStartedCard$ | async) {
          <spog-no-utility-services
          ></spog-no-utility-services>
        } @else {
          <spog-utility-service-list
            [utilityServices]="utilityServices$ | async"
          ></spog-utility-service-list>
          <a
            routerLink="./add"
            suiExtendedFab
            @suiAnimateFab
            color="accent"
            icon="add"
            *suiIfUserCan="['ssc:siteAdmin']"
            >
            Create Utility Service
          </a>
        }
      }
    }
    
    
    
    `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 24px 24px 96px;
        justify-content: space-between;
      }

      sui-spinner {
        width: 50px;
      }

      spog-utility-service-list {
        width: 100%;
      }
    `,
  ],
  animations: [SuiAnimateFab],
})
export class UtilityServicesPageComponent {
  isLoading$: Observable<boolean>;
  error$: Observable<any>;
  showGettingStartedCard$: Observable<boolean>;
  utilityServices$: Observable<UtilityServiceViewModel[]>;

  constructor(readonly store: Store) {
    this.isLoading$ = this.store.select(
      EnergyInfoSystemState.selectUtilityServicesPageIsLoading,
    );
    this.error$ = this.store.select(EnergyInfoSystemState.selectUtilityServicesPageError);
    this.showGettingStartedCard$ = this.store.select(
      EnergyInfoSystemState.selectUtilityServicesPageShouldShowGettingStartedCard,
    );
    this.utilityServices$ = this.store.select(CoreState.selectAllUtilityServiceViews);
  }

  ngOnInit() {
    this.store.dispatch(UtilityServicesPageActions.enterAction());
  }
}
