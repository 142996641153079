import { createReducer, on } from '@ngrx/store';
import {
  DepartmentsApiActions,
  DepartmentsPageActions,
} from '@spog-ui/departments/actions';

export interface Shape {
  isLoading: boolean;
  searchTerm: string;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
  searchTerm: '',
};

export const reducer = createReducer(
  initialState,
  on(
    DepartmentsPageActions.enterAction,
    (state): Shape => {
      return { ...initialState, searchTerm: state.searchTerm };
    },
  ),
  on(
    DepartmentsApiActions.loadSuccessAction,
    (state): Shape => {
      return { ...state, isLoading: false };
    },
  ),
  on(DepartmentsApiActions.loadFailureAction, (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  }),
  on(DepartmentsPageActions.searchAction, (state, action) => {
    return {
      ...state,
      searchTerm: action.searchTerm,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectSearchTerm = (state: Shape) => state.searchTerm;
