import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as SenseState from './sense.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(SenseState.STATE_KEY, SenseState.reducers),
  ],
})
export class SenseStateModule {}
