import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { SiteService } from '../site';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { selectSelectedSite } from '@spog-ui/shared/state/core';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

@Injectable({ providedIn: 'root' })
export class GoHomeGuardService implements CanActivate {
  constructor(
    readonly site: SiteService,
    readonly router: Router,
    readonly store: Store,
  ) {}

  canActivate(): Observable<UrlTree> {
    return this.store.select(selectSelectedSite).pipe(
      filter((selectedSite): selectedSite is SiteInternalModel => selectedSite !== null),
      take(1),
      map(selectedSite => {
        return this.router.parseUrl(this.site.getHomePage(selectedSite.id ?? ''));
      }),
    );
  }
}
