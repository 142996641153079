import { createAction } from '@ngrx/store';

export const activateFileUploadAction = createAction(
  '[Add Floor Plan] Activate File Upload',
);
export const activateCreateFromMapAction = createAction(
  '[Add Floor Plan] Activate Create from Map',
);
export const openAction = createAction('[Add Floor Plan] Open');
export const saveAction = createAction(
  '[Add Floor Plan] Save',
  (name: string, image: string) => {
    return { name, image };
  },
);
export const saveMapAction = createAction(
  '[Add Floor Plan] Save Floor Plan from Map',
  (bounds: string, useDark: boolean) => {
    return { bounds, useDark };
  },
);
export const closeProgressDialogAction = createAction(
  '[Add Floor Plan] Close Progress Dialog',
);
export const dismissAlertAction = createAction('[Add Floor Plan] Dismiss Alert');

export type Union =
  | ReturnType<typeof activateFileUploadAction>
  | ReturnType<typeof activateCreateFromMapAction>
  | ReturnType<typeof openAction>
  | ReturnType<typeof saveAction>
  | ReturnType<typeof saveMapAction>
  | ReturnType<typeof closeProgressDialogAction>
  | ReturnType<typeof dismissAlertAction>;

export const Types = {
  ActivateFileUpload: activateFileUploadAction.type,
  ActivateCreateFromMap: activateCreateFromMapAction.type,
  Open: openAction.type,
  Save: saveAction.type,
  SaveMap: saveMapAction.type,
  CloseProgressDialog: closeProgressDialogAction.type,
  DismissAlert: dismissAlertAction.type,
};
