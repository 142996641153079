import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CreatableHardwareType } from '@spog-ui/shared/models/indie-sensors';

@Component({
  selector: 'spog-hardware-type-selector',
  template: `
    <sui-card-select [formControl]="hardwareType">
      <sui-card-select-label>
        What kind of hardware are you configuring?
      </sui-card-select-label>
      <sui-card-select-option [value]="CreatableHardwareTypes.Sense420Sensor">
        <mat-icon>settings_input_antenna</mat-icon>
        <sui-card-select-option-label> Sense 4-20 </sui-card-select-option-label>
        <sui-card-select-option-hint>
          4-20 mA. Single-stream.
        </sui-card-select-option-hint>
      </sui-card-select-option>
      <sui-card-select-option [value]="CreatableHardwareTypes.Bridge485Sensor">
        <mat-icon>library_books</mat-icon>
        <sui-card-select-option-label>Bridge 485</sui-card-select-option-label>
        <sui-card-select-option-hint> Modbus. Multi-stream. </sui-card-select-option-hint>
      </sui-card-select-option>
    </sui-card-select>

    <div class="actions">
      <button
        data-test-id="next-button"
        mat-raised-button
        color="accent"
        type="button"
        (click)="onClick()"
      >
        Next
      </button>
      <button data-test-id="cancel-button" mat-button type="button" (click)="onCancel()">
        Cancel
      </button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        max-width: 420px;
        margin: 0 auto;

        padding: 8px;
        padding-top: 16px;
      }

      .actions {
        display: flex;
        flex-direction: row-reverse;
        justify-content: end;
        gap: 8px;

        margin-top: 16px;
      }
    `,
  ],
})
export class HardwareTypeSelectorComponent {
  CreatableHardwareTypes: {
    [key: string]: CreatableHardwareType;
  } = {
    Sense420Sensor: 'Sense420Sensor',
    Bridge485Sensor: 'Bridge485Metric',
  };

  hardwareType = new UntypedFormControl();

  @Output() select = new EventEmitter<CreatableHardwareType>();
  @Output() cancel = new EventEmitter();

  onClick() {
    const hardwareType = this.hardwareType.value;

    if (hardwareType !== null) {
      this.select.emit(hardwareType);
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}
