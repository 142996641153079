import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { Sense420FormComponentModule } from '../sense-420-form';
import { EditIndieSensorPageComponent } from './edit-indie-sensor-page.component';

@NgModule({
  imports: [CommonModule, SuiSpinnerModule, Sense420FormComponentModule],
  declarations: [EditIndieSensorPageComponent],
})
export class EditIndieSensorPageComponentModule {}
