import {
  Component,
  EventEmitter,
  Input,
  Output,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { ScheduledUtilityRateViewModel } from '@spog-ui/shared/models/scheduled-utility-rates';

@Component({
  selector: 'spog-scheduled-utility-rate',
  template: `
    <mat-icon class="utilityRateIcon">event</mat-icon>
    @if (utilityRate.flatEnergyUtilityRate) {
      <h4>
        Flat Utility Rate
        <span class="flatUtilityRateDescription">
          ({{ utilityRate.flatEnergyUtilityRate.costOfEnergy }} per kWh,
          {{ utilityRate.flatEnergyUtilityRate.costOfDemand }} per kW)
        </span>
      </h4>
    }
    @if (utilityRate.openEIUtilityRate) {
      <h4>
        {{ utilityRate.openEIUtilityRate.rateName }}
      </h4>
    }
    <span class="effectiveDate">
      Effective {{ utilityRate | spogUtilityRateStartDate }}
    </span>
    <span class="spacer">&middot;</span>
    @if (utilityRate.flatEnergyUtilityRate) {
      <span class="companyName"> Custom </span>
    }
    @if (utilityRate.openEIUtilityRate) {
      <span class="companyName">
        {{ utilityRate.openEIUtilityRate.companyName }}
      </span>
    }
    <mat-menu #utilityRateMenu="matMenu">
      <button mat-menu-item data-test-id="delete" (click)="delete.emit(utilityRate)">
        Delete
      </button>
    </mat-menu>
    <button
      class="utilityRateMenuButton"
      mat-icon-button
      [matMenuTriggerFor]="utilityRateMenu"
      >
      <mat-icon>more_vert</mat-icon>
    </button>
    `,
  styles: [
    `
      :host {
        display: grid;
        padding: 16px;
        grid-template-areas:
          'icon name name name menuButton'
          'icon effectiveDate spacer companyName menuButton';
        grid-template-columns: 48px auto 24px 1fr 32px;
      }

      .utilityRateIcon {
        grid-area: icon;
        align-self: center;
        position: relative;
        left: 3px;
      }

      h4 {
        grid-area: name;
        margin: 0;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      .flatUtilityRateDescription {
        font-style: italic;
        color: var(--color-foreground-secondary-text);
      }

      .effectiveDate {
        grid-area: effectiveDate;
        font-size: 12px;
        color: var(--color-foreground-secondary-text);
      }

      .spacer {
        font-size: 12px;
        color: var(--color-foreground-secondary-text);
        text-align: center;
      }

      .companyName {
        grid-area: companyName;
        font-size: 12px;
        color: var(--color-foreground-secondary-text);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .utilityRateMenuButton {
        grid-area: menuButton;
        justify-self: flex-end;
      }
    `,
  ],
})
export class ScheduledUtilityRateComponent {
  @Input('scheduledUtilityRate') utilityRate: ScheduledUtilityRateViewModel;
  @Output() edit = new EventEmitter<ScheduledUtilityRateViewModel>();
  @Output() delete = new EventEmitter<ScheduledUtilityRateViewModel>();
}

@Pipe({
  name: 'spogUtilityRateStartDate',
  pure: true,
})
export class UtilityRateStartDatePipe implements PipeTransform {
  transform(utilityRate: ScheduledUtilityRateViewModel): string {
    return utilityRate.startDate.toLocaleString();
  }
}
