import { createReducer, createSelector, on } from '@ngrx/store';
import * as BigSelectActions from './big-select.actions';
import {
  BigSelectList,
  BigSelectOption,
  BigSelectOptionView,
  BigSelectTab,
} from './big-select.models';

export interface Shape {
  filter: string;
  activeListTitle: string | null;
  lists: BigSelectList[];
  filterIsFocused: boolean;
}

const sortListByOrder = (a: BigSelectList, b: BigSelectList) =>
  a.order < b.order ? -1 : 1;

/**
 * Selectors
 */
export const selectFilter = (state: Shape) => state.filter;
export const selectActiveListTitle = (state: Shape) => state.activeListTitle;
export const selectLists = (state: Shape) => state.lists;
export const selectFilterIsFocused = (state: Shape) => state.filterIsFocused;

export const selectOrderedLists = (state: Shape) => state.lists.sort(sortListByOrder);

export const selectActiveList = createSelector(
  selectActiveListTitle,
  selectOrderedLists,
  (activeListTitle, lists) => {
    return lists.find(list => list.title === activeListTitle);
  },
);
export const selectActiveOptions = createSelector(
  selectActiveList,
  (list): BigSelectOption[] => {
    if (!list) return [];

    return list.options;
  },
);
export const selectActiveOptionViews = createSelector(
  selectActiveOptions,
  selectActiveList,
  (filteredOptions, activeList): BigSelectOptionView[] => {
    if (!activeList) return [];

    const checkedList: BigSelectOptionView[] = [];
    const uncheckedList: BigSelectOptionView[] = [];

    filteredOptions.forEach(option => {
      const optionView: BigSelectOptionView = {
        ...option,
        checked: activeList.value.has(option.value),
        disabled: activeList.disabled,
      };

      if (optionView.checked) {
        checkedList.push(optionView);
      } else {
        uncheckedList.push(optionView);
      }
    });

    return [...checkedList, ...uncheckedList];
  },
);
export const selectOptionViewsBisectedByFilter = createSelector(
  selectActiveOptionViews,
  selectFilter,
  (activeOptions, originalFilter) => {
    const filter = originalFilter.toLowerCase();

    return activeOptions.reduce(
      (result, option) => {
        const matches = option.name.toLowerCase().includes(filter);

        if (matches) {
          result.match.push(option);
        } else {
          result.nomatch.push(option);
        }

        return result;
      },
      { match: [] as BigSelectOptionView[], nomatch: [] as BigSelectOptionView[] },
    );
  },
);
export const selectFilteredOptionViews = createSelector(
  selectOptionViewsBisectedByFilter,
  optionsBisectedByFilter => {
    return optionsBisectedByFilter.match;
  },
);
export const selectOptionViewsRejectedByFilter = createSelector(
  selectOptionViewsBisectedByFilter,
  optionsBisectedByFilter => {
    return optionsBisectedByFilter.nomatch;
  },
);
export const selectFilteredCheckedOptionViews = createSelector(
  selectFilteredOptionViews,
  (filteredOptions): BigSelectOptionView[] => {
    return filteredOptions.filter(option => option.checked);
  },
);
export const selectGlobalCheckboxIsIndeterminate = createSelector(
  selectFilteredOptionViews,
  selectFilteredCheckedOptionViews,
  (filteredOptions, filteredCheckedOptions) => {
    return (
      filteredCheckedOptions.length > 0 &&
      filteredOptions.length !== filteredCheckedOptions.length
    );
  },
);
export const selectGlobalCheckboxIsChecked = createSelector(
  selectFilteredOptionViews,
  selectFilteredCheckedOptionViews,
  (filteredOptions, filteredCheckedOptions) => {
    return (
      filteredCheckedOptions.length > 0 &&
      filteredOptions.length === filteredCheckedOptions.length
    );
  },
);
export const selectGlobalCheckboxIsDisabled = createSelector(
  selectFilteredOptionViews,
  selectActiveList,
  (filteredOptions, activeList) => {
    return filteredOptions.length === 0 || (activeList && activeList.disabled);
  },
);
export const selectNumberOfCheckedOptionsRejectedByFilter = createSelector(
  selectOptionViewsRejectedByFilter,
  optionsRejectedByFilter => {
    return optionsRejectedByFilter.reduce((count, option) => {
      return option.checked ? count + 1 : count;
    }, 0);
  },
);
export const selectTabs = createSelector(
  selectActiveListTitle,
  selectOrderedLists,
  (activeListTitle, lists): BigSelectTab[] => {
    return lists.map((list): BigSelectTab => {
      return {
        title: list.title,
        active: list.title === activeListTitle,
        totalChecked: list.value.size,
      };
    });
  },
);
export const selectFilterIsExpanded = createSelector(
  selectFilter,
  selectFilterIsFocused,
  (filter, filterIsFocused) => {
    return filter.length > 0 || filterIsFocused;
  },
);

/**
 * Reducer
 */
export const initialState: Shape = {
  filter: '',
  activeListTitle: null,
  lists: [],
  filterIsFocused: false,
};

export const reducer = createReducer(
  initialState,
  on(BigSelectActions.addListAction, (state, action): Shape => {
    const lists = [...state.lists, action.list];

    const activeListTitle = lists.sort(sortListByOrder)[0].title;

    return {
      ...state,
      activeListTitle,
      lists,
    };
  }),
  on(BigSelectActions.updateListAction, (state, action): Shape => {
    return {
      ...state,
      lists: state.lists.map(list => {
        if (list.title === action.list.title) {
          return action.list;
        }

        return list;
      }),
    };
  }),
  on(BigSelectActions.removeListAction, (state, action): Shape => {
    return {
      ...state,
      lists: state.lists.filter(list => {
        return list.title !== action.listTitle;
      }),
    };
  }),
  on(BigSelectActions.toggleOptionAction, (state, action): Shape => {
    return {
      ...state,
      lists: state.lists.map(list => {
        if (list.title !== state.activeListTitle) return list;
        if (list.disabled) return list;

        const value = new Set(list.value);

        if (value.has(action.option.value)) {
          value.delete(action.option.value);
        } else {
          value.add(action.option.value);
        }

        return {
          ...list,
          value,
        };
      }),
    };
  }),
  on(BigSelectActions.filterAction, (state, action): Shape => {
    return {
      ...state,
      filter: action.filter,
    };
  }),
  on(BigSelectActions.selectTabAction, (state, action): Shape => {
    return {
      ...state,
      activeListTitle: action.tab.title,
    };
  }),
  on(BigSelectActions.checkAllAction, (state): Shape => {
    return {
      ...state,
      lists: state.lists.map(list => {
        if (list.title !== state.activeListTitle) return list;
        if (list.disabled) return list;

        const value = new Set(list.value);

        /**
         * When indeterminate, uncheck all visible options
         */
        if (selectGlobalCheckboxIsIndeterminate(state)) {
          selectFilteredOptionViews(state).forEach(option => {
            value.delete(option.value);
          });
        } else if (selectGlobalCheckboxIsChecked(state)) {
          /**
           * When checked, uncheck all visible options
           */
          selectFilteredOptionViews(state).forEach(option => {
            value.delete(option.value);
          });
        } else {
          /**
           * When unchecked, check all visible options
           */
          selectFilteredOptionViews(state).forEach(option => {
            value.add(option.value);
          });
        }

        return {
          ...list,
          value,
        };
      }),
    };
  }),
  on(BigSelectActions.focusFilterAction, (state): Shape => {
    return {
      ...state,
      filterIsFocused: true,
    };
  }),
  on(BigSelectActions.blurFilterAction, (state): Shape => {
    return {
      ...state,
      filterIsFocused: false,
    };
  }),
);
