import {
  CreateSiteControllerInput,
  MoveSiteControllerInput,
  SiteControllerModelFragment,
  SiteControllerResourceStatus,
  SiteControllerResourceType,
  UpdateSiteControllerInput,
} from '@spog-ui/graphql/types';
import { SiteControllerAdminUpsert as SiteControllerWSModel } from '@spog-shared/events/site-controller';
import * as semver from 'semver';
import { Products } from '@spog-shared/graphql-enums';

export { SiteControllerWSModel };

export type SiteControllerGQLModel = SiteControllerModelFragment & {
  tunnelPort?: number | null;
};
export const COLOR_LICENSE_NAME = 'Color';
const MINIMUM_VERSION = '7.5.0';

export interface SiteControllerResourceInternalModel {
  type: keyof typeof SiteControllerResourceType;
  status: keyof typeof SiteControllerResourceStatus;
  lastUpdateReceived?: string | null;
}

export interface SiteControllerInternalModel {
  id: string;
  name: string;
  macAddress: string;
  siteId?: string | null;
  uiProxyUrl?: string | null;
  secretKey?: string | null;
  systemVersion?: string | null;
  activeLicenses: string[];
  resources: SiteControllerResourceInternalModel[];
  tunnelPort?: number | null;
}

export interface SiteControllerViewModel {
  id: string;
  name: string;
  macAddress: string;
  siteId?: string | null;
  uiProxyUrl?: string | null;
  secretKey?: string | null;
  systemVersion?: string | null;
  activeLicenses: string[];
  resources: SiteControllerResourceInternalModel[];
  showStatus: boolean;
  status: SiteControllerStatus;
  statusClass: string;
  statusIcon: string;
  tunnelPort?: number | null;
}

function getStatusClass(status: string): string {
  return ['ONLINE', 'PENDING'].includes(status)
    ? 'onlineStatus'
    : ['DEGRADED', 'IN PROGRESS'].includes(status)
    ? 'pendingStatus'
    : ['OFFLINE'].includes(status)
    ? 'degradedStatus'
    : 'unknownStatus';
}

function getStatusIcon(status: string): string {
  return status === 'PENDING'
    ? 'update'
    : status === 'DEGRADED'
    ? 'sync_problem'
    : status === 'OFFLINE'
    ? 'close'
    : '';
}

/**
 * Show the statuses of a site controller's system resources when the
 * systemVersion is "development", "unknown", or a parseable system
 * version number that's greater than some MINIMUM_VERSION (i.e. "7.5.0").
 */
export const showSiteControllerStatus = (internal: SiteControllerInternalModel) => {
  if (internal.systemVersion === 'development' || internal.systemVersion === 'unknown') {
    return true;
  }
  const systemVersion = semver.coerce(internal.systemVersion);
  return semver.valid(systemVersion) && systemVersion !== null
    ? semver.gt(systemVersion, MINIMUM_VERSION)
    : false;
};

export function isRemoteAccessOnly(site: any | null | undefined) {
  if (!site) {
    return true;
  }

  const hasRemoteAccess = site.enabledProducts.includes(Products.REMOTE_ACCESS);

  const hasOtherUIFeatures = (site.enabledProducts as Products[]).some(product =>
    [
      Products.CLIMATE,
      Products.ILLUMINATE,
      Products.ILLUMINATE_POWER,
      Products.SENSE,
      Products.SENSE_POWER,
    ].includes(product),
  );

  return hasRemoteAccess && !hasOtherUIFeatures;
}

export function toSiteControllerViewModelFromInternal(
  internal: SiteControllerInternalModel,
  site: any | null | undefined,
): SiteControllerViewModel {
  const remoteAccessOnly = isRemoteAccessOnly(site);
  const resources = internal.resources.filter(
    resource => !remoteAccessOnly || resource.type === 'REMOTE_ACCESS',
  );
  const status = getSiteControllerStatus(resources);

  return {
    id: internal.id,
    name: internal.name ?? '',
    status,
    systemVersion: internal.systemVersion ?? 'UNKNOWN',
    macAddress: internal.macAddress ?? 'UNKNOWN',
    resources,
    siteId: internal.siteId,
    uiProxyUrl: internal.uiProxyUrl,
    secretKey: internal.secretKey,
    activeLicenses: internal.activeLicenses,
    statusClass: getStatusClass(status),
    statusIcon: getStatusIcon(status),
    showStatus: showSiteControllerStatus(internal),
    ...(internal.tunnelPort ? { tunnelPort: internal.tunnelPort } : {}),
  };
}

export function toSiteControllerInternalModelFromGQL(
  gql: SiteControllerGQLModel,
): SiteControllerInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    macAddress: gql.macAddress,
    siteId: gql.site?.id,
    uiProxyUrl: gql.remoteAccess?.uiProxyUrl,
    secretKey: gql.remoteAccess?.secretKey,
    systemVersion: gql.systemVersion,
    activeLicenses: gql.activeLicenses,
    resources: gql.resources,
    ...(gql.tunnelPort ? { tunnelPort: gql.tunnelPort } : {}),
  };
}

export interface SiteControllerFormModel {
  id: string;
  name: string;
  macAddress: string;
  siteId: string | null;
  importOnPremScenes: boolean;
  importOnPremScheduledEvents: boolean;
}

export function toSiteControllerFormModel(
  siteController: SiteControllerInternalModel,
): SiteControllerFormModel {
  return {
    id: siteController.id,
    name: siteController.name,
    macAddress: siteController.macAddress,
    siteId: siteController.siteId ?? null,
    importOnPremScenes: false,
    importOnPremScheduledEvents: false,
  };
}

export type CreateSiteControllerFormModel = Omit<SiteControllerFormModel, 'id'>;

export function toCreateSiteControllerInput(
  form: CreateSiteControllerFormModel,
): CreateSiteControllerInput {
  return form;
}

export function toUpdateSiteControllerInput(
  form: SiteControllerFormModel,
): UpdateSiteControllerInput {
  return {
    id: form.id,
    ...(form.name ? { name: form.name } : {}),
    ...(form.siteId ? { siteId: form.siteId } : {}),
  };
}

export function toMoveSiteControllerInput(
  siteControllerId: string,
  siteId: string,
): MoveSiteControllerInput {
  return {
    id: siteControllerId,
    siteId: siteId,
  };
}

export function toSiteControllerInternalModelFromWS(
  ws: SiteControllerWSModel,
): SiteControllerInternalModel {
  return {
    id: ws.id,
    name: ws.name,
    macAddress: ws.macAddress,
    siteId: ws.siteId,
    systemVersion: ws.systemVersion,
    // TODO: once we determine how live updates can work for activeLicenses, change this transform accordingly
    activeLicenses: [],
    uiProxyUrl: ws.remoteAccess?.uiProxyUrl,
    secretKey: ws.remoteAccess?.secretKey,
    resources: ws.resources,
  };
}

export function getSiteControllerResourceStatus(
  siteController: SiteControllerInternalModel,
  type: keyof typeof SiteControllerResourceType,
) {
  let resource;
  if (siteController.resources) {
    resource = siteController.resources.find(rs => rs.type === type);
  }

  return resource ? resource.status : SiteControllerResourceStatus.UNKNOWN;
}

export type SiteControllerStatus =
  | 'OFFLINE'
  | 'DEGRADED'
  | 'IN PROGRESS'
  | 'PENDING'
  | 'ONLINE'
  | 'UNKNOWN';

export function getSiteControllerStatus(
  resources: SiteControllerResourceInternalModel[],
): SiteControllerStatus {
  if (!resources) return 'UNKNOWN';

  // Get list of all statuses
  const statusList = resources.map(resource => resource.status);

  // All Unknown?
  if (statusList.every(status => status === 'UNKNOWN')) {
    // This should be rare, and not expected. It means that someone added the SC outside of the normal flow
    return 'UNKNOWN';
  }

  // Are all either DOWN or UNKNOWN, INIT_FAILED?
  if (
    statusList.every(
      status => status === 'DOWN' || status === 'UNKNOWN' || status === 'INIT_FAILED',
    )
  ) {
    return 'OFFLINE';
  }

  // Are only some DOWN or INIT_FAILED?
  if (statusList.some(status => status === 'DOWN' || status === 'INIT_FAILED')) {
    // Some resources are UP, but at least one resource is DOWN or INIT_FAILED
    return 'DEGRADED';
  }

  // Are any PENDING_ACK?
  if (statusList.some(status => status === 'PENDING_ACK')) {
    // Some resources may be UP, but at least one resource is waiting to get the first message from the SC to confirm
    return 'PENDING';
  }

  // Are any IN_PROGRESS?
  if (statusList.some(status => status === 'IN_PROGRESS')) {
    // This means that the SC is currently being initialized
    return 'IN PROGRESS';
  }

  // If none are DOWN, INIT_FAILED, PENDING_ACK or IN_PROGRESS, they must be UP
  return 'ONLINE';
}

export function isSiteControllerHavingConnectivityIssues(
  siteController: SiteControllerViewModel,
) {
  return ['DEGRADED', 'OFFLINE'].includes(siteController.status);
}
