import {
  AddScenePageComponent,
  AddScenePageComponentModule,
  AddScenePageState,
} from './components/add-scene-page';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import {
  AddSequenceScenePageComponentModule,
  AddSequenceScenePageState,
} from './components';

@NgModule({
  exports: [AddScenePageComponentModule],
})
class AddScenesFeatureModule {
  static connect(
    StateAdapterClass: Type<AddScenePageState>,
  ): ModuleWithProviders<AddScenesFeatureModule> {
    return {
      ngModule: AddScenesFeatureModule,
      providers: [
        {
          provide: AddScenePageState,
          useClass: StateAdapterClass,
        },
      ],
    };
  }
}

@NgModule({
  exports: [AddSequenceScenePageComponentModule],
})
class AddSequenceScenesFeatureModule {
  static connect(
    StateAdapterClass: Type<AddSequenceScenePageState>,
  ): ModuleWithProviders<AddSequenceScenesFeatureModule> {
    return {
      ngModule: AddSequenceScenesFeatureModule,
      providers: [
        {
          provide: AddSequenceScenePageState,
          useClass: StateAdapterClass,
        },
      ],
    };
  }
}

export {
  AddScenePageComponent,
  AddScenePageState,
  AddScenesFeatureModule,
  AddSequenceScenePageComponentModule,
  AddSequenceScenePageState,
  AddSequenceScenesFeatureModule,
};
