import { createSelector } from '@ngrx/store';
import { toOrganizationFormModelFromInternal } from '@spog-ui/shared/models/organizations';
import { filterBy } from '@spog-ui/utils/filter';
import { selectOrganizationIdForEdit } from './edit-organization-page.selectors';
import {
  selectOrgsPageFilter,
  selectOrgsPageIndex,
  selectOrgsPageSize,
} from './organizations-page.selectors';
import { selectOrganizationsForView } from './organizations.selectors';

export const selectOrganizationForEdit = createSelector(
  selectOrganizationsForView,
  selectOrganizationIdForEdit,
  (organization, id) => {
    const organizationToEdit = organization.find(e => e.id === id);
    return organizationToEdit
      ? toOrganizationFormModelFromInternal(organizationToEdit)
      : null;
  },
);

export const selectNonTemporaryOrgs = createSelector(selectOrganizationsForView, orgs =>
  orgs.filter(org => !org.temporary),
);

export const selectFilteredOrgs = createSelector(
  selectNonTemporaryOrgs,
  selectOrgsPageFilter,
  (orgs, filter) =>
    orgs.filter(org => {
      const searchTerm = filter.trim().toLowerCase();
      return (
        // Name
        filterBy(searchTerm, org.name.toLowerCase()) ||
        // ID prefixed with 'id:'
        filterBy(searchTerm, org.id.toLowerCase(), 'id:')
      );
    }),
);

export const selectOrgsPageLength = createSelector(
  selectFilteredOrgs,
  orgs => orgs.length,
);

export const selectPaginatedOrgs = createSelector(
  selectFilteredOrgs,
  selectOrgsPageIndex,
  selectOrgsPageSize,
  (organizations, index, size) => organizations.slice(index * size, index * size + size),
);
