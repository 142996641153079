import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-switch-photocell-and-vacancy',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        <strong>Manual On to Override Off with Daylighting</strong> - The switch controls
        the lights in the zone only when photocell detects darkness. Upon vacancy
        detection, two levels of timeout and dimming are provided.
      </p>

      <div class="bfForm">
        <div class="bfChunk">
          <p class="bfLabel">If daylight for a period of:</p>
          <sui-timeout-control
            formControlName="photocellHighTimeout"
          ></sui-timeout-control>

          <p class="bfLabel">Disable switch control and set dimming level to:</p>
          <sui-dimmer-control formControlName="baselineLevel"></sui-dimmer-control>

          <p class="bfLabel">Enable switch if dark for a period of:</p>
          <sui-timeout-control
            formControlName="photocellLowTimeout"
          ></sui-timeout-control>
        </div>

        <div class="bfChunk">
          <p class="bfLabel">When switched on, set dimming level to:</p>
          <sui-dimmer-control formControlName="switchOnLevel"></sui-dimmer-control>

          <p class="bfLabel">When switched off, set dimming level to:</p>
          <sui-dimmer-control formControlName="switchOffLevel"></sui-dimmer-control>
        </div>

        <div class="bfChunk">
          <p class="bfLabel">If vacant for a period of:</p>
          <sui-timeout-control formControlName="vacancyTimeout"></sui-timeout-control>

          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="vacancyLevel"></sui-dimmer-control>
        </div>

        <div class="bfChunk">
          <p class="bfLabel">After an additional vacancy period of:</p>
          <sui-timeout-control
            formControlName="extendedVacancyTimeout"
          ></sui-timeout-control>

          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="extendedVacancyLevel"></sui-dimmer-control>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      sui-dimmer-control {
        display: block;
      }

      sui-dimmer-control:last-child {
        margin-bottom: -13px;
      }

      .bfChunk:last-child sui-dimmer-control:last-child {
        margin-bottom: 0;
      }
    `,
  ],
})
export class BFSwitchPhotocellAndVacancyComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    baselineLevel: new UntypedFormControl(0),
    photocellHighTimeout: new UntypedFormControl(0.5),
    photocellLowTimeout: new UntypedFormControl(0.5),
    switchOnLevel: new UntypedFormControl(80),
    switchOffLevel: new UntypedFormControl(0),
    vacancyTimeout: new UntypedFormControl(30),
    vacancyLevel: new UntypedFormControl(50),
    extendedVacancyTimeout: new UntypedFormControl(15),
    extendedVacancyLevel: new UntypedFormControl(0),
  });
}
