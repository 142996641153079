import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of, forkJoin, defer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { EditEmailGroupPageActions } from '../actions';
import { selectActiveOrgId } from '@spog-ui/shared/state/organizations';
import {
  SitesApiService,
  OrganizationUsersApiService,
  EmailGroupsApiService,
} from '../services/';
import { OrgAdminSitesStateActions, OrgAdminUsersStateActions } from '../actions/';
import { EmailGroupStateActions } from '@spog-ui/shared/state/email-groups';
import { Store } from '@ngrx/store';

@Injectable()
export class EditEmailGroupApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly sitesApiService: SitesApiService,
    readonly organizationUsersApiService: OrganizationUsersApiService,
    readonly emailGroupsApiService: EmailGroupsApiService,
    readonly store: Store,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditEmailGroupPageActions.enterAction),
      withLatestFrom(defer(() => this.store.select(selectActiveOrgId))),
      switchMap(([, activeOrgId]) =>
        forkJoin([
          this.sitesApiService.getSitesByOrganization(activeOrgId!),
          this.organizationUsersApiService.getUsersByOrganization(activeOrgId!),
          this.emailGroupsApiService.getEmailGroupsByOrganization(activeOrgId!),
        ]).pipe(
          map(([sites, users, emailGroups]) =>
            EditEmailGroupPageActions.loadPageSuccessAction(users, sites, emailGroups),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              EditEmailGroupPageActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditEmailGroupPageActions.loadPageSuccessAction),
      map(action => {
        return OrgAdminSitesStateActions.loadSitesSuccessAction(action.sites);
      }),
    ),
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditEmailGroupPageActions.loadPageSuccessAction),
      map(action => {
        return OrgAdminUsersStateActions.loadUsersSuccessAction(action.users);
      }),
    ),
  );

  loadEmailGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditEmailGroupPageActions.loadPageSuccessAction),
      map(action => {
        return EmailGroupStateActions.loadEmailGroupsSuccessAction(action.emailGroups);
      }),
    ),
  );
}
