import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorDescriptionComponentModule } from '../behavior-description';
import { SceneZoneBehaviorsComponent } from './scene-zone-behaviors.component';

@NgModule({
  imports: [CommonModule, BehaviorDescriptionComponentModule],
  declarations: [SceneZoneBehaviorsComponent],
  exports: [SceneZoneBehaviorsComponent],
})
export class SceneZoneBehaviorsComponentModule {}
