import { createReducer, on } from '@ngrx/store';
import {
  ClimateMapPageActions,
  ClimateMapPageApiActions,
} from '@spog-ui/climate-map-layer/actions';
import { ApiErrorModel } from '@spog-ui/shared/models/errors';

export interface Shape {
  error: ApiErrorModel | null;
  loading: boolean;
}

export const initialState: Shape = {
  error: null,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(ClimateMapPageActions.enterAction, (state): Shape => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(ClimateMapPageApiActions.loadClimateMapPageModelsSuccessAction, (state): Shape => {
    return {
      ...state,
      loading: false,
    };
  }),
  on(
    ClimateMapPageApiActions.loadClimateMapPageModelsFailureAction,
    (state, action): Shape => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    },
  ),
);

export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
