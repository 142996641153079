import { NgModule } from '@angular/core';

import { BFSwitchAndOccupancyComponent } from './bf-switch-and-occupancy.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DimmerControlComponent } from '@spog-ui/shared/components';
import { TimeoutControlComponentModule } from '../timeout-control';

@NgModule({
  imports: [ReactiveFormsModule, DimmerControlComponent, TimeoutControlComponentModule],
  declarations: [BFSwitchAndOccupancyComponent],
  exports: [BFSwitchAndOccupancyComponent],
})
export class BFSwitchAndOccupancyComponentModule {}
