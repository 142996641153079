import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as ClimateMapState from './climate-map.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(ClimateMapState.STATE_KEY, ClimateMapState.reducers),
  ],
})
export class ClimateMapStateModule {}
