import { createAction } from '@ngrx/store';

export const saveAction = createAction(
  '[Replace Floor Plan] Save',
  (floorPlanId: string, image: string) => {
    return { floorPlanId, image };
  },
);
export const cancelAction = createAction('[Replace Floor Plan] Cancel');

export type Union = ReturnType<typeof saveAction> | ReturnType<typeof cancelAction>;

export const Types = {
  Save: saveAction.type,
  Cancel: cancelAction.type,
};
