import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromptService } from '@sui/prompt';
import { catchError, concatMap, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';

import { SiteService } from '@spog-ui/shared/services';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  AddUtilityServicePageActions,
  EditUtilityServicePageActions,
  UtilityServicesApiActions,
  UtilityServicesPageActions,
} from '@spog-ui/utility-services/actions';
import { DateTime } from 'luxon';
import { LightInternalModel } from '@spog-ui/shared/models/lights';
import {
  toCreateUtilityServiceInputFromForm,
  toUpdateUtilityServiceInputFromForm,
  UtilityServiceViewModel,
  UtilityServiceGQLModel,
} from '@spog-ui/shared/models/utility-services';
import { ScheduledUtilityRateGQLModel } from '@spog-ui/shared/models/scheduled-utility-rates';

/**
 * Given a UTC timestamp and a time zone, correct the timestamp to the
 * local time zone and then return the start of day (midnight in that
 * local time zone).
 */
function startOfDay(date: DateTime, timeZone: string) {
  return date.setZone(timeZone).startOf('day');
}

function utilityServiceHasStarted(
  utilityService: UtilityServiceViewModel,
  timeZone: string,
) {
  const startDay = startOfDay(DateTime.utc(), timeZone);
  return utilityService.scheduledUtilityRates.some(
    scheduledUtilityRate =>
      startOfDay(scheduledUtilityRate.startDate, timeZone) <= startDay,
  );
}

@Injectable()
export class UtilityServicesApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly siteService: SiteService,
    readonly snackbar: MatSnackBar,
    readonly prompt: PromptService,
    readonly location: Location,
    readonly gql: GraphQLAPIService,
  ) {}

  getUtilityServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilityServicesPageActions.enterAction),
      exhaustMap(() => {
        return this.gql
          .getUtilityServices({
            siteId: this.siteService.getId()!,
          })
          .pipe(
            map(result => {
              console.assert(
                !!result.site,
                'Did not find a site using the active site ID',
              );

              const utilityServices = result.site!.utilityServices;

              return UtilityServicesApiActions.loadSuccessAction(
                utilityServices as (UtilityServiceGQLModel &
                  ScheduledUtilityRateGQLModel)[],
              );
            }),
            catchError(error => {
              return of(UtilityServicesApiActions.loadFailureAction(error));
            }),
          );
      }),
    ),
  );

  getUtilityServicesAndThings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AddUtilityServicePageActions.enterAction,
        EditUtilityServicePageActions.enterAction,
      ),
      exhaustMap(() => {
        return this.gql
          .getUtilityServicesAndThings({
            siteId: this.siteService.getId()!,
          })
          .pipe(
            map(response => {
              console.assert(
                !!response.site,
                'Did not find a site using the active site ID',
              );

              return UtilityServicesApiActions.loadUtilityServicesAndThingsSuccessAction(
                response.site!.utilityServices as (UtilityServiceGQLModel &
                  ScheduledUtilityRateGQLModel)[],

                response.site!.industrialSensors,
                /**
                 * @todo Mike Ryan
                 * @date 6/18/2020
                 *
                 * The autogenerated types from the GraphQL schema don't match the
                 * authored-by-hand model that's used by the rest of the app.
                 * Revisit these "as any"s when that refactoring is complete.
                 */
                response.site!.lights as LightInternalModel[],
              );
            }),
            catchError(error => {
              return of(
                UtilityServicesApiActions.loadUtilityServicesAndThingsFailureAction(
                  error,
                ),
              );
            }),
          );
      }),
    ),
  );

  createUtilityService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddUtilityServicePageActions.addUtilityServiceAction),
      concatMap(action => {
        const input = toCreateUtilityServiceInputFromForm(
          action.utilityService,
          this.siteService.getId()!,
        );

        return this.gql.createUtilityService({ input }).pipe(
          map(() => UtilityServicesApiActions.createUtilityServiceSuccess()),
          catchError(error => {
            return of(UtilityServicesApiActions.createUtilityServiceFailure(error));
          }),
        );
      }),
    ),
  );

  updateUtilityService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditUtilityServicePageActions.editUtilityServiceAction),
      concatMap(action => {
        const input = toUpdateUtilityServiceInputFromForm(action.utilityService);

        return this.gql.updateUtilityService({ input }).pipe(
          map(() => UtilityServicesApiActions.updateUtilityServiceSuccess()),
          catchError(error => {
            return of(UtilityServicesApiActions.updateUtilityServiceFailure(error));
          }),
        );
      }),
    ),
  );

  redirectToUtilityServicesPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UtilityServicesApiActions.createUtilityServiceSuccess,
          UtilityServicesApiActions.updateUtilityServiceSuccess,
        ),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  deleteUtilityService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UtilityServicesApiActions.confirmDeleteUtilityServiceAction,
        UtilityServicesApiActions.retryDeleteDeleteUtilityServiceAction,
      ),
      mergeMap(action =>
        this.gql.deleteUtilityService({ input: { id: action.utilityService.id } }).pipe(
          map(UtilityServicesApiActions.deleteUtilityServiceSuccess),
          catchError((error: any) =>
            of(
              UtilityServicesApiActions.deleteUtilityServiceFailure(
                action.utilityService,
                error,
              ),
            ),
          ),
        ),
      ),
    ),
  );

  createScheduledUtilityRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilityServicesPageActions.saveScheduledUtilityRateAction),
      concatMap(action => {
        return this.gql
          .createScheduledUtilityRate({ input: action.scheduledUtilityRate })
          .pipe(
            map(() => UtilityServicesApiActions.createScheduledUtilityRateSuccess()),
            catchError(error => {
              return of(
                UtilityServicesApiActions.createScheduledUtilityRateFailure(error),
              );
            }),
          );
      }),
    ),
  );

  promptToRetryDeletingUtilityService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilityServicesApiActions.deleteUtilityServiceFailure),
      concatMap(action =>
        this.snackbar
          .open(
            `Failed to delete utility service "${action.utilityService.name}"`,
            'Retry',
            { duration: 10000 },
          )
          .onAction()
          .pipe(
            map(() =>
              UtilityServicesApiActions.retryDeleteDeleteUtilityServiceAction(
                action.utilityService,
              ),
            ),
          ),
      ),
    ),
  );

  confirmUserWantsToDeleteUtilityService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilityServicesPageActions.deleteUtilityServiceAction),
      concatMap(action => {
        const tz = this.siteService.getTimezone();
        if (utilityServiceHasStarted(action.utilityService, tz)) {
          return this.snackbar
            .open('Cannot delete Utility Service with active schedule', 'Dismiss', {
              duration: 7_500,
            })
            .onAction()
            .pipe(
              map(() => UtilityServicesApiActions.cancelDeleteUtilityServiceAction()),
            );
        }
        return this.prompt
          .open({
            title: 'Delete Utility Service?',
            description: `Are you sure you want to delete the "${action.utilityService.name}" Utility Service?`,
            cancelLabel: 'Cancel',
            confirmLabel: 'Delete Utility Service',
            confirmColor: 'warn',
          })
          .pipe(
            map(didConfirm =>
              didConfirm
                ? UtilityServicesApiActions.confirmDeleteUtilityServiceAction(
                    action.utilityService,
                  )
                : UtilityServicesApiActions.cancelDeleteUtilityServiceAction(),
            ),
          );
      }),
    ),
  );

  deleteScheduledUtilityRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilityServicesPageActions.confirmDeleteAction),
      mergeMap(action => {
        return this.gql
          .deleteScheduledUtilityRate({ input: { id: action.scheduledUtilityRate.id } })
          .pipe(
            map(() =>
              UtilityServicesApiActions.deleteScheduledUtilityRateSuccessAction(),
            ),
            catchError(error => {
              return of(
                UtilityServicesApiActions.deleteScheduledUtilityRateFailureAction(error),
              );
            }),
          );
      }),
    ),
  );

  showDeleteScheduledUtilityRateError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilityServicesApiActions.deleteScheduledUtilityRateFailureAction),
        map(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );

  showUpdateUtilityServiceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilityServicesApiActions.updateUtilityServiceFailure),
        map(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );
}
