import { createSelector } from '@ngrx/store';
import { filterBy } from '@spog-ui/utils/filter';
import { selectFeature } from './constants';
import {
  selectError,
  selectFilter,
  selectIndex,
  selectIsLoading,
  selectSize,
  STATE_KEY,
} from './sites-page.reducer';
import { selectAllOrgAdminSites } from './sites.selectors';

export const selectOrgAdminSitesPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsOrgAdminSitesPageLoading = createSelector(
  selectOrgAdminSitesPageFeatureState,
  selectIsLoading,
);
export const selectOrgAdminSitesPageError = createSelector(
  selectOrgAdminSitesPageFeatureState,
  selectError,
);
export const selectOrgAdminSitesPageFilter = createSelector(
  selectOrgAdminSitesPageFeatureState,
  selectFilter,
);
export const selectOrgAdminSitesPageIndex = createSelector(
  selectOrgAdminSitesPageFeatureState,
  selectIndex,
);
export const selectOrgAdminSitesPageSize = createSelector(
  selectOrgAdminSitesPageFeatureState,
  selectSize,
);

export const selectOrgAdminSitesShowGettingStartedCard = createSelector(
  selectAllOrgAdminSites,
  sites => sites.length === 0,
);

export const selectFilteredOrgAdminSites = createSelector(
  selectAllOrgAdminSites,
  selectOrgAdminSitesPageFilter,
  (sites, filter) =>
    sites.filter(site => {
      const searchTerm = filter.trim().toLowerCase();
      return (
        // Name
        filterBy(searchTerm, site.name.toLowerCase()) ||
        // ID prefixed with 'id:'
        filterBy(searchTerm, site.id.toLowerCase(), 'id:')
      );
    }),
);
export const selectOrgAdminSitesPageLength = createSelector(
  selectFilteredOrgAdminSites,
  sites => sites.length,
);

export const selectPaginatedOrgAdminSites = createSelector(
  selectFilteredOrgAdminSites,
  selectOrgAdminSitesPageIndex,
  selectOrgAdminSitesPageSize,
  (sites, index, size) => {
    return sites.slice(index * size, index * size + size);
  },
);
