import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as EmailGroupsState from './email-groups.state';

@NgModule({
  imports: [
    StoreModule.forFeature(EmailGroupsState.STATE_KEY, EmailGroupsState.reducers),
  ],
})
export class EmailGroupsStateModule {}
