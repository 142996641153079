import { createReducer, on } from '@ngrx/store';
import { EquipmentMapLayerActions } from '@spog-ui/equipment-map-layer/actions';

export interface Shape {
  isFilteringNormal: boolean;
  isFilteringInfo: boolean;
  isFilteringWarning: boolean;
  isFilteringCritical: boolean;
  isFilteringUnknown: boolean;
}

export const initialState: Shape = {
  isFilteringNormal: false,
  isFilteringInfo: false,
  isFilteringWarning: false,
  isFilteringCritical: false,
  isFilteringUnknown: false,
};

export const reducer = createReducer(
  initialState,
  on(EquipmentMapLayerActions.enterAction, () => {
    return initialState;
  }),
  on(EquipmentMapLayerActions.toggleStatusTrayFilterAction, (state, action) => {
    return {
      ...state,
      isFilteringNormal: action.filteringNormal,
      isFilteringInfo: action.filteringInfo,
      isFilteringWarning: action.filteringWarning,
      isFilteringCritical: action.filteringCritical,
      isFilteringUnknown: action.filteringUnknown,
    };
  }),
);

export const selectIsFilteringNormal = (state: Shape) => state.isFilteringNormal;
export const selectIsFilteringInfo = (state: Shape) => state.isFilteringInfo;
export const selectIsFilteringWarning = (state: Shape) => state.isFilteringWarning;
export const selectIsFilteringCritical = (state: Shape) => state.isFilteringCritical;
export const selectIsFilteringUnknown = (state: Shape) => state.isFilteringUnknown;
