import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PrefixlessSiteGuardService implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(CoreState.selectSelectedSiteId).pipe(
      take(1),
      switchMap(siteFromState => {
        if (!siteFromState) {
          return of(this.router.parseUrl('/404'));
        }

        // event.url is the target route the user was attempting to reach
        const targetRouteRaw = this.router.getCurrentNavigation()?.extractedUrl || '';
        const targetRoute = targetRouteRaw.toString();
        const siteAwareRoute = `/site/${siteFromState}${
          targetRoute.startsWith('/') ? targetRoute : '/' + targetRoute
        }`;

        return of(this.router.parseUrl(siteAwareRoute));
      }),
    );
  }
}
