import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {
  EditUserApiActions,
  SiteApiActions,
  UserApiActions,
  UsersPageApiActions,
} from '@spog-ui/admin/actions';
import { UserInternalModel } from '@spog-ui/shared/models/users';

export type Shape = EntityState<UserInternalModel>;

export const adapter = createEntityAdapter<UserInternalModel>({
  selectId: user => user.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }),
});

export const initialState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    SiteApiActions.loadModelsSuccessAction,
    SiteApiActions.loadModelsForEditSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.users, state);
    },
  ),
  on(
    UsersPageApiActions.loadModelsSuccessAction,
    UserApiActions.loadModelsSuccessAction,
    (state, action) => adapter.setAll(action.users, state),
  ),
  on(EditUserApiActions.loadModelsSuccessAction, (state, action) =>
    adapter.upsertOne(action.user, state),
  ),
  on(UserApiActions.deleteUserSuccessAction, (state, action) =>
    adapter.removeOne(action.user.id, state),
  ),
);

export const { selectAll, selectEntities } = adapter.getSelectors();
