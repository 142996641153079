import { createReducer, on } from '@ngrx/store';
import { OrgAdminAddUserPageActions, OrgAdminUsersApiActions } from '../actions';
export interface Shape {
  isSubmitting: boolean;
  error?: any;
}

export const STATE_KEY = 'add-organization-user-page';

export const initialState: Shape = {
  isSubmitting: false,
};

export const reducer = createReducer(
  initialState,
  on(OrgAdminAddUserPageActions.enterAction, (): Shape => {
    return initialState;
  }),
  on(OrgAdminAddUserPageActions.addUserAction, (state): Shape => {
    return { ...state, isSubmitting: true };
  }),
  on(OrgAdminUsersApiActions.addUserFailureAction, (state): Shape => {
    return { ...state, isSubmitting: false };
  }),
);

export const selectError = (state: Shape) => state.error;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
