import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GraphQLClient } from '@spog-ui/graphql-client';
import {
  CreateSense420SensorDocument,
  GetAllIndieSensorsAndControllersDocument,
  GetAllIndieSensorsAndControllersQueryResult,
  GetAllIndieSensorsDocument,
  GetAllIndieSensorsQueryResult,
  UpdateSense420SensorDocument,
} from '@spog-ui/graphql/types';
import {
  AddIndieSensorPage,
  EditIndieSensorPage,
  IndieSensorsApi,
  IndieSensorsPage,
} from '@spog-ui/indie-sensors/actions';
import { toControllerInternalModelFromGQL } from '@spog-ui/shared/models/controllers';
import {
  toCreateSense420SensorInput,
  toIndieSensorInternalModelFromGQL,
  toUpdateSense420SensorInput,
} from '@spog-ui/shared/models/indie-sensors';
import { SiteService } from '@spog-ui/shared/services';
import { defer, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { PromptService } from '@sui/prompt';
import { toSiteControllerInternalModelFromGQL } from '@spog-ui/shared/models/site-controllers';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as CoreState from '@spog-ui/shared/state/core';

export const indieSensorErrorConstants = {
  message: 'Failed to save sensor',
  action: 'Dismiss',
  config: { duration: 12 * 1000 },
};

@Injectable()
export class IndieSensorsAPIEffects {
  loadAllIndieSensors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IndieSensorsPage.enterAction),
      exhaustMap(() =>
        this.gqlClient
          .query<GetAllIndieSensorsQueryResult>(GetAllIndieSensorsDocument, {
            siteId: this.siteService.getId(),
          })
          .pipe(
            map(result => {
              return IndieSensorsApi.loadSuccessAction(
                result.data.site!.industrialSensors.map(
                  toIndieSensorInternalModelFromGQL,
                ),
                result.data.site!.utilityServices,
              );
            }),
            catchError(err => of(IndieSensorsApi.loadFailureAction(err))),
          ),
      ),
    ),
  );

  loadAllIndieSensorsAndControllers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddIndieSensorPage.enterAction, EditIndieSensorPage.enterAction),
      exhaustMap(() => {
        return this.gqlClient
          .query<GetAllIndieSensorsAndControllersQueryResult>(
            GetAllIndieSensorsAndControllersDocument,
            {
              siteId: this.siteService.getId(),
            },
          )
          .pipe(
            map(result => {
              return IndieSensorsApi.loadIndieSensorsAndControllersSuccessAction(
                result.data.site!.industrialSensors.map(
                  toIndieSensorInternalModelFromGQL,
                ),
                result.data.site!.controllers.map(toControllerInternalModelFromGQL),
                result.data.site!.siteControllers.map(
                  toSiteControllerInternalModelFromGQL,
                ),
              );
            }),
            catchError(err =>
              of(IndieSensorsApi.loadIndieSensorsAndControllersFailureAction(err)),
            ),
          );
      }),
    ),
  );

  createIndieSensor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddIndieSensorPage.addSense420IndieSensorAction),
      concatMap(action => {
        const siteId = this.siteService.getId();
        const input = toCreateSense420SensorInput(siteId!, action.indieSensor); // eslint-disable-line @typescript-eslint/no-non-null-assertion
        return this.gqlClient
          .query(CreateSense420SensorDocument, {
            input,
          })
          .pipe(
            map(result =>
              IndieSensorsApi.addSense420IndieSensorSuccessAction(result.data),
            ),
            catchError(error =>
              of(IndieSensorsApi.addSense420IndieSensorFailureAction(error.errors)),
            ),
          );
      }),
    ),
  );

  promptToEditSensor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditIndieSensorPage.editSense420IndieSensorWithChangedSnapAddrAction),
      concatMap(action => {
        return this.prompt
          .open({
            title: 'Update Sense420 SNAP Address',
            description: `Are you sure you want to update the SNAP Address?  Confirming this action may affect sensor data collection.`,
            cancelLabel: 'Cancel',
            confirmLabel: 'Update',
            confirmColor: 'warn',
          })
          .pipe(
            map(didConfirm =>
              didConfirm
                ? EditIndieSensorPage.confirmEditSense420IndieSensorAction(
                    action.indieSensor,
                    action.indieSensorId,
                  )
                : EditIndieSensorPage.cancelEditSensor420IndieSensorAction(),
            ),
          );
      }),
    ),
  );

  updateIndieSensor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EditIndieSensorPage.editSense420IndieSensorAction,
        EditIndieSensorPage.confirmEditSense420IndieSensorAction,
      ),
      concatMap(action => {
        const id = action.indieSensorId;
        const input = toUpdateSense420SensorInput(id, action.indieSensor);
        return this.gqlClient
          .query(UpdateSense420SensorDocument, {
            input,
          })
          .pipe(
            map(result =>
              IndieSensorsApi.editSense420IndieSensorSuccessAction(result.data),
            ),
            catchError(error =>
              of(IndieSensorsApi.editSense420IndieSensorFailureAction(error.errors)),
            ),
          );
      }),
    ),
  );

  redirectToIndieSensorsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          IndieSensorsApi.editSense420IndieSensorSuccessAction,
          IndieSensorsApi.addSense420IndieSensorSuccessAction,
        ),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  redirectToAddBridge485Page$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddIndieSensorPage.selectHardwareTypeAction),
        filter(action => action.hardwareType === 'Bridge485Metric'),
        withLatestFrom(defer(() => this.store.select(CoreState.selectSiteURLPrefix))),
        tap(([_, urlPrefix]) =>
          this.router.navigateByUrl(urlPrefix + '/sense/bridge-485s/add'),
        ),
      ),
    { dispatch: false },
  );

  showIndieSensorError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          IndieSensorsApi.addSense420IndieSensorFailureAction,
          IndieSensorsApi.editSense420IndieSensorFailureAction,
        ),
        map(action => {
          action.error.forEach((error: any) => {
            if (
              error.message &&
              error.message.toLocaleLowerCase().includes('is not unique')
            ) {
              this.snackbar.open(
                'The SNAP address entered is not unique',
                indieSensorErrorConstants.action,
                indieSensorErrorConstants.config,
              );
            } else {
              this.snackbar.open(
                indieSensorErrorConstants.message,
                indieSensorErrorConstants.action,
                indieSensorErrorConstants.config,
              );
            }
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private gqlClient: GraphQLClient,
    private location: Location,
    private siteService: SiteService,
    private snackbar: MatSnackBar,
    readonly prompt: PromptService,
    readonly store: Store,
    readonly router: Router,
  ) {}
}
