import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { interpolateRgb } from 'd3';
import { scaleLinear } from 'd3-scale';
import { fromEvent, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  MIN_TEMPERATURE,
  MAX_TEMPERATURE,
  SVG_HEIGHT,
  SVG_WIDTH,
  RADIUS,
  ANGLE_RANGE_MIN,
  ANGLE_RANGE_MAX,
  mapTemperatureToDegrees,
  COOL_COLOR,
} from '../constants';
import { BodyService, WINDOW_TOKEN } from '@spog-ui/shared/components';

@Component({
  selector: 'svg:g[spog-thermodial-nub]',
  template: `
    <svg:circle
      #circle
      [attr.cx]="centerX"
      [attr.cy]="centerY"
      [attr.r]="nubRadius"
      [attr.fill]="fill"
      [attr.transform]="transform"
    />
  `,
  styles: [
    `
      circle {
        stroke-width: 2px;
        stroke: white;
      }
    `,
  ],
})
export class ThermodialNubComponent implements AfterViewInit, OnDestroy {
  readonly height = SVG_HEIGHT;
  readonly width = SVG_WIDTH;
  readonly nubRadius = 8;
  readonly radius = RADIUS + this.nubRadius + 3;

  readonly centerX = this.width / 2;
  readonly centerY = this.height / 2;

  pointerEventSub: Subscription;

  transform: string;
  fill: string;

  @ViewChild('circle') circleRef: ElementRef<SVGCircleElement>;

  @Input() set temperature(temperature: number) {
    this.calcTransform(temperature);
    this.calcColor(temperature);
  }
  @Input() containerElement: Element;
  @Input() disabled: boolean;
  @Output() temperatureChange = new EventEmitter<number>();

  calcTransform(temperature: number) {
    const angle = mapTemperatureToDegrees(temperature);

    this.transform = `rotate(${angle} ${this.centerX} ${this.centerY}) translate(0 ${this.radius})`;
  }

  calcColor(temperature: number) {
    const warmColor = this.bodyService.getCssVariable('--color-accent-500', '#f59032');

    const mapTemperatureToColor = scaleLinear()
      .domain([MIN_TEMPERATURE, MAX_TEMPERATURE])
      .range([warmColor, COOL_COLOR] as any)
      .interpolate(interpolateRgb as any);

    this.fill = mapTemperatureToColor(temperature) as any;
  }

  constructor(
    @Inject(DOCUMENT) public readonly document: Document,
    @Inject(WINDOW_TOKEN) public readonly window: Window,
    private readonly bodyService: BodyService,
  ) {}

  ngAfterViewInit() {
    const circle = this.circleRef!.nativeElement;

    const pointerdown$ = fromEvent(circle, 'pointerdown');
    const pointermove$ = fromEvent<PointerEvent>(this.document, 'pointermove');
    const pointerup$ = fromEvent(this.document, 'pointerup');

    this.pointerEventSub = pointerdown$
      .pipe(
        switchMap(() => {
          return pointermove$.pipe(
            filter(() => !this.disabled),
            tap(event => {
              const boundingRect = this.containerElement.getBoundingClientRect();

              const centerOfCircleX =
                boundingRect.left + this.window.scrollX + this.centerX;
              const centerOfCircleY =
                boundingRect.top + this.window.scrollY + this.centerY;

              const x = event.pageX - centerOfCircleX;
              const y = event.pageY - centerOfCircleY;

              const angleInRadians = Math.atan2(x, y);
              const angleInDegrees = clampAngle(
                shiftAxis((180 / Math.PI) * angleInRadians),
              );
              const temperature = mapTemperatureToDegrees.invert(angleInDegrees);

              this.temperatureChange.emit(temperature);
            }),
            takeUntil(pointerup$),
          );
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.pointerEventSub?.unsubscribe();
  }
}

export function shiftAxis(angle: number): number {
  if (angle < 0) {
    return Math.abs(angle);
  }

  return 360 - angle;
}

export function clampAngle(angle: number): number {
  return Math.max(ANGLE_RANGE_MIN, Math.min(ANGLE_RANGE_MAX, angle));
}
