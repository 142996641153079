import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MapActions } from '@spog-ui/map/actions';
import { MenuActions } from '@spog-ui/modules/menu-state';
import * as CoreState from '@spog-ui/shared/state/core';
import * as MapState from '@spog-ui/shared/state/map';
import { CurrentUserService } from '@spog-ui/current-user/feature';

@Component({
  selector: 'spog-toolbar',
  template: `
    <mat-toolbar>
      <div class="toolbarLeftContent">
        @if(notOnSiteMap$ | async) {
        @if(site$ | async; as site) {
        <button mat-icon-button class="menuIcon" (click)="onOpenMenu()" id="menuIcon">
          @if(hasAlarms$ | async){
          <lit-alarm-indicator/>
          } @if((hasUpdates$ | async) && !(hasAlarms$ | async)){
          <lit-update-indicator/>
          }
          <mat-icon>menu</mat-icon>
        </button>
        }
        }

        <div class="title">
          @if(notOnSiteMap$ | async) {
          @if(site$ | async; as site) {
          <span class="site"> {{ site.name }} / </span>
          }} @if(pageProduct$ | async; as pageProduct) {
          <span class="product"> {{ pageProduct }} / </span>
          }
          <span class="name">{{ pageName$ | async }}</span>
          @if (pageIsBeta$ | async) {
          <span class="betaTag">Beta</span>
          }
        </div>
      </div>

      <div class="toolbarRightContent">
        <sui-charms-outlet></sui-charms-outlet>
      </div>
    </mat-toolbar>
  `,
  styles: [
    `
      .mat-toolbar {
        min-height: 64px;
        justify-content: space-between;
      }

      .toolbarLeftContent,
      .toolbarRightContent {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .menuIcon {
        margin-right: 12px;
      }

      .spacer {
        flex: 1 1 auto;
      }

      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: var(--color-foreground-secondary-text);
      }

      .title .product,
      .title .site {
        margin-right: 4px;
      }

      .title .name {
        color: var(--color-foreground-text);
      }

      .title .betaTag {
        display: flex;
        align-items: center;
        border: 1px solid var(--color-500);
        color: var(--color-500);
        border-radius: 2px;
        padding: 0px 8px;
        height: 22px;
        height: 22px;
        vertical-align: middle;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        margin-left: 12px;
      }

      :host ::ng-deep .alarm,
      ::ng-deep .update {
        position: fixed;
        left: 40px;
        top: 35px;
      }

      :host ::ng-deep mat-form-field {
        color: var(--color-contrast-500) !important;
      }
      :host ::ng-deep .mat-form-field .mat-input-element {
        color: var(--color-foreground-secondary-text) !important;
      }
      :host ::ng-deep .mat-form-field .mat-form-field-label {
        color: var(--color-foreground-secondary-text) !important;
      }
      :host ::ng-deep .mat-form-field .mat-form-field-underline {
        background-color: var(--color-500) !important;
      }
      :host ::ng-deep .mat-form-field .mat-form-field-ripple {
        background-color: var(--color-500) !important;
      }
      /* Change label color on focused */
      :host ::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
        color: var(--color-500) !important;
      }

      @media screen and (max-width: 699px) {
        .site {
          display: none;
        }
      }
    `,
  ],
})
export class ToolbarComponent {
  shouldShowLogout$ = this.currentUser.user$;

  constructor(private store: Store, private currentUser: CurrentUserService) {}
  pageProduct$ = this.store.select(CoreState.selectPageProduct);
  pageName$ = this.store.select(CoreState.selectPageName);
  pageIsBeta$ = this.store.select(CoreState.selectPageIsBeta);
  hasAlarms$ = this.store.select(CoreState.selectHasAlarms);
  hasUpdates$ = this.store.select(CoreState.selectIsUpdateAvailable);
  toggleMapSearch$ = this.store.select(CoreState.selectToggleMapSearch);
  mapHasNotBeenActivated$ = this.store.select(
    MapState.selectFloorPlanPageIsGettingStarted,
  );
  showMapFilter$ = this.store.select(CoreState.selectShowMapFilter);
  site$ = this.store.select(CoreState.selectSelectedSite);
  notOnSiteMap$ = this.store.select(CoreState.selectNotOnMapSite);

  onLogout() {
    this.store.dispatch(MenuActions.logoutAction());
  }

  onOpenMenu() {
    this.store.dispatch(MenuActions.openAction());
  }

  onToggleMapSearch() {
    this.store.dispatch(MapActions.toggleMapSearchAction());
  }
}
