import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { findBoundingBox } from '@spog-ui/map-tools';
import * as CoreState from '@spog-ui/shared/state/core';
import {
  getSenseLegendViewModel,
  isIndieSensorAlarmed,
  isIndieSensorOk,
  isIndieSensorPending,
} from '@spog-ui/shared/models/indie-sensors';
import * as FilterState from './filter';
import * as StatusTrayState from './status-tray';
import * as SenseLayerState from './sense-layer';

export const STATE_KEY = 'senseMap';

export interface Shape {
  filter: FilterState.Shape;
  statusTray: StatusTrayState.Shape;
  senseLayer: SenseLayerState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  filter: FilterState.reducer,
  statusTray: StatusTrayState.reducer,
  senseLayer: SenseLayerState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Sense Map Layer State
 */
export const selectSenseMapLayerState = createSelector(
  selectFeatureState,
  state => state.senseLayer,
);

export const selectSenseMapLayerLoading = createSelector(
  selectSenseMapLayerState,
  SenseLayerState.selectIsLoading,
);

export const selectSenseMapLayerError = createSelector(
  selectSenseMapLayerState,
  SenseLayerState.selectError,
);

/**
 * Sense Map Filter State
 */
export const selectSenseMapFilterState = createSelector(
  selectFeatureState,
  state => state.filter,
);

export const selectSenseMapFilterSearchTerm = createSelector(
  selectSenseMapFilterState,
  FilterState.selectSearchTerm,
);

/*
 * Status Tray State
 */
export const selectSenseMapStatusTrayState = createSelector(
  selectFeatureState,
  state => state.statusTray,
);

export const selectSenseStatusTrayIsFilteringOk = createSelector(
  selectSenseMapStatusTrayState,
  StatusTrayState.selectIsFilteringOk,
);

export const selectSenseStatusTrayIsFilteringAlarmed = createSelector(
  selectSenseMapStatusTrayState,
  StatusTrayState.selectIsFilteringAlarmed,
);

export const selectSenseStatusTrayIsFilteringPending = createSelector(
  selectSenseMapStatusTrayState,
  StatusTrayState.selectIsFilteringPending,
);

/**
 * Custom Sense Map Selectors
 */
export const selectFilteredIndieSensorsForTheMap = createSelector(
  CoreState.selectIndieSensorViewModels,
  selectSenseStatusTrayIsFilteringOk,
  selectSenseStatusTrayIsFilteringAlarmed,
  selectSenseStatusTrayIsFilteringPending,
  selectSenseMapFilterSearchTerm,
  (indieSensors, filteringOk, filteringAlarmed, filteringPending, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return indieSensors.filter(sensor => {
      const isPending = isIndieSensorPending(sensor);
      const isOk = isIndieSensorOk(sensor);
      const isAlarmed = isIndieSensorAlarmed(sensor);

      // if pending, and we only want 'ok', skip this one
      if (!isOk && filteringOk) {
        return false;
        // if ok, and we only want 'pending', skip this one
      } else if (!isPending && filteringPending) {
        return false;
      } else if (!isAlarmed && filteringAlarmed) {
        return false;
      } else if (!sensor.name.toLowerCase().includes(lowerCaseSearchTerm)) {
        return false;
      }

      return true;
    });
  },
);

export const selectBoxAroundFilteredIndieSensors = createSelector(
  selectFilteredIndieSensorsForTheMap,
  CoreState.selectIndieSensorViewModels,
  (filteredSensors, allSensors) => {
    const sensors = filteredSensors.length > 0 ? filteredSensors : allSensors;

    return findBoundingBox(sensors);
  },
);

export const selectSenseLegendView = createSelector(
  CoreState.selectIndieSensorViewModels,
  indieSensors => getSenseLegendViewModel(indieSensors),
);

export const selectSensorNames = createSelector(
  CoreState.selectIndieSensorViewModels,
  sensors => {
    return sensors.map(sense => sense.name);
  },
);

export const selectHasPositionedSensors = createSelector(
  CoreState.selectIndieSensorViewModels,
  positionedSensors => positionedSensors.length > 0,
);

/**
 * Active Indie Sensor on Sense Map
 */
export const selectActiveIndieSensorId = createSelector(
  CoreState.selectRouterParams,
  params => params.activeIndieSensorId as string | undefined | null,
);
export const selectActiveIndieSensor = createSelector(
  CoreState.selectIndieSensorViewModelsById,
  selectActiveIndieSensorId,
  (indieSensorsById, activeIndieSensorId) => {
    if (!activeIndieSensorId) return null;

    return indieSensorsById[activeIndieSensorId];
  },
);
export const selectActiveIndieSensorSiteController = createSelector(
  selectActiveIndieSensor,
  CoreState.selectSiteControllerEntities,
  (activeSensor, siteControllers) =>
    activeSensor && activeSensor.state && activeSensor.state.siteControllerId
      ? siteControllers[activeSensor.state.siteControllerId]
      : null,
);
