import {
  EmailGroupModelFragment,
  UpdateEmailGroupInput,
  CreateEmailGroupInput,
  AlarmEmailFrequency,
  LightAlarmReason,
  ThermostatAlarmReason,
} from '@spog-ui/graphql/types';
import { EmailGroupOrgAdminUpsert as EmailGroupWSModel } from '@spog-shared/events/organization';

export { EmailGroupWSModel };

export interface OfficeHoursInternalModel {
  startTime: string;
  endTime: string;
}

export interface AnyAlarmReasonInternalModel {
  lightAlarmReason?: LightAlarmReason | null;
  thermostatAlarmReason?: ThermostatAlarmReason | null;
}

export interface EmailGroupPreference {
  alarmType: AnyAlarmReasonInternalModel;
  frequency?: AlarmEmailFrequency | null;
  officeHours?: OfficeHoursInternalModel | null;
}

export interface EmailGroupInternalModel {
  id: string;
  name: string;
  preferences: EmailGroupPreference[];
  siteIds: string[];
  userIds: string[];
}

export type EmailGroupGQLModel = EmailGroupModelFragment;

export function toEmailGroupInternalModelFromGQL(
  gql: EmailGroupGQLModel,
): EmailGroupInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    preferences: gql.preferences?.specs ?? [],
    siteIds: gql.sites.edges.map(edge => edge.node.id),
    userIds: gql.users.edges.map(edge => edge.node.id),
  };
}

export interface EmailGroupFormModel {
  id?: string;
  name: string;
  preferences: EmailGroupPreference[];
  siteIds: string[];
  userIds: string[];
}

export function toCreateEmailGroupInputFromEmailGroupForm(
  form: EmailGroupFormModel,
  organizationId: string,
): CreateEmailGroupInput {
  return {
    name: form.name,
    preferences: {
      specs: form.preferences,
    },
    siteIds: form.siteIds,
    userIds: form.userIds,
    organizationId,
  };
}

export function toUpdateEmailGroupInputFromEmailGroupForm(
  form: EmailGroupFormModel,
): UpdateEmailGroupInput {
  return {
    id: form.id!,
    name: form.name,
    preferences: {
      specs: form.preferences,
    },
    siteIds: form.siteIds,
    userIds: form.userIds,
  };
}

export function toEmailGroupFormModelFromInternal(
  emailGroup: EmailGroupInternalModel,
): EmailGroupFormModel {
  return {
    id: emailGroup.id,
    name: emailGroup.name,
    preferences: emailGroup.preferences,
    siteIds: emailGroup.siteIds,
    userIds: emailGroup.userIds,
  };
}

export function toEmailGroupInternalModelFromWS(
  ws: EmailGroupWSModel,
): EmailGroupInternalModel {
  return {
    id: ws.id,
    name: ws.name,
    preferences: ws.preferences.specs,
    siteIds: ws.siteIds,
    userIds: ws.userIds,
  };
}
