import { createSelector } from '@ngrx/store';
import { selectOrganizations } from './admin-section-organizations.feature-selector';
import * as OrganizationsState from './organizations.reducer';

export const selectOrganizationsForView = createSelector(
  selectOrganizations,
  OrganizationsState.selectAll,
);

export const selectShowGettingStartedCard = createSelector(
  selectOrganizationsForView,
  organizations => organizations.length === 0,
);
