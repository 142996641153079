import { createAction } from '@ngrx/store';
import { EmailGroupFormModel } from '@spog-ui/shared/models/email-groups';
import { OrganizationSiteInternalModel } from '@spog-ui/shared/models/sites';
import { OrganizationUserInternalModel } from '@spog-ui/shared/models/organization-users';

export const enterAction = createAction('[Admin / Add Email Group Page] Enter');

export const addEmailGroupAction = createAction(
  '[Add Email Group] Add Email Group',
  (emailGroup: EmailGroupFormModel) => ({ emailGroup }),
);

export const loadPageSuccessAction = createAction(
  '[Add Email Group] Load Page Success',
  (users: OrganizationUserInternalModel[], sites: OrganizationSiteInternalModel[]) => ({
    users,
    sites,
  }),
);

export const loadPageFailureAction = createAction(
  '[Add Email Group] Load Page Failure',
  (error: string) => ({ error }),
);
