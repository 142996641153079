import { Component } from '@angular/core';
import { MatDialogContent } from '@angular/material/dialog';

@Component({
    selector: 'sui-dialog-content',
    template: `
    <mat-dialog-content>
      <ng-content></ng-content>
    </mat-dialog-content>
  `,
    styles: [
        `
      :host ::ng-deep .mat-dialog-content {
        background-color: var(--color-background-background);
        border-top: 1px solid var(--color-foreground-divider);
        border-bottom: 1px solid var(--color-foreground-divider);
      }
    `,
    ],
    standalone: true,
    imports: [MatDialogContent],
})
export class DialogContentComponent {}
