import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { DialogService } from '@spog-ui/shared/components';
import { EulaModalComponent } from '@spog-ui/current-user/eula';
import { LandingPageActions } from '../actions';

@Injectable()
export class LandingPageEffects {
  constructor(private actions$: Actions, private dialogService: DialogService) {}

  showEulaForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LandingPageActions.showEulaAction),
        mergeMap(action => {
          return this.dialogService.open(EulaModalComponent, {}, { disableClose: false });
        }),
      ),
    { dispatch: false },
  );
}
