import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SequenceSceneStepControlComponentModule } from '../sequence-scene-step-control';
import { SequenceSceneStepListComponent } from './sequence-scene-step-list.component';
import { ButtonComponent, SuiCardModule } from '@spog-ui/shared/components';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SuiCardModule,
    ButtonComponent,
    SequenceSceneStepControlComponentModule,
  ],
  declarations: [
    SequenceSceneStepListComponent,
  ],
  exports: [SequenceSceneStepListComponent],
})
export class SequenceSceneStepListComponentModule {}
