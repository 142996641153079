import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BFNoneComponent } from './bf-none.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [BFNoneComponent],
  exports: [BFNoneComponent],
})
export class BFNoneComponentModule {}
