import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'clr-color-swatch',
  template: `
    <svg viewBox="0 0 24 24">
      <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
    <div
      class="menuTrigger"
      data-test-id="colorSwatchMenuTrigger"
      [matMenuTriggerFor]="contextMenu"
    ></div>
    <mat-menu #contextMenu>
      <button mat-menu-item (click)="replace.emit()">Replace Color</button>
      <button mat-menu-item (click)="remove.emit()">Remove from Palette</button>
    </mat-menu>
  `,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      clr-color-swatch {
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        position: relative;
        background-position: center;
      }

      clr-color-swatch.isSelected {
        border: 2px solid rgba(255, 255, 255, 0.5);
      }

      clr-color-swatch .menuTrigger {
        position: absolute;
        bottom: 4px;
        right: 4px;
      }

      clr-color-swatch svg {
        display: none;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      clr-color-swatch path {
        fill: rgba(255, 255, 255, 0.4);
        transition: fill 250ms;
      }

      clr-color-swatch.isEmpty svg {
        display: block;
      }

      clr-color-swatch.isEmpty ~ clr-color-swatch.isEmpty path {
        fill: rgba(255, 255, 255, 0);
      }

      clr-color-swatch:hover {
        cursor: pointer;
      }

      clr-color-swatch:hover path {
        fill: rgba(255, 255, 255, 0.72) !important;
      }

      clr-color-swatch.isDraggedOver {
        border: 2px solid rgba(255, 255, 255, 0.24);
      }
    `,
  ],
})
export class ColorSwatchComponent {
  @Input() imageUrl: string | null = null;
  @Input() @HostBinding('class.isSelected') isSelected = false;
  @Output() apply = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() replace = new EventEmitter();
  @Output() remove = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;

  @HostBinding('class.isEmpty') get isEmpty() {
    return this.imageUrl === null;
  }

  @HostBinding('class.isDraggedOver') isDraggedOver = false;

  @HostBinding('style.backgroundImage') get backgroundImage() {
    if (this.imageUrl === null) {
      return 'none';
    }
    return `url(${this.imageUrl})`;
  }

  @HostListener('click') onClick() {
    if (this.imageUrl === null) {
      this.add.emit();
    } else {
      this.apply.emit();
    }
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent) {
    if (!event.dataTransfer) return;
    event.preventDefault();

    this.isDraggedOver = true;
  }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    if (!event.dataTransfer) return;
    event.preventDefault();
  }

  @HostListener('dragleave') onDragLeave() {
    this.isDraggedOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    if (!event.dataTransfer) return;
    if (event.dataTransfer.getData('text/plain') !== 'source:chromahedron') return;

    if (this.imageUrl === null) {
      this.add.emit();
    } else {
      this.replace.emit();
    }

    this.isDraggedOver = false;
  }

  @HostListener('contextmenu', ['$event']) onContextMenu(event: MouseEvent) {
    if (this.trigger && this.imageUrl) {
      this.trigger.openMenu();
      event.preventDefault();
    }
  }

  @HostListener('longpress', ['$event']) onLongPress(event: MouseEvent) {
    if (this.trigger && this.imageUrl) {
      this.trigger.openMenu();
      event.preventDefault();
    }
  }
}
