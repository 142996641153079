import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {
  ResourceGroupInternalModel,
  toResourceGroupInternalModelFromGQL,
} from '@spog-ui/shared/models/resource-groups';
import { DepartmentsApiActions } from '@spog-ui/departments/actions';
import {
  AddEquipmentApiActions,
  EditEquipmentApiActions,
  EquipmentApiActions,
  EquipmentProfileApiActions,
} from '@spog-ui/equipment/actions';
import { EquipmentMapLayerApiActions } from '@spog-ui/equipment-map-layer/actions';
import { EquipmentDetailsApiActions } from '@spog-ui/equipment-details/actions';
import { SocketActions } from '@spog-ui/socket/actions';

export type Shape = EntityState<ResourceGroupInternalModel>;

export const adapter = createEntityAdapter<ResourceGroupInternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    DepartmentsApiActions.loadSuccessAction,
    DepartmentsApiActions.loadFormDataSuccessAction,
    (state, action): Shape => {
      return adapter.upsertMany(
        action.resourceGroups.map(toResourceGroupInternalModelFromGQL),
        state,
      );
    },
  ),
  on(
    EquipmentApiActions.loadInitialModelsSuccessAction,
    EditEquipmentApiActions.loadFormDataSuccessAction,
    AddEquipmentApiActions.loadFormDataSuccessAction,
    (state, action) => {
      return adapter.upsertMany(action.resourceGroups, state);
    },
  ),
  on(EquipmentMapLayerApiActions.loadInitialModelsSuccessAction, (state, action) => {
    return adapter.upsertMany(action.equipment, state);
  }),
  on(
    EquipmentMapLayerApiActions.loadDeferredModelsSuccessAction,
    EquipmentProfileApiActions.loadSuccessAction,
    (state, action) => {
      return adapter.upsertMany(action.models.resourceGroups, state);
    },
  ),
  on(
    EquipmentDetailsApiActions.loadEquipmentDetailsModelsSuccessAction,
    (state, action) => {
      return adapter.upsertMany(action.resourceGroups, state);
    },
  ),
  on(
    DepartmentsApiActions.deleteDepartmentSuccess,
    EquipmentApiActions.deleteEquipmentSuccess,
    (state, action) => {
      return adapter.removeOne(action.id, state);
    },
  ),
  on(SocketActions.bulkFloorPlanPlacementAction, (state, action) => {
    const updates = action.placedThings.map(thing => ({
      id: thing.id,
      changes: {
        floorPlanId: thing.floorPlanId,
        floorPlanX: thing.floorPlanX,
        floorPlanY: thing.floorPlanY,
      },
    }));
    return adapter.updateMany(updates, state);
  }),
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
