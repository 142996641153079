import { Component, Input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  SequenceSceneViewModel,
  fromComponentsToPreviewText,
  fromSecondsToPreviewText,
  getCombinedStepsForPreview,
  getOrdinalString,
} from '@spog-ui/shared/models/sequence-scenes';

@Component({
  selector: 'scn-sequence-scene-preview',
  template: `
    <div>Start Scene</div>
    <span>{{ sequenceScene.steps[0].applySceneName }}</span>

    @for (step of getCombinedStepsForPreview(sequenceScene); track step.stepNumber) {
    <h4 class="mat-subheading-1">{{ getOrdinalString($index + 2) }} Step</h4>
    <div>Wait Before Step</div>
    <span>{{ fromSecondsToPreviewText(step.duration) }}</span>
    <div>Step Scene</div>
    <span>{{ step.applySceneName }}</span>
    } @if (sequenceScene.loop) {
    <div class="loop">
      Loops after
      {{
        fromComponentsToPreviewText(
          sequenceScene.loopWaitHours,
          sequenceScene.loopWaitMinutes,
          sequenceScene.loopWaitSeconds
        )
      }}
    </div>
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 64px;
        font-size: 12px;
      }

      @media screen and (max-width: 500px) {
        :host {
          padding-left: 26px;
        }
      }

      span {
        margin: 0;
        color: var(--color-foreground-secondary-text);
        padding-bottom: 12px;
      }

      h4 {
        margin: 0;
      }

      .loop {
        padding-bottom: 12px;
      }
    `,
  ],
  animations: [
    trigger('shrinkIn', [
      transition('void => *', [
        style({ height: 0, opacity: 0 }),
        animate(100, style({ height: '*', opacity: 1.0 })),
      ]),
    ]),
  ],
})
export class SequenceScenePreviewComponent {
  fromComponentsToPreviewText = fromComponentsToPreviewText;
  fromSecondsToPreviewText = fromSecondsToPreviewText;
  getCombinedStepsForPreview = getCombinedStepsForPreview;
  getOrdinalString = getOrdinalString;

  @Input()
  sequenceScene: SequenceSceneViewModel;
}
