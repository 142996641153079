import { createAction } from '@ngrx/store';
import {
  ResourceGroupGQLModel,
  ResourceGroupInternalModel,
} from '@spog-ui/shared/models/resource-groups';
import {
  IndieSensorGQLModel,
  IndieSensorInternalModel,
} from '@spog-ui/shared/models/indie-sensors';
import { TriggerGQLModel, TriggerInternalModel } from '@spog-ui/shared/models/triggers';

export const loadInitialModelsSuccessAction = createAction(
  '[Equipment API] Load Initial Models Success',
  (resourceGroups: ResourceGroupInternalModel[]) => ({
    resourceGroups,
  }),
);

export const loadInitialModelsFailureAction = createAction(
  '[Equipment API] Load Initial Models Failure',
  (error: string) => ({ error }),
);

export const loadDeferredModelsSuccessAction = createAction(
  '[Equipment API]  Load Deferred Models Success',
  (models: {
    industrialSensors: IndieSensorInternalModel[];
    triggers: TriggerInternalModel[];
  }) => {
    return { models };
  },
);

export const loadDeferredModelsFailureAction = createAction(
  '[Equipment API] Load Deferred Models Failure',
  (error: string) => ({ error }),
);

export const deleteEquipmentSuccess = createAction(
  '[Equipment API] Delete Equipment Success',
  id => ({ id }),
);

export const deleteEquipmentFailure = createAction(
  '[Equipment API] Delete Equipment Failure',
  (error: string) => ({ error }),
);
