import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as SceneViewsState from './scene-views.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(SceneViewsState.STATE_KEY, SceneViewsState.reducers),
  ],
})
export class SceneViewsStateModule {}
