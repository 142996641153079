import { ElementRef, Injectable } from '@angular/core';
import { PlatformService } from './platform.service';

@Injectable()
export class FocusService {
  constructor(private platform: PlatformService) {}

  on(ref: ElementRef) {
    if (this.platform.isBrowser()) {
      setTimeout(() => ref.nativeElement.focus(), 50);
    }
  }

  blur(ref: ElementRef) {
    if (this.platform.isBrowser()) {
      setTimeout(() => ref.nativeElement.blur());
    }
  }
}

export const FOCUS_SERVICE_PROVIDERS = [FocusService];
