import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SiteService } from '@spog-ui/shared/services';
import { GraphQLAPIService, UpdateColorPaletteInput } from '@spog-ui/graphql/types';
import { ColorPaletteService, ColorPalette } from '@sui/color-picker';
import { Injectable } from '@angular/core';
import { SocketEffects } from 'projects/simplysnap-spog/ui/modules/socket/src/effects/socket.effects';
import {
  MessageNotification,
  RDSUpsertNotification,
  SocketNotificationTypes,
} from '@spog-ui/shared/models/websocket-notifications';

export type HexColor = {
  hex: string;
};

function toColorPalette(colors: HexColor[]): ColorPalette {
  // Break each string into two-character chunks, then parse them as numbers
  const palette = colors.map(color =>
    color.hex.match(/.{1,2}/g)!.map(twoDigitHex => parseInt(twoDigitHex, 16)),
  );
  return palette as ColorPalette;
}

@Injectable()
export class ColorPaletteGQLService implements ColorPaletteService {
  constructor(
    private gql: GraphQLAPIService,
    private siteService: SiteService,
    private socketEffects: SocketEffects,
  ) {}

  load(): Observable<ColorPalette> {
    const siteId = this.siteService.getId();

    if (!siteId) throw new Error('Site ID is not defined');

    // get set of colors, then transform each from one 10-digit hex to five integers
    return this.gql
      .getColorPalette({ siteId })
      .pipe(map(data => toColorPalette(data.site!.colorPalette.colors)));
  }

  save(colorPalette: ColorPalette): Observable<ColorPalette> {
    const siteId = this.siteService.getId();

    if (!siteId) throw new Error('Site ID is not defined');

    // filter out any empty slots, then turn each number in each color array into
    // two-character hex strings and join them
    const colors = colorPalette
      .filter(color => color != null)
      .map(color =>
        color!.map(channel => channel.toString(16).padStart(2, '0')).join(''),
      );

    const input: UpdateColorPaletteInput = {
      siteId,
      colors,
    };

    return this.gql
      .updateColorPalette({
        input,
      })
      .pipe(map(data => toColorPalette(data.updateColorPalette)));
  }

  update(): Observable<ColorPalette> {
    return this.socketEffects.socketMessages$.pipe(
      filter(
        (
          message,
        ): message is MessageNotification<
          RDSUpsertNotification<{ colors: HexColor[] }>
        > =>
          message.type === SocketNotificationTypes.Message &&
          message.payload.type === 'upsert' &&
          message.payload.entityKind === 'colorPalette',
      ),
      map(message => toColorPalette(message.payload.document.colors)),
    );
  }
}
