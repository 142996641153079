import { Component } from '@angular/core';

@Component({
  selector: 'spog-drawer-logo',
  template: ` <img src="/assets/logo.svg" /> `,
  styles: [
    `
      :host {
        display: block;
        padding: 24px 16px;
      }

      img {
        width: 70%;
        height: auto;
      }
    `,
  ],
})
export class DrawerLogoComponent {}
