import { createAction } from '@ngrx/store';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const enterAction = createAction('[Admin / Sites] Enter');
export const copyIDAction = createAction(
  '[Admin / Sites] Copy Site ID',
  (site: SiteInternalModel) => {
    return { site };
  },
);
export const filterAction = createAction('[Admin / Sites] Filter', (term: string) => {
  return { term };
});
export const paginateAction = createAction(
  '[Admin / Sites] Paginate',
  (pageIndex: number, pageSize: number) => {
    return { pageIndex, pageSize };
  },
);
export const deleteAction = createAction(
  '[Admin / Sites] Delete',
  (site: SiteInternalModel) => ({
    site,
  }),
);


