import { createAction } from '@ngrx/store';
import { ColorPalette } from './color-picker.models';

export const loadColorPaletteSuccess = createAction(
  '[@sui/color-picker/api] Load Color Palette Success',
  (palette: ColorPalette) => ({ palette }),
);
export const loadColorPaletteFailure = createAction(
  '[@sui/color-picker/api] Load Color Palette Failure',
  (error: string) => ({ error }),
);
export const saveColorPaletteSuccess = createAction(
  '[@sui/color-picker/api] Save Color Palette Success',
  (palette: ColorPalette) => ({ palette }),
);
export const saveColorPaletteFailure = createAction(
  '[@sui/color-picker/api] Save Color Palette Failure',
  (error: string) => ({ error }),
);
export const updateColorPaletteFromWs = createAction(
  '[@sui/color-picker/api] Update Color Palette From WebSocket',
  (palette: ColorPalette) => ({ palette }),
);
