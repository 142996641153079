import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { SuiBodyModule } from '../body';
import { DialogComponent } from './dialog.component';
import { DialogService } from './dialog.service';
import { DialogContentComponent } from './content.component';
import { DialogFooterComponent } from './footer.component';
import { DialogTitleComponent } from './title.component';

const PUBLIC_COMPONENTS = [
  DialogComponent,
  DialogContentComponent,
  DialogFooterComponent,
  DialogTitleComponent,
];

@NgModule({
    imports: [MatDialogModule, SuiBodyModule, PUBLIC_COMPONENTS],
    exports: [MatDialogModule, PUBLIC_COMPONENTS],
    providers: [DialogService],
})
export class SuiDialogModule {}

export { DialogService };
