import { Component, ElementRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import { SuiPanelState } from './panel.state';
import { AsyncPipe } from '@angular/common';
import { PanelHeaderComponent } from './panel-header.component';
import { PanelOverlayComponent } from './panel-overlay.component';

export const SuiAnimatePanel = trigger('suiAnimatePanel', [
  transition(':enter', [
    group([
      query('sui-panel sui-panel-overlay', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      query('sui-panel .suiPanelContent', [
        style({ transform: 'translateX(360px)' }),
        animate(200, style({ transform: 'none' })),
      ]),
    ]),
  ]),
  transition(':leave', [
    group([
      query('sui-panel sui-panel-overlay', [
        style({ opacity: 1.0 }),
        animate(200, style({ opacity: 0 })),
      ]),
      query('sui-panel .suiPanelContent', [
        style({ transform: 'none' }),
        animate(200, style({ transform: 'translateX(360px)' })),
      ]),
    ]),
  ]),
]);

@Component({
  selector: 'sui-panel',
  template: `
    <sui-panel-overlay
      [visible]="state.isOverlayVisible$ | async"
      (click)="state.emitCloseEvent()"
    ></sui-panel-overlay>
    <div class="suiPanelContent">
      <sui-panel-header
        [title]="title"
        [color]="color"
        [covering]="state.isPanelCoveringThePage$ | async"
        (closed)="state.emitCloseEvent()"
      ></sui-panel-header>
      <div class="suiPanelBody">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        position: fixed;
        z-index: 802;
        top: 0;
        right: 0;
        width: 100vw;
        width: -webkit-fill-available;
        height: 100vh;
        height: -webkit-fill-available;
      }

      @media screen and (min-width: 500px) {
        :host {
          width: 360px;
        }
      }

      .suiPanelContent {
        display: grid;
        grid-template-areas:
          'header'
          'content';
        grid-template-rows: 64px 1fr;
        height: 100%;
        width: 100%;
        transition: transform 200ms;
      }

      sui-panel-header {
        grid-area: header;
      }

      .suiPanelBody {
        background-color: var(--color-background-app-bar);
        grid-area: content;
        overflow-y: scroll;
      }
    `,
  ],
  standalone: true,
  imports: [PanelOverlayComponent, PanelHeaderComponent, AsyncPipe],
})
export class PanelComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() color = 'default';
  @Output() closed: Observable<boolean>;

  ownerBodyRef: HTMLElement = this.elementRef.nativeElement.ownerDocument.body;
  isOverlayVisibleSub: Subscription;

  constructor(
    readonly state: SuiPanelState,
    readonly elementRef: ElementRef<HTMLElement>,
  ) {
    this.closed = state.observeCloseEvents();

    this.isOverlayVisibleSub = state.isOverlayVisible$.subscribe(overlayVisible => {
      this.state.disableScroll(this.ownerBodyRef, overlayVisible);
    });
  }

  ngOnInit(): void {
    this.state.markPanelAsOpen();
  }

  ngOnDestroy(): void {
    this.state.markPanelAsClosed();
    this.state.disableScroll(this.ownerBodyRef, false);
    this.isOverlayVisibleSub?.unsubscribe();
  }
}
