import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveSequenceScenesComponent } from './active-sequence-scenes.component';
import { CountdownComponentModule } from '../countdown';
import { SuiAccordionModule } from '@spog-ui/shared/components';

@NgModule({
  imports: [CommonModule, SuiAccordionModule, CountdownComponentModule],
  declarations: [ActiveSequenceScenesComponent],
  exports: [ActiveSequenceScenesComponent],
})
export class ActiveSequenceScenesComponentModule {}
