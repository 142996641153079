import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { EmailGroupInternalModel } from '@spog-ui/shared/models/email-groups';
import { EmailGroupStateActions } from '../actions';

export type Shape = EntityState<EmailGroupInternalModel>;

export const adapter = createEntityAdapter<EmailGroupInternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(EmailGroupStateActions.loadEmailGroupsSuccessAction, (state, action): Shape => {
    return adapter.setAll(action.emailGroups, state);
  }),
  on(EmailGroupStateActions.deleteEmailGroupAction, (state, action) => {
    return adapter.removeOne(action.emailGroup.id, state);
  }),
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
