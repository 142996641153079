import { createReducer, on } from '@ngrx/store';
import {
  EditUtilityServicePageActions,
  UtilityServicesApiActions,
} from '@spog-ui/utility-services/actions';

export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  loadingError?: any;
  submissionError?: any;
}

export const initialState: Shape = {
  isLoading: true,
  isSubmitting: false,
};

export const reducer = createReducer(
  initialState,
  on(EditUtilityServicePageActions.enterAction, (): Shape => {
    return initialState;
  }),
  on(UtilityServicesApiActions.loadUtilityServicesAndThingsSuccessAction, (): Shape => {
    return { isLoading: false, isSubmitting: false };
  }),
  on(
    UtilityServicesApiActions.loadUtilityServicesAndThingsFailureAction,
    (state, action): Shape => {
      return { isLoading: false, isSubmitting: false, loadingError: action.error };
    },
  ),
  on(EditUtilityServicePageActions.editUtilityServiceAction, (): Shape => {
    return { isLoading: false, isSubmitting: true };
  }),
  on(UtilityServicesApiActions.updateUtilityServiceFailure, (state, action): Shape => {
    return { isLoading: false, isSubmitting: false, submissionError: action.error };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
export const selectLoadingError = (state: Shape) => state.loadingError;
export const selectSubmissionError = (state: Shape) => state.submissionError;
