import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
    selector: '[spogValidateUniqueSnapaddrs]',
    providers: [
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: UniqueSnapaddrsValidator,
        },
    ],
    standalone: true,
})
export class UniqueSnapaddrsValidator implements Validator {
  snapaddrs: string[] = [];
  _currentSnapaddr = '';
  onValidatorChange = () => void 0;

  @Input('spogValidateUniqueSnapaddrs')
  set snapaddrsToValidateAgainst(snapaddrs: string[]) {
    this.snapaddrs = snapaddrs.map(snapaddr => formatSnapAddress(snapaddr.toLowerCase()));
    this.onValidatorChange();
  }

  @Input()
  set currentSnapaddr(snapaddr: string) {
    this._currentSnapaddr = snapaddr;
    this.onValidatorChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const snapaddr: string = formatSnapAddress(control.value.toLowerCase());

    return this._currentSnapaddr !== snapaddr &&
      this.snapaddrs.some(otherSnapaddr => snapaddr === otherSnapaddr)
      ? { spogSnapaddrIsNotUnique: true }
      : null;
  }
}

export function formatSnapAddress(snapaddr: string): string {
  return snapaddr.split('.').join('').split(':').join('');
}
