import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

const SNAP_ADDRESS_REGEX =
  /^(00[.:]?1[cC][.:]?2[cC][.:]?[0-9a-fA-F]{2}[.:]?[0-9a-fA-F]{2}[.:]?)?([0-9a-fA-F][.:]?){5}[0-9a-fA-F]$/;

export function isValidSnapAddress(snapaddr: string) {
  return SNAP_ADDRESS_REGEX.test(snapaddr);
}

@Directive({
    selector: '[suiSnapAddress]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: SnapAddressValidatorDirective,
            multi: true,
        },
    ],
    standalone: true,
})
export class SnapAddressValidatorDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: string } | null {
    const value: string = c.value;
    const valid = isValidSnapAddress(value);
    const empty = value === '';

    return empty || valid ? null : { suiSnapAddress: value };
  }
}

export const SNAP_ADDRESS_DIRECTIVES = [SnapAddressValidatorDirective];
