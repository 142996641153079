import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as AlarmsPageState from './alarms-page';
import { toAlarmViewModelFromInternal } from '@spog-ui/shared/models/alarms';
import { DateTime } from 'luxon';
import { sortAlarms, isAlarmTypeRetryable } from '@shared/alarms';

export const STATE_KEY = 'alarms';

export interface Shape {
  alarmsPage: AlarmsPageState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  alarmsPage: AlarmsPageState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Alarms Page State
 */
export const selectAlarmsPageState = createSelector(
  selectFeatureState,
  state => state.alarmsPage,
);
export const selectAlarmsPageIsLoading = createSelector(
  selectAlarmsPageState,
  AlarmsPageState.selectIsLoadingAlarms,
);

export const selectAlarmsPageIsReady = createSelector(
  selectAlarmsPageIsLoading,
  isLoading => !isLoading,
);

export const selectShowRemoteAccessLink = createSelector(
  selectAlarmsPageState,
  AlarmsPageState.selectShowRemoteAccessLink,
);

export const selectAlarmViews = createSelector(
  CoreState.selectAllAlarms,
  CoreState.selectAllSiteControllersForActiveSite,
  (alarms, siteControllers) => {
    return alarms.map(alarm => toAlarmViewModelFromInternal(alarm, siteControllers));
  },
);

export const selectAlarmsPageError = createSelector(
  selectAlarmsPageState,
  AlarmsPageState.selectError,
);

export const selectAlarmsFilter = createSelector(
  selectAlarmsPageState,
  AlarmsPageState.selectFilter,
);

export const selectFilteredAlarms = createSelector(
  selectAlarmsFilter,
  selectAlarmViews,
  (filter, alarms) => {
    return alarms.filter(alarm => {
      const searchTerm = filter.trim().toLowerCase();
      const alarmDescription = alarm.description?.toLowerCase();
      const alarmType = alarm.type.toString().toLowerCase();
      return alarmDescription?.includes(searchTerm) || alarmType.includes(searchTerm);
    });
  },
);

export const selectAlarmsCsvData = createSelector(
  selectFilteredAlarms,
  CoreState.selectSelectedSiteTimeZone,
  (alarms, siteTimeZone) => {
    alarms = sortAlarms(alarms, 'triggerTime');
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const firstRow = ['Type', 'Description', 'Trigger Time'];
    return (
      firstRow.join(',') +
      lineDelimiter +
      alarms
        .map(alarm => {
          return [
            alarm.type,
            alarm.description || '',
            DateTime.fromISO(alarm.triggerTime, { zone: siteTimeZone })
              .toLocaleString({
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              })
              .replace(',', ''),
          ].join(columnDelimiter);
        })
        .join(lineDelimiter)
    );
  },
);

export const selectRetryableAlarmCount = createSelector(
  selectAlarmViews,
  alarms => alarms.filter(alarm => isAlarmTypeRetryable(alarm.type)).length,
);
