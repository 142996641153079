import {
  Directive,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  Optional,
} from '@angular/core';

export type FormErrorFunction = (...args: any[]) => string;

export interface FormErrorMap {
  [key: string]: FormError;
}

export interface FormError {
  [key: string]: string | FormErrorFunction;
}

export const FORM_ERROR_MESSAGES = new InjectionToken<FormError>('Sui Form Errors');

@Directive({
    selector: '[suiFormError]',
    standalone: true,
})
export class FormInputErrorsDirective {
  private _errors: Record<string, unknown> | null = {};

  @Input() errorKey: string;

  @Input() set suiFormError(errors: Record<string, unknown>) {
    this._errors = errors;

    if (this.errorMessage) {
      this.elementRef.nativeElement.innerHTML = this.errorMessage;
    } else {
      this.elementRef.nativeElement.innerHTML = null;
    }
  }

  get errors() {
    return this._errors;
  }

  constructor(
    @Optional()
    @Inject(FORM_ERROR_MESSAGES)
    private errorMessages: FormErrorMap = {},
    private elementRef: ElementRef,
  ) {}

  get errorMessage() {
    const errors: any = this.errors || {};

    for (const propertyName in errors) {
      if (errors && Object.prototype.hasOwnProperty.call(errors, propertyName)) {
        return this.getMessage(this.errorKey, propertyName, errors[propertyName]);
      }
    }

    return null;
  }

  getMessage(errorKey: string, error: string, value?: any) {
    const messages = this.errorMessages[errorKey] || this.errorMessages['default'];
    const defaultError = 'Field is invalid';

    if (!messages) {
      return defaultError;
    }

    const errorMessage = messages[error];

    if (!errorMessage) {
      return defaultError;
    }

    if (typeof errorMessage === 'function') {
      return errorMessage(value);
    }

    return errorMessage;
  }
}

export function provideFormErrors(formErrorFactory: () => FormErrorMap) {
  return [{ provide: FORM_ERROR_MESSAGES, useFactory: formErrorFactory }];
}
