import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CreatableHardwareType,
  IndieSensorStatus,
} from '@spog-ui/shared/models/indie-sensors';
import * as CoreState from '@spog-ui/shared/state/core';
import { isPowerSensor } from '@spog-ui/shared/state/core';
import { filterBy } from '@spog-ui/utils/filter';
import { partition } from 'lodash';
import * as AddIndieSensorPageState from './add-indie-sensors-page';
import * as EditIndieSensorPageState from './edit-indie-sensors-page';
import * as IndieSensorDetailsPageState from './indie-sensor-details-page';
import * as IndieSensorsPageState from './indie-sensors-page';

export const STATE_KEY = 'sense';

export interface Shape {
  indieSensorsPage: IndieSensorsPageState.Shape;
  indieSensorDetailsPage: IndieSensorDetailsPageState.Shape;
  addIndieSensorsPage: AddIndieSensorPageState.Shape;
  editIndieSensorsPage: EditIndieSensorPageState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  indieSensorsPage: IndieSensorsPageState.reducer,
  indieSensorDetailsPage: IndieSensorDetailsPageState.reducer,
  addIndieSensorsPage: AddIndieSensorPageState.reducer,
  editIndieSensorsPage: EditIndieSensorPageState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Indie Sensors Page State
 */
export const selectIndieSensorsPageState = createSelector(
  selectFeatureState,
  state => state.indieSensorsPage,
);

export const selectIndieSensorsPageIsLoading = createSelector(
  selectIndieSensorsPageState,
  IndieSensorsPageState.selectIsLoading,
);

export const selectIndieSensorsPageSearchTerm = createSelector(
  selectIndieSensorsPageState,
  IndieSensorsPageState.selectSearchTerm,
);

/**
 * Indie Sensor Details Page State
 */
export const selectIndieSensorDetailsPageState = createSelector(
  selectFeatureState,
  state => state.indieSensorDetailsPage,
);

export const selectIndieSensorDetailsPageIsLoading = createSelector(
  selectIndieSensorDetailsPageState,
  IndieSensorDetailsPageState.selectIsLoading,
);

export const selectIndieSensorDetailsPageError = createSelector(
  selectIndieSensorDetailsPageState,
  IndieSensorDetailsPageState.selectError,
);

/**
 * Add Indie Sensors Page State
 */
export const selectAddIndieSensorPageState = createSelector(
  selectFeatureState,
  state => state.addIndieSensorsPage,
);

export const selectSavingIndieSensor = createSelector(
  selectAddIndieSensorPageState,
  AddIndieSensorPageState.selectSaving,
);

export const selectAddingIndieSensorError = createSelector(
  selectAddIndieSensorPageState,
  AddIndieSensorPageState.selectError,
);

export const selectAddIndieSensorPageIsLoading = createSelector(
  selectAddIndieSensorPageState,
  AddIndieSensorPageState.selectIsLoading,
);

export const selectAddIndieSensorPageIsReady = createSelector(
  selectAddIndieSensorPageIsLoading,
  isLoading => !isLoading,
);

export const selectAddIndieSensorPageHardwareType = createSelector(
  selectAddIndieSensorPageState,
  (state): CreatableHardwareType | null => {
    return AddIndieSensorPageState.selectHardwareType(state);
  },
);

/**
 * Edit Indie Sensors Page State
 */
export const selectEditIndieSensorPageState = createSelector(
  selectFeatureState,
  state => state.editIndieSensorsPage,
);

export const selectEditIndieSensorPageIsLoading = createSelector(
  selectEditIndieSensorPageState,
  EditIndieSensorPageState.selectIsLoading,
);

export const selectEditIndieSensorId = createSelector(
  selectEditIndieSensorPageState,
  EditIndieSensorPageState.selectIndieSensorId,
);

export const selectEditIndieSensor = createSelector(
  CoreState.selectAllIndieSensors,
  selectEditIndieSensorId,
  (indieSensors, indieSensorEditId) => {
    return indieSensors.find(indieSensor => indieSensor.id === indieSensorEditId);
  },
);

/**
 * Custom Indie Sensors Page Selectors
 */
export const selectIndieSensorsPageShowGettingStartedCard = createSelector(
  CoreState.selectTotalNumberOfIndieSensors,
  selectIndieSensorsPageIsLoading,
  (numberOfIndieSensors, isLoading) => numberOfIndieSensors === 0 && !isLoading,
);

export const selectIndieSensorsPageFilteredSensors = createSelector(
  CoreState.selectIndieSensorDetailsViewModels,
  selectIndieSensorsPageSearchTerm,
  (indieSensors, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return indieSensors.filter(indieSensor => {
      const termFoundInSource = filterBy(
        lowerCaseSearchTerm,
        indieSensor.name.toLowerCase(),
      );

      const termFoundInSnapaddr =
        indieSensor.snapaddr !== undefined
          ? filterBy(lowerCaseSearchTerm, indieSensor.snapaddr.toLowerCase())
          : false;

      // "OK", "LowBattery", "EmptyBattery", etc.
      const statusKeys = Object.values(IndieSensorStatus).filter(
        value => typeof value === 'string',
      ) as string[];

      const activeStatuses: string[] = statusKeys
        .filter(statusKey =>
          Boolean(
            indieSensor.status &
              IndieSensorStatus[statusKey as keyof typeof IndieSensorStatus],
          ),
        )
        .map(status => status.toLowerCase());

      return (
        termFoundInSource ||
        termFoundInSnapaddr ||
        filterBy(lowerCaseSearchTerm, indieSensor.id.toLowerCase(), 'id:') ||
        filterBy(lowerCaseSearchTerm, activeStatuses, 'status:')
      );
    });
  },
);

export const selectIndieSensorsPageGroups = createSelector(
  selectIndieSensorsPageFilteredSensors,
  indieSensors => {
    const [power, custom] = partition(indieSensors, indieSensor =>
      isPowerSensor(indieSensor),
    );

    return { power, custom };
  },
);

export const selectIndieSensorsPagePowerSensors = createSelector(
  selectIndieSensorsPageGroups,
  ({ power }) => power,
);

export const selectIndieSensorsPageCustomSensors = createSelector(
  selectIndieSensorsPageGroups,
  ({ custom }) => custom,
);

export const selectIndieSensorsPageShowPowerCategory = createSelector(
  selectIndieSensorsPagePowerSensors,
  indieSensors => indieSensors.length > 0,
);

export const selectIndieSensorsPageShowCustomCategory = createSelector(
  selectIndieSensorsPageCustomSensors,
  indieSensors => indieSensors.length > 0,
);

export const selectIndieSensorsPageShowNoResultsCard = createSelector(
  selectIndieSensorsPageShowGettingStartedCard,
  selectIndieSensorsPageFilteredSensors,
  (showGettingStartedCard, indieSensors) =>
    !showGettingStartedCard && indieSensors.length === 0,
);

export const selectIndieSensorsPageShowIndieSensors = createSelector(
  selectIndieSensorsPageShowGettingStartedCard,
  showGettingStartedCard => !showGettingStartedCard,
);

export const selectIndieSensorNames = createSelector(
  CoreState.selectAllIndieSensors,
  indieSensors => {
    return indieSensors.map(indieSensor => indieSensor.name);
  },
);
