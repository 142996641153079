import {
  SceneViewsApiActions,
  SceneViewsPageActions,
} from '@spog-ui/scene-views/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  error: any;
  loading: boolean;
}

export const initialState: Shape = {
  error: null,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(SceneViewsApiActions.loadFailureAction, (state, action) => ({
    error: action.error,
    loading: false,
  })),
  on(SceneViewsApiActions.loadSuccessAction, state => ({
    error: null,
    loading: false,
  })),
  on(SceneViewsPageActions.enterAction, (state, action) => ({
    ...state,
    loading: true,
  })),
);

export const selectError = (state: Shape) => state.error;
export const selectIsLoadingModels = (state: Shape) => state.loading;
