import { SocketActions } from '@spog-ui/socket/actions';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import * as LightsState from './lights';
import * as LightZonesState from './light-zone';
import * as ThermostatsState from './thermostats';
import * as ControlZonesState from './control-zones';

type ExpectedShape = {
  lights: LightsState.Shape;
  lightZones: LightZonesState.Shape;
  controlZones: ControlZonesState.Shape;
  thermostats: ThermostatsState.Shape;
};

type ExpectedReducer<T extends ExpectedShape> = (state: T | undefined, action: any) => T;

export function zoneControlMetaReducer<T extends ExpectedShape>(
  reducer: ExpectedReducer<T>,
) {
  /**
   * unknownAction is typed as "any" here because this metareducer
   * could handle truly any action and there's not a better type
   * to express this.
   *
   * Once I've detected the type in the switch statements I cast it
   * by assigning it to a well typed `action` const.
   */
  return function (state: T | undefined, unknownAction: any): T {
    if (state === undefined) return reducer(state, unknownAction);

    switch (unknownAction.type) {
      case SocketActions.zoneControlAction.type: {
        const action: ReturnType<typeof SocketActions.zoneControlAction> =
          unknownAction as any;
        const lightUpdates = LightZonesState.selectAll(state.lightZones)
          .filter(lightZone => lightZone.zoneId === action.zoneId)
          .map(lightZone => ({
            id: lightZone.lightId,
            changes: { level: action.level },
          }));

        const updatedState = Object.assign({}, state, {
          lights: LightsState.adapter.updateMany(lightUpdates, state.lights),
        });

        return reducer(updatedState, action);
      }

      case SocketActions.climateZoneControlAction.type: {
        const action: ReturnType<typeof SocketActions.climateZoneControlAction> =
          unknownAction as any;

        const controlZones: ZoneInternalModel[] = ControlZonesState.selectAll(
          state.controlZones,
        ).filter(controlZone => controlZone.id === action.climateZoneId);

        // If there are no thermostats associated with the control zone no state update is needed
        if (controlZones.length > 0 && controlZones[0].deviceIds) {
          const thermostatUpdates = controlZones[0].deviceIds.map(thermostatId => ({
            id: thermostatId,
            changes: { controlMode: action.controlMode },
          }));

          const updatedState = Object.assign({}, state, {
            thermostats: ThermostatsState.adapter.updateMany(
              thermostatUpdates,
              state.thermostats,
            ),
          });

          return reducer(updatedState, action);
        } else {
          return reducer(state, action);
        }
      }

      default: {
        return reducer(state, unknownAction);
      }
    }
  } as ExpectedReducer<T>;
}
