import { NgModule } from '@angular/core';
import { PendingButtonComponent } from '@spog-ui/shared/components';
import { ApplySceneButtonComponent } from './apply-scene-button.component';

@NgModule({
  imports: [PendingButtonComponent],
  declarations: [ApplySceneButtonComponent],
  exports: [ApplySceneButtonComponent],
})
export class ApplySceneButtonComponentModule {}
