import { NgModule } from '@angular/core';
import { SuiPlatformModule } from '../platform';
import { BODY_SERVICE_PROVIDER, BodyService } from './body.service';

@NgModule({
  imports: [SuiPlatformModule],
  providers: [BODY_SERVICE_PROVIDER],
})
export class SuiBodyModule {}

export { BodyService };
