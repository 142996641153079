import { get as lodashGet, set as lodashSet } from 'lodash';
import { ActionReducer, Action } from '@ngrx/store';
import { AuthActions } from '@spog-ui/current-user/auth-actions';
import { SiteSelectorPageActions } from '@spog-ui/site-selector/actions';
import { SiteDetailsActions } from '@spog-ui/site-details/actions';
import { SiteGuardActions } from '@spog-ui/shared/state/site/site-actions';

/**
 * This metareducer intercepts certain actions in order to reset all state
 * when changing sites or changing auth status
 *
 * - When logging out, it resets everything except the `core.clock` state
 * - When changing sites or logging in, it resets all state except some that
 *   need to persist
 */
export function resetStateMetaReducer<State extends Record<string, unknown>>(
  reducer: ActionReducer<State, any>,
) {
  return function (state: State | undefined, action: Action) {
    if (state === undefined) return reducer(state, action);

    switch (action.type) {
      case AuthActions.logoutSuccessAction.type: {
        const nextState = resetState(state, ['core.clock']);

        return reducer(nextState, action);
      }

      case AuthActions.loginSuccessAction.type:
      case SiteGuardActions.invalidatedSiteAction.type:
      case SiteSelectorPageActions.selectAction.type:
      case SiteGuardActions.changedSiteAction.type:
      case SiteDetailsActions.logInToSiteAction.type:
      case SiteDetailsActions.logInToSiteWithElevatedAccessAction.type: {
        // See docs for `resetState` function below for key format
        const nextState = resetState(state, [
          'core.browser',
          'core.clock',
          'core.eula',
          'core.page',
          'core.router',
          'core.site',
          'core.siteControllers',
          'core.siteSelector',
          // organizations state is coupled with the site selector
          'organizations.organizationsList',
        ]);

        return reducer(nextState, action);
      }

      default: {
        return reducer(state, action);
      }
    }
  };
}

/**
 * We rely on the lodash `get` method to specify the paths to retain
 * in the state object.
 *
 * The basic syntax is as below:
 * Example object:
 * {
 *   a: {
 *     b: {
 *       c: [
 *         { d: 'd' }
 *       ]
 *     }
 *   }
 * }
 *
 * To retain the nested { d: 'd' } we specify `a.b.c[0].d`
 * To retain everything within `a.b.c` we specify `a.b.c`
 * To retain everything under the top-level `a` we specify `a`
 *
 * @param state Previous state
 * @param allowedStatePathsArray Array of allowed paths specified as lodash paths
 * @returns new state object containing only the allowed paths
 */
function resetState(
  state: Record<string, unknown>,
  allowedStatePathsArray: string[],
): any {
  const newState = allowedStatePathsArray.reduce<Record<string, unknown>>(
    (memo, path) => {
      const newVal = lodashGet(state, path);

      if (newVal) {
        lodashSet(memo, path, newVal);
      }

      return memo;
    },
    {},
  );

  return newState;
}
