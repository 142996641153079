import { createAction } from '@ngrx/store';
import {
  PowerSourceComparisonInternalModel,
  PowerSourceModel,
} from '@spog-ui/shared/models/power-source-comparisons';
import { BaselineType } from '@spog-ui/graphql/types';

export const openBaselineAction = createAction('[Power] Open Baseline');
export const skipBaselineAction = createAction('[Power] Skip Baseline');
export const removeBaselineAction = createAction('[Power] Remove Baseline');
export const closeBaselineAction = createAction('[Power] Close Baseline');
export const saveBaselineAction = createAction(
  '[Power] Save Baseline',
  (baseline: number, baselineType: BaselineType) => {
    return { baseline, baselineType };
  },
);
export const selectPowerSourceAction = createAction(
  '[Power] Select Power Source',
  (powerSource: PowerSourceModel) => {
    return { powerSource };
  },
);
export const removeComparisonAction = createAction(
  '[Power] Remove Comparison',
  (comparison: PowerSourceComparisonInternalModel) => {
    return { comparison };
  },
);
export const addComparisonAction = createAction('[Power] Add Comparison');
export const enterAction = createAction('[Power] Enter');
export const leaveAction = createAction('[Power] Leave');
export const downloadCsvAction = createAction('[Power] Download CSV');
