import {
  SiteControllerApiActions,
  SiteControllerAdminPage,
} from '@spog-ui/shared/effects/actions';
import { createReducer, on } from '@ngrx/store';
import { SiteControllerPage } from '@spog-ui/site-controllers/actions';

export interface Shape {
  isLoading: boolean;
  pageIndex: number;
  pageSize: number;
  filter: string;
}

export const initialState: Shape = {
  isLoading: true,
  pageIndex: 0,
  pageSize: 50,
  filter: '',
};

export const reducer = createReducer(
  initialState,
  on(SiteControllerAdminPage.enterAction, state => {
    return { ...initialState, filter: state.filter };
  }),
  on(SiteControllerApiActions.loadAllAdminSuccessAction, state => {
    return { ...state, isLoading: false };
  }),
  on(SiteControllerApiActions.loadAllFailureAction, (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  }),
  on(SiteControllerAdminPage.paginateAction, (state, action) => {
    return { ...state, pageIndex: action.pageIndex, pageSize: action.pageSize };
  }),
  on(SiteControllerAdminPage.filterAction, (state, action) => {
    return { ...state, filter: action.searchTerm, pageIndex: 0 };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectPageIndex = (state: Shape) => state.pageIndex;
export const selectPageSize = (state: Shape) => state.pageSize;
export const selectFilter = (state: Shape) => state.filter;
