import { createSelector } from '@ngrx/store';
import { selectFeature } from './constants';
import {
  STATE_KEY,
  selectIsLoading,
  selectError,
} from './admin-organization-shell-page.reducer';

export const selectOrgAdminShellState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsAdminOrgShellPageLoading = createSelector(
  selectOrgAdminShellState,
  selectIsLoading,
);

export const selectAdminOrgShellPageError = createSelector(
  selectOrgAdminShellState,
  selectError,
);
