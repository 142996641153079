import { createReducer, on } from '@ngrx/store';
import {
  AddEquipmentApiActions,
  AddEquipmentPageActions,
} from '@spog-ui/equipment/actions';

export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  loadingError?: any;
}

export const initialState: Shape = {
  isLoading: false,
  isSubmitting: false,
};

export const reducer = createReducer(
  initialState,
  on(AddEquipmentPageActions.enterAction, (): Shape => {
    return initialState;
  }),
  on(AddEquipmentPageActions.loadPageModelsFromGQL, (state): Shape => {
    return { ...state, isLoading: true };
  }),
  on(AddEquipmentApiActions.loadFormDataSuccessAction, (state): Shape => {
    return { ...state, isLoading: false };
  }),
  on(AddEquipmentApiActions.loadFormDataFailureAction, (state, action): Shape => {
    return { ...state, isLoading: false, loadingError: action.error };
  }),
  on(AddEquipmentPageActions.addEquipmentAction, (state): Shape => {
    return { ...state, isSubmitting: true };
  }),
  on(AddEquipmentApiActions.createEquipmentFailure, (state): Shape => {
    return { ...state, isSubmitting: false };
  }),
  on(AddEquipmentApiActions.createEquipmentSuccess, (state): Shape => {
    return { ...state, isSubmitting: false };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
export const selectLoadingError = (state: Shape) => state.loadingError;
