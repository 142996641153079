import { Component, HostBinding, Input } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'sui-progress-bar',
    template: `
    <mat-progress-bar
      [mode]="mode"
      [color]="color"
      [value]="value"
      [bufferValue]="buffer"
    >
    </mat-progress-bar>
  `,
    styles: [
        `
      :host {
        position: relative;
        background-color: var(--color-300);
        height: 6px;
        display: block;
        width: 100%;
        background-clip: padding-box;
        overflow: hidden;
      }

      :host ::ng-deep .mat-progress-bar {
        height: 6px;
      }

      :host(.colorPrimary) {
        background-color: var(--color-300);
      }

      :host(.colorAccent) {
        background-color: var(--color-accent-300);
      }
    `,
    ],
    standalone: true,
    imports: [MatProgressBar],
})
export class ProgressBarComponent {
  @Input()
  color: 'primary' | 'accent' = 'primary';
  @Input()
  mode: 'determinate' | 'indeterminate' | 'buffer' | 'query' = 'indeterminate';
  @Input()
  value = 0;
  @Input()
  buffer = 0;

  @HostBinding('class.colorPrimary')
  get isPrimary() {
    return this.color === 'primary';
  }

  @HostBinding('class.colorAccent')
  get isAccent() {
    return this.color === 'accent';
  }
}
