import { createAction } from '@ngrx/store';
import { Bridge485InternalModel } from '@spog-ui/shared/models/bridge-485s';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';

export const loadIndieSensorDetailsPageModelsSuccessAction = createAction(
  '[Indie Sensor Details API] Load Indie Sensor Details Models Success',
  (models: {
    sensor: IndieSensorInternalModel;
    siteControllers: SiteControllerInternalModel[];
    bridge485s: Bridge485InternalModel[];
  }) => ({
    models,
  }),
);

export const loadIndieSensorDetailsPageModelsFailureAction = createAction(
  '[Indie Sensor Details API] Load Indie Sensor Details Models Failure',
  (error: string) => ({
    error,
  }),
);

export const deleteIndustrialSensorSuccessAction = createAction(
  '[Indie Sensor Details API] Delete Industrial Sensor Success',
  (indieSensorId: string) => ({
    indieSensorId,
  }),
);
export const deleteIndustrialSensorFailureAction = createAction(
  '[Indie Sensor Details API] Delete Industrial Sensor Failure',
  (error: any) => ({ error }),
);
