import { createReducer, on } from '@ngrx/store';
import { ScenesPageActions } from '@spog-ui/scenes/actions';
import { SceneStates } from '@spog-ui/shared/models/scenes';
import { SharedSceneApiActions } from '@spog-ui/shared/effects/actions';

export interface Shape {
  [sequenceSceneId: string]: SceneStates;
}

export const initialState: Shape = {};

export const reducer = createReducer(
  initialState,
  on(
    ScenesPageActions.applySequenceSceneAction,
    (state, action): Shape => {
      return {
        ...state,
        [action.sequenceScene.id]: 'pending',
      };
    },
  ),
  on(SharedSceneApiActions.applySequenceSceneSuccessAction, (state, action): Shape => {
    return {
      ...state,
      [action.sequenceSceneId]: 'success',
    };
  }),
  on(SharedSceneApiActions.applySequenceSceneFailureAction, (state, action): Shape => {
    return {
      ...state,
      [action.sequenceScene.id]: 'error',
    };
  }),
  on(SharedSceneApiActions.readySequenceSceneAction, (state, action): Shape => {
    return {
      ...state,
      [action.sequenceSceneId]: 'ready',
    };
  }),
);
