import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Action, select } from '@ngrx/store';
import * as fromBigSelect from './big-select.reducer';

@Injectable()
export class BigSelectState implements OnDestroy {
  private state$ = new BehaviorSubject(fromBigSelect.initialState);
  readonly filter$ = this.select(fromBigSelect.selectFilter);
  readonly tabs$ = this.select(fromBigSelect.selectTabs);
  readonly filteredOptions$ = this.select(fromBigSelect.selectFilteredOptionViews);
  readonly lists$ = this.select(fromBigSelect.selectLists);
  readonly filterIsExpanded$ = this.select(fromBigSelect.selectFilterIsExpanded);
  readonly globalCheckboxIsChecked$ = this.select(
    fromBigSelect.selectGlobalCheckboxIsChecked,
  );
  readonly globalCheckboxIsIndeterminate$ = this.select(
    fromBigSelect.selectGlobalCheckboxIsIndeterminate,
  );
  readonly globalCheckboxIsDisabled$ = this.select(
    fromBigSelect.selectGlobalCheckboxIsDisabled,
  );
  readonly numberOfCheckedOptionsRejectedByFilter$ = this.select(
    fromBigSelect.selectNumberOfCheckedOptionsRejectedByFilter,
  );
  private select<T>(selector: (state: fromBigSelect.Shape) => T) {
    return this.state$.pipe(select(selector));
  }

  dispatch(action: Action): void {
    this.state$.next(fromBigSelect.reducer(this.state$.value, action));
  }

  valueChanges(listTitle: string): Observable<string[]> {
    return this.lists$.pipe(
      map(lists => lists.find(list => list.title === listTitle)),
      distinctUntilChanged(),
      map((list): string[] => {
        if (!list) return [];

        return list.options.reduce((values, option) => {
          if (list.value.has(option.value)) {
            values.push(option.value);
          }

          return values;
        }, [] as string[]);
      }),
    );
  }

  ngOnDestroy(): void {
    this.state$.complete();
  }
}
