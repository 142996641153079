import {
  Component,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { IndustrialSensorDefinedDataEnum } from '@spog-ui/graphql/types';
import { markAsTouched } from '@spog-ui/shared/components';
import {
  fromIndieSensorReadingType,
  getIndieSensorReadingType,
  IndieSensorInternalModel,
  INDIE_SENSOR_CUSTOM_UNITS,
  Sense420IndustrialSensorRequiredProps,
  isIndustrialSensorDefinedDataType,
  IndustrialSensorDataType,
  getIndieSensorUnits,
} from '@spog-ui/shared/models/indie-sensors';
import { isPowerSensor } from '@spog-ui/shared/state/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { formatSnapAddress } from '@spog-ui/shared/directives/unique-snapaddrs-validator';

/**
 * The "unit type" for a non-power (aka "Custom") sensor is either one
 * of the defined types from the INDIE_SENSOR_CUSTOM_UNITS map (like
 * "Concentration" or "Current") or it's "Custom", to tell us that
 * the user is defining their own name and units.
 */
const isCustomUnits = (unitType: string) => unitType === 'Custom';

const POWER_DISPLAY_UNIT = 'Kilowatts';
const POWER_READING_TYPE = 'Power';

/**
 * Describes the state of the form.
 */
type SensorCategory = 'power' | 'custom';

interface Sense420FormModel {
  name: string;
  snapaddr: string;
  sensorCategory: SensorCategory; // What kind of sensor is attached, power or custom?
  readingType: string; // the custom reading type
  unitType: string; // for a custom sensor, either of of the INDIE_SENSOR_CUSTOM_UNITS keys, or "Custom"
  units: string; // for a custom sensor whose unitType is also "Custom", the custom units
  minValue: number;
  maxValue: number;
}

@Component({
  selector: 'spog-sense-420-form',
  template: `
    @if (!isInvalidSensor) {
      <form
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        >
        <mat-form-field id="name">
          <mat-label>Name</mat-label>
          <input
            type="text"
            matInput
            id="nameInput"
            formControlName="name"
            />
          <mat-error [suiFormError]="form.get('name')?.errors"></mat-error>
        </mat-form-field>
        <mat-form-field id="snapaddr">
          <mat-label>SNAP Address</mat-label>
          <input
            id="snapaddrInput"
            type="text"
            matInput
            suiSnapAddress
            [spogValidateUniqueSnapaddrs]="snapaddrs"
            [currentSnapaddr]="currentSnapaddr"
            formControlName="snapaddr"
            class="uppercase"
            />
          <mat-error [suiFormError]="form.get('snapaddr')?.errors"></mat-error>
        </mat-form-field>
        <sui-card-select id="sensorCategory" formControlName="sensorCategory">
          <sui-card-select-label> What kind of sensor is attached? </sui-card-select-label>
          <sui-card-select-option value="power">
            <mat-icon svgIcon="spog_power"></mat-icon>
            <sui-card-select-option-label> Power Sensor </sui-card-select-option-label>
            <sui-card-select-option-hint>
              Used for measuring power consumption
            </sui-card-select-option-hint>
          </sui-card-select-option>
          <sui-card-select-option value="custom">
            <mat-icon>settings_input_antenna</mat-icon>
            <sui-card-select-option-label> Custom Sensor </sui-card-select-option-label>
            <sui-card-select-option-hint>
              Used to measure any kind of data
            </sui-card-select-option-hint>
          </sui-card-select-option>
        </sui-card-select>
        @if (!isInPowerCategory) {
          <mat-form-field id="customSensorType">
            <mat-label>Custom Sensor Type</mat-label>
            <mat-select formControlName="unitType">
              @for (unit of customUnits | keyvalue; track unit) {
                <mat-option [value]="unit.key">
                  {{ unit.key }} ({{ unit.value }})
                </mat-option>
              }
              <mat-option value="Custom"> Other... </mat-option>
            </mat-select>
            <mat-error [suiFormError]="form.get('unitType')?.errors"></mat-error>
          </mat-form-field>
        }
        <div class="row">
          @if (!isInPowerCategory && showCustomReadingControls) {
            <mat-form-field
              id="readingType"
              class="halfWidth"
              >
              <mat-label>Custom Reading Type</mat-label>
              <input
                id="readingTypeInput"
                type="text"
                matInput
                formControlName="readingType"
                />
              <mat-error [suiFormError]="form.get('readingType')?.errors"></mat-error>
            </mat-form-field>
          }
          @if (!isInPowerCategory && showCustomReadingControls) {
            <mat-form-field
              id="units"
              class="halfWidth"
              >
              <mat-label>Custom Units</mat-label>
              <input
                id="unitsInput"
                type="text"
                matInput
                formControlName="units"
                />
              <mat-error [suiFormError]="form.get('units')?.errors"></mat-error>
            </mat-form-field>
          }
        </div>
        <div class="row">
          <mat-form-field class="halfWidth" id="minValue">
            <mat-label>Minimum Value</mat-label>
            <input
              id="minValueInput"
              type="number"
              matInput
              formControlName="minValue"
              />
            <mat-error [suiFormError]="form.get('minValue')?.errors"></mat-error>
            <mat-hint align="end">{{ units }}</mat-hint>
          </mat-form-field>
          <mat-form-field class="halfWidth" id="maxValue">
            <mat-label>Maximum Value</mat-label>
            <input
              id="maxValueInput"
              type="number"
              matInput
              formControlName="maxValue"
              [spogValidateIsGreaterThan]="form.get('minValue')?.value"
              />
            <mat-error [suiFormError]="form.get('maxValue')?.errors"></mat-error>
            <mat-hint align="end">{{ units }}</mat-hint>
          </mat-form-field>
        </div>
        <div class="actions">
          <button mat-raised-button color="accent" [disabled]="isPending">
            {{ isInEditMode ? 'Save Industrial Sensor' : 'Add Industrial Sensor' }}
          </button>
          <a class="cancelButton" (click)="onCancel()" mat-button> Cancel </a>
        </div>
      </form>
    } @else {
      <spog-page-not-found></spog-page-not-found>
    }
    
    `,
  styles: [
    `
      form {
        max-width: 400px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
      }

      mat-form-field {
        margin: 14px 0 6px;
      }

      sui-card-select {
        margin: 14px 0 26px;
      }

      .hidden {
        display: none;
      }

      .actions {
        display: flex;
        flex-direction: row-reverse;
      }

      .actions button {
        margin-left: 8px;
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .halfWidth {
        width: 48%;
      }

      .uppercase {
        text-transform: uppercase;
      }
    `,
  ],
})
export class Sense420FormComponent implements OnInit, OnDestroy {
  isInEditMode = false;
  indieSensorId: string | null = null;
  isInvalidSensor = false;
  isPending = false;
  _showCustomReadingControls = false;
  customUnits = INDIE_SENSOR_CUSTOM_UNITS;
  categoryValueSubscription: Subscription;
  private _indieSensor: IndieSensorInternalModel;

  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    snapaddr: new UntypedFormControl('', [Validators.required]),
    sensorCategory: new UntypedFormControl('power'),
    /**
     * @todo Jake Harris
     * It seems wrong to do all this conversion back and forth between
     * these maps. What's the true, authoritative source? And: are
     * reading types and unit types different?
     */
    readingType: new UntypedFormControl(POWER_READING_TYPE, [Validators.required]),
    unitType: new UntypedFormControl('', [Validators.required]),
    units: new UntypedFormControl(POWER_DISPLAY_UNIT, [Validators.required]),
    minValue: new UntypedFormControl(null, [Validators.required]),
    maxValue: new UntypedFormControl(null, [Validators.required]),
  });

  @Output()
  save = new EventEmitter<{
    isSnapAddrChanged: boolean;
    model: Sense420IndustrialSensorRequiredProps;
  }>();

  @Output()
  cancel = new EventEmitter();

  @Input()
  snapaddrs: string[] = [];

  @Input()
  set indieSensor(indieSensor: IndieSensorInternalModel) {
    this._indieSensor = indieSensor;

    if (indieSensor.hardwareType !== 'VirtualIndustrialSensor') {
      const formModel = toIndieSensorFormModel(indieSensor);
      this.showCustomReadingControls = isCustomUnits(formModel.unitType);
      this.isInEditMode = true;
      this.indieSensorId = indieSensor.id;
      this.form.patchValue(formModel);
    } else {
      this.isInvalidSensor = true;
    }
  }

  @Input()
  set pending(isPending: boolean) {
    isPending ? this.form.disable() : this.form.enable();

    this.isPending = isPending;
  }

  get isInPowerCategory() {
    return this.form.value.sensorCategory === 'power';
  }

  get showCustomReadingControls() {
    return this._showCustomReadingControls;
  }

  set showCustomReadingControls(shown: boolean) {
    this._showCustomReadingControls = shown;
  }

  get units() {
    return this.form.getRawValue().units;
  }

  get currentSnapaddr() {
    return this._indieSensor?.snapaddr;
  }

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.categoryValueSubscription = this.form
      .get('sensorCategory')!
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(this.onSensorCategoryChange.bind(this));

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.form
      .get('unitType')!
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(this.onCustomSensorTypeChange.bind(this));
  }

  ngOnDestroy() {
    this.categoryValueSubscription.unsubscribe();
  }

  onSubmit() {
    markAsTouched(this.form);
    if (this.form.valid) {
      const model = fromIndieSensorFormModel(this.form);

      const isSnapAddrChanged = model.snapaddr !== this._indieSensor?.snapaddr;
      this.save.emit({
        isSnapAddrChanged,
        model,
      });
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  get rawValue() {
    return fromIndieSensorFormModel(this.form);
  }

  /**
   * This function is called when the value for the "sensorCategory" input
   * (aka the Power Sensor vs. Custom Sensor toggle) changes.
   */
  onSensorCategoryChange(sensorCategory: SensorCategory) {
    if (sensorCategory === 'power') {
      this.showCustomReadingControls = false;
      this.form.patchValue({
        readingType: POWER_READING_TYPE,
        unitType: 'Power',
        units: POWER_DISPLAY_UNIT,
      });
    } else {
      this.form.patchValue({
        readingType: '',
        unitType: '',
        units: '',
      });
    }
  }

  /**
   * This function is called when the value for the "unitType" input
   * (aka the Custom Sensor Type dropdown list) changes.
   */
  onCustomSensorTypeChange(unitType: string) {
    if (!this.isInPowerCategory) {
      this.showCustomReadingControls = isCustomUnits(unitType);
      const units = INDIE_SENSOR_CUSTOM_UNITS.get(unitType);
      if (units) {
        this.form.patchValue({
          readingType: unitType,
          units,
        });
      }
    } else {
      this.form.patchValue({
        units: POWER_DISPLAY_UNIT,
      });
    }
  }
}

/**
 * Convert from one of the defined sensor types to one of the
 * "unitType" strings, defined as the keys on the INDIE_SENSOR_CUSTOM_UNITS
 * map.
 */
function getUnitType(definedType: IndustrialSensorDefinedDataEnum) {
  return getIndieSensorReadingType({ type: definedType });
}

function getReadingType(indieSensor: IndieSensorInternalModel) {
  if (!isIndustrialSensorDefinedDataType(indieSensor.dataType)) {
    return {
      readingType: indieSensor.dataType.name,
      unitType: 'Custom',
      units: indieSensor.dataType.units,
    };
  } else {
    return {
      readingType: getIndieSensorReadingType(indieSensor.dataType),
      unitType: getUnitType(indieSensor.dataType.type),
      units: getIndieSensorUnits(indieSensor.dataType),
    };
  }
}

export function toIndieSensorFormModel(
  indieSensor: IndieSensorInternalModel,
): Sense420FormModel {
  const isPower = isPowerSensor(indieSensor);
  const sensorCategory = isPower ? 'power' : 'custom';
  const { unitType, readingType, units } = getReadingType(indieSensor);

  return {
    name: indieSensor.name,
    snapaddr: indieSensor.snapaddr!,
    sensorCategory,
    unitType,
    readingType,
    units: isPower ? POWER_DISPLAY_UNIT : units,
    maxValue: isPower ? toKilowatts(indieSensor.maxValue!) : indieSensor.maxValue!,
    minValue: isPower ? toKilowatts(indieSensor.minValue!) : indieSensor.minValue!,
  };
}

function getDataType(indieSensor: Sense420FormModel): IndustrialSensorDataType {
  if (indieSensor.sensorCategory === 'power') {
    return {
      type: IndustrialSensorDefinedDataEnum.POWER,
    };
  } else {
    if (isCustomUnits(indieSensor.unitType)) {
      return {
        name: indieSensor.readingType,
        units: indieSensor.units,
      };
    } else {
      const type = fromIndieSensorReadingType(indieSensor.unitType)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
      return {
        type,
      };
    }
  }
}

const toKilowatts = (watts: number) => watts / 1000;
const toWatts = (kilowatts: number) => kilowatts * 1000;

/**
 * Convert the form inputs into the set of properties we need to invoke
 * the createSense420 or updateSense420 mutations.
 */
function fromIndieSensorFormModel(
  indieSensorForm: UntypedFormGroup,
): Sense420IndustrialSensorRequiredProps {
  const indieSensor: Sense420FormModel = indieSensorForm.getRawValue();
  const isPowerSensor = indieSensor.sensorCategory === 'power';
  const dataType = getDataType(indieSensor);

  return {
    name: indieSensor.name,
    snapaddr: formatSnapAddress(indieSensor.snapaddr.toLowerCase()),
    maxValue: isPowerSensor ? toWatts(indieSensor.maxValue) : indieSensor.maxValue,
    minValue: isPowerSensor ? toWatts(indieSensor.minValue) : indieSensor.minValue,
    dataType,
  };
}

@Directive({
  selector: '[spogValidateIsGreaterThan]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ValidateIsGreaterThanDirective,
    },
  ],
})
export class ValidateIsGreaterThanDirective implements Validator {
  onValidatorChange = () => void 0 as void;
  otherValue = Infinity;

  @Input('spogValidateIsGreaterThan')
  set valueToCompareAgainst(otherValue: number | null) {
    if (otherValue === null) return;

    this.otherValue = otherValue;
    this.onValidatorChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value: number = control.value;

    return value > this.otherValue
      ? null
      : {
          spogIsLessThanOrEqualTo: true,
        };
  }

  registerOnValidatorChange(onValidatorChange: () => void) {
    this.onValidatorChange = onValidatorChange;
  }
}
