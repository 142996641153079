import {
  Component,
  EventEmitter,
  Input,
  Output,
  Pipe,
  PipeTransform,
  TrackByFunction,
} from '@angular/core';
import { ScheduledUtilityRateViewModel } from '@spog-ui/shared/models/scheduled-utility-rates';
import { UtilityServiceViewModel } from '@spog-ui/shared/models/utility-services';

@Component({
  selector: 'spog-utility-service',
  template: `
    <mat-card>
      <div class="header">
        <mat-icon class="utilityServiceIcon" svgIcon="gauge"></mat-icon>
        <h3>{{ utilityService.name }}</h3>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          *suiIfUserCan="['ssc:siteAdmin']"
          >
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
      <mat-menu #menu="matMenu">
        <a mat-menu-item [routerLink]="'./' + utilityService.id">Edit</a>
        <button mat-menu-item (click)="onDeleteUtilityService()">Delete</button>
      </mat-menu>
    
      <div class="content">
        @for (
          utilityRate of utilityService.scheduledUtilityRates
          | spogSortUtilityRates; track trackByUtilityRateId($index,
          utilityRate)) {
          <spog-scheduled-utility-rate
            [scheduledUtilityRate]="utilityRate"
            (delete)="onDeleteScheduledUtilityRate($event)"
          ></spog-scheduled-utility-rate>
        }
      </div>
    
      <mat-card-actions *suiIfUserCan="['ssc:siteAdmin']">
        <button
          mat-button
          color="primary"
          (click)="onScheduleUtilityRate()"
          class="scheduleUtilityRate"
          >
          Schedule Utility Rate
        </button>
      </mat-card-actions>
    </mat-card>
    `,
  styles: [
    `
      .header {
        display: grid;
        grid-template-columns: 48px 1fr 48px;
        align-items: center;
        padding-bottom: 16px;
      }

      .utilityServiceIcon {
        transform-origin: 0% 50%;
        transform: scale(1.3);
      }

      mat-card {
        padding-right: 16px;
        padding-left: 16px;
        padding-top: 16px;
      }

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: normal;
      }

      .header button {
        justify-self: flex-end;
      }

      .content {
        height: 220px;
        overflow-y: auto;
        border-top: 1px solid var(--color-foreground-divider);
        border-bottom: 1px solid var(--color-foreground-divider);
        width: calc(100% + 32px);
        position: relative;
        left: -16px;
        background-color: var(--color-background-hover);
      }
    `,
  ],
})
export class UtilityServiceComponent {
  @Input() utilityService: UtilityServiceViewModel;
  @Output() deleteUtilityService = new EventEmitter<UtilityServiceViewModel>();
  @Output() scheduleUtilityRate = new EventEmitter<UtilityServiceViewModel>();
  @Output()
  deleteScheduledUtilityRate = new EventEmitter<ScheduledUtilityRateViewModel>();

  trackByUtilityRateId: TrackByFunction<ScheduledUtilityRateViewModel> = (
    _,
    utilityRate,
  ) => utilityRate.id;

  onDeleteUtilityService() {
    this.deleteUtilityService.emit(this.utilityService);
  }

  onScheduleUtilityRate() {
    this.scheduleUtilityRate.emit(this.utilityService);
  }

  onDeleteScheduledUtilityRate(scheduledUtilityRate: ScheduledUtilityRateViewModel) {
    this.deleteScheduledUtilityRate.emit(scheduledUtilityRate);
  }
}

@Pipe({
  name: 'spogSortUtilityRates',
  pure: true,
})
export class SortUtilityRatesPipe implements PipeTransform {
  transform(
    utilityRates: ScheduledUtilityRateViewModel[],
  ): ScheduledUtilityRateViewModel[] {
    return utilityRates.sort((a, b) => {
      // "b - a" sorts in descending order
      return b.startDate.valueOf() - a.startDate.valueOf();
    });
  }
}
