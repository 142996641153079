import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ACCORDION_DIRECTIVES } from './accordion.component';

@NgModule({
    imports: [PortalModule, CommonModule, ACCORDION_DIRECTIVES],
    exports: [ACCORDION_DIRECTIVES],
})
export class SuiAccordionModule {}
