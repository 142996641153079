import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import {
  SuiColorPreviewModule,
  DimmerControlComponent,
  ColorHueControlComponent,
  ColorLuminanceControlComponent,
  ColorSaturationControlComponent,
} from '@spog-ui/shared/components';
import { BFRandomComponent } from './bf-random.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ColorHueControlComponent,
    ColorLuminanceControlComponent,
    ColorSaturationControlComponent,
    SuiColorPreviewModule,
    DimmerControlComponent,
  ],
  declarations: [BFRandomComponent],
  exports: [BFRandomComponent],
})
export class BFRandomComponentModule {}
