import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ScenesApiActions } from '@spog-ui/scenes/actions';
import { BehaviorInternalModel } from '@spog-ui/shared/models/behaviors';
import { LightMapLayerApiActions } from '@spog-ui/light-map-layer/actions';
import { LightZonesApiActions } from '@spog-ui/modules/light-zones-actions';

export type Shape = EntityState<BehaviorInternalModel>;

export function sortById(a: BehaviorInternalModel, b: BehaviorInternalModel): number {
  return a.id.localeCompare(b.id);
}

export const adapter = createEntityAdapter<BehaviorInternalModel>({
  sortComparer: sortById,
});

export const initialState = adapter.getInitialState();

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const reducer = createReducer(
  initialState,
  on(
    LightMapLayerApiActions.loadLightMapLayerDeferredModelsSuccessAction,
    ScenesApiActions.loadSuccessAction,
    LightZonesApiActions.loadLightZonesPageModelsSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.behaviors, state);
    },
  ),
);
