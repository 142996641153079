import { SceneApplication } from '@spog-ui/shared/models/scenes';
import { ScenesApiActions, ScenesPageActions } from '@spog-ui/scenes/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  application: SceneApplication | null;
  error: any;
  loading: boolean;
}

export const initialState: Shape = {
  application: null,
  error: null,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    ScenesApiActions.loadFailureAction,
    ScenesApiActions.duplicateSceneFailureAction,
    (state, action) => ({
      application: state.application,
      error: action.error,
      loading: false,
    }),
  ),
  on(ScenesApiActions.loadSuccessAction, state => ({
    application: state.application,
    error: null,
    loading: false,
  })),
  on(ScenesPageActions.enterAction, (state, action) => ({
    ...state,
    application: action.application,
    loading: true,
  })),
);

export const selectApplication = (state: Shape) => state.application;
export const selectError = (state: Shape) => state.error;
export const selectIsLoadingModels = (state: Shape) => state.loading;
