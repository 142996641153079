import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'spog-eula-form',
  template: `
    <form [formGroup]="eulaForm" (ngSubmit)="onSubmit()">
      @if (eula) {
        <div class="eulaContent" [innerHTML]="eula"></div>
      }
      <div class="spogEulaFormActions">
        @if (showAccept) {
          <button
            suiButton
            id="eulaAcceptButton"
            raised
            color="accent"
            type="submit"
            >
            Accept
          </button>
        }
      </div>
      @if (errorMessage) {
        <sui-alert [message]="errorMessage"></sui-alert>
      }
    </form>
    `,
  styles: [
    `
      .spogEulaFormActions {
        display: flex;
        flex-direction: row-reverse;
      }

      .eulaContent {
        padding: 20px;
      }

      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
})
export class EulaFormComponent {
  @Output()
  accepted: EventEmitter<any> = new EventEmitter();
  @Input()
  eula: string;
  @Input()
  showAccept: boolean;

  errorMessage: any = null;
  @Input()
  set error(error: any) {
    this.errorMessage = error.description;
  }

  eulaForm: UntypedFormGroup = new UntypedFormGroup({});

  onSubmit() {
    this.accepted.emit();
  }
}
