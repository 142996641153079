import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapCanvasComponentModule } from '../map-canvas';
import { ZoomAndPanModule } from '../zoom-and-pan';
import {
  MapCanvasDirective,
  MapProjectedSvgDirective,
  MapSvgDirective,
} from './map-layer-outlets.directives';
import { MapLayersComponent } from './map-layers.component';
import { PortalModule } from '@angular/cdk/portal';
import { RouterModule } from '@angular/router';
import { SubscriptionBannerComponentModule } from '@spog-ui/shared/components';

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    RouterModule,
    MapCanvasComponentModule,
    ZoomAndPanModule,
    SubscriptionBannerComponentModule,
  ],
  declarations: [
    MapLayersComponent,
    MapCanvasDirective,
    MapSvgDirective,
    MapProjectedSvgDirective,
  ],
  exports: [
    MapLayersComponent,
    MapCanvasDirective,
    MapSvgDirective,
    MapProjectedSvgDirective,
  ],
})
export class MapLayersComponentModule {}
