import { createAction } from '@ngrx/store';

export const createOrganizationSuccessAction = createAction(
  '[Organization / API] Create Organization Success',
);

export const createOrganizationFailureAction = createAction(
  '[Organization / API] Create Organization Failure',
  (error?: any) => ({ error }),
);

export const deleteOrganizationSuccessAction = createAction(
  '[Organization / API] Delete Organization Success',
  organization => ({ organization }),
);

export const deleteOrganizationFailureAction = createAction(
  '[Organization / API] Delete Organization Failure',
  (error?: any) => ({ error }),
);

export const updateOrganizationSuccessAction = createAction(
  '[Organization / API] Update Organization Success',
);

export const updateOrganizationFailureAction = createAction(
  '[Organization / API] Update Organization Failure',
  (error?: any) => ({ error }),
);
