import { createAction } from '@ngrx/store';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const validatedSiteAction = createAction('[Site Router Guard] Validated Site');
export const invalidatedSiteAction = createAction('[Site Router Guard] Invalidated Site');
export const changedSiteAction = createAction(
  '[Site Router Guard] Changed Site',
  (site: SiteInternalModel) => {
    return { site };
  },
);
