import { DateTime } from 'luxon';
import {
  OnPremScheduledEventModel,
  Day,
  Frequency,
  ScheduledSceneViewModel,
  ScheduledSceneModel,
  AstroTime,
} from './parser.model';

const RFC_2445_DATETIME_FORMAT = 'yyyyMMdd HHmmss';

export function parseDateTime(input: string): DateTime {
  return DateTime.fromFormat(
    input.replace('T', ' ').replace('Z', ''),
    RFC_2445_DATETIME_FORMAT,
    { zone: 'utc' },
  ).set({ millisecond: 0 });
}

export function serializeDateTime(input: DateTime): string {
  return input.toUTC().toFormat(RFC_2445_DATETIME_FORMAT).replace(' ', 'T') + 'Z';
}

export function parseOffset(offset: string) {
  const isOffsetNegative = offset[0] === '-'; // for example: "-00:14"
  const [hoursString, numbersString] = offset.split(':');
  const hours = Math.abs(parseInt(hoursString, 10)); // we'll handle sign later
  const minutes = hours * 60 + parseInt(numbersString, 10);

  return isOffsetNegative ? -minutes : minutes;
}

export function serializeOffset(offset: number) {
  const absoluteOffset = Math.abs(offset);
  const hours = Math.floor(absoluteOffset / 60);
  const minutes = absoluteOffset % 60;
  const sign = offset < 0 ? '-' : '';

  const paddedHours = hours > 9 ? hours : `0${hours}`;
  const paddedMinutes = minutes > 9 ? minutes : `0${minutes}`;

  return `${sign}${paddedHours}:${paddedMinutes}`;
}

export function parseRRULE(rruleAndExdateDefinition: string) {
  const [rruleString, exdateString] = rruleAndExdateDefinition.split('\n');
  const rruleDefinitions = rruleString.split(';');

  const rruleDictionary: { [rule: string]: string } = rruleDefinitions.reduce(
    (dictionary, definition) => {
      const [key, value] = definition.split('=');

      return { ...dictionary, [key]: value };
    },
    {},
  );

  // 1. Find weekdays
  const days: Day[] = rruleDictionary['BYDAY']
    ? (rruleDictionary['BYDAY'].split(',') as Day[])
    : [];

  // 2. Find until
  const until = rruleDictionary['UNTIL'] ? parseDateTime(rruleDictionary['UNTIL']) : null;

  // 3. Find exdates
  const excludedDates: DateTime[] = exdateString
    ? exdateString
        .replace('EXDATE:', '')
        .split(',')
        .map(datetime => parseDateTime(datetime))
    : [];

  return {
    frequency: Frequency.Weekly,
    days: days,
    until: until,
    excludedDates: excludedDates,
  };
}

export function serializeRRULE(recurrence: ScheduledSceneViewModel['recurrence']) {
  if (!recurrence) {
    return null;
  }

  const until = recurrence.until ? serializeDateTime(recurrence.until) : null;

  const untilDefinition = recurrence.until ? `UNTIL=${until};` : '';
  const frequencyDefinition =
    recurrence.days.length > 0 ? `FREQ=${recurrence.frequency};` : '';
  const daysDefinition =
    recurrence.days.length > 0 ? `BYDAY=${recurrence.days.join(',')}` : '';
  const exdateDefinition =
    recurrence.excludedDates && recurrence.excludedDates.length > 0
      ? `\nEXDATE:${recurrence.excludedDates
          .map(date => serializeDateTime(date))
          .join(',')}`
      : '';

  return `${untilDefinition}${frequencyDefinition}${daysDefinition}${exdateDefinition}`;
}

export function toScheduledSceneViewModel(
  onPremModel: OnPremScheduledEventModel,
): ScheduledSceneViewModel {
  const start = DateTime.fromSQL(onPremModel.dtstart, { zone: 'utc' });

  return {
    id: onPremModel.id,
    name: onPremModel.description,
    sceneId: onPremModel.scene_id,
    astroTime: onPremModel.astrotime,
    astroTimeOffset: onPremModel.offset ? parseOffset(onPremModel.offset) : null,
    start,
    recurrence: onPremModel.rrule ? parseRRULE(onPremModel.rrule) : null,
  };
}

// Format data for backend
export function fromScheduledSceneViewModel(
  scheduledSceneViewModel: ScheduledSceneViewModel,
): OnPremScheduledEventModel {
  const startString = scheduledSceneViewModel.start.toFormat('yyyy-MM-dd HH:mm:ss');
  return {
    id: scheduledSceneViewModel.id,
    description: scheduledSceneViewModel.name,
    scene_id: scheduledSceneViewModel.sceneId,
    astrotime: scheduledSceneViewModel.astroTime,
    offset: scheduledSceneViewModel.astroTimeOffset
      ? serializeOffset(scheduledSceneViewModel.astroTimeOffset)
      : null,
    dtstart: startString,
    rrule: serializeRRULE(scheduledSceneViewModel.recurrence),
  };
}

// Format data from backend (needed in on-prem)
export function fromOnPremModelToScheduledScene(
  onPremModel: OnPremScheduledEventModel,
): ScheduledSceneModel {
  return {
    id: onPremModel.id,
    sceneId: onPremModel.scene_id,
    name: onPremModel.description,
    astroTime: onPremModel.astrotime,
    astroTimeOffset: onPremModel.offset ? parseOffset(onPremModel.offset) : null,
    start: onPremModel.dtstart.replace(' ', 'T') + 'Z',
    rrule: onPremModel.rrule,
  };
}

// Format data for reducer
export function fromViewModelToScheduledScene(
  scheduledSceneViewModel: ScheduledSceneViewModel,
): ScheduledSceneModel {
  const startString = scheduledSceneViewModel.start.toFormat('yyyy-MM-dd HH:mm:ss');

  return {
    id: scheduledSceneViewModel.id,
    name: scheduledSceneViewModel.name,
    sceneId: scheduledSceneViewModel.sceneId,
    astroTime: scheduledSceneViewModel.astroTime,
    astroTimeOffset: scheduledSceneViewModel.astroTimeOffset,
    start: startString,
    rrule: serializeRRULE(scheduledSceneViewModel.recurrence),
  };
}

// Format data for form display
export function fromScheduledSceneModel(
  scheduledSceneModel: ScheduledSceneModel,
): ScheduledSceneViewModel {
  return {
    id: scheduledSceneModel.id,
    name: scheduledSceneModel.name,
    sceneId: scheduledSceneModel.sceneId,
    astroTime:
      scheduledSceneModel.astroTime === null
        ? null
        : (scheduledSceneModel.astroTime as AstroTime),
    astroTimeOffset: scheduledSceneModel.astroTimeOffset
      ? scheduledSceneModel.astroTimeOffset
      : null,
    start: DateTime.fromISO(scheduledSceneModel.start, { setZone: true }),
    recurrence: scheduledSceneModel.rrule ? parseRRULE(scheduledSceneModel.rrule) : null,
  };
}
