import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  ChartingPageComponentModule,
  ChartingPageComponent,
} from './components/charting-page';
import { IfUserCanGuard } from '@spog-ui/current-user/permissions';

export const routes: Routes = [
  {
    path: '',
    component: ChartingPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), ChartingPageComponentModule],
})
export class ChartingRoutingModule {}
