import {
  UserCreateInput,
  UserModelFragment,
  UserRole,
  UserUpdateInput,
} from '@spog-ui/graphql/types';
import { UserAdminUpsert as UserWSModel } from '@spog-shared/events/user';

export { UserWSModel };

export interface CurrentUser {
  id: string;
  name: string;
  email: string;
  picture: string;
}

export interface UserInternalModel {
  id: string;
  name: string;
  email: string;
  role: UserRole;
}

export type UserGQLModel = UserModelFragment;

export interface UserFormModel {
  id: string;
  name: string;
  email: string;
  role: UserRole;
}

export type CreateUserFormModel = Omit<UserFormModel, 'id'>;

export function toUserInternalModelFromGQL(gql: UserGQLModel): UserInternalModel {
  return {
    id: gql.id,
    email: gql.email,
    name: gql.name,
    role: gql.role,
  };
}

export function toUserInternalModelFromWS(ws: UserWSModel): UserInternalModel {
  return ws;
}

export function toUserFormModelFromInternal(user: UserInternalModel): UserFormModel {
  return {
    id: user.id,
    name: user.name,
    email: user.email,
    role: user.role,
  };
}

export function toUserGQLModelFromInternal(user: UserInternalModel): UserGQLModel {
  return {
    id: user.id,
    name: user.name,
    email: user.email,
    role: user.role,
  };
}

export function toUserCreateInput(form: CreateUserFormModel): UserCreateInput {
  return {
    name: form.name,
    email: form.email,
    role: form.role,
  };
}

export function toUserUpdateInput(form: UserFormModel): UserUpdateInput {
  return {
    id: form.id,
    name: form.name,
    email: form.email,
    role: form.role,
  };
}
