import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { TransformModel, ChildRenderer } from '@spog-ui/map-tools/models';
import { MAP_CANVAS_SCALE, MapRendererRef } from '../map-canvas';
import { SpriteSheet, SpriteSheetLoader } from '../../services';
import { map, switchAll } from 'rxjs/operators';
import { MapLayersRef } from '../map-layers';

export const enum SpriteProp {
  id = 0,
  label = 1,
  x = 2,
  y = 3,
}

export type Sprite = [
  /*    id */ string,
  /* label */ number,
  /*     x */ number,
  /*     y */ number,
];

export function sprite(id: string, label: number, x: number, y: number): Sprite {
  return [id, label, x, y];
}

export interface SpriteLayerClickEvent {
  transform: TransformModel;
  point: { x: number; y: number };
  sprite: Sprite | null;
  nearbySprites: Sprite[];
}

@Component({
  selector: 'map-sprite-layer',
  encapsulation: ViewEncapsulation.None,
  template: '',
  styles: [
    `
      .spriteLayerHover {
        cursor: pointer;
      }
    `,
  ],
})
export class SpriteLayerComponent implements OnInit, OnChanges, OnDestroy {
  renderer: ChildRenderer;
  subscriptions = new Subscription();
  clickSource$ = new Subject<Observable<SpriteLayerClickEvent>>();
  @Input() spriteSheet: SpriteSheet;
  @Input() sprites: Sprite[] = [];
  @Input() transform: TransformModel = { k: 1, x: 0, y: 0 };
  @Output() clicked: Observable<SpriteLayerClickEvent> = this.clickSource$.pipe(
    switchAll(),
  );

  constructor(
    readonly rendererRef: MapRendererRef,
    readonly spriteSheetLoader: SpriteSheetLoader,
    readonly layersRef: MapLayersRef,
  ) {}

  ngOnInit() {
    this.setupClickEvent();
    this.setupRenderer();
  }

  async setupRenderer() {
    const resolvedSpriteSheet = await this.spriteSheetLoader.loadSpriteSheet(
      this.spriteSheet,
    );

    this.renderer = this.rendererRef.renderer.createChildRenderer(ctx => {
      const scale = 1 / this.transform.k;
      const spriteSize = resolvedSpriteSheet.spriteSize * scale;

      for (let i = 0, l = this.sprites.length; i < l; i++) {
        const sprite = this.sprites[i];
        const label = sprite[SpriteProp.label];
        const spriteX =
          sprite[SpriteProp.x] * MAP_CANVAS_SCALE -
          (resolvedSpriteSheet.spriteSize / 2) * scale;
        const spriteY =
          sprite[SpriteProp.y] * MAP_CANVAS_SCALE -
          (resolvedSpriteSheet.spriteSize / 2) * scale;

        resolvedSpriteSheet.draw(ctx, label, spriteX, spriteY, spriteSize, spriteSize);
      }
    });

    this.subscriptions.add(this.renderer.teardown);
  }

  setupClickEvent() {
    const eventTarget = this.layersRef.mapLayers.wrapper;

    const click$ = fromEvent<MouseEvent>(eventTarget, 'click').pipe(
      map((event): SpriteLayerClickEvent => {
        const { transform, sprites, spriteSheet } = this;
        const { x, y } = getMapPointAtMouseEvent(event, eventTarget, transform);
        const nearbySprites = getSpritesNearMapPoint(transform, x, y, sprites);
        const sprite = getSpriteAtMapPoint(
          transform,
          x,
          y,
          nearbySprites,
          spriteSheet.size,
        );

        return {
          point: { x, y },
          transform,
          sprite,
          nearbySprites,
        };
      }),
    );

    this.clickSource$.next(click$);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sprites && this.renderer) {
      this.renderer.markForRender();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

function getMapPointAtMouseEvent(
  event: MouseEvent,
  wrapper: Element,
  transform: TransformModel,
) {
  const { clientX, clientY } = event;
  const { left: offsetX, top: offsetY } = wrapper.getBoundingClientRect();
  const inverseTransform = {
    k: 1 / transform.k,
    x: -1 * transform.x,
    y: -1 * transform.y,
  };

  const actualX = (clientX + inverseTransform.x - offsetX) * inverseTransform.k;
  const actualY = (clientY + inverseTransform.y - offsetY) * inverseTransform.k;

  return { x: actualX, y: actualY };
}

const MOBILE_FRIENDLY_TOUCH_SIZE = 34;
function getSpritesNearMapPoint(
  transform: TransformModel,
  x1: number,
  y1: number,
  sprites: Sprite[],
  searchDistance: number = MOBILE_FRIENDLY_TOUCH_SIZE,
): Sprite[] {
  const scale = 1 / transform.k;

  return sprites.filter(sprite => {
    const x2 = sprite[SpriteProp.x];
    const y2 = sprite[SpriteProp.y];
    const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const scaledSearchDistance = searchDistance * scale;

    return distance <= scaledSearchDistance;
  });
}

function getSpriteAtMapPoint(
  transform: TransformModel,
  x1: number,
  y1: number,
  sprites: Sprite[],
  spriteSize: number,
): Sprite | null {
  const scale = 1 / transform.k;

  for (let i = 0, l = sprites.length; i < l; i++) {
    const sprite = sprites[i];
    const x2 = sprite[SpriteProp.x];
    const y2 = sprite[SpriteProp.y];
    const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const radius = spriteSize / MAP_CANVAS_SCALE / 2;
    const scaledRadius = radius * scale;

    if (distance <= scaledRadius) return sprite;
  }

  return null;
}
