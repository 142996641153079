import { createReducer, on } from '@ngrx/store';
import { SceneApplication } from '@spog-ui/shared/models/scenes';
import { AddScenePageActions, ScenesApiActions } from '@spog-ui/scenes/actions';

export interface Shape {
  application: SceneApplication | null;
  error?: any;
  adding: boolean;
  loading: boolean;
}

const initialState: Shape = {
  application: null,
  adding: false,
  loading: true,
};

export const reducer = createReducer(
  initialState,
  on(AddScenePageActions.enterAction, (state, action) => ({
    application: action.application,
    adding: false,
    loading: true,
  })),
  on(AddScenePageActions.addSceneAction, state => {
    return {
      ...state,
      error: null,
      adding: true,
      loading: false,
    };
  }),
  on(ScenesApiActions.addSceneSuccessAction, state => {
    return {
      ...state,
      error: null,
      adding: false,
      loading: false,
    };
  }),
  on(ScenesApiActions.addSceneFailureAction, (state, action) => {
    return {
      ...state,
      error: action.error,
      adding: false,
      loading: false,
    };
  }),
  on(ScenesApiActions.loadSuccessAction, state => {
    return {
      ...state,
      error: null,
      adding: false,
      loading: false,
    };
  }),
  on(ScenesApiActions.loadFailureAction, (state, action) => {
    return {
      ...state,
      error: action.error,
      adding: false,
      loading: false,
    };
  }),
);

export const selectApplication = (state: Shape) => state.application;
export const selectAdding = (state: Shape) => state.adding;
export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
