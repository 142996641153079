import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent, SuiCardModule } from '@spog-ui/shared/components';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { SceneZoneBehaviorsComponentModule } from '../scene-zone-behaviors';
import { ApplySceneButtonComponentModule } from '../apply-scene-button';
import { ClimateSceneComponent } from './climate-scene.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ButtonComponent,
    SuiCardModule,
    PermissionsModule,
    SceneZoneBehaviorsComponentModule,
    ApplySceneButtonComponentModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
  ],
  declarations: [ClimateSceneComponent],
  exports: [ClimateSceneComponent],
})
export class ClimateSceneComponentModule {}
