import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as EnergyInfoSystemState from '@spog-ui/shared/state/energy-info-system';

@Component({
  selector: 'spog-no-utility-services',
  template: `
    @if (showGetStartedCard$ | async) {
      <sui-empty-state>
        <ng-template suiEmptyStateTitle> Get Started </ng-template>
        <ng-template suiEmptyStateMessage>
          No utility services have been configured. Please add a utility service to get
          started.
        </ng-template>
        <ng-template suiEmptyStateActions>
          <a
            routerLink="./add"
            suiButton
            class="addUtilityService"
            *suiIfUserCan="['ssc:siteAdmin']"
            >Add Utility Service</a
            >
          </ng-template>
        </sui-empty-state>
      }
    `,
  styles: [
    `
      :host {
        display: block;
        width: calc(100% - 40px);
        max-width: 560px;
        margin: 0 auto;
      }
    `,
  ],
})
export class NoUtilityServicesComponent {
  constructor(private store: Store) {}
  showGetStartedCard$ = this.store.select(
    EnergyInfoSystemState.selectUtilityServicesPageShouldShowGettingStartedCard,
  );
}
