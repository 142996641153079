import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { EditIndieSensorPage } from '@spog-ui/indie-sensors/actions';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import * as SenseState from '@spog-ui/shared/state/sense';
import * as CoreState from '@spog-ui/shared/state/core';
import { Sense420IndustrialSensorRequiredProps } from '@spog-ui/shared/models/indie-sensors';

@Component({
  selector: 'spog-edit-indie-sensor-page',
  template: `
    @if (isLoading$ | async) {
      <div class="loading">
        <sui-spinner></sui-spinner>
      </div>
    } @else {
      @switch ((indieSensorToEdit$ | async)?.hardwareType) {
        @case ('Sense420Sensor') {
          <spog-sense-420-form
            [pending]="isSaving$ | async"
            [snapaddrs]="snapaddrs$ | async"
            [indieSensor]="indieSensorToEdit$ | async"
            (save)="onSaveSense420($event)"
            (cancel)="onCancel()"
            >
          </spog-sense-420-form>
        }
      }
    }
    `,
  styles: [
    `
      sui-spinner {
        display: block;
        width: 40px;
        margin: 100px auto 0;
      }
    `,
  ],
})
export class EditIndieSensorPageComponent implements OnInit, OnDestroy {
  id: string;
  sub: Subscription;

  isLoading$ = this.store.select(SenseState.selectEditIndieSensorPageIsLoading);
  isSaving$ = this.store.select(SenseState.selectSavingIndieSensor);
  snapaddrs$ = this.store.select(CoreState.selectUsedSnapaddrs);
  siteControllers$ = this.store.select(CoreState.selectAllSiteControllers);
  indieSensorToEdit$ = this.store.select(SenseState.selectEditIndieSensor);

  constructor(
    public store: Store,
    private route: ActivatedRoute,
    readonly location: Location,
  ) {}

  /**
   * @todo Jake Harris
   * This type seems wrong. We should use a FormModel, or we should use a
   * type that actually specifies the required props -- not one that labels half
   * of them as conditional, when they are not conditional in the form.
   */
  onSaveSense420($event: {
    isSnapAddrChanged: boolean;
    model: Sense420IndustrialSensorRequiredProps;
  }) {
    if ($event.isSnapAddrChanged) {
      this.store.dispatch(
        EditIndieSensorPage.editSense420IndieSensorWithChangedSnapAddrAction(
          $event.model,
          this.id,
        ),
      );
    } else {
      this.store.dispatch(
        EditIndieSensorPage.editSense420IndieSensorAction($event.model, this.id),
      );
    }
  }

  onCancel() {
    this.location.back();
  }

  ngOnInit(): void {
    this.sub = this.route.paramMap
      .pipe(
        map(param => param.get('id')),
        filter((id): id is string => !!id),
        tap((id: string) => (this.id = id)),
        map(EditIndieSensorPage.enterAction),
      )
      .subscribe(action => this.store.dispatch(action));
  }

  ngOnDestroy(): void {
    this.store.dispatch(EditIndieSensorPage.leaveAction());
    this.sub.unsubscribe();
  }
}
