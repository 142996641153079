import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import {
  SuiColorPreviewModule,
  DimmerControlComponent,
  ColorHueControlComponent,
  ColorLuminanceControlComponent,
  ColorSaturationControlComponent,
} from '@spog-ui/shared/components';
import { SpeedControlComponentModule } from '../speed-control';
import { BFSparkleComponent } from './bf-sparkle.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ColorHueControlComponent,
    ColorLuminanceControlComponent,
    ColorSaturationControlComponent,
    SuiColorPreviewModule,
    DimmerControlComponent,
    SpeedControlComponentModule,
  ],
  declarations: [BFSparkleComponent],
  exports: [BFSparkleComponent],
})
export class BFSparkleComponentModule {}
