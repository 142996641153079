import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { CurrentUserActions } from '@spog-ui/current-user/auth-actions';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.handleHttpError(error)));
  }

  handleHttpError(error: any) {
    if (
      error &&
      error instanceof HttpErrorResponse &&
      this.isUnauthenticated(error) &&
      !this.router.url.startsWith('/login')
    ) {
      this.store.dispatch(CurrentUserActions.requestLoginAction());
      this.router.navigate(['/login']);
    }
    return throwError(() => error);
  }

  isUnauthenticated(error: any) {
    return (
      error.status === 401 ||
      (error.status === 400 &&
        error.errors != null &&
        error.errors[0].extensions?.code === 'UNAUTHENTICATED')
    );
  }
}
