import { Component } from '@angular/core';

@Component({
    selector: 'sui-card-body',
    template: ` <ng-content></ng-content> `,
    styles: [
        `
      :host {
        display: block;
        width: 100%;
        padding: 16px;
      }
    `,
    ],
    standalone: true,
})
export class CardBodyComponent {}
