import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { UtilityServicesPageActions } from '@spog-ui/utility-services/actions';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import * as CoreState from '@spog-ui/shared/state/core';
import * as EnergyInfoSystemState from '@spog-ui/shared/state/energy-info-system';
import { UtilityServiceViewModel } from '@spog-ui/shared/models/utility-services';
import { CreateScheduledUtilityRateInput } from '@spog-ui/graphql/types';

@Component({
  selector: 'spog-schedule-utility-rate-modal',
  template: `
    <h3 mat-dialog-title>Schedule Utility Rate</h3>
    
    @if (utilityService$ | async; as utilityService) {
      <div mat-dialog-content>
        <spog-schedule-utility-rate-form
          [utilityService]="utilityService"
          [now]="now$ | async"
          [siteTimeZone]="siteTimeZone$ | async"
          [disabled]="isSaving$ | async"
          (save)="onSave($event)"
          (cancel)="onCancel()"
          >
        </spog-schedule-utility-rate-form>
      </div>
    }
    `,
  styles: [
    `
      h3 {
        margin-bottom: 0;
      }
    `,
  ],
})
export class ScheduleUtilityRateModalComponent {
  now$: Observable<DateTime>;
  siteTimeZone$: Observable<string>;
  utilityService$: Observable<UtilityServiceViewModel | undefined>;
  isSaving$: Observable<boolean>;

  constructor(readonly store: Store) {
    this.now$ = store.select(CoreState.selectSiteTime);
    this.siteTimeZone$ = store.select(CoreState.selectSelectedSiteTimeZone);
    this.utilityService$ = store.select(
      EnergyInfoSystemState.selectUtilityServiceBeingScheduled,
    );
    this.isSaving$ = store.select(
      EnergyInfoSystemState.selectScheduleUtilityRateIsSaving,
    );
  }

  onSave($event: CreateScheduledUtilityRateInput) {
    this.store.dispatch(
      UtilityServicesPageActions.saveScheduledUtilityRateAction($event),
    );
  }

  onCancel() {
    this.store.dispatch(UtilityServicesPageActions.cancelSchedulingUtilityRateAction());
  }
}
