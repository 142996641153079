import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PermissionGroupsApiService } from '../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  catchError,
  map,
  switchMap,
  tap,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of, defer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import {
  PermissionGroupApiActions,
  AddPermissionGroupPageActions,
  EditPermissionGroupPageActions,
  PermissionGroupsPageActions,
} from '../actions';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  toCreatePermissionGroupInputFromPermissionGroupForm,
  toUpdatePermissionGroupInputFromPermissionGroupForm,
} from '@spog-ui/shared/models/permission-groups';
import { Location } from '@angular/common';
import { PromptService } from '@sui/prompt';
import { Store } from '@ngrx/store';
import { selectActiveOrgId } from '@spog-ui/shared/state/organizations';
import { PermissionGroupStateActions } from '@spog-ui/shared/state/permission-groups';

@Injectable()
export class PermissionGroupApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly permissionGroupsApiService: PermissionGroupsApiService,
    readonly gql: GraphQLAPIService,
    readonly snackbar: MatSnackBar,
    readonly location: Location,
    readonly promptService: PromptService,
    readonly store: Store,
  ) {}

  createPermissionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddPermissionGroupPageActions.addPermissionGroupAction),
      withLatestFrom(defer(() => this.store.select(selectActiveOrgId))),
      mergeMap(([action, activeOrgId]) => {
        const input = toCreatePermissionGroupInputFromPermissionGroupForm(
          action.permissionGroup,
          activeOrgId!,
        );
        return this.gql.createPermissionGroup({ input }).pipe(
          map(() => PermissionGroupApiActions.createPermissionGroupSuccessAction()),
          catchError(error => {
            return of(
              PermissionGroupApiActions.createPermissionGroupFailureAction(error),
            );
          }),
        );
      }),
    ),
  );

  redirectToPermissionGroupsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PermissionGroupApiActions.createPermissionGroupSuccessAction,
          PermissionGroupApiActions.updatePermissionGroupSuccessAction,
        ),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  createPermissionGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PermissionGroupApiActions.createPermissionGroupFailureAction),
        tap(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );

  editPermissionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPermissionGroupPageActions.editPermissionGroupAction),
      switchMap(action => {
        const input = toUpdatePermissionGroupInputFromPermissionGroupForm(
          action.permissionGroup,
        );
        return this.gql.updatePermissionGroup({ input }).pipe(
          map(() => PermissionGroupApiActions.updatePermissionGroupSuccessAction()),
          catchError(error => {
            return of(
              PermissionGroupApiActions.updatePermissionGroupFailureAction(error),
            );
          }),
        );
      }),
    ),
  );

  editPermissionGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PermissionGroupApiActions.updatePermissionGroupFailureAction),
        tap(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionGroupsPageActions.confirmDeleteAction),
      mergeMap(({ permissionGroup }) =>
        this.permissionGroupsApiService.delete(permissionGroup.id).pipe(
          map(() =>
            PermissionGroupStateActions.deletePermissionGroupAction(permissionGroup),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              PermissionGroupApiActions.deletePermissionGroupFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  notifyOnDeleteError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PermissionGroupApiActions.deletePermissionGroupFailureAction),
        tap(() =>
          this.snackbar.open(
            'Failed to delete the permission group. Please try again',
            'Dismiss',
            {
              duration: 7_500,
            },
          ),
        ),
      ),
    { dispatch: false },
  );
}
