import { createAction } from '@ngrx/store';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';

export const loadPageSuccessAction = createAction(
  '[Admin Organization] Load Page Success',
  (organization: OrganizationInternalModel) => ({
    organization,
  }),
);

export const loadPageFailureAction = createAction(
  '[Admin Organization] Load Page Failure',
  (error: string) => ({ error }),
);

export const updateOrgDefaultSiteRoleSuccessAction = createAction(
  '[Admin Organization] Update Organization Default Site Role Success',
);

export const updateOrgDefaultSiteRoleFailureAction = createAction(
  '[Admin Organization] Update Organization Default Site Role Failure',
  (error?: any) => ({ error }),
);
