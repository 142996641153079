import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { SiteSelectorPageComponent } from './site-selector-page.component';
import { NoSitesComponentModule } from '../no-sites';
import { SiteSelectorComponentModule } from '../site-selector';

@NgModule({
  imports: [
    CommonModule,
    SuiSpinnerModule,
    SiteSelectorComponentModule,
    NoSitesComponentModule,
  ],
  declarations: [SiteSelectorPageComponent],
  exports: [SiteSelectorPageComponent],
})
export class SiteSelectorPageComponentModule {}
