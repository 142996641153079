import { NgModule } from '@angular/core';
import {
  PanAndZoomContainerComponent,
  PanAndZoomTargetDirective,
} from './zoom-and-pan.component';

@NgModule({
  declarations: [PanAndZoomContainerComponent, PanAndZoomTargetDirective],
  exports: [PanAndZoomContainerComponent, PanAndZoomTargetDirective],
})
export class ZoomAndPanModule {}
