import { createAction } from '@ngrx/store';
import { SceneZoneApiModel } from '@spog-ui/shared/models/scene-zone-behaviors';
import { SceneApplication } from '@spog-ui/shared/models/scenes';

export const enterAction = createAction(
  '[Edit Scene Page] Enter | @spog-sui/scenes',
  (sceneId: string, application: SceneApplication) => ({
    sceneId,
    application,
  }),
);
export const leaveAction = createAction('[Edit Scene Page] Leave | @spog-sui/scenes');
export const editSceneAction = createAction(
  '[Edit Scene Page] Edit Scene | @spog-sui/scenes',
  (scene: SceneZoneApiModel) => ({ scene }),
);

export type Union =
  | ReturnType<typeof enterAction>
  | ReturnType<typeof leaveAction>
  | ReturnType<typeof editSceneAction>;

export const Types = {
  Enter: enterAction.type,
  Leave: leaveAction.type,
  EditScene: editSceneAction.type,
};
