import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DimmerControlComponent } from '@spog-ui/shared/components';
import { BFSwitchPhotocellAndOccupancyComponent } from './bf-switch-photocell-and-occupancy.component';
import { TimeoutControlComponentModule } from '../timeout-control';

@NgModule({
  imports: [ReactiveFormsModule, DimmerControlComponent, TimeoutControlComponentModule],
  declarations: [BFSwitchPhotocellAndOccupancyComponent],
  exports: [BFSwitchPhotocellAndOccupancyComponent],
})
export class BFSwitchPhotocellAndOccupancyComponentModule {}
