import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { ScheduledUtilityRateComponentModule } from '../scheduled-utility-rate';
import {
  SortUtilityRatesPipe,
  UtilityServiceComponent,
} from './utility-service.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    PermissionsModule,
    ScheduledUtilityRateComponentModule,
    RouterModule,
  ],
  declarations: [UtilityServiceComponent, SortUtilityRatesPipe],
  exports: [UtilityServiceComponent],
})
export class UtilityServiceComponentModule {}
