import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import {
  ThermostatStatus,
  toThermostatViewModelFromInternal,
} from '@spog-ui/shared/models/thermostats';
import {
  ClimateZoneViewModel,
  toControlModeFromParameters,
} from '@spog-ui/shared/models/zones';
import * as ThermostatsPageState from './thermostats-page';
import * as AddThermostatPageState from './add-thermostat-page';
import * as EditThermostatPageState from './edit-thermostat-page';
import * as ThermostatDetailsPageState from './thermostat-details-page';
import * as ClimateZoneDetailsPageState from './climate-zone-details-page';
import * as ClimateZonesPageState from './climate-zones-page';
import * as ClimateZoneModalState from './climate-zone-modal';

export const STATE_KEY = 'climate';

export interface Shape {
  addThermostatPage: AddThermostatPageState.Shape;
  editThermostatPage: EditThermostatPageState.Shape;
  thermostatsPage: ThermostatsPageState.Shape;
  thermostatDetailsPage: ThermostatDetailsPageState.Shape;
  climateZoneDetailsPage: ClimateZoneDetailsPageState.Shape;
  climateZonesPage: ClimateZonesPageState.Shape;
  climateZoneModal: ClimateZoneModalState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  thermostatsPage: ThermostatsPageState.reducer,
  addThermostatPage: AddThermostatPageState.reducer,
  editThermostatPage: EditThermostatPageState.reducer,
  thermostatDetailsPage: ThermostatDetailsPageState.reducer,
  climateZoneDetailsPage: ClimateZoneDetailsPageState.reducer,
  climateZonesPage: ClimateZonesPageState.reducer,
  climateZoneModal: ClimateZoneModalState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);
/**
 * Add Thermostat Page
 */
export const selectAddThermostatPageState = createSelector(
  selectFeatureState,
  state => state.addThermostatPage,
);
export const selectIsAddThermostatPageLoading = createSelector(
  selectAddThermostatPageState,
  AddThermostatPageState.selectIsLoading,
);
export const selectIsAddThermostatPageSaving = createSelector(
  selectAddThermostatPageState,
  AddThermostatPageState.selectIsSaving,
);
export const selectAddThermostatPageError = createSelector(
  selectAddThermostatPageState,
  AddThermostatPageState.selectError,
);

/**
 * Edit Thermostat Page
 */
export const selectEditThermostatPageState = createSelector(
  selectFeatureState,
  state => state.editThermostatPage,
);
export const selectIsEditThermostatPageLoading = createSelector(
  selectEditThermostatPageState,
  EditThermostatPageState.selectIsLoading,
);
export const selectIsEditThermostatPageSaving = createSelector(
  selectEditThermostatPageState,
  EditThermostatPageState.selectIsSaving,
);
export const selectEditThermostatPageError = createSelector(
  selectEditThermostatPageState,
  EditThermostatPageState.selectError,
);

/**
 * Thermostats Page
 */
export const selectThermostatsPageState = createSelector(
  selectFeatureState,
  state => state.thermostatsPage,
);
export const selectThermostatsPageIsLoading = createSelector(
  selectThermostatsPageState,
  ThermostatsPageState.selectIsLoading,
);
export const selectThermostatsPageError = createSelector(
  selectThermostatsPageState,
  ThermostatsPageState.selectError,
);
export const selectThermostatsSearchTerm = createSelector(
  selectThermostatsPageState,
  ThermostatsPageState.selectSearchTerm,
);
export const selectThermostatsPageUserIsEditingOrAdding = createSelector(
  selectThermostatsPageState,
  ThermostatsPageState.selectUserIsEditingOrAdding,
);

export const selectThermostatsPageShouldShowGettingStartedCard = createSelector(
  selectThermostatsPageIsLoading,
  CoreState.selectAllThermostats,
  (isLoading, thermostats) => {
    return !isLoading && thermostats.length === 0;
  },
);
export const selectFilteredThermostats = createSelector(
  CoreState.selectAllThermostats,
  CoreState.selectAllThermostatAlarms,
  selectThermostatsSearchTerm,
  (thermostats, alarms, searchTerm) => {
    const filteredThermostats = thermostats.filter(thermostat => {
      return thermostat.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const numAlarms = (thermostatId: string) =>
      alarms.filter(alarm => alarm.thermostatId === thermostatId).length;

    return filteredThermostats.map(t => ({ totalAlarms: numAlarms(t.id), ...t }));
  },
);

export const selectThermostatNames = createSelector(
  CoreState.selectAllThermostats,
  thermostats => {
    return thermostats.map(thermostat => thermostat.name);
  },
);

/**
 * Thermostat Details
 */
export const selectActiveThermostatId = createSelector(
  CoreState.selectRouterParams,
  params => params.activeThermostatId as string | undefined | null,
);

export const selectActiveThermostat = createSelector(
  CoreState.selectAllThermostats,
  selectActiveThermostatId,
  (thermostats, id) => {
    return thermostats.find(t => t.id === id);
  },
);

export const selectActiveThermostatSiteController = createSelector(
  selectActiveThermostat,
  CoreState.selectAllSiteControllers,
  (thermostat, siteControllers) =>
    siteControllers.find(sc => sc.id === thermostat?.siteControllerId),
);

export const selectActiveThermostatViewModel = createSelector(
  selectActiveThermostat,
  CoreState.selectSelectedSiteTimeZone,
  selectActiveThermostatSiteController,
  (activeThermostat, timezone, siteController) =>
    activeThermostat
      ? toThermostatViewModelFromInternal(activeThermostat, timezone, siteController)
      : null,
);

export const selectActiveThermostatZones = createSelector(
  selectActiveThermostat,
  CoreState.selectAllClimateZones,
  (thermostat, climateZones) =>
    thermostat
      ? climateZones.filter(climateZone => climateZone?.deviceIds.includes(thermostat.id))
      : [],
);

export const selectActiveThermostatScenes = createSelector(
  selectActiveThermostatZones,
  CoreState.selectSceneViews,
  CoreState.selectAllSceneZoneBehaviors,
  (activeThermostatZones, sceneViews, szbs) => {
    const zoneIds = activeThermostatZones.map(zone => zone.id);
    const szbsForActiveThermostat = szbs.filter(szb => zoneIds.includes(szb.zoneId));

    return sceneViews.filter(scene =>
      szbsForActiveThermostat.map(activeSzb => activeSzb.sceneId).includes(scene.id),
    );
  },
);

export const selectThermostatDetailsPageState = createSelector(
  selectFeatureState,
  state => state.thermostatDetailsPage,
);

export const selectThermostatDetailsPageIsLoading = createSelector(
  selectThermostatDetailsPageState,
  ThermostatDetailsPageState.selectIsLoading,
);

/**
 * Thermostat alarms
 */

export const selectCommunicationLostThermostatAlarmStatus = createSelector(
  selectActiveThermostatId,
  CoreState.selectThermostatAlarmsLookupTableByThermostatId,
  (thermostatId, thermostatAlarmsTablesByThermostatId) => {
    return thermostatId
      ? thermostatAlarmsTablesByThermostatId[thermostatId] &
          ThermostatStatus.communicationFailure
      : ThermostatStatus.ok;
  },
);

export const selectConfigFailureThermostatAlarmStatus = createSelector(
  selectActiveThermostatId,
  CoreState.selectThermostatAlarmsLookupTableByThermostatId,
  (thermostatId, thermostatAlarmsTablesByThermostatId) => {
    return thermostatId
      ? thermostatAlarmsTablesByThermostatId[thermostatId] &
          ThermostatStatus.configurationFailure
      : ThermostatStatus.ok;
  },
);

export const selectLowQualityLinkThermostatAlarmStatus = createSelector(
  selectActiveThermostatId,
  CoreState.selectThermostatAlarmsLookupTableByThermostatId,
  (thermostatId, thermostatAlarmsTablesByThermostatId) => {
    return thermostatId
      ? thermostatAlarmsTablesByThermostatId[thermostatId] &
          ThermostatStatus.linkQualityWarning
      : ThermostatStatus.ok;
  },
);

export const selectMissingScriptThermostatAlarmStatus = createSelector(
  selectActiveThermostatId,
  CoreState.selectThermostatAlarmsLookupTableByThermostatId,
  (thermostatId, thermostatAlarmsTablesByThermostatId) => {
    return thermostatId
      ? thermostatAlarmsTablesByThermostatId[thermostatId] &
          ThermostatStatus.missingScriptFailure
      : ThermostatStatus.ok;
  },
);

/**
 * Edit Thermostat
 */
export const selectThermostatIdForEdit = createSelector(
  CoreState.selectRouterParams,
  params => params.id as string | undefined | null,
);

export const selectThermostatForEdit = createSelector(
  CoreState.selectAllThermostats,
  selectThermostatIdForEdit,
  (thermostats, id) => {
    return thermostats.find(t => t.id === id);
  },
);

export const selectThermostatForEditSiteController = createSelector(
  selectThermostatForEdit,
  CoreState.selectAllSiteControllers,
  (thermostat, siteControllers) =>
    siteControllers.find(sc => sc.id === thermostat?.siteControllerId),
);

export const selectThermostatForEditViewModel = createSelector(
  selectThermostatForEdit,
  CoreState.selectSelectedSiteTimeZone,
  selectThermostatForEditSiteController,
  (thermostatForEdit, timezone, siteController) => {
    return thermostatForEdit
      ? toThermostatViewModelFromInternal(thermostatForEdit, timezone, siteController)
      : null;
  },
);

export const selectThermostatsNotSelected = createSelector(
  CoreState.selectAllThermostats,
  selectThermostatIdForEdit,
  (thermostats, thermostatIdForEdit) => {
    return thermostats.filter(thermostat => thermostat.id !== thermostatIdForEdit);
  },
);

/**
 * Climate Zones Page
 */
export const selectClimateZonesPageState = createSelector(
  selectFeatureState,
  state => state.climateZonesPage,
);
export const selectClimateZonesPageIsLoading = createSelector(
  selectClimateZonesPageState,
  ClimateZonesPageState.selectIsLoading,
);
export const selectClimateZonesPageError = createSelector(
  selectClimateZonesPageState,
  ClimateZonesPageState.selectError,
);
export const selectClimateZonesPageSearchTerm = createSelector(
  selectClimateZonesPageState,
  ClimateZonesPageState.selectSearchTerm,
);
export const selectClimateZonesPageShouldShowGettingStartedCard = createSelector(
  selectClimateZonesPageIsLoading,
  CoreState.selectAllClimateZones,
  (isLoading, climateZones) => {
    return !isLoading && climateZones.length === 0;
  },
);
export const selectFilteredClimateZones = createSelector(
  CoreState.selectAllClimateZones,
  selectClimateZonesPageSearchTerm,
  CoreState.selectThermostatAlarmTypeByThermostatId,
  (climateZones, searchTerm, thermostatAlarmsTable) => {
    return climateZones
      .filter(cz => cz.name.toLowerCase().includes(searchTerm.toLowerCase()))
      .map(
        cz =>
          <ClimateZoneViewModel>{
            id: cz.id,
            name: cz.name,
            controlMode: toControlModeFromParameters(cz.behaviorParameters ?? ''),
            thermostatIds: cz.deviceIds,
            totalAlarmingThermostats: cz.deviceIds
              ? cz.deviceIds.filter(t => thermostatAlarmsTable[t]?.length > 0)?.length
              : 0,
          },
      );
  },
);

export const selectClimateZoneNames = createSelector(
  CoreState.selectAllClimateZones,
  climateZones => {
    return climateZones.map(climateZone => climateZone.name);
  },
);

/*
 * Climate Zone Details Page
 */
export const selectClimateZoneDetailsPageState = createSelector(
  selectFeatureState,
  state => state.climateZoneDetailsPage,
);
export const selectClimateZoneDetailsPageIsLoading = createSelector(
  selectClimateZoneDetailsPageState,
  ClimateZoneDetailsPageState.selectIsLoading,
);

/**
 * Edit Climate Zone
 */
export const selectClimateZoneIdForEdit = createSelector(
  CoreState.selectRouterParams,
  params => params.id as string | undefined | null,
);

export const selectClimateZoneForEdit = createSelector(
  CoreState.selectAllClimateZones,
  selectClimateZoneIdForEdit,
  (climateZones, id) => {
    return climateZones.find(t => t.id === id);
  },
);

export const selectClimateZonesNotBeingEdited = createSelector(
  CoreState.selectAllClimateZones,
  selectClimateZoneIdForEdit,
  (zones, zoneIdForEdit) => {
    return zones.filter(zone => zone.id !== zoneIdForEdit);
  },
);

/**
 * Climate Zone Modal
 */
export const selectClimateZoneModalState = createSelector(
  selectFeatureState,
  state => state.climateZoneModal,
);
export const selectClimateZoneModalIsLoading = createSelector(
  selectClimateZoneModalState,
  ClimateZoneModalState.selectIsLoading,
);
