import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SpeedControlComponentModule } from '../speed-control';
import { BFPaparazziMonochromeComponent } from './bf-paparazzi-monochrome.component';

@NgModule({
  imports: [ReactiveFormsModule, SpeedControlComponentModule],
  declarations: [BFPaparazziMonochromeComponent],
  exports: [BFPaparazziMonochromeComponent],
})
export class BFPaparazziMonochromeComponentModule {}
