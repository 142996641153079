import { createReducer, on } from '@ngrx/store';
import { SenseMapLayerActions } from '@spog-ui/sense-map-layer/actions';

export interface Shape {
  searchTerm: string;
}

export const initialState: Shape = {
  searchTerm: '',
};

export const reducer = createReducer(
  initialState,
  on(
    SenseMapLayerActions.filterAction,
    (state, action): Shape => {
      return { ...state, searchTerm: action.searchTerm };
    },
  ),
);

export const selectSearchTerm = (state: Shape) => state.searchTerm;
