import { Injectable } from '@angular/core';
import { applyTransform, AreaModel, TransformModel } from '@spog-ui/map-tools/models';
import { fromEvent, merge, Observable } from 'rxjs';
import { exhaustMap, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

export type StartPosition = { startX: number; startY: number };
export type EndPosition = { endX: number; endY: number };
export type BehaviorOptions = {
  targetElement: Element;
  drawObserver: { onStart: () => void; onEnd: () => void };
  calculateStartingPosition: (start: {
    clientX: number;
    clientY: number;
  }) => StartPosition;
  getTransform: () => TransformModel;
  getContainerRect: () => ClientRect;
};

export interface DiffCalulator<T> {
  (
    startPosition: StartPosition,
    endPosition: EndPosition,
    transform: TransformModel,
    targetArea: ClientRect,
  ): T;
}

const calculateMoveDiff: DiffCalulator<{ x: number; y: number }> = (
  start,
  end,
  transform,
) => {
  const { startX, startY } = start;
  const { endX, endY } = end;

  return {
    x: (endX - startX) * (1 / transform.k),
    y: (endY - startY) * (1 / transform.k),
  };
};

const calculateDragDiff: DiffCalulator<AreaModel> = (
  start,
  end,
  transform,
  targetArea,
) => {
  const { startX, startY } = start;
  const { endX, endY } = end;
  const { top: minY, bottom: maxY, left: minX, right: maxX } = targetArea;
  const currentX = Math.min(maxX, Math.max(minX, endX));
  const currentY = Math.min(maxY, Math.max(minY, endY));
  const x1 = applyTransform('x', startX - minX, transform);
  const x2 = applyTransform('x', currentX - minX, transform);
  const y1 = applyTransform('y', startY - minY, transform);
  const y2 = applyTransform('y', currentY - minY, transform);

  const x = Math.min(x1, x2);
  const y = Math.min(y1, y2);
  const width = Math.abs(x1 - x2);
  const height = Math.abs(y1 - y2);

  return { x, y, width, height };
};

@Injectable({ providedIn: 'root' })
export class MovementService {
  createMoveBehavior(options: BehaviorOptions) {
    return this.createBehavior(options, calculateMoveDiff);
  }

  createDragBehavior(options: BehaviorOptions) {
    return this.createBehavior(options, calculateDragDiff);
  }

  private getMouseDownEvents(target: Element): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(target, 'mousedown');
  }

  private getMouseMoveEvents(): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(document, 'mousemove', { capture: true });
  }

  private getMouseUpEvents(): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(document, 'mouseup', { capture: true });
  }

  private createMouseBehavior<T>(
    options: BehaviorOptions,
    diffCalculator: DiffCalulator<T>,
  ): Observable<T> {
    const mousedown$ = this.getMouseDownEvents(options.targetElement);
    const mousemove$ = this.getMouseMoveEvents();
    const mouseup$ = this.getMouseUpEvents();

    return mousedown$.pipe(
      filter(event => event.button === 0),
      tap(options.drawObserver.onStart),
      this.preventEventBehavior(),
      map(options.calculateStartingPosition),
      mergeMap(start =>
        mousemove$.pipe(
          this.preventEventBehavior(),
          map(event =>
            diffCalculator(
              start,
              { endX: event.clientX, endY: event.clientY },
              options.getTransform(),
              options.getContainerRect(),
            ),
          ),
          takeUntil(
            mouseup$.pipe(this.preventEventBehavior(), tap(options.drawObserver.onEnd)),
          ),
        ),
      ),
    );
  }

  private getTouchStartEvents(target: Element): Observable<TouchEvent> {
    return fromEvent<TouchEvent>(target, 'touchstart');
  }

  private getTouchMoveEvents(): Observable<TouchEvent> {
    return fromEvent<TouchEvent>(document, 'touchmove', { capture: true });
  }
  private getTouchEndEvents(): Observable<TouchEvent> {
    return fromEvent<TouchEvent>(document, 'touchend', { capture: true });
  }

  private createTouchBehavior<T>(
    options: BehaviorOptions,
    diffCalculator: DiffCalulator<T>,
  ): Observable<T> {
    const touchstart$ = this.getTouchStartEvents(options.targetElement);
    const touchmove$ = this.getTouchMoveEvents();
    const touchend$ = this.getTouchEndEvents();

    return touchstart$.pipe(
      filter(event => event.targetTouches.length !== 0),
      tap(options.drawObserver.onStart),
      this.preventEventBehavior(),
      map(event => event.targetTouches[0]),
      exhaustMap(startingTouch => {
        const start: StartPosition = options.calculateStartingPosition(startingTouch);

        return touchmove$.pipe(
          this.preventEventBehavior(),
          map(event => {
            for (let i = 0; i < event.changedTouches.length; i++) {
              const nextTouch = event.changedTouches[i];

              if (nextTouch.identifier === startingTouch.identifier) {
                return nextTouch;
              }
            }

            throw new Error('Did not find matching touch');
          }),
          filter((nextTouch): nextTouch is Touch => !!nextTouch),
          map(nextTouch => {
            const end: EndPosition = {
              endX: nextTouch.clientX,
              endY: nextTouch.clientY,
            };

            return diffCalculator(
              start,
              end,
              options.getTransform(),
              options.getContainerRect(),
            );
          }),
          takeUntil(
            touchend$.pipe(
              this.preventEventBehavior(),
              map(event => {
                for (let i = 0; i < event.changedTouches.length; i++) {
                  const nextTouch = event.changedTouches[i];

                  if (nextTouch.identifier === startingTouch.identifier) {
                    return nextTouch;
                  }
                }

                throw new Error('Did not find matching touch');
              }),
              filter((endTouch): endTouch is Touch => endTouch !== undefined),
              tap(options.drawObserver.onEnd),
            ),
          ),
        );
      }),
    );
  }

  private createBehavior<T>(
    options: BehaviorOptions,
    diffCalculator: DiffCalulator<T>,
  ): Observable<T> {
    return merge(
      this.createMouseBehavior(options, diffCalculator),
      this.createTouchBehavior(options, diffCalculator),
    );
  }

  private preventEventBehavior<V extends Event>() {
    return (source$: Observable<V>): Observable<V> =>
      source$.pipe(
        tap(event => {
          event.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();
        }),
      );
  }
}
