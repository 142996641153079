import { createReducer, on } from '@ngrx/store';
import { MenuActions } from '@spog-ui/modules/menu-state';
import { OrganizationsPageActions } from '@spog-ui/modules/organizations/state';

export interface Shape {
  adminOrgPanelRedirectUrl: string;
}

export const STATE_KEY = 'redirect';

export const initialState: Shape = {
  adminOrgPanelRedirectUrl: '/',
};

export const reducer = createReducer(
  initialState,
  on(
    MenuActions.navigateToOrganization,
    OrganizationsPageActions.manageAction,
    (state, action) => {
      return {
        ...state,
        adminOrgPanelRedirectUrl: action.redirectUrl,
      };
    },
  ),
);

export const selectRedirectUrl = (state: Shape) => state.adminOrgPanelRedirectUrl;
