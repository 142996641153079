import { createAction } from '@ngrx/store';

export const enterAction = createAction('[Sense Map Layer] Enter');

export const toggleStatusTrayFilterAction = createAction(
  '[Sense Map Layer] Toggle Status Tray Filter Ok',
  (filteringOk: boolean, filteringAlarmed: boolean, filteringPending: boolean) => {
    return { filteringOk, filteringAlarmed, filteringPending };
  },
);

export const filterAction = createAction(
  '[Sense Map Layer] Filter',
  (searchTerm: string) => ({ searchTerm }),
);
