import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SpeedControlComponentModule } from '../speed-control';
import { BFSparkleMonochromeComponent } from './bf-sparkle-monochrome.component';

@NgModule({
  imports: [ReactiveFormsModule, SpeedControlComponentModule],
  declarations: [BFSparkleMonochromeComponent],
  exports: [BFSparkleMonochromeComponent],
})
export class BFSparkleMonochromeComponentModule {}
