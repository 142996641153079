import { createAction } from '@ngrx/store';
import { ThermostatFormModel } from '@spog-ui/shared/models/thermostats';

export const enterAction = createAction('[Edit Thermostat Page] Enter');
export const leaveAction = createAction('[Edit Thermostat Page] Leave');
export const editThermostatAction = createAction(
  '[Edit Thermostat Page] Edit Thermostat',
  (thermostat: ThermostatFormModel) => {
    return { thermostat };
  },
);
