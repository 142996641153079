import { createAction } from '@ngrx/store';
import { UtilityServiceFormModel } from '@spog-ui/shared/models/utility-services';

export const enterAction = createAction('[Edit Utility Service Page] Enter');
export const leaveAction = createAction('[Edit Utility Service Page] Leave');

export const editUtilityServiceAction = createAction(
  '[Edit Utility Service Page] Edit Utility Service',
  (utilityService: UtilityServiceFormModel) => ({ utilityService }),
);
