import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { SceneFormComponentModule } from '../scene-form';
import { AddScenePageComponent } from './add-scene-page.component';

@NgModule({
  imports: [CommonModule, SceneFormComponentModule, SuiSpinnerModule],
  declarations: [AddScenePageComponent],
  exports: [AddScenePageComponent],
})
export class AddScenePageComponentModule {}
