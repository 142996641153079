import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';

export class DateTimeValidators {
  static isSameOrAfterDate(whenFn: () => DateTime): ValidatorFn {
    return function (control: AbstractControl) {
      const dateOnly = {
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      };

      if (whenFn && control.value) {
        const when = whenFn().set(dateOnly);
        return control.value.valueOf() >= when.valueOf()
          ? null
          : { suiIsSameOrAfterDate: true };
      }

      return null;
    };
  }

  static isSameOrAfterTimeInput(whenFn: () => DateTime): ValidatorFn {
    return function (control: AbstractControl) {
      const when = whenFn();

      if (control.value) {
        const compare = DateTime.fromString(control.value, 'HH:mm');
        return compare.valueOf() >= when.valueOf()
          ? null
          : { suiIsSameOrAfterTimeInput: true };
      }

      return null;
    };
  }

  static hasAtLeastOne(): ValidatorFn {
    return function (control: AbstractControl) {
      const length = control.value ? control.value.length : 0;

      return length > 0 ? null : { suiHasAtLeastOne: false };
    };
  }

  static isValidEndDate(startDate: AbstractControl) {
    return function (control: AbstractControl) {
      if (control.value) {
        return startDate.value.valueOf() > control.value.valueOf()
          ? { endDate: control.value }
          : null;
      }

      return null;
    };
  }
}
