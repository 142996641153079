import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as PowerState from '@spog-ui/shared/state/power';
import * as AddUtilityServicePageState from './add-utility-service-page';
import * as EditUtilityServicePageState from './edit-utility-service-page';
import * as ScheduleUtilityRateState from './schedule-utility-rate';
import * as UtilityServicesPageState from './utility-services-page';
import * as AddDepartmentPageState from './add-department-page';
import * as EditDepartmentPageState from './edit-department-page';
import * as DepartmentsPageState from './departments-page';
import * as EquipmentPageState from './equipment-page';
import * as AddEquipmentPageState from './add-equipment-page';
import * as EditEquipmentPageState from './edit-equipment-page';
import * as EquipmentDetailsPageState from './equipment-details-page';
import * as EquipmentProfilePageState from './equipment-profile-page';
import { TakenLights, TakenPowerSensors } from '@spog-ui/shared/models/utility-services';
import { toDepartmentFormModel } from '@spog-ui/shared/models/department';
import { isDefinedType } from '@spog-ui/shared/models/equipment';
import { toEquipmentFormModel } from '@spog-ui/shared/models/equipment';
import { isDepartment } from '@spog-ui/shared/models/resource-groups';
import { selectAllUtilityServices, isPowerSensor } from '@spog-ui/shared/state/core';

export const STATE_KEY = 'energy-info-system';

export interface Shape {
  addUtilityServicePage: AddUtilityServicePageState.Shape;
  editUtilityServicePage: EditUtilityServicePageState.Shape;
  scheduleUtilityRate: ScheduleUtilityRateState.Shape;
  utilityServicesPage: UtilityServicesPageState.Shape;
  addDepartmentPage: AddDepartmentPageState.Shape;
  editDepartmentPage: EditDepartmentPageState.Shape;
  departmentsPage: DepartmentsPageState.Shape;
  equipmentPage: EquipmentPageState.Shape;
  addEquipmentPage: AddEquipmentPageState.Shape;
  editEquipmentPage: EditEquipmentPageState.Shape;
  equipmentDetailsPage: EquipmentDetailsPageState.Shape;
  equipmentProfilePage: EquipmentProfilePageState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  addUtilityServicePage: AddUtilityServicePageState.reducer,
  editUtilityServicePage: EditUtilityServicePageState.reducer,
  scheduleUtilityRate: ScheduleUtilityRateState.reducer,
  utilityServicesPage: UtilityServicesPageState.reducer,
  addDepartmentPage: AddDepartmentPageState.reducer,
  editDepartmentPage: EditDepartmentPageState.reducer,
  departmentsPage: DepartmentsPageState.reducer,
  equipmentPage: EquipmentPageState.reducer,
  addEquipmentPage: AddEquipmentPageState.reducer,
  editEquipmentPage: EditEquipmentPageState.reducer,
  equipmentDetailsPage: EquipmentDetailsPageState.reducer,
  equipmentProfilePage: EquipmentProfilePageState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Add Utility Service Page
 */
export const selectAddUtilityServicePageState = createSelector(
  selectFeatureState,
  state => state.addUtilityServicePage,
);
export const selectAddUtilityServicePageIsLoading = createSelector(
  selectAddUtilityServicePageState,
  AddUtilityServicePageState.selectIsLoading,
);
export const selectAddUtilityServicePageLoadingError = createSelector(
  selectAddUtilityServicePageState,
  AddUtilityServicePageState.selectLoadingError,
);
export const selectAddUtilityServicePageIsSubmitting = createSelector(
  selectAddUtilityServicePageState,
  AddUtilityServicePageState.selectIsSubmitting,
);
export const selectAddUtilityServicePageSubmissionError = createSelector(
  selectAddUtilityServicePageState,
  AddUtilityServicePageState.selectSubmissionError,
);

/**
 * Edit Utility Service Page
 */
export const selectEditUtilityServicePageState = createSelector(
  selectFeatureState,
  state => state.editUtilityServicePage,
);
export const selectEditUtilityServicePageIsLoading = createSelector(
  selectEditUtilityServicePageState,
  EditUtilityServicePageState.selectIsLoading,
);
export const selectEditUtilityServicePageLoadingError = createSelector(
  selectEditUtilityServicePageState,
  EditUtilityServicePageState.selectLoadingError,
);
export const selectEditUtilityServicePageIsSubmitting = createSelector(
  selectEditUtilityServicePageState,
  EditUtilityServicePageState.selectIsSubmitting,
);
export const selectEditUtilityServicePageSubmissionError = createSelector(
  selectEditUtilityServicePageState,
  EditUtilityServicePageState.selectSubmissionError,
);

export const selectActiveUtilityServiceId = createSelector(
  CoreState.selectRouterParams,
  params => params['activeUtilityServiceId'] as string | undefined | null,
);

export const selectActiveUtilityService = createSelector(
  selectActiveUtilityServiceId,
  selectAllUtilityServices,
  (activeId, utilityServices) => {
    return utilityServices.find(utilityService => utilityService.id === activeId);
  },
);

/**
 * Schedule Utility Rate
 */
export const selectScheduleUtilityRateState = createSelector(
  selectFeatureState,
  state => state.scheduleUtilityRate,
);
export const selectScheduleUtilityRateUtilityServiceId = createSelector(
  selectScheduleUtilityRateState,
  ScheduleUtilityRateState.selectUtilityServiceId,
);
export const selectScheduleUtilityRateIsSaving = createSelector(
  selectScheduleUtilityRateState,
  ScheduleUtilityRateState.selectIsSaving,
);

/**
 * Utility Services Page
 */
export const selectUtilityServicesPageState = createSelector(
  selectFeatureState,
  state => state.utilityServicesPage,
);
export const selectUtilityServicesPageIsLoading = createSelector(
  selectUtilityServicesPageState,
  UtilityServicesPageState.selectIsLoading,
);
export const selectUtilityServicesPageError = createSelector(
  selectUtilityServicesPageState,
  UtilityServicesPageState.selectError,
);
export const selectUtilityServicesPageShouldShowGettingStartedCard = createSelector(
  selectUtilityServicesPageIsLoading,
  CoreState.selectAllUtilityServices,
  (isLoading, utilityServices) => {
    return !isLoading && utilityServices.length === 0;
  },
);

/**
 * Custom Utility Service Form Selectors
 */
export const selectTakenPowerSensorIds = createSelector(
  CoreState.selectAllUtilityServices,
  selectActiveUtilityServiceId,
  (utilityServices, activeUtilityServiceId) => {
    return utilityServices.reduce((takenPowerSensorIds, utilityService) => {
      // if we are editing, don't consider the utility service being edited
      if (utilityService.id === activeUtilityServiceId) {
        return takenPowerSensorIds;
      }

      utilityService.mainIds.forEach(id => takenPowerSensorIds.add(id));
      utilityService.indieSensorsIds.forEach(id => takenPowerSensorIds.add(id));

      return takenPowerSensorIds;
    }, new Set<string>());
  },
);
export const selectIndustrialPowerSensors = createSelector(
  CoreState.selectAllIndieSensors,
  indieSensors => indieSensors.filter(isPowerSensor),
);

export const selectTakenPowerSensors = createSelector(
  selectTakenPowerSensorIds,
  selectIndustrialPowerSensors,
  (takenPowerSensorIds, indiePowerSensors): TakenPowerSensors => {
    return indiePowerSensors.reduce((takenPowerSensors, indiePowerSensor) => {
      if (takenPowerSensorIds.has(indiePowerSensor.id)) {
        takenPowerSensors[indiePowerSensor.id] = indiePowerSensor;
      }

      return takenPowerSensors;
    }, {} as TakenPowerSensors);
  },
);
export const selectTakenLightIds = createSelector(
  CoreState.selectAllUtilityServices,
  selectActiveUtilityServiceId,
  (utilityServices, activeUtilityServiceId) => {
    return utilityServices.reduce((takenLightIds, utilityService) => {
      // if we are editing, don't consider the utility service being edited
      if (utilityService.id === activeUtilityServiceId) {
        return takenLightIds;
      }

      utilityService.lightIds.forEach(id => takenLightIds.add(id));

      return takenLightIds;
    }, new Set<string>());
  },
);
export const selectTakenLights = createSelector(
  selectTakenLightIds,
  CoreState.selectAllLights,
  (takenLightIds, lights): TakenLights => {
    return lights.reduce((takenLights, light) => {
      if (takenLightIds.has(light.id)) {
        takenLights[light.id] = light;
      }

      return takenLights;
    }, {} as TakenLights);
  },
);

/**
 * Custom Schedule Utility Rate Selectors
 */
export const selectUtilityServiceBeingScheduled = createSelector(
  selectScheduleUtilityRateUtilityServiceId,
  CoreState.selectAllUtilityServiceViews,
  (utilityServiceId, utilityServiceViews) => {
    return utilityServiceViews.find(
      utilityService => utilityService.id === utilityServiceId,
    );
  },
);

/**
 * Departments
 */

export const selectDepartmentsPageState = createSelector(
  selectFeatureState,
  state => state.departmentsPage,
);
export const selectDepartmentsPageIsLoading = createSelector(
  selectDepartmentsPageState,
  DepartmentsPageState.selectIsLoading,
);
export const selectDepartmentsPageError = createSelector(
  selectDepartmentsPageState,
  DepartmentsPageState.selectError,
);
export const selectDepartmentsSearchTerm = createSelector(
  selectDepartmentsPageState,
  DepartmentsPageState.selectSearchTerm,
);
export const selectFilteredDepartments = createSelector(
  selectDepartmentsSearchTerm,
  CoreState.selectAllDepartments,
  (searchTerm, departments) => {
    const filter = searchTerm.trim().toLowerCase();
    return departments.filter(dept => dept.name.toLowerCase().includes(filter));
  },
);
export const selectDepartmentsPageShouldShowGettingStartedCard = createSelector(
  selectDepartmentsPageIsLoading,
  CoreState.selectAllDepartments,
  (isLoading, departments) => {
    return !isLoading && departments.length === 0;
  },
);
export const selectDepartmentNames = createSelector(
  CoreState.selectAllDepartments,
  departments => {
    return departments.map(department => department.name);
  },
);

/**
 * Add Department Page
 */
export const selectAddDepartmentPageState = createSelector(
  selectFeatureState,
  state => state.addDepartmentPage,
);
export const selectAddDepartmentPageIsLoading = createSelector(
  selectAddDepartmentPageState,
  AddDepartmentPageState.selectIsLoading,
);
export const selectAddDepartmentPageLoadingError = createSelector(
  selectAddDepartmentPageState,
  AddDepartmentPageState.selectLoadingError,
);
export const selectAddDepartmentPageIsSubmitting = createSelector(
  selectAddDepartmentPageState,
  AddDepartmentPageState.selectIsSubmitting,
);

/**
 * Edit Department Page
 */
export const selectEditDepartmentPageState = createSelector(
  selectFeatureState,
  state => state.editDepartmentPage,
);
export const selectEditDepartmentPageIsLoading = createSelector(
  selectEditDepartmentPageState,
  EditDepartmentPageState.selectIsLoading,
);
export const selectEditDepartmentPageLoadingError = createSelector(
  selectEditDepartmentPageState,
  EditDepartmentPageState.selectLoadingError,
);
export const selectEditDepartmentPageIsSubmitting = createSelector(
  selectEditDepartmentPageState,
  EditDepartmentPageState.selectIsSubmitting,
);
export const selectDepartmentIdForEdit = createSelector(
  selectEditDepartmentPageState,
  EditDepartmentPageState.selectActiveId,
);
export const selectDepartmentForEdit = createSelector(
  CoreState.selectAllDepartments,
  selectDepartmentIdForEdit,
  (departments, id) => {
    const departmentToEdit = departments.find(d => d.id === id);
    return departmentToEdit ? toDepartmentFormModel(departmentToEdit) : null;
  },
);

export const selectResourceGroupsWithoutActiveDept = createSelector(
  CoreState.selectAllResourceGroups,
  selectDepartmentIdForEdit,
  (resourceGroups, idForEdit) => {
    return resourceGroups.filter(rg => rg.id !== idForEdit);
  },
);

/**
 * Equipment
 */
export const selectEquipmentPageState = createSelector(
  selectFeatureState,
  state => state.equipmentPage,
);
export const selectEquipmentPageIsLoading = createSelector(
  selectEquipmentPageState,
  EquipmentPageState.selectIsLoading,
);
export const selectEquipmentPageError = createSelector(
  selectEquipmentPageState,
  EquipmentPageState.selectError,
);
export const selectEquipmentSearchTerm = createSelector(
  selectEquipmentPageState,
  EquipmentPageState.selectSearchTerm,
);
export const selectFilteredEquipment = createSelector(
  selectEquipmentSearchTerm,
  CoreState.selectAllEquipment,
  (searchTerm, equipment) => {
    const filter = searchTerm.trim().toLowerCase();
    return equipment.filter(e => e.name.toLowerCase().includes(filter));
  },
);
export const selectEquipmentPageShouldShowGettingStartedCard = createSelector(
  selectEquipmentPageIsLoading,
  CoreState.selectAllEquipment,
  (isLoading, equipment) => {
    return !isLoading && equipment.length === 0;
  },
);

export const selectEquipmentNames = createSelector(
  CoreState.selectAllEquipment,
  equipment => {
    return equipment.map(e => e.name);
  },
);

/**
 * Equipment Profile
 */
export const selectEquipmentProfilePageState = createSelector(
  selectFeatureState,
  state => state.equipmentProfilePage,
);
export const selectEquipmentProfilePageIsLoading = createSelector(
  selectEquipmentProfilePageState,
  EquipmentProfilePageState.selectIsLoading,
);
export const selectEquipmentProfilePageError = createSelector(
  selectEquipmentProfilePageState,
  EquipmentProfilePageState.selectError,
);

/**
 * Add Equipment Page
 */
export const selectAddEquipmentPageState = createSelector(
  selectFeatureState,
  state => state.addEquipmentPage,
);
export const selectAddEquipmentPageIsLoading = createSelector(
  selectAddEquipmentPageState,
  AddEquipmentPageState.selectIsLoading,
);
export const selectAddEquipmentPageLoadingError = createSelector(
  selectAddEquipmentPageState,
  AddEquipmentPageState.selectLoadingError,
);
export const selectAddEquipmentPageIsSubmitting = createSelector(
  selectAddEquipmentPageState,
  AddEquipmentPageState.selectIsSubmitting,
);

/**
 * Edit Equipment Page
 */
export const selectEditEquipmentPageState = createSelector(
  selectFeatureState,
  state => state.editEquipmentPage,
);
export const selectEditEquipmentPageIsLoading = createSelector(
  selectEditEquipmentPageState,
  EditEquipmentPageState.selectIsLoading,
);
export const selectEditEquipmentPageLoadingError = createSelector(
  selectEditEquipmentPageState,
  EditEquipmentPageState.selectLoadingError,
);
export const selectEditEquipmentPageIsSubmitting = createSelector(
  selectEditEquipmentPageState,
  EditEquipmentPageState.selectIsSubmitting,
);
export const selectEquipmentIdForEdit = createSelector(
  selectEditEquipmentPageState,
  EditEquipmentPageState.selectActiveId,
);
export const selectEquipmentForEdit = createSelector(
  CoreState.selectAllEquipment,
  selectEquipmentIdForEdit,
  (equipment, id) => {
    const equipmentToEdit = equipment.find(e => e.id === id);
    return equipmentToEdit ? toEquipmentFormModel(equipmentToEdit) : null;
  },
);

/**
 * Return the resource groups other than the equipment that is
 * currently being edited (if any). Excludes departments!
 */
export const selectResourceGroupsWithoutActiveEquipment = createSelector(
  CoreState.selectAllResourceGroups,
  selectEquipmentIdForEdit,
  (resourceGroups, equipmentId) => {
    return resourceGroups
      .filter(resourceGroup => resourceGroup.id !== equipmentId)
      .filter(resourceGroup => !isDepartment(resourceGroup));
  },
);

/**
 * Equipment
 */
export const selectEquipmentDetailsPageState = createSelector(
  selectFeatureState,
  state => state.equipmentDetailsPage,
);
export const selectEquipmentDetailsPageIsLoading = createSelector(
  selectEquipmentDetailsPageState,
  EquipmentDetailsPageState.selectIsLoading,
);

/**
 * Custom Assets Selectors
 */
export const selectCustomAssetTypes = createSelector(
  CoreState.selectAllEquipment,
  assets => {
    const customAssets = assets.filter(asset => !isDefinedType(asset.assetType));
    return [...new Set(customAssets.map(asset => asset.assetType))];
  },
);
