import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { AddOrganizationPageActions } from '../actions';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import { toSiteInternalModelFromGQL } from '@spog-ui/shared/models/sites';
import { RemoteAccessStateActions } from '@spog-ui/shared/state/remote-access';

@Injectable()
export class AddOrganizationApiEffects {
  constructor(readonly actions$: Actions, readonly gql: GraphQLAPIService) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddOrganizationPageActions.enterAction),
      switchMap(() =>
        this.gql.getAllSitesAsAdmin().pipe(
          map(data =>
            AddOrganizationPageActions.loadPageSuccessAction(
              data.allSites.edges.map(edge => toSiteInternalModelFromGQL(edge.site)),
            ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              AddOrganizationPageActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddOrganizationPageActions.loadPageSuccessAction),
      map(action => {
        return RemoteAccessStateActions.loadAdminSitesSuccessAction(action.sites);
      }),
    ),
  );
}
