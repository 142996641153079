import { AfterViewInit, Component, Input, Output } from '@angular/core';
import { BehaviorSubject, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import {
  exhaustMap,
  filter,
  map,
  mergeMap,
  pairwise,
  repeat,
  takeUntil,
} from 'rxjs/operators';
import { AreaModel, TransformModel } from '@spog-ui/map-tools/models';
import { MovementService } from '../../services';
import { MapLayersRef } from '../map-layers/map-layers.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg:g[map-area-select]',
  template: `
    @if (area) {
      <svg:g
        map-movable-rect
        [rect]="area"
        [transform]="transform"
        (resized)="childResize$.next($event)"
        (move)="childMove$.next($event)"
        (commitMove)="childCommitMove$.next(undefined)"
        ></svg:g>
      }
    `,
})
export class AreaSelectComponent implements AfterViewInit {
  afterViewInit$ = new ReplaySubject<boolean>();
  childResize$ = new Subject<AreaModel>();
  childMove$ = new Subject<{ x: number; y: number }>();
  childCommitMove$ = new Subject<any>();
  isDisabled$ = new BehaviorSubject<boolean>(false);

  @Output() selected: Observable<AreaModel> = merge(
    this.childResize$,
    this.afterViewInit$.pipe(
      mergeMap(() => {
        const svg = this.layersRef.mapLayers.wrapper;
        const disabled$ = this.isDisabled$.pipe(filter(isDisabled => isDisabled));
        const enabled$ = this.isDisabled$.pipe(filter(isDisabled => !isDisabled));

        const dragBehavior$ = this.movement.createDragBehavior({
          targetElement: svg,
          drawObserver: {
            onStart: () => void 0,
            onEnd: () => void 0,
          },
          calculateStartingPosition: event => ({
            startX: event.clientX,
            startY: event.clientY,
          }),
          getTransform: () => this.transform,
          getContainerRect: () => svg.getBoundingClientRect(),
        });

        return enabled$.pipe(exhaustMap(() => dragBehavior$.pipe(takeUntil(disabled$))));
      }),
    ),
  );

  @Input()
  area: AreaModel | null;
  @Input()
  transform: TransformModel;
  @Input()
  set disabled(isDisabled: boolean) {
    this.isDisabled$.next(isDisabled);
  }

  @Output()
  move = this.childMove$.pipe(
    pairwise(),
    map(([last, current]) => ({ x: current.x - last.x, y: current.y - last.y })),
    takeUntil(this.childCommitMove$),
    repeat(),
  );

  constructor(private movement: MovementService, private layersRef: MapLayersRef) {}

  ngAfterViewInit() {
    this.afterViewInit$.next(true);
  }
}
