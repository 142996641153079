import { createAction } from '@ngrx/store';
import { ApiErrorModel } from '@spog-ui/shared/models/errors';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';

export const getAllModelsSuccessFromLoginAction = createAction(
  '[Site Selector API] Get All Success from Login',
  (
    sites: SiteInternalModel[],
    siteControllers: SiteControllerInternalModel[],
    organizations: OrganizationInternalModel[],
  ) => {
    return { sites, siteControllers, organizations };
  },
);

export const loadMySitesAndOrganizationsFailureAction = createAction(
  '[Site Selector API] Load MySitesAndOrgs Failure',
  (error: { errors: ApiErrorModel[] }) => ({ error }),
);
