import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as SenseMapState from './sense-map.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(SenseMapState.STATE_KEY, SenseMapState.reducers),
  ],
})
export class SenseMapStateModule {}
