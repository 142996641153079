import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ClimateZoneDetailsApiActions } from '@spog-ui/climate-zone-details/actions';
import { ClimateZonesApiActions } from '@spog-ui/climate-zones/actions';
import { DepartmentsApiActions } from '@spog-ui/departments/actions';
import { LightMapLayerApiActions } from '@spog-ui/light-map-layer/actions';
import { LightZoneDetailsAPIActions } from '@spog-ui/light-zone-details/actions';
import { LightZonesApiActions } from '@spog-ui/modules/light-zones-actions';
import { PowerApiActions } from '@spog-ui/power/actions';
import { ScenesApiActions } from '@spog-ui/scenes/actions';
import { ScheduleApiActions } from '@spog-ui/schedule/actions';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { ThermostatDetailsApiActions } from '@spog-ui/thermostat-details/actions';
import { ZoneControlActions, ZoneControlApiActions } from '@spog-ui/zone-control/actions';

export type Shape = EntityState<ZoneInternalModel>;

export function sortByName<T extends { name: string }>(a: T, b: T): number {
  return a.name.localeCompare(b.name);
}

export const adapter = createEntityAdapter<ZoneInternalModel>({
  sortComparer: sortByName,
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    ScenesApiActions.loadSuccessAction,
    ScheduleApiActions.loadSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.zones, state);
    },
  ),
  on(
    ClimateZonesApiActions.loadClimateZonesPageModelsSuccessAction,
    LightMapLayerApiActions.loadLightMapLayerDeferredModelsSuccessAction,
    LightZonesApiActions.loadLightZonesPageModelsSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.controlZones, state);
    },
  ),
  on(
    PowerApiActions.loadSuccessAction,
    DepartmentsApiActions.loadFormDataSuccessAction,
    (state, action) => {
      return adapter.setAll(action.zones, state);
    },
  ),
  on(
    ThermostatDetailsApiActions.loadThermostatDetailsModelsSuccessAction,
    (state, action) => {
      return adapter.upsertMany(action.models.zones, state);
    },
  ),
  on(ZoneControlActions.saveAction, (state, action) => {
    const id = action.intendedZoneBehavior.id;
    const updates = {
      behaviorId: action.intendedZoneBehavior.behaviorId,
      behaviorParameters: action.intendedZoneBehavior.behaviorParameters,
    };

    return adapter.updateOne(
      {
        id: id,
        changes: updates,
      },
      state,
    );
  }),
  on(ZoneControlApiActions.saveBehaviorFailureAction, (state, action) => {
    const id = action.originalZoneBehavior.id;
    const updates: any = {
      behaviorId: action.originalZoneBehavior.behaviorId,
      behaviorParameters: action.originalZoneBehavior.behaviorParameters,
    };
    return adapter.updateOne(
      {
        id: id,
        changes: updates,
      },
      state,
    );
  }),
  on(ClimateZoneDetailsApiActions.loadClimateZoneDetailsSuccessAction, (state, action) =>
    adapter.upsertOne(action.climateZone, state),
  ),
  on(
    LightZoneDetailsAPIActions.loadLightZoneDetailsPageModelsSuccessAction,
    (state, { models }) => adapter.upsertOne(models.controlZone, state),
  ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
