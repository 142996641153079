import { Dictionary } from '@ngrx/entity';
import {
  UtilityType,
  CreateUtilityServiceInput,
  UpdateUtilityServiceInput,
  DeleteUtilityServiceInput,
  UtilityServiceModelFragment,
} from '@spog-ui/graphql/types';
import { ScheduledUtilityRateViewModel } from '@spog-ui/shared/models/scheduled-utility-rates';
import { LightInternalModel } from '@spog-ui/shared/models/lights';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';

/**
 * Utility Services GraphQL API model
 */
export type UtilityServiceGQLModel = UtilityServiceModelFragment;

/**
 * Normalized Utility Service appropriate for
 * keeping in the store
 */
export interface UtilityServiceInternalModel {
  id: string;
  name: string;
  mainIds: string[];
  indieSensorsIds: string[];
  lightIds: string[];
}

/**
 * Utility Services from our WebSocket API
 */
export interface UtilityServiceWSModel {
  id: string;
  siteId: string;
  name: string;
  mainIds: string[];
  industrialSensorIds: string[];
  lightIds: string[];
}

/**
 * Utility Service ready to be displayed in the UI
 */
export interface UtilityServiceViewModel {
  id: string;
  name: string;
  mains: IndieSensorInternalModel[];
  indieSensors: IndieSensorInternalModel[];
  lights: LightInternalModel[];
  scheduledUtilityRates: ScheduledUtilityRateViewModel[];
}

/**
 * Inputs for deleting, updating, and creating
 * Utility Services. These are imported from the
 * generated GraphQL types.
 */
export {
  UtilityType,
  CreateUtilityServiceInput,
  UpdateUtilityServiceInput,
  DeleteUtilityServiceInput,
};

/**
 * Utililty Service ready to be operated on in a form
 */
export interface UtilityServiceFormModel {
  id: string | null;
  name: string;
  mainIds: string[];
  indieSensorIds: string[];
  lightIds: string[];
}

export interface TakenPowerSensors {
  [powerSensorId: string]: IndieSensorInternalModel;
}

export interface TakenLights {
  [lightId: string]: LightInternalModel;
}

/**
 * Why isn't this in @ngrx/entity!?
 */
function resolveFromLookupTable<T>(ids: string[], map: Dictionary<T>): T[] {
  return ids.reduce((items, id) => {
    const item = map[id];

    console.assert(
      item !== undefined,
      'Encountered undefined ID when resolving from a lookup table',
    );

    if (item) items.push(item);

    return items;
  }, [] as T[]);
}

/**
 * Normalizes a Utility Service returned from our GraphQL API
 * into a model ready to be put in the store
 */
export function toUtilityServiceInternalModelFromGQL(
  gql: UtilityServiceGQLModel,
): UtilityServiceInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    mainIds: gql.mains.map(main => main.id),
    indieSensorsIds: gql.industrialSensors.map(industrialSensor => industrialSensor.id),
    lightIds: gql.lights.map(light => light.id),
  };
}

/**
 * Normalizes a Utility Service sent to us from our WebSocket API
 * into a model ready to be put in the store
 */
export function toUtilityServiceInternalModelFromWS(
  ws: UtilityServiceWSModel,
): UtilityServiceInternalModel {
  return {
    id: ws.id,
    name: ws.name,
    mainIds: ws.mainIds,
    indieSensorsIds: ws.industrialSensorIds,
    lightIds: ws.lightIds,
  };
}

/**
 * Inflates a utility service in the store into a model that is
 * ready to be displayed in the UI
 */
export function toUtilityServiceViewModelFromInternal(
  internal: UtilityServiceInternalModel,
  indieSensorLookupTable: Dictionary<IndieSensorInternalModel>,
  lightLookupTable: Dictionary<LightInternalModel>,
  scheduledUtilityRateLookupTable: Dictionary<ScheduledUtilityRateViewModel[]>,
): UtilityServiceViewModel {
  return {
    id: internal.id,
    name: internal.name,
    mains: resolveFromLookupTable(internal.mainIds, indieSensorLookupTable),
    indieSensors: resolveFromLookupTable(
      internal.indieSensorsIds,
      indieSensorLookupTable,
    ),
    lights: resolveFromLookupTable(internal.lightIds, lightLookupTable),
    scheduledUtilityRates: scheduledUtilityRateLookupTable[internal.id] || [],
  };
}

/**
 * Converts a utility service form model into one ready to be suppled
 * by the GraphQL create operation
 */
export function toCreateUtilityServiceInputFromForm(
  form: UtilityServiceFormModel,
  siteId: string,
): CreateUtilityServiceInput {
  return {
    siteId,
    name: form.name,
    utilityType: UtilityType.POWER,
    mainIds: form.mainIds,
    lightIds: form.lightIds,
    industrialSensorIds: form.indieSensorIds,
  };
}

export function toUpdateUtilityServiceInputFromForm(
  form: UtilityServiceFormModel,
): UpdateUtilityServiceInput {
  return {
    id: form.id!,
    name: form.name,
    mainIds: form.mainIds,
    lightIds: form.lightIds,
    industrialSensorIds: form.indieSensorIds,
  };
}
