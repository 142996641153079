import { createAction } from '@ngrx/store';
import { SequenceSceneInternalModel } from '@spog-ui/shared/models/sequence-scenes';

export const enterAction = createAction(
  '[Add Sequence Scene Page] Enter | @spog-sui/scenes',
);
export const addSequenceSceneAction = createAction(
  '[Add Sequence Scene Page] Add Sequence Scene | @spog-sui/scenes',
  (sequenceScene: SequenceSceneInternalModel) => {
    return { sequenceScene };
  },
);

export type Union =
  | ReturnType<typeof enterAction>
  | ReturnType<typeof addSequenceSceneAction>;

export const Types = {
  Enter: enterAction.type,
  AddSequenceScene: addSequenceSceneAction.type,
};
