import { Component, EventEmitter, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ButtonComponent } from '../button/button.component';
import { NgIf } from '@angular/common';
import {
  MatDatepickerInput,
  MatDatepickerToggle,
  MatDatepicker,
} from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import {
  MatFormField,
  MatSuffix,
  MatError,
  MatLabel,
} from '@angular/material/form-field';

@Component({
  selector: 'sui-date-range-form',
  template: `
    <form [formGroup]="dateRangeForm" (submit)="onSubmit($event)" name="dateRangeForm">
      <mat-form-field>
        <mat-label>Choose a start date</mat-label>
        <input
          matInput
          [matDatepicker]="startDate"
          required
          #startDateInput
          formControlName="startDate"
        />
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate startView="month"></mat-datepicker>
      </mat-form-field>
      @if ( dateRangeForm.hasError('required', ['startDate']) &&
      dateRangeForm.get('startDate')?.touched ) {
      <mat-error>Start Date is Required</mat-error>
      }

      <mat-form-field>
        <mat-label>Choose an end date</mat-label>
        <input
          matInput
          [matDatepicker]="endDate"
          required
          #endDateInput
          formControlName="endDate"
        />
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate startView="month"></mat-datepicker>
      </mat-form-field>
      @if ( dateRangeForm.hasError('required', ['endDate']) &&
      dateRangeForm.get('endDate')?.touched ) {
      <mat-error>End Date is Required</mat-error>
      }

      <div class="suidateRangeFormActions">
        <button suiButton raised color="accent" [disabled]="dateRangeForm.disabled">
          Select Date Range
        </button>
      </div>
    </form>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }

      input {
        color: var(--color-foreground-text);
      }

      mat-form-field {
        display: block;
        width: 100%;
        margin: 6px 0;
      }

      .suidateRangeFormActions {
        display: flex;
        flex-direction: row-reverse;
      }
    `,
  ],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    NgIf,
    MatError,
    ButtonComponent,
  ],
})
export class DateRangeFormComponent {
  constructor(private formBuilder: UntypedFormBuilder) {}

  @Output() dateRange = new EventEmitter<any>();

  dateRangeForm: UntypedFormGroup = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
  });

  onSubmit($event: Event): void {
    $event.preventDefault();

    if (this.dateRangeForm.valid) {
      this.dateRange.emit(this.dateRangeForm.value);
    }
  }
}
