import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LightingAppModule } from '../../apps/lighting';
import { environment } from '@spog-ui/shared/environments';
import { worker } from 'projects/simplysnap-spog/ui/shared/mocks';

if (environment.production) {
  enableProdMode();
} else {
  // In non-Production builds, install Mock Service Worker
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

function addClassToRoot(themeSuffix: string) {
  const root = document.querySelector(':root')!;
  root.classList.add(`theme-${themeSuffix}`);
}

addClassToRoot('synapsedark');

platformBrowserDynamic().bootstrapModule(LightingAppModule);
