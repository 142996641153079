import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { OrgAdminEditUserPageActions, OrgAdminUsersApiActions } from '../actions';
export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  error?: any;
  userId: string | null;
}

export const initialState: Shape = {
  isLoading: true,
  isSubmitting: false,
  userId: null,
};

export const reducer = createReducer(
  initialState,
  on(OrgAdminEditUserPageActions.enterAction, (state, action): Shape => {
    return {
      ...initialState,
      userId: action.userId,
    };
  }),
  on(OrgAdminEditUserPageActions.loadPageSuccessAction, (state, action): Shape => {
    return { ...state, isLoading: false };
  }),
  on(OrgAdminEditUserPageActions.loadPageFailureAction, (state, action): Shape => {
    return { ...state, isLoading: false, error: action.error };
  }),
  on(OrgAdminEditUserPageActions.editUserAction, (state, action): Shape => {
    return { ...state, isSubmitting: true };
  }),
  on(OrgAdminUsersApiActions.updateUserFailureAction, (state, action): Shape => {
    return { ...state, isSubmitting: false };
  }),
);

export const STATE_KEY = 'edit-organization-user-page';

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
export const selectActiveId = (state: Shape) => state.userId;
