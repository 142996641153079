import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdminLinkComponent } from './admin-link.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [AdminLinkComponent],
  exports: [AdminLinkComponent],
})
export class AdminLinkComponentModule {}
