import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { Knobs } from '@omni/knobs';
import { Products } from '@spog-ui/graphql/types';
import { environment } from '@spog-ui/shared/environments';
import { CalendarDayModel } from '@spog-ui/shared/models/calendar-day';
import {
  OccurrenceViewModel,
  ScheduledEventOccurrenceModel,
  findOccurrenceWarnings,
  getScheduledEventOccurrences,
  toScheduledEventFormModelFromInternal,
} from '@spog-ui/shared/models/scheduled-events';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import * as CoreState from '@spog-ui/shared/state/core';
import { DateTime } from 'luxon';
import * as AddScheduledEventPageState from './add-scheduled-event-page';
import * as EditScheduledEventPageState from './edit-scheduled-event-page';
import * as SchedulePageState from './schedule-page';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';

export const STATE_KEY = 'schedule';

export interface Shape {
  addScheduledEventPage: AddScheduledEventPageState.Shape;
  editScheduledEventPage: EditScheduledEventPageState.Shape;
  schedulePage: SchedulePageState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  addScheduledEventPage: AddScheduledEventPageState.reducer,
  editScheduledEventPage: EditScheduledEventPageState.reducer,
  schedulePage: SchedulePageState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Schedule Page State
 */
export const selectSchedulePageState = createSelector(
  selectFeatureState,
  state => state.schedulePage,
);
export const selectSchedulePageIsLoading = createSelector(
  selectSchedulePageState,
  SchedulePageState.selectIsLoadingModels,
);
export const selectSchedulePageError = createSelector(
  selectSchedulePageState,
  SchedulePageState.selectError,
);
export const selectSchedulePageIsReady = createSelector(
  selectSchedulePageIsLoading,
  isLoading => !isLoading,
);
export const selectSchedulePageToday = createSelector(
  selectSchedulePageState,
  SchedulePageState.selectToday,
);
export const selectSchedulePageActiveDay = createSelector(
  selectSchedulePageState,
  SchedulePageState.selectActiveDay,
);
export const selectSchedulePageActiveMonth = createSelector(
  selectSchedulePageState,
  SchedulePageState.selectActiveMonth,
);

export const selectNoScheduledEventsExist = createSelector(
  CoreState.selectAllScheduledEvents,
  scheduledEvents => scheduledEvents.length === 0,
);
export const selectSchedulePageTodayInSiteTimeZone = createSelector(
  selectSchedulePageToday,
  CoreState.selectSelectedSiteTimeZone,
  (Today, siteTimeZone): DateTime => Today.setZone(siteTimeZone),
);
export const selectSchedulePageActiveMonthInSiteTimeZone = createSelector(
  selectSchedulePageActiveMonth,
  CoreState.selectSelectedSiteTimeZone,
  (activeMonth, siteTimeZone): DateTime => activeMonth.setZone(siteTimeZone),
);
export const selectSchedulePageDisplayMonth = createSelector(
  selectSchedulePageActiveMonthInSiteTimeZone,
  month => month.monthLong,
);
export const selectSchedulePageDisplayYear = createSelector(
  selectSchedulePageActiveMonthInSiteTimeZone,
  month => month.year,
);
export const selectSchedulePageActiveDayInSiteTimeZone = createSelector(
  selectSchedulePageActiveDay,
  CoreState.selectSelectedSiteTimeZone,
  (activeDay, siteTimeZone): DateTime => activeDay.setZone(siteTimeZone),
);
export const selectSchedulePageActiveDayAsString = createSelector(
  selectSchedulePageActiveDayInSiteTimeZone,
  activeDay => activeDay.toFormat('MMMM d'),
);

export const selectCalendarRange = createSelector(
  selectSchedulePageActiveMonthInSiteTimeZone,
  (activeMonth): DateTime[] => {
    //Luxon returns Sunday as 7 instead of 0 which makes calendar logic much harder.
    const adjustedWeekday = activeMonth.weekday === 7 ? 0 : activeMonth.weekday;

    const weeks = Math.ceil((adjustedWeekday + activeMonth.daysInMonth) / 7); // should be 4, 5, or 6
    const days = weeks * 7;

    return new Array(days)
      .fill(undefined)
      .map((_, i) => i - adjustedWeekday)
      .map(dateOffset => activeMonth.plus({ days: dateOffset }));
  },
);

export const selectSceneZones = createSelector(
  CoreState.selectZoneEntities,
  CoreState.selectAllSceneZoneBehaviors,
  (zoneEntities, sceneZoneBehaviors) => {
    return sceneZoneBehaviors.reduce((zoneLookupTable, sceneZoneBehavior) => {
      const zone = zoneEntities[sceneZoneBehavior.zoneId];
      const sceneId = sceneZoneBehavior.sceneId;
      let scene = zoneLookupTable[sceneId];

      if (!zone) {
        return zoneLookupTable;
      }

      if (!scene) {
        scene = zoneLookupTable[sceneId] = [];
      }

      scene.push(zone);

      return zoneLookupTable;
    }, {} as { [sceneId: string]: ZoneInternalModel[] });
  },
);

const toOccurrenceViewModel = (
  occurrence: ScheduledEventOccurrenceModel,
  allScenes: SceneInternalModel[],
  zoneLookupTable: { [sceneId: string]: ZoneInternalModel[] },
): OccurrenceViewModel => ({
  ...occurrence,
  sceneNames: occurrence.sceneIds.map(sceneId => {
    const matchingScene = allScenes.find(scene => scene.id === sceneId);
    return matchingScene?.name ?? '';
  }),
  sceneZones: occurrence.sceneIds.flatMap((id: string) => zoneLookupTable[id]),
});

export const selectCalendarOccurrences = createSelector(
  CoreState.selectSelectedSiteLocationSettings,
  CoreState.selectAllScheduledEventViews,
  CoreState.selectAllScenes,
  selectCalendarRange,
  selectSceneZones,
  (
    locationSettings,
    scheduledEventViews,
    allScenes,
    calendarRange,
    zoneLookupTable,
  ): OccurrenceViewModel[] => {
    return getScheduledEventOccurrences(
      scheduledEventViews,
      calendarRange[0],
      calendarRange[calendarRange.length - 1],
      locationSettings,
    )
      .map(occurrence => toOccurrenceViewModel(occurrence, allScenes, zoneLookupTable))
      .sort((a, b) => a.occurrenceDateTime.valueOf() - b.occurrenceDateTime.valueOf());
  },
);

export const selectCalendarOccurrencesByDate = createSelector(
  selectCalendarOccurrences,
  (occurrences: OccurrenceViewModel[]) =>
    occurrences.reduce<{ [timestamp: string]: OccurrenceViewModel[] }>(
      (occurrencesByDate, occurrence) => {
        const timestamp = occurrence.occurrenceDateTime
          //Subtract an offset if one exists so we keep solar events on their relative day.
          .minus({ minutes: occurrence.astroTimeOffset || 0 })
          .startOf('day')
          .valueOf();

        if (occurrencesByDate[timestamp] === undefined) {
          occurrencesByDate[timestamp] = [occurrence];
        } else {
          occurrencesByDate[timestamp].push(occurrence);
        }
        return occurrencesByDate;
      },
      {},
    ),
);

export const selectCalendarDays = createSelector(
  selectCalendarRange,
  selectSchedulePageTodayInSiteTimeZone,
  selectSchedulePageActiveDayInSiteTimeZone,
  selectSchedulePageActiveMonthInSiteTimeZone,
  selectCalendarOccurrencesByDate,
  (calendarRange, today, activeDay, activeMonth, occurrencesByDate): CalendarDayModel[] =>
    calendarRange.map(date => {
      return {
        date,
        isToday: date.valueOf() === today.valueOf(),
        isSelected: date.valueOf() === activeDay.valueOf(),
        isNotActiveMonth: !(date.startOf('month').valueOf() === activeMonth.valueOf()),
        totalScheduledEvents:
          occurrencesByDate[date.valueOf()] === undefined
            ? 0
            : occurrencesByDate[date.valueOf()].length,
      };
    }),
);

export const selectCalendarActiveDayOccurrences = createSelector(
  selectCalendarOccurrencesByDate,
  selectSchedulePageActiveDayInSiteTimeZone,
  (occurrencesByDate, activeDay) => {
    return occurrencesByDate[activeDay.valueOf()] || [];
  },
);

export const selectOccurrenceWarnings = createSelector(
  selectCalendarActiveDayOccurrences,
  CoreState.selectZoneEntities,
  findOccurrenceWarnings,
);

export const selectShowCalculatedAstroTimes = createSelector(
  selectSchedulePageState,
  SchedulePageState.selectShowCalculatedAstroTimes,
);

/**
 * Add Sceduled Event Page State
 */
export const selectAddScheduledEventPageState = createSelector(
  selectFeatureState,
  state => state.addScheduledEventPage,
);

export const selectAddingScheduledEvent = createSelector(
  selectAddScheduledEventPageState,
  AddScheduledEventPageState.selectAdding,
);

export const selectAddingScheduledEventError = createSelector(
  selectAddScheduledEventPageState,
  AddScheduledEventPageState.selectError,
);

export const selectAddScheduledEventPageIsLoading = createSelector(
  selectAddScheduledEventPageState,
  AddScheduledEventPageState.selectIsLoading,
);

export const selectAddScheduledEventPageIsReady = createSelector(
  selectAddScheduledEventPageIsLoading,
  isLoading => !isLoading,
);

/**
 * Edit Scheduled Event Page State
 */
export const selectEditScheduledEventPageState = createSelector(
  selectFeatureState,
  state => state.editScheduledEventPage,
);

export const selectEditingScheduledEvent = createSelector(
  selectEditScheduledEventPageState,
  EditScheduledEventPageState.selectEditing,
);

export const selectEditingScheduledEventError = createSelector(
  selectEditScheduledEventPageState,
  EditScheduledEventPageState.selectError,
);

export const selectEditScheduledEventPageIsLoading = createSelector(
  selectEditScheduledEventPageState,
  EditScheduledEventPageState.selectIsLoading,
);

export const selectEditScheduledEventPageIsReady = createSelector(
  selectEditScheduledEventPageIsLoading,
  isLoading => !isLoading,
);

export const selectEditPageScheduledEventId = createSelector(
  selectEditScheduledEventPageState,
  EditScheduledEventPageState.selectScheduledEventId,
);

export const selectScheduledEventToEdit = createSelector(
  selectEditPageScheduledEventId,
  CoreState.selectScheduledEventEntities,
  (scheduledEventId, scheduledEvents) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return toScheduledEventFormModelFromInternal(scheduledEvents[scheduledEventId!]!);
  },
);

export const selectSupportsLighting = createSelector(
  CoreState.selectSelectedSiteProducts,
  products => products.includes(Products.ILLUMINATE),
);

export const selectSupportsClimate = createSelector(
  CoreState.selectSelectedSiteProducts,
  products => products.includes(Products.CLIMATE),
);
