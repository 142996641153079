import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { filter, first } from 'rxjs/operators';
import { ScenesPageState, AddScenePageState, EditScenePageState } from '.';
import { BehaviorInternalModel } from '@spog-ui/shared/models/behaviors';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import * as ScenesState from '@spog-ui/shared/state/scenes';
import * as CoreState from '@spog-ui/shared/state/core';
import {
  SceneInternalModel,
  SceneApplication,
  SceneViewModel,
  SceneFormModel,
} from '@spog-ui/shared/models/scenes';
import {
  SequenceSceneViewModel,
  SequenceSceneInternalModel,
} from '@spog-ui/shared/models/sequence-scenes';
import { AddSequenceScenePageState } from './add';
import { EditSequenceScenePageState } from './edit';
import { ActiveSequenceSceneViewModel } from '@spog-ui/shared/models/active-sequence-scenes';

@Injectable()
export class ScenesPageStateAdapter implements ScenesPageState {
  constructor(private store: Store) {}

  application$: Observable<SceneApplication> = this.store
    .select(ScenesState.selectScenesPageApplication)
    .pipe(filter((x): x is SceneApplication => !!x));
  ready$: Observable<boolean> = this.store.select(ScenesState.selectScenesPageIsReady);
  scenes$: Observable<SceneViewModel[]> = this.store.select(
    ScenesState.selectSceneViewsFilteredBySearchTerm,
  );
  sequenceScenes$: Observable<SequenceSceneViewModel[]> = this.store.select(
    ScenesState.selectSequenceSceneViewsFilteredBySearchTerm,
  );
  activeSequenceScenes$: Observable<ActiveSequenceSceneViewModel[]> = this.store.select(
    CoreState.selectActiveSequenceSceneViews,
  );
  noScenesFound$: Observable<boolean> = this.store.select(
    ScenesState.selectNoScenesFound,
  );
  noSequenceScenesFound$: Observable<boolean> = this.store.select(
    ScenesState.selectNoSequenceScenesFound,
  );
  noScenesExist$: Observable<boolean> = this.store.select(
    ScenesState.selectNoScenesExistForApplication,
  );
  noSequenceScenesExist$: Observable<boolean> = this.store.select(
    ScenesState.selectNoSequenceScenesExist,
  );
  searchTerm$: Observable<string> = this.store.select(ScenesState.selectScenesSearchTerm);

  urlPrefix$: Observable<string> = this.store.select(CoreState.selectSiteURLPrefix);

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}

@Injectable()
export class AddScenePageStateAdapter implements AddScenePageState {
  constructor(private store: Store) {}

  application$: Observable<SceneApplication> = this.store
    .select(ScenesState.selectAddScenePageApplication)
    .pipe(filter((x): x is SceneApplication => !!x));
  ready$: Observable<boolean> = this.store.select(ScenesState.selectAddScenesPageIsReady);
  saving$: Observable<boolean> = this.store.select(ScenesState.selectAddingScene);
  scenes$: Observable<SceneInternalModel[]> = this.store.select(
    CoreState.selectAllScenes,
  );
  controlZones$: Observable<ZoneInternalModel[]> = this.store.select(
    ScenesState.selectAddScenePageZonesForApplication,
  );
  behaviors$: Observable<BehaviorInternalModel[]> = this.store.select(
    ScenesState.selectAddScenePageBehaviorsForApplication,
  );

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}

@Injectable()
export class AddSequenceScenePageStateAdapter implements AddSequenceScenePageState {
  constructor(private store: Store) {}

  ready$: Observable<boolean> = this.store.select(
    ScenesState.selectAddSequenceScenesPageIsReady,
  );
  saving$: Observable<boolean> = this.store.select(ScenesState.selectAddingSequenceScene);
  sequenceScenes$: Observable<SequenceSceneInternalModel[]> = this.store.select(
    CoreState.selectAllSequenceScenes,
  );
  staticScenes$: Observable<SceneInternalModel[]> = this.store.select(
    CoreState.selectAllScenes,
  );

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}

@Injectable()
export class EditScenePageStateAdapter implements EditScenePageState {
  constructor(private store: Store) {}

  application$: Observable<SceneApplication> = this.store
    .select(ScenesState.selectEditScenePageApplication)
    .pipe(filter((x): x is SceneApplication => !!x));
  ready$: Observable<boolean> = this.store.select(
    ScenesState.selectEditScenesPageIsReady,
  );
  saving$: Observable<boolean> = this.store.select(ScenesState.selectEditingScene);
  scenes$: Observable<SceneInternalModel[]> = this.store.select(
    ScenesState.selectScenesWithoutSceneToEdit,
  );
  controlZones$: Observable<ZoneInternalModel[]> = this.store.select(
    ScenesState.selectEditScenePageZonesForApplication,
  );
  behaviors$: Observable<BehaviorInternalModel[]> = this.store.select(
    ScenesState.selectEditScenePageBehaviorsForApplication,
  );
  sceneToEdit$: Observable<SceneFormModel> = this.store
    .select(ScenesState.selectSceneToEdit)
    .pipe(first());

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}

@Injectable()
export class EditSequenceScenePageStateAdapter implements EditSequenceScenePageState {
  constructor(private store: Store) {}

  ready$: Observable<boolean> = this.store.select(
    ScenesState.selectEditSequenceScenesPageIsReady,
  );
  saving$: Observable<boolean> = this.store.select(
    ScenesState.selectEditingSequenceScene,
  );
  sequenceScenes$: Observable<SequenceSceneInternalModel[]> = this.store.select(
    ScenesState.selectSequenceScenesWithoutSequenceSceneToEdit,
  );
  staticScenes$: Observable<SceneInternalModel[]> = this.store.select(
    CoreState.selectAllScenes,
  );

  sequenceSceneToEdit$: Observable<SequenceSceneInternalModel> = this.store
    .select(ScenesState.selectSequenceSceneToEdit)
    .pipe(first());

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }
}
