import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DatePickerSelection } from '../../models/date-range.model';

@Component({
  selector: 'spog-custom-date-select',
  template: `
    <sui-dialog>
      <div class="container">
        <sui-date-range-form
          (dateRange)="onCustomDateRangeSelected($event)"
        ></sui-date-range-form>
      </div>
    </sui-dialog>
  `,
  styles: [
    `
      .container {
        padding: 24px;
      }
    `,
  ],
})
export class CustomDateSelectComponent {
  constructor(private dialogRef: MatDialogRef<CustomDateSelectComponent>) {}

  onCustomDateRangeSelected(selectedDate: DatePickerSelection) {
    this.dialogRef.close(selectedDate);
  }
}
