import { createReducer, on } from '@ngrx/store';
import { SceneApplication } from '@spog-ui/shared/models/scenes';
import { EditScenePageActions, ScenesApiActions } from '@spog-ui/scenes/actions';

export interface Shape {
  application: SceneApplication | null;
  sceneId: string | null;
  editing: boolean;
  loading: boolean;
  error?: any;
}

const initialState: Shape = {
  application: null,
  sceneId: null,
  editing: false,
  loading: true,
};

export const reducer = createReducer(
  initialState,
  on(EditScenePageActions.enterAction, (state, action) => ({
    ...state,
    application: action.application,
    sceneId: action.sceneId,
  })),
  on(EditScenePageActions.editSceneAction, state => {
    return {
      ...state,
      editing: true,
      error: null,
    };
  }),
  on(ScenesApiActions.editSceneSuccessAction, state => {
    return {
      ...state,
      editing: false,
      error: null,
    };
  }),
  on(ScenesApiActions.editSceneFailureAction, (state, action) => {
    return {
      ...state,
      editing: false,
      error: action.error,
    };
  }),
  on(ScenesApiActions.loadSuccessAction, state => {
    return {
      ...state,
      error: null,
      editing: false,
      loading: false,
    };
  }),
  on(ScenesApiActions.loadFailureAction, (state, action) => {
    return {
      ...state,
      error: action.error,
      editing: false,
      loading: false,
    };
  }),
);

export const selectApplication = (state: Shape) => state.application;
export const selectEditing = (state: Shape) => state.editing;
export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
export const selectSceneId = (state: Shape) => state.sceneId;
