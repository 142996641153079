import { Component } from '@angular/core';

@Component({
  selector: 'spog-nav-footer',
  template: `
    <div class="flex-column">
      <spog-app-update></spog-app-update>
      <div class="flex-row footer">
        <spog-admin-link></spog-admin-link>
        <spog-app-update-check></spog-app-update-check>
        <spog-refresh-button></spog-refresh-button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        padding: 24px 16px;
        justify-content: space-between;
      }

      .flex-column {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
      }

      .flex-row {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
      }

      .footer {
        margin-top: 12px;
      }

      spog-admin-link {
        margin-right: 12px;
      }

      spog-app-update-check {
        flex-grow: 2;
      }
    `,
  ],
})
export class NavFooterComponent {}
