import { createReducer, on } from '@ngrx/store';
import { AddSequenceScenePageActions, ScenesApiActions } from '@spog-ui/scenes/actions';

export interface Shape {
  error?: any;
  adding: boolean;
  loading: boolean;
}

const initialState: Shape = {
  adding: false,
  loading: true,
};

export const reducer = createReducer(
  initialState,
  on(AddSequenceScenePageActions.enterAction, (state, action) => ({
    adding: false,
    loading: true,
  })),
  on(AddSequenceScenePageActions.addSequenceSceneAction, state => {
    return {
      ...state,
      error: null,
      adding: true,
      loading: false,
    };
  }),
  on(ScenesApiActions.addSequenceSceneSuccessAction, state => {
    return {
      ...state,
      error: null,
      adding: false,
      loading: false,
    };
  }),
  on(ScenesApiActions.addSequenceSceneFailureAction, (state, action) => {
    return {
      ...state,
      error: action.error,
      adding: false,
      loading: false,
    };
  }),
  on(ScenesApiActions.loadSuccessAction, state => {
    return {
      ...state,
      error: null,
      adding: false,
      loading: false,
    };
  }),
  on(ScenesApiActions.loadFailureAction, (state, action) => {
    return {
      ...state,
      error: action.error,
      adding: false,
      loading: false,
    };
  }),
);

export const selectAdding = (state: Shape) => state.adding;
export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
