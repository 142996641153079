import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-photocell-only',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        <strong>Daylighting</strong> - Only the photocell sensor controls the lights in
        the zone. After daylight is detected for a configured interval, the photocell dims
        the lights to the specified value. When darkness is detected, the photocell sets
        the lights to the specified value after the set period of time.
      </p>
      <div class="bfForm">
        <div class="bfChunk">
          <p class="bfLabel">If daylight for a period of:</p>
          <sui-timeout-control
            formControlName="photocellHighTimeout"
          ></sui-timeout-control>
          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="whenDaylightLevel"></sui-dimmer-control>
        </div>

        <div class="bfChunk">
          <p class="bfLabel">Otherwise, if dark for a period of:</p>
          <sui-timeout-control
            formControlName="photocellLowTimeout"
          ></sui-timeout-control>

          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="whenDarkLevel"></sui-dimmer-control>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      sui-dimmer-control {
        display: block;
        margin-bottom: -13px;
      }

      .bfChunk:last-of-type sui-dimmer-control {
        margin-bottom: 0;
      }
    `,
  ],
})
export class BFPhotocellOnlyComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    whenDarkLevel: new UntypedFormControl(80),
    whenDaylightLevel: new UntypedFormControl(0),
    photocellHighTimeout: new UntypedFormControl(0.5),
    photocellLowTimeout: new UntypedFormControl(0.5),
  });
}
