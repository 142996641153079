import { createAction } from '@ngrx/store';

import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const loadSiteControllerDetailsSuccessAction = createAction(
  '[Site Controller Details API] Load Site Controller Details Success',
  (
    siteController: SiteControllerInternalModel | null,
    site: SiteInternalModel | null,
  ) => ({
    siteController,
    site,
  }),
);
export const loadSiteControllerDetailsFailureAction = createAction(
  '[Site Controller Details API] Load Site Controller Details Failure',
  (error: any) => ({ error }),
);
export const deleteSuccessAction = createAction(
  '[Site Controller Details API] Delete Success',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const deleteFailureAction = createAction(
  '[Site Controller Details API] Delete Failure',
  (error: string) => {
    return { error };
  },
);
export const initResourcesSuccessAction = createAction(
  '[Site Controller Details API] Init Resources Success',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);
export const initResourcesFailureAction = createAction(
  '[Site Controller Details API] Init Resources Failure',
  (error: string) => {
    return { error };
  },
);
export const moveSuccessAction = createAction(
  '[Site Controller Details API] Move Success',
);
export const moveFailureAction = createAction(
  '[Site Controller Details API] Move Failure',
  (error: string) => {
    return { error };
  },
);

export const replaceSuccessAction = createAction(
  '[Site Controller Details API] Replace Success',
);
export const replaceFailureAction = createAction(
  '[Site Controller Details API] Replace Failure',
  (error: string) => {
    return { error };
  },
);

export const importSuccessAction = createAction(
  '[Site Controller Details API] Import Success',
);
export const importFailureAction = createAction(
  '[Site Controller Details API] Import Failure',
  (error: string) => {
    return { error };
  },
);
