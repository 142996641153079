import { createSelector } from '@ngrx/store';
import * as EmailGroupState from '@spog-ui/shared/state/email-groups';
import { filterBy } from '@spog-ui/utils/filter';
import { selectFeature } from './constants';
import {
  selectError,
  selectIsLoading,
  selectPageIndex,
  selectPageSize,
  selectSearchTerm,
  STATE_KEY,
} from './email-groups-page.reducer';

export const selectOrgAdminEmailsGroupPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsEmailGroupsPageLoading = createSelector(
  selectOrgAdminEmailsGroupPageFeatureState,
  selectIsLoading,
);

export const selectEmailGroupsPageError = createSelector(
  selectOrgAdminEmailsGroupPageFeatureState,
  selectError,
);

export const selectEmailGroupsPageFilter = createSelector(
  selectOrgAdminEmailsGroupPageFeatureState,
  selectSearchTerm,
);

export const selectEmailGroupsPageIndex = createSelector(
  selectOrgAdminEmailsGroupPageFeatureState,
  selectPageIndex,
);

export const selectEmailGroupsPageSize = createSelector(
  selectOrgAdminEmailsGroupPageFeatureState,
  selectPageSize,
);

export const selectEmailGroupsShowGettingStartedCard = createSelector(
  EmailGroupState.selectAllEmailGroups,
  emailGroups => emailGroups.length === 0,
);

export const selectFilteredEmailGroups = createSelector(
  EmailGroupState.selectAllEmailGroups,
  selectEmailGroupsPageFilter,
  (emailGroups, filter) =>
    emailGroups.filter(emailGroup => {
      const searchTerm = filter.trim().toLowerCase();
      return (
        // Name
        filterBy(searchTerm, emailGroup.name.toLowerCase()) ||
        // ID prefixed with 'id:'
        filterBy(searchTerm, emailGroup.id.toLowerCase(), 'id:')
      );
    }),
);

export const selectEmailGroupsPageLength = createSelector(
  selectFilteredEmailGroups,
  emailGroups => emailGroups.length,
);

export const selectPaginatedEmailGroups = createSelector(
  selectFilteredEmailGroups,
  selectEmailGroupsPageIndex,
  selectEmailGroupsPageSize,
  (emailGroups, index, size) => {
    return emailGroups.slice(index * size, index * size + size);
  },
);
