import { ZONE_COMPARISON_GRAPH } from '@spog-ui/shared/models/graphs';
import { scaleLinear, scaleTime } from 'd3-scale';
import { DateTime } from 'luxon';
import {
  DateDomainModel,
  PowerDomainModel,
  EnergyDomainModel,
} from './power-source-comparison.model';

const graph = ZONE_COMPARISON_GRAPH;
const maxDate: DateTime = DateTime.local().set({ second: 0, millisecond: 0 });
const minDate: DateTime = maxDate.minus({ days: 1 });

export function createMockEnergyScale() {
  return scaleLinear();
}

export function createMockDateScale() {
  return scaleTime();
}

export function createDefaultPowerScale(powerDomain: PowerDomainModel = [0, 5]) {
  return scaleLinear()
    .range([graph.height - graph.margin.bottom, graph.margin.top])
    .domain(powerDomain)
    .nice();
}

export function createDefaultEnergyScale(energyDomain: EnergyDomainModel = [0, 5]) {
  return scaleLinear()
    .range([graph.height - graph.margin.bottom, graph.margin.top])
    .domain(energyDomain)
    .nice();
}

export function createDefaultDateScale(dateDomain: DateDomainModel = [minDate, maxDate]) {
  return scaleTime()
    .range([graph.margin.left, graph.width - graph.margin.right])
    .domain(dateDomain);
}
