import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { markAsTouched } from '@spog-ui/shared/components';
import {
  SequenceSceneInternalModel,
  toComponentsFromSeconds,
  fromInputsToSeconds,
} from '@spog-ui/shared/models/sequence-scenes';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';

@Component({
  selector: 'scn-sequence-scene-form',
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <input type="hidden" formControlName="id" />
      <div class="suiSequenceSceneFormBody">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input
            type="text"
            matInput
            formControlName="name"
            [scnValidateSequenceSceneName]="takenSceneNames"
          />
          <mat-error [suiFormError]="form.get('name')?.errors"></mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Start Scene</mat-label>
          <mat-select formControlName="startSceneId">
            @for (scene of scenes; track scene) {
            <mat-option [value]="scene.id">
              {{ scene.name }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <scn-sequence-scene-step-list formControlName="steps" [scenes]="scenes">
      </scn-sequence-scene-step-list>

      <mat-slide-toggle formControlName="loop"> Loop continuously </mat-slide-toggle>

      @if (form.get('loop')?.value) {
      <sui-card class="loopWaitInputs">
        <sui-card-body>
          <mat-label class="waitLabel">Wait Before Looping</mat-label>
          <div class="waitInputs">
            <mat-form-field>
              <mat-label>Hours</mat-label>
              <input
                type="number"
                matInput
                formControlName="loopWaitHours"
                min="0"
                max="23"
              />
              <mat-error *ngIf="form.get('loopWaitHours')?.invalid"
                >Must be between 0 and 23</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Minutes</mat-label>
              <input
                type="number"
                matInput
                formControlName="loopWaitMinutes"
                min="0"
                max="59"
              />
              <mat-error *ngIf="form.get('loopWaitMinutes')?.invalid"
                >Must be between 0 and 59</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <mat-label>Seconds</mat-label>
              <input
                type="number"
                matInput
                formControlName="loopWaitSeconds"
                min="0"
                max="59"
              />
              <mat-error *ngIf="form.get('loopWaitSeconds')?.invalid"
                >Must be between 0 and 459</mat-error
              >
            </mat-form-field>
          </div>
        </sui-card-body>
      </sui-card>
      }

      <div class="suiSequenceSceneFormActions">
        <button
          [disabled]="form.disabled"
          data-test-id="create-sequence-scene-button"
          type="submit"
          suiButton
          raised
          color="accent"
        >
          @switch (mode) { @case ('add') { Create Sequence Scene } @case ('edit') { Save
          Sequence Scene } @default { Mode not supported } }
        </button>

        @switch (mode) { @case ('edit') {
        <button
          [disabled]="form.disabled"
          data-test-id="cancel-sequence-scene-button"
          [routerLink]="['../../']"
          suiButton
        >
          Cancel
        </button>
        } @default {
        <button
          [disabled]="form.disabled"
          data-test-id="cancel-sequence-scene-button"
          [routerLink]="['../']"
          suiButton
        >
          Cancel
        </button>
        } }
      </div>
    </form>
  `,
  styles: [
    `
      :host {
        width: 100%;
        display: block;
        padding: 16px;
        margin: 0 auto;
      }

      form {
        display: block;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
      }

      .suiSequenceSceneFormBody {
        padding: 8px;
        width: 100%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
      }

      .suiSequenceSceneFormBody mat-form-field {
        max-width: 400px;
        width: 100%;
      }

      scn-sequence-scene-step-list {
        padding-bottom: 8px;
        padding-left: 8px;
        display: block;
      }

      mat-form-field {
        padding-bottom: 1.25em;
      }

      mat-slide-toggle {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 8px;
      }

      .waitInputs {
        display: flex;
        justify-content: space-between;
      }
      .waitInputs .mat-mdc-form-field {
        width: 100px;
      }
      .waitInputs .mat-mdc-form-field:not(:last-child) {
        margin-right: 16px;
      }
      .loopWaitInputs {
        margin-top: 8px;
        margin-left: 8px;
      }
    `,
  ],
})
export class SequenceSceneFormComponent implements AfterViewInit {
  fromInputsToSeconds = fromInputsToSeconds;

  public form = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    name: new UntypedFormControl(''),
    startSceneId: new UntypedFormControl(null),
    steps: new UntypedFormControl([]),
    loop: new UntypedFormControl(false),
    loopWaitHours: new UntypedFormControl(0),
    loopWaitMinutes: new UntypedFormControl(0),
    loopWaitSeconds: new UntypedFormControl(0),
  });
  public mode: 'add' | 'edit' = 'add';
  public takenSceneNames: string[] = [];

  private _scenes: SceneInternalModel[] = [];

  @ViewChild(MatInput, { static: true }) nameInput?: MatInput;

  @Input() set sequenceScenes(sequenceScenes: SequenceSceneInternalModel[]) {
    this.takenSceneNames = sequenceScenes.map(sequenceScene => sequenceScene.name);
  }
  @Input() set staticScenes(scenes: SceneInternalModel[]) {
    this._scenes = scenes.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }
  @Input() set value(value: SequenceSceneInternalModel) {
    this.mode = 'edit';

    const firstStep = value.steps[0];

    const startSceneId = firstStep.applySceneId!;

    const otherSteps = value.steps.slice(1);

    const steps = [];

    for (let i = 0; i < otherSteps.length; i += 2) {
      const waitStep = otherSteps[i];
      const sceneStep = otherSteps[i + 1];

      steps.push({
        sceneId: sceneStep.applySceneId!,
        ...toComponentsFromSeconds(waitStep.duration),
      });
    }

    this.form.patchValue({
      id: value.id,
      name: value.name,
      startSceneId,
      steps,
      loop: value.loop,
      loopWaitHours: value.loopWaitHours,
      loopWaitMinutes: value.loopWaitMinutes,
      loopWaitSeconds: value.loopWaitSeconds,
    });
  }
  @Input() set disabled(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }
  @Output() save = new EventEmitter<SequenceSceneInternalModel>();

  get scenes(): SceneInternalModel[] {
    return this._scenes;
  }

  ngAfterViewInit(): void {
    if (this.nameInput && this.nameInput.focus && this.mode === 'add') {
      this.nameInput.focus();
    }
  }

  onSubmit(): void {
    markAsTouched(this.form);
    if (!this.form.valid) return void 0;

    const explodedSteps: any[] = [
      {
        stepNumber: 1,
        applySceneId: this.form.value.startSceneId,
      },
    ];

    this.form.value.steps.forEach((step: any) => {
      explodedSteps.push(
        {
          stepNumber: explodedSteps.length + 1,
          duration: this.fromInputsToSeconds(
            step.waitHours,
            step.waitMinutes,
            step.waitSeconds,
          ),
        },
        {
          stepNumber: explodedSteps.length + 2,
          applySceneId: step.sceneId,
        },
      );
    });

    this.save.emit({
      id: this.form.value.id,
      name: this.form.value.name.trim(),
      steps: explodedSteps,
      loop: this.form.value.loop,
      loopWaitHours: this.form.value.loopWaitHours,
      loopWaitMinutes: this.form.value.loopWaitMinutes,
      loopWaitSeconds: this.form.value.loopWaitSeconds,
    });
  }
}
