import { createReducer, on } from '@ngrx/store';
import { EditUserApiActions, EditUserPageActions } from '@spog-ui/admin/actions';

export interface Shape {
  isLoading: boolean;
  isSaving: boolean;
  error: any;

  userId: string | null;
}

export const initialState: Shape = {
  isLoading: true,
  isSaving: false,
  error: null,

  userId: null,
};

export const reducer = createReducer(
  initialState,
  on(EditUserPageActions.enterAction, (_, { userId }) => ({ ...initialState, userId })),
  on(EditUserApiActions.loadModelsSuccessAction, state => ({
    ...state,
    isLoading: false,
  })),
  on(EditUserApiActions.loadModelsFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(EditUserPageActions.saveAction, state => ({ ...state, isSaving: true })),
  on(EditUserApiActions.updateUserSuccessAction, state => ({
    ...state,
    isSaving: false,
  })),
  on(EditUserApiActions.updateUserFailureAction, (state, { error }) => ({
    ...state,
    isSaving: false,
    error,
  })),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSaving = (state: Shape) => state.isSaving;
export const selectError = (state: Shape) => state.error;
export const selectUserId = (state: Shape) => state.userId;
