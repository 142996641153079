import { createAction } from '@ngrx/store';
import { SceneViewModel } from '@spog-ui/shared/models/scenes';
import { SequenceSceneViewModel } from '@spog-ui/shared/models/sequence-scenes';

export const applySceneSuccessAction = createAction(
  '[Shared Scenes API] Apply Scene Success',
  (sceneId: string) => {
    return { sceneId };
  },
);
export const applySceneFailureAction = createAction(
  '[Shared Scenes API] Apply Scene Failure',
  (scene: SceneViewModel, error: string) => {
    return { scene, error };
  },
);
export const readySceneAction = createAction(
  '[Shared Scenes API] Ready Scene',
  (sceneId: string) => {
    return { sceneId };
  },
);

export const applySequenceSceneSuccessAction = createAction(
  '[Shared Scenes API] Apply Sequence Scene Success',
  (sequenceSceneId: string) => {
    return { sequenceSceneId };
  },
);
export const applySequenceSceneFailureAction = createAction(
  '[Shared Scenes API] Apply Sequence Scene Failure',
  (sequenceScene: SequenceSceneViewModel, error: string) => {
    return { sequenceScene, error };
  },
);
export const readySequenceSceneAction = createAction(
  '[Shared Scenes API] Ready Sequence Scene',
  (sequenceSceneId: string) => {
    return { sequenceSceneId };
  },
);
