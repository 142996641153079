import { createReducer, on } from '@ngrx/store';
import { AppUpdatesActions, AppUpdatesApiActions } from '@spog-ui/app-updates/actions';

export interface Shape {
  checkingForUpdate: boolean;
  updateAvailable: boolean;
  downloadingUpdate: boolean;
  updateDownloaded: boolean;
}

export const initialState: Shape = {
  checkingForUpdate: false,
  updateAvailable: false,
  downloadingUpdate: false,
  updateDownloaded: false,
};

export const reducer = createReducer(
  initialState,

  on(AppUpdatesActions.checkForUpdate, (state, action) => {
    return { ...state, checkingForUpdate: true };
  }),

  on(
    AppUpdatesApiActions.checkForUpdateFailure,
    AppUpdatesApiActions.checkForUpdateSuccess,
    (state, action) => {
      return { ...state, checkingForUpdate: false };
    },
  ),

  on(AppUpdatesApiActions.updateAvailable, (state, action) => {
    return { ...state, checkingForUpdate: false, updateAvailable: true };
  }),

  on(AppUpdatesActions.downloadUpdate, (state, action) => {
    return { ...state, updateAvailable: false, downloadingUpdate: true };
  }),

  on(AppUpdatesApiActions.downloadUpdateSuccess, (state, action) => {
    return { ...state, downloadingUpdate: false, updateDownloaded: true };
  }),
  on(AppUpdatesApiActions.downloadUpdateFailure, (state, action) => {
    return { ...state, downloadingUpdate: false };
  }),
);

export const selectIsCheckingForUpdate = (state: Shape) => state.checkingForUpdate;
export const selectIsUpdateAvailable = (state: Shape) => state.updateAvailable;
export const selectIsDownloadingUpdate = (state: Shape) => state.downloadingUpdate;
export const selectIsUpdateDownloaded = (state: Shape) => state.updateDownloaded;
