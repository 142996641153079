import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SuiEmptyStateModule } from '../empty-state';
import { ErrorCardComponent } from './error-card.component';

@NgModule({
    imports: [CommonModule, MatButtonModule, SuiEmptyStateModule, ErrorCardComponent],
    exports: [ErrorCardComponent],
})
export class ErrorCardComponentModule {}
