import { createSelector } from '@ngrx/store';
import { toPermissionGroupFormModelFromInternal } from '@spog-ui/shared/models/permission-groups';
import { selectAllPermissionGroups } from '@spog-ui/shared/state/permission-groups';
import { selectFeature } from './constants';
import {
  STATE_KEY,
  selectIsLoading,
  selectError,
  selectIsSubmitting,
  selectActiveId,
} from './edit-permission-group-page.reducer';

export const selectEditPermissionGroupsPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsEditPermissionGroupPageLoading = createSelector(
  selectEditPermissionGroupsPageFeatureState,
  selectIsLoading,
);
export const selectEditPermissionGroupPageError = createSelector(
  selectEditPermissionGroupsPageFeatureState,
  selectError,
);
export const selectIsEditPermissionGroupPageSubmitting = createSelector(
  selectEditPermissionGroupsPageFeatureState,
  selectIsSubmitting,
);
export const selectPermissionGroupIdForEdit = createSelector(
  selectEditPermissionGroupsPageFeatureState,
  selectActiveId,
);

export const selectPermissionGroupForEdit = createSelector(
  selectAllPermissionGroups,
  selectPermissionGroupIdForEdit,
  (permissionGroups, id) => {
    const permissionGroupToEdit = permissionGroups.find(e => e.id === id);
    return permissionGroupToEdit
      ? toPermissionGroupFormModelFromInternal(permissionGroupToEdit)
      : null;
  },
);
