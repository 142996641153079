import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { NgIf } from '@angular/common';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';

@Component({
    selector: 'sui-alarm-card',
    template: `
    <mat-card class="alarmCard" role="alert">
      <mat-card-content>
        <mat-card-title>{{ title }}</mat-card-title>
        <p>{{ description }}</p>
        @if (ctaButtonText) {
          <button
            suiButton
            type="button"
            (click)="ctaButtonClick.emit()"
            >
            {{ ctaButtonText }}
          </button>
        }
      </mat-card-content>
    </mat-card>
    `,
  styles: [
    `
      mat-card {
        border-radius: 2px;
        background-color: var(--color-warn-500);
        color: var(--color-warn-contrast-500);
        margin-bottom: 16px;
      }

      mat-card-title {
        font-size: 16px;
        font-weight: normal;
        padding: 0px;
      }

      button {
        border: 1px solid var(--color-warn-contrast-500);
        border-radius: 2px;
        color: var(--color-warn-contrast-500);
        height: 36px;
        padding: 0px;
      }
    `,
    ],
    standalone: true,
    imports: [
        MatCard,
        MatCardContent,
        MatCardTitle,
        NgIf,
        ButtonComponent,
    ],
})
export class AlarmCardComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() ctaButtonText?: string;

  @Output() ctaButtonClick = new EventEmitter();
}
