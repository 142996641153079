import { Component, Input } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { SequenceSceneInternalModel } from '@spog-ui/shared/models/sequence-scenes';

@Component({
  selector: 'scn-sequence-scene-step-list',
  template: `
    <scn-sequence-scene-step-control
      *ngFor="let control of formArray.controls; let i = index"
      [index]="i"
      [formControl]="control"
      [scenes]="scenes"
      [open]="expanded === i"
      (remove)="removeControl(i)"
      (expand)="expanded = i"
      (closed)="expanded = -1"
    >
    </scn-sequence-scene-step-control>
    <sui-card>
      <sui-card-footer>
        <button suiButton color="primary" type="button" (click)="addControl()">
          Add Step
        </button>
      </sui-card-footer>
      <ng-content></ng-content>
    </sui-card>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SequenceSceneStepListComponent,
    },
  ],
})
export class SequenceSceneStepListComponent implements ControlValueAccessor {
  @Input() scenes: SequenceSceneInternalModel[] = [];
  expanded = -1;
  formArray = new UntypedFormArray([]);

  removeControl(index: number) {
    this.formArray.removeAt(index);
    this.expanded = -1;
  }

  createControl() {
    this.formArray.push(
      new UntypedFormControl({
        waitHours: 0,
        waitMinutes: 0,
        waitSeconds: 0,
        sceneId: null,
      }),
    );
  }

  addControl() {
    this.createControl();
    this.expanded = this.formArray.controls.length - 1;
  }

  writeValue(value: any[]): void {
    while (value.length > this.formArray.length) {
      this.createControl();
    }

    while (value.length < this.formArray.length) {
      this.removeControl(this.formArray.length - 1);
    }

    this.formArray.setValue(value, { onlySelf: true, emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.formArray.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.formArray.statusChanges.subscribe(() => fn());
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formArray.disable() : this.formArray.enable();
  }
}
