import { Component, Input } from '@angular/core';
import { ErrorCardComponent } from '../error-card/error-card.component';

@Component({
    selector: 'spog-ui-map-error-card',
    template: `<div class="portal-outlet">
    <sui-error-card [item]="itemName"></sui-error-card>
  </div> `,
    styles: [
        `
      @media screen and (max-width: 768px) {
        .portal-outlet {
          top: 20%;
          position: relative;
        }
      }

      @media screen and (min-width: 768px) {
        .portal-outlet {
          top: 30%;
          left: 30%;
          width: 40%;
          position: relative;
        }
      }
    `,
    ],
    standalone: true,
    imports: [ErrorCardComponent],
})
export class MapErrorCardComponent {
  @Input() itemName: string = 'devices';
}
