import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as THREE from 'three';
import { CanvasFactory } from './canvas-factory';
import { Color, ColorChannels, LightColors } from './color-picker.models';

const SWATCH_DIMENSIONS = [14, 14] as const; // px
const CAMERA_POSITION = [0, 0, 10] as const;
const WALL_POSITION = [0, 0, 0] as const;
const WALL_DIMENSIONS = [20, 20] as const;
/**
 * Rotate 45deg around the x-axis, so the top is further away from the camera
 * and the bottom is nearer to the camera. This means most of the light won't
 * reflect directly into the camera and wash the color out, and we get a nice
 * gradient effect from top (bright) to bottom (dim) in a way that matches
 * the octohedron.
 */
const WALL_ROTATION = [Math.PI / 4, 0, 0] as const;
const LIGHT_POSITION = [
  WALL_POSITION[0] - 2,
  WALL_POSITION[1] + 0.9,
  WALL_POSITION[2] + 13,
] as const;

@Injectable()
export class SwatchRendererService {
  private canvas: HTMLCanvasElement;
  private scene: THREE.Scene;
  private renderer: THREE.WebGLRenderer;
  _lights: THREE.PointLight[];
  swatches$?: Observable<(string | null)[]>;

  constructor(private canvasFactory: CanvasFactory) {
    this.canvas = this.canvasFactory.createCanvas(400, 400); // random pick. maybe should be an injection token?

    const scene = new THREE.Scene();

    const fov = 45; // field of view (degrees).
    const aspect = 1; // aspect ratio. the canvas default
    const near = 0.1; // distance from the camera to the near clipping plane
    const far = 100; // distance from the camera to the far clipping plane
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.set(...CAMERA_POSITION);
    scene.userData.camera = camera;

    const renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true });
    renderer.setSize(...SWATCH_DIMENSIONS);

    const wallGeometry = new THREE.PlaneGeometry(...WALL_DIMENSIONS);
    const wallMaterial = new THREE.MeshPhongMaterial({ color: '#fff' });
    const wall = new THREE.Mesh(wallGeometry, wallMaterial);
    wall.position.set(...WALL_POSITION);
    wall.rotation.set(...WALL_ROTATION);
    scene.add(wall);

    const lightColors = [
      LightColors.Red,
      LightColors.Green,
      LightColors.Blue,
      LightColors.Amber,
      LightColors.White,
    ];
    const [redLight, greenLight, blueLight, amberLight, whiteLight] = lightColors.map(
      lightColor => {
        const light = new THREE.PointLight(lightColor);
        light.position.set(...LIGHT_POSITION);
        scene.add(light);
        return light;
      },
    );
    scene.userData.redLight = redLight;
    scene.userData.greenLight = greenLight;
    scene.userData.blueLight = blueLight;
    scene.userData.amberLight = amberLight;
    scene.userData.whiteLight = whiteLight;
    this._lights = [redLight, greenLight, blueLight, amberLight, whiteLight]; // for testing purposes
    this.scene = scene;
    this.renderer = renderer;
  }

  render(color: Color) {
    const minimum = 0.2;
    const scalingFactor = 0.6;

    this.scene.userData.redLight.intensity =
      minimum + (color[ColorChannels.RED] / 255) * scalingFactor;
    this.scene.userData.greenLight.intensity =
      minimum + (color[ColorChannels.GREEN] / 255) * scalingFactor;
    this.scene.userData.blueLight.intensity =
      minimum + (color[ColorChannels.BLUE] / 255) * scalingFactor;
    this.scene.userData.amberLight.intensity =
      minimum + (color[ColorChannels.AMBER] / 255) * scalingFactor;
    this.scene.userData.whiteLight.intensity =
      minimum + (color[ColorChannels.WHITE] / 255) * scalingFactor;

    this.renderer.render(this.scene, this.scene.userData.camera);
  }

  renderToDataUrl(color: Color) {
    this.render(color);
    return this.canvas?.toDataURL();
  }
}
