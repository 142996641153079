import { createAction } from '@ngrx/store';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const enterAction = createAction('[Admin / Organizations] Enter');

export const loadPageSuccessAction = createAction(
  '[Organizations / API] Load Page Success',
  (organizations: OrganizationInternalModel[], sites: SiteInternalModel[]) => ({
    organizations,
    sites,
  }),
);

export const loadPageFailureAction = createAction(
  '[Organizations / API] Load Page Failure',
  (error: string) => ({ error }),
);

export const deleteAction = createAction(
  '[Admin / Organizations] Delete',
  (organization: OrganizationInternalModel) => ({ organization }),
);

export const confirmDeleteAction = createAction(
  '[Admin / Organizations] Confirm Delete',
  (organization: OrganizationInternalModel) => ({ organization }),
);

export const cancelDeleteAction = createAction(
  '[Admin / Organizations] Cancel Delete',
  (organization: OrganizationInternalModel) => ({ organization }),
);

export const filterAction = createAction(
  '[Admin / Organizations] Filter',
  (term: string) => {
    return { term };
  },
);
export const paginateAction = createAction(
  '[Admin / Organizations] Paginate',
  (pageIndex: number, pageSize: number) => {
    return { pageIndex, pageSize };
  },
);

export const copyIDAction = createAction(
  '[Admin / Organizations] Copy Org ID',
  (organization: OrganizationInternalModel) => {
    return { organization };
  },
);

export const manageAction = createAction(
  '[Admin / Organizations] Manage',
  (organization: OrganizationInternalModel, redirectUrl: string) => {
    return { organization, redirectUrl };
  },
);
