import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LightViewModel } from '@spog-ui/shared/models/lights';
import { LightDetailsActions } from '@spog-ui/light-details/actions';

@Component({
  selector: 'spog-replace-light-form',
  template: `
    <div class="wrapper">
      <sui-dialog-title>Replace {{ light.name }}: </sui-dialog-title>
      <form [formGroup]="replaceLightForm">
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Replacement MAC Address (Last 6)"
            formControlName="macAddress"
            maxLength="6"
          />
        </mat-form-field>

        <div class="actions">
          <button
            id="cancelButton"
            class="cancelButton"
            type="button"
            mat-button
            color="warn"
            (click)="onCancel()"
          >
            Cancel
          </button>
          <button
            id="submitButton"
            suiButton
            raised
            [disabled]="replaceLightForm.invalid"
            color="accent"
            type="submit"
            (click)="onSubmit()"
          >
            Replace
          </button>
        </div>
      </form>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        padding: 8px;
      }

      .wrapper {
        width: 100%;
      }

      mat-form-field {
        width: 200px;
      }

      .cancelButton {
        margin-right: 8px;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
      }

      .warn {
        color: var(--color-warn-500);
      }
    `,
  ],
})
export class ReplaceLightFormComponent {
  light: LightViewModel;

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA)
    data: { light: LightViewModel },
  ) {
    this.light = data.light;

    this.replaceLightForm.patchValue({
      macAddress: '',
    });
  }

  replaceLightForm: UntypedFormGroup = new UntypedFormGroup({
    macAddress: new UntypedFormControl(null, Validators.required),
  });

  onSubmit() {
    if (this.replaceLightForm.valid) {
      this.store.dispatch(
        LightDetailsActions.replaceAction({
          light: this.light,
          macAddress: this.replaceLightForm.value.macAddress,
        }),
      );
    }
  }

  onCancel() {
    this.store.dispatch(LightDetailsActions.replaceCancelAction());
  }
}
