import {
  PowerActions,
  PowerApiActions,
  BrowserDateApiActions,
  DateRangeActions,
} from '@spog-ui/power/actions';
import { createReducer, on } from '@ngrx/store';
import { ALL_LIGHTS } from '@spog-ui/shared/models/power-source-comparisons';

export interface Shape {
  loadingZones: boolean;
  loadingData: boolean;
}

const initialState: Shape = {
  loadingZones: true,
  loadingData: false,
};

const DEFAULT_ZONE_PREFIX = 'Default Zone - ';

export function sortByName<T extends { name: string }>(a: T, b: T): number {
  // Prioritize All Lights
  if (a.name == ALL_LIGHTS) {
    return Number.NEGATIVE_INFINITY;
  } else if (b.name == ALL_LIGHTS) {
    return Number.POSITIVE_INFINITY;
  }

  // Then, any Default Zones
  if (a.name.startsWith(DEFAULT_ZONE_PREFIX)) {
    return Number.NEGATIVE_INFINITY;
  } else if (b.name.startsWith(DEFAULT_ZONE_PREFIX)) {
    return Number.POSITIVE_INFINITY;
  }

  // Lastly, everything else
  return a.name.localeCompare(b.name);
}

export const reducer = createReducer(
  initialState,
  on(PowerActions.enterAction, () => initialState),
  on(PowerApiActions.loadSuccessAction, state => {
    return {
      ...state,
      loadingZones: false,
    };
  }),
  on(
    DateRangeActions.selectCustomDateRangeAction,
    BrowserDateApiActions.calculateDateRangeAction,
    PowerActions.selectPowerSourceAction,
    state => {
      return {
        ...state,
        loadingData: true,
      };
    },
  ),
  on(PowerApiActions.getPowerDataSuccessAction, state => {
    return {
      ...state,
      loadingData: false,
    };
  }),
);

export const selectIsLoadingZones = (state: Shape) => state.loadingZones;
export const selectIsLoadingData = (state: Shape) => state.loadingData;
