import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { map, skipWhile } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { AuthActions } from '@spog-ui/current-user/auth-actions';

@Injectable()
export class AuthEffects {
  isAuthenticated$ = createEffect(() =>
    this.auth0.isAuthenticated$.pipe(
      // skipWhile: Returns an Observable that skips all items emitted by the source
      //            Observable as long as a specified condition holds true, but emits
      //            all further source items as soon as the condition becomes false.
      skipWhile(isAuthenticated => !isAuthenticated),
      map(isAuthenticated =>
        isAuthenticated
          ? AuthActions.loginSuccessAction()
          : AuthActions.logoutSuccessAction(),
      ),
    ),
  );

  loginError$ = createEffect(() =>
    this.auth0.error$.pipe(map(error => AuthActions.loginErrorAction(error))),
  );

  constructor(private actions$: Actions, private auth0: AuthService) {}
}
