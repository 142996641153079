import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonComponent, SuiEmptyStateModule } from '@spog-ui/shared/components';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { NoScenesForApplicationComponent } from './no-scenes-for-application.component';

@NgModule({
  imports: [SuiEmptyStateModule, ButtonComponent, PermissionsModule, RouterModule],
  declarations: [NoScenesForApplicationComponent],
  exports: [NoScenesForApplicationComponent],
})
export class NoScenesForApplicationComponentModule {}
