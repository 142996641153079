import {
  SiteControllerResourceStatus,
  SiteControllerResourceType,
} from '@spog-ui/graphql/types';
import {
  SiteControllerFormModel,
  SiteControllerGQLModel,
  SiteControllerInternalModel,
  SiteControllerWSModel,
  toSiteControllerFormModel,
  SiteControllerViewModel,
} from './site-controller.model';

const lastUpdateReceived = '2020-08-10T22:42:04.672Z';
export type ResourceStatus = {
  type: SiteControllerResourceType;
  status: SiteControllerResourceStatus;
  lastUpdateReceived: string;
};
const allResources = (status: SiteControllerResourceStatus) =>
  Object.values(SiteControllerResourceType).map(type => ({
    type,
    status,
    lastUpdateReceived,
  }));
const updateRemoteAccess = (
  status: SiteControllerResourceStatus,
  resources: ResourceStatus[],
) =>
  resources.map(r =>
    r.type === SiteControllerResourceType.REMOTE_ACCESS ? { ...r, status } : r,
  );

export const resourcesAllUp = allResources(SiteControllerResourceStatus.UP);
export const resourcesAllDown = allResources(SiteControllerResourceStatus.DOWN);
export const resourcesAllInProgress = allResources(
  SiteControllerResourceStatus.IN_PROGRESS,
);
export const resourcesPendingRemoteAccessUp = updateRemoteAccess(
  SiteControllerResourceStatus.UP,
  allResources(SiteControllerResourceStatus.PENDING_ACK),
);
export const resourcesUpRemoteAccessDown = updateRemoteAccess(
  SiteControllerResourceStatus.DOWN,
  resourcesAllUp,
);
export const resourcesFailedRemoteAccessUp = updateRemoteAccess(
  SiteControllerResourceStatus.UP,
  allResources(SiteControllerResourceStatus.INIT_FAILED),
);

export function createMockSiteController(
  updates: Partial<SiteControllerInternalModel> = {},
): SiteControllerInternalModel {
  return {
    id: 'mock-site-controller-id',
    name: 'DF9',
    macAddress: 'AABBCC',
    siteId: 'site-1',
    uiProxyUrl: 'gateway-AABBCC.spogforthe.win',
    secretKey: 'some-secret-key',
    systemVersion: 'mock system version',
    activeLicenses: ['Color', 'Standard'],
    resources: resourcesUpRemoteAccessDown,
    ...updates,
  };
}

export function createMockSiteControllerWSModel(): SiteControllerWSModel {
  return {
    id: 'mock-site-controller-id',
    name: 'DF9',
    macAddress: 'AABBCC',
    siteId: 'site-1',
    systemVersion: 'mock system version',
    remoteAccess: {
      ipAddress: 'ignored',
      uiProxyUrl: 'gateway-AABBCC.spogforthe.win',
      secretKey: 'some-secret-key',
      apiProxyUrl: 'ignored',
    },
    resources: resourcesUpRemoteAccessDown,
  };
}

export function createMockSiteControllers(
  numberOfSiteControllers: number,
): SiteControllerInternalModel[] {
  return Array.from(Array(numberOfSiteControllers), (x, i) =>
    createMockSiteController({
      id: `test-id-${i}`,
      name: `test site controller ${i}`,
    }),
  );
}

export function createMockGQLSiteController(
  updates?: Partial<SiteControllerGQLModel>,
): SiteControllerGQLModel {
  return {
    id: 'mock-site-controller-id',
    name: 'DF9',
    macAddress: 'AABBCC',
    systemVersion: 'mock system version',
    activeLicenses: ['Color', 'Standard'],
    resources: resourcesUpRemoteAccessDown,
    remoteAccess: {
      uiProxyUrl: 'gateway-AABBCC.spogforthe.win',
      secretKey: 'some-secret-key',
    },
    site: {
      id: 'site-1',
    },
    ...updates,
  };
}

export function createMockGraphQLSiteControllers(): SiteControllerGQLModel[] {
  return [createMockGQLSiteController()];
}

export function createMockSiteControllerFormModel(
  updates?: Partial<SiteControllerFormModel>,
): SiteControllerFormModel {
  return {
    id: 'mock-site-controller-id',
    name: 'DF9',
    macAddress: 'AABBCC',
    siteId: 'site-1',
    importOnPremScenes: false,
    importOnPremScheduledEvents: false,
    ...updates,
  };
}

export function createMockSiteControllerFormModels(count = 3): SiteControllerFormModel[] {
  return createMockSiteControllers(count).map(toSiteControllerFormModel);
}

export function createMockSiteControllerView(
  updates: Partial<SiteControllerViewModel> = {},
): SiteControllerViewModel {
  return {
    id: 'mock-site-controller-id',
    name: 'DF9',
    macAddress: 'AABBCC',
    uiProxyUrl: 'gateway-AABBCC.spogforthe.win',
    secretKey: 'some-secret-key',
    systemVersion: 'mock system version',
    activeLicenses: ['Color', 'Standard'],
    resources: resourcesUpRemoteAccessDown,
    showStatus: true,
    status: 'DEGRADED',
    statusIcon: '',
    statusClass: '',
    siteId: 'site-1',
    ...updates,
  };
}
