type ErrorResponse =
  | ErrorWithMessage[]
  | { errors: ErrorWithMessage[] }
  | ErrorWithMessage
  | string;

type ErrorWithMessage = { message: string };

export function getError(errorResponse: ErrorResponse | unknown): string[] {
  console.log(errorResponse);
  if (Array.isArray(errorResponse)) {
    return errorResponse.map(e => e.message);
  } else if (isErrorWithMessage(errorResponse)) {
    return [errorResponse.message];
  } else if (isResponseWithErrors(errorResponse)) {
    return errorResponse.errors.map(e => e.message);
  } else if (typeof errorResponse === 'string') {
    return [errorResponse];
  } else {
    return ['Error: Unable to connect to server.'];
  }
}

function isErrorWithMessage(
  errorResponse: ErrorResponse | unknown,
): errorResponse is ErrorWithMessage {
  return (errorResponse as ErrorWithMessage).message !== undefined;
}

function isResponseWithErrors(
  errorResponse: ErrorResponse | unknown,
): errorResponse is { errors: ErrorWithMessage[] } {
  return (errorResponse as { errors: ErrorWithMessage[] }).errors !== undefined;
}
