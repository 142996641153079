import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-switch-with-blink',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        <strong>Time Sweep ON/OFF with Override</strong> - The switch sets the lights in
        the zone to a user-defined dimming level for a user-defined period. After the
        user-defined period, the zone is temporarily dimmed indicating the start of a
        user-defined override timeout. The level and duration of the temporary dim are
        user-defined. If the switch is pressed during the override timeout, the zone
        returns to the user-defined dimming level for the user-defined period, else the
        zone turns off after the override timeout.
      </p>

      <div class="bfForm">
        <div class="bfChunk">
          <p class="bfLabel">When switched on, set dimming level to:</p>
          <sui-dimmer-control formControlName="switchOnLevel"></sui-dimmer-control>

          <p class="bfLabel">When switched off, set dimming level to:</p>
          <sui-dimmer-control formControlName="switchOffLevel"></sui-dimmer-control>
        </div>
        <div class="bfChunk">
          <p class="bfLabel">After:</p>
          <sui-timeout-control formControlName="initialTimeout"></sui-timeout-control>

          <p class="bfLabel">temporarily dim lights for a duration of:</p>
          <sui-short-duration-control
            formControlName="blinkDurationSeconds"
          ></sui-short-duration-control>
          <p class="bfLabel">with a dimming level of:</p>
          <sui-dimmer-control formControlName="blinkLevel"></sui-dimmer-control>
        </div>
        <div class="bfChunk">
          <p class="bfLabel">Then start override timeout of:</p>
          <sui-timeout-control formControlName="offTimeout"></sui-timeout-control>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      sui-dimmer-control:last-of-type {
        display: block;
        margin-bottom: -13px;
      }
    `,
  ],
})
export class BFSwitchWithBlinkComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    switchOnLevel: new UntypedFormControl(100),
    switchOffLevel: new UntypedFormControl(0),
    initialTimeout: new UntypedFormControl(10),
    blinkDurationSeconds: new UntypedFormControl(3),
    blinkLevel: new UntypedFormControl(20),
    offTimeout: new UntypedFormControl(1),
  });
}
