import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of, defer, forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { AddEmailGroupPageActions } from '../actions';
import { selectActiveOrgId } from '@spog-ui/shared/state/organizations';
import { SitesApiService } from '../services/';
import { OrganizationUsersApiService } from '../services/';
import { OrgAdminSitesStateActions } from '../actions/';
import { OrgAdminUsersStateActions } from '../actions/';
import { Store } from '@ngrx/store';

@Injectable()
export class AddEmailGroupApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly sitesApiService: SitesApiService,
    readonly organizationUsersApiService: OrganizationUsersApiService,
    readonly store: Store,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddEmailGroupPageActions.enterAction),
      withLatestFrom(defer(() => this.store.select(selectActiveOrgId))),
      switchMap(([, activeOrgId]) =>
        forkJoin([
          this.sitesApiService.getSitesByOrganization(activeOrgId!),
          this.organizationUsersApiService.getUsersByOrganization(activeOrgId!),
        ]).pipe(
          map(([sites, users]) =>
            AddEmailGroupPageActions.loadPageSuccessAction(users, sites),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              AddEmailGroupPageActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddEmailGroupPageActions.loadPageSuccessAction),
      map(action => {
        return OrgAdminSitesStateActions.loadSitesSuccessAction(action.sites);
      }),
    ),
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddEmailGroupPageActions.loadPageSuccessAction),
      map(action => {
        return OrgAdminUsersStateActions.loadUsersSuccessAction(action.users);
      }),
    ),
  );
}
