import { createAction } from '@ngrx/store';
import {
  MetricHistoryInternalModel,
  TriggerInternalModel,
} from '@spog-ui/shared/models/triggers';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';

export const loadTriggersPageModelsSuccessAction = createAction(
  '[Triggers API] Load Triggers Page Models Success',
  (models: { triggers: TriggerInternalModel[] }) => ({ models }),
);
export const loadTriggersPageModelsFailureAction = createAction(
  '[Triggers API] Load All Triggers Page Models Failure',
  (error: any) => ({ error }),
);

export const loadAddTriggersPageSuccessAction = createAction(
  '[Triggers API] Load Add Triggers Page Success',
  (models: {
    sources: { indieSensors: IndieSensorInternalModel[] };
    histories: MetricHistoryInternalModel[];
  }) => ({ models }),
);

export const loadAddTriggersPageFailureAction = createAction(
  '[Triggers API] Load Add Triggers Page Failure',
  (error: any) => ({ error }),
);

export const loadEditTriggersPageSuccessAction = createAction(
  '[Triggers API] Load Edit Triggers Page Success',
  (models: {
    triggers: TriggerInternalModel[];
    sources: { indieSensors: IndieSensorInternalModel[] };
    histories: MetricHistoryInternalModel[];
  }) => ({ models }),
);

export const loadEditTriggersPageFailureAction = createAction(
  '[Triggers API] Load Edit Triggers Page Failure',
  (error: any) => ({ error }),
);

export const loadAllMetricSourcesSuccessAction = createAction(
  '[Triggers API] Load All Metric Sources Success',
  (sources: { indieSensors: IndieSensorInternalModel[] }) => ({ sources }),
);
export const loadAllMetricSourcesFailureAction = createAction(
  '[Triggers API] Load All Metric Sources Failure',
  (error: any) => ({ error }),
);

export const deleteTriggerSuccessAction = createAction(
  '[Triggers API] Delete Trigger Success',
);
export const deleteTriggerFailureAction = createAction(
  '[Triggers API] Delete Trigger Failure',
  (error: any) => ({ error }),
);

export const duplicateTriggerSuccessAction = createAction(
  '[Triggers API] Duplicate Trigger Success',
);

export const duplicateTriggerFailureAction = createAction(
  '[Triggers API] Duplicate Trigger Failure',
  (error: any) => ({ error }),
);

export const createTriggerSuccessAction = createAction(
  '[Triggers API] Create Trigger Success',
);

export const createTriggerFailureAction = createAction(
  '[Triggers API] Create Trigger Failure',
  (error: any) => ({ error }),
);

export const editTriggerSuccessAction = createAction(
  '[Triggers API] Edit Trigger Success',
);

export const editTriggerFailureAction = createAction(
  '[Triggers API] Edit Trigger Failure',
  (error: any) => ({ error }),
);
