import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-none',
  template: `
    <p class="bfDescription">
      The zone has no behaviors applied. All control is via the zone panel only.
    </p>
  `,
})
export class BFNoneComponent implements BehaviorForm {
  form = new UntypedFormControl(null);
}
