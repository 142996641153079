import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as PowerState from '@spog-ui/shared/state/power';
import * as EnergyInfoSystemState from './energy-info-system.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    PowerState.PowerStateModule,
    StoreModule.forFeature(
      EnergyInfoSystemState.STATE_KEY,
      EnergyInfoSystemState.reducers,
    ),
  ],
})
export class EnergyInfoSystemStateModule {}
