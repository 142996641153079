import {
  catchError,
  defer,
  exhaustMap,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PromptOptions, PromptService } from '@sui/prompt';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import { ClipboardService, SiteService } from '@spog-ui/shared/services';
import { toBridge485InternalModelFromGQL } from '@spog-ui/shared/models/bridge-485s';
import { toIndieSensorInternalModelFromGQL } from '@spog-ui/shared/models/indie-sensors';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { Bridge485sStateActions } from '@spog-ui/shared/state/bridge-485s';
import { IndieSensorsStateActions, selectRouterUrl } from '@spog-ui/shared/state/core';
import { Bridge485Api, Bridge485DetailsPaneActions } from '../actions';
import { Bridge485DetailsViewModel } from '../models';
import { Bridge485DetailsState } from '../..';

export const PROMPT_OPTIONS: Partial<PromptOptions> = {
  description:
    'All associated sensors will also be deleted, as well as any triggers associated with those sensors.',
  confirmButtonTestId: 'delete-button',
  confirmLabel: 'Delete',
  confirmColor: 'warn',
  cancelLabel: 'Cancel',
  cancelColor: 'default',
};

@Injectable()
export class Bridge485DetailsEffects {
  constructor(
    readonly actions$: Actions,
    readonly gql: GraphQLAPIService,
    readonly site: SiteService,
    readonly prompt: PromptService,
    readonly store: Store,
    readonly router: Router,
    readonly clipboard: ClipboardService,
    readonly snackbar: MatSnackBar,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485DetailsPaneActions.enterAction),
      switchMap(({ bridge485Id }) => {
        const siteId = this.site.getId();

        if (!siteId) throw new Error('No site for session');

        return this.gql.getAllBridge485DetailsModels({ bridge485Id, siteId }).pipe(
          map(({ bridge485, site }) => {
            if (!bridge485)
              throw new Error(`Could not find Bridge485 with id ${bridge485Id}.`);
            if (!site) throw new Error(`Could not find site with id ${siteId}`);

            const models = {
              bridge485: toBridge485InternalModelFromGQL(bridge485),
              indieSensors: site.industrialSensors.map(toIndieSensorInternalModelFromGQL),
            };

            return Bridge485Api.loadSuccess(models);
          }),
          catchError(error => of(Bridge485Api.loadFailure(retrieveErrorMessage(error)))),
        );
      }),
    ),
  );

  ping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485DetailsPaneActions.pingAction),
      exhaustMap(action =>
        this.gql
          .pingBridge485({ pingBridge485Input: { snapaddr: action.snapaddr } })
          .pipe(
            map(worked =>
              worked
                ? Bridge485Api.pingSuccess()
                : Bridge485Api.pingFailure(
                    `Device ${action.snapaddr} did not respond and may be offline.`,
                  ),
            ),
            catchError(error =>
              of(Bridge485Api.pingFailure(retrieveErrorMessage(error))),
            ),
          ),
      ),
    ),
  );

  confirmDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485DetailsPaneActions.deleteAction),
      withLatestFrom(
        defer(() =>
          this.store
            .select(Bridge485DetailsState.selectActiveBridge485)
            .pipe(filter((b485): b485 is Bridge485DetailsViewModel => b485 !== null)),
        ),
      ),
      exhaustMap(([, bridge485]) =>
        this.prompt
          .open({
            ...PROMPT_OPTIONS,
            title: `Delete ${bridge485.name}?`,
          })
          .pipe(
            map(confirmed =>
              confirmed
                ? Bridge485DetailsPaneActions.confirmDeleteAction(bridge485.id)
                : Bridge485DetailsPaneActions.cancelDeleteAction(),
            ),
          ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485DetailsPaneActions.confirmDeleteAction),
      mergeMap(action =>
        this.gql.deleteBridge485({ input: { id: action.bridge485Id } }).pipe(
          map(() => Bridge485Api.deleteSuccess(action.bridge485Id)),
          catchError(error => of(Bridge485Api.deleteFailure(error))),
        ),
      ),
    ),
  );

  copyId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Bridge485DetailsPaneActions.copyIdAction),
        tap(action => {
          this.clipboard.copy(action.bridge485Id);
          this.snackbar.open('Copied ID to the clipboard', 'Dismiss', {
            duration: 6_000,
          });
        }),
      ),
    { dispatch: false },
  );

  redirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Bridge485Api.deleteSuccess),
        withLatestFrom(defer(() => this.store.select(selectRouterUrl))),
        tap(([, currentUrl]) => {
          const partToRemove = '/bridge-485/details';
          const index = currentUrl?.indexOf(partToRemove);
          if (currentUrl && index !== -1) {
            const url = currentUrl?.substring(0, index);
            this.router.navigateByUrl(url);
          }
        }),
      ),
    { dispatch: false },
  );

  pushBridge485ToState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485Api.loadSuccess),
      map(action =>
        Bridge485sStateActions.loadBridge485sAction([action.models.bridge485]),
      ),
    ),
  );

  pushIndieSensorsToState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485Api.loadSuccess),
      map(action => IndieSensorsStateActions.loadAction(action.models.indieSensors)),
    ),
  );
}
