import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export function markAsTouched(form: UntypedFormGroup) {
  Object.keys(form.controls)
    .map(key => form.get(key))
    .forEach(control => {
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
        control.updateValueAndValidity();
      } else if (control instanceof UntypedFormGroup) {
        markAsTouched(control);
      }
    });
}
