import {
  Directive,
  EmbeddedViewRef,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { SiteService } from '@spog-ui/shared/services';
import { Products } from '@spog-ui/graphql/types';

@Injectable({ providedIn: 'root' })
export class IfSupportsProductsComparer {
  enabledProducts$: Observable<Products[]>;

  constructor(@Optional() readonly site: SiteService) {
    this.enabledProducts$ = site && site.observeEnabledProducts();
  }

  compare(supportsAny: boolean, requiredProducts: Products[]): Observable<boolean> {
    /**
     * If we are in a unit test and the Site service wasn't mocked out
     * just avoid doing any product comparisons
     */
    if (!this.site) {
      return of(true);
    }

    return this.enabledProducts$.pipe(
      map(enabledProducts =>
        this.checkIfSupports(supportsAny, enabledProducts, requiredProducts),
      ),
      distinctUntilChanged(),
    );
  }

  private checkIfSupports(
    supportsAny: boolean,
    enabledProducts: Products[],
    requiredProducts: Products[],
  ) {
    if (supportsAny) {
      return requiredProducts.some(product => enabledProducts.includes(product));
    }

    return requiredProducts.every(product => enabledProducts.includes(product));
  }
}

@Directive({
    selector: '[spogIfSupportsProducts]',
    standalone: true
})
export class IfSupportsProductsDirective implements OnInit, OnDestroy {
  @Input('spogIfSupportsProductsAny') supportsAny = false;
  @Input('spogIfSupportsProducts') requiredProducts: Products[] = [];
  subscription = new Subscription();
  viewRef: EmbeddedViewRef<any> | null = null;

  constructor(
    readonly comparer: IfSupportsProductsComparer,
    readonly viewContainer: ViewContainerRef,
    readonly templateRef: TemplateRef<any>,
  ) {}

  ngOnInit() {
    const updateViewSubscription = this.comparer
      .compare(this.supportsAny, this.requiredProducts)
      .subscribe(siteSupportsProducts => {
        if (siteSupportsProducts && !this.viewRef) {
          this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (!siteSupportsProducts && this.viewRef) {
          this.viewContainer.clear();
          this.viewRef = null;
        }
      });

    this.subscription.add(updateViewSubscription);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
