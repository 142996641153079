import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { selectOrgAdminRedirectUrl } from '../state';

@Injectable({ providedIn: 'root' })
export class OrgAdminRedirectService {
  constructor(private store: Store) {}

  getAdminOrgPanelRedirectUrl(): Observable<string> {
    return this.store.select(selectOrgAdminRedirectUrl).pipe(take(1));
  }
}
