import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UtilityServicesApiActions } from '@spog-ui/utility-services/actions';
import {
  ScheduledUtilityRateInternalModel,
  toScheduledUtilityRateInternalModelFromGQL,
} from '@spog-ui/shared/models/scheduled-utility-rates';

export type Shape = EntityState<ScheduledUtilityRateInternalModel>;

export const adapter = createEntityAdapter<ScheduledUtilityRateInternalModel>();

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    UtilityServicesApiActions.loadSuccessAction,
    UtilityServicesApiActions.loadUtilityServicesAndThingsSuccessAction,
    (state, action): Shape => {
      const scheduledUtilityRates = action.utilityServices.flatMap(utilityService => {
        return toScheduledUtilityRateInternalModelFromGQL(utilityService);
      });

      return adapter.setAll(scheduledUtilityRates, state);
    },
  ),
);

export const { selectAll, selectEntities } = adapter.getSelectors();
