import { BehaviorSubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { AvailablePermissionsService } from '../services/available-permissions.service';

@Directive({
  selector: '[suiIfUserCan]',
})
export class IfUserCanDirective implements OnInit, OnDestroy {
  requiredPermissions$ = new BehaviorSubject<string[]>([]);
  subscriptions = new Subscription();
  viewRef: EmbeddedViewRef<IfUserCanContext> | null = null;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<IfUserCanContext>,
    private availablePermissions: AvailablePermissionsService,
  ) {}

  @Input()
  set suiIfUserCan(permissions: string[]) {
    this.requiredPermissions$.next(permissions);
  }

  ngOnInit() {
    const updateViewSubscription = this.requiredPermissions$
      .pipe(switchMap(permissions => this.availablePermissions.includes(permissions)))
      .subscribe(hasPermission => {
        if (hasPermission && !this.viewRef) {
          this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef, {});
        } else if (!hasPermission && this.viewRef) {
          this.viewContainer.clear();
          this.viewRef = null;
        }
      });

    this.subscriptions.add(updateViewSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

export class IfUserCanContext {}
