import { createReducer, on } from '@ngrx/store';
import { Bridge485Api, Bridge485DetailsPaneActions } from '../actions';

export interface Shape {
  bridge485Id: string | null;
  isLoading: boolean;
  error: string | null;
}

export const STATE_KEY = 'bridge-485-details-pane';

export const initialState: Shape = {
  bridge485Id: null,
  isLoading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(Bridge485DetailsPaneActions.enterAction, (state, action) => {
    return {
      ...state,
      bridge485Id: action.bridge485Id,
    };
  }),
  on(Bridge485Api.loadSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: null,
    };
  }),
  on(Bridge485Api.loadFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const selectActiveBridge485Id = (state: Shape) => state.bridge485Id;
export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
