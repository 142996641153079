import { createAction } from '@ngrx/store';
import { UserFormModel } from '@spog-ui/shared/models/users';

export const enterAction = createAction('[Edit User Page] Enter', (userId: string) => ({
  userId,
}));
export const saveAction = createAction(
  '[Edit User Page] Save Changes',
  (user: UserFormModel) => ({
    user,
  }),
);
export const cancelAction = createAction('[Edit User Page] Cancel');
