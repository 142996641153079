import { createAction } from '@ngrx/store';
import { PermissionGroupInternalModel } from '@spog-ui/shared/models/permission-groups';

export const loadPermissionGroupsSuccessAction = createAction(
  '[Permission Groups State] Load Permission Groups Success',
  (permissionGroups: PermissionGroupInternalModel[]) => ({ permissionGroups }),
);

export const deletePermissionGroupAction = createAction(
  '[Permission Groups State] Delete Permission Group',
  (permissionGroup: PermissionGroupInternalModel) => ({ permissionGroup }),
);
