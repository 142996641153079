import { createReducer, createSelector, on } from '@ngrx/store';
import {
  addColorToPalette,
  Color,
  ColorChannels,
  ColorPalette,
  ColorPickerMode,
  EMPTY_COLOR_PALETTE,
  removeColorFromPalette,
  reorderColorInPalette,
  replaceColorInPalette,
  toBytesFromPercent,
  toColorFromHexString,
  toHexStringFromColor,
  toPercentFromBytes,
} from './color-picker.models';
import * as ColorPickerActions from './color-picker.actions';
import * as ColorPickerApiActions from './color-picker-api.actions';

export interface Shape {
  mode: ColorPickerMode;
  color: Color;
  isDisabled: boolean;
  hexInputValue: string;
  appSupportsColorPalette: boolean;
  isLoadingColorPalette: boolean;
  colorPalette: ColorPalette;
}

export const initialState: Shape = {
  mode: ColorPickerMode.BYTES,
  /**
   * Regardless of mode, we always store colors in bytes in
   * this reducer and then cast to either bytes or percent
   * in a selector
   */
  color: [0, 0, 0, 0, 0],
  isDisabled: false,
  /**
   * The hex input value is stored independently of the
   * color because there are times when the value of the hex
   * input if malformed and cannot be converted into a color.
   *
   * The logic of the reducer synchronizes the two when the
   * user interacts with the sliders or enters a valid hex string
   */
  hexInputValue: '#0000000000',

  /**
   * Some apps (like OnPrem) don't support color palettes
   */
  appSupportsColorPalette: false,
  isLoadingColorPalette: true,
  colorPalette: EMPTY_COLOR_PALETTE,
};

export const reducer = createReducer(
  initialState,
  on(ColorPickerActions.setModeFromParentComponentInput, (state, action): Shape => {
    return {
      ...state,
      mode: action.mode,
    };
  }),
  on(ColorPickerActions.updateColorChannelFromSliders, (state, action): Shape => {
    /**
     * There are nicer ways to clone arrays, but not in ways that make
     * TypeScript say "hey, I know what you're doing with that tuple"
     */
    const [r, g, b, a, w] = state.color;
    const updatedColor: Color = [r, g, b, a, w];

    updatedColor[action.channel] =
      state.mode === ColorPickerMode.BYTES
        ? action.value
        : toBytesFromPercent(action.value);

    const hexInputValue = toHexStringFromColor(updatedColor);

    return {
      ...state,
      hexInputValue,
      color: updatedColor,
    };
  }),
  on(
    ColorPickerActions.setDisabledStateFromFormControl,
    ColorPickerActions.setDisabledStateFromParentComponentInput,
    (state, action): Shape => {
      return {
        ...state,
        isDisabled: action.isDisabled,
      };
    },
  ),
  on(
    ColorPickerActions.writeValueFromFormControl,
    ColorPickerActions.writeValueFromParentComponentInput,
    (state, action): Shape => {
      if (state.mode === ColorPickerMode.BYTES) {
        return {
          ...state,
          color: action.color,
          hexInputValue: toHexStringFromColor(action.color),
        };
      } else {
        const color: Color = [
          toBytesFromPercent(action.color[ColorChannels.RED]),
          toBytesFromPercent(action.color[ColorChannels.GREEN]),
          toBytesFromPercent(action.color[ColorChannels.BLUE]),
          toBytesFromPercent(action.color[ColorChannels.AMBER]),
          toBytesFromPercent(action.color[ColorChannels.WHITE]),
        ];
        return {
          ...state,
          color,
          hexInputValue: toHexStringFromColor(color),
        };
      }
    },
  ),
  on(ColorPickerActions.userInputsHexValue, (state, action): Shape => {
    const hexInputValue = action.value;
    const color = toColorFromHexString(hexInputValue);

    if (color) {
      return {
        ...state,
        hexInputValue,
        color,
      };
    }

    return {
      ...state,
      hexInputValue,
    };
  }),
  on(ColorPickerActions.userAppliesColorSwatch, (state, action): Shape => {
    const color = action.color;
    const hexInputValue = toHexStringFromColor(color);

    return {
      ...state,
      hexInputValue,
      color,
    };
  }),
  on(ColorPickerActions.userCreatesColorSwatch, (state, action): Shape => {
    const updatedPalette = addColorToPalette(state.colorPalette, state.color);

    return {
      ...state,
      colorPalette: updatedPalette,
    };
  }),

  on(ColorPickerActions.userReordersColorInColorSwatch, (state, action): Shape => {
    const updatedPalette = reorderColorInPalette(
      state.colorPalette,
      action.oldIndex,
      action.newIndex,
    );

    return {
      ...state,
      colorPalette: updatedPalette,
    };
  }),

  on(ColorPickerActions.userReplacesColorInColorSwatch, (state, action): Shape => {
    const updatedPalette = replaceColorInPalette(
      state.colorPalette,
      state.color,
      action.index,
    );

    return {
      ...state,
      colorPalette: updatedPalette,
    };
  }),
  on(ColorPickerActions.userDeletesColorInColorSwatch, (state, action): Shape => {
    const updatedPalette = removeColorFromPalette(state.colorPalette, action.index);

    return {
      ...state,
      colorPalette: updatedPalette,
    };
  }),
  on(ColorPickerApiActions.loadColorPaletteSuccess, (state, action): Shape => {
    return {
      ...state,
      colorPalette: action.palette,
      isLoadingColorPalette: false,
    };
  }),
  on(ColorPickerApiActions.updateColorPaletteFromWs, (state, action): Shape => {
    return {
      ...state,
      colorPalette: action.palette,
    };
  }),
);

export const selectMode = (state: Shape) => state.mode;
export const selectColor = (state: Shape) => state.color;
export const selectIsDisabled = (state: Shape) => state.isDisabled;
export const selectHexInputValue = (state: Shape) => state.hexInputValue;
export const selectAppSupportsColorPalette = (state: Shape) =>
  state.appSupportsColorPalette;
export const selectIsLoadingColorPalette = (state: Shape) => state.isLoadingColorPalette;
export const selectColorPalette = (state: Shape) => state.colorPalette;
export const selectColorIndex = createSelector(
  selectColor,
  selectColorPalette,
  (color, palette) =>
    palette.findIndex(swatch => JSON.stringify(swatch) === JSON.stringify(color)),
);
export const selectSliderMaxValue = createSelector(selectMode, mode => {
  return mode === ColorPickerMode.BYTES ? 255 : 100;
});
export const selectValue = createSelector(
  selectColor,
  selectMode,
  (color, mode): Color => {
    if (mode === ColorPickerMode.BYTES) {
      return color;
    } else {
      return [
        toPercentFromBytes(color[ColorChannels.RED]),
        toPercentFromBytes(color[ColorChannels.GREEN]),
        toPercentFromBytes(color[ColorChannels.BLUE]),
        toPercentFromBytes(color[ColorChannels.AMBER]),
        toPercentFromBytes(color[ColorChannels.WHITE]),
      ];
    }
  },
);
export const selectRedSliderValue = createSelector(selectValue, (value): number => {
  return value[ColorChannels.RED];
});
export const selectGreenSliderValue = createSelector(selectValue, (value): number => {
  return value[ColorChannels.GREEN];
});
export const selectBlueSliderValue = createSelector(selectValue, (value): number => {
  return value[ColorChannels.BLUE];
});
export const selectAmberSliderValue = createSelector(selectValue, (value): number => {
  return value[ColorChannels.AMBER];
});
export const selectWhiteSliderValue = createSelector(selectValue, (value): number => {
  return value[ColorChannels.WHITE];
});
export const selectHexString = createSelector(selectColor, (color: Color) => {
  return toHexStringFromColor(color);
});
export const selectHexInputIsValid = createSelector(
  selectHexInputValue,
  (hexInputValue): boolean => {
    return toColorFromHexString(hexInputValue) !== null;
  },
);
