import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { SceneFormComponentModule } from '../scene-form';
import { EditScenePageComponent } from './edit-scene-page.component';

@NgModule({
  imports: [CommonModule, SuiSpinnerModule, SceneFormComponentModule],
  declarations: [EditScenePageComponent],
  exports: [EditScenePageComponent],
})
export class EditScenePageComponentModule {}
