import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IndieSensorComponentModule } from '../indie-sensor/indie-sensor.component.module';
import { IndieSensorsPageListComponent } from './indie-sensors-page-list.component';

@NgModule({
  imports: [CommonModule, IndieSensorComponentModule, RouterModule],
  declarations: [IndieSensorsPageListComponent],
  exports: [IndieSensorsPageListComponent],
})
export class IndieSensorsPageListComponentModule {}
