import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of, forkJoin, defer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { EditPermissionGroupPageActions } from '../actions';
import { selectActiveOrgId } from '@spog-ui/shared/state/organizations';
import {
  SitesApiService,
  OrganizationUsersApiService,
  PermissionGroupsApiService,
} from '../services/';
import { OrgAdminSitesStateActions, OrgAdminUsersStateActions } from '../actions/';
import { PermissionGroupStateActions } from '@spog-ui/shared/state/permission-groups';
import { Store } from '@ngrx/store';

@Injectable()
export class EditPermissionGroupApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly sitesApiService: SitesApiService,
    readonly organizationUsersApiService: OrganizationUsersApiService,
    readonly permissionGroupsApiService: PermissionGroupsApiService,
    readonly store: Store,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPermissionGroupPageActions.enterAction),
      withLatestFrom(defer(() => this.store.select(selectActiveOrgId))),
      switchMap(([, activeOrgId]) =>
        forkJoin([
          this.sitesApiService.getSitesByOrganization(activeOrgId!),
          this.organizationUsersApiService.getUsersByOrganization(activeOrgId!),
          this.permissionGroupsApiService.getPermissionGroupsByOrganization(activeOrgId!),
        ]).pipe(
          map(([sites, users, permissionGroups]) =>
            EditPermissionGroupPageActions.loadPageSuccessAction(
              users,
              sites,
              permissionGroups,
            ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              EditPermissionGroupPageActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPermissionGroupPageActions.loadPageSuccessAction),
      map(action => {
        return OrgAdminSitesStateActions.loadSitesSuccessAction(action.sites);
      }),
    ),
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPermissionGroupPageActions.loadPageSuccessAction),
      map(action => {
        return OrgAdminUsersStateActions.loadUsersSuccessAction(action.users);
      }),
    ),
  );

  loadPermissionGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditPermissionGroupPageActions.loadPageSuccessAction),
      map(action => {
        return PermissionGroupStateActions.loadPermissionGroupsSuccessAction(
          action.permissionGroups,
        );
      }),
    ),
  );
}
