import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { EditOrganizationPageActions } from '../actions';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import { toSiteInternalModelFromGQL } from '@spog-ui/shared/models/sites';
import { OrganizationApiService } from '../services';
import { OrganizationStateActions } from '../actions';
import { RemoteAccessStateActions } from '@spog-ui/shared/state/remote-access';

@Injectable()
export class EditOrganizationApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly organizationApiService: OrganizationApiService,
    readonly gql: GraphQLAPIService,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditOrganizationPageActions.enterAction),
      switchMap(() =>
        forkJoin([
          this.gql.getAllSitesAsAdmin(),
          this.organizationApiService.getAll(),
        ]).pipe(
          map(([allSitesResponse, organizations]) =>
            EditOrganizationPageActions.loadPageSuccessAction(
              allSitesResponse.allSites.edges.map(edge =>
                toSiteInternalModelFromGQL(edge.site),
              ),
              organizations,
            ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              EditOrganizationPageActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  loadOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditOrganizationPageActions.loadPageSuccessAction),
      map(action => {
        return OrganizationStateActions.loadOrganizationsForAdminSectionSuccessAction(
          action.organizations,
        );
      }),
    ),
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditOrganizationPageActions.loadPageSuccessAction),
      map(action => {
        return RemoteAccessStateActions.loadAdminSitesSuccessAction(action.sites);
      }),
    ),
  );
}
