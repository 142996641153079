import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppUpdateCheckComponent } from './app-update-check.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AppUpdateCheckComponent],
  exports: [AppUpdateCheckComponent],
})
export class AppUpdateCheckComponentModule {}
