import { Application, SceneModelFragment } from '@spog-ui/graphql/types';
import { BehaviorParameters, BehaviorType } from '@spog-ui/shared/models/behaviors';
import { ZoneBehaviorFormModel } from '@spog-ui/shared/models/zones';

export type SceneGQLModel = SceneModelFragment;
export { Application as SceneApplication };

export interface ZoneBehaviorInternalModel {
  zoneId: string;
  behaviorId: BehaviorType;
  behaviorParameters: BehaviorParameters;
}

export interface SceneInternalModel {
  id: string;
  name: string;
  application: Application;
  zoneBehaviors: ZoneBehaviorInternalModel[];
}

export interface ZoneBehaviorWSModel {
  zoneId: string;
  behaviorId: BehaviorType;
  behaviorParameters: BehaviorParameters;
}

export interface SceneWSModel {
  id: string;
  name: string;
  application: Application;
  sceneZoneBehaviors: ZoneBehaviorWSModel[];
}

export function toSceneInternalModelFromWS(gql: SceneWSModel): SceneInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    application: gql.application,
    zoneBehaviors: gql.sceneZoneBehaviors.map(szb => ({
      zoneId: szb.zoneId,
      behaviorId: szb.behaviorId,
      behaviorParameters: szb.behaviorParameters,
    })),
  };
}

export type SceneStates = 'ready' | 'success' | 'pending' | 'error';

export interface SceneFormModel {
  id: string | null;
  name: string;
  zoneBehaviors: ZoneBehaviorFormModel[];
}

export function toSceneInternalModelFromGQL(gql: SceneGQLModel): SceneInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    application: gql.application,
    zoneBehaviors: gql.controlZones.edges.map(czb => ({
      zoneId: czb.node.id,
      behaviorId: czb.behavior.id as BehaviorType,
      behaviorParameters: czb.parameters ?? null,
    })),
  };
}

export interface SceneViewModel {
  id: string;
  name: string;
  application: Application;
  linkedZoneCount: number;
  linkedBehaviorList: LinkedBehavior[];
  state: SceneStates;
  isHidden: boolean;
}

export interface LinkedBehavior {
  behaviorParameters: string | null;
  id: BehaviorType;
  zoneList: { id: string; name: string }[];
}
