import { createAction } from '@ngrx/store';
import { Bridge485InternalModel } from '@spog-ui/shared/models/bridge-485s';

export const loadBridge485sAction = createAction(
  '[Bridge 485s State] Load Bridge 485s',
  (bridge485s: Bridge485InternalModel[]) => ({ bridge485s }),
);

export const deleteBridge485Action = createAction(
  '[Bridge 485s State] Delete One Bridge485',
  (id: string) => ({ id }),
);
