import { createReducer, on } from '@ngrx/store';
import {
  LightMapLayerActions,
  LightMapLayerApiActions,
} from '@spog-ui/light-map-layer/actions';

export interface Shape {
  searchTerm: string;
  error: any;
  loading: boolean;
  loadingDeferredModels: boolean;
}

export const initialState: Shape = {
  searchTerm: '',
  error: null,
  loading: false,
  loadingDeferredModels: false,
};

export const reducer = createReducer(
  initialState,
  on(
    LightMapLayerActions.enterAction,
    (state): Shape => {
      return {
        ...state,
        loading: true,
      };
    },
  ),
  on(
    LightMapLayerApiActions.loadLightMapLayerModelsSuccessAction,
    (state): Shape => {
      return {
        ...state,
        loading: false,
      };
    },
  ),
  on(
    LightMapLayerApiActions.loadLightMapLayerModelsFailureAction,
    (state, action): Shape => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    },
  ),
  on(
    LightMapLayerActions.filterAction,
    (state, action): Shape => {
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    },
  ),
  on(
    LightMapLayerApiActions.loadLightMapLayerModelsSuccessAction,
    (state): Shape => {
      return {
        ...state,
        loadingDeferredModels: true,
      };
    },
  ),
  on(
    LightMapLayerApiActions.loadLightMapLayerDeferredModelsSuccessAction,
    LightMapLayerApiActions.loadLightMapLayerDeferredModelsFailureAction,
    (state): Shape => {
      return {
        ...state,
        loadingDeferredModels: false,
      };
    },
  ),
);

export const selectSearchTerm = (state: Shape) => state.searchTerm;
export const selectIsLoading = (state: Shape) => state.loading;
export const selectIsDeferredModelsLoading = (state: Shape) =>
  state.loadingDeferredModels;
export const selectError = (state: Shape) => state.error;
