import { Component, Injectable, Input } from '@angular/core';
import { SceneViewModel } from '@spog-ui/shared/models/scenes';
import { ScenesPageActions } from '@spog-ui/scenes/actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lit-light-scenes-card',
  template: `
    <mat-card class="sceneCard">
      <mat-card-content>
        <mat-card-title>Scenes</mat-card-title>
        @if (scenes.length > 0) {
          <div>
            @for (scene of scenes; track scene) {
              <div class="row" [matTooltip]="scene.name">
                <mat-icon>image</mat-icon>
                <span>{{ scene.name }}</span>
                <scn-apply-scene-button
                  [state]="scene.state"
                  [application]="scene.application"
                  (apply)="onApplyScene(scene)"
                  *suiIfUserCan="['ssc:siteAdmin']"
                ></scn-apply-scene-button>
              </div>
            }
          </div>
        } @else {
          There are no scenes that are associated with this light.
        }
      </mat-card-content>
    </mat-card>
    `,
  styles: [
    `
      .sceneCard {
        border-radius: 2px;
      }

      mat-card-title {
        font-size: 16px;
        padding: 0px;
      }

      .row {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 40px auto 82px;
        align-items: center;
        padding-bottom: 16px;
        cursor: pointer;
        text-decoration: none;
      }
    `,
  ],
})
export class LightScenesCardComponent {
  @Input()
  scenes: SceneViewModel[];

  constructor(public store: Store) {}

  onApplyScene(scene: SceneViewModel) {
    this.store.dispatch(ScenesPageActions.applySceneAction(scene));
  }
}
