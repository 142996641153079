import { createAction } from '@ngrx/store';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';
import { UserInternalModel } from '@spog-ui/shared/models/users';

export const loadModelsSuccessAction = createAction(
  '[Edit User / API] Load Models Success',
  (user: UserInternalModel, sites: SiteInternalModel[]) => ({ user, sites }),
);
export const loadModelsFailureAction = createAction(
  '[Edit User / API] Load Models Failure',
  (error: string) => ({ error }),
);

export const updateUserSuccessAction = createAction(
  '[Edit User / API] Update User Success',
  (userId: string) => ({ userId }),
);
export const updateUserFailureAction = createAction(
  '[Edit User / API] Update User Failure',
  (error: string) => ({ error }),
);
