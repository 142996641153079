import {
  EditThermostatApiActions,
  EditThermostatPageActions,
} from '@spog-ui/thermostats/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  isLoading: boolean;
  isSaving: boolean;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
  isSaving: false,
};

export const reducer = createReducer(
  initialState,
  on(EditThermostatPageActions.enterAction, () => initialState),
  on(
    EditThermostatApiActions.loadEditThermostatPageModelsSuccessAction,
    (state): Shape => {
      return { ...state, isLoading: false };
    },
  ),
  on(
    EditThermostatApiActions.loadEditThermostatPageModelsFailureAction,
    (state, action) => {
      return { ...state, isLoading: false, error: action.error };
    },
  ),
  on(EditThermostatPageActions.editThermostatAction, state => {
    return { ...state, isSaving: true };
  }),
  on(EditThermostatApiActions.editThermostatSuccess, state => {
    return { ...state, isSaving: false };
  }),
  on(EditThermostatApiActions.editThermostatFailure, state => {
    return { ...state, isSaving: false };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSaving = (state: Shape) => state.isSaving;
export const selectError = (state: Shape) => state.error;
