import { Component, Input, TrackByFunction } from '@angular/core';
import { Store } from '@ngrx/store';
import { UtilityServicesPageActions } from '@spog-ui/utility-services/actions';
import { ScheduledUtilityRateViewModel } from '@spog-ui/shared/models/scheduled-utility-rates';
import { UtilityServiceViewModel } from '@spog-ui/shared/models/utility-services';

@Component({
  selector: 'spog-utility-service-list',
  template: `
    @for (utilityService of utilityServices; track trackById($index, utilityService)) {
      <spog-utility-service
        [utilityService]="utilityService"
        (deleteUtilityService)="onDeleteUtilityService($event)"
        (scheduleUtilityRate)="onScheduleUtilityRate($event)"
        (deleteScheduledUtilityRate)="onDeleteScheduledUtilityRate($event)"
        >
      </spog-utility-service>
    }
    `,
  styles: [
    `
      :host {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
        column-gap: 16px;
        row-gap: 16px;
      }

      @media screen and (max-width: 540px) {
        :host {
          grid-template-columns: 1fr;
        }
      }
    `,
  ],
})
export class UtilityServiceListComponent {
  constructor(readonly store: Store) {}

  @Input() utilityServices: UtilityServiceViewModel[] = [];
  trackById: TrackByFunction<UtilityServiceViewModel> = (_, utilityService) => {
    return utilityService.id;
  };

  onDeleteUtilityService(utilityService: UtilityServiceViewModel) {
    this.store.dispatch(
      UtilityServicesPageActions.deleteUtilityServiceAction(utilityService),
    );
  }

  onDeleteScheduledUtilityRate(scheduledUtilityRate: ScheduledUtilityRateViewModel) {
    this.store.dispatch(
      UtilityServicesPageActions.deleteScheduledUtilityRateAction(scheduledUtilityRate),
    );
  }

  onScheduleUtilityRate(utilityService: UtilityServiceViewModel) {
    this.store.dispatch(
      UtilityServicesPageActions.scheduleUtilityRateAction(utilityService),
    );
  }
}
