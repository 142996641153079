import { EMPTY, fromEvent, Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { PlatformService } from './platform.service';

export const WINDOW_TOKEN = new InjectionToken<Window>('Window');

export function createWindow() {
  return window;
}

export abstract class WindowService {
  abstract observeEvent(eventName: string): Observable<any>;
  abstract getWidth(): number;
  abstract getHeight(): number;
}

export class BrowserWindowService implements WindowService {
  obsFromEvent = fromEvent;
  constructor(private window: Window) {}

  observeEvent(eventName: string): Observable<any> {
    return this.obsFromEvent(this.window, eventName);
  }

  getWidth() {
    return this.window.innerWidth;
  }

  getHeight() {
    return this.window.innerHeight;
  }
}

export class NodeWindowService implements WindowService {
  observeEvent(eventName: string): Observable<any> {
    return EMPTY;
  }

  getWidth() {
    return 0;
  }

  getHeight() {
    return 0;
  }
}

export function createWindowService(platform: PlatformService, window: Window) {
  if (platform.isBrowser()) {
    return new BrowserWindowService(window);
  }

  return new NodeWindowService();
}

export const WINDOW_SERVICE_PROVIDER = [
  {
    provide: WindowService,
    deps: [PlatformService, WINDOW_TOKEN],
    useFactory: createWindowService,
  },
  {
    provide: WINDOW_TOKEN,
    useFactory: createWindow,
  },
];
