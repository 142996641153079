import { createAction } from '@ngrx/store';
import { LightControlViewModel } from '@spog-ui/shared/models/lights';

export const cancelAction = createAction('[Light Control] Cancel');
export const dimAction = createAction(
  '[Light Control] Dim',
  (light: LightControlViewModel, level: number) => {
    return { light, level };
  },
);
