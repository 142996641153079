import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SceneApplication, SceneViewModel } from '@spog-ui/shared/models/scenes';

@Component({
  selector: 'scn-climate-scene',
  template: `
    <mat-icon>smart_button</mat-icon>
    <h3>{{ scene.name }}</h3>
    <h4>
      <span id="climate-zone" [ngPlural]="scene.linkedZoneCount">
        <ng-template ngPluralCase="=1">1 Climate Zone</ng-template>
        <ng-template ngPluralCase="other"
          >{{ scene.linkedZoneCount.toString() }} Climate Zones</ng-template
        >
      </span>
    </h4>
    <scn-apply-scene-button
      [state]="scene.state"
      [application]="scene.application"
      (apply)="apply.emit()"
      *suiIfUserCan="['ssc:siteAdmin']"
    ></scn-apply-scene-button>
    <button
      mat-icon-button
      class="contextMenuButton"
      [matMenuTriggerFor]="contextMenu"
      *suiIfUserCan="['ssc:siteAdmin']"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #contextMenu="matMenu">
      <a
        mat-menu-item
        *suiIfUserCan="['ssc:siteAdmin']"
        [routerLink]="'./' + scene.id"
        >Edit</a
      >
      <a
        mat-menu-item
        *suiIfUserCan="['ssc:siteAdmin']"
        [routerLink]="urlPrefix + '/schedule/add'"
        [queryParams]="{ sceneId: scene.id }"
      >
        Schedule
      </a>
      <button
        mat-menu-item
        *suiIfUserCan="['ssc:siteAdmin']"
        (click)="duplicate.emit()"
      >
        Duplicate
      </button>
      <button mat-menu-item (click)="delete.emit()">Delete</button>
    </mat-menu>
  `,
  styles: [
    `
      :host {
        padding: 16px 8px 16px 16px;
        display: grid;
        grid-template-areas:
          'icon  title    button menu'
          'icon  subtitle button menu';
        grid-template-columns: 40px 1fr auto auto;
        column-gap: 8px;
        background-color: var(--color-background-card);
        border-radius: 2px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
        align-items: center;
      }

      mat-icon {
        grid-area: icon;
      }

      h3 {
        grid-area: title;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.0015em;
        margin: 0;
      }

      h4 {
        grid-area: subtitle;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.001em;
        margin: 0;
        color: var(--color-foreground-secondary-text);
      }

      scn-apply-scene-button {
        /* Button spans 2 rows */
        grid-row-start: button;
        grid-row-end: button;
      }

      .contextMenuButton {
        grid-area: menu;
      }
    `,
  ],
})
export class ClimateSceneComponent {
  @Input() scene: SceneViewModel = {
    name: '',
    application: SceneApplication.CLIMATE,
    id: '',
    linkedBehaviorList: [],
    linkedZoneCount: 0,
    state: 'error',
    isHidden: false,
  };
  @Input() urlPrefix = '';
  @Output() apply = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() duplicate = new EventEmitter<void>();
}
