import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { Bridge485DetailsEffects } from './lib/effects';
import { bridge485DetailsStateFeatureKey } from './lib/state';
import * as Bridge485DetailsState from './lib/state/bridge-485-details-pane.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(bridge485DetailsStateFeatureKey, {
      [Bridge485DetailsState.STATE_KEY]: Bridge485DetailsState.reducer,
    }),
    EffectsModule.forFeature([Bridge485DetailsEffects]),
  ],
})
export class Bridge485DetailsStateModule {}
