import {
  LightSuiModel as LightModel,
  LightViewModel,
} from '@spog-ui/shared/models/lights';
import { ZoneModel } from '@spog-ui/shared/models/zones';
import { createAction } from '@ngrx/store';

export const goBackAction = createAction('[Light Details] Go Back');
export const controlLightAction = createAction(
  '[Light Details] Control Light',
  (light: LightModel, level: number) => {
    return { light, level };
  },
);
export const pingLightAction = createAction(
  '[Light Details] Ping Light',
  (light: LightModel) => {
    return { light };
  },
);
export const checkLightSignalStrengthAction = createAction(
  '[Light Details] Check Light Signal Strength',
  (light: LightModel) => {
    return { light };
  },
);
export const retryLightConfigAction = createAction(
  '[Light Details] Retry Light Config',
  (light: LightModel) => {
    return { light };
  },
);
export const previewZoneAction = createAction(
  '[Light Details] Preview Zone',
  (zoneId: string) => ({ zoneId }),
);
export const unpreviewZoneAction = createAction('[Light Details] Unpreview Zone');
export const controlZoneAction = createAction(
  '[Light Details] Control Zone',
  (zone: ZoneModel) => ({ zone }),
);
export const replacePromptAction = createAction(
  '[Light Details] Replace Prompt',
  (lightId: string) => ({ lightId }),
);
export const replaceCancelAction = createAction('[Light Page] Replace Cancel');
export const replaceAction = createAction(
  '[Light Details] Replace',
  (formValue: { light: LightViewModel; macAddress: string }) => formValue,
);

export type Union =
  | ReturnType<typeof goBackAction>
  | ReturnType<typeof controlLightAction>
  | ReturnType<typeof pingLightAction>
  | ReturnType<typeof checkLightSignalStrengthAction>
  | ReturnType<typeof retryLightConfigAction>
  | ReturnType<typeof previewZoneAction>
  | ReturnType<typeof unpreviewZoneAction>
  | ReturnType<typeof replacePromptAction>
  | ReturnType<typeof replaceCancelAction>
  | ReturnType<typeof replaceAction>
  | ReturnType<typeof controlZoneAction>;

export const Types = {
  GoBack: goBackAction.type,
  ControlLight: controlLightAction.type,
  PingLight: pingLightAction.type,
  CheckLightSignalStrength: checkLightSignalStrengthAction.type,
  RetryLightConfig: retryLightConfigAction.type,
  PreviewZone: previewZoneAction.type,
  UnpreviewZone: unpreviewZoneAction.type,
  ControlZone: controlZoneAction.type,
};
