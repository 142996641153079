import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { Sense420FormComponentModule } from '../sense-420-form';
import { AddIndieSensorPageComponent } from './add-indie-sensor-page.component';
import { HardwareTypeSelectorComponentModule } from '../hardware-type-selector';

@NgModule({
  imports: [
    CommonModule,
    SuiSpinnerModule,
    HardwareTypeSelectorComponentModule,
    Sense420FormComponentModule,
  ],
  declarations: [AddIndieSensorPageComponent],
})
export class AddIndieSensorPageComponentModule {}
