import * as SceneViewsPageState from './scene-views-page';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

export const STATE_KEY = 'scene-views';

export interface Shape {
  sceneViewsPage: SceneViewsPageState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  sceneViewsPage: SceneViewsPageState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Scene Views Page State
 */
export const selectSceneViewsPageState = createSelector(
  selectFeatureState,
  state => state.sceneViewsPage,
);
export const selectSceneViewsPageIsLoading = createSelector(
  selectSceneViewsPageState,
  SceneViewsPageState.selectIsLoadingModels,
);
export const selectSceneViewsPageError = createSelector(
  selectSceneViewsPageState,
  SceneViewsPageState.selectError,
);

/**
 * Custom Scene Views Page Selectors
 */
export const selectSceneViewsPageIsReady = createSelector(
  selectSceneViewsPageIsLoading,
  isLoading => !isLoading,
);
