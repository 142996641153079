import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { FloorPlanModel } from '@spog-ui/shared/models/floor-plans';
import * as CoreState from '@spog-ui/shared/state/core';
import * as FloorPlanState from './floor-plan';
import * as ZoomAndPanState from './zoom-and-pan';

export const STATE_KEY = 'map';

export interface Shape {
  floorPlan: FloorPlanState.Shape;
  zoomAndPan: ZoomAndPanState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  floorPlan: FloorPlanState.reducer,
  zoomAndPan: ZoomAndPanState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Floor Plan Page State
 */
export const selectFloorPlanState = createSelector(
  selectFeatureState,
  state => state.floorPlan,
);
export const selectFloorPlanPagePending = createSelector(
  selectFloorPlanState,
  FloorPlanState.selectPending,
);
export const selectFloorPlanPageError = createSelector(
  selectFloorPlanState,
  FloorPlanState.selectError,
);
export const selectActiveFloorPlan = createSelector(
  selectFloorPlanState,
  FloorPlanState.selectActive,
);
export const selectActiveFloorPlanId = createSelector(
  selectActiveFloorPlan,
  (floorPlan: FloorPlanModel | null) => {
    return floorPlan ? floorPlan.id : null;
  },
);
export const selectFloorPlanPageIsGettingStarted = createSelector(
  selectActiveFloorPlan,
  floorPlan => !floorPlan,
);

/**
 * Zoom and Pan
 */
export const selectZoomAndPanState = createSelector(
  selectFeatureState,
  state => state.zoomAndPan,
);
export const selectZoomAndPanIsEnabled = createSelector(
  selectZoomAndPanState,
  ZoomAndPanState.selectIsEnabled,
);
export const selectZoomAndPanTransform = createSelector(
  selectZoomAndPanState,
  ZoomAndPanState.selectTransform,
);
