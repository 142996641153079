import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Inject,
  Injectable,
  InjectionToken,
  OnDestroy,
  Optional,
  Output,
  Provider,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { defer, from, Observable, Observer, of, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { TransformModel } from '@spog-ui/map-tools/models';
import { MapToolsState } from '../../services';
import { MapToolsActions } from '../../actions';
import { MapLayerOutletsService } from './map-layer-outlets.directives';

export interface MapLayer {
  isLayerLocking?: boolean;
  isLayerEnabled(): Observable<boolean>;
  getLayerComponents(): Promise<MapLayerComponents>;
}

export interface MapLayerComponents {
  html: Type<any>[];
  svg: Type<any>[];
  projectedSVG: Type<any>[];
  canvas: Type<any>[];
}

type MapLayerRenderEvent =
  | { type: 'start'; layer: MapLayer }
  | { type: 'complete'; layer: MapLayer };

export const MAP_LAYERS = new InjectionToken<MapLayer>('Map Layers');

export function provideMapLayer(layerService: Type<MapLayer>): Provider {
  return {
    provide: MAP_LAYERS,
    multi: true,
    useClass: layerService,
  };
}

@Injectable({ providedIn: 'root' })
export class MapLayersRef {
  mapLayers: MapLayersComponent;
}

@Component({
  selector: 'map-layers',
  template: `
    <spog-subscription-banner></spog-subscription-banner>
    
    <map-pan-and-zoom-container
      [enabled]="state.zoomAndPanEnabled$ | async"
      (clickOnPoint)="onClickOnPoint($event)"
      (zoomAndPan)="onZoomAndPan($event)"
      >
      <map-canvas>
        <ng-template #canvas></ng-template>
        @for (canvasElement of layers.canvasElements$ | async; track canvasElement) {
          <ng-template [cdkPortalOutlet]="canvasElement"></ng-template>
        }
      </map-canvas>
      <svg
        #wrapper
        [class.areaSelectTool]="
          (state.areaSelectEnabled$ | async) || (state.freeformSelectEnabled$ | async)
        "
        >
        <svg:g map-pan-and-zoom-target>
          <ng-template #projectedSVG></ng-template>
          @for (projectedSvgElement of layers.projectedSvgElements$ | async; track projectedSvgElement) {
            <ng-container
              >
              <ng-container *cdkPortalOutlet="projectedSvgElement"></ng-container>
            </ng-container>
          }
          </svg:g>
          <ng-template #svg></ng-template>
          @for (svgElement of layers.svgElements$ | async; track svgElement) {
            <ng-container>
              <ng-container *cdkPortalOutlet="svgElement"></ng-container>
            </ng-container>
          }
        </svg>
      </map-pan-and-zoom-container>
      <ng-template #html></ng-template>
      <ng-content></ng-content>
    `,
  styles: [
    `
      :host {
        width: 100vw;
        background-color: var(--color-background-background);
        position: absolute;
        height: var(--content-height);
        top: var(--content-offset);
        left: 0;
      }

      svg {
        width: 100vw;
        position: absolute;
        height: var(--content-height);
        top: 0;
        left: 0;
      }

      .areaSelectTool {
        cursor: crosshair;
      }

      spog-subscription-banner {
        z-index: 10;
        position: relative;
      }
    `,
  ],
})
export class MapLayersComponent implements AfterViewInit, OnDestroy {
  @Output() render = new EventEmitter();
  @ViewChild('svg', { read: ViewContainerRef, static: true })
  svgOutlet: ViewContainerRef;
  @ViewChild('projectedSVG', { read: ViewContainerRef, static: true })
  projectedSVGOutlet: ViewContainerRef;
  @ViewChild('html', { read: ViewContainerRef, static: true })
  htmlOutlet: ViewContainerRef;
  @ViewChild('canvas', { read: ViewContainerRef, static: true })
  canvasOutlet: ViewContainerRef;
  @ViewChild('wrapper', { static: true }) wrapperRef: ElementRef<SVGSVGElement>;
  get wrapper() {
    return this.wrapperRef.nativeElement;
  }

  locked = 0;

  private renderSubscription: Subscription;
  private mapLayers: MapLayer[];

  constructor(
    readonly state: MapToolsState,
    readonly layers: MapLayerOutletsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(MAP_LAYERS) @Optional() mapLayers: MapLayer[],
    ref: MapLayersRef,
  ) {
    ref.mapLayers = this;
    this.mapLayers = mapLayers || [];
  }

  ngAfterViewInit() {
    this.renderSubscription = from(this.mapLayers)
      .pipe(
        mergeMap(layer => this.renderLayer(layer)),
        scan(
          (renderStatuses: Map<MapLayer, boolean>, renderEvent: MapLayerRenderEvent) => {
            if (renderEvent.type === 'start') {
              renderStatuses.set(renderEvent.layer, false);
            } else {
              renderStatuses.set(renderEvent.layer, true);
            }

            return renderStatuses;
          },
          new Map<MapLayer, boolean>(),
        ),
        map(renderStatuses =>
          Array.from(renderStatuses.values()).every(
            renderStatus => renderStatus === true,
          ),
        ),
        filter(hasRendered => hasRendered === true),
        tap(() => void 0, console.error),
      )
      .subscribe(this.render);
  }

  ngOnDestroy() {
    if (this.renderSubscription) this.renderSubscription.unsubscribe();
  }

  private renderLayer(layer: MapLayer): Observable<MapLayerRenderEvent> {
    const startEvent: MapLayerRenderEvent = { type: 'start', layer };
    const completeEvent: MapLayerRenderEvent = { type: 'complete', layer };
    const isLayerLocking = Boolean(layer.isLayerLocking);

    const insertComponentsIntoOutlet = (
      outlet: ViewContainerRef,
      components: Type<any>[],
      isSvg: boolean,
    ) =>
      components.map(component => {
        const factory = this.componentFactoryResolver.resolveComponentFactory(component);

        if (isSvg) {
          const groupNode = document.createElementNS('http://www.w3.org/2000/svg', 'g');
          const instance = factory.create(outlet.injector, [], groupNode);

          outlet.insert(instance.hostView);
          return instance;
        }

        return outlet.createComponent(component);
      });

    const loadLayer$ = defer(() => layer.getLayerComponents()).pipe(shareReplay(1));

    return layer.isLayerEnabled().pipe(
      distinctUntilChanged(),
      switchMap((shouldShowLayer: boolean): Observable<MapLayerRenderEvent> => {
        if (!shouldShowLayer) return of(completeEvent);
        if (isLayerLocking) this.locked += 1;

        return loadLayer$.pipe(
          mergeMap(components => {
            return new Observable((observer: Observer<MapLayerRenderEvent>) => {
              const componentRefs = [
                ...insertComponentsIntoOutlet(this.htmlOutlet, components.html, false),
                ...insertComponentsIntoOutlet(this.svgOutlet, components.svg, true),
                ...insertComponentsIntoOutlet(
                  this.projectedSVGOutlet,
                  components.projectedSVG,
                  true,
                ),
                ...insertComponentsIntoOutlet(
                  this.canvasOutlet,
                  components.canvas,
                  false,
                ),
              ];

              observer.next(completeEvent);

              return () => {
                componentRefs.forEach(ref => ref.destroy());

                if (isLayerLocking) --this.locked;
              };
            });
          }),
          startWith(startEvent),
        );
      }),
    );
  }

  onClickOnPoint(point: { x: number; y: number; r: number }) {
    this.state.dispatch(MapToolsActions.clickAction(point));
  }

  onZoomAndPan(transform: TransformModel) {
    this.state.dispatch(MapToolsActions.zoomAndPanAction(transform));
  }
}
