import { Observable, OperatorFunction } from 'rxjs';
import { exhaustMap, take, tap } from 'rxjs/operators';
import { AreaModel } from '@spog-ui/map-tools/models';
import { TransformAnimator } from '../services';

export function zoomAndPan(
  rectFactory: () => Observable<AreaModel>,
  transformAnimator: TransformAnimator,
): OperatorFunction<unknown, AreaModel> {
  /**
   * This operator intentionally uses `exhaustMap` instead of `withLatestFrom`
   * so that the effect only subscribes to the selector once. If this used
   * `withLatestFrom` it would stay subscribed to the result of the selector
   * causing us to compute the box around the filtered entities way too
   * aggressively
   */
  return exhaustMap(() =>
    rectFactory().pipe(
      take(1),
      tap(rect => transformAnimator.transitionToRect(rect)),
    ),
  );
}
