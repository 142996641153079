import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ScenesService } from '../services';
import {
  AddScenePageActions,
  EditScenePageActions,
  ScenesApiActions,
  ScenesPageActions,
  AddSequenceScenePageActions,
  EditSequenceScenePageActions,
} from '@spog-ui/scenes/actions';
import { SiteService, withSiteId } from '@spog-ui/shared/services';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { SceneApplication } from '@spog-ui/shared/models/scenes';

export const deleteRoutineFailureMessage = 'Failed to delete routine';
export const deleteSceneFailureMessage = 'Failed to delete scene';

@Injectable()
export class ScenesApiEffects {
  constructor(
    private actions$: Actions,
    private scenesService: ScenesService,
    private sitesService: SiteService,
    private snackbar: MatSnackBar,
  ) {}

  loadAllScenes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ScenesPageActions.enterAction,
        AddScenePageActions.enterAction,
        EditScenePageActions.enterAction,
        AddSequenceScenePageActions.enterAction,
        EditSequenceScenePageActions.enterAction,
      ),
      withSiteId(
        () => this.sitesService.observe(),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        exhaustMap(([_action, siteId]) =>
          this.scenesService.getAllScenes(siteId).pipe(
            map(things => {
              return ScenesApiActions.loadSuccessAction(things);
            }),
            catchError(error => of(ScenesApiActions.loadFailureAction(error))),
          ),
        ),
      ),
    ),
  );

  deleteScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenesPageActions.deleteSceneConfirmAction),
      mergeMap(action =>
        this.scenesService.deleteScene(action.sceneId).pipe(
          map(() => ScenesApiActions.deleteSceneSuccessAction(action.sceneId)),
          catchError(errorResponse => {
            return of(
              ScenesApiActions.deleteSceneFailureAction(
                action.application,
                action.sceneId,
                retrieveErrorMessage(errorResponse),
              ),
            );
          }),
        ),
      ),
    ),
  );

  deleteSceneFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenesApiActions.deleteSceneFailureAction),
      mergeMap(action =>
        this.getRetryToast(
          action.application === SceneApplication.CLIMATE
            ? deleteRoutineFailureMessage
            : deleteSceneFailureMessage,
          ScenesPageActions.deleteSceneAction(action.application, action.sceneId),
        ),
      ),
    ),
  );

  deleteSequenceScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenesPageActions.deleteSequenceSceneConfirmAction),
      mergeMap(action =>
        this.scenesService.deleteSequenceScene(action.sequenceSceneId).pipe(
          map(() =>
            ScenesApiActions.deleteSequenceSceneSuccessAction(action.sequenceSceneId),
          ),
          catchError(errorResponse => {
            return of(
              ScenesApiActions.deleteSequenceSceneFailureAction(
                action.sequenceSceneId,
                retrieveErrorMessage(errorResponse),
              ),
            );
          }),
        ),
      ),
    ),
  );

  deleteSequenceSceneFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenesApiActions.deleteSequenceSceneFailureAction),
      mergeMap(action =>
        this.getRetryToast(
          'Failed to delete sequence scene',
          ScenesPageActions.deleteSequenceSceneAction(action.sequenceSceneId),
        ),
      ),
    ),
  );

  showSceneError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ScenesApiActions.addSceneFailureAction,
          ScenesApiActions.editSceneFailureAction,
          ScenesApiActions.addSequenceSceneFailureAction,
          ScenesApiActions.editSequenceSceneFailureAction,
        ),
        tap(action => this.snackbar.open(action.error, 'Dismiss')),
      ),
    { dispatch: false },
  );

  getRetryToast<T extends Action>(message: string, retryAction: T): Observable<T> {
    return this.snackbar
      .open(message, 'Try Again', { duration: 12 * 1000 })
      .onAction()
      .pipe(map(() => retryAction));
  }
}
