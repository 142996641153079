import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ViewScenesFeatureModule } from './view';
import { AddScenesFeatureModule, AddSequenceScenesFeatureModule } from './add';
import { EditScenesFeatureModule, EditSequenceScenesFeatureModule } from './edit';
import { FORM_ERROR_MESSAGES } from '@spog-ui/shared/components';
import { routes } from './scenes.routing';
import {
  ScenesPageStateAdapter,
  AddScenePageStateAdapter,
  EditScenePageStateAdapter,
  AddSequenceScenePageStateAdapter,
  EditSequenceScenePageStateAdapter,
} from './scenes.state-adapters';
import { ScenesEffectsModule } from './effects/index';

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    ViewScenesFeatureModule.connect(ScenesPageStateAdapter),
    AddScenesFeatureModule.connect(AddScenePageStateAdapter),
    EditScenesFeatureModule.connect(EditScenePageStateAdapter),
    AddSequenceScenesFeatureModule.connect(AddSequenceScenePageStateAdapter),
    EditSequenceScenesFeatureModule.connect(EditSequenceScenePageStateAdapter),
    ScenesEffectsModule,
  ],
  providers: [
    {
      provide: FORM_ERROR_MESSAGES,
      useFactory: getFormErrorMessages,
    },
  ],
})
export class ScenesModule {}

export function getFormErrorMessages() {
  return {
    default: {
      suiUniqueSceneName: (sceneName: string) =>
        `Scene name must be unique, "${sceneName}" is already taken`,
    },
  };
}
