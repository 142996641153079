import { createReducer, on } from '@ngrx/store';
import { LightMapLayerIconSize } from '@spog-shared/graphql-enums';
import { MapActions, MapApiActions } from '@spog-ui/map/actions';

export interface Shape {
  loadingEssentialModels: boolean;
  hasEssentialModelsError: boolean;
  hasEssentialModelsNetworkError: boolean;
  toggleMapSearch: boolean;
  showMapFilter: boolean;
  lightMapLayerIconSize: LightMapLayerIconSize;
}

const initialState: Shape = {
  loadingEssentialModels: true,
  hasEssentialModelsError: false,
  hasEssentialModelsNetworkError: false,
  toggleMapSearch: false,
  showMapFilter: true,
  lightMapLayerIconSize: LightMapLayerIconSize.AUTO,
};

export const reducer = createReducer(
  initialState,
  on(MapActions.enterAction, MapActions.retryAction, () => {
    return initialState;
  }),
  on(MapApiActions.loadEssentialModelsSuccessAction, (state, action) => {
    return {
      ...state,
      loadingEssentialModels: false,
      hasEssentialModelsError: false,
      hasEssentialModelsNetworkError: false,
      lightMapLayerIconSize: action.models.lightMapLayerIconSize,
    };
  }),
  on(MapApiActions.loadEssentialModelsFailureAction, state => {
    return {
      ...state,
      loadingEssentialModels: false,
      hasEssentialModelsNetworkError: false,
      hasEssentialModelsError: true,
    };
  }),
  on(MapApiActions.loadEssentialModelsNetworkFailureAction, state => {
    return {
      ...state,
      loadingEssentialModels: false,
      hasEssentialModelsError: false,
      hasEssentialModelsNetworkError: true,
    };
  }),
  on(MapActions.leaveAction, state => {
    return { ...state, showMapFilter: false };
  }),
  on(MapActions.toggleMapSearchAction, state => {
    if (state.toggleMapSearch) {
      return { ...state, toggleMapSearch: false };
    } else {
      return { ...state, toggleMapSearch: true };
    }
  }),
);

export const selectIsLoadingEssentialModels = (state: Shape) =>
  state.loadingEssentialModels;
export const selectHasEssentialModelsError = (state: Shape) =>
  state.hasEssentialModelsError;
export const selectHasEssentialModelsNetworkError = (state: Shape) =>
  state.hasEssentialModelsNetworkError;
export const selectToggleMapSearch = (state: Shape) => state.toggleMapSearch;
export const selectShowMapFilter = (state: Shape) => state.showMapFilter;
export const selectLightMapLayerIconSize = (state: Shape) => state.lightMapLayerIconSize;
