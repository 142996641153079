import { createAction } from '@ngrx/store';
import { PermissionGroupSiteRole } from '@spog-ui/graphql/types';

export const enterAction = createAction(
  '[Admin Organization] Enter Admin Organization Panel',
  (organizationId: string) => {
    return { organizationId };
  },
);

export const closeAdminOrgPanel = createAction(
  '[Admin Organization] Close Admin Organization Panel',
);

export const updateOrganizationDefaultSiteRoleAction = createAction(
  '[Admin Organization] Update Organization Default Site Role',
  (id: string, defaultSiteRole: PermissionGroupSiteRole) => {
    return { id, defaultSiteRole };
  },
);
