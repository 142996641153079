import { Injectable } from '@angular/core';
import { TemplatePortalDirective } from '@angular/cdk/portal';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CharmsService {
  charms$ = new BehaviorSubject<Set<TemplatePortalDirective>>(new Set());

  addCharm(charm: TemplatePortalDirective) {
    const nextCharms = new Set(this.charms$.value);

    nextCharms.add(charm);

    this.charms$.next(nextCharms);
  }

  removeCharm(charm: TemplatePortalDirective) {
    const nextCharms = new Set(this.charms$.value);

    nextCharms.delete(charm);

    this.charms$.next(nextCharms);
  }
}
