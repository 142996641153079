import { PowerActions, PowerApiActions } from '@spog-ui/power/actions';
import { SocketActions } from '@spog-ui/socket/actions';
import { createReducer, on } from '@ngrx/store';
import { BaselineType } from '@spog-ui/graphql/types';

export interface Shape {
  baseline: number | null;
  skip: boolean | null;
  baselineType: BaselineType;
  error?: any;
}

export const initialState: Shape = {
  baseline: null,
  skip: null,
  baselineType: BaselineType.FLAT,
  error: false,
};

export const reducer = createReducer(
  initialState,
  on(PowerApiActions.saveBaselineSuccessAction, (state, action) => {
    return {
      ...state,
      baseline: action.baseline,
      baselineType: action.baselineType,
      skip: false,
    };
  }),
  on(PowerActions.skipBaselineAction, state => ({
    ...state,
    skip: true,
  })),
  on(PowerApiActions.loadBaselineSuccessAction, (state, action) => ({
    ...state,
    baseline: action.baseline,
    baselineType: action.baselineType,
    skip: action.skip,
  })),
  on(PowerApiActions.loadBaselineFailureAction, (state, action) => ({
    ...state,
    baseline: null,
    skip: null,
    error: action.error,
  })),
  on(PowerApiActions.removeBaselineSuccessAction, state => ({
    ...state,
    baseline: null,
  })),
  on(PowerApiActions.saveBaselineFailureAction, (state, action) => ({
    ...state,
    baseline: null,
    error: action.error,
  })),
  on(PowerApiActions.saveBaselineSkipFailureAction, (state, action) => ({
    ...state,
    skip: null,
    error: action.error,
  })),
  on(SocketActions.updateEnergyBaselineAction, (state, action) => {
    return {
      ...state,
      baseline: action.baseline,
      baselineType: action.baselineType,
    };
  }),
);

export const selectError = (state: Shape) => state.error;
export const selectBaseline = (state: Shape) => state.baseline;
export const selectSkip = (state: Shape) => state.skip;
export const selectBaselineType = (state: Shape) => state.baselineType;
