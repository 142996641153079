import { createAction } from '@ngrx/store';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';
import { OrganizationUserInternalModel } from '@spog-ui/shared/models/organization-users';

export const loadPageSuccessAction = createAction(
  '[Admin Organization / Users / API] Load Page Success',
  (users: OrganizationUserInternalModel[]) => ({
    users,
  }),
);

export const loadPageFailureAction = createAction(
  '[Admin Organization / Users / API] Load Page Failure',
  (error: string) => ({ error }),
);

export const removeOrganizationUserFailureAction = createAction(
  '[Admin Organization / Users / API] Remove Organization User Failure',
  (error: string) => ({ error }),
);

export const addUserSuccessAction = createAction(
  '[Admin Organization / Users / API] Add Organization User Success',
  (user: OrganizationUserInternalModel) => ({
    user,
  }),
);

export const addUserFailureAction = createAction(
  '[Admin Organization / Users / API] Add Organization User Failure',
  (error?: any) => ({ error }),
);

export const updateUserSuccessAction = createAction(
  '[Admin Organization / Users / API] Update Organization User Success',
  (user: OrganizationUserInternalModel) => ({
    user,
  }),
);

export const updateUserFailureAction = createAction(
  '[Admin Organization / Users / API] Update Organization User Failure',
  (error?: any) => ({ error }),
);

export const resetPasswordSuccessAction = createAction(
  '[Admin Organization / Users / API] Reset Password Success',
  (user: OrganizationUserInternalModel) => ({ user }),
);

export const resetPasswordFailureAction = createAction(
  '[Admin Organization / Users / API] Reset Password Failure',
  (error: string) => ({ error }),
);
