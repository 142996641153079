import { createAction } from '@ngrx/store';
import { LightInternalModel } from '@spog-ui/shared/models/lights';
import {
  UtilityServiceGQLModel,
  UtilityServiceViewModel,
} from '@spog-ui/shared/models/utility-services';
import { ScheduledUtilityRateGQLModel } from '@spog-ui/shared/models/scheduled-utility-rates';
import { IndieSensorGQLModel } from '@spog-ui/shared/models/indie-sensors';

export const loadSuccessAction = createAction(
  '[Utility Services API] Load Success',
  (utilityServices: (UtilityServiceGQLModel & ScheduledUtilityRateGQLModel)[]) => ({
    utilityServices,
  }),
);
export const loadFailureAction = createAction(
  '[Utility Services API] Load Failure',
  (error?: any) => ({ error }),
);

export const deleteUtilityServiceSuccess = createAction(
  '[Utility Services API] Delete Utility Service Success',
);
export const deleteUtilityServiceFailure = createAction(
  '[Utility Services API] Delete Utility Service Failure',
  (utilityService: UtilityServiceViewModel, error: any) => {
    return { utilityService, error };
  },
);

export const confirmDeleteUtilityServiceAction = createAction(
  '[Utility Services API] Confirm Delete Utility Service',
  (utilityService: UtilityServiceViewModel) => {
    return { utilityService };
  },
);

export const cancelDeleteUtilityServiceAction = createAction(
  '[Utility Services API] Cancel Delete Utility Services',
);

export const retryDeleteDeleteUtilityServiceAction = createAction(
  '[Utility Services API] Retry Delete Utility Services',
  (utilityService: UtilityServiceViewModel) => {
    return { utilityService };
  },
);

export const createUtilityServiceSuccess = createAction(
  '[Utility Services API] Create Utility Service Success',
);
export const createUtilityServiceFailure = createAction(
  '[Utility Services API] Create Utility Service Failure',
  (error?: any) => ({ error }),
);

export const updateUtilityServiceSuccess = createAction(
  '[Utility Services API] Update Utility Service Success',
);
export const updateUtilityServiceFailure = createAction(
  '[Utility Services API] Update Utility Service Failure',
  (error?: any) => ({ error }),
);

export const loadUtilityServicesAndThingsSuccessAction = createAction(
  '[Utility Services API] Load Utility Services and Things Success',
  (
    utilityServices: (UtilityServiceGQLModel & ScheduledUtilityRateGQLModel)[],
    indieSensors: IndieSensorGQLModel[],
    lights: LightInternalModel[],
  ) => ({
    utilityServices,
    indieSensors,
    lights,
  }),
);
export const loadUtilityServicesAndThingsFailureAction = createAction(
  '[Utility Services API] Load Utility Services and Things Failure',
  (error?: any) => ({ error }),
);
export const createScheduledUtilityRateSuccess = createAction(
  '[Utility Services API] Create Scheduled Utility Rate Success',
);
export const createScheduledUtilityRateFailure = createAction(
  '[Utility Services API] Create Scheduled Utility Rate Failure',
  (error?: any) => ({ error }),
);
export const deleteScheduledUtilityRateSuccessAction = createAction(
  '[Utility Services API] Delete Scheduled Utility Rate Success',
);
export const deleteScheduledUtilityRateFailureAction = createAction(
  '[Utility Services API] Delete Scheduled Utility Rate Failure',
  (error?: any) => ({ error }),
);
