import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SuiEmptyStateModule, ButtonComponent } from '@spog-ui/shared/components';
import { PageNotFoundComponent } from './page-not-found.component';

@NgModule({
  imports: [CommonModule, RouterModule, SuiEmptyStateModule, ButtonComponent],
  declarations: [PageNotFoundComponent],
  exports: [PageNotFoundComponent],
})
export class PageNotFoundComponentModule {}
