import { createAction } from '@ngrx/store';
import { UserFormModel } from '@spog-ui/shared/models/users';

export const enterAction = createAction('[Add User Page] Enter');
export const saveAction = createAction(
  '[Add User Page] Add User',
  (user: UserFormModel) => ({
    user,
  }),
);
export const cancelAction = createAction('[Add User Page] Cancel');
