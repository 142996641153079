import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import {
  BehaviorControlContainerComponentModule,
  BehaviorFormComponentModule,
  BehaviorLicenseMessageModule,
  BehaviorSelectComponentModule,
  BFBasicColorComponentModule,
  BFDimmerComponentModule,
  BFDLHComponentModule,
  BFFadeComponentModule,
  BFNoneComponentModule,
  BFOccupancyOnlyComponentModule,
  BFOffComponentModule,
  BFOnComponentModule,
  BFPaparazziComponentModule,
  BFPaparazziMonochromeComponentModule,
  BFPhotocellAndOccupancyComponentModule,
  BFPhotocellAndSwitchComponentModule,
  BFPhotocellOnlyComponentModule,
  BFPinwheel5ComponentModule,
  BFPinwheelComponentModule,
  BFRandomComponentModule,
  BFRandomMonochromeComponentModule,
  BFSparkleComponentModule,
  BFSparkleMonochromeComponentModule,
  BFSwitchAndOccupancyComponentModule,
  BFSwitchAndVacancyComponentModule,
  BFSwitchOnlyComponentModule,
  BFSwitchPhotocellAndOccupancyComponentModule,
  BFSwitchPhotocellAndVacancyComponentModule,
  BFSwitchWithBlinkComponentModule,
  SpeedControlComponentModule,
  TimeoutControlComponentModule,
} from './components';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,

    BehaviorControlContainerComponentModule,
    BehaviorFormComponentModule,
    BehaviorSelectComponentModule,
    BehaviorLicenseMessageModule,

    SpeedControlComponentModule,
    TimeoutControlComponentModule,

    BFBasicColorComponentModule,
    BFDimmerComponentModule,
    BFDLHComponentModule,
    BFFadeComponentModule,
    BFNoneComponentModule,
    BFOccupancyOnlyComponentModule,
    BFOffComponentModule,
    BFOnComponentModule,
    BFPaparazziComponentModule,
    BFPaparazziMonochromeComponentModule,
    BFPhotocellAndOccupancyComponentModule,
    BFPhotocellAndSwitchComponentModule,
    BFPhotocellOnlyComponentModule,
    BFPinwheelComponentModule,
    BFPinwheel5ComponentModule,
    BFRandomComponentModule,
    BFRandomMonochromeComponentModule,
    BFSparkleComponentModule,
    BFSparkleMonochromeComponentModule,
    BFSwitchAndOccupancyComponentModule,
    BFSwitchAndVacancyComponentModule,
    BFSwitchOnlyComponentModule,
    BFSwitchPhotocellAndOccupancyComponentModule,
    BFSwitchPhotocellAndVacancyComponentModule,
    BFSwitchWithBlinkComponentModule,
  ],
  exports: [
    BehaviorControlContainerComponentModule,
    BehaviorFormComponentModule,
    BehaviorSelectComponentModule,
    BehaviorLicenseMessageModule
  ],
})
export class BehaviorsModule {}
