import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteSelectFormModule } from '../site-select-form';
import { SiteSelectorComponent } from './site-selector.component';

@NgModule({
  imports: [CommonModule, SiteSelectFormModule],
  declarations: [SiteSelectorComponent],
  exports: [SiteSelectorComponent],
})
export class SiteSelectorComponentModule {}
