import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  CardSelectComponentModule,
  provideFormErrors,
  SuiFormsModule,
  SuiValidatorsModule,
} from '@spog-ui/shared/components';
import { RouterModule } from '@angular/router';
import {
  Sense420FormComponent,
  ValidateIsGreaterThanDirective,
} from './sense-420-form.component';
import { PageNotFoundComponentModule } from '@spog-ui/modules/page-not-found';
import { UniqueSnapaddrsValidatorModule } from '@spog-ui/shared/directives/unique-snapaddrs-validator';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    RouterModule,
    SuiValidatorsModule,
    SuiFormsModule,
    CardSelectComponentModule,
    PageNotFoundComponentModule,
    UniqueSnapaddrsValidatorModule,
  ],
  declarations: [Sense420FormComponent, ValidateIsGreaterThanDirective],
  exports: [Sense420FormComponent],
  providers: [provideFormErrors(getFormErrors)],
})
export class Sense420FormComponentModule {}

export function getFormErrors() {
  return {
    default: {
      required: 'This field is required.',
      suiSnapAddress:
        'A valid SNAP Address must be a hex value of at least 6 digits with optional period and colon delimiters (ex:"a18ca9", "04:3E:82").',
      spogIsLessThanOrEqualTo: 'Value must be greater than the minimum value',
      spogSnapaddrIsNotUnique: 'A device with this SNAP Address already exists',
    },
  };
}
