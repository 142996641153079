import { createAction } from '@ngrx/store';
import { TriggerFormModel } from '@spog-ui/shared/models/triggers';

export const enterAction = createAction(
  '[Edit Trigger Page] Enter',
  (triggerId: string) => ({ triggerId }),
);

export const cancelAction = createAction('[Edit Trigger Page] Cancel');

export const selectSourceAction = createAction(
  '[Edit Trigger Page] Select Source',
  (sensorId: string) => ({ sensorId }),
);

export const editTriggerAction = createAction(
  '[Edit Trigger Page] Edit Trigger',
  (id: string, trigger: TriggerFormModel) => ({ id, trigger }),
);
