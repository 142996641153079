import { createAction } from '@ngrx/store';
import {
  ThermostatControlModeInternalModel,
  ThermostatViewModel,
} from '@spog-ui/shared/models/thermostats';

export const enterAction = createAction('[Thermostat Details Page] Enter');

export const closeAction = createAction('[Thermostat Details Page] Close');

export const controlThermostatAction = createAction(
  '[Thermostat Details Page] Control Thermostat',
  (thermostat: ThermostatViewModel, controlMode: ThermostatControlModeInternalModel) => ({
    thermostat,
    controlMode,
  }),
);
export const retryControlThermostatAction = createAction(
  '[Thermostat Details Page] Retry Control Thermostat',
  (thermostat: ThermostatViewModel, controlMode: ThermostatControlModeInternalModel) => ({
    thermostat,
    controlMode,
  }),
);
export const deleteThermostatAction = createAction(
  '[Thermostat Details Page] Delete Thermostat',
  (thermostatId: string) => ({ thermostatId }),
);
export const confirmDeleteThermostatAction = createAction(
  '[Thermostat Details Page] Confirm Delete Thermostat',
  (thermostatId: string) => ({ thermostatId }),
);
export const cancelDeleteThermostatAction = createAction(
  '[Thermostat Details Page] Cancel Delete Thermostat',
);
export const pingThermostatAction = createAction(
  '[Thermostat Details] Ping Thermostat',
  (thermostat: ThermostatViewModel) => {
    return { thermostat };
  },
);
export const checkSignalStrengthAction = createAction(
  '[Thermostat Details] Check Thermostat Signal Strength',
  (thermostat: ThermostatViewModel) => {
    return { thermostat };
  },
);

export const applyClimateSceneAction = createAction(
  '[Thermostat Details] Apply Climate Scene',
  (climateSceneId: string) => ({ climateSceneId }),
);
