import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import {
  SocketNotification,
  SocketNotificationTypes,
} from '@spog-ui/shared/models/websocket-notifications';

export type SocketFactory = new (url: string) => WebSocket;

export const SOCKET_FACTORY = new InjectionToken<SocketFactory>('Socket Factory');

@Injectable()
export class SocketConnectionFactory {
  constructor(@Inject(SOCKET_FACTORY) private socketFactory: SocketFactory) {}

  /**
   * This wraps the WebSocket browser API into an observable of SocketNotifications.
   * This would be a good candidate to open source. There is a similar piece of code
   * in SimplySnap OnPrem UI.
   */
  createConnection<Output, Input>(url: string, messages$: Observable<Input>) {
    return new Observable((observer: Observer<SocketNotification<Output>>) => {
      const connection = new this.socketFactory(url);
      let messageSubscription: Subscription;

      connection.addEventListener('open', () => {
        messageSubscription = messages$.subscribe(message =>
          connection.send(JSON.stringify(message)),
        );

        observer.next({
          type: SocketNotificationTypes.Open,
        });
      });

      connection.addEventListener('close', () =>
        observer.next({
          type: SocketNotificationTypes.Close,
        }),
      );

      connection.addEventListener('message', message =>
        observer.next({
          type: SocketNotificationTypes.Message,
          payload: JSON.parse(message.data),
        }),
      );

      return () => {
        connection.close();

        if (messageSubscription) {
          messageSubscription.unsubscribe();
        }
      };
    });
  }
}
