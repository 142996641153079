import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'spog-drawer',
  template: `
    <nav [class.open]="open">
      <div class="scrollArea">
        <ng-content></ng-content>
      </div>
      <spog-nav-footer></spog-nav-footer>
    </nav>
    
    @if (open) {
      <div
        class="overlay"
        (click)="onClose()"
        [@overlayState]="'active'"
      ></div>
    }
    `,
  styles: [
    `
      nav {
        width: 280px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--color-background-card);
        transform: translate3d(-280px, 0, 0);
        transition: transform 250ms;
        z-index: 905;
      }

      nav.open {
        transform: translate3d(0, 0, 0);
      }

      .scrollArea {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }

      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.36);
        z-index: 900;
      }
    `,
  ],
  animations: [
    trigger('overlayState', [
      state(
        'active',
        style({
          opacity: 1,
        }),
      ),
      transition('void => *', [style({ opacity: 0 }), animate(250)]),
      transition('* => void', [animate(100, style({ opacity: 0 }))]),
    ]),
  ],
})
export class DrawerComponent {
  @Input() open = false;
  @Output() close = new EventEmitter();

  onClose(): void {
    this.close.emit();
  }
}
