import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  UtilityServicesApiActions,
  UtilityServicesPageActions,
} from '@spog-ui/utility-services/actions';
import { concatMap, map, mergeMap, takeUntil } from 'rxjs/operators';
import { DialogService } from '@spog-ui/shared/components';
import { PromptService } from '@sui/prompt';
import { ScheduleUtilityRateModalComponent } from '../components/schedule-utility-rate-modal';

@Injectable()
export class UtilityServicesPageEffects {
  constructor(
    readonly actions$: Actions,
    readonly dialogService: DialogService,
    readonly prompt: PromptService,
  ) {}

  showScheduleUtilityRateModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UtilityServicesPageActions.scheduleUtilityRateAction),
        mergeMap(() =>
          this.dialogService
            .open(ScheduleUtilityRateModalComponent)
            .pipe(
              takeUntil(
                this.actions$.pipe(
                  ofType(
                    UtilityServicesPageActions.cancelSchedulingUtilityRateAction,
                    UtilityServicesApiActions.createScheduledUtilityRateSuccess,
                  ),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );

  promptToDeleteScheduledUtilityRate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UtilityServicesPageActions.deleteScheduledUtilityRateAction),
      concatMap(action => {
        return this.prompt
          .open({
            title: 'Delete Scheduled Utility Rate',
            description: 'Are you sure you want to delete the scheduled utility rate?',
            cancelLabel: 'Cancel',
            confirmLabel: 'Delete',
            confirmColor: 'warn',
          })
          .pipe(
            map(didConfirm => {
              return didConfirm
                ? UtilityServicesPageActions.confirmDeleteAction(
                    action.scheduledUtilityRate,
                  )
                : UtilityServicesPageActions.cancelDeleteAction(
                    action.scheduledUtilityRate,
                  );
            }),
          );
      }),
    ),
  );
}
