import { createAction } from '@ngrx/store';

export const replaceFloorPlanAction = createAction('[Map Settings] Replace Floor Plan');
export const removeFloorPlanAction = createAction('[Map Settings] Remove Floor Plan');
export const closeSettingsAction = createAction('[Map Settings] Close Settings');

export type Union =
  | ReturnType<typeof replaceFloorPlanAction>
  | ReturnType<typeof removeFloorPlanAction>
  | ReturnType<typeof closeSettingsAction>;

export const Types = {
  ReplaceFloorPlan: replaceFloorPlanAction.type,
  RemoveFloorPlan: removeFloorPlanAction.type,
  CloseSettings: closeSettingsAction.type,
};
