import { createReducer, on } from '@ngrx/store';
import {
  ThermostatDetailsApiActions,
  ThermostatDetailsPageActions,
} from '@spog-ui/thermostat-details/actions';

export interface Shape {
  isLoading: boolean;
}

export const initialState: Shape = {
  isLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(
    ThermostatDetailsApiActions.loadThermostatDetailsSuccessAction,
    (): Shape => {
      return {
        ...initialState,
        isLoading: false,
      };
    },
  ),
  on(
    ThermostatDetailsPageActions.enterAction,
    (): Shape => {
      return {
        ...initialState,
        isLoading: true,
      };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
