import { createReducer, createSelector, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import {
  ScheduleApiActions,
  ScheduleDateActions,
  SchedulePageActions,
} from '@spog-ui/schedule/actions';

export interface Shape {
  //Dates are stored as ISO strings and rehydrated to DateTimes on the way out.
  today: string;
  activeMonth: string;
  activeDay: string;
  showCalculatedAstroTimes: boolean;
  loading: boolean;
  error: string | null;
}

export const initialState: Shape = {
  today: '2018-01-22T00:00:00.000-04:00',
  activeMonth: '2018-01-22T00:00:00.000-04:00',
  activeDay: '2018-01-22T00:00:00.000-04:00',
  showCalculatedAstroTimes: true,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(SchedulePageActions.enterAction, state => {
    return { ...state, loading: true };
  }),
  on(ScheduleApiActions.loadFailureAction, (state, action) => {
    return { ...state, loading: false, error: action.error };
  }),
  on(ScheduleApiActions.loadSuccessAction, state => {
    return { ...state, loading: false, error: null };
  }),
  on(ScheduleDateActions.updateActiveDayAndMonthAction, (state, action) => {
    return { ...state, ...action.dayMonth };
  }),
  on(ScheduleDateActions.calculateDatesAction, (state, action) => {
    return { ...state, ...action.dates };
  }),
  on(ScheduleDateActions.updateTodayAction, (state, action) => {
    return { ...state, today: action.today };
  }),
  on(ScheduleDateActions.updateActiveDayAction, (state, action) => {
    return { ...state, activeDay: action.activeDay };
  }),
  on(ScheduleDateActions.updateActiveMonthAction, (state, action) => {
    return { ...state, activeMonth: action.activeMonth };
  }),
);

export const selectTodayString = (state: Shape) => state.today;
export const selectActiveMonthString = (state: Shape) => state.activeMonth;
export const selectActiveDayString = (state: Shape) => state.activeDay;
export const selectToday = createSelector(selectTodayString, fromISO);
export const selectActiveMonth = createSelector(selectActiveMonthString, fromISO);
export const selectActiveDay = createSelector(selectActiveDayString, fromISO);
export const selectShowCalculatedAstroTimes = (state: Shape) =>
  state.showCalculatedAstroTimes;
export const selectIsLoadingModels = (state: Shape) => state.loading;
export const selectError = (state: Shape) => state.error;

function fromISO(dateTimeString: string): DateTime {
  //This is going to parse the date from the ISO AND then return it in local time.
  //Since this isn't customer facing, this is fine since it's still globally accurate.
  //We can't use {setZone: true} here since it sets zone to a fixed offset rather than an IANA zone and won't respect DST.
  return DateTime.fromISO(dateTimeString, { setZone: true });
}
