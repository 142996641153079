import {
  UpdateControlZoneInput,
  CreateControlZoneInput,
  Application,
} from '@spog-ui/graphql/types';

import { ZoneInternalModel } from './zone.model';

/**
 * Control Zone Appropriate for Displaying in the Details View
 */
export interface ClimateZoneViewModel {
  id: string;
  name: string;
  controlMode: ClimateZoneControlModeInternalModel;
  thermostatIds: string[];
  totalAlarmingThermostats?: number;
}

export enum ClimateZoneFanMode {
  ON = 'ON',
  AUTO = 'AUTO',
}

export interface ClimateZoneControlModeInternalModel {
  coolF: number;
  heatF: number;
  fanMode: ClimateZoneFanMode;
}

export interface ControlZoneFormModel {
  id?: string;
  name: string;
  thermostats: string[];
}

export function toClimateZoneViewModelFromZoneInternalModel(
  zone: ZoneInternalModel,
): ClimateZoneViewModel {
  return {
    id: zone.id,
    name: zone.name,
    controlMode: toControlModeFromParameters(zone.behaviorParameters!),
    thermostatIds: zone.deviceIds,
  };
}

export function convertFanMode(mode: string): ClimateZoneFanMode {
  switch (mode) {
    case 'Auto':
      return ClimateZoneFanMode.AUTO;
    case 'On':
      return ClimateZoneFanMode.ON;
    default:
      throw new Error(`Unknown fan type: ${mode}`);
  }
}

export function toControlModeFromParameters(
  parameters: string,
): ClimateZoneControlModeInternalModel {
  const parsed = JSON.parse(parameters);

  return {
    coolF: parsed.cool_setpoint,
    heatF: parsed.heat_setpoint,
    fanMode: convertFanMode(parsed.fan),
  };
}

export function toCreateControlZoneInputFromForm(
  formModel: ControlZoneFormModel,
  siteId: string,
): CreateControlZoneInput {
  return {
    ...formModel,
    siteId,
    name: formModel.name,
    application: Application.CLIMATE,
    thermostats: formModel.thermostats,
  };
}

export function toUpdateControlZoneInputFromForm(
  formModel: ControlZoneFormModel,
): UpdateControlZoneInput {
  return {
    id: formModel.id!,
    name: formModel.name,
    thermostats: formModel.thermostats,
  };
}
