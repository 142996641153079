import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as SenseState from '@spog-ui/shared/state/sense';

@Component({
  selector: 'spog-no-indie-sensors',
  template: `
    @if (showGetStartedCard$ | async) {
      <sui-empty-state>
        <ng-template suiEmptyStateTitle> Get Started </ng-template>
        <ng-template suiEmptyStateMessage>
          No industrial sensors have been configured. Please add an industrial sensor to get
          started.
        </ng-template>
        <ng-template suiEmptyStateActions>
          <a
            routerLink="./add"
            suiButton
            class="addSensor"
            *suiIfUserCan="['ssc:siteAdmin']"
            >Add Industrial Sensor</a
            >
          </ng-template>
        </sui-empty-state>
      }
    `,
})
export class NoIndieSensorsComponent {
  constructor(private store: Store) {}
  showGetStartedCard$ = this.store.select(
    SenseState.selectIndieSensorsPageShowGettingStartedCard,
  );
}
