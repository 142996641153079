import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import { Knobs } from '@omni/knobs';
import { FormattedExecutionResult, GraphQLFormattedError } from 'graphql';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { GraphQLQuery } from './gql.tag';
import {
  processGraphQLErrorAction,
  processGraphQLHTTPErrorAction,
} from './graphql-error.action';

export const ENABLED_KNOBS = new InjectionToken('ENABLED_KNOBS');
export const GQL_URL = new InjectionToken('GRAPHQL_URL');

export function provideEnabledKnobs(knobs: number): Provider {
  return {
    provide: ENABLED_KNOBS,
    useValue: knobs,
  };
}

export function provideGqlUrl(url: string): Provider {
  return {
    provide: GQL_URL,
    useValue: url,
  };
}

export interface GraphQLResponse<T> extends FormattedExecutionResult<T> {
  data: T;
}

export class ApiErrorResponse<T> implements FormattedExecutionResult<T> {
  constructor(public data?: T, public errors?: readonly GraphQLFormattedError[]) {}
}

@Injectable({ providedIn: 'root' })
export class GraphQLClient {
  constructor(
    @Inject(ENABLED_KNOBS) private enabledKnobs: number,
    @Inject(GQL_URL) private url: string,
    private http: HttpClient,
    private readonly store: Store,
  ) {}

  getCorrelationIdFromResponse(response: any) {
    return response?.headers?.get('x-correlation-id');
  }

  logGraphQLErrorsInHTTPResponse(errorResponse: any) {
    /*
      Sample GraphQL error response (for schema validation failure):
      {
        "headers": {},
        "status": 400,
        "statusText": "OK",
        "url": "https://api.simplysnapcloud.com/graphql",
        "ok": false,
        "name": "HttpErrorResponse",
        "message": "Http failure response for https://api.simplysnapcloud.com/graphql: 400 OK",
        "error": {
            "errors": [
                {
                    "message": "Cannot query field \"something\" on type \"Query\".",
                    "extensions": {
                        "code": "GRAPHQL_VALIDATION_FAILED"
                    }
                }
            ]
        }
    } */

    /**
     * We want to use the following properties:
     *
     * - message: The error message to display
     * - headers: To retrieve the correlationId
     * - error: GraphQL sends an "errors" property in the body of the response when there is an error
     */

    const correlationId = this.getCorrelationIdFromResponse(errorResponse);

    if (
      errorResponse.error &&
      Object.prototype.hasOwnProperty.call(errorResponse.error, 'errors')
    ) {
      // GraphQL error, for sure

      this.store.dispatch(
        processGraphQLErrorAction({
          response: new ApiErrorResponse(
            errorResponse.error.data,
            errorResponse.error.errors,
          ),
          correlationId,
        }),
      );
    } else {
      // Some other kind of error

      this.store.dispatch(
        processGraphQLHTTPErrorAction({
          response: errorResponse,
          correlationId,
        }),
      );
    }
  }

  handleRawResponse<T>(rawResponse: HttpResponse<GraphQLResponse<T>>) {
    // GraphQL returns a 200 even for errors. These are nested within a property called `errors`.

    const correlationId = this.getCorrelationIdFromResponse(rawResponse);

    if (rawResponse === null || rawResponse.body === null) {
      return throwError(
        () => new Error(`empty response [correlationId: ${correlationId}]`),
      );
    }

    const responseBody = rawResponse.body;

    if (responseBody && Object.prototype.hasOwnProperty.call(responseBody, 'errors')) {
      this.store.dispatch(
        processGraphQLErrorAction({
          response: new ApiErrorResponse(responseBody.data, responseBody.errors),
          correlationId,
        }),
      );

      throw responseBody; // this is actually an error response
    } else if (!rawResponse.ok) {
      throw responseBody;
    }

    return of(responseBody);
  }

  query<T = any, V = Record<string, unknown>>(
    query: GraphQLQuery,
    variables?: V,
  ): Observable<GraphQLResponse<T>> {
    return this.http
      .post<GraphQLResponse<T>>(
        this.url,
        {
          query: query.build(Knobs as any, this.enabledKnobs),
          variables,
        },
        { observe: 'response' },
      )
      .pipe(
        /**
         * There are two paths for errors to come in from the graphql API:
         *   - as an error notification (4xx, 5xx)
         *   - in the body of a success response (unders 'errors', and might contain partial data)
         */

        catchError(errorResponse => {
          // This is an HTTP error response since we used `{ observe: 'response' }`

          if (errorResponse?.status !== 0) {
            /*
              A status of 0 occurs when the browser cancels the request, either when
              the pre-flight errors, or when the browser is prevented
              from making the request due to request blocking.

              This mostly happens when the request is made whilst a deployment is happening

              We are filtering these requests so that our error logs ignore them.
            */
            this.logGraphQLErrorsInHTTPResponse(errorResponse);
          }

          throw errorResponse;
        }),
        mergeMap(rawResponse => this.handleRawResponse(rawResponse)),
      );
  }
}
