import { createAction } from '@ngrx/store';
import { MapPositionerResult } from '@spog-ui/shared/models/map-positioners';

export const savePositionsSuccess = createAction(
  '[Map Positioner API] Save Positions Success',
  (result: MapPositionerResult) => ({ result }),
);
export const savePositionsFailure = createAction(
  '[Map Positioner API] Save Positions Failure',
  (result: MapPositionerResult, error?: any) => ({ result, error }),
);
