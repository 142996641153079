import { createReducer, on } from '@ngrx/store';
import {
  AddThermostatApiActions,
  AddThermostatPageActions,
  ThermostatsApiActions,
} from '@spog-ui/thermostats/actions';

export interface Shape {
  isLoading: boolean;
  isSaving: boolean;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
  isSaving: false,
};

export const reducer = createReducer(
  initialState,
  on(AddThermostatPageActions.enterAction, () => initialState),
  on(AddThermostatApiActions.loadAddThermostatPageModelsSuccessAction, state => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(
    AddThermostatApiActions.loadAddThermostatPageModelsFailureAction,
    (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    },
  ),
  on(AddThermostatPageActions.addThermostatAction, state => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(ThermostatsApiActions.createThermostatSuccess, state => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(ThermostatsApiActions.createThermostatFailure, state => {
    return {
      ...state,
      isSaving: false,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSaving = (state: Shape) => state.isSaving;
export const selectError = (state: Shape) => state.error;
