import { Inject, Injectable, InjectionToken } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect } from '@ngrx/effects';
import { defer, EMPTY, interval, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AppUpdatesApiActions } from '@spog-ui/app-updates/actions';

export const CHECK_FOR_UPDATES_INTERVAL = new InjectionToken<Observable<any>>(
  'CHECK_FOR_UPDATES_INTERVAL',
  {
    providedIn: 'root',
    factory: () => interval(/* Every Five Minutes */ 5 * 60_000),
  },
);

@Injectable()
export class AppUpdatesApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly snackbar: MatSnackBar,
    readonly swUpdate: SwUpdate,
    @Inject(CHECK_FOR_UPDATES_INTERVAL)
    readonly checkForUpdatesInterval: Observable<number>,
  ) {}

  checkForAppUpdated$ = createEffect(
    () =>
      defer(() => {
        if (sessionStorage.getItem('appUpdated')) {
          this.snackbar.open(
            'Software update has been successfully applied.',
            'Dismiss',
            {
              duration: 7_500,
            },
          );
          sessionStorage.removeItem('appUpdated');
        }

        return EMPTY;
      }),
    { dispatch: false },
  );

  listenForUpdateAvailable$ = createEffect(() =>
    defer(() => {
      if (this.swUpdate.isEnabled) {
        return this.swUpdate.versionUpdates.pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          map(() => AppUpdatesApiActions.updateAvailable()),
        );
      }

      return EMPTY;
    }),
  );

  checkForUpdatesOnInterval$ = createEffect(
    () =>
      this.checkForUpdatesInterval.pipe(
        filter(() => this.swUpdate.isEnabled),
        tap(() => this.swUpdate.checkForUpdate()),
      ),
    { dispatch: false },
  );
}
