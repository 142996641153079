import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SuiAnimateFab } from '@spog-ui/shared/components';
import { IndieSensorsPage } from '@spog-ui/indie-sensors/actions';
import * as SenseState from '@spog-ui/shared/state/sense';

@Component({
  selector: 'spog-indie-sensors-page',
  template: `
    <sui-page>
      <div class="content">
        @if (loading$ | async) {
          <div class="loading">
            <sui-spinner></sui-spinner>
          </div>
        } @else {
          <spog-no-indie-sensors></spog-no-indie-sensors>
          <a
            routerLink="./add"
            suiExtendedFab
            @suiAnimateFab
            color="accent"
            icon="add"
            *suiIfUserCan="['ssc:siteAdmin']"
            >
            Add Industrial Sensor
          </a>
          <sui-charm-filter
            type="Industrial Sensors"
            [term]="searchTerm$ | async"
            [options]="indieSensorNames$ | async"
            (filter)="onSearch($event)"
          ></sui-charm-filter>
          <spog-indie-sensors-page-list></spog-indie-sensors-page-list>
          <spog-indie-sensors-page-no-results></spog-indie-sensors-page-no-results>
          <router-outlet></router-outlet>
        }
      </div>
    </sui-page>
    `,
  styles: [
    `
      /* :host {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 960px;
        padding: 24px 16px 96px; 
      } */

      .content {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 960px;
      }

      sui-spinner {
        width: 50px;
        display: block;
        margin: 60px auto 0;
      }
    `,
  ],
  animations: [SuiAnimateFab],
})
export class IndieSensorsPageComponent {
  constructor(private store: Store) {
    this.store.dispatch(IndieSensorsPage.enterAction());
  }

  loading$ = this.store.select(SenseState.selectIndieSensorsPageIsLoading);
  showIndieSensors$ = this.store.select(
    SenseState.selectIndieSensorsPageShowIndieSensors,
  );
  indieSensorNames$ = this.store.select(SenseState.selectIndieSensorNames);
  searchTerm$ = this.store.select(SenseState.selectIndieSensorsPageSearchTerm);

  onSearch($event: string) {
    this.store.dispatch(IndieSensorsPage.searchAction($event));
  }
}
