import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { NoIndieSensorsComponentModule } from '@spog-ui/indie-sensors';
import { AddUtilityServicePageComponent } from './add-utility-service-page.component';
import { UtilityServiceFormComponentModule } from '../utility-service-form';

@NgModule({
  imports: [
    CommonModule,
    NoIndieSensorsComponentModule,
    SuiSpinnerModule,
    UtilityServiceFormComponentModule,
  ],
  declarations: [AddUtilityServicePageComponent],
  exports: [AddUtilityServicePageComponent],
})
export class AddUtilityServicePageComponentModule {}
