import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ClimateMapPageApiActions } from '@spog-ui/climate-map-layer/actions';
import {
  AddThermostatApiActions,
  EditThermostatApiActions,
  ThermostatsApiActions,
} from '@spog-ui/thermostats/actions';
import {
  ThermostatDetailsApiActions,
  ThermostatDetailsPageActions,
} from '@spog-ui/thermostat-details/actions';
import { ClimateZoneModalApiActions } from '@spog-ui/climate-zones/actions';
import { SocketActions } from '@spog-ui/socket/actions';
import { ThermostatInternalModel } from '@spog-ui/shared/models/thermostats';
import { ClimateZoneDetailsApiActions } from '@spog-ui/climate-zone-details/actions';

export type Shape = EntityState<ThermostatInternalModel>;

export const adapter = createEntityAdapter<ThermostatInternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    ThermostatsApiActions.loadThermostatsPageModelsSuccessAction,
    AddThermostatApiActions.loadAddThermostatPageModelsSuccessAction,
    EditThermostatApiActions.loadEditThermostatPageModelsSuccessAction,
    ClimateZoneModalApiActions.loadClimateZoneModalModelsSuccessAction,
    ClimateZoneDetailsApiActions.loadClimateZoneDetailsThermostatsSuccessAction,
    (state, action) => adapter.setAll(action.thermostats, state),
  ),
  on(ThermostatDetailsApiActions.loadThermostatDetailsSuccessAction, (state, action) =>
    adapter.upsertOne(action.thermostat, state),
  ),
  on(ClimateMapPageApiActions.loadClimateMapPageModelsSuccessAction, (state, action) =>
    adapter.setAll(action.models.thermostats, state),
  ),
  on(
    ThermostatDetailsPageActions.controlThermostatAction,
    ThermostatDetailsPageActions.retryControlThermostatAction,
    (state, action) => {
      return adapter.updateOne(
        {
          id: action.thermostat.id,
          changes: {
            controlMode: action.controlMode,
          },
        },
        state,
      );
    },
  ),
  on(ThermostatDetailsApiActions.controlThermostatFailureAction, (state, action) => {
    return adapter.updateOne(
      {
        id: action.thermostat.id,
        changes: {
          controlMode: action.thermostat.controlMode,
        },
      },
      state,
    );
  }),
  on(SocketActions.bulkFloorPlanPlacementAction, (state, action) => {
    const updates = action.placedThings.map(thing => ({
      id: thing.id,
      changes: {
        floorPlanId: thing.floorPlanId,
        floorPlanX: thing.floorPlanX,
        floorPlanY: thing.floorPlanY,
      },
    }));
    return adapter.updateMany(updates, state);
  }),
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
