import { createAction } from '@ngrx/store';
import { EmailGroupInternalModel } from '@spog-ui/shared/models/email-groups';

export const loadEmailGroupsSuccessAction = createAction(
  '[Email Groups State] Load Email Groups Success',
  (emailGroups: EmailGroupInternalModel[]) => ({ emailGroups }),
);

export const deleteEmailGroupAction = createAction(
  '[Email Groups State] Delete Email Group',
  (emailGroup: EmailGroupInternalModel) => ({ emailGroup }),
);
