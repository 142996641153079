import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  defer,
  EMPTY,
  Observable,
  ObservableInput,
  ObservedValueOf,
  OperatorFunction,
} from 'rxjs';
import {
  filter,
  first,
  groupBy,
  map,
  mergeMap,
  skip,
  withLatestFrom,
} from 'rxjs/operators';
import * as CoreState from '@spog-ui/shared/state/core';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';
import { Products, PermissionGroupSiteRole } from '@spog-ui/graphql/types';

const ROLE_TO_PERMISSIONS = {
  [PermissionGroupSiteRole.MEMBER]: ['ssc:siteAdmin'],
  [PermissionGroupSiteRole.SCENE_AND_SCHEDULE_MEMBER]: ['ssc:schedule-and-scene'],
  [PermissionGroupSiteRole.SCENE_USER]: ['ssc:sceneUser'],
  [PermissionGroupSiteRole.VIEWER]: ['ssc:siteViewer'],
  [PermissionGroupSiteRole.NONE]: [],
};

@Injectable()
export class SiteService {
  constructor(private store: Store) {}

  observe(): Observable<SiteInternalModel | null | undefined> {
    return this.store.select(CoreState.selectSelectedSite);
  }

  observeEnabledProducts(): Observable<Products[]> {
    return this.store.select(CoreState.selectSelectedSiteProducts);
  }

  observeTimezone(): Observable<string> {
    return this.store.select(CoreState.selectSelectedSiteTimeZone);
  }

  get(): SiteInternalModel | null {
    let site: SiteInternalModel | null | undefined = null;

    this.observe()
      .pipe(first())
      .subscribe(_site => (site = _site));

    return site;
  }

  getEnabledProducts(): Products[] {
    let products: Products[] = [];

    this.observeEnabledProducts()
      .pipe(first())
      .subscribe(_products => (products = _products));

    return products;
  }

  getRole(): PermissionGroupSiteRole {
    const site = this.get();

    return site?.mySiteRole ? site.mySiteRole : PermissionGroupSiteRole.NONE;
  }

  getPermissions(): Observable<string[]> {
    return this.observe().pipe(
      map((site: SiteInternalModel | null | undefined) => {
        if (!site) {
          return null;
        }
        return ROLE_TO_PERMISSIONS[site?.mySiteRole ?? PermissionGroupSiteRole.NONE];
      }),
      filter((p): p is string[] => p !== null),
    );
  }

  getId(): string | null {
    const site = this.get();

    return site ? site.id : null;
  }

  supports(product: Products) {
    return this.getEnabledProducts().includes(product);
  }

  getCurrentSiteHomePage() {
    return this.getHomePage(this.getId() ?? '');
  }

  getHomePage(siteId: string) {
    const siteRole = this.getRole();

    //  A user with Scene Only site role or Scene and Schedule site role should be routed to lighting scenes page
    if (
      siteRole === PermissionGroupSiteRole.SCENE_USER ||
      siteRole === PermissionGroupSiteRole.SCENE_AND_SCHEDULE_MEMBER
    ) {
      if (this.supports(Products.ILLUMINATE)) {
        return `/site/${siteId}/lighting/scenes`;
      } else {
        return '/404';
      }
    }

    if (this.supports(Products.SENSE)) {
      return `/site/${siteId}/map/equipment`;
    }
    if (this.supports(Products.ILLUMINATE)) {
      return `/site/${siteId}/map/lighting`;
    }
    if (this.supports(Products.CLIMATE)) {
      return `/site/${siteId}/map/climate`;
    }
    if (this.supports(Products.SENSE_POWER) || this.supports(Products.ILLUMINATE_POWER)) {
      return `/site/${siteId}/power/comparison`;
    }
    if (this.supports(Products.REMOTE_ACCESS)) {
      return `/site/${siteId}/site-controllers`;
    }

    return '/404';
  }

  getToMapPage(siteId: string) {
    if (this.supports(Products.SENSE)) {
      return `/site/${siteId}/map/equipment`;
    }
    if (this.supports(Products.ILLUMINATE)) {
      return `/site/${siteId}/map/lighting`;
    }
    if (this.supports(Products.CLIMATE)) {
      return `/site/${siteId}/map/climate`;
    }

    return '/404';
  }

  getTimezone() {
    let timezone = '';

    this.observeTimezone()
      .pipe(first())
      .subscribe(tz => (timezone = tz));

    return timezone;
  }
}

export function withSiteId<V, U extends ObservableInput<any>>(
  site$: () => Observable<SiteInternalModel | null | undefined>,
  operator: OperatorFunction<[action: V, siteId: string], ObservedValueOf<U>>,
): OperatorFunction<V, ObservedValueOf<U>> {
  return source$ =>
    source$.pipe(
      withLatestFrom(defer(site$).pipe(map(site => site?.id))),
      groupBy(([, siteId]) => siteId),
      mergeMap(inputWithSiteId$ => {
        if (!inputWithSiteId$.key) return EMPTY;

        return (inputWithSiteId$ as Observable<[V, string]>).pipe(operator);
      }),
    );
}
