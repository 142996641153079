import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'sui-spinner',
    template: `
    <mat-spinner
      [color]="color"
      [diameter]="diameter"
      [strokeWidth]="strokeWidth"
      mode="indeterminate"
    ></mat-spinner>
  `,
    styles: [
        `
      :host {
        display: inline-block;
      }

      mat-spinner {
        width: 100%;
        height: 100%;
      }
    `,
    ],
    standalone: true,
    imports: [MatProgressSpinner],
})
export class SpinnerComponent implements OnInit {
  @Input() color = 'primary';
  diameter = 100;
  strokeWidth = 3;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const offsetWidth = (this.elementRef.nativeElement as HTMLElement).offsetWidth;

    if (offsetWidth > 0) {
      this.diameter = offsetWidth;
    }
  }
}

export const SPINNER_DIRECTIVES = [SpinnerComponent];
