import { createAction } from '@ngrx/store';
import { DateDomainModel } from '@spog-ui/shared/models/power-source-comparisons';

export const calculateDateRangeAction = createAction(
  '[Browser Date API] Calculate Date Range',
  (dates: DateDomainModel) => {
    return { dates };
  },
);
export const detectTimezoneAction = createAction(
  '[Browser Date API] Detect Timezone',
  (timezone: string) => {
    return { timezone };
  },
);
