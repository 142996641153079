import { createAction } from '@ngrx/store';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const selectAction = createAction(
  '[Site Selector] Select Site',
  (site: SiteInternalModel) => {
    return { site };
  },
);

export const openDialogAction = createAction('[Site Selector] Open Dialog');

export const cancelSiteSelectAction = createAction('[Site Selector] Cancel Site Select');

export const cancelSiteChangeAction = createAction('[Site Selector] Cancel Site Change');
