import { createAction } from '@ngrx/store';
import { Bridge485FormOutputModel } from '@spog-ui/shared/models/bridge-485s';

export const enterAction = createAction(
  '[Edit Bridge 485] Enter',
  (bridge485Id: string) => ({ bridge485Id }),
);

export const saveAction = createAction(
  '[Edit Bridge 485] Save',
  (bridge485: Bridge485FormOutputModel & { id: string }) => ({ bridge485 }),
);

export const confirmSaveAction = createAction(
  '[Edit Bridge 485] Confirm Save',
  (bridge485: Bridge485FormOutputModel & { id: string }) => ({ bridge485 }),
);

export const cancelSaveAction = createAction('[Edit Bridge 485] Cancel Save');

export const cancelAction = createAction('[Edit Bridge 485] Cancel');
