import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LightStatusTrayComponent } from './light-status-tray.component';
import { CommunicationStatusComponent } from './communication-status.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [
    LightStatusTrayComponent,
    CommunicationStatusComponent,
  ],
  exports: [LightStatusTrayComponent],
})
export class LightStatusTrayComponentModule {}
