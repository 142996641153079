import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { SpeedControlComponent } from './speed-control.component';

@NgModule({
  imports: [ReactiveFormsModule, MatRadioModule],
  declarations: [SpeedControlComponent],
  exports: [SpeedControlComponent],
})
export class SpeedControlComponentModule {}
