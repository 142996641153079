import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'sui-countdown',
  template: `
    @if (hoursToNow) {
      <span id="hours">
        {{ hoursToNow }} hour@if (hoursToNow > 1) {
        s
        },</span
        >
      }
      @if (minutesToNow) {
        <span id="minutes">
          {{ minutesToNow }} minute@if (minutesToNow > 1) {
          s
          }, and</span
          >
        }
        <span id="seconds"> {{ secondsToNow }} seconds</span>
    `,
  styles: [
    `
      .span {
        margin: 0 0 0 16px;
      }
    `,
  ],
})
export class CountdownComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  timestamp: string;

  private subscription: Subscription;

  public dateNow = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference: number;
  public secondsToNow: number;
  public minutesToNow: number;
  public hoursToNow: number;
  private getTimeDifference() {
    this.timeDifference = new Date(this.timestamp).getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference: number) {
    this.secondsToNow = Math.floor(
      (timeDifference / this.milliSecondsInASecond) % this.SecondsInAMinute,
    );
    this.minutesToNow = Math.floor(
      (timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour)) %
        this.SecondsInAMinute,
    );
    this.hoursToNow = Math.floor(
      (timeDifference /
        (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute)) %
        this.hoursInADay,
    );
  }

  ngOnInit() {
    this.subscription = interval(1000).subscribe((x: any) => {
      this.getTimeDifference();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // Updates to the underlying state that feed this component
  // can occur inbetween the interval firing, which causes the values
  // to disappear for a (literal) split second
  ngOnChanges() {
    this.getTimeDifference();
  }
}
