import { Injectable } from '@angular/core';

@Injectable()
export class ImageLoader {
  async loadImage(src: string): Promise<HTMLImageElement> {
    throw new Error(
      'Not Implemented. You must provide either the browser or Node implementation.',
    );
  }
}
