import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  GraphQLAPIService,
  InviteOrganizationUserInput,
  OrganizationRole,
  RemoveOrganizationUserInput,
  UpdateOrganizationUserInput,
} from '@spog-ui/graphql/types';
import {
  OrganizationUserInternalModel,
  toOrganizationUserInternalModelFromGQL,
} from '@spog-ui/shared/models/organization-users';
import {
  OrganizationInternalModel,
  toOrganizationInternalModelFromGQL,
} from '@spog-ui/shared/models/organizations';

@Injectable({ providedIn: 'root' })
export class OrganizationUsersApiService {
  constructor(readonly gql: GraphQLAPIService) {}

  getUsersByOrganization(
    organizationId: string,
  ): Observable<OrganizationUserInternalModel[]> {
    return this.gql.getUsersByOrganization({ organizationId }).pipe(
      map(response => {
        if (!response.organization) {
          throw new Error('No organization for id');
        }

        return response.organization!.users.edges.map(edge =>
          toOrganizationUserInternalModelFromGQL(edge),
        );
      }),
    );
  }

  removeOrganizationUser(
    organizationId: string,
    userId: string,
  ): Observable<OrganizationInternalModel> {
    const input: RemoveOrganizationUserInput = {
      organizationId: organizationId,
      userId: userId,
    };

    return this.gql.removeOrganizationUser({ input }).pipe(
      map(response => {
        return toOrganizationInternalModelFromGQL(response.removeOrganizationUser);
      }),
    );
  }

  inviteOrganizationUser(
    organizationId: string,
    email: string,
    organizationRole: OrganizationRole,
  ): Observable<OrganizationUserInternalModel> {
    const input: InviteOrganizationUserInput = {
      email,
      organizationId,
      organizationRole,
    };
    return this.gql.inviteOrganizationUser({ input }).pipe(
      map(response => {
        return toOrganizationUserInternalModelFromGQL(response.inviteOrganizationUser);
      }),
    );
  }

  updateOrganizationUser(
    organizationId: string,
    userId: string,
    name: string,
    organizationRole: OrganizationRole,
  ): Observable<OrganizationUserInternalModel> {
    const input: UpdateOrganizationUserInput = {
      organizationId,
      userId,
      name,
      organizationRole,
    };
    return this.gql.updateOrganizationUser({ input }).pipe(
      map(response => {
        return toOrganizationUserInternalModelFromGQL(response.updateOrganizationUser);
      }),
    );
  }
}
