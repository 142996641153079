import { createSelector } from '@ngrx/store';
import { selectAllBridge485Templates } from '@spog-ui/shared/state/bridge-485-templates';
import { toBridge485TemplateViewModel } from '../models';
import {
  selectError,
  selectIsLoading,
  selectIsSaving,
  STATE_KEY,
} from './add-bridge-485-page.reducer';
import { selectFeature } from './constants';

export const selectAddPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectAddPageError = createSelector(selectAddPageFeatureState, selectError);
export const selectAddPageIsLoading = createSelector(
  selectAddPageFeatureState,
  selectIsLoading,
);
export const selectAddPageIsSaving = createSelector(
  selectAddPageFeatureState,
  selectIsSaving,
);

export const selectBridge485TemplateViewModels = createSelector(
  selectAllBridge485Templates,
  templates => templates.map(toBridge485TemplateViewModel),
);
