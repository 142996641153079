import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TriggersApiActions } from '@spog-ui/triggers/actions';
import { MetricHistoryInternalModel } from '@spog-ui/shared/models/triggers';

export type Shape = EntityState<MetricHistoryInternalModel>;

export const adapter = createEntityAdapter<MetricHistoryInternalModel>();

export const initialState: Shape = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    TriggersApiActions.loadAddTriggersPageSuccessAction,
    TriggersApiActions.loadEditTriggersPageSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.histories, state);
    },
  ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
