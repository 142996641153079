export type HexString = `#${string}${string}${string}${string}${string}`;

export type Color = [
  red: number,
  green: number,
  blue: number,
  amber: number,
  white: number,
];

export enum ColorPickerMode {
  PERCENT,
  BYTES,
}

export enum ColorChannels {
  RED = 0,
  GREEN,
  BLUE,
  AMBER,
  WHITE,
}

export type ColorSwatch = Color | null;
export type ColorPalette = ColorSwatch[];
export const COLOR_PALETTE_LENGTH = 12;
export const NUMBER_OF_FAST_COLORS = 4;
export const EMPTY_COLOR_PALETTE: ColorPalette = new Array(COLOR_PALETTE_LENGTH).fill(
  null,
);
/**
 * An arbitrarily-chosen color to use when no color is selected.
 * It clearly demonstrates the five colors and how the slider works,
 * especially important in OnPrem, where the theme color is bright
 * enough that the slide lanes are hard to see without colors in them.
 */
export const DEFAULT_COLOR: Color = [255, 128, 64, 16, 0];

/**
 * Converts a number into a HEX string
 *
 * @param value The number to convert
 * @returns The HEX string
 */
function toHexStringFromNumber(value: number): string {
  return value.toString(16).padStart(2, '0');
}

/**
 * Converts a color object into a HEX string
 *
 * @param color The color object to convert
 * @returns The HEX string
 */
export function toHexStringFromColor(color: Color): HexString {
  const [r, g, b, a, w] = color.map(value => toHexStringFromNumber(value));

  return `#${r}${g}${b}${a}${w}` as HexString;
}

/**
 * Converts a HEX string to a Color object.
 *
 * @param hexString The HEX string to convert.
 * @returns The Color object or null if the string is invalid.
 */
export function toColorFromHexString(hex: string): Color | null {
  const colorStrings = hex.slice(1).match(/.{2}/g);

  if (!colorStrings) {
    return null;
  }

  if (colorStrings.length !== 5 && colorStrings.length !== 3) {
    return null;
  }

  const [r, g, b, a = 0, w = 0] = colorStrings.map(x => parseInt(x, 16));

  const color: Color = [r, g, b, a, w];

  if (color.some(value => isNaN(value))) {
    return null;
  }

  return color;
}

/**
 * Converts a value from 0-255 to 0-100
 *
 * @param valueInBytes The value to convert
 * @returns The converted value
 */
export function toPercentFromBytes(valueInBytes: number): number {
  return Math.round((valueInBytes / 255) * 100);
}

/**
 * Converts a value from 0-100 to 0-255
 *
 * @param valueInPercent The value to convert
 * @returns The converted value
 */
export function toBytesFromPercent(valueInPercent: number): number {
  return Math.round((valueInPercent / 100) * 255);
}

export const enum LightColors {
  Red = 0xff0000,
  Green = 0x00ff00,
  Blue = 0x0000ff,
  Amber = 0xf3b38e,
  White = 0xffffff,
}

/**
 * Flattens a Color Palette so that there are no "null" values in
 * between each Color, and then adds nulls to the end of the array
 * until there are 12 colors in the array.
 *
 * @param palette The Color Palette to flatten
 * @returns The flattened Color Palette
 */
export function flattenPalette(palette: ColorPalette): ColorPalette {
  const flattenedPalette = palette
    .filter(color => color !== null)
    .slice(0, COLOR_PALETTE_LENGTH);
  const missingColors = Math.max(COLOR_PALETTE_LENGTH - flattenedPalette.length, 0);

  return [...flattenedPalette, ...Array(missingColors).fill(null)];
}

/**
 * Replaces the first "null" in the Color Palette with the given Color.
 *
 * @param palette The Color Palette to add the color to
 * @param color The color to add
 * @returns The Color Palette with the new color added
 */
export function addColorToPalette(palette: ColorPalette, color: Color): ColorPalette {
  const index = palette.indexOf(null);

  if (index === -1) {
    return palette;
  }

  return flattenPalette([...palette.slice(0, index), color, ...palette.slice(index + 1)]);
}

/**
 * Replace a given color swatch in the color palette with a new color, and
 * then flattens the palette.
 *
 * @param palette The color palette to replace the color in
 * @param color The color to replace
 * @param index The index of the color to replace
 * @returns The flattened color palette
 */
export function replaceColorInPalette(
  palette: ColorPalette,
  color: Color,
  index: number,
): ColorPalette {
  return flattenPalette([...palette.slice(0, index), color, ...palette.slice(index + 1)]);
}

export function reorderColorInPalette(
  palette: ColorPalette,
  sourceIndex: number,
  destinationIndex: number,
): ColorPalette {
  const color = palette[sourceIndex];
  const paletteWithColorRemoved = [
    ...palette.slice(0, sourceIndex),
    ...palette.slice(sourceIndex + 1),
  ];

  return flattenPalette([
    ...paletteWithColorRemoved.slice(0, destinationIndex),
    color,
    ...paletteWithColorRemoved.slice(destinationIndex),
  ]);
}

/**
 * Removes a color from the color palette and then flattens the palette.
 *
 * @param palette The color palette to remove the color from
 * @param index The index of the color to remove
 * @returns The flattened color palette
 */
export function removeColorFromPalette(
  palette: ColorPalette,
  index: number,
): ColorPalette {
  return flattenPalette([...palette.slice(0, index), ...palette.slice(index + 1)]);
}

/**
 * Determines if a color is from the first 4 swatches in the color palette.
 *
 * @param palette The color palette to look for the color in
 * @param color The color to look for
 * @returns boolean
 */
export function isUsingFastColor(palatte: ColorPalette, color: Color): boolean {
  return flattenPalette(palatte)
    .slice(0, NUMBER_OF_FAST_COLORS)
    .some(paletteColor => JSON.stringify(paletteColor) === JSON.stringify(color));
}
