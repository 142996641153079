import { BehaviorType, BehaviorParameters } from '@spog-ui/shared/models/behaviors';
import { SceneZoneBehaviorModelFragment } from '@spog-ui/graphql/types';

export interface SceneZoneBehaviorInternalModel {
  id: string;
  sceneId: string;
  behaviorId: BehaviorType;
  behaviorParameters: BehaviorParameters;
  zoneId: string;
}

export interface SceneZoneBehaviorWSModel {
  id: string;
  behavior_id: string;
  behavior_parameters: string | null;
  removable: boolean;
  scene_id: string;
  zone_id: string;
}

export type SceneZoneBehaviorGQLModel = SceneZoneBehaviorModelFragment;

export function toSceneZoneBehaviorInternalModelFromGQL(
  gql: SceneZoneBehaviorGQLModel,
): SceneZoneBehaviorInternalModel {
  return {
    id: gql.id,
    sceneId: gql.sceneId,
    zoneId: gql.zoneId,
    behaviorId: gql.behaviorId as BehaviorType,
    behaviorParameters: gql.behaviorParameters ?? null,
  };
}

export function toSceneZoneBehaviorInternalModelFromWS(
  ws: SceneZoneBehaviorWSModel,
): SceneZoneBehaviorInternalModel {
  return {
    id: ws.id,
    sceneId: ws.scene_id,
    zoneId: ws.zone_id,
    behaviorId: ws.behavior_id as BehaviorType,
    behaviorParameters: ws.behavior_parameters,
  };
}

/**
 * @TODO: David Pierce
 * Remove once Scene Zone Behaviors are factored out of SPoG UI.
 */
interface SceneZoneBehaviorApiModel {
  sceneId: string;
  zoneId: string;
  behaviorId: BehaviorType;
  behaviorParameters: BehaviorParameters;
}

export interface SceneZoneApiModel {
  id: string | null;
  name: string;
  sceneZoneBehaviors: SceneZoneBehaviorApiModel[];
}

export function toControlZoneSceneEdgeInputFromSceneZoneBehaviorModel(
  sZB: SceneZoneBehaviorApiModel,
) {
  return {
    zoneId: sZB.zoneId,
    behaviorId: sZB.behaviorId,
    parameters: sZB.behaviorParameters,
  };
}

interface LinkedBehavior {
  behaviorParameters: string | null;
  id: BehaviorType;
  zoneList: { id: string; name: string }[];
}

/**
 * A dictionary whose keys are the concatenation of a behavior's ID and
 * parameters, and the values are the LinkedBehavior object for that
 * combination.
 */
type LinkedBehaviorDict = {
  [idAndParams: string]: LinkedBehavior;
};

/**
 * Takes SceneZoneBehaviors and a given scene's ID and creates the list of
 * linked behaviors for that scene.
 *
 * NOTE: Assumes that the sceneZoneBehaviors passed in are only those that
 * describe a single scene.
 * @param szbs SceneZoneBehavior relationship objects, including zone name
 * @param sceneId The scene for which we are trying to determine linked behaviors
 * @returns The linked behaviors for the scene whose ID was provided.
 */
export function toLinkedBehaviorsFromSceneZoneBehaviors(
  szbs: SceneZoneBehaviorViewModel[],
): LinkedBehavior[] {
  /**
   * We use a dict here so that it's easier to track whether or not we've
   * already created a LinkedBehavior with that behavior configuration.
   *
   * If there isn't, we create a new LinkedBehavior and add the current
   *   current szb's zone to its zoneList.
   * If there is, and that LinkedBehavior's zoneList doesn't contain the
   *  current szb's zone, we add it to the LinkedBehavior's zoneList.
   */
  const linkedBehaviorsDict = szbs.reduce((linkedBehaviors, szb) => {
    const idAndParams = szb.behaviorId + szb.behaviorParameters;
    const linkedBehaviorForIdAndParams = linkedBehaviors[idAndParams];
    const zone = { id: szb.zoneId, name: szb.name };

    if (typeof linkedBehaviorForIdAndParams === 'undefined') {
      linkedBehaviors[idAndParams] = {
        id: szb.behaviorId,
        behaviorParameters: szb.behaviorParameters,
        zoneList: [zone],
      };
    } else if (!linkedBehaviorForIdAndParams.zoneList.find(z => z.id === zone.id)) {
      linkedBehaviorForIdAndParams.zoneList.push(zone);
    }

    return linkedBehaviors;
  }, {} as LinkedBehaviorDict);

  /**
   * Here, we chop off the keys, since that was just for convenience
   * while we built the zoneLists.
   */
  return Object.values(linkedBehaviorsDict);
}

export function toControlZoneSceneEdgesFromLinkedBehaviorList(
  linkedBehaviorList: LinkedBehavior[],
) {
  return linkedBehaviorList
    .map(behavior =>
      behavior.zoneList.map(zone => ({
        zoneId: zone.id,
        behaviorId: behavior.id,
        parameters: behavior.behaviorParameters,
      })),
    )
    .flat();
}

export interface SceneZoneBehaviorViewModel extends SceneZoneBehaviorInternalModel {
  id: string;
  sceneId: string;
  behaviorId: BehaviorType;
  behaviorParameters: BehaviorParameters;
  zoneId: string;
  name: string;
}
