import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MovableRectComponentModule } from '../movable-rect';
import { AreaSelectComponent } from './area-select.component';

@NgModule({
  imports: [CommonModule, MovableRectComponentModule],
  declarations: [AreaSelectComponent],
  exports: [AreaSelectComponent],
})
export class AreaSelectComponentModule {}
