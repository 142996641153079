import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, defer, of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import { filter, take, withLatestFrom, switchMap } from 'rxjs/operators';
import { selectOrganizationEntities } from '@spog-ui/shared/state/organizations';
import { CurrentUserService } from '@spog-ui/current-user/feature';

@Injectable({ providedIn: 'root' })
export class AdminOrganizationGuardService implements CanActivate {
  constructor(private store: Store, private currentUser: CurrentUserService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(CoreState.selectSitesLoading).pipe(
      filter(isLoading => !isLoading),
      withLatestFrom(defer(() => this.store.select(selectOrganizationEntities))),
      switchMap(([isLoading, organizations]) => {
        // get the active org ID
        const organizationId = route.parent?.params['activeOrgId'];
        if (!organizationId) {
          // no org id -> routing denied
          return of(false);
        }

        // find the matching org in state.
        const organization = organizations[organizationId];
        if (!organization) {
          // no org -> routing denied unless user is superAdmin
          return this.currentUser.isSuperAdmin$.pipe(
            take(1),
            switchMap(isSuperAdmin => {
              if (!isSuperAdmin) {
                return of(false);
              }

              return of(true);
            }),
          );
        }

        return of(true);
      }),
    );
  }
}
