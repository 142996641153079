import { createReducer, on } from '@ngrx/store';
import {
  AddDepartmentPageActions,
  DepartmentsApiActions,
} from '@spog-ui/departments/actions';

export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  loadingError?: any;
}

export const initialState: Shape = {
  isLoading: true,
  isSubmitting: false,
};

export const reducer = createReducer(
  initialState,
  on(
    AddDepartmentPageActions.enterAction,
    (): Shape => {
      return initialState;
    },
  ),
  on(
    DepartmentsApiActions.loadFormDataSuccessAction,
    (): Shape => {
      return { isLoading: false, isSubmitting: false };
    },
  ),
  on(
    DepartmentsApiActions.loadFormDataFailureAction,
    (state, action): Shape => {
      return { isLoading: false, isSubmitting: false, loadingError: action.error };
    },
  ),
  on(
    AddDepartmentPageActions.addDepartmentAction,
    (): Shape => {
      return { isLoading: false, isSubmitting: true };
    },
  ),
  on(
    DepartmentsApiActions.createDepartmentFailure,
    (): Shape => {
      return { isLoading: false, isSubmitting: false };
    },
  ),
  on(
    DepartmentsApiActions.createDepartmentSuccess,
    (): Shape => {
      return { isLoading: false, isSubmitting: false };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
export const selectLoadingError = (state: Shape) => state.loadingError;
