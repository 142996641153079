import { createAction } from '@ngrx/store';
import { PermissionGroupInternalModel } from '@spog-ui/shared/models/permission-groups';

export const enterAction = createAction(
  '[Admin / Organizations / Permissions Group] Enter',
  (organizationId: string) => ({ organizationId }),
);

export const loadPageSuccessAction = createAction(
  '[Permission Groups / API] Load Page Success',
  (permissionGroups: PermissionGroupInternalModel[]) => ({
    permissionGroups,
  }),
);

export const loadPageFailureAction = createAction(
  '[Permission Groups / API] Load Page Failure',
  (error: string) => ({ error }),
);

export const deleteAction = createAction(
  '[Admin Organization / Permission Groups] Delete',
  (permissionGroup: PermissionGroupInternalModel) => ({ permissionGroup }),
);

export const confirmDeleteAction = createAction(
  '[Admin Organization / Permission Groups] Confirm Delete',
  (permissionGroup: PermissionGroupInternalModel) => ({ permissionGroup }),
);

export const cancelDeleteAction = createAction(
  '[Admin Organization / Permission Groups] Cancel Delete',
  (permissionGroup: PermissionGroupInternalModel) => ({ permissionGroup }),
);

export const filterAction = createAction(
  '[Admin Organization / Permission Groups] Filter',
  (term: string) => {
    return { term };
  },
);
export const paginateAction = createAction(
  '[Admin Organization / Permission Groups] Paginate',
  (pageIndex: number, pageSize: number) => {
    return { pageIndex, pageSize };
  },
);

export const copyIDAction = createAction(
  '[Admin Organization / Permission Groups] Copy Org ID',
  (permissionGroup: PermissionGroupInternalModel) => {
    return { permissionGroup };
  },
);
