import { createReducer, on } from '@ngrx/store';
import { AddOrganizationPageActions, OrganizationApiActions } from '../actions';

export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
  isSubmitting: false,
};

export const reducer = createReducer(
  initialState,
  on(AddOrganizationPageActions.enterAction, (): Shape => {
    return initialState;
  }),
  on(AddOrganizationPageActions.loadPageSuccessAction, (state): Shape => {
    return { ...state, isLoading: false };
  }),
  on(AddOrganizationPageActions.loadPageFailureAction, (state, action): Shape => {
    return { ...state, isLoading: false, error: action.error };
  }),
  on(AddOrganizationPageActions.addOrganizationAction, (state): Shape => {
    return { ...state, isSubmitting: true };
  }),
  on(
    OrganizationApiActions.createOrganizationFailureAction,
    OrganizationApiActions.createOrganizationFailureAction,
    (state): Shape => {
      return { ...state, isSubmitting: false };
    },
  ),
);

export const STATE_KEY = 'addOrganizationPage';

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
