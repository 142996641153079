import { fromEvent as observableFromEvent, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  AfterContentInit,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { throttle } from 'lodash';
import { zoom, ZoomBehavior, zoomIdentity } from 'd3-zoom';
import { event, select } from 'd3-selection';
import { TransformModel } from '@spog-ui/map-tools/models';
import { MAX_ZOOM_SCALE, MIN_ZOOM_SCALE, TransformAnimator } from '../../services';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[map-pan-and-zoom-target]',
})
export class PanAndZoomTargetDirective {
  constructor(private elementRef: ElementRef<SVGGElement>) {}

  get element() {
    return this.elementRef.nativeElement;
  }
}

@Component({
  selector: 'map-pan-and-zoom-container',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    `,
  ],
})
export class PanAndZoomContainerComponent implements OnDestroy, AfterContentInit {
  clickSubscription: Subscription | null = null;
  animationSubscription: Subscription | null = null;
  zoomBehavior: ZoomBehavior<any, any> | null = null;
  transform: TransformModel;

  @Output()
  clickOnPoint = new EventEmitter<{ x: number; y: number; r: number }>();
  @Output()
  zoomAndPan = new EventEmitter<TransformModel>();
  @ContentChild(PanAndZoomTargetDirective, { static: true })
  target: PanAndZoomTargetDirective;

  private isEnabled = true;
  @Input()
  set enabled(isEnabled: boolean) {
    if (isEnabled) {
      this.addEventHandlers();
    } else {
      this.removeEventHandlers();
    }
    this.isEnabled = isEnabled;
  }

  throttledZoomAndPan = throttle(({ x, y, k }) => {
    this.zoomAndPan.emit({ x, y, k });
  }, 100);

  constructor(
    private elementRef: ElementRef<HTMLUnknownElement>,
    private transformAnimator: TransformAnimator,
  ) {}

  removeEventHandlers() {
    if (this.clickSubscription) {
      this.clickSubscription.unsubscribe();
      this.clickSubscription = null;
    }

    if (this.zoomBehavior) {
      this.zoomBehavior.on('zoom', null);
      this.zoomBehavior = null;

      select(this.target.element)
        .on('wheel.zoom', null)
        .on('mousedown.zoom', null)
        .on('dblclick.zoom', null)
        .on('touchstart.zoom', null)
        .on('touchmove.zoom', null)
        .on('touchend.zoom touchcancel.zoom', null);
    }
  }

  createClickStream(target: any): Observable<MouseEvent> {
    return observableFromEvent<MouseEvent>(target, 'click');
  }

  addEventHandlers() {
    const parent = this.elementRef.nativeElement;

    if (this.clickSubscription === null) {
      const svgClickTransform$ = this.createClickStream(parent).pipe(
        filter((event: MouseEvent) => !event.defaultPrevented),
        map((event: MouseEvent) => {
          const { clientX, clientY } = event;
          const { left: offsetX, top: offsetY } = parent.getBoundingClientRect();

          const inverseTransform = {
            k: 1 / this.transform.k,
            x: -1 * this.transform.x,
            y: -1 * this.transform.y,
          };

          const actualX = (clientX + inverseTransform.x - offsetX) * inverseTransform.k;
          const actualY = (clientY + inverseTransform.y - offsetY) * inverseTransform.k;
          const radius = 15 * inverseTransform.k;

          return { x: actualX, y: actualY, r: radius };
        }),
      );
      this.clickSubscription = svgClickTransform$.subscribe(this.clickOnPoint);
    }

    if (this.zoomBehavior === null) {
      const g = select(this.target.element);

      this.zoomBehavior = zoom()
        .scaleExtent([MIN_ZOOM_SCALE, MAX_ZOOM_SCALE])
        .clickDistance(2)
        .on('zoom', () => {
          this.transform = event.transform;
          this.throttledZoomAndPan({
            x: event.transform.x,
            y: event.transform.y,
            k: event.transform.k,
          });
          g.attr('transform', event.transform);
        });

      this.zoomBehavior(select(parent));
    }

    this.animationSubscription = this.transformAnimator
      .observe()
      .subscribe(({ transform, duration }) => {
        if (!this.zoomBehavior) return;

        (select(parent) as any)
          .transition()
          .duration(duration)
          .call(
            this.zoomBehavior.transform,
            zoomIdentity.translate(transform.x, transform.y).scale(transform.k),
          );
      });
  }

  ngAfterContentInit() {
    if (this.isEnabled) {
      this.addEventHandlers();
    }
  }

  ngOnDestroy() {
    this.removeEventHandlers();

    if (this.animationSubscription) this.animationSubscription.unsubscribe();
  }
}
