import { createAction } from '@ngrx/store';
import {
  ThermostatAlarmInternalModel,
  ThermostatInternalModel,
} from '@spog-ui/shared/models/thermostats';

export const loadThermostatsPageModelsSuccessAction = createAction(
  '[Thermostats API] Load Thermostats Page Models Success',
  (thermostats: ThermostatInternalModel[], alarms: ThermostatAlarmInternalModel[]) => ({
    thermostats,
    alarms,
  }),
);
export const loadThermostatsPageModelsFailureAction = createAction(
  '[Thermostats API] Load All Thermostats Page Models Failure',
  (error: any) => ({ error }),
);

export const createThermostatSuccess = createAction(
  '[Thermostats API] Create Thermostat Success',
);
export const createThermostatFailure = createAction(
  '[Thermostats API] Create Thermostat Failure',
  (error?: any) => ({ error }),
);
