import { createReducer, on } from '@ngrx/store';
import { NearbyResultsActions } from '@spog-ui/light-map-layer/actions';
import { LightZoneDetailsPageActions } from '@spog-ui/light-zone-details/actions';
import { LightDetailsActions } from '@spog-ui/light-details/actions';

export interface Shape {
  previewZoneId: string | null;
  previewLightId: string | null;
  previewSceneId: string | null;
}

const initialState: Shape = {
  previewZoneId: null,
  previewLightId: null,
  previewSceneId: null,
};

/**
 * Note: Many of these reducer cases spread initialState,
 * instead of state. This is to avoid allowing more than
 * one preview to be active at a time.
 */

export const reducer = createReducer(
  initialState,
  on(
    NearbyResultsActions.enterAction,
    NearbyResultsActions.leaveAction,
    NearbyResultsActions.unpreviewLightAction,
    NearbyResultsActions.unpreviewSceneAction,
    NearbyResultsActions.unpreviewZoneAction,
    LightDetailsActions.unpreviewZoneAction,
    LightZoneDetailsPageActions.enterAction,
    LightZoneDetailsPageActions.closeAction,
    LightZoneDetailsPageActions.unpreviewLightAction,
    () => {
      return initialState;
    },
  ),
  on(
    NearbyResultsActions.previewZoneAction,
    LightDetailsActions.previewZoneAction,
    (state, action) => {
      return {
        ...initialState,
        previewZoneId: action.zoneId,
      };
    },
  ),
  on(
    NearbyResultsActions.previewLightAction,
    LightZoneDetailsPageActions.previewLightAction,
    (state, action) => {
      return {
        ...initialState,
        previewLightId: action.lightId,
      };
    },
  ),
  on(NearbyResultsActions.previewSceneAction, (state, action) => {
    return {
      ...initialState,
      previewSceneId: action.sceneId,
    };
  }),
);

export const selectPreviewZoneId = (state: Shape) => state.previewZoneId;
export const selectPreviewLightId = (state: Shape) => state.previewLightId;
export const selectPreviewSceneId = (state: Shape) => state.previewSceneId;
