import { createAction } from '@ngrx/store';
import {
  CreatableHardwareType,
  Sense420IndustrialSensorRequiredProps,
} from '@spog-ui/shared/models/indie-sensors';

export const enterAction = createAction('[Add Indie Sensor Page] Enter');
export const selectHardwareTypeAction = createAction(
  '[Add Indie Sensor Page] Select Hardware Type',
  (hardwareType: CreatableHardwareType) => ({ hardwareType }),
);
export const unselectHardwareTypeAction = createAction(
  '[Add Indie Sensor Page] Unselect Hardware Type',
);
export const leaveAction = createAction('[Add Indie Sensor Page] Leave');
export const addSense420IndieSensorAction = createAction(
  '[Add Indie Sensor Page] Add Sense 420 Indie Sensor',
  (indieSensor: Sense420IndustrialSensorRequiredProps) => {
    return { indieSensor };
  },
);
