export enum AlarmTypes {
  I2CWriteFaultFailure = 'I2C Write Fault Failure',
  PollerException = 'Poller Exception',
  DeviceLinkQualityWarning = 'Device Link Quality Warning',
  CensusFailure = 'Census Failure',
  BridgeTimeout = 'Bridge Timeout',
  AuthenticationFailure = 'Authentication Failure',
  ScriptLoadFailure = 'Script Load Failure',
  DeviceConfigurationFailure = 'Device Configuration Failure',
  DeviceCommunicationFailure = 'Device Communication Failure',
  NetworkChangeFailure = 'Network Change Failure',
  BridgeNetworkChangeFailure = 'Bridge Network Change Failure',
  TimeChangeFailure = 'Time Change Failure',
  TimeSynchronizationFailure = 'Time Synchronization Failure',
  InvalidControllerType = 'Invalid Controller Type',
  FixtureThresholdFailure = 'Fixture Threshold Failure',
  EncryptionDisabled = 'Encryption Disabled',
  EnhancedCrcDisabled = 'Enhanced CRC Disabled',
  UnknownSiteControllerDetected = 'Unknown Site Controller Detected',
  SecureLoginFailure = 'Secure login failure',
  CellModemDisconnected = 'Cell Modem Disconnected',
  DeviceScriptMissing = 'Device Script Missing',
  DaylightSensorTimeout = 'Daylight Sensor Timeout',
  FusedRelayDetected = 'Fused Relay Detected',
  MapMigrationFailure = 'Map Migration Failure',
  UnknownIndustrialSensorReport = 'Unknown Industrial Sensor Report',
  UnchangingMotionSensor = 'Unchanging Motion Sensor',
  TiltDetected = 'Light Tilted',
  DaliDriverError = 'DALI Driver Error',
  DimmingTestFailre = 'Dimming Test Failure',
  LightRecruitmentFailure = 'Light Recruitment Failure',
}

// On Prem UI CRUD alarm model
export interface AlarmModel {
  id: string;
  alert_sent: boolean;
  clear_time: string | null;
  cleared: boolean;
  description: string;
  level: number;
  removable: boolean;
  self_clearing: boolean;
  snapaddr: string | null;
  subtype: string | null;
  trigger_time: string | null;
  type: AlarmTypes;
}

export const ALARM_THINGS_BY_TYPE: { [key: string]: any } = {
  [AlarmTypes.TiltDetected as string]: {
    title: 'Light is Tilted',
    description: 'Light is not upright.  Please check fixture orientation.',
  },
  [AlarmTypes.FixtureThresholdFailure as string]: {
    title: 'Power Threshold Failure',
  },
  [AlarmTypes.DeviceCommunicationFailure as string]: {
    title: 'Cannot Communicate With Light',
    description:
      'We are having trouble communicating with this light.  Verify the device is powered on and then try pinging the light.',
    actionText: 'Ping Light',
  },
  [AlarmTypes.DeviceConfigurationFailure as string]: {
    title: 'Unable to Push Configuration',
    description:
      'We are having trouble pushing configuration to this light.  Verify the device has power and good signal strength then try to push configuration again.',
    actionText: 'Retry Configuration',
  },
  [AlarmTypes.DeviceLinkQualityWarning as string]: {
    title: 'Poor Signal Strength',
    description:
      'The signal strength between this light and its neighbors is poor.  Check the antenna then refresh the signal strength.',
    actionText: 'Check Signal Strength',
  },
};

// On Prem SUI state model (also used for websockets and things in cloud)
export interface SuiAlarmModel {
  id: string;
  triggerTime: string;
  type: AlarmTypes;
  snapaddr: string;
  controllerId?: string | null;
  siteControllerId?: string;
  cleared: boolean;
  description?: string; // TODO mark this not optional after cloud backend update is in place
  selfClearing?: boolean; // TODO mark this not optional after cloud backend update is in place
  retryable?: boolean; // TODO mark this not optional after cloud backend update is in place
}

export function createMockCsvData(): string {
  return 'Device Configuration Failure, Test alarm 1, 2016-11-02 18:28:12.323483 /n Device Communication Failure, Test alarm 2, 2016-11-02 18:28:12.323483';
}

export function createMockAlarm(updates: Partial<SuiAlarmModel> = {}): SuiAlarmModel {
  return {
    id: 'mock_alarm_id',
    triggerTime: '2016-11-02 18:28:12.323483',
    type: AlarmTypes.DeviceConfigurationFailure,
    controllerId: 'mock-controller-1',
    siteControllerId: '1234',
    cleared: false,
    snapaddr: 'mock_snapaddr',
    description: 'Light Test: Unable to retrieve light status: No response',
    ...updates,
  };
}

export function createMockClearedAlarm(
  updates: Partial<SuiAlarmModel> = {},
): SuiAlarmModel {
  return {
    id: 'mock-alarm-id',
    type: AlarmTypes.DeviceCommunicationFailure,
    snapaddr: 'abc123',
    triggerTime: '2018-11-28T18:25:32Z',
    controllerId: 'controller id',
    cleared: true,
    ...updates,
  };
}

export function isAlarmTypeRetryable(alarmType: AlarmTypes): boolean {
  return alarmType === AlarmTypes.DeviceConfigurationFailure;
}

export function createMockAlarmByType(type: AlarmTypes): SuiAlarmModel {
  return {
    id: 'mock-alarm-1',
    triggerTime: '2016-11-02 18:28:12.323483',
    type: type,
    snapaddr: 'mock_snapaddr',
    controllerId: 'mock-controller-1',
    cleared: false,
    description: 'mock alarm description',
    retryable: isAlarmTypeRetryable(type),
    selfClearing: true,
  };
}

export function createMockAlarmList(): SuiAlarmModel[] {
  return [
    {
      id: 'mock_alarm_id_1',
      triggerTime: '2016-11-02 18:28:12.323483',
      type: AlarmTypes.DeviceConfigurationFailure,
      controllerId: 'mock-controller-1',
      selfClearing: true,
      cleared: false,
      snapaddr: 'mock_snapaddr_1',
      description: 'Light Test: Unable to retrieve light status: No response',
      retryable: true,
    },
    {
      id: 'mock_alarm_id_3',
      triggerTime: '2016-11-02 19:28:12.323483',
      type: AlarmTypes.DeviceConfigurationFailure,
      controllerId: 'mock-controller-2',
      selfClearing: false,
      cleared: false,
      snapaddr: 'mock_snapaddr_2',
      description: 'Light Test: Unable to retrieve light status: No response',
      retryable: false,
    },
    {
      id: 'mock_alarm_id_3',
      triggerTime: '2016-11-02 20:28:12.323483',
      type: AlarmTypes.DeviceConfigurationFailure,
      controllerId: 'mock-controller-3',
      selfClearing: true,
      cleared: true,
      snapaddr: 'mock_snapaddr_3',
      description: 'Light Test: Unable to retrieve light status: No response',
      retryable: true,
    },
  ];
}

export function sortAlarms(alarms: SuiAlarmModel[], field: string): SuiAlarmModel[] {
  if (!alarms || !field) {
    return alarms;
  }
  alarms.sort((a: any, b: any) => {
    if (a[field] < b[field]) {
      return 1;
    } else if (a[field] > b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
  return alarms;
}

export function isAlarmTypeSelfClearing(alarmType: AlarmTypes): boolean {
  return (
    alarmType === AlarmTypes.BridgeTimeout ||
    alarmType === AlarmTypes.ScriptLoadFailure ||
    alarmType === AlarmTypes.DeviceConfigurationFailure ||
    alarmType === AlarmTypes.DeviceCommunicationFailure ||
    alarmType === AlarmTypes.TimeChangeFailure ||
    alarmType === AlarmTypes.DeviceScriptMissing ||
    alarmType === AlarmTypes.DaylightSensorTimeout ||
    alarmType === AlarmTypes.CellModemDisconnected ||
    alarmType === AlarmTypes.EnhancedCrcDisabled ||
    alarmType === AlarmTypes.EncryptionDisabled ||
    alarmType === AlarmTypes.TimeSynchronizationFailure ||
    alarmType === AlarmTypes.FixtureThresholdFailure ||
    alarmType === AlarmTypes.UnchangingMotionSensor ||
    alarmType === AlarmTypes.DaliDriverError
  );
}

// Alarms Table Populator Model
export interface Alarm {
  siteId: string;
  gatewayId: string;
  alarmId: string;
  alarmCleared: boolean;
  alarmClearTime: string | null;
  alarmDescription: string;
  alarmTriggerTime: string;
  alarmType: string;
  controllerId: string;
  snapaddr: string;
}

export function getAlarmTitle(alarmType: AlarmTypes) {
  return ALARM_THINGS_BY_TYPE[alarmType]?.title ?? alarmType;
}

export function getAlarmDescription(alarm: AlarmModel) {
  return ALARM_THINGS_BY_TYPE[alarm.type]?.description ?? alarm.description;
}

export function getAlarmActionLabel(alarm: AlarmModel) {
  // It's ok if this is undefined - there just won't be a button
  return ALARM_THINGS_BY_TYPE[alarm.type]?.actionText;
}