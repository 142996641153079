import { MenuActions } from '@spog-ui/modules/menu-state';
import { CurrentUserActions } from '@spog-ui/current-user/auth-actions';
import { AdminPanelActions } from '@spog-ui/admin/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  isOpen: boolean;
}

export const initialState: Shape = {
  isOpen: false,
};

export const reducer = createReducer(
  initialState,
  on(MenuActions.openAction, (state, action) => {
    return { isOpen: true };
  }),
  on(
    CurrentUserActions.confirmLogoutAction,
    MenuActions.closeAction,
    AdminPanelActions.openAdminPanel,
    (state, action) => {
      return { isOpen: false };
    },
  ),
);

export const selectIsOpen = (state: Shape) => state.isOpen;
