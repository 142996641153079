import { createAction } from '@ngrx/store';

export const enterAction = createAction('[Departments Page] Enter');

export const deleteDepartmentAction = createAction(
  '[Departments Page] Delete Department',
  (departmentId: string) => ({ departmentId }),
);

export const confirmDeleteDepartmentAction = createAction(
  '[Departments Page] Confirm Delete Department',
  (departmentId: string) => ({ departmentId }),
);

export const cancelDeleteDepartmentAction = createAction(
  '[Departments Page] Cancel Delete Department',
);

export const goToEditDepartmentPageAction = createAction(
  '[Departments Page] Go to Edit Department Page',
  (departmentId: string) => {
    return { departmentId };
  },
);

export const searchAction = createAction(
  '[Departments Page] Search',
  (searchTerm: string) => {
    return { searchTerm };
  },
);
