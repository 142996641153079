import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[scnValidateSceneName]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ValidateSceneNameDirective,
    },
  ],
})
export class ValidateSceneNameDirective implements Validator {
  onValidatorChange: () => void;
  usedSceneNames: Set<string> = new Set();

  @Input()
  set scnValidateSceneName(names: string[]) {
    this.usedSceneNames = new Set(names);

    if (this.onValidatorChange) {
      this.onValidatorChange();
    }
  }

  validate(c: AbstractControl): { [key: string]: string | boolean } | null {
    const value: string = c.value;

    if (value === '' || value === null) {
      return { required: true };
    }

    return this.usedSceneNames.has(value)
      ? {
          suiUniqueSceneName: value,
        }
      : null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }
}
