import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  SiteMapPageActions,
  SiteSelectorApiActions,
  SiteSelectorPageActions,
} from '@spog-ui/site-selector/actions';
import { MenuActions } from '@spog-ui/modules/menu-state';
import { DialogService } from '@spog-ui/shared/components';
import { SiteDetailsActions } from '@spog-ui/site-details/actions';
import { SiteSelectorComponent } from '../components/site-selector';
import { SiteService } from '@spog-ui/shared/services';
import { SiteGuardActions } from '@spog-ui/shared/state/site/site-actions';
import {
  GraphQLAPIService,
  OrganizationRole,
  Capabilities,
} from '@spog-ui/graphql/types';
import { AuthActions } from '@spog-ui/current-user/auth-actions';
import { OrganizationStateActions } from '@spog-ui/shared/state/organizations';
import { SitesStateActions } from '@spog-ui/shared/state/core';
import {
  OrganizationIdentityGQLModel,
  toOrganizationInternalModelFromIdentityGQL,
} from '@spog-ui/shared/models/organizations';
import { toSiteInternalModelWithSiteRoleFromGQL } from '@spog-ui/shared/models/sites';
import { CurrentUserStore } from '@spog-ui/shared/state/current-user';
import {
  SiteControllerInternalModel,
  toSiteControllerInternalModelFromGQL,
} from '@spog-ui/shared/models/site-controllers';

@Injectable()
export class SiteSelectorEffects {
  currentUserStore: any;

  constructor(
    readonly actions$: Actions,
    readonly router: Router,
    readonly gql: GraphQLAPIService,
    readonly dialog: DialogService,
    readonly site: SiteService,
  ) {
    this.currentUserStore = inject(CurrentUserStore);
  }

  loadSitesAndOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccessAction, SiteGuardActions.invalidatedSiteAction),
      mergeMap(() => {
        return this.gql
          .mySitesAndOrganizations({
            filter: {},
          })
          .pipe(
            map(result => {
              const associatedOrgs = result.myOrganizations.edges;

              const orgAllowingMapSelect = associatedOrgs.find(edge =>
                edge.node.capabilities.includes(Capabilities.MAP_SITE_SELECT),
              );

              if (orgAllowingMapSelect) {
                this.currentUserStore.setSelectSiteMethod(true);
              }

              const siteControllers: SiteControllerInternalModel[] =
                result.mySites?.reduce((acc, current: any) => {
                  acc.push(
                    ...current.siteControllers.map(toSiteControllerInternalModelFromGQL),
                  );

                  return acc;
                }, [] as SiteControllerInternalModel[]);

              return SiteSelectorApiActions.getAllModelsSuccessFromLoginAction(
                result.mySites.map(toSiteInternalModelWithSiteRoleFromGQL),
                siteControllers,
                associatedOrgs
                  .filter(edge => edge.organizationRole === OrganizationRole.ADMIN)
                  .map((e: { node: OrganizationIdentityGQLModel }) =>
                    toOrganizationInternalModelFromIdentityGQL(e.node),
                  ),
              );
            }),
            catchError(error =>
              of(SiteSelectorApiActions.loadMySitesAndOrganizationsFailureAction(error)),
            ),
          );
      }),
    ),
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteSelectorApiActions.getAllModelsSuccessFromLoginAction),
      map(action => SitesStateActions.loadSitesSuccessAction(action.sites)),
    ),
  );

  loadOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SiteSelectorApiActions.getAllModelsSuccessFromLoginAction),
      map(action =>
        OrganizationStateActions.loadOrganizationsSuccessAction(action.organizations),
      ),
    ),
  );

  goToHomePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SiteSelectorPageActions.selectAction,
          SiteDetailsActions.logInToSiteAction,
          SiteDetailsActions.logInToSiteWithElevatedAccessAction,
        ),
        tap(action => this.router.navigateByUrl(this.site.getHomePage(action.site.id))),
      ),
    { dispatch: false },
  );

  cancelMapSiteChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SiteMapPageActions.cancelSiteChangeAction,
        ),
        tap(action => this.router.navigateByUrl(this.site.getCurrentSiteHomePage())),
      ),
    { dispatch: false },
  );
  

  handleSiteChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.changeSiteAction),
      map(() => {
        if (this.currentUserStore.selectSiteViaMap()) {
          this.router.navigateByUrl('/user/site-map/change');
          return MenuActions.closeAction();
        } else {
          return SiteSelectorPageActions.openDialogAction();
        }
      }),
    ),
  );

  openSiteSelectorDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteSelectorPageActions.openDialogAction),
        switchMap(() =>
          this.dialog
            .open(SiteSelectorComponent, {
              // this is for a change, instead of an initial selection
              data: true,
            })
            .pipe(
              takeUntil(this.actions$.pipe(ofType(SiteSelectorPageActions.selectAction))),
            ),
        ),
      ),
    { dispatch: false },
  );

  closeSiteSelectorDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteSelectorPageActions.cancelSiteChangeAction),
        tap(() => this.dialog.close()),
      ),
    { dispatch: false },
  );
}
