import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  COOL_COLOR,
  MAX_COOL_SETPOINT,
  MAX_HEAT_SETPOINT,
  MIN_COOL_SETPOINT,
  MIN_HEAT_SETPOINT,
  MIN_TEMP_DIFF,
  SVG_HEIGHT,
  SVG_WIDTH,
} from '../constants';

export enum ThermostatFanMode {
  ON = 'ON',
  AUTO = 'AUTO',
}

@Component({
  selector: 'spog-thermodial',
  template: `
    @if (controlGroup) {
    <svg [attr.width]="width" [attr.height]="height" #svgRef class="thermodialSvg">
      <defs>
        <linearGradient id="temperatureGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop class="gradientHeatSetpointStop" offset="0%" />
          <stop class="gradientCoolSetpointStop" offset="100%" />
        </linearGradient>
      </defs>
      <g spog-thermodial-background></g>
      <g
        spog-thermodial-arc
        [heatSetpoint]="heatSetpoint"
        [coolSetpoint]="coolSetpoint"
      ></g>
      <g
        spog-thermodial-nub
        data-test-id="heatSetpointNub"
        [containerElement]="svgRef"
        [temperature]="heatSetpoint"
        [disabled]="controlGroup?.disabled"
        (temperatureChange)="onHeatSetpointChange($event)"
      ></g>
      <g
        spog-thermodial-nub
        data-test-id="coolSetpointNub"
        [containerElement]="svgRef"
        [temperature]="coolSetpoint"
        [disabled]="controlGroup?.disabled"
        (temperatureChange)="onCoolSetpointChange($event)"
      ></g>
    </svg>
    <fieldset [formGroup]="controlGroup" class="thermodialControlGroup">
      <div class="currentRange">
        <spog-thermodial-temperature-input
          label="Minimum heat setpoint"
          formControlName="heatF"
        ></spog-thermodial-temperature-input>
        -
        <spog-thermodial-temperature-input
          label="Minimum cool setpoint"
          formControlName="coolF"
        ></spog-thermodial-temperature-input>
      </div>
      <mat-icon class="fanIcon" svgIcon="fan"></mat-icon>
      <mat-button-toggle-group formControlName="fanMode" aria-label="Fan Mode">
        <mat-button-toggle [value]="ON">{{ ON }}</mat-button-toggle>
        <mat-button-toggle [value]="AUTO">{{ AUTO }}</mat-button-toggle>
      </mat-button-toggle-group>
    </fieldset>
    }
  `,
  styles: [
    `
      spog-thermodial {
        display: flex;
        position: relative;
        touch-action: none;
        height: 250px;
        width: 250px;
      }

      spog-thermodial.disabled {
        cursor: not-allowed;
      }

      spog-thermodial .gradientHeatSetpointStop {
        stop-color: var(--color-accent-500);
        stop-opacity: 1;
      }

      spog-thermodial .gradientCoolSetpointStop {
        stop-color: var(--thermodial-cool-color);
        stop-opacity: 1;
      }

      spog-thermodial .thermodialSvg,
      spog-thermodial .thermodialControlGroup {
        position: absolute;
      }

      spog-thermodial .thermodialControlGroup {
        border-radius: 50%;
        border: 0;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin: 40px;
        padding: 0;
        height: calc(250px - 80px);
        width: calc(250px - 80px);
      }

      spog-thermodial .currentRange {
        display: flex;
        align-items: center;
        color: white;
        margin-top: 40px;
        user-select: none;
      }

      spog-thermodial .fanIcon {
        fill: rgba(255, 255, 255, 0.3);
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
      }

      spog-thermodial
        .mat-button-toggle-appearance-standard
        .mat-button-toggle-label-content {
        font-size: 10px;
        line-height: 25px;
        padding: 0 7px;
        min-width: 40px;
      }

      spog-thermodial
        .mat-button-toggle-group-appearance-standard
        .mat-button-toggle
        + .mat-button-toggle {
        border-left-width: 0;
      }

      spog-thermodial .mat-button-toggle-group-appearance-standard {
        border: solid 1px rgba(255, 255, 255, 0.3);
      }

      spog-thermodial .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
        background-color: transparent;
      }

      spog-thermodial .mat-button-toggle-appearance-standard {
        background-color: rgba(255, 255, 255, 0.3);
      }

      spog-thermodial .mat-button-toggle-button:disabled {
        color: rgba(255, 255, 255, 0.5);
        cursor: not-allowed;
      }

      spog-thermodial .mat-button-toggle-checked {
        color: var(--color-500) !important;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ThermodialComponent implements OnInit, OnDestroy {
  @HostBinding('style.--thermodial-cool-color')
  readonly coolColor = COOL_COLOR;

  @HostBinding('class.disabled') isDisabled = false;

  /**
   * The name of a control group in the parent component
   */
  @Input() controlGroupName: string;

  ON = ThermostatFanMode.ON;
  AUTO = ThermostatFanMode.AUTO;

  selectId = (_: number, value: { id: string }) => value.id;

  readonly height = SVG_WIDTH;
  readonly width = SVG_HEIGHT;

  readonly minTempDiff = MIN_TEMP_DIFF;
  readonly minHeatSetpoint = MIN_HEAT_SETPOINT;
  readonly maxCoolSetpoint = MAX_COOL_SETPOINT;

  _heatSetpoint: number;
  _coolSetpoint: number;

  set heatSetpoint(value: number) {
    this._heatSetpoint = value;

    if (this.controlGroup?.get('heatF')?.value !== value) {
      this.controlGroup?.get('heatF')?.setValue(value);
    }
  }

  get heatSetpoint() {
    return this._heatSetpoint;
  }

  set coolSetpoint(value: number) {
    this._coolSetpoint = value;

    if (this.controlGroup?.get('coolF')?.value !== value) {
      this.controlGroup?.get('coolF')?.setValue(value);
    }
  }

  get coolSetpoint() {
    return this._coolSetpoint;
  }

  subscriptions: Subscription = new Subscription();

  constructor(public readonly controlContainer: ControlContainer) {}

  ngOnInit() {
    this.isDisabled = this.controlGroup?.disabled || false;
    this.heatSetpoint = this.controlGroup?.get('heatF')?.value;
    this.coolSetpoint = this.controlGroup?.get('coolF')?.value;

    this.subscriptions.add(
      this.controlGroup?.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(value => this.onControlModeChange(value)),
    );
  }

  get controlGroup() {
    return this.controlContainer.control?.get(this.controlGroupName);
  }

  onControlModeChange({
    heatF,
    coolF,
  }: {
    heatF: number;
    coolF: number;
    fanMode: ThermostatFanMode;
  }) {
    // only heat is new -- likely from manual control, e.g. the nubs
    if (this.heatSetpoint !== heatF && this.coolSetpoint === coolF) {
      this.onHeatSetpointChange(heatF);
    }
    // only cool is new -- likely from manual control, e.g. the nubs
    else if (this.heatSetpoint === heatF && this.coolSetpoint !== coolF) {
      this.onCoolSetpointChange(coolF);
    } else {
      /**
       * both are new -- likely from applying a routine (or, possibly,
       * fan mode has changed, which can also run this case safely)
       */
      const heatSetpoint = Math.floor(
        Math.max(MIN_HEAT_SETPOINT, Math.min(MAX_HEAT_SETPOINT, heatF)),
      );
      const coolSetpoint = Math.floor(
        Math.max(MIN_COOL_SETPOINT, Math.min(MAX_COOL_SETPOINT, coolF)),
      );

      console.assert(
        coolSetpoint - heatSetpoint > MIN_TEMP_DIFF,
        `Cool and heat setpoints must be at least ${MIN_TEMP_DIFF} degrees apart.`,
      );

      this.heatSetpoint = heatSetpoint;
      this.coolSetpoint = coolSetpoint;
    }
  }

  onHeatSetpointChange($event: number) {
    this.heatSetpoint = Math.floor(
      Math.max(MIN_HEAT_SETPOINT, Math.min(MAX_HEAT_SETPOINT, $event)),
    );

    if (this.coolSetpoint - this.heatSetpoint < MIN_TEMP_DIFF) {
      this.coolSetpoint = this.heatSetpoint + MIN_TEMP_DIFF;
    }
  }

  onCoolSetpointChange($event: number) {
    this.coolSetpoint = Math.floor(
      Math.max(MIN_COOL_SETPOINT, Math.min(MAX_COOL_SETPOINT, $event)),
    );

    if (this.coolSetpoint - this.heatSetpoint < MIN_TEMP_DIFF) {
      this.heatSetpoint = this.coolSetpoint - MIN_TEMP_DIFF;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
