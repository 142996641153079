import { NgModule } from '@angular/core';
import { FOCUS_SERVICE_PROVIDERS, FocusService } from './focus.service';
import { PLATFORM_SERVICE_PROVIDERS, PlatformService } from './platform.service';
import {
  createWindow,
  WINDOW_SERVICE_PROVIDER,
  WINDOW_TOKEN,
  WindowService,
} from './window.service';

@NgModule({
  providers: [
    FOCUS_SERVICE_PROVIDERS,
    PLATFORM_SERVICE_PROVIDERS,
    WINDOW_SERVICE_PROVIDER,
  ],
})
export class SuiPlatformModule {}

export {
  FocusService,
  PlatformService,
  WindowService,
  WINDOW_TOKEN,
  createWindow,
  PLATFORM_SERVICE_PROVIDERS,
};
