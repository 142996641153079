import { Component } from '@angular/core';

@Component({
  selector: 'sui-wide-status-tray',
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        display: flex;
        position: fixed;
        top: calc(100% - 64px);
        background-color: var(--color-background-app-bar);
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.12);
        width: 100%;
        height: 64px;
        padding: 8px 24px;
        align-items: center;
        justify-content: space-between;
      }
    `,
  ],
})
export class WideStatusTrayComponent {}
