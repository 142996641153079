import { createReducer, on } from '@ngrx/store';
import { AddUserPageActions, UserApiActions } from '@spog-ui/admin/actions';

export interface Shape {
  isLoading: boolean;
  isSaving: boolean;
  error: any;
}

export const initialState: Shape = {
  isLoading: true,
  isSaving: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(AddUserPageActions.enterAction, () => initialState),
  on(UserApiActions.loadModelsSuccessAction, state => ({ ...state, isLoading: false })),
  on(UserApiActions.loadModelsFailureAction, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  on(AddUserPageActions.saveAction, state => ({
    ...state,
    isSaving: true,
  })),
  on(UserApiActions.createUserSuccessAction, state => ({
    ...state,
    isSaving: false,
    error: null,
  })),
  on(UserApiActions.createUserFailureAction, (state, action) => ({
    ...state,
    isSaving: false,
    error: action.error,
  })),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSaving = (state: Shape) => state.isSaving;
export const selectError = (state: Shape) => state.error;
