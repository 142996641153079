import { createReducer, on } from '@ngrx/store';
import { AlarmsApiActions, AlarmsPageActions } from '@spog-ui/alarms/actions';

export interface Shape {
  loading: boolean;
  error: any;
  filter: string;
  showRemoteAccessLink: boolean;
}

export const initialState: Shape = {
  loading: false,
  error: null,
  filter: '',
  showRemoteAccessLink: true,
};

export const reducer = createReducer(
  initialState,
  on(AlarmsPageActions.enterAction, state => {
    return { ...state, loading: true, filter: state.filter };
  }),
  on(AlarmsApiActions.loadAlarmsFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      filter: '',
      showRemoteAccessLink: false,
    };
  }),
  on(AlarmsApiActions.loadAlarmsSuccess, state => {
    return { ...state, loading: false };
  }),
  on(AlarmsPageActions.filterAction, (state, action) => {
    return { ...state, filter: action.searchTerm };
  }),
);

export const selectIsLoadingAlarms = (state: Shape) => state.loading;
export const selectError = (state: Shape) => state.error;
export const selectFilter = (state: Shape) => state.filter;
export const selectShowRemoteAccessLink = (state: Shape) => state.showRemoteAccessLink;
