import { Dictionary } from '@ngrx/entity';

export interface TreeSelectOption {
  value: string;
  name: string;
  svgIcon?: string;
  icon?: string;
  checked: boolean;
  shown?: boolean;
  expanded?: boolean;
  ancestor: string;
}

export interface TreeSelectOptionView {
  value: string;
  name: string;
  svgIcon?: string;
  icon?: string;
  checked: boolean;
  shown: boolean;
  expanded?: boolean;
  children: TreeSelectOptionView[];
}

export function populateChildren(
  option: TreeSelectOptionView,
  groups: Dictionary<TreeSelectOption[]>,
  filter: string,
  selectedOptions: TreeSelectOptionView[],
): boolean {
  const children = groups[option.value] ?? [];

  let hasShownChildren = false;

  if (children.length > 0) {
    option.children = children.map(child => {
      let showChild = child.name.toLowerCase().includes(filter);

      if (child.checked) {
        // Child needs to show up in selected list
        selectedOptions.push({
          ...child,
          shown: showChild,
          children: [],
        });

        // Child needs to be hidden under parent
        showChild = false;
      }

      if (showChild) hasShownChildren = true;

      return {
        ...child,
        shown: showChild,
        children: [],
      };
    });

    option.children.forEach(child => {
      child.shown = populateChildren(child, groups, filter, selectedOptions);
    });
  }

  return option.shown || hasShownChildren;
}
