import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import { catchError, filter, tap, withLatestFrom } from 'rxjs/operators';
import { selectSelectedSite } from '@spog-ui/shared/state/core';
import { processUnhandledExceptionAction } from '../actions';
import { environment } from '@spog-ui/shared/environments';
import { CurrentUserService } from '@spog-ui/current-user/feature';
import { CurrentUser } from '@spog-ui/shared/models/users';
import {
  processGraphQLErrorAction,
  processGraphQLHTTPErrorAction,
} from '@spog-ui/graphql-client';

// Typically, we only see errors with this status cdoe when a browser has no or weak internet
const LOCAL_INTERNET_TROUBLE_ERROR_CODE = 0;
// NB: Some error messages capitalize the 'O' in 'one', and some don't.
// So it's left off to catch both variants.
const SITE_CONTROLLER_ERROR_FRAGMENT = 'ne or more site controllers';
const INDUSTRIAL_SENSOR_ASSOCIATED_WITH_ASSET =
  'industrial sensors is already associated with another asset';
const UNAUTHENTICATED = 'UNAUTHENTICATED';

@Injectable()
export class AnalyticsEffects {
  private handlerURLGraphQLError: string;

  private static disallowedKeys = ['authorization', 'exception'];

  private static sanitizeValues(k: string, v: any) {
    return AnalyticsEffects.disallowedKeys.includes(k) ? undefined : v;
  }

  sendGraphQlErrorBeacon(errorDetails: any) {
    const logMessagePayload = JSON.stringify(
      errorDetails,
      AnalyticsEffects.sanitizeValues,
    );

    navigator?.sendBeacon?.(this.handlerURLGraphQLError, logMessagePayload);
  }

  logGraphQlErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(processGraphQLErrorAction),
        withLatestFrom(this.store.select(selectSelectedSite), this.currentUser.user$),
        tap(([action, site, user]) => {
          /**
           * We're sending each nested error as a separate beacon event
           * These can be grouped in the logs by correlationId
           */
          action.response.errors?.forEach(currentError => {
            if (currentError.message.includes("Could not ping SNAP device")) {
              return EMPTY;
            }

            if (currentError.message.includes("One or more site controllers")) {
              return EMPTY;
            }

            this.sendGraphQlErrorBeacon({
              correlationId: action.correlationId,
              errorJSON: currentError,
              siteId: site?.id,
              siteName: site?.name,
              userId: user?.id,
              message: currentError.message,
              ...(currentError.extensions ?? {}),
            });
          });
        }),
      ),
    { dispatch: false },
  );

  logGraphQlHTTPErrors$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(processGraphQLHTTPErrorAction),
        withLatestFrom(this.store.select(selectSelectedSite), this.currentUser.user$),
        tap(([action, site, user]) => {
          this.sendGraphQlErrorBeacon({
            correlationId: action.correlationId,
            errorJSON: action.response,
            siteId: site?.id,
            siteName: site?.name,
            userId: user?.id,
            httpResponse: action.response ?? {},
          });

          const response = action.response;

          if (typeof response.error === 'string') {
            if (response.error.includes(SITE_CONTROLLER_ERROR_FRAGMENT)) {
              console.log('Not forwarding error about unreachable site controllers');
              console.error(JSON.stringify(response.error));
              return EMPTY;
            }
          }

          if (
            response.status != null &&
            response.status === LOCAL_INTERNET_TROUBLE_ERROR_CODE
          ) {
            // the local browser is having internet issues.  Don't forward those!  But, log them.
            console.log('Not forwarding error with status 0');
            console.error(JSON.stringify(response.error));
            return EMPTY;
          }

          // handle errors arrays
          if (Array.isArray(response.error?.errors)) {
            if (
              response.error.errors.some(
                (_error: GraphQLFormattedError) =>
                  _error.message != null &&
                  _error.message.includes(SITE_CONTROLLER_ERROR_FRAGMENT),
              )
            ) {
              console.log('Not forwarding error about unreachable site controllers');
              console.error(JSON.stringify(response.error));
              return EMPTY;
            }

            if (
              response.error.errors.some(
                (_error: GraphQLFormattedError) =>
                  _error.message != null &&
                  _error.message.includes(INDUSTRIAL_SENSOR_ASSOCIATED_WITH_ASSET),
              )
            ) {
              console.log(
                'Not forwarding error about already-associated industrial sensors',
              );
              console.error(JSON.stringify(response.error));
              return EMPTY;
            }

            if (
              response.error.errors.some(
                (_error: GraphQLFormattedError) =>
                  _error.extensions != null && _error.extensions.code === UNAUTHENTICATED,
              )
            ) {
              console.log('Not forwarding error about unauthenticated user');
              console.error(JSON.stringify(response.error));
              return EMPTY;
            }
          }

          const error =
            response.error instanceof Error
              ? JSON.stringify(
                  {
                    message: response.error.message,
                    stack: response.error.stack,
                  },
                  null,
                  2,
                )
              : JSON.stringify(response.error || response, null, 2);

          return this.functions
            .httpsCallable('logError')({
              action: processGraphQLHTTPErrorAction.type,
              error,
              siteId: site?.id,
              site: site?.name,
              user: user?.name,
            })
            .pipe(catchError(() => EMPTY));
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    readonly actions$: Actions,
    readonly functions: AngularFireFunctions,
    readonly store: Store,
    readonly currentUser: CurrentUserService,
  ) {
    this.handlerURLGraphQLError = `${environment.firebase.functionHost}/logGraphQLError`;
  }

  logUnhandledExceptions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(processUnhandledExceptionAction),
        tap(action => {
          const handlerURL = `${environment.firebase.functionHost}/logUnhandledException`;

          const error =
            action.error instanceof Error
              ? JSON.stringify(
                  {
                    message: action.error.message,
                    stack: action.error.stack,
                    userId: action.userId,
                    siteId: action.siteId,
                    activeRoute: action.activeRoute,
                  },
                  null,
                  2,
                )
              : JSON.stringify(
                  {
                    error: action.error,
                    userId: action.userId,
                    siteId: action.siteId,
                    activeRoute: action.activeRoute,
                  },
                  null,
                  2,
                );

          navigator?.sendBeacon?.(handlerURL, error);
        }),
      ),
    { dispatch: false },
  );
}
