import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { PanelComponent, DimmerComponent } from '@spog-ui/shared/components';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { LightStatusTrayComponentModule } from '../light-status-tray';
import { LightDetailsPanelComponent } from './light-details-panel.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    LightStatusTrayComponentModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    PermissionsModule,
    PanelComponent,
    DimmerComponent,
  ],
  declarations: [LightDetailsPanelComponent],
  exports: [LightDetailsPanelComponent],
})
export class LightDetailsPanelComponentModule {}
