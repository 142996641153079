import { ControllerModelFragment as ControllerGQLModel } from '@spog-ui/graphql/types';

export interface ControllerInternalModel {
  id: string;
  name: string;
  snapaddr: string;
  type: string;
}

export function toControllerInternalModelFromGQL(
  gql: ControllerGQLModel,
): ControllerInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    snapaddr: gql.snapaddr,
    type: gql.type,
  };
}

export { ControllerGQLModel };
