import { createAction } from '@ngrx/store';

export const enterAction = createAction('[Map Positioner / Step Two Freeform] Enter');
export const createPositionAction = createAction(
  '[Map Positioner / Step Two Freeform] Create Position',
  (floorPlanX: number, floorPlanY: number) => ({ floorPlanX, floorPlanY }),
);
export const removePositionAction = createAction(
  '[Map Positioner / Step Two Freeform] Remove Position',
  (positionId: string) => ({ positionId }),
);
