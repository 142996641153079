import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;

@Component({
  selector: 'spog-thermodial-temperature-input',
  template: `
    <input
      #inputRef
      type="number"
      [value]="value"
      (blur)="onChange($event.target.value)"
      (keyup)="sizerValue = $event.target.value"
      (keydown.enter)="onKeydownEnter($event)"
      [id]="id"
      [attr.aria-label]="label"
      [disabled]="disabled"
    />
    <span class="sizer" aria-hidden="true">{{ sizerValue }}</span>
    <span class="suffix">&deg;</span>
  `,
  styles: [
    `
      :host {
        display: block;
        line-height: 32px;
        position: relative;
        margin: 5px;
        min-width: 56px;
        max-width: 72px;
      }

      .suffix {
        font-size: 32px;
        right: 0;
        top: 0;
        position: absolute;
        z-index: 9;
      }

      .sizer {
        font-size: 32px;
        color: transparent;
        padding-right: 16px;
        user-select: none;
      }

      input {
        background-color: transparent;
        border: 0;
        font-size: 32px;
        color: white;
        margin: 0;
        max-width: 100%;
        padding: 0;
        position: absolute;
        padding-right: 16px;
        text-align: right;
        vertical-align: bottom;
        width: 100%;
        z-index: 10;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input:disabled {
        cursor: not-allowed;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThermodialTemperatureInputComponent),
      multi: true,
    },
  ],
})
export class ThermodialTemperatureInputComponent implements ControlValueAccessor {
  /**
   * Descriptive content to label the input element for screenreaders only.
   */
  @Input() label?: string;

  @ViewChild('inputRef', { static: true }) inputRef!: ElementRef<HTMLInputElement>;

  onChangeFn!: (value: any) => any;
  onTouchedFn!: () => any;

  id = `thermodial-temperature-input-${nextUniqueId++}`;
  value?: number;
  disabled?: boolean;

  /**
   * Populates an invisible element that allows the input to dynamically change
   * size based on it's content, which more naturally spaces the temperature inputs
   */
  sizerValue?: number;

  onChange(value: string) {
    const numValue = +value;
    if (numValue !== this.value) {
      this.writeValue(numValue);
      this.onChangeFn(numValue);
    }
  }

  onKeydownEnter($event: Event) {
    $event.preventDefault();
    this.onChange(($event.target as HTMLInputElement).value);
  }

  writeValue(value: number): void {
    this.inputRef.nativeElement.value = `${value}`;
    this.sizerValue = value;
    this.value = value;
  }

  registerOnChange(fn: () => any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
