import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiCardModule } from '@spog-ui/shared/components';
import { IndieSensorsPageNoResultsComponent } from './indie-sensors-page-no-results.component';

@NgModule({
  imports: [CommonModule, SuiCardModule],
  declarations: [IndieSensorsPageNoResultsComponent],
  exports: [IndieSensorsPageNoResultsComponent],
})
export class IndieSensorsPageNoResultsComponentModule {}
