import { createAction } from '@ngrx/store';
import { ThermostatFormModel } from '@spog-ui/shared/models/thermostats';

export const enterAction = createAction('[Add Thermostat Page] Enter');
export const leaveAction = createAction('[Add Thermostat Page] Leave');
export const addThermostatAction = createAction(
  '[Add Thermostat Page] Add Thermostat',
  (thermostat: ThermostatFormModel) => {
    return { thermostat };
  },
);
