import { createAction } from '@ngrx/store';
import { LightCreateProps } from '../models';

export const enterAction = createAction('[Add Light Page] Enter | @sui/light');
export const saveAction = createAction(
  '[Add Light Page] Save | @sui/light',
  (light: LightCreateProps) => {
    return { light };
  },
);
export const cancelAction = createAction('[Add Light Page] Cancel | @sui/light');

export type Union =
  | ReturnType<typeof enterAction>
  | ReturnType<typeof saveAction>
  | ReturnType<typeof cancelAction>;

export const Types = {
  Enter: enterAction.type,
  Save: saveAction.type,
  Cancel: cancelAction.type,
};
