import { createAction } from '@ngrx/store';

export const quickSiteSetupSuccessAction = createAction(
  '[Quick Site Setup / API] Quick Site Setup Success',
);

export const quickSiteSetupFailureAction = createAction(
  '[Quick Site Setup / API] Quick Site Setup Failure',
  (error?: any) => ({ error }),
);

