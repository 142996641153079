import { DateRangeActions, BrowserDateApiActions } from '@spog-ui/power/actions';
import { DateTime } from 'luxon';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  option: string;
  //We're storing as millis-since-epoch for clarity, then rehydrate back to DateTime when selected.
  dates: [number, number];
}

const today = DateTime.local().set({ second: 0, millisecond: 0 });

export const initialState: Shape = {
  option: 'Last Day',
  dates: [today.minus({ days: 1 }).valueOf(), today.valueOf()],
};

export const reducer = createReducer(
  initialState,
  on(DateRangeActions.selectDateRangeOptionAction, (state, action) => {
    return {
      ...state,
      option: action.option,
    };
  }),
  on(
    DateRangeActions.selectCustomDateRangeAction,
    BrowserDateApiActions.calculateDateRangeAction,
    (state, action) => {
      return {
        ...state,
        dates: action.dates.map(dateTime => dateTime.valueOf()) as [number, number],
      };
    },
  ),
);

export const selectOption = (state: Shape) => state.option;
export const selectDateRange = (state: Shape) =>
  state.dates.map(millisDate => DateTime.fromMillis(millisDate)) as [DateTime, DateTime];
