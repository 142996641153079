import { createReducer, on } from '@ngrx/store';
import { ClimateMapPageActions } from '@spog-ui/climate-map-layer/actions';

export interface Shape {
  isFilteringOk: boolean;
  isFilteringAlarmed: boolean;
  isFilteringPrecommissioned: boolean;
}

export const initialState: Shape = {
  isFilteringOk: false,
  isFilteringAlarmed: false,
  isFilteringPrecommissioned: false,
};

export const reducer = createReducer(
  initialState,
  on(ClimateMapPageActions.enterAction, () => {
    return initialState;
  }),
  on(ClimateMapPageActions.toggleStatusTrayFilterAction, (state, action) => {
    return {
      ...state,
      isFilteringOk: action.filteringOk,
      isFilteringAlarmed: action.filteringAlarmed,
      isFilteringPrecommissioned: action.filteringPrecommissioned,
    };
  }),
);

export const selectIsFilteringOk = (state: Shape) => state.isFilteringOk;
export const selectIsFilteringAlarmed = (state: Shape) => state.isFilteringAlarmed;
export const selectIsFilteringPrecommissioned = (state: Shape) =>
  state.isFilteringPrecommissioned;
