import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as EquipmentMapState from './equipment-map.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(EquipmentMapState.STATE_KEY, EquipmentMapState.reducers),
  ],
})
export class EquipmentMapStateModule {}
