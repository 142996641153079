import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SuiButtonModule, SuiDialogModule } from '@sui/components';
import { PromptComponent, PROMPT_DIRECTIVES } from './prompt.component';
import { PROMPT_SERVICE_PROVIDERS, PromptService } from './prompt.service';
import { PromptOptions } from './models';

@NgModule({
  imports: [CommonModule, SuiButtonModule, SuiDialogModule],
  declarations: [PROMPT_DIRECTIVES],
  exports: [PROMPT_DIRECTIVES],
  providers: [PROMPT_SERVICE_PROVIDERS],
})
export class SuiPromptModule {}

export { PromptService, PromptOptions, PromptComponent };
