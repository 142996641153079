import { createAction } from '@ngrx/store';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';

export const enterAction = createAction('[Site Controller Page] Enter');
export const remotelyAccessAction = createAction(
  '[Site Controller Page] Remotely Access',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);
export const copyIDAction = createAction(
  '[Site Controller Page] Copy ID',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);
export const paginateAction = createAction(
  '[Site Controllers Page] Paginate',
  (pageIndex: number, pageSize: number) => {
    return { pageIndex, pageSize };
  },
);
export const filterAction = createAction(
  '[Site Controllers Page] Filter',
  (searchTerm: string) => {
    return { searchTerm };
  },
);
