import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as AlarmsState from './alarms.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(AlarmsState.STATE_KEY, AlarmsState.reducers),
  ],
})
export class AlarmsStateModule {}
