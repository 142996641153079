import { createReducer, on } from '@ngrx/store';
import { ClimateMapPageActions } from '@spog-ui/climate-map-layer/actions';
import {
  ClimateZoneDetailsApiActions,
  ClimateZoneDetailsPaneActions,
} from '@spog-ui/climate-zone-details/actions';
import { ThermostatDetailsPageActions } from '@spog-ui/thermostat-details/actions';

export interface Shape {
  searchTerm: string;
  previewZoneThermostatsIds: string[];
  previewZoneThermostatId: string;
  previewSceneId: string;
}

export const initialState: Shape = {
  searchTerm: '',
  previewZoneThermostatsIds: [],
  previewZoneThermostatId: '',
  previewSceneId: '',
};

export const reducer = createReducer(
  initialState,
  on(ClimateMapPageActions.filterAction, (state, action): Shape => {
    return {
      ...state,
      searchTerm: action.searchTerm,
    };
  }),
  on(
    ClimateZoneDetailsApiActions.loadClimateZoneDetailsSuccessAction,
    (state, action): Shape => {
      return {
        ...state,
        previewZoneThermostatsIds: action.climateZone.deviceIds,
        previewZoneThermostatId: '',
        previewSceneId: '',
      };
    },
  ),
  on(
    ClimateMapPageActions.enterAction,
    ClimateZoneDetailsPaneActions.closeAction,
    ThermostatDetailsPageActions.closeAction,
    (state): Shape => {
      return {
        ...state,
        previewZoneThermostatsIds: [],
        previewZoneThermostatId: '',
        previewSceneId: '',
      };
    },
  ),
  on(
    ClimateMapPageActions.previewClimateZoneThermostatsAction,
    (state, action): Shape => {
      return {
        ...state,
        previewZoneThermostatsIds: action.previewThermostatsIds,
      };
    },
  ),
  on(ClimateMapPageActions.previewClimateZoneThermostatAction, (state, action): Shape => {
    return {
      ...state,
      previewZoneThermostatId: action.previewThermostatId,
    };
  }),
  on(ClimateMapPageActions.previewClimateSceneAction, (state, action): Shape => {
    return {
      ...state,
      previewSceneId: action.climateSceneId,
    };
  }),
  on(ClimateMapPageActions.unpreviewClimateZoneThermostatsAction, (state): Shape => {
    return {
      ...state,
      previewZoneThermostatsIds: [],
    };
  }),
  on(ClimateMapPageActions.unpreviewClimateZoneThermostatAction, (state): Shape => {
    return {
      ...state,
      previewZoneThermostatId: '',
    };
  }),
  on(ClimateMapPageActions.unpreviewClimateSceneAction, (state): Shape => {
    return {
      ...state,
      previewSceneId: '',
    };
  }),
);

export const selectSearchTerm = (state: Shape) => state.searchTerm;
export const selectPreviewZoneThermostatsIds = (state: Shape) =>
  state.previewZoneThermostatsIds;
export const selectPreviewZoneThermostatId = (state: Shape) =>
  state.previewZoneThermostatId;
export const selectPreviewSceneId = (state: Shape) => state.previewSceneId;
