import { createReducer, on } from '@ngrx/store';
import {
  SenseMapLayerActions,
  SenseMapLayerApiActions,
} from '@spog-ui/sense-map-layer/actions';

export interface Shape {
  error: any;
  loading: boolean;
}

export const initialState: Shape = {
  error: null,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    SenseMapLayerActions.enterAction,
    (state): Shape => {
      return {
        ...state,
        loading: true,
      };
    },
  ),
  on(
    SenseMapLayerApiActions.loadSenseMapLayerModelsSuccessAction,
    (state): Shape => {
      return {
        ...state,
        loading: false,
      };
    },
  ),
  on(
    SenseMapLayerApiActions.loadSenseMapLayerModelsFailureAction,
    (state, action): Shape => {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    },
  ),
);

export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
