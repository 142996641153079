import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EmailGroupsApiService } from '../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  catchError,
  map,
  switchMap,
  tap,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of, defer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import {
  EmailGroupApiActions,
  AddEmailGroupPageActions,
  EditEmailGroupPageActions,
  EmailGroupsPageActions,
} from '../actions';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  toCreateEmailGroupInputFromEmailGroupForm,
  toUpdateEmailGroupInputFromEmailGroupForm,
} from '@spog-ui/shared/models/email-groups';
import { Location } from '@angular/common';
import { PromptService } from '@sui/prompt';
import { Store } from '@ngrx/store';
import { selectActiveOrgId } from '@spog-ui/shared/state/organizations';
import { EmailGroupStateActions } from '@spog-ui/shared/state/email-groups';

@Injectable()
export class EmailGroupApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly emailGroupsApiService: EmailGroupsApiService,
    readonly gql: GraphQLAPIService,
    readonly snackbar: MatSnackBar,
    readonly location: Location,
    readonly promptService: PromptService,
    readonly store: Store,
  ) {}

  createEmailGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddEmailGroupPageActions.addEmailGroupAction),
      withLatestFrom(defer(() => this.store.select(selectActiveOrgId))),
      mergeMap(([action, activeOrgId]) => {
        const input = toCreateEmailGroupInputFromEmailGroupForm(
          action.emailGroup,
          activeOrgId!,
        );
        return this.gql.createEmailGroup({ input }).pipe(
          map(() => EmailGroupApiActions.createEmailGroupSuccessAction()),
          catchError(error => {
            return of(EmailGroupApiActions.createEmailGroupFailureAction(error));
          }),
        );
      }),
    ),
  );

  redirectToEmailGroupsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EmailGroupApiActions.createEmailGroupSuccessAction,
          EmailGroupApiActions.updateEmailGroupSuccessAction,
        ),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  createEmailGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailGroupApiActions.createEmailGroupFailureAction),
        tap(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );

  editEmailGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditEmailGroupPageActions.editEmailGroupAction),
      switchMap(action => {
        const input = toUpdateEmailGroupInputFromEmailGroupForm(action.emailGroup);
        return this.gql.updateEmailGroup({ input }).pipe(
          map(() => EmailGroupApiActions.updateEmailGroupSuccessAction()),
          catchError(error => {
            return of(EmailGroupApiActions.updateEmailGroupFailureAction(error));
          }),
        );
      }),
    ),
  );

  editEmailGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailGroupApiActions.updateEmailGroupFailureAction),
        tap(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailGroupsPageActions.confirmDeleteAction),
      mergeMap(({ emailGroup }) =>
        this.emailGroupsApiService.delete(emailGroup.id).pipe(
          map(() => EmailGroupStateActions.deleteEmailGroupAction(emailGroup)),
          catchError((response: HttpErrorResponse) =>
            of(
              EmailGroupApiActions.deleteEmailGroupFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  notifyOnDeleteError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailGroupApiActions.deleteEmailGroupFailureAction),
        tap(() =>
          this.snackbar.open(
            'Failed to delete the email group. Please try again',
            'Dismiss',
            {
              duration: 7_500,
            },
          ),
        ),
      ),
    { dispatch: false },
  );
}
