import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-dlh',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        Daylight Harvesting - The daylight sensor limits the output of the lights in the
        zone to conserve energy.
      </p>
      <mat-slide-toggle formControlName="enabled">Enabled</mat-slide-toggle>
      <br />
      <mat-form-field>
        <mat-label>Daylight Gain</mat-label>
        <input
          matInput
          id="daylightGain"
          type="number"
          step="1"
          formControlName="daylightGain"
        />
        <mat-error>
          Must be a whole number between {{ daylightGainMin }} and {{ daylightGainMax }}
        </mat-error>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>Fade Rate</mat-label>
        <input matInput id="fadeRate" type="number" step="1" formControlName="fadeRate" />
        <mat-hint align="end">deciseconds (0.1 s)</mat-hint>
        <mat-error>
          Must be a whole number between {{ fadeRateMin }} and {{ fadeRateMax }}
        </mat-error>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>Minimum Light Level</mat-label>
        <input
          matInput
          id="daylightMinimumLevel"
          type="number"
          step="1"
          formControlName="daylightMinimumLevel"
        />
        <mat-error>
          Must be a whole number between {{ daylightMinimumLevelMin }} and
          {{ daylightMinimumLevelMax }}
        </mat-error>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      mat-slide-toggle {
        margin-top: 16px;
        margin-bottom: 16px;
      }
      mat-form-field {
        margin-bottom: 16px;
      }
    `,
  ],
})
export class BFDLHComponent implements BehaviorForm {
  daylightMinimumLevelMin = 0;
  daylightMinimumLevelMax = 100;
  daylightGainMin = 1;
  daylightGainMax = 32767;
  fadeRateMin = 0;
  fadeRateMax = 32767;
  integerOnly = '[0-9]+';

  form = new UntypedFormGroup({
    daylightGain: new UntypedFormControl(15, [
      Validators.min(this.daylightGainMin),
      Validators.max(this.daylightGainMax),
      Validators.pattern(this.integerOnly),
      Validators.required,
    ]),
    fadeRate: new UntypedFormControl(0, [
      Validators.min(this.fadeRateMin),
      Validators.max(this.fadeRateMax),
      Validators.pattern(this.integerOnly),
      Validators.required,
    ]),
    daylightMinimumLevel: new UntypedFormControl(10, [
      Validators.min(this.daylightMinimumLevelMin),
      Validators.max(this.daylightMinimumLevelMax),
      Validators.pattern(this.integerOnly),
      Validators.required,
    ]),
    enabled: new UntypedFormControl(true),
  });
}
