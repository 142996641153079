import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SliderComponent } from '../slider/slider.component';

@Component({
    selector: 'sui-color-slider',
    template: `
    <canvas #canvas width="1000" height="30"></canvas>
    <div class="suiColorSliderBorder"></div>
    <sui-slider
      [min]="min"
      [max]="max"
      [value]="value"
      [isDisabled]="isDisabled"
      [step]="step"
      (update)="onUpdate($event)"
    >
    </sui-slider>
  `,
    styles: [
        `
      :host {
        display: block;
        width: 100%;
        height: 30px;
        position: relative;
        --color-slider-fill: white;
        margin: 10px 0;
      }

      canvas {
        border-radius: 3px;
        position: absolute;
        top: 0;
        left: 8px;
        width: calc(100% - 16px);
        height: 30px;
        border: 1px solid #d8d8d8;
      }

      .suiColorSliderBorder {
        display: block;
        position: absolute;
        box-sizing: border-box;
        border-radius: 3px;
        top: 0;
        left: 8px;
        width: calc(100% - 16px);
        height: 30px;
      }

      sui-slider {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding-top: 1px;
        padding-left: 1px;
      }

      :host ::ng-deep .mat-slider-track-wrapper {
        display: none;
      }

      :host ::ng-deep .mat-slider-thumb {
        border-color: var(--color-background-card) !important;
        border-width: 4px !important;
        background-color: var(--color-slider-fill) !important;
        width: 42px;
        height: 42px;
        right: -21px;
        bottom: -21px;
        transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      }
    `,
    ],
    standalone: true,
    imports: [SliderComponent],
})
export class ColorSliderComponent implements OnChanges, AfterViewInit {
  @Input() min = 0;
  @Input() max = 100;
  @Input() value = 0;
  @Input() isDisabled = false;
  @Input() step = 1;
  @Input() gradient: Gradient = [
    [0, 'black'],
    [1, 'white'],
  ];
  @Output() update = new EventEmitter<number>();
  @ViewChild('canvas', { static: true }) canvasRef: ElementRef;
  ctx: CanvasRenderingContext2D;

  constructor(private elementRef: ElementRef) {}

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;

    this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    if (this.ctx) {
      this.renderGradient();
      this.renderValue();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const needsReRender = 'gradient' in changes || 'value' in changes;

    if (needsReRender && this.ctx) {
      this.renderGradient();
      this.renderValue();
    }
  }

  renderGradient(): void {
    const gradient = this.ctx.createLinearGradient(0, 0, 1000, 0);

    for (const [stop, color] of this.gradient) {
      gradient.addColorStop(stop, color);
    }

    this.ctx.fillStyle = gradient;
    this.ctx.fillRect(0, 0, 1000, 30);
  }

  renderValue(): void {
    const range = this.max - this.min;
    const rangeBoundLevel = this.value - this.min;
    const percent = Math.floor((rangeBoundLevel / range) * 999);
    const pixel = this.ctx.getImageData(percent, 0, 1, 1).data;
    const r = pixel[0];
    const g = pixel[1];
    const b = pixel[2];

    this.element.style.setProperty('--color-slider-fill', `rgb(${r}, ${g}, ${b})`);
  }

  onUpdate(value: number): void {
    if (this.ctx) {
      this.value = value;
      this.update.emit(value);
      this.renderValue();
    }
  }
}

export type Gradient = [number, string][];

export const COLOR_SLIDER_DIRECTIVES = [ColorSliderComponent];
