import { EnergyConsumptionModelFragment, Interval } from '@spog-ui/graphql/types';
import { DateTime } from 'luxon';

/**
 * EnergyConsumption from our GraphQL API
 */
export type EnergyConsumptionGQLModel = EnergyConsumptionModelFragment;

export interface EnergyConsumptionInternalModel {
  start: string;
  end: string;
  timezone: string;
  interval: Interval;
  reports: {
    timestamp: string;
    energy: number;
    cost?: string | null;
  }[];
}

/**
 * Normalizes a Energy Consumption returned from our GraphQL API
 * into a model ready to be put in the store
 */
export function toEnergyConsumptionInternalModelFromGQL(
  gql: EnergyConsumptionGQLModel,
): EnergyConsumptionInternalModel {
  return {
    start: gql.start,
    end: gql.end,
    timezone: gql.timezone,
    interval: gql.interval,
    reports: gql.reports.map(r => ({
      timestamp: r.timestamp,
      energy: r.energy.kWh,
      cost: r.cost,
    })),
  };
}

export function getGraphData(
  energyConsumption?: EnergyConsumptionInternalModel,
): { data: [DateTime, number][]; units: string } {
  if (energyConsumption && energyConsumption.reports) {
    //  If cost data is available, return cost data for sparkline graph
    const costData = energyConsumption.reports
      .filter((item: any) => item.cost)
      .map(
        (item: any) =>
          [DateTime.fromISO(item.timestamp), parseFloat(item.cost.replace('$', ''))] as [
            DateTime,
            number,
          ],
      );

    if (costData && costData.length > 0) {
      return { data: costData, units: 'USD' };
    }

    //  If cost data is not availab, return energy consumption data for sparkline graph
    const energyConsumptionData = energyConsumption.reports.map(
      (item: any) =>
        [DateTime.fromISO(item.timestamp), item.energy] as [DateTime, number],
    );
    return { data: energyConsumptionData, units: 'kWh' };
  }
  return { data: [], units: '' };
}
