import { scaleLinear } from 'd3';

/** Temperature settings */
export const MIN_TEMPERATURE = 45;
export const MAX_TEMPERATURE = 90;
export const MIN_TEMP_DIFF = 3;

export const MIN_HEAT_SETPOINT = MIN_TEMPERATURE;
export const MAX_HEAT_SETPOINT = MAX_TEMPERATURE - MIN_TEMP_DIFF;
export const MIN_COOL_SETPOINT = MIN_TEMPERATURE + MIN_TEMP_DIFF;
export const MAX_COOL_SETPOINT = MAX_TEMPERATURE;

/** Visualization sizing */
export const SVG_WIDTH = 250;
export const SVG_HEIGHT = 250;

export const RADIUS = 92;

/**
 * These 2 numbers each represent an angle in degrees away from
 * the bottom-most point.
 * ANGLE_RANGE_MIN translates to MIN_TEMPERATURE on the arc
 * ANGLE_RANGE_MAX translates to MAX_TEMPERATURE on the arc
 */
export const ANGLE_RANGE_MIN = 50;
export const ANGLE_RANGE_MAX = 310;

/**
 * We are using purple for our cool color so that we are not
 * introducing color confusion between the application of
 * Synapse blue and things that are cold.
 */
export const COOL_COLOR = '#6c61f7';

/**
 * A constant scale that maps any temperature in deg F into
 * degreees around a circle
 */
export const mapTemperatureToDegrees = scaleLinear()
  .domain([MIN_TEMPERATURE, MAX_TEMPERATURE])
  .range([ANGLE_RANGE_MIN, ANGLE_RANGE_MAX]);
