import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TransformModel } from '@spog-ui/map-tools/models';
import { MapToolsActions } from '../actions';

@Injectable()
export abstract class MapToolsState {
  abstract transform$: Observable<TransformModel>;
  abstract browserSize$: Observable<{ width: number; height: number }>;
  abstract zoomAndPanEnabled$: Observable<boolean>;
  abstract areaSelectEnabled$: Observable<boolean>;
  abstract freeformSelectEnabled$: Observable<boolean>;
  abstract dispatch(action: MapToolsActions.Union): void;
}
