import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SuiAlertModule, ButtonComponent } from '@spog-ui/shared/components';
import { EulaFormComponent } from './eula-form.component';

@NgModule({
  imports: [CommonModule, SuiAlertModule, ButtonComponent, ReactiveFormsModule],
  declarations: [EulaFormComponent],
  exports: [EulaFormComponent],
})
export class EulaFormComponentModule {}
