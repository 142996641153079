import { Component } from '@angular/core';

@Component({
    selector: 'sui-dialog-title',
    template: `
    <span class="header">
      <ng-content></ng-content>
    </span>
  `,
    styles: [
        `
      .header {
        font-weight: 500;
        font-size: 18px;
        padding-bottom: 16px;
        display: block;
      }
    `,
    ],
    standalone: true,
})
export class DialogTitleComponent {}
