import { createAction } from '@ngrx/store';
import { MapEditorResult } from '@spog-ui/shared/models/map-editor';

export const savePositionsSuccess = createAction(
  '[Map Editor API] Save Positions Success',
  (result: MapEditorResult) => ({ result }),
);
export const savePositionsFailure = createAction(
  '[Map Editor API] Save Positions Failure',
  (result: MapEditorResult, error?: any) => ({ result, error }),
);
