import { createAction } from '@ngrx/store';
import { UserInternalModel } from '@spog-ui/shared/models/users';

export const enterAction = createAction('[Users Page] Enter');
export const copyIDAction = createAction(
  '[Users Page] Copy User ID',
  (user: UserInternalModel) => ({ user }),
);
export const createUserAction = createAction(
  '[Users Page] Create User',
  (user: UserInternalModel) => ({
    user,
  }),
);
export const filterAction = createAction('[Users Page] Filter', (searchTerm: string) => {
  return { searchTerm };
});
export const paginateAction = createAction(
  '[Users Page] Paginate',
  (pageIndex: number, pageSize: number) => {
    return { pageIndex, pageSize };
  },
);
export const deleteAction = createAction(
  '[Users Page] Delete',
  (user: UserInternalModel) => {
    return { user };
  },
);
export const deleteConfirmAction = createAction(
  '[Users Page] Confirm Delete',
  (user: UserInternalModel) => {
    return { user };
  },
);
export const deleteCancelAction = createAction('[Users Page] Cancel Delete');
export const resetPasswordAction = createAction(
  '[Users Page] Reset Password',
  (user: UserInternalModel) => {
    return { user };
  },
);
export const resetPasswordConfirmAction = createAction(
  '[Users Page] Confirm Reset Password',
  (user: UserInternalModel) => {
    return { user };
  },
);
export const resetPasswordCancelAction = createAction(
  '[Users Page] Cancel Reset Password',
);
export const showUserSitesPreviewAction = createAction(
  '[Admin / Users] Get Sites for User',
  (userId: string) => ({ userId }),
);
export const closeUserSitesPreviewAction = createAction(
  '[Admin / Users] Close User Sites Preview',
);
