import {
  CreateSense420SensorInput,
  IndieSensorModelFragment as IndieSensorGQLModel,
  BatteryPoweredIndustrialSensorBatteryLevel as IndieSensorBatteryLevel,
  BatteryPoweredIndustrialSensorUpgradeStatus as IndieSensorUpgradeStatus,
  IndustrialSensorDataTypeInput,
  IndustrialSensorDefinedDataEnum,
  UpdateSense420SensorInput,
  ModbusFunctionCode,
} from '@spog-ui/graphql/types';
import { DateTime } from 'luxon';

const DAYS_UNTIL_READING_CONSIDERED_OLD = 1;
const MINUTES_UNTIL_UPGRADE_CONSIDERED_FAILED = 60; // this should cover 3 failed DAQ messages (15min ea) and ample time for a rolodex

export { IndieSensorGQLModel, IndieSensorBatteryLevel, IndieSensorUpgradeStatus };

/**
 * @todo Jake Harris
 * We can remove the null...ability of
 * siteControllerId after all sensors have
 * reported in at least once since this feature
 * landed. Check in again in 6mo.
 * (Written 4/20/20)
 */
export interface IndieSensorStateModel {
  lastValue: number;
  lastValueRead: string;
  batteryLevel?: IndieSensorBatteryLevel;
  siteControllerId?: string | null; // null will only happen for sensors that haven't reported since this feature landed in the backend
  upgradeStatus?: IndieSensorUpgradeStatus | null | undefined;
}

export interface IndieSensorInternalModel {
  id: string;
  name: string;
  snapaddr?: string;

  floorPlanX?: number | null;
  floorPlanY?: number | null;
  floorPlanId?: string | null;

  minValue?: number;
  maxValue?: number;
  dataType: IndustrialSensorDataType;
  units: string;

  state?: IndieSensorStateModel;

  hardwareType: IndustrialSensorHardwareType;
}

export interface Bridge485MetricInternalModel extends IndieSensorInternalModel {
  id: string;
  name: string;
  dataType: IndustrialSensorDataType;
  units: string;
  hardwareType: 'Bridge485Metric';

  state?: IndieSensorStateModel;

  conversion: { polynomial?: number[] };
  dataFormat: string;
  dataAddress: number;
  unitId: number;
  functionCode: ModbusFunctionCode;

  source: { id: string };
}

export function isBridge485Metric(
  sensor: IndieSensorInternalModel | IndieSensorDetailsViewModel,
): sensor is Bridge485MetricInternalModel {
  return sensor.hardwareType === 'Bridge485Metric';
}

export type IndustrialSensorHardwareType = IndieSensorGQLModel['__typename'];

export type CreatableHardwareType = Exclude<
  IndustrialSensorHardwareType,
  'VirtualIndustrialSensor'
>;

export function isIndustrialSensorHardwareType(
  hardwareType: unknown,
): hardwareType is IndustrialSensorHardwareType {
  return (
    typeof hardwareType === 'string' &&
    [
      'Bridge485Sensor',
      'Sense420Sensor',
      'VirtualIndustrialSensor',
      'Bridge485Metric',
    ].includes(hardwareType)
  );
}

export function isCreatableHardwareType(
  hardwareType: IndustrialSensorHardwareType,
): hardwareType is CreatableHardwareType {
  return (
    isIndustrialSensorHardwareType(hardwareType) &&
    ['Bridge485Sensor', 'Sense420Sensor'].includes(hardwareType)
  );
}

export interface IndieSensorWSModel {
  id: string;
  name: string;
  type: IndustrialSensorHardwareType;
  dataType: IndustrialSensorDataType;
  units: string;
  maxValue: number;
  minValue: number;
  siteId: string;
  snapaddr: string;
  state?: IndieSensorGQLModel['state'];
  floorPlanId?: string | null;
  floorPlanX?: number | null;
  floorPlanY?: number | null;
}

export interface IndieSensorStatusApiModel {
  lastValue: number;
  lastMessageReceived: string;
  siteControllerId: string;
  batteryLevel: IndieSensorBatteryLevel;
  upgradeStatus: IndieSensorUpgradeStatus | null;
}

export interface SenseLegendViewModel {
  ok: number;
  pending: number;
  alarmed: number;
}

export interface SenseLegendFilterModel {
  filteringOk: boolean;
  filteringPending: boolean;
  filteringAlarmed: boolean;
}

export function toIndieSensorInternalModelFromGQL(
  apiModel: IndieSensorGQLModel,
): IndieSensorInternalModel {
  const state = toIndieSensorStateInternalModelFromGQL(apiModel.state);

  switch (apiModel.__typename) {
    case 'Sense420Sensor': {
      const indieSensor: IndieSensorInternalModel = {
        id: apiModel.id,
        name: apiModel.name,
        snapaddr: apiModel.snapaddr,
        dataType: apiModel.dataType,
        units: apiModel.units,
        minValue: apiModel.minValue,
        maxValue: apiModel.maxValue,
        ...(apiModel.floorPlanId ? { floorPlanId: apiModel.floorPlanId } : {}),
        ...(apiModel.floorPlanX ? { floorPlanX: apiModel.floorPlanX } : {}),
        ...(apiModel.floorPlanY ? { floorPlanY: apiModel.floorPlanY } : {}),
        hardwareType: apiModel.__typename,
      };

      if (state) {
        indieSensor.state = state;
      }

      return indieSensor;
    }
    case 'VirtualIndustrialSensor': {
      const indieSensor: IndieSensorInternalModel = {
        id: apiModel.id,
        name: apiModel.name,
        dataType: apiModel.dataType,
        units: apiModel.units,
        hardwareType: apiModel.__typename,
      };

      if (state) {
        indieSensor.state = state;
      }

      return indieSensor;
    }
    case 'Bridge485Metric': {
      const indieSensor: Bridge485MetricInternalModel = {
        id: apiModel.id,
        name: apiModel.name,
        dataType: apiModel.dataType,
        units: apiModel.units,
        hardwareType: apiModel.__typename,

        conversion: {
          ...(apiModel.conversion.polynomial
            ? { polynomial: apiModel.conversion.polynomial }
            : {}),
        },
        dataAddress: apiModel.dataAddress,
        dataFormat: apiModel.dataFormat,
        functionCode: apiModel.functionCode,
        source: apiModel.source,
        unitId: apiModel.unitId,
      };

      if (state) {
        indieSensor.state = state;
      }

      return indieSensor;
    }
    default: {
      throw new Error(
        'Could not narrow the type of IndustrialSensor instance to a known implementation.',
      );
    }
  }
}

export function toIndieSensorInternalModelFromWS(
  ws: IndieSensorWSModel,
): IndieSensorInternalModel | Bridge485MetricInternalModel {
  const state = toIndieSensorStateInternalModelFromGQL(ws.state);

  return {
    id: ws.id,
    name: ws.name,
    hardwareType: ws.type,
    snapaddr: ws.snapaddr,
    dataType: ws.dataType,
    units: ws.units,
    minValue: ws.minValue,
    maxValue: ws.maxValue,
    floorPlanId: ws.floorPlanId,
    floorPlanX: ws.floorPlanX,
    floorPlanY: ws.floorPlanY,
    state,
  };
}

export function toIndieSensorStateInternalModelFromGQL(
  apiModel: IndieSensorGQLModel['state'],
): IndieSensorStateModel | undefined {
  if (!apiModel) return;

  // the conditional is structured this way because state updates
  // prior to adding Virtual sensors will use an older format (i.e. no typename)
  // and we still need to parse those correctly as Sense420SensorState
  switch (apiModel.__typename) {
    case 'Bridge485MetricState':
    case 'VirtualIndustrialSensorState': {
      return {
        lastValue: apiModel.lastValue,
        lastValueRead: apiModel.lastMessageReceived,
      };
    }
    case undefined:
    case 'Sense420SensorState': {
      return {
        lastValue: apiModel.lastValue,
        lastValueRead: apiModel.lastMessageReceived,
        siteControllerId: apiModel.siteControllerId ?? null,
        batteryLevel: apiModel.batteryLevel,
        upgradeStatus: apiModel.upgradeStatus,
      };
    }
  }
}

export type IndustrialSensorCustomDataType = { name: string; units: string };

export type IndustrialSensorDefinedDataType = { type: IndustrialSensorDefinedDataEnum };

export type IndustrialSensorDataType =
  | IndustrialSensorDefinedDataType
  | IndustrialSensorCustomDataType;

export function isIndustrialSensorDefinedDataType(
  dataType: IndustrialSensorDataType,
): dataType is IndustrialSensorDefinedDataType {
  return typeof (dataType as IndustrialSensorDefinedDataType).type !== 'undefined';
}

export enum IndieSensorStatus {
  OK = 0b000_0000,
  LowBattery = 0b000_0001,
  EmptyBattery = 0b000_0010,
  Pending = 0b000_0100,
  OldReading = 0b000_1000,
  UpgradePending = 0b001_0000,
  Upgrading = 0b010_0000,
  UpgradeFailed = 0b100_0000,
}

export interface IndieSensorMapViewModel {
  id: string;
  name: string;
  snapaddr?: string;
  dataType: IndustrialSensorDataType;
  floorPlanX: number;
  floorPlanY: number;
  status: number;
  state?: IndieSensorStateModel;
}

/**
 * @todo Jake Harris
 * Consider: Bridge485 has a lot of fields the others don't
 * 1. Create a details view model that only includes the
 *    properties that all sensors have
 * 2. Create differentiated sensor types for the different
 *    types of sensors
 * 3. Update selector methods to generate correct subtypes
 */
export interface IndieSensorDetailsViewModel {
  id: string;
  name: string;
  snapaddr?: string;
  dataType: IndustrialSensorDataType;
  status: number;
  state?: IndieSensorStateModel;
  hardwareType: IndustrialSensorHardwareType;
}

export interface Bridge485SensorDetailsViewModel extends IndieSensorDetailsViewModel {
  id: string;
  name: string;
  dataType: IndustrialSensorDataType;
  status: number;
  state?: IndieSensorStateModel;
  hardwareType: 'Bridge485Metric';

  // Bridge485 specific fields
  // source: string;
  dataFormat: string;

  /**
   * Unit ID of Modbus device that is being read. Valid range is 1-247.
   */
  unitId: number;
  /**
   * Modbus register number to read. Valid range is 1-9999.
   *
   * Note: This is the Modbus register number, not the register address. For example, if the
   *   register number is 1, the underlying register address is 0x00.
   */
  dataAddress: number;
  functionCode: ModbusFunctionCode;
  conversion: { polynomial: number[] };
}

export type Sense420IndustrialSensorRequiredProps = Pick<
  IndieSensorInternalModel,
  'name' | 'snapaddr' | 'dataType' | 'minValue' | 'maxValue'
>;

export function isIndieSensorOk(
  sensor:
    | IndieSensorMapViewModel
    | IndieSensorDetailsViewModel
    | Bridge485SensorDetailsViewModel,
) {
  return sensor.status === IndieSensorStatus.OK;
}

export function isIndieSensorLowBattery(
  sensor: IndieSensorMapViewModel | IndieSensorDetailsViewModel,
) {
  return Boolean(sensor.status & IndieSensorStatus.LowBattery);
}

export function isIndieSensorEmptyBattery(
  sensor: IndieSensorMapViewModel | IndieSensorDetailsViewModel,
) {
  return Boolean(sensor.status & IndieSensorStatus.EmptyBattery);
}

export function isIndieSensorFullBattery(
  sensor: IndieSensorMapViewModel | IndieSensorDetailsViewModel,
) {
  return !isIndieSensorLowBattery(sensor) && !isIndieSensorEmptyBattery(sensor);
}

export function isIndieSensorPending(
  sensor:
    | IndieSensorMapViewModel
    | IndieSensorDetailsViewModel
    | Bridge485SensorDetailsViewModel,
) {
  return Boolean(sensor.status & IndieSensorStatus.Pending);
}

export function isIndieSensorOldReading(
  sensor:
    | IndieSensorMapViewModel
    | IndieSensorDetailsViewModel
    | Bridge485SensorDetailsViewModel,
) {
  return Boolean(sensor.status & IndieSensorStatus.OldReading);
}

export function isIndieSensorConnected(
  sensor:
    | IndieSensorMapViewModel
    | IndieSensorDetailsViewModel
    | Bridge485SensorDetailsViewModel,
) {
  return !isIndieSensorOldReading(sensor);
}

export function isIndieSensorUpgradePending(
  sensor:
    | IndieSensorMapViewModel
    | IndieSensorDetailsViewModel
    | Bridge485SensorDetailsViewModel,
) {
  return Boolean(sensor.status & IndieSensorStatus.UpgradePending);
}

export function isIndieSensorUpgrading(
  sensor:
    | IndieSensorMapViewModel
    | IndieSensorDetailsViewModel
    | Bridge485SensorDetailsViewModel,
) {
  return Boolean(sensor.status & IndieSensorStatus.Upgrading);
}

export function isIndieSensorUpgradeFailed(
  sensor:
    | IndieSensorMapViewModel
    | IndieSensorDetailsViewModel
    | Bridge485SensorDetailsViewModel,
) {
  return Boolean(sensor.status & IndieSensorStatus.UpgradeFailed);
}

/**
 * @note Display logic
 * - Most alarms override upgrades, but often don't interact with them.
 * - If the batteries are dead, we can't start an upgrade -- easy.
 * - If we haven't heard from the sensor in 24hrs, we can't start an upgrade -- easy.
 * - If we started an upgrade but it failed, it should definitely show the alarm -- easy.
 * - If we have low batteries, we actually CAN start an upgrade. This one is tougher.
 *   - Upgrade status is more important to the user than low battery status, so we'll show that.
 *   - This also means low battery alarms get overridden by the upgrade-pending state.
 *   - If this is no longer true in the future, this logic should be changed.
 * - Also, we can't fail an upgrade if we're in any upgrade-related state, so those are
 *     mutually exclusive.
 * - This all comes out to mean that all alarms that are not mutually exclusive with
 *     upgrade status are overridden by upgrade status.
 */
export function isIndieSensorAlarmed(
  sensor:
    | IndieSensorMapViewModel
    | IndieSensorDetailsViewModel
    | Bridge485SensorDetailsViewModel,
) {
  return (
    isIndieSensorEmptyBattery(sensor) ||
    isIndieSensorLowBattery(sensor) ||
    isIndieSensorOldReading(sensor) ||
    isIndieSensorUpgradeFailed(sensor)
  );
}

export function isIndieSensorAlarmedAndNotUpgrading(sensor: IndieSensorMapViewModel) {
  return (
    isIndieSensorAlarmed(sensor) &&
    !(isIndieSensorUpgrading(sensor) || isIndieSensorUpgradePending(sensor))
  );
}

export function isIndieSensorReadingTypePower(
  sensor:
    | IndieSensorMapViewModel
    | IndieSensorDetailsViewModel
    | Bridge485SensorDetailsViewModel
    | IndieSensorInternalModel,
) {
  return (
    isIndustrialSensorDefinedDataType(sensor.dataType) &&
    sensor.dataType.type === IndustrialSensorDefinedDataEnum.POWER
  );
}

export function getIndieSensorStatus(state: IndieSensorStateModel, systemTime: DateTime) {
  if (!state) return IndieSensorStatus.Pending;

  let bitFlags = IndieSensorStatus.OK;

  // Battery Level
  if (state.batteryLevel === IndieSensorBatteryLevel.LOW) {
    bitFlags |= IndieSensorStatus.LowBattery;
  } else if (state.batteryLevel === IndieSensorBatteryLevel.EMPTY) {
    bitFlags |= IndieSensorStatus.EmptyBattery;
  }

  // Old Reading
  const timeOfLastMessage = DateTime.fromISO(state.lastValueRead);
  const daysSinceLastMessage = Math.abs(timeOfLastMessage.diff(systemTime, 'days').days);

  if (daysSinceLastMessage >= DAYS_UNTIL_READING_CONSIDERED_OLD) {
    bitFlags |= IndieSensorStatus.OldReading;
  }

  // Upgrade Status
  if (state.upgradeStatus) {
    const minutesSinceLastMessage = Math.abs(
      timeOfLastMessage.diff(systemTime, 'minutes').minutes,
    );

    if (state.upgradeStatus === IndieSensorUpgradeStatus.UPGRADE_PENDING) {
      bitFlags |= IndieSensorStatus.UpgradePending;
    } else if (
      state.upgradeStatus === IndieSensorUpgradeStatus.UPGRADE_IN_PROGRESS &&
      minutesSinceLastMessage >= MINUTES_UNTIL_UPGRADE_CONSIDERED_FAILED
    ) {
      bitFlags |= IndieSensorStatus.UpgradeFailed;
    } else if (state.upgradeStatus === IndieSensorUpgradeStatus.UPGRADE_IN_PROGRESS) {
      bitFlags |= IndieSensorStatus.Upgrading;
    }
  }
  return bitFlags;
}

export function getIndieSensorLastReadingDiff(
  timeZone: string,
  siteTime: DateTime,
  state?: IndieSensorStateModel,
): {
  date: string;
  diff: string;
} {
  if (!state) return { date: '', diff: '' };

  const readTime = DateTime.fromISO(state.lastValueRead, { zone: timeZone });
  const { minutes } = readTime.diff(siteTime, 'minutes');
  let readingDiff = '';

  if (Math.abs(minutes) < 1) {
    readingDiff = 'moments ago';
  } else {
    const relativeDiff = readTime.toRelative({
      base: siteTime,
    });

    readingDiff = relativeDiff || 'moments ago';
  }

  const readingDateTime = readTime.toLocaleString(DateTime.DATETIME_FULL);

  return {
    date: readingDateTime,
    diff: readingDiff,
  };
}

export function getSenseLegendViewModel(
  indieSensors: IndieSensorMapViewModel[],
): SenseLegendViewModel {
  return indieSensors.reduce(
    (counts, indieSensor) => {
      const isOk = isIndieSensorOk(indieSensor);
      const isPending = isIndieSensorPending(indieSensor);
      const isNotAlarmed = !isIndieSensorAlarmed(indieSensor);

      if (isOk && isNotAlarmed) {
        ++counts.ok;
      } else if (isPending && isNotAlarmed) {
        ++counts.pending;
      } else {
        ++counts.alarmed;
      }

      return counts;
    },
    { alarmed: 0, ok: 0, pending: 0 },
  );
}

/**
 * All of the defined sensor types, exluding power, and their assumed units.
 */
export const INDIE_SENSOR_CUSTOM_UNITS = new Map([
  ['Concentration', 'ppm'],
  ['Current', 'A'],
  ['Differential Pressure', 'inH2O'],
  ['Flow', 'cfm'],
  ['Humidity', '%H'],
  ['Mass', 'lbs'],
  ['Percentage', '%'],
  ['Pressure', 'psi'],
  ['Temperature', '°C'],
  ['Voltage', 'V'],
]);

/**
 * All of the defined sensor types, and their assumed units.
 */
const INDIE_SENSOR_DEFINED_DATA_TYPE_UNITS = new Map([
  ['CONCENTRATION', 'ppm'],
  ['CURRENT', 'A'],
  ['DIFFERENTIAL_PRESSURE', 'inH2O'],
  ['FLOW', 'cfm'],
  ['HUMIDITY', '%H'],
  ['MASS', 'lbs'],
  ['PERCENTAGE', '%'],
  ['POWER', 'W'],
  ['PRESSURE', 'psi'],
  ['TEMPERATURE', '°C'],
  ['VOLTAGE', 'V'],
]);

/**
 * Map the defined data types to their presentation names.
 */
const INDIE_SENSOR_READING_TYPES = new Map([
  [IndustrialSensorDefinedDataEnum.CONCENTRATION, 'Concentration'],
  [IndustrialSensorDefinedDataEnum.CURRENT, 'Current'],
  [IndustrialSensorDefinedDataEnum.DIFFERENTIAL_PRESSURE, 'Differential Pressure'],
  [IndustrialSensorDefinedDataEnum.FLOW, 'Flow'],
  [IndustrialSensorDefinedDataEnum.HUMIDITY, 'Humidity'],
  [IndustrialSensorDefinedDataEnum.MASS, 'Mass'],
  [IndustrialSensorDefinedDataEnum.PERCENTAGE, 'Percentage'],
  [IndustrialSensorDefinedDataEnum.POWER, 'Power'],
  [IndustrialSensorDefinedDataEnum.PRESSURE, 'Pressure'],
  [IndustrialSensorDefinedDataEnum.TEMPERATURE, 'Temperature'],
  [IndustrialSensorDefinedDataEnum.VOLTAGE, 'Voltage'],
]);

export function getIndieSensorUnits(dataType: IndustrialSensorDataType) {
  if (isIndustrialSensorDefinedDataType(dataType)) {
    return INDIE_SENSOR_DEFINED_DATA_TYPE_UNITS.get(dataType.type) ?? 'Unknown';
  } else {
    return dataType.units;
  }
}

export function getIndieSensorReadingType(dataType: IndustrialSensorDataType) {
  if (isIndustrialSensorDefinedDataType(dataType)) {
    return INDIE_SENSOR_READING_TYPES.get(dataType.type) ?? 'Unknown';
  } else {
    return dataType.name;
  }
}

export function fromIndieSensorReadingType(
  readingType: string,
): IndustrialSensorDefinedDataEnum | null {
  const pair = Array.from(INDIE_SENSOR_READING_TYPES).find(
    pair => pair[1] === readingType,
  );
  return pair ? pair[0] : null;
}

function toSense420SensorCustomDataTypeInput(name: string, units: string) {
  return {
    name,
    units,
  };
}

function toSense420SensorDataTypeInput(
  dataType: IndustrialSensorDataType,
): IndustrialSensorDataTypeInput {
  return isIndustrialSensorDefinedDataType(dataType)
    ? { type: dataType.type }
    : { custom: toSense420SensorCustomDataTypeInput(dataType.name, dataType.units) };
}

export function toCreateSense420SensorInput(
  siteId: string,
  indieSensor: Sense420IndustrialSensorRequiredProps,
): CreateSense420SensorInput {
  return {
    siteId,
    name: indieSensor.name,
    snapaddr: indieSensor.snapaddr!,
    minValue: indieSensor.minValue!,
    maxValue: indieSensor.maxValue!,
    dataType: toSense420SensorDataTypeInput(indieSensor.dataType),
  };
}

export function toUpdateSense420SensorInput(
  id: string,
  indieSensor: Sense420IndustrialSensorRequiredProps,
): UpdateSense420SensorInput {
  return {
    id,
    name: indieSensor.name,
    snapaddr: indieSensor.snapaddr!,
    minValue: indieSensor.minValue!,
    maxValue: indieSensor.maxValue!,
    dataType: toSense420SensorDataTypeInput(indieSensor.dataType),
  };
}
