import { createReducer, on } from '@ngrx/store';
import { AddSitePageActions, SiteApiActions } from '@spog-ui/admin/actions';

export interface Shape {
  isLoading: boolean;
  isSaving: boolean;
}

export const initialState: Shape = {
  isLoading: true,
  isSaving: false,
};

export const reducer = createReducer(
  initialState,
  on(AddSitePageActions.enterAction, () => {
    return initialState;
  }),
  on(SiteApiActions.loadModelsSuccessAction, state => {
    return { ...state, isLoading: false };
  }),
  on(AddSitePageActions.addSiteAction, state => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(SiteApiActions.addSiteFailureAction, state => {
    return {
      ...state,
      isSaving: false,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSaving = (state: Shape) => state.isSaving;
