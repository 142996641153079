import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { IfUserCanGuard } from '@spog-ui/current-user/permissions';
import {
  UtilityServicesPageComponent,
  UtilityServicesPageComponentModule,
} from './components/utility-services-page';
import {
  AddUtilityServicePageComponent,
  AddUtilityServicePageComponentModule,
} from './components/add-utility-service-page';
import {
  EditUtilityServicePageComponent,
  EditUtilityServicePageComponentModule,
} from './components/edit-utility-service-page';

export const routes: Route[] = [
  {
    path: '',
    component: UtilityServicesPageComponent,
  },
  {
    path: 'add',
    component: AddUtilityServicePageComponent,
    canActivate: [IfUserCanGuard],
    data: { permissions: ['ssc:siteAdmin'] },
  },
  {
    path: ':activeUtilityServiceId',
    component: EditUtilityServicePageComponent,
    canActivate: [IfUserCanGuard],
    data: { permissions: ['ssc:siteAdmin'] },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    UtilityServicesPageComponentModule,
    AddUtilityServicePageComponentModule,
    EditUtilityServicePageComponentModule,
  ],
})
export class UtilityServicesRoutingModule {}
