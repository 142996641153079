import { createAction } from '@ngrx/store';
import { SceneZoneApiModel } from '@spog-ui/shared/models/scene-zone-behaviors';
import { SceneApplication } from '@spog-ui/shared/models/scenes';

export const enterAction = createAction(
  '[Add Scene Page] Enter | @spog-sui/scenes',
  (application: SceneApplication) => ({ application }),
);
export const leaveAction = createAction('[Add Scene Page] Leave | @spog-sui/scenes');
export const addSceneAction = createAction(
  '[Add Scene Page] Add Scene | @spog-sui/scenes',
  (scene: SceneZoneApiModel) => {
    return { scene };
  },
);

export type Union =
  | ReturnType<typeof enterAction>
  | ReturnType<typeof leaveAction>
  | ReturnType<typeof addSceneAction>;

export const Types = {
  Enter: enterAction.type,
  Leave: leaveAction.type,
  AddScene: addSceneAction.type,
};
