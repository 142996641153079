import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { UserApiService } from '@spog-ui/shared/services';
import { PromptService } from '@sui/prompt';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { OrgAdminUsersApiActions, OrgAdminUsersPageActions } from '../actions';

export const resetPasswordSuccessSnackbarSettings = {
  message: 'Password reset email sent',
  action: 'Dismiss',
  config: {
    duration: 7_500,
  },
};

export const resetPasswordFailureSnackbarSettings = {
  message: 'Failed to reset user password. Please try again',
  action: 'Dismiss',
  config: {
    duration: 7_500,
  },
};

@Injectable()
export class OrganizationUserPageEffects {
  constructor(
    private actions$: Actions,
    readonly promptService: PromptService,
    readonly userApiService: UserApiService,
    readonly snackbar: MatSnackBar,
  ) {}

  confirmUserRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminUsersPageActions.confirmRemoveOrganizationUserAction),
      concatMap(action =>
        this.promptService
          .open({
            title: 'Remove user from organization?',
            description: '',
            cancelLabel: 'Cancel',
            confirmLabel: 'Remove',
            confirmColor: 'warn',
          })
          .pipe(
            map(confirmed =>
              confirmed
                ? OrgAdminUsersPageActions.removeOrganizationUserAction(action.user)
                : OrgAdminUsersPageActions.cancelRemoveOrganizationUserAction(),
            ),
          ),
      ),
    ),
  );

  resetPasswordPrompt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminUsersPageActions.resetPasswordAction),
      exhaustMap(action =>
        this.promptService
          .open({
            title: 'Reset User Password?',
            description:
              "The user will be sent an e-mail to the user's configured email address with a link to reset their password. They will still be able to continue to log in with their old password until it is changed.",
            cancelLabel: 'Cancel',
            confirmLabel: 'Reset Password',
            confirmColor: 'warn',
          })
          .pipe(
            map(confirmed =>
              confirmed
                ? OrgAdminUsersPageActions.resetPasswordConfirmAction(action.user)
                : OrgAdminUsersPageActions.resetPasswordCancelAction(),
            ),
          ),
      ),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminUsersPageActions.resetPasswordConfirmAction),
      switchMap(({ user }) =>
        this.userApiService.resetPassword(user.id).pipe(
          map(() => OrgAdminUsersApiActions.resetPasswordSuccessAction(user)),
          catchError((response: HttpErrorResponse) =>
            of(
              OrgAdminUsersApiActions.resetPasswordFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  notifyOnPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgAdminUsersApiActions.resetPasswordSuccessAction),
        tap(() =>
          this.snackbar.open(
            resetPasswordSuccessSnackbarSettings.message,
            resetPasswordSuccessSnackbarSettings.action,
            resetPasswordSuccessSnackbarSettings.config,
          ),
        ),
      ),
    { dispatch: false },
  );

  notifyOnPasswordResetError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgAdminUsersApiActions.resetPasswordFailureAction),
        tap(() =>
          this.snackbar.open(
            resetPasswordFailureSnackbarSettings.message,
            resetPasswordFailureSnackbarSettings.action,
            resetPasswordFailureSnackbarSettings.config,
          ),
        ),
      ),
    { dispatch: false },
  );
}
