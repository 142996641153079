import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { DialogService } from '@spog-ui/shared/components';
import { MenuActions } from '@spog-ui/modules/menu-state';
import { OrganizationMenuComponent } from '../components/drawer-organization-selector';

@Injectable()
export class MenuEffects {
  constructor(
    readonly actions$: Actions,
    readonly router: Router,
    readonly dialog: DialogService,
  ) {}

  openOrganizationMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MenuActions.openOrganizationMenu),
        switchMap(action =>
          this.dialog
            .open(OrganizationMenuComponent, {
              organizations: action.organizations,
            })
            .pipe(
              takeUntil(
                this.actions$.pipe(
                  ofType(
                    MenuActions.navigateToOrganization,
                    MenuActions.closeOrganizationMenu,
                  ),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );

  navigateToOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.navigateToOrganization),
      map(action => {
        this.router.navigate(['./admin-organization', action.organizationId]);
        return MenuActions.closeAction();
      }),
    ),
  );
}
