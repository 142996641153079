import { createAction } from '@ngrx/store';

export const calculateDatesAction = createAction(
  '[Schedule Date] Calculate Dates',
  (dates: { today: string; activeDay: string; activeMonth: string }) => {
    return { dates };
  },
);
export const updateActiveDayAndMonthAction = createAction(
  '[Schedule Date] Update Active Day and Month',
  (dayMonth: { activeDay: string; activeMonth: string }) => {
    return { dayMonth };
  },
);
export const updateTodayAction = createAction(
  '[Schedule Date] Update Today',
  (today: string) => {
    return { today };
  },
);
export const updateActiveMonthAction = createAction(
  '[Schedule Date] Update Active Month',
  (activeMonth: string) => {
    return { activeMonth };
  },
);
export const updateActiveDayAction = createAction(
  '[Schedule Date] Update Active Day',
  (activeDay: string) => {
    return { activeDay };
  },
);

export type Union =
  | ReturnType<typeof calculateDatesAction>
  | ReturnType<typeof updateActiveDayAndMonthAction>
  | ReturnType<typeof updateTodayAction>
  | ReturnType<typeof updateActiveMonthAction>
  | ReturnType<typeof updateActiveDayAction>;

export const Types = {
  CalculateDates: calculateDatesAction.type,
  UpdateActiveDayAndMonth: updateActiveDayAndMonthAction.type,
  UpdateToday: updateTodayAction.type,
  UpdateActiveMonth: updateActiveMonthAction.type,
  UpdateActiveDay: updateActiveDayAction.type,
};
