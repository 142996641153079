import { createAction } from '@ngrx/store';

export const enterAction = createAction('[Climate Map Page] Enter');

export const toggleStatusTrayFilterAction = createAction(
  '[Climate Map Page] Toggle Status Tray Filter Ok',
  (
    filteringOk: boolean,
    filteringAlarmed: boolean,
    filteringPrecommissioned: boolean,
  ) => {
    return { filteringOk, filteringAlarmed, filteringPrecommissioned };
  },
);

export const filterAction = createAction(
  '[Climate Map Page] Filter',
  (searchTerm: string) => ({ searchTerm }),
);

export const addClimateZoneAction = createAction('[Climate Map Page] Add Climate Zone');

export const previewClimateZoneThermostatsAction = createAction(
  '[Climate Map Page] Preview Climate Zone Thermostats',
  (previewThermostatsIds: string[]) => {
    return { previewThermostatsIds };
  },
);

export const unpreviewClimateZoneThermostatsAction = createAction(
  '[Climate Map Page] Unpreview Climate Zone Thermostats',
);

export const previewClimateZoneThermostatAction = createAction(
  '[Climate Map Page] Preview Climate Zone Thermostat',
  (previewThermostatId: string) => {
    return { previewThermostatId };
  },
);

export const previewClimateSceneAction = createAction(
  '[Climate Map Page] Preview Climate Scene',
  (climateSceneId: string) => ({ climateSceneId }),
);

export const unpreviewClimateSceneAction = createAction(
  '[Climate Map Page] Unpreview Climate Scene',
);

export const unpreviewClimateZoneThermostatAction = createAction(
  '[Climate Map Page] Unpreview Climate Zone Thermostat',
);
