import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { IndieSensorsApi } from '@spog-ui/indie-sensors/actions';
import { IndieSensorDetailsAPIActions } from '@spog-ui/indie-sensor-details-actions';
import { SocketActions } from '@spog-ui/socket/actions';
import { SenseMapLayerApiActions } from '@spog-ui/sense-map-layer/actions';
import { UtilityServicesApiActions } from '@spog-ui/utility-services/actions';
import { TriggersApiActions } from '@spog-ui/triggers/actions';
import {
  IndieSensorInternalModel,
  toIndieSensorInternalModelFromGQL,
} from '@spog-ui/shared/models/indie-sensors';
import {
  AddEquipmentApiActions,
  EditEquipmentApiActions,
  EquipmentApiActions,
  EquipmentProfileApiActions,
} from '@spog-ui/equipment/actions';
import { EquipmentMapLayerApiActions } from '@spog-ui/equipment-map-layer/actions';
import * as IndieSensorsStateActions from './indie-sensors.actions';
import { EquipmentDetailsApiActions } from '@spog-ui/equipment-details/actions';

export type Shape = EntityState<IndieSensorInternalModel>;

export const adapter = createEntityAdapter({
  sortComparer: (a: IndieSensorInternalModel, b: IndieSensorInternalModel) =>
    a.name.localeCompare(b.name, undefined, { numeric: true }),
});

export const initialState: Shape = adapter.getInitialState();

export const { selectAll, selectTotal, selectEntities } = adapter.getSelectors();

export const reducer = createReducer(
  initialState,
  on(
    IndieSensorsApi.loadSuccessAction,
    IndieSensorsApi.loadIndieSensorsAndControllersSuccessAction,
    (state, action) => {
      return adapter.setAll(action.indieSensors, state);
    },
  ),
  on(
    EquipmentMapLayerApiActions.loadDeferredModelsSuccessAction,
    EquipmentApiActions.loadDeferredModelsSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.industrialSensors, state);
    },
  ),
  on(
    TriggersApiActions.loadAddTriggersPageSuccessAction,
    TriggersApiActions.loadEditTriggersPageSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.sources.indieSensors, state);
    },
  ),
  on(
    SocketActions.sense420StateChangeAction,
    SocketActions.virtualIndustrialSensorStateChangeAction,
    SocketActions.bridge485MetricStateChangeAction,
    (state, action) => {
      return adapter.updateOne(
        {
          id: action.sensor.id,
          changes: { state: action.sensor.state },
        },
        state,
      );
    },
  ),
  on(
    IndieSensorDetailsAPIActions.deleteIndustrialSensorSuccessAction,
    (state, action) => {
      return adapter.removeOne(action.indieSensorId, state);
    },
  ),
  on(SenseMapLayerApiActions.loadSenseMapLayerModelsSuccessAction, (state, action) => {
    return adapter.setAll(action.indieSensors, state);
  }),
  on(
    EquipmentProfileApiActions.loadSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.indieSensors, state);
    },
  ),
  on(
    EditEquipmentApiActions.loadFormDataSuccessAction,
    AddEquipmentApiActions.loadFormDataSuccessAction,
    EquipmentDetailsApiActions.loadEquipmentDetailsModelsSuccessAction,
    (state, action) => {
      return adapter.setAll(action.indieSensors, state);
    },
  ),
  on(
    UtilityServicesApiActions.loadUtilityServicesAndThingsSuccessAction,
    (state, action) => {
      const indieSensors = action.indieSensors.map(sensor =>
        toIndieSensorInternalModelFromGQL(sensor),
      );

      return adapter.setAll(indieSensors, state);
    },
  ),
  on(SocketActions.bulkFloorPlanPlacementAction, (state, action) => {
    const updates = action.placedThings.map(thing => ({
      id: thing.id,
      changes: {
        floorPlanId: thing.floorPlanId,
        floorPlanX: thing.floorPlanX,
        floorPlanY: thing.floorPlanY,
      },
    }));
    return adapter.updateMany(updates, state);
  }),
  on(IndieSensorsStateActions.loadAction, (state, action) => {
    return adapter.addMany(action.indieSensors, state);
  }),
);
