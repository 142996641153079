import { createAction } from '@ngrx/store';

export const enterAction = createAction('[Site Controller Details] Enter');
export const closeAction = createAction('[Site Controller Details] Close');
export const selectSiteControllerAction = createAction(
  '[Site Controller Details] Select Site Controller',
  (siteControllerId: string | null | undefined) => ({ siteControllerId }),
);
export const editAction = createAction('[Site Controller Details] Edit', () => {
  return { redirectUrl: '/admin/site-controllers' };
});
export const remotelyAccessAction = createAction(
  '[Site Controller Details] Remotely Access',
);
export const copyIDAction = createAction(
  '[Site Controller Details] Copy ID',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const copyTunnelPortAction = createAction(
  '[Site Controller Details] Copy Tunnel Port',
  (tunnelPort: number) => ({ tunnelPort }),
);
export const deleteAction = createAction(
  '[Site Controller Details] Delete',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const deleteConfirmAction = createAction(
  '[Site Controller Details] Confirm Delete',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const deleteCancelAction = createAction('[Site Controllers Page] Cancel Delete');
export const initResourcesAction = createAction(
  '[Site Controller Details] Init Resources',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const initResourcesConfirmAction = createAction(
  '[Site Controller Details] Confirm Init Resources',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const initResourcesCancelAction = createAction(
  '[Site Controller Details] Cancel Init Resources',
);
export const movePromptAction = createAction(
  '[Site Controller Details] Move Prompt',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const moveCancelAction = createAction('[Site Controller Admin Page] Move Cancel');
export const moveAction = createAction(
  '[Site Controller Details] Move',
  (formValue: { siteControllerId: string; siteId: string }) => formValue,
);

export const replacePromptAction = createAction(
  '[Site Controller Details] Replace Prompt',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const replaceCancelAction = createAction(
  '[Site Controller Admin Page] Replace Cancel',
);
export const replaceAction = createAction(
  '[Site Controller Details] Replace',
  (formValue: { siteControllerId: string; macAddress: string }) => formValue,
);
export const importPromptAction = createAction(
  '[Site Controller Details] Import Prompt',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const importConfirmAction = createAction(
  '[Site Controller Details] Confirm Import',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const importCancelAction = createAction('[Site Controller Details] Cancel Import');
