import { signalStore, withState, patchState, withMethods } from '@ngrx/signals';

type CurrentUserState = {
  selectSiteViaMap: boolean;
};

const initialState: CurrentUserState = { selectSiteViaMap: false };

export const CurrentUserStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(store => ({
    setSelectSiteMethod(useMap: boolean): void {
      patchState(store, state => ({ selectSiteViaMap: useMap }));
    },
  })),
);