import { Component, TrackByFunction } from '@angular/core';
import * as BigSelectActions from './big-select.actions';
import { BigSelectTab } from './big-select.models';
import { BigSelectState } from './big-select.state';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatTabNav, MatTabLink, MatTabNavPanel } from '@angular/material/tabs';

@Component({
    selector: 'sui-big-select-tabs',
    template: `
    <nav mat-tab-nav-bar mat-stretch-tabs [tabPanel]="tabPanel">
      @for (tab of state.tabs$ | async; track trackByTabTitle($index, tab)) {
        <a
          mat-tab-link
          [attr.data-tab-title]="tab.title"
          [active]="tab.active"
          (click)="onSelectTab(tab)"
          >
          {{ tab.title }}
          @if (tab.totalChecked > 0) {
            <span> ({{ tab.totalChecked }})</span>
          }
        </a>
      }
    </nav>
    
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    `,
  styles: [
    `
      nav {
        background-color: var(--color-background-app-bar);
      }

      a span {
        margin-left: 6px;
        opacity: 0.82;
      }
    `,
    ],
    standalone: true,
    imports: [
        MatTabNav,
        NgFor,
        MatTabLink,
        NgIf,
        MatTabNavPanel,
        AsyncPipe,
    ],
})
export class BigSelectTabsComponent {
  trackByTabTitle: TrackByFunction<BigSelectTab> = (_, tab) => tab.title;

  constructor(readonly state: BigSelectState) {}

  onSelectTab(tab: BigSelectTab) {
    this.state.dispatch(BigSelectActions.selectTabAction(tab));
  }
}
