import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPageFooterComponentModule } from '../landing-page-footer';
import { LandingPageComponent } from './landing-page.component';
import { EffectsModule } from '@ngrx/effects';
import { LandingPageEffects } from '../../effects';

@NgModule({
  imports: [
    CommonModule,
    LandingPageFooterComponentModule,
    EffectsModule.forFeature([LandingPageEffects]),
  ],
  declarations: [LandingPageComponent],
  exports: [LandingPageComponent],
})
export class LandingPageComponentModule {}
