import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BehaviorModel } from '@spog-ui/shared/models/behaviors';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { SceneInternalModel, SceneApplication } from '@spog-ui/shared/models/scenes';
import { SceneZoneApiModel } from '@spog-ui/shared/models/scene-zone-behaviors';
import { AddScenePageActions } from '@spog-ui/scenes/actions';

@Injectable()
export abstract class AddScenePageState {
  abstract application$: Observable<SceneApplication>;
  abstract ready$: Observable<boolean>;
  abstract saving$: Observable<boolean>;
  abstract scenes$: Observable<SceneInternalModel[]>;
  abstract controlZones$: Observable<ZoneInternalModel[]>;
  abstract behaviors$: Observable<BehaviorModel[]>;
  abstract dispatch(action: AddScenePageActions.Union): void;
}

@Component({
  selector: 'scn-add-scene-page',
  template: `
    @if (state.ready$ | async) {
      <scn-scene-form
        [scenes]="state.scenes$ | async"
        [zones]="state.controlZones$ | async"
        [behaviors]="state.behaviors$ | async"
        [disabled]="state.saving$ | async"
        [application]="state.application$ | async"
        (save)="onSave($event)"
        >
      </scn-scene-form>
    } @else {
      <sui-spinner></sui-spinner>
    }
    
    `,
  styles: [
    `
      sui-spinner {
        display: block;
        width: 50px;
        margin: 0 auto;
      }
    `,
  ],
})
export class AddScenePageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  constructor(public state: AddScenePageState, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.data.subscribe(data =>
        this.state.dispatch(AddScenePageActions.enterAction(data.application)),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.state.dispatch(AddScenePageActions.leaveAction());
  }

  onSave($event: SceneZoneApiModel): void {
    this.state.dispatch(AddScenePageActions.addSceneAction($event));
  }
}
