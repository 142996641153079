import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  toBridge485InternalModelFromGQL,
  toUpdateBridge485Input,
} from '@spog-ui/shared/models/bridge-485s';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { toIndieSensorInternalModelFromGQL } from '@spog-ui/shared/models/indie-sensors';
import { toSiteControllerInternalModelFromGQL } from '@spog-ui/shared/models/site-controllers';
import { SiteService } from '@spog-ui/shared/services';
import { Bridge485sStateActions } from '@spog-ui/shared/state/bridge-485s';
import {
  IndieSensorsStateActions,
  SiteControllersStateActions,
} from '@spog-ui/shared/state/core';
import {
  catchError,
  EMPTY,
  exhaustMap,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Bridge485Api, EditBridge485Page } from '../actions';
import { selectEditPageBridge485ToEdit } from '../state';
import { PromptOptions, PromptService } from '@sui/prompt';
import { MatSnackBar } from '@angular/material/snack-bar';

export const PROMPT_OPTIONS: Partial<PromptOptions> = {
  title: 'Update Bridge485 SNAP Address',
  description: `Are you sure you want to update the SNAP address? Confirming this action may affect sensor data collection.`,
  cancelLabel: 'Cancel',
  confirmLabel: 'Update',
  confirmColor: 'warn',
};

@Injectable()
export class EditBridge485PageEffects {
  constructor(
    readonly actions$: Actions,
    readonly store: Store,
    readonly gql: GraphQLAPIService,
    readonly siteService: SiteService,
    readonly location: Location,
    readonly prompt: PromptService,
    private snackbar: MatSnackBar,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditBridge485Page.enterAction),
      mergeMap(() => {
        const siteId = this.siteService.getId();

        if (!siteId) return EMPTY;

        return this.gql.getAllBridge485Models({ siteId }).pipe(
          map(result => {
            const site = result.site;

            if (!site) throw new Error(`No site with id ${siteId} was found.`);

            const bridge485s = site.bridge485s.map(toBridge485InternalModelFromGQL);
            const indieSensors = site.industrialSensors.map(
              toIndieSensorInternalModelFromGQL,
            );
            const siteControllers = site.siteControllers.map(
              toSiteControllerInternalModelFromGQL,
            );

            return Bridge485Api.loadEditPageModelsSuccessAction(
              bridge485s,
              indieSensors,
              siteControllers,
            );
          }),
          catchError(error =>
            of(Bridge485Api.loadEditPageModelsFailureAction(retrieveErrorMessage(error))),
          ),
        );
      }),
    ),
  );

  confirmSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditBridge485Page.saveAction),
      withLatestFrom(this.store.select(selectEditPageBridge485ToEdit)),
      exhaustMap(([action, preEditBridge485]) => {
        const hadExistingSnapaddr = Boolean(preEditBridge485?.snapaddr);
        const submissionHasSnapaddr = Boolean(action.bridge485.snapaddr);
        const areSameSnapaddr =
          preEditBridge485?.snapaddr?.toLowerCase() ===
          action.bridge485.snapaddr?.toLowerCase();

        if (
          hadExistingSnapaddr &&
          ((submissionHasSnapaddr && !areSameSnapaddr) || !submissionHasSnapaddr)
        ) {
          return this.prompt
            .open(PROMPT_OPTIONS)
            .pipe(
              map(confirmed =>
                confirmed
                  ? EditBridge485Page.confirmSaveAction(action.bridge485)
                  : EditBridge485Page.cancelSaveAction(),
              ),
            );
        }

        return of(EditBridge485Page.confirmSaveAction(action.bridge485));
      }),
    ),
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditBridge485Page.confirmSaveAction),
      switchMap(action => {
        const siteId = this.siteService.getId();

        if (!siteId) return EMPTY;

        const input = toUpdateBridge485Input(action.bridge485);

        return this.gql.updateBridge485({ input }).pipe(
          map(result => {
            return Bridge485Api.updateSuccessAction(
              toBridge485InternalModelFromGQL(result.updateBridge485),
            );
          }),
          catchError(error =>
            of(Bridge485Api.updateFailureAction(retrieveErrorMessage(error))),
          ),
        );
      }),
    ),
  );

  cancel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EditBridge485Page.cancelAction),
        tap(() => {
          const siteId = this.siteService.getId();

          if (!siteId) throw new Error(`No site ID for session`);

          this.location.back();
        }),
      ),
    { dispatch: false },
  );

  redirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Bridge485Api.updateSuccessAction),
        tap(() => {
          const siteId = this.siteService.getId();

          if (!siteId) throw new Error(`No site ID for session`);

          this.location.back();
        }),
      ),
    { dispatch: false },
  );

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Bridge485Api.updateFailureAction),
        tap(action => this.snackbar.open(action.error, 'Dismiss')),
      ),
    { dispatch: false },
  );

  pushBridge485sToState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485Api.loadEditPageModelsSuccessAction),
      map(action =>
        Bridge485sStateActions.loadBridge485sAction(action.models.bridge485s),
      ),
    ),
  );

  pushIndieSensorsToState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485Api.loadEditPageModelsSuccessAction),
      map(action => IndieSensorsStateActions.loadAction(action.models.indieSensors)),
    ),
  );

  pushSiteControllersToState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485Api.loadEditPageModelsSuccessAction),
      map(action =>
        SiteControllersStateActions.loadAction(action.models.siteControllers),
      ),
    ),
  );
}
