import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AdminOrgShellPageActions } from '../actions';
import { OrgAdminRedirectService } from '../services';

@Injectable()
export class AdminOrganizationShellPageEffects {
  constructor(
    readonly actions$: Actions,
    readonly router: Router,
    readonly redirectService: OrgAdminRedirectService,
  ) {}

  redirectAdminOrgPanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminOrgShellPageActions.closeAdminOrgPanel),
        mergeMap(() => this.redirectService.getAdminOrgPanelRedirectUrl()),
        tap(redirectUrl => this.router.navigateByUrl(redirectUrl)),
      ),
    { dispatch: false },
  );
}
