import { LightZoneModelFragment } from '@spog-ui/graphql/types';

export interface LightZoneInternalModel {
  id: string;
  lightId: string;
  zoneId: string;
}

export type LightZoneGQLModel = LightZoneModelFragment;

export interface LightZoneWSModel {
  id: string;
  light_id: string;
  removable: boolean;
  zone_id: string;
}

export function toLightZoneInternalModelFromGQL(
  gql: LightZoneGQLModel,
): LightZoneInternalModel {
  return {
    id: gql.id,
    lightId: gql.lightId,
    zoneId: gql.zoneId,
  };
}

export function toLightZoneInternalModelFromWS(
  ws: LightZoneWSModel,
): LightZoneInternalModel {
  return {
    id: ws.id,
    zoneId: ws.zone_id,
    lightId: ws.light_id,
  };
}
