import { createReducer, on } from '@ngrx/store';
import { EditSequenceScenePageActions, ScenesApiActions } from '@spog-ui/scenes/actions';

export interface Shape {
  sequenceSceneId: string | null;
  editing: boolean;
  loading: boolean;
  error?: any;
}

const initialState: Shape = {
  sequenceSceneId: null,
  editing: false,
  loading: true,
};

export const reducer = createReducer(
  initialState,
  on(EditSequenceScenePageActions.enterAction, (state, action) => ({
    ...state,
    sequenceSceneId: action.sequenceSceneId,
  })),
  on(EditSequenceScenePageActions.editSequenceSceneAction, state => {
    return {
      ...state,
      editing: true,
      error: null,
    };
  }),
  on(ScenesApiActions.editSequenceSceneSuccessAction, state => {
    return {
      ...state,
      editing: false,
      error: null,
    };
  }),
  on(ScenesApiActions.editSequenceSceneFailureAction, (state, action) => {
    return {
      ...state,
      editing: false,
      error: action.error,
    };
  }),
  on(ScenesApiActions.loadSuccessAction, state => {
    return {
      ...state,
      error: null,
      editing: false,
      loading: false,
    };
  }),
  on(ScenesApiActions.loadFailureAction, (state, action) => {
    return {
      ...state,
      error: action.error,
      editing: false,
      loading: false,
    };
  }),
);

export const selectEditing = (state: Shape) => state.editing;
export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
export const selectSequenceSceneId = (state: Shape) => state.sequenceSceneId;
