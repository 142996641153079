import { AlarmModelFragment } from '@spog-ui/graphql/types';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';
import {
  AlarmTypes,
  SuiAlarmModel,
  isAlarmTypeRetryable,
  isAlarmTypeSelfClearing,
} from '@shared/alarms';

/**
 * Alarm from our GraphQL API
 */
export type AlarmGQLModel = AlarmModelFragment;

/**
 * Normalizes alarms returned from our GraphQL API
 * into a model ready to be put in the store
 */
export function toAlarmModelFromGQL(gql: AlarmGQLModel): SuiAlarmModel {
  return {
    id: gql.id,
    triggerTime: gql.triggerTime,
    type: gql.type as AlarmTypes,
    controllerId: gql.controllerId,
    siteControllerId: gql.siteController.id,
    snapaddr: gql.snapaddr,
    cleared: gql.cleared,
    description: gql.description!,
    selfClearing: isAlarmTypeSelfClearing(gql.type as AlarmTypes),
    retryable: isAlarmTypeRetryable(gql.type as AlarmTypes),
  };
}

export interface AlarmViewModel {
  id: string;
  triggerTime: string;
  type: AlarmTypes;
  snapaddr: string;
  controllerId?: string | null;
  siteControllerId?: string;
  siteControllerName?: string;
  cleared: boolean;
  description?: string; // TODO mark this not optional after cloud backend update is in place
  selfClearing?: boolean; // TODO mark this not optional after cloud backend update is in place
  retryable?: boolean; // TODO mark this not optional after cloud backend update is in place
}

export function toAlarmViewModelFromInternal(
  internal: SuiAlarmModel,
  siteControllers: SiteControllerInternalModel[],
): AlarmViewModel {
  const matchingSiteController = siteControllers.find(
    siteController => siteController.id === internal.siteControllerId,
  );

  return {
    ...internal,
    siteControllerName: matchingSiteController?.name,
  };
}
