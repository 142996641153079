import baseGqlTag from 'graphql-tag';
import { DirectiveNode, FieldNode, OperationDefinitionNode } from 'graphql/language/ast';
import { visit } from 'graphql/language/visitor';
import { print } from 'graphql/language/printer';

type KnobsEnum = { [knobName: string]: number };

function getKnobNameFromArgs(node: DirectiveNode) {
  if (!node.arguments) return null;

  const arg = node.arguments.find(arg => arg.name.value === 'knob');

  if (!arg) return null;

  if (arg.value.kind !== 'StringValue') return null;

  return arg.value.value;
}

function knobNameIsValid(Knobs: KnobsEnum, knob: string | null): knob is string {
  if (!knob)
    throw new Error(
      `Encountered @ifEnabled/@ifDisabled directive in query without knob argument`,
    );

  if (!(knob in Knobs))
    throw new Error(
      `Encountered undefined knob in @ifEnabled/@ifDisabled directive: "${knob}"`,
    );

  return true;
}

export function filterNodesBasedOnKnobs(
  node: FieldNode | OperationDefinitionNode,
  Knobs: KnobsEnum,
  knobs: number,
) {
  if (!node.directives) return node;

  const filters = node.directives.reduce(
    (filters, directiveNode) => {
      if (directiveNode.name.value === 'ifEnabled') {
        const knobName = getKnobNameFromArgs(directiveNode);

        if (knobNameIsValid(Knobs, knobName)) {
          filters.enabled.push(knobName);
        }
      }

      if (directiveNode.name.value === 'ifDisabled') {
        const knobName = getKnobNameFromArgs(directiveNode);

        if (knobNameIsValid(Knobs, knobName)) {
          filters.disabled.push(knobName);
        }
      }

      return filters;
    },
    { enabled: [] as string[], disabled: [] as string[] },
  );

  const enabledKnobNamesFromFilterArePresent = filters.enabled.every(knobName => {
    return Boolean(Knobs[knobName] & knobs);
  });

  const disabledKnobNamesFromFilterAreNotPresent = filters.disabled.every(knobName => {
    return !(Knobs[knobName] & knobs);
  });

  if (enabledKnobNamesFromFilterArePresent && disabledKnobNamesFromFilterAreNotPresent) {
    return node;
  }

  return null;
}

export function filterKnobDirectives(node: DirectiveNode) {
  if (node.name.value === 'ifEnabled' || node.name.value === 'ifDisabled') {
    return null;
  } else {
    return node;
  }
}

export interface GraphQLQuery {
  build(Knobs: KnobsEnum, knobs: number): string;
}

export function gql(stringPieces: TemplateStringsArray): GraphQLQuery {
  const ast = baseGqlTag(stringPieces);

  function buildQuery(Knobs: KnobsEnum, knobs: number) {
    const filteredAst = visit(ast, {
      OperationDefinition: node => filterNodesBasedOnKnobs(node, Knobs, knobs),
      Field: node => filterNodesBasedOnKnobs(node, Knobs, knobs),
      Directive: node => filterKnobDirectives(node),
    });

    return print(filteredAst);
  }

  return { build: buildQuery };
}
