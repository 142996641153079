import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Bridge485InternalModel } from '@spog-ui/shared/models/bridge-485s';
import { Bridge485sStateActions } from '../actions';

export type Shape = EntityState<Bridge485InternalModel>;

export const adapter = createEntityAdapter<Bridge485InternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(Bridge485sStateActions.loadBridge485sAction, (state, action): Shape => {
    return adapter.upsertMany(action.bridge485s, state);
  }),
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
