import { createAction } from '@ngrx/store';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';

export const loadOrganizationsForAdminSectionSuccessAction = createAction(
  '[Admin Organizations State] Load Organizations  for Admin SectionSuccess',
  (organizations: OrganizationInternalModel[]) => ({ organizations }),
);

export const deleteOrganizationForAdminSectionAction = createAction(
  '[Admin Organizations State] Delete Organization for Admin Section',
  (organization: OrganizationInternalModel) => ({ organization }),
);
