import { createAction } from '@ngrx/store';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';
import { UserInternalModel } from '@spog-ui/shared/models/users';
import { SiteFormModel, SiteInternalModel } from '@spog-ui/shared/models/sites';
import { ApiErrorModel } from '@spog-ui/shared/models/errors';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';

export const loadModelsSuccessAction = createAction(
  '[Admin / Site API] Load Models Success',
  (models: {
    sites: SiteInternalModel[];
    siteControllers: SiteControllerInternalModel[];
    users: UserInternalModel[];
    organizations: OrganizationInternalModel[];
  }) => ({ models }),
);

export const loadModelsForEditFailureAction = createAction(
  '[Admin / Site API] Load Models For Edit Failure',
  (error: { errors: ApiErrorModel[] }) => ({ error }),
);

export const loadModelsForEditSuccessAction = createAction(
  '[Admin / Site API] Load Models For Edit Success',
  (models: {
    site: SiteInternalModel;
    siteControllers: SiteControllerInternalModel[];
    users: UserInternalModel[];
    organizations: OrganizationInternalModel[];
  }) => ({ models }),
);

export const loadModelsForEditSiteWithDisabledFeaturesSuccessAction = createAction(
  '[Admin / Site API] Load Models For Edit Site With Disabled Features Success',
  (models: {
    siteId: string;
    changes: SiteFormModel;
    featuresChangedToDisabled: string[];
    scenes: SceneInternalModel[];
    controlZones: ZoneInternalModel[];
  }) => ({
    models,
  }),
);

export const loadModelsForEditSiteWithDisabledFeaturesFailureAction = createAction(
  '[Admin / Site API] Load Models For Edit Site With Disabled Features Failure',
  (error: { errors: ApiErrorModel[] }) => ({ error }),
);

export const loadModelsFailureAction = createAction(
  '[Admin / Site API] Load Models Failure',
  (error: { errors: ApiErrorModel[] }) => ({ error }),
);

export const addSiteSuccessAction = createAction('[Admin / Site API] Add Site Success');

export const addSiteFailureAction = createAction(
  '[Admin / Site API] Add Site Failure',
  (error: { errors: ApiErrorModel[] }) => ({ error }),
);

export const editSiteSuccessAction = createAction('[Admin / Site API] Edit Site Success');

export const editSiteFailureAction = createAction(
  '[Admin / Site API] Edit Site Failure',
  (error: { errors: ApiErrorModel[] }) => ({ error }),
);
