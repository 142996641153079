import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { SceneViewModel, SceneApplication } from '@spog-ui/shared/models/scenes';
import { ScenesPageActions } from '@spog-ui/scenes/actions';

@Injectable()
export abstract class ThermostatScenesListState {
  abstract dispatch(action: ScenesPageActions.Union): void;
}

@Component({
  selector: 'sui-thermostat-scenes-list',
  template: `
    <mat-divider></mat-divider>
    
    <div class="header">Routines</div>
    @for (scene of scenes; track scene) {
      <a
        class="container"
        (mouseenter)="preview.emit(scene.id)"
        (press)="preview.emit(scene.id)"
        (mouseleave)="unpreview.emit()"
        (pressup)="unpreview.emit()"
        >
        <mat-icon>smart_button</mat-icon>
        <span class="name">{{ scene.name }}</span>
        <scn-apply-scene-button
          *suiIfUserCan="['ssc:siteAdmin']"
          [state]="scene.state"
          [application]="eApplication.CLIMATE"
          (apply)="onApplyScene(scene)"
          >
          Run Now
        </scn-apply-scene-button>
      </a>
    }
    
    @if (!scenes || scenes.length < 1) {
      <span class="noRoutines">
        There are no routines associated with this {{ associatedEntityName }}
      </span>
    }
    `,
  styles: [
    `
      .header {
        padding: 16px;
        font-weight: 500;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7);
      }

      mat-icon {
        margin-left: 16px;
      }

      .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .noRoutines {
        padding: 16px;
        font-size: 14px;
        display: block;
      }

      scn-apply-scene-button {
        min-width: 105px;
      }

      .container {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 52px auto 120px;
        align-items: center;
        padding-bottom: 8px;
      }
    `,
  ],
})
export class ThermostatScenesListComponent {
  eApplication = SceneApplication;

  @Input() scenes: SceneViewModel[];
  @Input() associatedEntityName: string;

  @Output() preview: EventEmitter<string> = new EventEmitter<string>();
  @Output() unpreview: EventEmitter<never> = new EventEmitter<never>();

  constructor(public state: ThermostatScenesListState) {}

  onApplyScene(scene: SceneViewModel) {
    this.state.dispatch(ScenesPageActions.applySceneAction(scene));
  }
}
