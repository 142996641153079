import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SiteService } from '@spog-ui/shared/services';
import { ScenesService } from '../services';
import { ScenesApiEffects } from './scenes-api.effects';
import { ScenesPageEffects } from './scenes-page.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ScenesApiEffects, ScenesPageEffects]),
    MatSnackBarModule,
  ],
  providers: [ScenesService, SiteService],
})
export class ScenesEffectsModule {}

export { ScenesPageEffects };
