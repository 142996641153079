import { createAction } from '@ngrx/store';
import {
  ThermostatControlModeInternalModel,
  ThermostatInternalModel,
  ThermostatViewModel,
} from '@spog-ui/shared/models/thermostats';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';
import { SceneZoneBehaviorInternalModel } from '@spog-ui/shared/models/scene-zone-behaviors';

export const loadThermostatDetailsSuccessAction = createAction(
  '[Thermostat Details API] Load Thermostat Details Success',
  (
    thermostat: ThermostatInternalModel,
    siteController: SiteControllerInternalModel | null,
  ) => ({
    thermostat,
    siteController,
  }),
);
export const loadThermostatDetailsFailureAction = createAction(
  '[Thermostat Details API] Load Thermostat Details Failure',
  (error: any) => ({ error }),
);
export const controlThermostatFailureAction = createAction(
  '[Thermostat Details API] Control Thermostat Failure',
  (
    thermostat: ThermostatViewModel,
    controlMode: ThermostatControlModeInternalModel,
    error?: any,
  ) => ({ thermostat, controlMode, error }),
);
export const controlThermostatSuccessAction = createAction(
  '[Thermostat Details API] Control Thermostat Success',
);
export const deleteThermostatSuccessAction = createAction(
  '[Thermostat Details API] Delete Thermostat Success',
);
export const deleteThermostatFailureAction = createAction(
  '[Thermostat Details API] Delete Thermostat Failure',
  (error: any) => ({ error }),
);
export const checkThermostatSignalStrengthSuccessAction = createAction(
  '[Thermostat Details API] Check Thermostat Signal Strength Success',
);
export const checkThermostatSignalStrengthFailureAction = createAction(
  '[Thermostat Details API] Check Thermostat Signal Strength Failure',
  (thermostat: ThermostatViewModel, error?: any) => {
    return { thermostat, error };
  },
);
export const pingThermostatSuccessAction = createAction(
  '[Thermostat Details API] Ping Thermostat Success',
);
export const pingThermostatFailureAction = createAction(
  '[Thermostat Details API] Ping Thermostat Failure',
  (thermostat: ThermostatViewModel, error?: any) => {
    return { thermostat, error };
  },
);
export const loadThermostatDetailsModelsSuccessAction = createAction(
  '[Thermostat Details API] Load Thermostat Details Models Success',
  (models: {
    zones: ZoneInternalModel[];
    scenes: SceneInternalModel[];
    sceneZoneBehaviors: SceneZoneBehaviorInternalModel[];
  }) => ({
    models,
  }),
);

export const loadThermostatDetailsModelsFailureAction = createAction(
  '[Thermostat Details API] Load Thermostat Details Models Failure',
  (error: any) => ({ error }),
);
