import { createAction } from '@ngrx/store';

export const enterAction = createAction('[Map] Enter');
export const retryAction = createAction('[Map] Retry');
export const leaveAction = createAction('[Map] Leave');
export const openSettingsAction = createAction('[Map] Open Settings');
export const toggleMapSearchAction = createAction('[Map] Toggle Map Search');
export const searchLightsAction = createAction(
  '[Map] Search Lights',
  (searchTerm: string) => {
    return { searchTerm };
  },
);
export const floorPlanLoadedAction = createAction(
  '[Map] Floor Plan Loaded',
  (width: number, height: number) => {
    return { width, height };
  },
);

export type Union =
  | ReturnType<typeof enterAction>
  | ReturnType<typeof leaveAction>
  | ReturnType<typeof openSettingsAction>
  | ReturnType<typeof toggleMapSearchAction>
  | ReturnType<typeof searchLightsAction>
  | ReturnType<typeof floorPlanLoadedAction>;

export const Types = {
  Enter: enterAction.type,
  Leave: leaveAction.type,
  OpenSettings: openSettingsAction.type,
  ToggleMapLightSearch: toggleMapSearchAction.type,
  SearchLights: searchLightsAction.type,
  FloorPlanLoaded: floorPlanLoadedAction.type,
};
