import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { IfSupportsProductsDirectiveModule } from '@spog-ui/shared/directives/if-supports-products';
import { DrawerComponentModule } from '../drawer';
import { DrawerAccountComponentModule } from '../drawer-account';
import { DrawerLabelComponentModule } from '../drawer-label';
import { DrawerLinkComponentModule } from '../drawer-link';
import { AlarmingBadgeComponentModule } from '@spog-ui/alarming-badge';
import { DrawerLinkBetaComponentModule } from '../drawer-link-beta';
import { DrawerLogoComponentModule } from '../drawer-logo';
import { MainNavDrawerComponent } from './main-nav-drawer.component';

@NgModule({
  imports: [
    CommonModule,
    IfSupportsProductsDirectiveModule,
    DrawerComponentModule,
    DrawerAccountComponentModule,
    DrawerLabelComponentModule,
    DrawerLinkComponentModule,
    AlarmingBadgeComponentModule,
    DrawerLinkBetaComponentModule,
    DrawerLogoComponentModule,
    PermissionsModule,
  ],
  declarations: [MainNavDrawerComponent],
  exports: [MainNavDrawerComponent],
})
export class MainNavDrawerComponentModule {}
