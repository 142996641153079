import { Bridge485InternalModel } from '@spog-ui/shared/models/bridge-485s';

export interface Bridge485DetailsViewModel {
  id: string;
  name: string;
  metricIds: string[];

  snapaddr?: string;
}

export const toBridge485DetailsViewModel = (
  internal: Bridge485InternalModel,
): Bridge485DetailsViewModel => ({
  id: internal.id,
  name: internal.name,
  metricIds: internal.metricIds,

  ...(internal.networkSettings?.snapAddress
    ? { snapaddr: internal.networkSettings.snapAddress }
    : {}),
});

export const createMockBridge485DetailsViewModel = (
  updates?: Partial<Bridge485DetailsViewModel>,
): Bridge485DetailsViewModel => ({
  id: 'dummy-bridge-485-id',
  name: 'Dummy Bridge 485',
  metricIds: [],
  ...updates,
});
