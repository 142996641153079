import { createAction } from '@ngrx/store';
import { OrganizationUserInternalModel } from '@spog-ui/shared/models/organization-users';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';

export const loadUsersSuccessAction = createAction(
  '[Admin Organization / Users State] Load Users Success',
  (users: OrganizationUserInternalModel[]) => ({ users }),
);

export const removeOrganizationUserSuccessAction = createAction(
  '[Admin Organization / Users State] Remove Organization User Success',
  (userId: string) => ({
    userId,
  }),
);
