import { Injectable, Provider } from '@angular/core';
import { CanvasFactory } from './canvas-factory';

@Injectable()
export class CanvasFactoryBrowser implements CanvasFactory {
  createCanvas(width: number, height: number) {
    const canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;

    return canvas;
  }
}

export const CANVAS_FACTORY_BROWSER_PROVIDERS: Provider[] = [
  { provide: CanvasFactory, useClass: CanvasFactoryBrowser },
];
