import { createAction } from '@ngrx/store';
import { BigSelectList, BigSelectOptionView, BigSelectTab } from './big-select.models';

export const addListAction = createAction(
  '[Big Select] Add List',
  (list: BigSelectList) => ({ list }),
);
export const updateListAction = createAction(
  '[Big Select] Update List',
  (list: BigSelectList) => ({ list }),
);
export const removeListAction = createAction(
  '[Big Select] Remove List',
  (listTitle: string) => ({ listTitle }),
);
export const toggleOptionAction = createAction(
  '[Big Select] Toggle Option',
  (option: BigSelectOptionView) => ({ option }),
);
export const filterAction = createAction(
  '[Big Select] Filter Options',
  (filter: string) => ({ filter }),
);
export const selectTabAction = createAction(
  '[Big Select] Select List',
  (tab: BigSelectTab) => ({ tab }),
);
export const checkAllAction = createAction('[Big Select] Check All');
export const focusFilterAction = createAction('[Big Select] Focus Filter');
export const blurFilterAction = createAction('[Big Select] Blur Filter');
