import { createAction } from '@ngrx/store';
import {
  PermissionGroupFormModel,
  PermissionGroupInternalModel,
} from '@spog-ui/shared/models/permission-groups';
import { OrganizationSiteInternalModel } from '@spog-ui/shared/models/sites';
import { OrganizationUserInternalModel } from '@spog-ui/shared/models/organization-users';

export const enterAction = createAction(
  '[Admin / Edit Permission Group Page] Enter',
  (permissionGroupId: string) => ({
    permissionGroupId,
  }),
);

export const editPermissionGroupAction = createAction(
  '[Edit Permission Group] Edit Permission Group',
  (permissionGroup: PermissionGroupFormModel) => ({ permissionGroup }),
);

export const loadPageSuccessAction = createAction(
  '[Edit Permission Group] Load Edit Page Success',
  (
    users: OrganizationUserInternalModel[],
    sites: OrganizationSiteInternalModel[],
    permissionGroups: PermissionGroupInternalModel[],
  ) => ({
    users,
    sites,
    permissionGroups,
  }),
);

export const loadPageFailureAction = createAction(
  '[Edit Permission Group] Load Edit Page Failure',
  (error: string) => ({ error }),
);
