import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { tap, mergeMap } from 'rxjs/operators';
import {
  AdminPanelActions,
  AddSiteControllerPageActions,
  AddSiteControllerPageApiActions,
  EditSiteControllerPageActions,
  EditSiteControllerPageApiActions,
} from '@spog-ui/admin/actions';
import { Router } from '@angular/router';
import { RedirectService } from '@spog-ui/shared/services';

@Injectable()
export class RedirectEffects {
  constructor(
    readonly actions$: Actions,
    readonly router: Router,
    readonly redirectService: RedirectService,
  ) {}

  redirectAdminPanel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminPanelActions.closeAdminPanel),
        mergeMap(() => this.redirectService.getAdminPanelRedirectUrl()),
        tap(redirectUrl => this.router.navigateByUrl(redirectUrl)),
      ),
    { dispatch: false },
  );

  redirectSiteController$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AddSiteControllerPageActions.cancelAction,
          AddSiteControllerPageApiActions.saveSuccessAction,
          EditSiteControllerPageActions.cancelAction,
          EditSiteControllerPageApiActions.saveSuccessAction,
        ),
        mergeMap(() => this.redirectService.getSiteControllerRedirectUrl()),
        tap(redirectUrl => this.router.navigateByUrl(redirectUrl)),
      ),
    { dispatch: false },
  );
}
