import { EntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { toIndieSensorInternalModelFromWS } from '@spog-ui/shared/models/indie-sensors';
import { toLightZoneInternalModelFromWS } from '@spog-ui/shared/models/light-zones';
import { toLightInternalModelFromWS } from '@spog-ui/shared/models/lights';
import { toOrganizationInternalModelFromWS } from '@spog-ui/shared/models/organizations';
import { toPermissionGroupInternalModelFromWS } from '@spog-ui/shared/models/permission-groups';
import { toEmailGroupInternalModelFromWS } from '@spog-ui/shared/models/email-groups';
import { toResourceGroupInternalModelFromWS } from '@spog-ui/shared/models/resource-groups';
import { toSceneInternalModelFromWS } from '@spog-ui/shared/models/scenes';
import { toScheduledUtilityRateInternalModelFromWS } from '@spog-ui/shared/models/scheduled-utility-rates';
import { toSiteControllerInternalModelFromWS } from '@spog-ui/shared/models/site-controllers';
import { toSiteInternalModelFromWS } from '@spog-ui/shared/models/sites';
import { toThermostatInternalModelFromWS } from '@spog-ui/shared/models/thermostats';
import { toTriggerInternalModelFromWS } from '@spog-ui/shared/models/triggers';
import { toUtilityServiceInternalModelFromWS } from '@spog-ui/shared/models/utility-services';
import * as SocketMessages from '@spog-ui/shared/models/websocket-notifications';
import { toZoneInternalModelFromWS } from '@spog-ui/shared/models/zones';
import {
  fromAlarms,
  fromIndieSensors,
  fromLights,
  fromLightZones,
  fromResourceGroups,
  fromScenes,
  fromSchedule,
  fromScheduledUtilityRates,
  fromSiteControllers,
  fromSites,
  fromThermostats,
  fromTriggers,
  fromUtilityServices,
  fromZones,
  fromSequenceScenes,
  fromActiveSequenceScenes,
} from '@spog-ui/shared/state/core';
import { fromOrganizations } from '@spog-ui/shared/state/organizations';
import { fromOrganizationUsers } from '@spog-ui/admin-organization/state';
import { fromOrganizationSites } from '@spog-ui/admin-organization/state';
import { fromPermissionGroups } from '@spog-ui/shared/state/permission-groups';
import { fromEmailGroups } from '@spog-ui/shared/state/email-groups';
import { SocketActions } from '@spog-ui/socket/actions';
import { toOrganizationUserInternalModelFromWS } from '@spog-ui/shared/models/organization-users';
import { fromBridge485s } from '@spog-ui/shared/state/bridge-485s';
import { toBridge485InternalModelFromWS } from '@spog-ui/shared/models/bridge-485s';
import { toSequenceSceneInternalModelFromWS } from '@spog-ui/shared/models/sequence-scenes';

type DeleteNotification<T> =
  | SocketMessages.CDBDeleteNotification<T>
  | SocketMessages.RDSDeleteNotification<T>
  | SocketMessages.AdminDeleteNotification
  | SocketMessages.OrgAdminDeleteNotification;

function deleteNotificationId<T>(notification: DeleteNotification<T>): string {
  return notification.entityId;
}

type ExpectedState = {
  core: {
    activeSequenceScenes: fromActiveSequenceScenes.Shape;
    alarms: fromAlarms.Shape;
    controlZones: fromZones.Shape;
    indieSensors: fromIndieSensors.Shape;
    lightZones: fromLightZones.Shape;
    lights: fromLights.Shape;
    resourceGroups: fromResourceGroups.Shape;
    scenes: fromScenes.Shape;
    scheduledEvents: fromSchedule.Shape;
    scheduledUtilityRate: fromScheduledUtilityRates.Shape;
    sequenceScenes: fromSequenceScenes.Shape;
    thermostats: fromThermostats.Shape;
    triggers: fromTriggers.Shape;
    utilityServices: fromUtilityServices.Shape;
    site: fromSites.Shape;
    siteControllers: fromSiteControllers.Shape;
  };
  organizations: {
    organizationsList: fromOrganizations.Shape;
  };
  'feature-manageOrganizations': {
    'organization-users': fromOrganizationUsers.Shape;
    sites: fromOrganizationSites.Shape;
  };
  'permission-groups': {
    permissionGroupsState: fromPermissionGroups.Shape;
  };
  'email-groups': {
    emailGroupsState: fromEmailGroups.Shape;
  };
  'bridge485s-core': fromBridge485s.Shape;
};

/**
 * @todo There is a type called EntityState in '@ngrx/entity' with a different definition.
 *       We should consider renaming this to something less conflict-y.
 */
type EntityState =
  | fromActiveSequenceScenes.Shape
  | fromAlarms.Shape
  | fromBridge485s.Shape
  | fromIndieSensors.Shape
  | fromLights.Shape
  | fromLightZones.Shape
  | fromOrganizations.Shape
  | fromOrganizationUsers.Shape
  | fromOrganizationSites.Shape
  | fromPermissionGroups.Shape
  | fromEmailGroups.Shape
  | fromResourceGroups.Shape
  | fromScenes.Shape
  | fromSchedule.Shape
  | fromScheduledUtilityRates.Shape
  | fromSequenceScenes.Shape
  | fromSites.Shape
  | fromSiteControllers.Shape
  | fromThermostats.Shape
  | fromTriggers.Shape
  | fromUtilityServices.Shape
  | fromZones.Shape;

/**
 * An EntityHandler defines how to update the state for a given
 * entity kind.
 */
type EntityHandler<Source, Actual> = {
  adapter: EntityAdapter<Actual>;
  getEntityState: <T extends ExpectedState>(state: T) => EntityState;
  replaceEntityState: <T extends ExpectedState>(state: T, entityState: EntityState) => T;
  mapUpdateToEntity: (model: Source) => Partial<Actual>;
  mapDeleteNotificationToId: (deleteNotification: DeleteNotification<Source>) => string;
};

/**
 * The idea here is that the websocket notifications we receive are really specific to
 * RDS and CouchDB. I don't want those implementation details leaking into the reducers
 * for light zones, zones, and lights. This metareducer allows us to contain the
 * entity update logic into one location.
 *
 * For each entity, we specify the state key, the document kind (this comes from the
 * web socket notifications), the Entity Adapter from @ngrx/entity used to update the
 * state, and a function to map from the notification model to the shape of the model
 * the store expects.
 *
 * If the backend socket API is updated to publish models that match the GraphQL schema
 * then we would want to refactor this logic and put it into each entity's reducer.
 * We also wouldn't need the `mapUpdateToEntity` function making the handling of
 * socket notifications simpler.
 */
const EntitySocketMessagesHandler: {
  [kind: string]: EntityHandler<any, any>;
} = {
  bridge485: {
    adapter: fromBridge485s.adapter,
    getEntityState: state => state['bridge485s-core'],
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        'bridge485s-core': Object.assign({}, state['bridge485s-core'], entityState),
      });
    },
    mapUpdateToEntity: toBridge485InternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  Bridge485Metric: {
    adapter: fromIndieSensors.adapter,
    getEntityState: state => state.core.indieSensors,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          indieSensors: entityState,
        }),
      });
    },
    mapUpdateToEntity: toIndieSensorInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  light_zone: {
    adapter: fromLightZones.adapter,
    getEntityState: state => state.core.lightZones,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          lightZones: entityState,
        }),
      });
    },
    mapUpdateToEntity: toLightZoneInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  light: {
    adapter: fromLights.adapter,
    getEntityState: state => state.core.lights,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          lights: entityState,
        }),
      });
    },
    mapUpdateToEntity: toLightInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  resourceGroup: {
    adapter: fromResourceGroups.adapter,
    getEntityState: state => state.core.resourceGroups,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          resourceGroups: entityState,
        }),
      });
    },
    mapUpdateToEntity: toResourceGroupInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  scene: {
    adapter: fromScenes.adapter,
    getEntityState: state => state.core.scenes,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          scenes: entityState,
        }),
      });
    },
    mapUpdateToEntity: toSceneInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  scheduledEvent: {
    adapter: fromSchedule.adapter,
    getEntityState: state => state.core.scheduledEvents,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          scheduledEvents: entityState,
        }),
      });
    },
    mapUpdateToEntity: scheduledEventDocument => ({
      id: scheduledEventDocument.id,
      name: scheduledEventDocument.name,
      start:
        scheduledEventDocument.customStartTime?.startsAt ||
        scheduledEventDocument.astroStartTime?.startsAt ||
        null,
      rrule: scheduledEventDocument.rrule,
      astroTime: scheduledEventDocument.astroStartTime?.event || null,
      astroTimeOffset: scheduledEventDocument.astroStartTime?.offset || null,
      sceneIds: scheduledEventDocument.sceneIds,
    }),
    mapDeleteNotificationToId: deleteNotificationId,
  },
  scheduledUtilityRate: {
    adapter: fromScheduledUtilityRates.adapter,
    getEntityState: state => state.core.scheduledUtilityRate,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          scheduledUtilityRate: entityState,
        }),
      });
    },
    mapUpdateToEntity: toScheduledUtilityRateInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  sense420: {
    // @todo Do we still need to support this? Was this just for the legacy Sense420 support which has since been removed?
    adapter: fromIndieSensors.adapter,
    getEntityState: state => state.core.indieSensors,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          indieSensors: entityState,
        }),
      });
    },
    mapUpdateToEntity: toIndieSensorInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  SceneSequence: {
    adapter: fromSequenceScenes.adapter,
    getEntityState: state => state.core.sequenceScenes,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          sequenceScenes: entityState,
        }),
      });
    },
    mapUpdateToEntity: toSequenceSceneInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  thermostat: {
    adapter: fromThermostats.adapter,
    getEntityState: state => state.core.thermostats,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          thermostats: entityState,
        }),
      });
    },
    mapUpdateToEntity: toThermostatInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  trigger: {
    adapter: fromTriggers.adapter,
    getEntityState: state => state.core.triggers,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          triggers: entityState,
        }),
      });
    },
    mapUpdateToEntity: toTriggerInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  utilityService: {
    adapter: fromUtilityServices.adapter,
    getEntityState: state => state.core.utilityServices,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          utilityServices: entityState,
        }),
      });
    },
    mapUpdateToEntity: toUtilityServiceInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  zone: {
    adapter: fromZones.adapter,
    getEntityState: state => state.core.controlZones,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          controlZones: entityState,
        }),
      });
    },
    mapUpdateToEntity: toZoneInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  Organization: {
    adapter: fromOrganizations.adapter,
    getEntityState: state => state.organizations.organizationsList,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        organizations: Object.assign({}, state.organizations, {
          organizationsList: entityState,
        }),
      });
    },
    mapUpdateToEntity: toOrganizationInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  OrganizationUser: {
    adapter: fromOrganizationUsers.adapter,
    getEntityState: state => state['feature-manageOrganizations']['organization-users'],
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        'feature-manageOrganizations': Object.assign(
          {},
          state['feature-manageOrganizations'],
          {
            'organization-users': entityState,
          },
        ),
      });
    },
    mapUpdateToEntity: toOrganizationUserInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  OrganizationSite: {
    adapter: fromOrganizationSites.adapter,
    getEntityState: state => state['feature-manageOrganizations']['sites'],
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        'feature-manageOrganizations': Object.assign(
          {},
          state['feature-manageOrganizations'],
          {
            sites: entityState,
          },
        ),
      });
    },
    mapUpdateToEntity: toSiteInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  PermissionGroup: {
    adapter: fromPermissionGroups.adapter,
    getEntityState: state => state['permission-groups'].permissionGroupsState,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        'permission-groups': Object.assign({}, state['permission-groups'], {
          permissionGroupsState: entityState,
        }),
      });
    },
    mapUpdateToEntity: toPermissionGroupInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  EmailGroup: {
    adapter: fromEmailGroups.adapter,
    getEntityState: state => state['email-groups'].emailGroupsState,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        'email-groups': Object.assign({}, state['email-groups'], {
          emailGroupsState: entityState,
        }),
      });
    },
    mapUpdateToEntity: toEmailGroupInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  Site: {
    adapter: fromSites.adapter,
    getEntityState: state => state.core.site,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          site: entityState,
        }),
      });
    },
    mapUpdateToEntity: toSiteInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
  SiteController: {
    adapter: fromSiteControllers.adapter,
    getEntityState: state => state.core.siteControllers,
    replaceEntityState: (state, entityState) => {
      return Object.assign({}, state, {
        core: Object.assign({}, state.core, {
          siteControllers: entityState,
        }),
      });
    },
    mapUpdateToEntity: toSiteControllerInternalModelFromWS,
    mapDeleteNotificationToId: deleteNotificationId,
  },
};

/**
 * This is the actual metareducer function. A metareducer acts as a hook
 * into the action -> reducer pipeline, allowing you to preprocess actions
 * before the normal reducers are invoked (see https://v7.ngrx.io/guide/store/metareducers).
 *
 * This metareducer handles the `SocketActions.updateEntitiesAction` action,
 * passing the action and previous state to the `applySocketMessageUpdates` function.
 */
export function socketMessagesMetareducer<T extends ExpectedState, V extends Action>(
  reducer: (state: T | undefined, action: V) => T,
) {
  /**
   * unknownAction is typed as "any" here because this metareducer
   * could handle truly any action and there's not a better type
   * to express this.
   *
   * Once I've detected the type in the switch statements I cast it
   * by assigning it to a well typed `action` const.
   */
  return (state: T | undefined, unknownAction: V) => {
    if (state === undefined) {
      return reducer(state, unknownAction);
    }

    switch (unknownAction.type) {
      case SocketActions.updateEntitiesAction.type: {
        const action: ReturnType<typeof SocketActions.updateEntitiesAction> =
          unknownAction as any;
        return reducer(
          Object.assign({}, applySocketMessageUpdates(state, action)),
          unknownAction,
        );
      }

      default: {
        return reducer(state, unknownAction);
      }
    }
  };
}

/**
 * @todo @mikeryan - Refactor into more utility functions
 *
 * This function first groups a set of WebSocket updates by their
 * entity kind and then again based on the operation type. This creates
 * a set of sets, where each inner set is a group of the same operation
 * (upsert/delete) for the same entity kind (light/zone/light_zone).
 *
 * It then goes through each inner set, finds the right `EntitySocketMessagesHandler`,
 * and uses the @ngrx/entity adapter for that entity kind to update the state.
 *
 * By doing it in chunks this way, we reduce the number of new state objects
 * we create when handling a large set of websocket notifications. This matters
 * significantly when a lot of light zones are created, for instance.
 *
 * However, a lot of this was designed when we thought we would be getting
 * light level changes and sensor status changes over the websocket connection.
 * This would have resulted in a lot more messages. Now it seems like that won't
 * be the case for a while and so all of this performance work may not be
 * necessary.
 */
function applySocketMessageUpdates<T extends ExpectedState>(
  state: T,
  action: SocketActions.Union,
): T {
  if (
    action.type === SocketActions.updateActiveSequenceSceneAction.type ||
    action.type === SocketActions.deleteActiveSequenceSceneAction.type ||
    action.type === SocketActions.updateAlarmsAction.type ||
    action.type === SocketActions.deleteAlarmAction.type ||
    action.type === SocketActions.updateEnergyBaselineAction.type ||
    action.type === SocketActions.zoneControlAction.type ||
    action.type === SocketActions.climateZoneControlAction.type ||
    action.type === SocketActions.lightControlAction.type ||
    action.type === SocketActions.sense420StateChangeAction.type ||
    action.type === SocketActions.virtualIndustrialSensorStateChangeAction.type ||
    action.type === SocketActions.bridge485MetricStateChangeAction.type ||
    action.type === SocketActions.triggerStateChangeAction.type ||
    action.type === SocketActions.bulkFloorPlanPlacementAction.type ||
    action.type === SocketActions.thermostatAlarmStatusAction.type ||
    action.type === SocketActions.deleteThermostatAlarmAction.type ||
    action.type === SocketActions.socketCloseAction.type
  ) {
    return state;
  }

  const updatesByKind = groupOperationsByKind(action.updates);

  return updatesByKind.reduce((state: T, [kind, updates]) => {
    //  This is a work around because departments and assets are handled
    //  by resource group state and don't have their own reducers/adapters.
    if (kind === 'department' || kind === 'asset') {
      kind = 'resourceGroup';
    }

    if (EntitySocketMessagesHandler[kind] === undefined) {
      return state;
    }

    const {
      adapter,
      getEntityState,
      replaceEntityState,
      mapUpdateToEntity,
      mapDeleteNotificationToId,
    } = EntitySocketMessagesHandler[kind];
    const entityState = getEntityState(state);
    const operationsInOrderByOperationType = groupInOrderByOperationType(updates);

    const updatedEntityState = computeUpdatedEntityState(
      entityState,
      adapter,
      mapUpdateToEntity,
      mapDeleteNotificationToId,
      operationsInOrderByOperationType,
    );

    return replaceEntityState(state, updatedEntityState);
  }, state);
}

/**
 * Given the current entity state and a set of operations to apply to it,
 * compute and return the updated entity state.
 */
function computeUpdatedEntityState(
  entityState: EntityState,
  adapter: EntityAdapter<any>,
  mapUpdateToEntity: (model: any) => Partial<any>,
  mapDeleteNotificationToId: (notification: DeleteNotification<any>) => string,
  operationsInOrderByOperationType: OperationGroup<any>[],
) {
  return operationsInOrderByOperationType.reduce(
    (state: EntityState, operationSet): EntityState => {
      if (operationSet.type === 'upsert') {
        return adapter.upsertMany(
          operationSet.notifications.map(notification =>
            mapUpdateToEntity(notification.document),
          ),
          state,
        );
      } else if (operationSet.type === 'adminUpsert') {
        return adapter.upsertMany(
          operationSet.notifications.map(notification =>
            mapUpdateToEntity(notification.current),
          ),
          state,
        );
      } else if (operationSet.type === 'orgAdminUpsert') {
        return adapter.upsertMany(
          operationSet.notifications.map(notification =>
            mapUpdateToEntity(notification.current),
          ),
          state,
        );
      } else if (operationSet.type === 'delete') {
        return adapter.removeMany(
          operationSet.notifications.map(notification =>
            mapDeleteNotificationToId(notification),
          ),
          state,
        );
      } else if (operationSet.type === 'adminDelete') {
        return adapter.removeMany(
          operationSet.notifications.map(notification =>
            mapDeleteNotificationToId(notification),
          ),
          state,
        );
      } else if (operationSet.type === 'orgAdminDelete') {
        return adapter.removeMany(
          operationSet.notifications.map(notification =>
            mapDeleteNotificationToId(notification),
          ),
          state,
        );
      }

      return state;
    },
    entityState,
  );
}

/**
 * Given a set of operations, this one groups them into smaller sets
 * based on the document kind. For instance, if the set contains
 * operations for lights and zones this will return two grouped
 * sets: one set of operations in order for lights and another
 * for zones.
 */
function groupOperationsByKind(updates: SocketMessages.CrudNotification<any>[]) {
  const groups = new Map<string, SocketMessages.CrudNotification<any>[]>();

  updates.forEach(update => {
    if (!groups.has(update.entityKind)) {
      groups.set(update.entityKind, []);
    }

    groups.get(update.entityKind)?.push(update);
  });

  return Array.from(groups.entries());
}

type OperationGroup<T> =
  | {
      type: 'delete';
      notifications: Array<
        SocketMessages.CDBDeleteNotification<T> | SocketMessages.RDSDeleteNotification<T>
      >;
    }
  | {
      type: 'upsert';
      notifications: Array<
        SocketMessages.CDBUpsertNotification<T> | SocketMessages.RDSUpsertNotification<T>
      >;
    }
  | {
      type: 'adminDelete';
      notifications: Array<SocketMessages.AdminDeleteNotification>;
    }
  | {
      type: 'adminUpsert';
      notifications: Array<SocketMessages.AdminUpsertNotification<T>>;
    }
  | {
      type: 'orgAdminDelete';
      notifications: Array<SocketMessages.OrgAdminDeleteNotification>;
    }
  | {
      type: 'orgAdminUpsert';
      notifications: Array<SocketMessages.OrgAdminUpsertNotification<T>>;
    };

/**
 * This further chunks a group of operations into smaller,
 * ordered groups based on the operation type. This lets
 * us apply a set of "updates" efficiently using the
 * Entity Adapter from @ngrx/entity
 */
function groupInOrderByOperationType<T extends { id: string }>(
  updates: SocketMessages.CrudNotification<T>[],
) {
  const operationQueue: OperationGroup<T>[] = [];
  const firstOperation = updates[0];
  let currentOperationSet = [firstOperation];
  let currentOperationType = firstOperation.type;

  for (let i = 1; i < updates.length; i++) {
    const nextOperation = updates[i];

    if (nextOperation.type !== currentOperationType) {
      operationQueue.push({
        type: currentOperationType,
        notifications: currentOperationSet as any,
      });

      currentOperationSet = [nextOperation];
      currentOperationType = nextOperation.type;
    } else {
      currentOperationSet.push(nextOperation);
    }
  }

  if (currentOperationSet.length > 0) {
    operationQueue.push({
      type: currentOperationType,
      notifications: currentOperationSet as any,
    });
  }

  return operationQueue;
}
