import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Observer } from 'rxjs';
import { BodyService } from '../body';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private dialog: MatDialog, private body: BodyService) {}

  open<T>(
    component: Type<T>,
    dialogData: Record<string, any> = {},
    config: Partial<MatDialogConfig> = {},
  ): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.body.addClass('suiModalOpen');
      const dialogRef = this.dialog.open(component, {
        data: dialogData,
        ...config,
      });

      const removeClass = () => this.body.removeClass('suiModalOpen');
      const subscription = dialogRef
        .afterClosed()
        .pipe(map(Boolean), tap(removeClass))
        .subscribe(observer);

      return () => {
        removeClass();
        subscription.unsubscribe();
        dialogRef.close();
      };
    });
  }

  close(): void {
    if (this.dialog) {
      this.dialog.closeAll();
    }
  }
}

export const DIALOG_SERVICE_PROVIDERS = [DialogService];
