import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { LandingPageFooterComponent } from './landing-page-footer.component';

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule],
  declarations: [LandingPageFooterComponent],
  exports: [LandingPageFooterComponent],
})
export class LandingPageFooterComponentModule {}
