import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { DialogService } from '@spog-ui/shared/components';
import { EulaApiActions, EulaUserActions } from '@spog-ui/current-user/eula-actions';
import { EulaService } from '../services';
import { EulaModalComponent } from '../components/eula-modal';
import { CurrentUserService } from '@spog-ui/current-user/feature';
import { AuthActions } from '@spog-ui/current-user/auth-actions';

@Injectable()
export class EulaEffects {
  saveEulaStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EulaUserActions.eulaAcceptedAction),
      withLatestFrom(this.currentUser.user$),
      switchMap(([, user]) => {
        if (user && user.id) {
          return this.eulaService.updateEulaStatus(user.id).pipe(
            map(response => EulaApiActions.saveEulaStatusSuccessAction(response)),
            catchError(error => of(EulaApiActions.saveEulaStatusFailureAction(error))),
          );
        } else {
          return of(
            EulaApiActions.saveEulaStatusFailureAction(
              'Eula acceptance failed, no user provided',
            ),
          );
        }
      }),
    ),
  );

  getEulaStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccessAction),
      switchMap(() =>
        this.eulaService.getEulaStatus().pipe(
          map(result => {
            return EulaApiActions.getEulaStatusSuccessAction(result);
          }),
          catchError(error => of(EulaApiActions.getEulaStatusFailureAction(error))),
        ),
      ),
    ),
  );

  showEulaForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EulaUserActions.showEulaAction),
        mergeMap(action =>
          this.dialogService.open(
            EulaModalComponent,
            { showAccept: action.showAccept },
            { disableClose: action.showAccept },
          ),
        ),
      ),
    { dispatch: false },
  );

  checkShowEula$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EulaApiActions.getEulaStatusSuccessAction),
      mergeMap(action => {
        if (!action.eulaStatus) {
          return of(EulaUserActions.showEulaAction(true));
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private eulaService: EulaService,
    private dialogService: DialogService,
    private currentUser: CurrentUserService,
  ) {}
}
