import { createAction } from '@ngrx/store';
import { Sense420IndustrialSensorRequiredProps } from '@spog-ui/shared/models/indie-sensors';

export const enterAction = createAction(
  '[Edit Indie Sensor Page] Enter',
  (indieSensorId: string) => {
    return { indieSensorId };
  },
);

export const leaveAction = createAction('[Edit Indie Sensor Page] Leave');

export const editSense420IndieSensorAction = createAction(
  '[Edit Indie Sensor Page] Edit Sense 420 Indie Sensor',
  (indieSensor: Sense420IndustrialSensorRequiredProps, indieSensorId: string) => {
    return { indieSensor, indieSensorId };
  },
);

export const editSense420IndieSensorWithChangedSnapAddrAction = createAction(
  '[Edit Indie Sensor Page] Edit Sense 420 Indie Sensor With Changed Snap Addr',
  (indieSensor: Sense420IndustrialSensorRequiredProps, indieSensorId: string) => {
    return { indieSensor, indieSensorId };
  },
);

export const confirmEditSense420IndieSensorAction = createAction(
  '[Edit Indie Sensor Page] Confirm Edit Sense 420 Indie Sensor',
  (indieSensor: Sense420IndustrialSensorRequiredProps, indieSensorId: string) => {
    return { indieSensor, indieSensorId };
  },
);

export const cancelEditSensor420IndieSensorAction = createAction(
  '[Edit Indie Sensor Page] Cancel Edit Sense 420 Indie Sensor',
);
