import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdminPanelActions } from '@spog-ui/admin/actions';
import { Router } from '@angular/router';
import { CurrentUserService } from '@spog-ui/current-user/feature';

@Component({
  selector: 'spog-admin-link',
  template: `
    @if (isSuperAdmin$ | async) {
      <a
        id="adminPanelLink"
        routerLink="/admin"
        routerLinkActive="active"
        (click)="onAdminPanelOpen()"
        >
        Admin
      </a>
    }
    `,
  styles: [
    `
      a {
        color: var(--color-500);
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
      }
    `,
  ],
})
export class AdminLinkComponent {
  isSuperAdmin$ = this.currentUser.isSuperAdmin$;

  constructor(
    private store: Store,
    private router: Router,
    readonly currentUser: CurrentUserService,
  ) {}

  onAdminPanelOpen() {
    this.store.dispatch(AdminPanelActions.openAdminPanel(this.router.url));
  }
}
