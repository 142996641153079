import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as PermissionGroupsState from './permission-groups.reducer';

export const STATE_KEY = 'permission-groups';

export interface Shape {
  permissionGroupsState: PermissionGroupsState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  permissionGroupsState: PermissionGroupsState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

export const selectPermissionGroupState = createSelector(
  selectFeatureState,
  state => state.permissionGroupsState,
);

export const selectAllPermissionGroups = createSelector(
  selectPermissionGroupState,
  PermissionGroupsState.selectAll,
);
