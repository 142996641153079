import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { AlarmIndicatorComponentModule } from '@spog-ui/light';
import { UpdateIndicatorComponentModule } from '@spog-ui/light';
import { SuiCharmsModule } from '@spog-ui/shared/components';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
  imports: [
    AlarmIndicatorComponentModule,
    UpdateIndicatorComponentModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    PermissionsModule,
    SuiCharmsModule,
  ],
  declarations: [ToolbarComponent],
  exports: [ToolbarComponent],
})
export class ToolbarComponentModule {}
