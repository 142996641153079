import { createAction } from '@ngrx/store';
import { AlarmGQLModel } from '@spog-ui/shared/models/alarms';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';

export const loadAlarmsSuccess = createAction(
  '[Alarms Page / API] Load Alarms Success',
  (alarms: AlarmGQLModel[], siteControllers: SiteControllerInternalModel[]) => {
    return { alarms, siteControllers };
  },
);

export const loadAlarmsFailure = createAction(
  '[Alarms Page / API] Load Alarms Failure',
  (error?: any) => {
    return { error };
  },
);

export const dismissAlarmFailure = createAction(
  '[Alarms Page / API] Dismiss Alarm Failure',
  (error?: any) => {
    return { error };
  },
);

export const retryAlarmSuccess = createAction('[Alarms Page / API] Retry Alarm Success');

export const retryAlarmFailure = createAction(
  '[Alarms Page / API] Retry Alarm Failure',
  (error?: any) => {
    return { error };
  },
);

export const retryAllAlarmsSuccess = createAction(
  '[Alarms Page / API] Retry All Alarms Success',
);

export const retryAllAlarmsFailure = createAction(
  '[Alarms Page / API] Retry All Alarms Failure',
  (error?: any) => {
    return { error };
  },
);

export const loadAlarmSiteControllerForRemoteAccessSuccess = createAction(
  '[Alarms Page / API] Load Site Controller for Alarms Remote Access Success',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);

export const loadAlarmSiteControllerForRemoteAccessFailure = createAction(
  '[Alarms Page / API] Load Site Controller for Alarms Remote Access Failure',
  (error?: any) => {
    return { error };
  },
);
