import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { PermissionGroupInternalModel } from '@spog-ui/shared/models/permission-groups';
import { PermissionGroupStateActions } from '../actions';

export type Shape = EntityState<PermissionGroupInternalModel>;

export const adapter = createEntityAdapter<PermissionGroupInternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    PermissionGroupStateActions.loadPermissionGroupsSuccessAction,
    (state, action): Shape => {
      return adapter.setAll(action.permissionGroups, state);
    },
  ),
  on(PermissionGroupStateActions.deletePermissionGroupAction, (state, action) => {
    return adapter.removeOne(action.permissionGroup.id, state);
  }),
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
