import {
  CreateTriggerInput,
  DeleteTriggerInput,
  DispositionLevel,
  RunningState,
  TriggerModelFragment as TriggerGQLModel,
  UpdateTriggerInput,
} from '@spog-ui/graphql/types';
import { DateTime } from 'luxon';
import { TriggerUpsert as TriggerWSModel } from '@spog-shared/events/triggers';

/**
 * Triggers from our GraphQL API
 */
export {
  CreateTriggerInput,
  UpdateTriggerInput,
  DeleteTriggerInput,
  TriggerGQLModel,
  TriggerWSModel,
};

export interface TriggerInternalNotifications {
  disposition?: {
    level: DispositionLevel;
    message: string;
  };
  runningState?: {
    state: RunningState;
  };
  webhooks?: {
    url: string;
  }[];
}

/**
 * Normalized Trigger appropriate for keeping
 * in the store
 */
export interface TriggerInternalModel {
  id: string;
  name: string;
  state: TriggerState | null;
  history: TriggerEvent[];
  sensorId: string;
  condition: TriggerCondition;
  transitionConfig: TriggerTransitionConfig;
  notifications: TriggerInternalNotifications;
}

/**
 * Trigger ready to be operated on in a form
 */
export interface TriggerFormModel {
  name: string;
  sensorId: string;
  condition: TriggerCondition;
  advanced: {
    activeTransitionDelayInMinutes: number;
    inactiveTransitionDelayInMinutes: number;
    unknownTransitionDelayInMinutes: number;
  };
  actions: {
    webhook?: string;
    runningState?: RunningState | undefined;
    disposition?: DispositionLevel | undefined;
  };
}

export type TriggerStatus = 'ACTIVE' | 'INACTIVE' | 'UNKNOWN';

export interface TriggerState {
  lastStatusUpdate: string; // ISO 8601
  status: TriggerStatus;
}

export interface TriggerEvent {
  value: TriggerStatus;
  timestamp: string; // ISO 8601
}

export type TriggerCondition = { gt?: number; lt?: number };

export interface TriggerTransitionConfig {
  activeTransitionDelayInMinutes: number;
  inactiveTransitionDelayInMinutes: number;
  unknownTransitionDelayInMinutes: number;
}

/**
 * Trigger View Models, appropriate for displaying in the UI
 */
export interface TriggerViewModel {
  id: string;
  name: string;
  lastStatusUpdate: DateTime | null;
  status: TriggerStatus;
  history: {
    value: TriggerStatus;
    timestamp: DateTime;
  }[];
}

/**
 * Trigger State Change from our WebSocket API
 */
export interface TriggerStateChangeWSModel {
  triggerId: string;
  lastStatusUpdate: string; // ISO-formatted datetime
  value: TriggerStatus;
}

export function applyTriggerStateChange(
  change: TriggerStateChangeWSModel,
  target: TriggerInternalModel,
): TriggerInternalModel {
  // we might receive WS messages that are not the "newest" state -- so is this one of those?
  const shouldUpdateState =
    target.history.length === 0 ||
    DateTime.fromISO(change.lastStatusUpdate, { zone: 'utc' }).toMillis() >
      DateTime.fromISO(target.history[target.history.length - 1].timestamp, {
        zone: 'utc',
      }).toMillis();

  const state = shouldUpdateState
    ? {
        lastStatusUpdate: change.lastStatusUpdate,
        status: change.value,
      }
    : target.state;

  const history: TriggerEvent[] = [
    ...target.history,
    {
      timestamp: change.lastStatusUpdate,
      value: change.value,
    },
  ].sort(
    (a, b) =>
      DateTime.fromISO(a.timestamp, { zone: 'utc' }).toMillis() -
      DateTime.fromISO(b.timestamp, { zone: 'utc' }).toMillis(),
  );

  return {
    ...target,
    state,
    history,
  };
}

/**
 * Functions to translate between different variants
 * of the Trigger model
 */
export function toTriggerFormModelFromInternal(
  trigger: TriggerInternalModel,
): TriggerFormModel {
  const { sensorId, name, condition, notifications, transitionConfig } = trigger;

  return {
    sensorId,
    name,
    condition,
    advanced: {
      activeTransitionDelayInMinutes: transitionConfig.activeTransitionDelayInMinutes,
      inactiveTransitionDelayInMinutes: transitionConfig.inactiveTransitionDelayInMinutes,
      unknownTransitionDelayInMinutes: transitionConfig.unknownTransitionDelayInMinutes,
    },
    actions: {
      webhook: notifications.webhooks?.[0]?.url,
      runningState: notifications.runningState?.state,
      disposition: notifications.disposition?.level,
    },
  };
}

export function toTriggerInternalModelFromGQL(
  gql: TriggerGQLModel,
): TriggerInternalModel {
  return {
    id: gql.id,
    sensorId: gql.industrialSensor.id,
    name: gql.name,
    condition: gql.condition!.gt ? { gt: gql.condition!.gt } : { lt: gql.condition!.lt! },
    history: [],
    notifications: toTriggerInternalNotifications(gql.notifications),
    state: gql.state ? gql.state : null,
    transitionConfig: gql.transitionConfig,
  };
}

type TriggerNotificationGQL = TriggerGQLModel['notifications'][number];

export function toTriggerInternalNotifications(notifications: TriggerNotificationGQL[]) {
  return notifications.reduce<TriggerInternalNotifications>(
    (acc, notification) => {
      switch (notification.__typename) {
        case 'WebhookTriggerNotification':
          acc.webhooks!.push({
            url: notification.url,
          });
          break;
        case 'RunningStateTriggerNotification':
          acc.runningState = {
            state: notification.state,
          };
          break;
        case 'DispositionTriggerNotification':
          acc.disposition = {
            message: notification.message,
            level: notification.level,
          };
          break;
      }
      return acc;
    },
    { webhooks: [] },
  );
}

export function toTriggerInternalModelFromWS(
  ws: TriggerWSModel,
): Partial<TriggerInternalModel> {
  return {
    id: ws.id,
    sensorId: ws.source.sourceId,
    name: ws.name,
    condition: ws.condition,
    notifications: ws.notifications,
    transitionConfig: ws.transitionConfig,
  };
}

export function toCreateTriggerInputFromForm(
  form: TriggerFormModel,
  siteId: string,
): CreateTriggerInput {
  return {
    siteId,
    name: form.name,
    condition: form.condition,

    // Only add webhooks, runningState and/or disposition to notifications if the user selected a value for it
    // otherwise don't add that property at all to notifications
    notifications: {
      ...(form.actions.webhook
        ? {
            webhooks: form.actions.webhook ? [{ url: form.actions.webhook }] : [],
          }
        : {}),

      ...(form.actions.runningState
        ? {
            runningState: {
              state: form.actions.runningState,
            },
          }
        : {}),

      ...(form.actions.disposition
        ? {
            disposition: {
              message: form.name,
              level: form.actions.disposition,
            },
          }
        : {}),
    },

    transitionConfig: {
      activeTransitionDelayInMinutes: form.advanced.activeTransitionDelayInMinutes,
      inactiveTransitionDelayInMinutes: form.advanced.inactiveTransitionDelayInMinutes,
      unknownTransitionDelayInMinutes: form.advanced.unknownTransitionDelayInMinutes,
    },
    industrialSensorId: form.sensorId,
  };
}

export function toUpdateTriggerInputFromForm(
  id: string,
  form: TriggerFormModel,
): UpdateTriggerInput {
  return {
    id,
    name: form.name,
    condition: form.condition,

    // Only add webhooks, runningState and/or disposition to notifications if the user selected a value for it
    // otherwise don't add that property at all to notifications
    notifications: {
      ...(form.actions.webhook
        ? {
            webhooks: form.actions.webhook ? [{ url: form.actions.webhook }] : [],
          }
        : {}),

      ...(form.actions.runningState
        ? {
            runningState: {
              state: form.actions.runningState,
            },
          }
        : {}),

      ...(form.actions.disposition
        ? {
            disposition: {
              message: form.name,
              level: form.actions.disposition,
            },
          }
        : {}),
    },

    transitionConfig: {
      activeTransitionDelayInMinutes: form.advanced.activeTransitionDelayInMinutes,
      inactiveTransitionDelayInMinutes: form.advanced.inactiveTransitionDelayInMinutes,
      unknownTransitionDelayInMinutes: form.advanced.unknownTransitionDelayInMinutes,
    },
    industrialSensorId: form.sensorId,
  };
}

export function toTriggerViewModelFromInternal(
  trigger: TriggerInternalModel,
  timezone: string,
): TriggerViewModel {
  const lastStatusUpdate = trigger?.state?.lastStatusUpdate;

  return {
    id: trigger.id,
    name: trigger.name,
    status: trigger?.state?.status ?? 'UNKNOWN',
    lastStatusUpdate: lastStatusUpdate
      ? DateTime.fromISO(lastStatusUpdate, { zone: timezone })
      : null,
    history: trigger.history.map(triggerEvent => {
      return {
        timestamp: DateTime.fromISO(triggerEvent.timestamp, { zone: timezone }),
        value: triggerEvent.value,
      };
    }),
  };
}

export enum TriggerActions {
  webhook = 'Webhook',
  runningState = 'Running State',
  disposition = 'Disposition',
}
