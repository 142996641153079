import { createAction } from '@ngrx/store';
import { SiteControllerFormModel } from '@spog-ui/shared/models/site-controllers';

export const enterAction = createAction(
  '[Edit Site Controller Page] Enter',
  (siteControllerId: string) => ({ siteControllerId }),
);
export const cancelAction = createAction('[Edit Site Controller Page] Cancel');
export const saveAction = createAction(
  '[Edit Site Controller Page] Save',
  (model: SiteControllerFormModel) => ({ model }),
);
