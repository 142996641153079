import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

@Component({
  selector: 'spog-site-select-form',
  template: `
    <form [formGroup]="selectionForm" (ngSubmit)="onSelectSite()">
      <mat-form-field>
        <mat-label>Select Site</mat-label>
        <mat-select formControlName="site">
          @for (site of sites; track trackSites($index, site)) {
            @if (site.enabledProducts.length) {
              <mat-option
                [value]="site"
                [attr.data-site-name]="site.name"
                >
                {{ site.name }}
              </mat-option>
            } @else {
              <mat-option
                [disabled]="true"
                [value]="site"
                [attr.data-site-name]="site.name"
                matTooltip="This site has no active products"
                >
                {{ site.name }}
              </mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
    
      <div class="actions">
        <button
          class="cancelButton"
          type="button"
          mat-button
          color="warn"
          (click)="onCancel()"
          >
          Cancel
        </button>
        <button
          type="submit"
          [disabled]="selectionForm.invalid"
          mat-stroked-button
          color="accent"
          >
          Enter Site
        </button>
      </div>
    </form>
    `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        padding: 24px;
      }

      mat-form-field {
        width: 100%;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
      }

      .cancelButton {
        margin-right: 8px;
      }
    `,
  ],
})
export class SiteSelectFormComponent {
  @Output()
  selectSite = new EventEmitter<SiteInternalModel>();
  @Output()
  cancel = new EventEmitter();
  @Input()
  sites: SiteInternalModel[] = [];

  constructor(private store: Store) {}

  selectionForm: UntypedFormGroup = new UntypedFormGroup({
    site: new UntypedFormControl(null, Validators.required),
  });

  trackSites(index: number, site: SiteInternalModel) {
    return site.id;
  }

  onSelectSite() {
    if (this.selectionForm.valid) {
      this.selectSite.emit(this.selectionForm.value.site);
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}
