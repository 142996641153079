import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'indefiniteArticle',
    standalone: true
})
export class IndefiniteArticlePipe implements PipeTransform {
  transform(value: string): string {
    const article = ['a', 'e', 'i', 'o', 'u'].indexOf(value.charAt(0)) > 0 ? 'an' : 'a';
    return `${article} ${value}`;
  }
}
