import { createAction } from '@ngrx/store';
import { TransformModel } from '@spog-ui/map-tools/models';

export const clickAction = createAction(
  '[Map Tools] Click',
  (point: { x: number; y: number; r: number }) => {
    return { point };
  },
);
export const zoomAndPanAction = createAction(
  '[Map Tools] Zoom and Pan',
  (transform: TransformModel) => {
    return { transform };
  },
);

export type Union = ReturnType<typeof clickAction> | ReturnType<typeof zoomAndPanAction>;

export const Types = {
  Click: clickAction.type,
  ZoomAndPan: zoomAndPanAction.type,
};
