import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-occupancy-only',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        <strong>Auto ON/OFF</strong> - Only the occupancy sensor controls the lights in
        the zone. Upon sensing motion, all lights in the zone are set to a user-defined
        dimming level between 0 and 100%. Upon vacancy detection, two levels of timeout
        and dimming are provided.
      </p>

      <div class="bfForm">
        <div class="occupancy bfChunk">
          <p class="bfLabel">If occupancy is detected, set the dimming level to:</p>
          <sui-dimmer-control formControlName="activeLevel"></sui-dimmer-control>
        </div>

        <div class="idle bfChunk">
          <p class="bfLabel">If vacant for a period of:</p>
          <sui-timeout-control formControlName="vacancyTimeout"></sui-timeout-control>

          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="vacancyLevel"></sui-dimmer-control>
        </div>

        <div class="vacant bfChunk">
          <p class="bfLabel">After an additional vacancy period of:</p>
          <sui-timeout-control
            formControlName="extendedVacancyTimeout"
          ></sui-timeout-control>

          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="extendedVacancyLevel"></sui-dimmer-control>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      sui-dimmer-control {
        display: block;
        margin-bottom: -13px;
      }

      .vacant sui-dimmer-control {
        margin-bottom: 0;
      }
    `,
  ],
})
export class BFOccupancyOnlyComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    activeLevel: new UntypedFormControl(80),
    vacancyTimeout: new UntypedFormControl(30),
    vacancyLevel: new UntypedFormControl(50),
    extendedVacancyTimeout: new UntypedFormControl(15),
    extendedVacancyLevel: new UntypedFormControl(0),
  });
}
