import {
  InviteOrganizationUserInput,
  OrganizationRole,
  OrganizationUserModelFragment,
  PermissionGroupSiteRole,
  UpdateOrganizationUserInput,
} from '@spog-ui/graphql/types';
import { OrganizationUserOrgAdminUpsert as OrganizationUserWSModel } from '@spog-shared/events/organization';

export { OrganizationUserWSModel };

export interface OrganizationUserInternalModel {
  id: string;
  name: string;
  email: string;
  organizationRole: OrganizationRole;
  sites?: UserSiteInternalModel[];
}

export interface UserSiteInternalModel {
  name: string;
}

export interface SiteUserInternalModel {
  name: string;
  siteRole: PermissionGroupSiteRole;
}

export type OrganizationUserGQLModel = OrganizationUserModelFragment;

export interface OrganizationUserFormModel {
  id: string;
  name: string;
  email: string;
  organizationRole: OrganizationRole;
}

export type AddOrganizationUserFormModel = Omit<OrganizationUserFormModel, 'id'>;

export function toOrganizationUserInternalModelFromGQL(
  gql: OrganizationUserGQLModel,
): OrganizationUserInternalModel {
  return {
    id: gql.node.id,
    email: gql.node.email,
    name: gql.node.name,
    organizationRole: gql.organizationRole,
    sites: gql.sites ? gql.sites.map(s => s.site) : [],
  };
}

export function toOrganizationUserInternalModelFromWS(
  ws: OrganizationUserWSModel,
): OrganizationUserInternalModel {
  return {
    id: ws.userId,
    name: ws.name,
    email: ws.email,
    organizationRole: ws.organizationRole,
  };
}

export function toOrganizationUserFormModelFromInternal(
  user: OrganizationUserInternalModel,
): OrganizationUserFormModel {
  return {
    id: user.id,
    name: user.name,
    email: user.email,
    organizationRole: user.organizationRole,
  };
}

export function toOrganizationUserGQLModelFromInternal(
  user: OrganizationUserInternalModel,
): OrganizationUserGQLModel {
  return {
    node: {
      id: user.id,
      name: user.name,
      email: user.email,
    },
    organizationRole: user.organizationRole,
    sites: user.sites
      ? user.sites.map(us => {
          return { site: { name: us.name } };
        })
      : [],
  };
}

export function toUserAddInput(
  form: AddOrganizationUserFormModel,
  organizationId: string,
): InviteOrganizationUserInput {
  return {
    email: form.email,
    organizationId: organizationId,
    organizationRole: form.organizationRole,
  };
}

export function toUserUpdateInput(
  form: OrganizationUserFormModel,
  organizationId: string,
): UpdateOrganizationUserInput {
  return {
    userId: form.id,
    name: form.name,
    organizationRole: form.organizationRole,
    organizationId: organizationId,
  };
}
