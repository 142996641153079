import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

@Component({
  selector: 'scn-zone-behavior-errors',
  template: `
    @if (errorMessage; as message) {
      <p class="mat-body-1">
        {{ message }}
      </p>
    }
    `,
  styles: [
    `
      p {
        margin: 0;
        padding: 9px 18px;
        background-color: var(--color-warn-500);
        color: var(--color-warn-contrast-500);
      }
    `,
  ],
})
export class ZoneBehaviorErrorsComponent {
  @Input()
  control: UntypedFormControl;

  get errorMessage() {
    if (!this.control || !this.touched) {
      return null;
    }

    if (this.control.hasError('suiNoDuplicateZoneInScene')) {
      const zones: ZoneInternalModel[] = this.control.getError(
        'suiNoDuplicateZoneInScene',
      );

      return `Zone${zones.length > 1 ? 's' : ''} ${zones
        .map(z => z.name)
        .join(', ')} cannot be assigned to more than one behavior.`;
    }

    if (this.control.hasError('suiMaxZoneLimitInScene')) {
      const { max } = this.control.getError('suiMaxZoneLimitInScene');

      return `There cannot be more than ${max} zones assigned to behaviors in a scene.`;
    }

    return null;
  }

  get touched() {
    return this.control ? this.control.touched : false;
  }
}
