import { createReducer, on } from '@ngrx/store';
import { MapActions } from '@spog-ui/map/actions';
import { MapToolsActions } from '@spog-ui/map-tools';
import { TransformModel } from '@spog-ui/shared/models/map-positioners';

export interface Shape {
  enabled: boolean;
  transform: TransformModel;
}

export const initialState: Shape = {
  enabled: true,
  transform: {
    k: 1,
    x: 0,
    y: 0,
  },
};

export const reducer = createReducer(
  initialState,
  on(MapActions.enterAction, () => {
    return initialState;
  }),
  on(MapToolsActions.zoomAndPanAction, (state, action) => {
    return {
      ...state,
      transform: action.transform,
    };
  }),
);

export const selectIsEnabled = (state: Shape) => state.enabled;
export const selectTransform = (state: Shape) => state.transform;
