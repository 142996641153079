import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SuiPromptModule } from '@sui/prompt';
import { CurrentUserEffects, AuthEffects } from './lib';

@NgModule({
  imports: [
    EffectsModule.forFeature([CurrentUserEffects, AuthEffects]),
    MatSnackBarModule,
    SuiPromptModule,
  ],
  exports: [],
})
export class CurrentUserModule {}
