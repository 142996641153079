import { createAction } from '@ngrx/store';
import { LightControlViewModel } from '@spog-ui/shared/models/lights';

export const dimLightSuccessAction = createAction(
  '[Light Control API] Dim Light Success',
);
export const dimLightFailureAction = createAction(
  '[Light Control API] Dim Light Failure',
  (light: LightControlViewModel, intendedLevel: number, error?: any) => {
    return { light, intendedLevel, error };
  },
);
