import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DimmerControlComponent } from '@spog-ui/shared/components';
import { TimeoutControlComponentModule } from '../timeout-control';
import { ShortDurationControlComponentModule } from '../short-duration-control';
import { BFSwitchWithBlinkComponent } from './bf-switch-with-blink.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    DimmerControlComponent,
    ShortDurationControlComponentModule,
    TimeoutControlComponentModule,
  ],
  declarations: [BFSwitchWithBlinkComponent],
  exports: [BFSwitchWithBlinkComponent],
})
export class BFSwitchWithBlinkComponentModule {}
