import { createAction } from '@ngrx/store';
import { PowerSourceComparisonInternalModel } from '@spog-ui/shared/models/power-source-comparisons';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { BaselineType } from '@spog-ui/graphql/types';

export const saveBaselineSuccessAction = createAction(
  '[Power API] Baseline Save Success',
  (baseline: number, baselineType: BaselineType) => ({ baseline, baselineType }),
);
export const saveBaselineFailureAction = createAction(
  '[Power API] Baseline Save Failure',
  (error: string) => ({ error }),
);
export const saveBaselineSkipSuccessAction = createAction(
  '[Power API] Baseline Skip Save Success',
  (skip: boolean) => ({ skip }),
);
export const saveBaselineSkipFailureAction = createAction(
  '[Power API] Baseline Skip Save Failure',
  (error: string) => ({ error }),
);
export const loadBaselineSuccessAction = createAction(
  '[Power API] Load Baseline Success',
  (baseline: number | null, skip: boolean, baselineType: BaselineType) => ({
    baseline,
    skip,
    baselineType,
  }),
);
export const loadBaselineFailureAction = createAction(
  '[Power API] Load Baseline Failure',
  (error: string) => ({ error }),
);
export const removeBaselineSuccessAction = createAction(
  '[Power API] Remove Baseline Success',
);
export const removeBaselineFailureAction = createAction(
  '[Power API] Remove Baseline Failure',
  (error: string) => ({ error }),
);
export const getPowerDataSuccessAction = createAction(
  '[Power API] Get Power Data Success',
  (powerSourceComparisons: PowerSourceComparisonInternalModel[]) => ({
    powerSourceComparisons,
  }),
);
export const getPowerDataFailureAction = createAction(
  '[Power API] Get Power Data Failure',
  (error: string) => ({ error }),
);
export const loadSuccessAction = createAction(
  '[Power API] Load Success',
  (zones: ZoneInternalModel[]) => ({
    zones,
  }),
);
export const loadFailureAction = createAction(
  '[Power API] Load Failure',
  (error: string) => ({
    error,
  }),
);
