import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClipboardService } from '@spog-ui/shared/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromptService } from '@sui/prompt';
import { concatMap, map, tap } from 'rxjs/operators';
import { OrganizationsPageActions } from '../actions';
import { Router } from '@angular/router';

@Injectable()
export class OrganizationsPageEffects {
  constructor(
    private actions$: Actions,
    readonly clipboardService: ClipboardService,
    readonly snackbar: MatSnackBar,
    readonly prompt: PromptService,
    readonly router: Router,
  ) {}

  manage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationsPageActions.manageAction),
        tap(action =>
          this.router.navigate(['./admin-organization', action.organization.id]),
        ),
      ),
    { dispatch: false },
  );

  copyOrgId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationsPageActions.copyIDAction),
        tap(action => {
          this.clipboardService.copy(action.organization.id);
          this.snackbar.open('Copied ID to the clipboard', 'Dismiss', {
            duration: 6_000,
          });
        }),
      ),
    { dispatch: false },
  );

  promptForDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsPageActions.deleteAction),
      concatMap(action =>
        this.prompt
          .open({
            title: 'Delete organization and its permission groups?',
            description: '',
            cancelLabel: 'Cancel',
            confirmLabel: 'Delete',
            confirmColor: 'warn',
          })
          .pipe(
            map(confirmed =>
              confirmed
                ? OrganizationsPageActions.confirmDeleteAction(action.organization)
                : OrganizationsPageActions.cancelDeleteAction(action.organization),
            ),
          ),
      ),
    ),
  );
}
