import { ErrorHandler, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { UnhandledExceptionEffects } from './effects/unhandled-exception.effects';
import { LogUnhandledExceptionsErrorHandler } from './error-handler.service';

@NgModule({
  imports: [EffectsModule.forFeature([UnhandledExceptionEffects])],
  providers: [{ provide: ErrorHandler, useClass: LogUnhandledExceptionsErrorHandler }],
})
export class UnhandledExceptionsModule {}
