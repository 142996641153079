import { createReducer, on } from '@ngrx/store';
import {
  SiteControllerDetailsApiActions,
  SiteControllerDetailsActions,
} from '@spog-ui/site-controller-details/state';

export interface Shape {
  isLoading: boolean;
}

export const initialState: Shape = {
  isLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(SiteControllerDetailsActions.enterAction, state => {
    return { ...initialState };
  }),
  on(SiteControllerDetailsApiActions.loadSiteControllerDetailsSuccessAction, state => {
    return { ...state, isLoading: false };
  }),
  on(
    SiteControllerDetailsApiActions.loadSiteControllerDetailsFailureAction,
    (state, action) => {
      return { ...state, isLoading: false, error: action.error };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
