import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SequenceScenePreviewComponent } from './sequence-scene-preview.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SequenceScenePreviewComponent],
  exports: [SequenceScenePreviewComponent],
})
export class SequenceScenePreviewComponentModule {}
