export abstract class PromptOptions {
  title: string;
  description: string;
  confirmLabel: string;
  confirmColor: string;
  cancelLabel: string;
  cancelColor: string;
  confirmButtonTestId: string;
}

export const defaultPromptOptions: PromptOptions = {
  title: 'Continue?',
  description:
    'This action may have unintended consequences. Would you like to continue?',
  confirmLabel: 'Confirm',
  confirmColor: 'primary',
  cancelLabel: 'Cancel',
  cancelColor: 'default',
  confirmButtonTestId: 'generic-dialog-confirm',
};
