import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import {
  ScheduledUtilityRateComponent,
  UtilityRateStartDatePipe,
} from './scheduled-utility-rate.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule],
  declarations: [ScheduledUtilityRateComponent, UtilityRateStartDatePipe],
  exports: [ScheduledUtilityRateComponent],
})
export class ScheduledUtilityRateComponentModule {}
