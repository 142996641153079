import { Component, Input } from '@angular/core';
import { Products } from '@spog-ui/graphql/types';

// TODO: this component is only used by the schedule page component
// and probably needs to live in the schedule feature.  That would also
// help make it clearer why the routing needs to be absolute as
// adding a scene or routine from the schedule page is "switching features"
@Component({
  selector: 'scn-no-scenes-for-enabled-products',
  template: `
    <sui-empty-state>
      <ng-template suiEmptyStateTitle>Get Started</ng-template>
      <ng-template suiEmptyStateMessage
        >Scenes and Routines allow you to apply settings to up to 20 zones. Use the Scenes
        and Routines pages to manually apply them, or use the calendar to schedule when
        they should be applied.</ng-template
        >
        <ng-template suiEmptyStateActions>
          @if (enabledProducts.includes(eProducts.ILLUMINATE)) {
            <span>
              <a
                suiButton
                *suiIfUserCan="['ssc:siteAdmin', 'ssc:schedule-and-scene']"
                class="suiAddScene"
                [routerLink]="urlPrefix + '/lighting/scenes/add'"
                >
                Add Scene</a
                >
              </span>
            }
            @if (enabledProducts.includes(eProducts.CLIMATE)) {
              <span>
                <a
                  suiButton
                  *suiIfUserCan="['ssc:siteAdmin']"
                  class="suiAddScene"
                  [routerLink]="urlPrefix + '/climate/scenes/add'"
                  >
                  Add Routine</a
                  >
                </span>
              }
            </ng-template>
          </sui-empty-state>
    `,
})
export class NoScenesForEnabledProductsComponent {
  eProducts = Products;

  @Input() enabledProducts: Products[] = [];
  @Input() urlPrefix = '';
}
