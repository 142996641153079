import { EulaApiActions, EulaUserActions } from '@spog-ui/current-user/eula-actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  eulaAccepted: boolean;
  error?: any;
}

export const initialState: Shape = {
  eulaAccepted: false,
  error: false,
};

export const reducer = createReducer(
  initialState,
  on(EulaUserActions.eulaAcceptedAction, (state, action) => {
    return {
      ...state,
      eulaAccepted: action.eulaStatus,
    };
  }),
  on(EulaApiActions.getEulaStatusSuccessAction, (state, action) => {
    return {
      ...state,
      eulaAccepted: action.eulaStatus,
      error: false,
    };
  }),
  on(EulaApiActions.saveEulaStatusSuccessAction, (state, action) => {
    return {
      ...state,
      eulaAccepted: action.eulaStatus,
      error: false,
    };
  }),
  on(EulaApiActions.getEulaStatusFailureAction, (state, action) => {
    return {
      ...state,
      eulaAccepted: false,
      error: action.error,
    };
  }),
  on(EulaApiActions.saveEulaStatusFailureAction, (state, action) => {
    return {
      ...state,
      eulaAccepted: false,
      error: action.error,
    };
  }),
);

export const selectError = (state: Shape) => state.error;
export const selectEulaStatus = (state: Shape) => state.eulaAccepted;
