import { Injectable } from '@angular/core';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  BehaviorInternalModel,
  toBehaviorInternalModelFromGQL,
} from '@spog-ui/shared/models/behaviors';
import {
  SceneZoneApiModel,
  SceneZoneBehaviorInternalModel,
  toControlZoneSceneEdgeInputFromSceneZoneBehaviorModel,
  toControlZoneSceneEdgesFromLinkedBehaviorList,
  toSceneZoneBehaviorInternalModelFromGQL,
} from '@spog-ui/shared/models/scene-zone-behaviors';
import {
  SceneApplication,
  SceneInternalModel,
  SceneViewModel,
  toSceneInternalModelFromGQL,
} from '@spog-ui/shared/models/scenes';
import {
  SequenceSceneInternalModel,
  toSequenceSceneInternalModelFromGQL,
  toSequenceSceneStepInputFromInternal,
  fromInputsToSeconds,
} from '@spog-ui/shared/models/sequence-scenes';
import {
  ActiveSequenceSceneInternalModel,
  createMockActiveSequenceSceneModel,
  toActiveSequenceSceneInternalModelFromGQL,
} from '@spog-ui/shared/models/active-sequence-scenes';
import {
  ZoneInternalModel,
  toControlZoneInternalModelFromGQL,
} from '@spog-ui/shared/models/zones';
import { SiteService } from '@spog-ui/shared/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScenesService {
  constructor(readonly gql: GraphQLAPIService, readonly siteService: SiteService) {}

  getAllScenes(siteId: string): Observable<{
    behaviors: BehaviorInternalModel[];
    scenes: SceneInternalModel[];
    sequenceScenes: SequenceSceneInternalModel[];
    sceneZoneBehaviors: SceneZoneBehaviorInternalModel[];
    zones: ZoneInternalModel[];
    activeSequenceScenes: ActiveSequenceSceneInternalModel[];
  }> {
    return this.gql.getScenes({ siteId }).pipe(
      map(({ site }) => {
        if (!site) throw Error('data.site is undefined');

        return {
          behaviors: site.behaviors.map(toBehaviorInternalModelFromGQL),
          scenes: site.scenes.map(toSceneInternalModelFromGQL),
          sequenceScenes: site.sceneSequences.map(toSequenceSceneInternalModelFromGQL),
          sceneZoneBehaviors: site.sceneZoneBehaviors.map(
            toSceneZoneBehaviorInternalModelFromGQL,
          ),
          zones: site.controlZones.map(toControlZoneInternalModelFromGQL),
          activeSequenceScenes: site.activeSceneSequences.map(
            toActiveSequenceSceneInternalModelFromGQL,
          ),
        };
      }),
    );
  }

  applyScene(sceneId: string): Observable<boolean> {
    return this.gql
      .applyScene_v2({ applySceneInput: { id: sceneId } })
      .pipe(map(() => true));
  }

  deleteScene(sceneId: string): Observable<boolean> {
    return this.gql
      .deleteScene_v2({ deleteSceneInput: { id: sceneId } })
      .pipe(map(() => true));
  }

  addScene(
    scene: SceneZoneApiModel,
    application: SceneApplication,
  ): Observable<SceneInternalModel> {
    const siteId = this.siteService.getId();
    if (!siteId) throw new Error('Site ID is not defined');

    return this.gql
      .createScene({
        createSceneInput: {
          siteId,
          name: scene.name,
          application,
          zones: scene.sceneZoneBehaviors.map(
            toControlZoneSceneEdgeInputFromSceneZoneBehaviorModel,
          ),
        },
      })
      .pipe(map(response => toSceneInternalModelFromGQL(response.createScene)));
  }

  editScene(scene: SceneZoneApiModel): Observable<SceneInternalModel> {
    if (!scene.id) throw new Error('Scene ID is not defined');

    return this.gql
      .updateScene_v2({
        updateSceneInput: {
          id: scene.id,
          name: scene.name,
          zones: scene.sceneZoneBehaviors.map(
            toControlZoneSceneEdgeInputFromSceneZoneBehaviorModel,
          ),
        },
      })
      .pipe(map(response => toSceneInternalModelFromGQL(response.updateScene_version_2)));
  }

  duplicateScene(
    scene: SceneViewModel,
    scenes: SceneInternalModel[],
  ): Observable<SceneInternalModel> {
    const siteId = this.siteService.getId();
    if (!siteId) throw new Error('Site ID is not defined');

    return this.gql
      .createScene({
        createSceneInput: {
          siteId,
          name: this.getUniqueName(scene.name, scenes),
          application: scene.application,
          zones: toControlZoneSceneEdgesFromLinkedBehaviorList(scene.linkedBehaviorList),
        },
      })
      .pipe(map(response => toSceneInternalModelFromGQL(response.createScene)));
  }

  getUniqueName(sceneName: string, scenes: SceneInternalModel[]): string {
    if (scenes.some(scene => scene.name === sceneName)) {
      return this.getUniqueName(`${sceneName} - COPY`, scenes);
    }

    return sceneName;
  }

  applySequenceScene(sequenceSceneId: string) {
    return this.gql
      .applySequenceScene({ applySceneSequenceInput: { id: sequenceSceneId } })
      .pipe(map(() => true));
  }

  deleteSequenceScene(sequenceSceneId: string) {
    return this.gql
      .deleteSequenceScene({ deleteSceneSequenceInput: { id: sequenceSceneId } })
      .pipe(map(() => true));
  }

  addSequenceScene(
    sequenceScene: SequenceSceneInternalModel,
  ): Observable<SequenceSceneInternalModel> {
    const siteId = this.siteService.getId();
    if (!siteId) throw new Error('Site ID is not defined');

    const transformedSteps = sequenceScene.steps.map(
      toSequenceSceneStepInputFromInternal,
    );

    if (sequenceScene.loop) {
      transformedSteps.push({
        stepNumber: sequenceScene.steps.length + 1,
        duration: fromInputsToSeconds(
          sequenceScene.loopWaitHours,
          sequenceScene.loopWaitMinutes,
          sequenceScene.loopWaitSeconds,
        ),
      });
    }

    return this.gql
      .createSequenceScene({
        createSceneSequenceInput: {
          siteId,
          name: sequenceScene.name,
          steps: transformedSteps,
          loop: sequenceScene.loop,
        },
      })
      .pipe(
        map(response =>
          toSequenceSceneInternalModelFromGQL(response.createSceneSequence),
        ),
      );
  }

  editSequenceScene(
    sequenceScene: SequenceSceneInternalModel,
  ): Observable<SequenceSceneInternalModel> {
    if (!sequenceScene.id) throw new Error('Sequence Scene ID is not defined');

    const transformedSteps = sequenceScene.steps.map(
      toSequenceSceneStepInputFromInternal,
    );

    if (sequenceScene.loop) {
      transformedSteps.push({
        stepNumber: sequenceScene.steps.length + 1,
        duration: fromInputsToSeconds(
          sequenceScene.loopWaitHours,
          sequenceScene.loopWaitMinutes,
          sequenceScene.loopWaitSeconds,
        ),
      });
    }
    return this.gql
      .updateSequenceScene({
        updateSceneSequenceInput: {
          id: sequenceScene.id,
          name: sequenceScene.name,
          steps: transformedSteps,
          loop: sequenceScene.loop,
        },
      })
      .pipe(
        map(response =>
          toSequenceSceneInternalModelFromGQL(response.updateSceneSequence),
        ),
      );
  }

  duplicateSequenceScene(
    sequenceScene: SequenceSceneInternalModel,
    sequenceScenes: SequenceSceneInternalModel[],
  ) {
    const siteId = this.siteService.getId();
    if (!siteId) throw new Error('Site ID is not defined');

    return this.gql
      .createSequenceScene({
        createSceneSequenceInput: {
          siteId,
          name: this.getUniqueSequenceSceneName(sequenceScene.name, sequenceScenes),
          steps: sequenceScene.steps.map(toSequenceSceneStepInputFromInternal),
          loop: sequenceScene.loop,
        },
      })
      .pipe(
        map(response =>
          toSequenceSceneInternalModelFromGQL(response.createSceneSequence),
        ),
      );
  }

  getUniqueSequenceSceneName(
    sequenceSceneName: string,
    sequenceScenes: SequenceSceneInternalModel[],
  ): string {
    if (sequenceScenes.some(sequenceScene => sequenceScene.name === sequenceSceneName)) {
      return this.getUniqueSequenceSceneName(
        `${sequenceSceneName} - COPY`,
        sequenceScenes,
      );
    }

    return sequenceSceneName;
  }
}
