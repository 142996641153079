import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  OrganizationApiEffects,
  OrganizationsPageEffects,
  AddOrganizationApiEffects,
  EditOrganizationApiEffects,
} from './effects';
import * as OrganizationsPageState from './state/organizations-page.reducer';
import * as AddOrganizationPageState from './state/add-organization-page.reducer';
import * as EditOrganizationPageState from './state/edit-organization-page.reducer';
import * as OrganizationsState from './state/organizations.reducer';
import { featureKey, FeatureShape } from './state';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      OrganizationApiEffects,
      OrganizationsPageEffects,
      AddOrganizationApiEffects,
      EditOrganizationApiEffects,
    ]),
    StoreModule.forFeature<FeatureShape>(featureKey, {
      [OrganizationsPageState.STATE_KEY]: OrganizationsPageState.reducer,
      [AddOrganizationPageState.STATE_KEY]: AddOrganizationPageState.reducer,
      [EditOrganizationPageState.STATE_KEY]: EditOrganizationPageState.reducer,
      [OrganizationsState.STATE_KEY]: OrganizationsState.reducer,
    }),
  ],
  exports: [],
})
export class OrganizationsFeatureStateModule {}
