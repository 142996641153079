import { DateTime } from 'luxon';
import { ActiveSequenceSceneModelFragment } from '@spog-ui/graphql/types';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';
import { SequenceSceneInternalModel } from '@spog-ui/shared/models/sequence-scenes';

export type ActiveSequenceSceneGQLModel = ActiveSequenceSceneModelFragment;

export interface ActiveSequenceSceneViewModel {
  id: string; // used for reducing and web-socket updates
  sequenceSceneName: string;
  nextStepTimestamp: string; // ISO UTC timestamp
  nextStepSceneId: string;
  nextStepSceneName: string;
}

export function createMockActiveSequenceSceneViewModel(): ActiveSequenceSceneViewModel {
  return {
    id: 'mock-sequence-scene-id',
    sequenceSceneName: 'Mock Sequence Scene Name',
    nextStepTimestamp: '2023-04-24T13:32:41Z',
    nextStepSceneId: 'mock-sequence-scene-next-id',
    nextStepSceneName: 'Next Scene Name',
  };
}

export interface ActiveSequenceSceneInternalModel {
  id: string;
  currentStep: number;
  currentStepCompletionTime: number; // Seconds since epoch, UTC
}

export interface ActiveSequenceSceneWSModel {
  id: string;
  currentStep: number;
  currentStepCompletionTime: number; // Seconds since epoch, UTC
}

export function toActiveSequenceSceneInternalModelFromGQL(
  gql: ActiveSequenceSceneGQLModel,
): ActiveSequenceSceneInternalModel {
  return {
    id: gql.sequenceSceneId,
    currentStep: gql.currentStep,
    currentStepCompletionTime: gql.currentStepCompletionTime,
  };
}

export function toActiveSequenceSceneViewModelFromInternal(
  internal: ActiveSequenceSceneInternalModel,
  sequenceScenes: SequenceSceneInternalModel[],
  scenes: SceneInternalModel[],
): ActiveSequenceSceneViewModel {
  const matchingSequenceScene = sequenceScenes.find(
    sequenceScene => sequenceScene.id === internal.id,
  );

  let nextStepNumber = internal.currentStep + 1;

  if (
    matchingSequenceScene?.loop &&
    internal.currentStep === matchingSequenceScene?.steps.length
  ) {
    nextStepNumber = 1;
  }

  const nextStep = matchingSequenceScene?.steps.find(
    step => step.stepNumber === nextStepNumber,
  );
  const matchingScene = scenes.find(
    staticScene => staticScene.id === nextStep?.applySceneId,
  );

  return {
    id: internal.id,
    sequenceSceneName: matchingSequenceScene?.name || 'Scene not found',
    nextStepTimestamp:
      DateTime.fromSeconds(internal.currentStepCompletionTime).toUTC().toISO() ??
      'Scene not found',
    nextStepSceneId: matchingScene?.id || 'Scene not found',
    nextStepSceneName: matchingScene?.name || 'Scene not found',
  };
}
