import { createReducer, on } from '@ngrx/store';
import {
  UtilityServicesApiActions,
  UtilityServicesPageActions,
} from '@spog-ui/utility-services/actions';

export interface Shape {
  utilityServiceId: string | null;
  isSaving: boolean;
}

export const initialState: Shape = {
  utilityServiceId: null,
  isSaving: false,
};

export const reducer = createReducer(
  initialState,
  on(
    UtilityServicesPageActions.scheduleUtilityRateAction,
    (state, action): Shape => {
      return {
        ...state,
        utilityServiceId: action.utilityService.id,
      };
    },
  ),
  on(
    UtilityServicesPageActions.saveScheduledUtilityRateAction,
    (state): Shape => {
      return {
        ...state,
        isSaving: true,
      };
    },
  ),
  on(
    UtilityServicesApiActions.createScheduledUtilityRateFailure,
    (state): Shape => {
      return {
        ...state,
        isSaving: false,
      };
    },
  ),
  on(
    UtilityServicesApiActions.createScheduledUtilityRateSuccess,
    (): Shape => {
      return initialState;
    },
  ),
);

export const selectUtilityServiceId = (state: Shape) => state.utilityServiceId;
export const selectIsSaving = (state: Shape) => state.isSaving;
