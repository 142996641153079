import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-photocell-and-occupancy',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        <strong>Auto On/Off with Daylighting</strong> - If the photocell detects outside
        light, the light is set to a baseline level. Photocell timeouts are provided to
        avoid the light repeatedly turning on and off.
      </p>
      <p class="bfDescription">
        The occupancy sensor controls the lights in the zone only when the photocell
        detects darkness. Upon vacancy detection, two levels of timeout and dimming are
        provided.
      </p>

      <div class="bfForm">
        <div class="bfChunk">
          <p class="bfLabel">If daylight for a period of:</p>
          <sui-timeout-control
            formControlName="photocellHighTimeout"
          ></sui-timeout-control>

          <p class="bfLabel">Disable motion sensor and set dimming level to:</p>
          <sui-dimmer-control formControlName="baselineLevel"></sui-dimmer-control>

          <p class="bfLabel">Enable motion sensor if dark for a period of:</p>
          <sui-timeout-control
            formControlName="photocellLowTimeout"
          ></sui-timeout-control>
        </div>

        <div class="bfChunk">
          <p class="bfLabel">If occupancy is detected, set the dimming level to:</p>
          <sui-dimmer-control formControlName="activeLevel"></sui-dimmer-control>
        </div>

        <div class="bfChunk">
          <p class="bfLabel">If vacant for a period of:</p>
          <sui-timeout-control formControlName="vacancyTimeout"></sui-timeout-control>

          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="vacancyLevel"></sui-dimmer-control>
        </div>

        <div class="bfChunk">
          <p class="bfLabel">After an additional vacancy period of:</p>
          <sui-timeout-control
            formControlName="extendedVacancyTimeout"
          ></sui-timeout-control>

          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="extendedVacancyLevel"></sui-dimmer-control>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      sui-dimmer-control {
        display: block;
      }

      sui-dimmer-control:last-child {
        margin-bottom: -13px;
      }

      .bfChunk:last-child sui-dimmer-control:last-child {
        margin-bottom: 0;
      }
    `,
  ],
})
export class BFPhotocellAndOccupancyComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    baselineLevel: new UntypedFormControl(0),
    photocellHighTimeout: new UntypedFormControl(0.5),
    photocellLowTimeout: new UntypedFormControl(0.5),
    activeLevel: new UntypedFormControl(80),
    vacancyTimeout: new UntypedFormControl(30),
    vacancyLevel: new UntypedFormControl(50),
    extendedVacancyTimeout: new UntypedFormControl(15),
    extendedVacancyLevel: new UntypedFormControl(0),
  });
}
