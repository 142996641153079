import { CanvasFactory } from './canvas-factory.service';
import { ImageLoader } from './image-loader.service';
import { Injectable } from '@angular/core';

export interface SpriteSheet {
  src: string;
  size: number;
  labels: number[];
}

export class ResolvedSpriteSheet {
  private sprites: Map<number, HTMLCanvasElement>;

  constructor(sprites: [number, HTMLCanvasElement][], readonly spriteSize: number) {
    this.sprites = new Map(sprites);
  }

  draw(
    ctx: CanvasRenderingContext2D,
    label: number,
    x: number,
    y: number,
    w: number,
    h: number,
  ) {
    const imageData = this.sprites.get(label);

    if (!imageData) throw new Error(`No sprite with label: ${label}`);

    ctx.drawImage(imageData, x, y, w, h);
  }
}

@Injectable({ providedIn: 'root' })
export class SpriteSheetLoader {
  constructor(readonly canvasFactory: CanvasFactory, readonly imageLoader: ImageLoader) {}

  async loadSpriteSheet(spriteSheet: SpriteSheet): Promise<ResolvedSpriteSheet> {
    const img = await this.imageLoader.loadImage(spriteSheet.src);
    const canvas = this.canvasFactory.createCanvas(img.width, img.height);
    const ctx = canvas.getContext('2d');

    if (!ctx) throw new Error('Could not get 2d context');

    ctx.drawImage(img, 0, 0);

    const sprites: [number, HTMLCanvasElement][] = spriteSheet.labels.map(
      (label, index) => {
        const imageData = ctx.getImageData(
          0,
          index * spriteSheet.size,
          spriteSheet.size,
          spriteSheet.size,
        );
        const sprite = this.canvasFactory.createCanvas(
          spriteSheet.size,
          spriteSheet.size,
        );
        const spriteCtx = sprite.getContext('2d');

        if (!spriteCtx) throw new Error("Could not get sprite's 2d context");

        spriteCtx.putImageData(imageData, 0, 0);

        return [label, sprite];
      },
    );

    return new ResolvedSpriteSheet(sprites, spriteSheet.size);
  }
}
