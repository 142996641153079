import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as TriggersState from './triggers.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(TriggersState.STATE_KEY, TriggersState.reducers),
  ],
})
export class TriggersStateModule {}
