import { createSelector } from '@ngrx/store';
import { selectEditOrgsPage } from './admin-section-organizations.feature-selector';
import * as EditOrgPageState from './edit-organization-page.reducer';

export const selectIsEditOrgPageLoading = createSelector(
  selectEditOrgsPage,
  EditOrgPageState.selectIsLoading,
);

export const selectEditOrgPageError = createSelector(
  selectEditOrgsPage,
  EditOrgPageState.selectError,
);

export const selectIsEditOrgPageSubmitting = createSelector(
  selectEditOrgsPage,
  EditOrgPageState.selectIsSubmitting,
);

export const selectOrganizationIdForEdit = createSelector(
  selectEditOrgsPage,
  EditOrgPageState.selectActiveId,
);
