import { createAction } from '@ngrx/store';

export const enterAction = createAction('[Indie Sensor Details Page] Enter');

export const closeAction = createAction('[Indie Sensor Details Page] Close');

export const deleteIndustrialSensorAction = createAction(
  '[Indie Sensor Details Page] Delete Industrial Sensor',
  (indieSensorId: string, indieSensorName: string) => ({
    indieSensorId,
    indieSensorName,
  }),
);
export const confirmDeleteIndustrialSensorAction = createAction(
  '[Indie Sensor Details Page] Confirm Delete Industrial Sensor',
  (indieSensorId: string) => ({
    indieSensorId,
  }),
);
export const cancelDeleteIndustrialSensorAction = createAction(
  '[Indie Sensor Details Page] Cancel Delete Industrial Sensor',
);
export const copyIdAction = createAction(
  '[Indie Sensor Details Page] Copy Sensor ID',
  (sensorId: string) => {
    return { sensorId };
  },
);
