import { Component } from '@angular/core';

@Component({
    selector: 'sui-card',
    template: ` <ng-content></ng-content> `,
    styles: [
        `
      :host {
        display: block;
        position: relative;
        border-radius: 2px;
        background-color: var(--color-background-card);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      }
    `,
    ],
    standalone: true,
})
export class CardComponent {}
