import { createAction } from '@ngrx/store';
import { Bridge485InternalModel } from '@spog-ui/shared/models/bridge-485s';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';

export const loadSuccess = createAction(
  '[Bridge 485 Details / API] Load Models Success',

  (models: {
    bridge485: Bridge485InternalModel;
    indieSensors: IndieSensorInternalModel[];
  }) => ({ models }),
);

export const loadFailure = createAction(
  '[Bridge 485 Details / API] Load Models Failure',
  (error: string) => ({ error }),
);

export const pingSuccess = createAction('[Bridge 485 Details / API] Ping Success');

export const pingFailure = createAction(
  '[Bridge 485 Details / API] Ping Failure',
  (error: string) => ({ error }),
);

export const deleteSuccess = createAction(
  '[Bridge 485 Details / API] Delete Success',
  (bridge485Id: string) => ({ bridge485Id }),
);

export const deleteFailure = createAction(
  '[Bridge 485 Details / API] Delete Failure',
  (error: string) => ({ error }),
);
