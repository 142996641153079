export interface GraphModel {
  width: number;
  height: number;
  margin: Margin;
}

export interface Margin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export const ZONE_COMPARISON_GRAPH: GraphModel = {
  width: 1025,
  height: 550,
  margin: {
    top: 40,
    bottom: 50,
    left: 70,
    right: 35,
  },
};

export const SPARK_LINE_GRAPH: GraphModel = {
  width: 275,
  height: 20,
  margin: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export const TRIGGER_THRESHOLD_GRAPH: GraphModel = {
  width: 775,
  height: 260,
  margin: {
    left: 40,
    top: 30,
    right: 0,
    bottom: 40,
  },
};
