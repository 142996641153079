import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonComponent, SuiEmptyStateModule } from '@spog-ui/shared/components';
import { NoSitesComponent } from './no-sites.component';

@NgModule({
  imports: [CommonModule, RouterModule, SuiEmptyStateModule, ButtonComponent],
  declarations: [NoSitesComponent],
  exports: [NoSitesComponent],
})
export class NoSitesComponentModule {}
