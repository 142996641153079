import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-on',
  template: ` <p class="bfDescription">Lights in the zone turn on.</p> `,
})
export class BFOnComponent implements BehaviorForm {
  form = new UntypedFormControl(null);
}
