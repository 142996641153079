import { BrowserSizeActions } from '@spog-ui/map/actions';
import { BrowserActions } from '@spog-ui/shared/effects/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  width: number;
  height: number;
  online: boolean;
}

export const initialState: Shape = {
  width: 1920,
  height: 1080,
  online: true,
};

export const reducer = createReducer(
  initialState,
  on(
    BrowserSizeActions.currentSizeReadAction,
    BrowserSizeActions.resizeAction,
    (state, action) => {
      return {
        ...state,
        width: action.width,
        height: action.height,
      };
    },
  ),
  on(BrowserActions.onlineAction, (state, action) => {
    return {
      ...state,
      online: true,
    };
  }),
  on(BrowserActions.offlineAction, (state, action) => {
    return {
      ...state,
      online: false,
    };
  }),
);

export const selectSize = (state: Shape) => ({
  width: state.width,
  height: state.height,
});

export const selectIsOnline = (state: Shape) => state.online;

export const selectIsOffline = (state: Shape) => !state.online;
