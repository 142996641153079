import { createAction } from '@ngrx/store';
import { SceneApplication, SceneViewModel } from '@spog-ui/shared/models/scenes';
import {
  SequenceSceneViewModel,
  SequenceSceneInternalModel,
} from '@spog-ui/shared/models/sequence-scenes';

export const enterAction = createAction(
  '[Scenes Page] Enter | @spog-sui/scenes',
  (application: SceneApplication) => ({ application }),
);
export const leaveAction = createAction('[Scenes Page] Leave | @spog-sui/scenes');
export const applySceneAction = createAction(
  '[Scenes Page] Apply Scene | @spog-sui/scenes',
  (scene: SceneViewModel) => {
    return { scene };
  },
);
export const applySequenceSceneAction = createAction(
  '[Scenes Page] Apply Sequence Scene | @spog-sui/scenes',
  (sequenceScene: SequenceSceneViewModel) => {
    return { sequenceScene };
  },
);
export const searchAction = createAction(
  '[Scenes Page] Search | @spog-sui/scenes',
  (searchTerm: string) => {
    return { searchTerm };
  },
);
export const deleteSceneAction = createAction(
  '[Scenes Page] Delete Scene | @spog-sui/scenes',
  (application: SceneApplication, sceneId: string) => ({ application, sceneId }),
);
export const deleteSceneCancelAction = createAction(
  '[Scenes Page] Delete Scene Cancel | @spog-sui/scenes',
);
export const deleteSceneConfirmAction = createAction(
  '[Scenes Page] Delete Scene Confirm | @spog-sui/scenes',
  (application: SceneApplication, sceneId: string) => ({ application, sceneId }),
);
export const deleteSequenceSceneAction = createAction(
  '[Scenes Page] Delete Sequence Scene | @spog-sui/scenes',
  (sequenceSceneId: string) => ({ sequenceSceneId }),
);
export const deleteSequenceSceneCancelAction = createAction(
  '[Scenes Page] Delete Sequence Scene Cancel | @spog-sui/scenes',
);
export const deleteSequenceSceneConfirmAction = createAction(
  '[Scenes Page] Delete Sequence Scene Confirm | @spog-sui/scenes',
  (sequenceSceneId: string) => ({ sequenceSceneId }),
);
export const duplicateSceneAction = createAction(
  '[Scenes Page] Duplicate Scene | @spog-sui/scenes',
  (scene: SceneViewModel) => {
    return { scene };
  },
);
export const duplicateSequenceSceneAction = createAction(
  '[Scenes Page] Duplicate Sequence Scene | @spog-sui/scenes',
  (sequenceScene: SequenceSceneInternalModel) => {
    return { sequenceScene };
  },
);

export const scheduleSceneAction = createAction(
  '[Scenes Page] Schedule Scene',
  (sceneId: string) => ({ sceneId }),
);

export const scheduleSequenceSceneAction = createAction(
  '[Scenes Page] Schedule Sequence Scene',
  (sequenceSceneId: string) => ({ sequenceSceneId }),
);

export const scheduleSceneToExistingEventAction = createAction(
  '[Scenes Page / Scheduling] Schedule Scene to Existing Event',
  (sceneId: string, scheduledEventId: string) => ({
    sceneId,
    scheduledEventId,
  }),
);

export const scheduleSequenceSceneToExistingEventAction = createAction(
  '[Scenes Page / Scheduling] Schedule Sequence Scene to Existing Event',
  (sequenceSceneId: string, scheduledEventId: string) => ({
    sequenceSceneId,
    scheduledEventId,
  }),
);

export const cancelScheduleSceneAction = createAction(
  '[Scenes Page / Scheduling] Cancel Schedule Scene',
);

export const cancelScheduleSequenceSceneAction = createAction(
  '[Scenes Page / Scheduling] Cancel Schedule Sequence Scene',
);

export type Union =
  | ReturnType<typeof enterAction>
  | ReturnType<typeof leaveAction>
  | ReturnType<typeof applySceneAction>
  | ReturnType<typeof applySequenceSceneAction>
  | ReturnType<typeof searchAction>
  | ReturnType<typeof deleteSceneAction>
  | ReturnType<typeof deleteSceneCancelAction>
  | ReturnType<typeof deleteSceneConfirmAction>
  | ReturnType<typeof deleteSequenceSceneAction>
  | ReturnType<typeof deleteSequenceSceneCancelAction>
  | ReturnType<typeof deleteSequenceSceneConfirmAction>
  | ReturnType<typeof duplicateSceneAction>
  | ReturnType<typeof duplicateSequenceSceneAction>
  | ReturnType<typeof scheduleSceneAction>
  | ReturnType<typeof scheduleSceneToExistingEventAction>
  | ReturnType<typeof cancelScheduleSceneAction>
  | ReturnType<typeof scheduleSequenceSceneAction>
  | ReturnType<typeof scheduleSequenceSceneToExistingEventAction>
  | ReturnType<typeof cancelScheduleSequenceSceneAction>;

export const Types = {
  Enter: enterAction.type,
  Leave: leaveAction.type,
  ApplyScene: applySceneAction.type,
  ApplySequenceScene: applySequenceSceneAction.type,
  Search: searchAction.type,
  DeleteScene: deleteSceneAction.type,
  DeleteSceneCancel: deleteSceneCancelAction.type,
  DeleteSceneConfirm: deleteSceneConfirmAction.type,
  DeleteSequenceScene: deleteSequenceSceneAction.type,
  DeleteSequenceSceneCancel: deleteSequenceSceneCancelAction.type,
  DeleteSequenceSceneConfirm: deleteSequenceSceneConfirmAction.type,
  DuplicateScene: duplicateSceneAction.type,
  DuplicateSequenceScene: duplicateSequenceSceneAction.type,
  ScheduleScene: scheduleSceneAction.type,
  ScheduleSceneToExistingEvent: scheduleSceneToExistingEventAction.type,
  CancelScheduleScene: cancelScheduleSceneAction.type,
  ScheduleSequenceScene: scheduleSequenceSceneAction.type,
  ScheduleSequenceSceneToExistingEvent: scheduleSequenceSceneToExistingEventAction.type,
  CancelScheduleSequenceScene: cancelScheduleSequenceSceneAction.type,
};
