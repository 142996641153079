import {
  SiteSelectorPageActions,
} from '@spog-ui/site-selector/actions';
import { SiteGuardActions } from '@spog-ui/shared/state/site/site-actions';
import { createReducer, on } from '@ngrx/store';
import { SitesStateActions } from '../site/actions';
import { SiteDetailsActions } from '@spog-ui/site-details/actions';

export interface Shape {
  selectedSiteId: string | null;
  sitesLoading: boolean;
}

const initialState: Shape = {
  selectedSiteId: null,
  sitesLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(
    SiteSelectorPageActions.selectAction,
    SiteDetailsActions.logInToSiteAction,
    SiteDetailsActions.logInToSiteWithElevatedAccessAction,
    SiteGuardActions.changedSiteAction,
    (state, action) => {
      return { ...state, selectedSiteId: action.site.id };
    },
  ),
  on(SitesStateActions.loadSitesSuccessAction, state => {
    return { ...state, sitesLoading: false };
  }),
);

export const selectSelectedSiteId = (state: Shape) => state.selectedSiteId;
