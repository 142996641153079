import { Component } from '@angular/core';

@Component({
  selector: 'spog-lighting-app',
  template: `
    <spog-toolbar> </spog-toolbar>
    <spog-main-nav-drawer></spog-main-nav-drawer>
    <router-outlet></router-outlet>
  `,
  styles: [
    `
      spog-toolbar {
        top: var(--navbar-offset);
        position: fixed;
        width: 100%;
        z-index: 800;
      }
    `,
  ],
})
export class LightingAppComponent {}
