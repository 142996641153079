import { createAction } from '@ngrx/store';
import { EquipmentFormModel } from '@spog-ui/shared/models/equipment';

export const enterAction = createAction(
  '[Edit Equipment Page] Enter',
  (equipmentId: string) => ({
    equipmentId,
  }),
);

export const loadPageModelsFromGQL = createAction(
  '[Edit Equipment Page] Load Page Models',
);

export const editEquipmentSubmitAction = createAction(
  '[Edit Equipment Page] Edit Equipment Submit',
  (id: string, equipment: EquipmentFormModel) => ({ id, equipment }),
);
