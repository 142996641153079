import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { BehaviorInternalModel } from '@spog-ui/shared/models/behaviors';
import { ZoneBehaviorFormModel, ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { SceneZoneApiModel } from '@spog-ui/shared/models/scene-zone-behaviors';
import {
  SceneInternalModel,
  SceneApplication,
  SceneFormModel,
} from '@spog-ui/shared/models/scenes';
import { markAsTouched } from '@spog-ui/shared/components';
import { validateZoneConstraints } from '../zone-behavior-control-list';

@Component({
  selector: 'scn-scene-form',
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <input type="hidden" formControlName="id" />
      <div class="suiSceneFormBody">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input
            type="text"
            matInput
            formControlName="name"
            [scnValidateSceneName]="takenSceneNames"
            />
          <mat-error [suiFormError]="form.get('name')?.errors"></mat-error>
        </mat-form-field>
    
        <scn-zone-behavior-control-list
          [application]="application"
          formControlName="zoneBehaviors"
          [zones]="zones"
          [behaviors]="behaviors"
          >
          <scn-zone-behavior-errors
            [control]="form.get('zoneBehaviors')"
          ></scn-zone-behavior-errors>
        </scn-zone-behavior-control-list>
      </div>
    
      <div class="suiSceneFormActions">
        <button
          type="submit"
          suiButton
          raised
          color="accent"
          [disabled]="form.disabled"
          >
          @switch (mode) {
            @case ('add') {
              {{ application === eApplication.CLIMATE ? 'Create Routine' : 'Create Scene' }}
            }
            @case ('edit') {
              {{ application === eApplication.CLIMATE ? 'Save Routine' : 'Save Scene' }}
            }
            @default {
              Mode not supported
            }
          }
        </button>
    
        <button [disabled]="form.disabled" [routerLink]="'../'" suiButton type="button">
          Cancel
        </button>
      </div>
    </form>
    `,
  styles: [
    `
      :host {
        width: 100%;
        display: block;
        padding: 16px;
        margin: 0 auto;
      }

      form {
        display: block;
        width: 100%;
        max-width: 740px;
        margin: 0 auto;
      }

      .suiSceneFormBody {
        padding: 8px;
        width: 100%;
      }

      .suiSceneFormBody mat-form-field {
        max-width: 400px;
        width: 100%;
      }
    `,
  ],
})
export class SceneFormComponent implements AfterViewInit {
  eApplication = SceneApplication;

  public form = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    name: new UntypedFormControl(''),
    zoneBehaviors: new UntypedFormControl([], validateZoneConstraints),
  });
  public mode: 'add' | 'edit' = 'add';
  public takenSceneNames: string[] = [];

  @Input() application: SceneApplication = SceneApplication.LIGHTING;
  @Input() zones: ZoneInternalModel[] = [];
  @Input() behaviors: BehaviorInternalModel[] = [];
  @ViewChild(MatInput, { static: true }) nameInput?: MatInput;

  @Input() set scenes(scenes: SceneInternalModel[]) {
    this.takenSceneNames = scenes.map(scene => scene.name);
  }
  @Input() set value(value: SceneFormModel) {
    this.mode = 'edit';
    this.form.patchValue(value);
  }
  @Input() set disabled(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }
  @Output() save = new EventEmitter<SceneZoneApiModel>();

  ngAfterViewInit(): void {
    if (this.nameInput && this.nameInput.focus && this.mode === 'add') {
      this.nameInput.focus();
    }
  }

  onSubmit(): void {
    markAsTouched(this.form);
    if (!this.form.valid) return void 0;

    this.save.emit({
      id: this.form.value.id,
      name: this.form.value.name.trim(),
      sceneZoneBehaviors: this.form.value.zoneBehaviors.flatMap(
        (behavior: ZoneBehaviorFormModel) =>
          behavior.zones.map((zone: ZoneInternalModel) => ({
            sceneId: this.form.value.name,
            behaviorId: behavior.behaviorId,
            behaviorParameters: behavior.behaviorParameters,
            zoneId: zone.id,
          })),
      ),
    });
  }
}
