import {
  EditScenePageComponent,
  EditScenePageComponentModule,
  EditScenePageState,
} from './components/edit-scene-page';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import {
  EditSequenceScenePageComponentModule,
  EditSequenceScenePageComponent,
  EditSequenceScenePageState,
} from './components';
import { EditSequenceScenePageStateAdapter } from './scenes.state-adapters';

@NgModule({
  exports: [EditScenePageComponentModule],
})
class EditScenesFeatureModule {
  static connect(
    StateAdapterClass: Type<EditScenePageState>,
  ): ModuleWithProviders<EditScenesFeatureModule> {
    return {
      ngModule: EditScenesFeatureModule,
      providers: [
        {
          provide: EditScenePageState,
          useClass: StateAdapterClass,
        },
      ],
    };
  }
}

@NgModule({
  exports: [EditSequenceScenePageComponentModule],
})
class EditSequenceScenesFeatureModule {
  static connect(
    StateAdapterClass: Type<EditSequenceScenePageStateAdapter>,
  ): ModuleWithProviders<EditSequenceScenesFeatureModule> {
    return {
      ngModule: EditSequenceScenesFeatureModule,
      providers: [
        {
          provide: EditSequenceScenePageState,
          useClass: StateAdapterClass,
        },
      ],
    };
  }
}

export {
  EditScenePageComponent,
  EditScenePageState,
  EditScenesFeatureModule,
  EditSequenceScenePageComponent,
  EditSequenceScenePageState,
  EditSequenceScenesFeatureModule,
};
