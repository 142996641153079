import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ButtonComponent, SuiCardModule } from '@spog-ui/shared/components';
import { ZoneBehaviorControlComponentModule } from '../zone-behavior-control';
import { ZoneBehaviorControlListValidatorDirective } from './zone-behavior-control-list-validator.directive';
import { ZoneBehaviorControlListComponent } from './zone-behavior-control-list.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SuiCardModule,
    ButtonComponent,
    ZoneBehaviorControlComponentModule,
  ],
  declarations: [
    ZoneBehaviorControlListValidatorDirective,
    ZoneBehaviorControlListComponent,
  ],
  exports: [ZoneBehaviorControlListValidatorDirective, ZoneBehaviorControlListComponent],
})
export class ZoneBehaviorControlListComponentModule {}
