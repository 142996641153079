import { Component, Injectable, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { SequenceSceneInternalModel } from '@spog-ui/shared/models/sequence-scenes';
import { EditSequenceScenePageActions } from '@spog-ui/scenes/actions';
import { EditSequenceScenePageStateAdapter } from '../../scenes.state-adapters';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';

@Injectable()
export abstract class EditSequenceScenePageState {
  abstract ready$: Observable<boolean>;
  abstract sequenceScenes$: Observable<SequenceSceneInternalModel[]>;
  abstract staticScenes$: Observable<SceneInternalModel[]>;
  abstract saving$: Observable<boolean>;
  abstract sequenceSceneToEdit$: Observable<SequenceSceneInternalModel>;
  abstract dispatch(action: EditSequenceScenePageActions.Union): void;
}

@Component({
  selector: 'scn-edit-sequence-scene-page',
  template: `
    @if (state.ready$ | async) {
      <scn-sequence-scene-form
        [value]="state.sequenceSceneToEdit$ | async"
        [sequenceScenes]="state.sequenceScenes$ | async"
        [staticScenes]="state.staticScenes$ | async"
        [disabled]="state.saving$ | async"
        (save)="onSave($event)"
        >
      </scn-sequence-scene-form>
    } @else {
      <sui-spinner></sui-spinner>
    }
    
    `,
  styles: [
    `
      sui-spinner {
        display: block;
        width: 50px;
        margin: 0 auto;
      }
    `,
  ],
})
export class EditSequenceScenePageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(public state: EditSequenceScenePageState, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscriptions = this.route.paramMap
      .pipe(
        map(param => param.get('id')),
        filter((id): id is string => !!id && typeof id === 'string'),
      )
      .subscribe((id: string) =>
        this.state.dispatch(EditSequenceScenePageActions.enterAction(id)),
      );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSave(scene: SequenceSceneInternalModel): void {
    this.state.dispatch(EditSequenceScenePageActions.editSequenceSceneAction(scene));
  }
}
