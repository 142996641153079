import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Application, BehaviorModelFragment } from '@spog-ui/graphql/types';
import { camelCase, snakeCase } from './change-case.util';

export type BehaviorGQLModel = BehaviorModelFragment;
export { Application as BehaviorApplication };

export const HIDDEN_GROUP = 10;

export interface BehaviorInternalModel {
  // should be a superset of @spog-ui/shared/models/behaviors BehaviorModel
  id: BehaviorType;
  displayGroup: number;
  displayOrder: number;
  application: Application;
  isLicensed: boolean;
}

export function toBehaviorInternalModelFromGQL(
  gqlModel: BehaviorGQLModel,
): BehaviorInternalModel {
  return {
    id: gqlModel.id as BehaviorType,
    displayGroup: gqlModel.displayGroup,
    displayOrder: gqlModel.displayOrder,
    application: gqlModel.application,
    isLicensed: gqlModel.isLicensed,
  };
}

export interface BehaviorForm {
  form: UntypedFormControl | UntypedFormGroup;
  writeBehaviorParameters?: (value: any) => void;
  getBehaviorParameters?: () => any;
}

export function parseParameters(parameters: string | null) {
  if (parameters) {
    return toNormalizedModel(JSON.parse(parameters));
  }

  return null;
}

export function serializeParameters(parameters: Record<string, unknown> | null) {
  if (parameters) {
    return JSON.stringify(fromNormalizedModel(parameters));
  }

  return null;
}

export const toNormalizedModel = createModelTransformer(camelCase, [
  'removable',
  'ota_id',
]);

export const fromNormalizedModel = createModelTransformer(snakeCase, []);

export function createModelTransformer(
  transformerFunction: (input: string) => string,
  keysToRemove: string[],
): (model: any) => any {
  return function (object: any) {
    const keys = Object.keys(object).map<[string, string]>(key => [
      key,
      transformerFunction(key),
    ]);
    const map = new Map<string, string>(keys);
    keysToRemove.forEach(key => map.delete(key));

    return Array.from(map).reduce((result, [oldKey, newKey]) => {
      return {
        ...result,
        [newKey]: object[oldKey],
      };
    }, {});
  };
}

export type BehaviorParameters = string | null;

export enum BehaviorType {
  BasicColor = 'Basic Color',
  ColorPaparazzi = 'Color Paparazzi',
  ColorRandom = 'Color Random',
  ColorSparkle = 'Color Sparkle',
  Dimmer = 'Dimmer',
  DLH = 'Daylight Harvesting',
  None = 'No Behavior',
  OccupancyOnly = 'Occupancy-only',
  Off = 'Off',
  On = 'On',
  Paparazzi = 'Paparazzi',
  PhotocellOnly = 'Photocell-only',
  PhotocellAndOccupancy = 'Photocell and Occupancy',
  PhotocellAndSwitch = 'Photocell and Switch',
  SwitchPhotocellAndOccupancy = 'Switch, Photocell and Occupancy',
  SwitchPhotocellAndVacancy = 'Switch, Photocell and Vacancy',
  Pinwheel = 'Pinwheel',
  Pinwheel5 = 'Pinwheel-5',
  Random = 'Random',
  Sparkle = 'Sparkle',
  StaticClimate = 'Static Climate',
  SwitchOnly = 'Switch-only',
  SwitchAndOccupancy = 'Switch and Occupancy',
  SwitchAndVacancy = 'Switch and Vacancy',
  SwitchWithBlink = 'Switch Control with Blink Warning',
  MonoPaparazzi = '$MONO Paparazzi',
  MonoRandom = '$MONO Random',
  MonoSparkle = '$MONO Sparkle',
  Fade = 'Fade',
  ColorFade = 'Color Fade',
}

export enum BehaviorGroup {
  Basic = 1,
  SingleSensor,
  Switch,
  Photocell,
  Complex,
  Color,
  Effect,
  Mono,
}

export interface BehaviorModel {
  id: BehaviorType;
  displayGroup: number;
  displayOrder: number;
}
