import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { ThermodialComponent } from './thermodial/thermodial.component';
import { ThermodialBackgroundComponent } from './thermodial-background/thermodial-background.component';
import { ThermodialNubComponent } from './thermodial-nub';
import { ThermodialArcComponent } from './thermodial-arc';
import { ThermodialTemperatureInputComponent } from './thermodial-temperature-input';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatIconModule,
    MatInputModule,
  ],
  declarations: [
    ThermodialComponent,
    ThermodialBackgroundComponent,
    ThermodialNubComponent,
    ThermodialArcComponent,
    ThermodialTemperatureInputComponent,
  ],
  exports: [ThermodialComponent, ThermodialBackgroundComponent],
})
export class ThermodialModule {}
