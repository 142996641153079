import {
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TemplatePortalDirective, CdkPortalOutlet } from '@angular/cdk/portal';
import { NgIf } from '@angular/common';

@Directive({
    selector: '[suiAccordionHeader]',
    standalone: true
})
export class AccordionHeaderDirective extends TemplatePortalDirective {
  constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(templateRef, viewContainerRef);
  }
}

@Directive({
    selector: '[suiAccordionBody]',
    standalone: true
})
export class AccordionBodyDirective extends TemplatePortalDirective {
  constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(templateRef, viewContainerRef);
  }
}

@Directive({
    selector: '[suiAccordionFooter]',
    standalone: true
})
export class AccordionFooterDirective extends TemplatePortalDirective {
  constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(templateRef, viewContainerRef);
  }
}

@Component({
    selector: 'sui-accordion',
    template: `
    <div class="suiAccordionHeader" [class.open]="open" (click)="onToggle($event)">
      <ng-template [cdkPortalOutlet]="header"></ng-template>
    </div>
    @if (open) {
      <div
        class="suiAccordionContent"
        #scrollTarget
        [@shrinkOut]="'in'"
        (@shrinkOut.done)="onShrinkOut($event)"
        >
        <div class="suiAccordionBody">
          <ng-template [cdkPortalOutlet]="body"></ng-template>
        </div>
        <div class="suiAccordionFooter">
          <ng-template [cdkPortalOutlet]="footer"></ng-template>
        </div>
      </div>
    }
    `,
  styles: [
    `
      :host {
        display: block;
        box-sizing: border-box;
        border-radius: 2px;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      }

      .suiAccordionHeader {
        box-sizing: border-box;
        padding: 14px 60px 14px 26px;
        background-color: var(--color-background-card);
        transition: all 200ms;
        position: relative;
        cursor: pointer;
      }

      .suiAccordionHeader:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: 50%;
        margin-top: -5px;
        right: 18px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 6px solid black;
        transition: all 200ms;
      }

      .suiAccordionHeader.open:after {
        transform: rotate(90deg);
        border-left-color: var(--color-background-card);
      }

      .suiAccordionHeader.open {
        background-color: var(--color-500);
        color: var(--color-contrast-500);
      }

      .suiAccordionContent {
        overflow: hidden;
      }

      .suiAccordionBody,
      .suiAccordionFooter {
        padding: 12px 0;
      }
    `,
    ],
    animations: [
        trigger('shrinkOut', [
            state('in', style({ height: '*' })),
            state('void', style({ height: 0 })),
            transition('* <=> void', [animate(200)]),
        ]),
    ],
    standalone: true,
    imports: [CdkPortalOutlet, NgIf],
})
export class AccordionComponent {
  @HostBinding('class.suiAccordionOpen')
  @Input()
  open = true;
  @Output()
  toggle = new EventEmitter();

  @ContentChild(AccordionHeaderDirective, { static: true })
  header: AccordionHeaderDirective;
  @ContentChild(AccordionBodyDirective, { static: true })
  body: AccordionBodyDirective;
  @ContentChild(AccordionFooterDirective, { static: true })
  footer: AccordionFooterDirective;
  @ViewChild('scrollTarget', { static: false })
  scrollTarget: ElementRef;

  onToggle($event: any) {
    $event.preventDefault();

    this.toggle.emit();
  }

  onShrinkOut($event: any) {
    if ($event.toState === 'in') {
      const element = this.scrollTarget.nativeElement;

      if (!this.isInViewportVertical(element)) {
        const headerOffset = 148;
        const bodyRectTop = document.body.getBoundingClientRect().top;
        const elementRectTop = element.getBoundingClientRect().top;
        const elementPosition = elementRectTop - bodyRectTop;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }

  isInViewportVertical(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }
}

export const ACCORDION_DIRECTIVES = [
  AccordionHeaderDirective,
  AccordionComponent,
  AccordionBodyDirective,
  AccordionFooterDirective,
];
