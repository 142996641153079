import { DateTime } from 'luxon';
import { groupBy, values } from 'lodash';
import { Dictionary } from '@ngrx/entity';
import {
  createMockOccurrenceViewModel,
  OccurrenceViewModel,
} from './scheduled-event.model';

export interface OccurrenceWarningModel {
  occurrenceDateTime: DateTime;
  impactedZones: { id: string; name: string }[];
  occurrences: OccurrenceViewModel[];
}

export function findOccurrenceWarnings(
  occurrences: OccurrenceViewModel[],
  zonesById: Dictionary<{ id: string; name: string }>,
): OccurrenceWarningModel[] {
  /**
   * Step One:
   *
   * Group each occurrence by its exact datetime to find
   * overlapping
   */
  const occurrencesByDateTime = values(
    groupBy(occurrences, occurrence => occurrence.occurrenceDateTime.valueOf()),
  );

  /**
   * Step Two:
   *
   * Identify groups that have overlapping zones
   */
  return occurrencesByDateTime.flatMap(
    (occurrences: OccurrenceViewModel[]): OccurrenceWarningModel[] => {
      // If there is one occurrence in this group then there must be no overlap.
      if (occurrences.length === 1) {
        return [];
      }

      // Get a large list of all the zone IDs that appear in this grouping,
      // filtering out sceneZones with no zones.
      const occurrenceZoneIds: string[] = occurrences
        .flatMap((occurrence: OccurrenceViewModel) =>
          occurrence.sceneZones
            ? occurrence.sceneZones.filter(Boolean).map(sceneZone => sceneZone.id)
            : [null],
        )
        .filter((zoneId): zoneId is string => typeof zoneId === 'string');

      // Create a list of arrays where each array represents a single time a given
      // zone ID appears in the master list
      const instancesOfZoneId = values(groupBy(occurrenceZoneIds, id => id));

      // Filter out each array that only has one item and then flatten out
      // the list. Result is now a single array of strings where each item
      // is a zone ID that appeared multiple times across this occurrence group.
      const zoneIdsThatAppearMultipleTimes = instancesOfZoneId
        .filter(instances => instances.length > 1)
        .map(([zoneId]) => zoneId);

      // For each duplicate, get the zone's name and each occurrence that
      // impacts it
      const impactedZones = zoneIdsThatAppearMultipleTimes
        .map(zoneId => zonesById[zoneId])
        .filter((zone): zone is { id: string; name: string } => !!zone);

      if (impactedZones.length === 0) {
        return [];
      }

      const [{ occurrenceDateTime }] = occurrences;

      return [
        {
          occurrenceDateTime,
          impactedZones,
          occurrences,
        },
      ];
    },
  );
}

export function createMockOccurrenceWarningModel() {
  return {
    occurrenceDateTime: DateTime.local(),
    impactedZones: [
      {
        id: 'id_builtin_zone_all',
        name: 'All',
      },
    ],
    occurrences: [createMockOccurrenceViewModel()],
  };
}
