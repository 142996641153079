import { createReducer, on } from '@ngrx/store';
import { EquipmentApiActions, EquipmentPageActions } from '@spog-ui/equipment/actions';

export interface Shape {
  isLoading: boolean;
  searchTerm: string;
  error?: any;
}

export const initialState: Shape = {
  isLoading: false,
  searchTerm: '',
};

export const reducer = createReducer(
  initialState,
  on(EquipmentPageActions.enterAction, (state): Shape => {
    return { ...initialState, searchTerm: state.searchTerm };
  }),
  on(EquipmentPageActions.loadInitialModelsFromGQL, (state): Shape => {
    return { ...initialState, isLoading: true };
  }),
  on(EquipmentApiActions.loadInitialModelsSuccessAction, (state): Shape => {
    return { ...state, isLoading: false };
  }),
  on(EquipmentApiActions.loadInitialModelsFailureAction, (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  }),
  on(EquipmentPageActions.searchAction, (state, action) => {
    return {
      ...state,
      searchTerm: action.searchTerm,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectSearchTerm = (state: Shape) => state.searchTerm;
