import { createAction } from '@ngrx/store';
import { OrganizationFormModel } from '@spog-ui/shared/models/organizations';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const enterAction = createAction('[Admin / Add Organization Page] Enter');

export const addOrganizationAction = createAction(
  '[Add Organization] Add Organization',
  (organization: OrganizationFormModel) => ({ organization }),
);

export const loadPageSuccessAction = createAction(
  '[Add Organization] Load Page Success',
  (sites: SiteInternalModel[]) => ({ sites }),
);

export const loadPageFailureAction = createAction(
  '[Add Organization] Load Page Failure',
  (error: string) => ({ error }),
);
