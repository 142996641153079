import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MenuActions } from '@spog-ui/modules/menu-state';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'spog-drawer-organization-menu',
  template: `
    <div class="wrapper">
      <form [formGroup]="selectionForm" (ngSubmit)="onSelectOrganization()">
        <mat-form-field>
          <mat-label>Select Organization</mat-label>
          <mat-select formControlName="org">
            @for (org of organizations; track trackOrgs($index, org)) {
              <mat-option [value]="org.id" [attr.data-org-name]="org.name">
                {{ org.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
    
        <div class="actions">
          <button
            class="cancelButton"
            type="button"
            mat-button
            color="warn"
            (click)="onClose()"
            >
            Cancel
          </button>
          <button
            type="submit"
            [disabled]="selectionForm.invalid"
            mat-stroked-button
            color="accent"
            >
            Manage Organization
          </button>
        </div>
      </form>
    </div>
    `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        padding: 24px;
      }

      .wrapper {
        width: 100%;
      }

      mat-form-field {
        width: 100%;
      }

      .cancelButton {
        margin-right: 8px;
      }
    `,
  ],
})
export class OrganizationMenuComponent {
  organizations: OrganizationInternalModel[];

  constructor(
    private store: Store,
    readonly router: Router,
    @Inject(MAT_DIALOG_DATA) data: { organizations: OrganizationInternalModel[] },
  ) {
    this.organizations = data.organizations;
  }

  selectionForm: UntypedFormGroup = new UntypedFormGroup({
    org: new UntypedFormControl(null, Validators.required),
  });

  trackOrgs(index: number, org: OrganizationInternalModel) {
    return org.id;
  }

  onSelectOrganization() {
    if (this.selectionForm.valid) {
      this.store.dispatch(
        MenuActions.navigateToOrganization(this.selectionForm.value.org, this.router.url),
      );
    }
  }

  onClose() {
    this.store.dispatch(MenuActions.closeOrganizationMenu());
  }
}
