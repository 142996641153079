import { createAction } from '@ngrx/store';
import { FloorPlanModel } from '@spog-ui/shared/models/floor-plans';

export const saveSuccessAction = createAction(
  '[Floor Plan API] Save Success',
  (floorPlan: FloorPlanModel) => {
    return { floorPlan };
  },
);
export const saveFailureAction = createAction(
  '[Floor Plan API] Save Failure',
  (error: any) => {
    return { error };
  },
);
export const removeSuccessAction = createAction('[Floor Plan API] Remove Success');
export const removeFailureAction = createAction(
  '[Floor Plan API] Remove Failure',
  (error: any) => {
    return { error };
  },
);
export const replaceSuccessAction = createAction(
  '[Floor Plan API] Replace Success',
  (floorPlan: FloorPlanModel) => {
    return { floorPlan };
  },
);
export const replaceFailureAction = createAction(
  '[Floor Plan API] Replace Failure',
  (error?: any) => {
    return { error };
  },
);
