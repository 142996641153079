import { DateTime } from 'luxon';
import { createAction, union } from '@ngrx/store';
import { OccurrenceViewModel } from '@spog-ui/shared/models/scheduled-events';

export const enterAction = createAction('[Schedule Page] Enter');
export const leaveAction = createAction('[Schedule Page] Leave');
export const goToNextMonthAction = createAction('[Schedule Page] Go To Next Month');
export const goToPrevMonthAction = createAction('[Schedule Page] Go To Prev Month');
export const goToTodayAction = createAction('[Schedule Page] Go To Today');
export const deleteScheduledEventAction = createAction(
  '[Schedule Page] Delete Scheduled Event',
  (scheduledEventId: string) => {
    return { scheduledEventId };
  },
);
export const deleteScheduledEventCancelAction = createAction(
  '[Schedule Page] Delete Scheduled Event Cancel',
);
export const deleteScheduledEventConfirmAction = createAction(
  '[Schedule Page] Delete Scheduled Event Confirm',
  (scheduledEventId: string) => {
    return { scheduledEventId };
  },
);
export const deleteScheduledEventOccurrenceAction = createAction(
  '[Schedule Page] Delete Scheduled Event Occurrence',
  (occurrence: OccurrenceViewModel) => {
    return { occurrence };
  },
);
export const deleteScheduledEventOccurrenceCancelAction = createAction(
  '[Schedule Page] Delete Scheduled Event Occurrence Cancel',
);
export const deleteScheduledEventOccurrenceConfirmAction = createAction(
  '[Schedule Page] Delete Scheduled Event Occurrence Confirm',
  (occurrence: OccurrenceViewModel) => {
    return { occurrence };
  },
);
export const goToEditScheduledEventPageAction = createAction(
  '[Schedule Page] Go to Edit Scheduled Event Page',
  (occurrence: OccurrenceViewModel) => {
    return { occurrence };
  },
);
export const goToDateAction = createAction(
  '[Schedule Page] Go To Date',
  (date: DateTime) => {
    return { date };
  },
);
export const showDailyScheduledEventsAction = createAction(
  '[Schedule Page] Show Daily Scheduled Events',
);
export const swipeLeftOnCalendarAction = createAction(
  '[Schedule Page] Swipe Left on Calendar',
);
export const swipeRightOnCalendarAction = createAction(
  '[Schedule Page] Swipe Right on Calendar',
);

const all = union({
  enterAction,
  leaveAction,
  goToNextMonthAction,
  goToPrevMonthAction,
  goToTodayAction,
  deleteScheduledEventAction,
  deleteScheduledEventCancelAction,
  deleteScheduledEventConfirmAction,
  deleteScheduledEventOccurrenceAction,
  deleteScheduledEventOccurrenceCancelAction,
  deleteScheduledEventOccurrenceConfirmAction,
  goToEditScheduledEventPageAction,
  goToDateAction,
  showDailyScheduledEventsAction,
  swipeLeftOnCalendarAction,
  swipeRightOnCalendarAction,
});
export type Union = typeof all;
