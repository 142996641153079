import { createReducer, on } from '@ngrx/store';
import {
  LightZonesApiActions,
  LightZonesPageActions,
} from '@spog-ui/modules/light-zones-actions';
import { ZoneControlActions, ZoneControlApiActions } from '@spog-ui/zone-control/actions';

export interface Shape {
  isLoading: boolean;
  searchTerm: string;
  error?: string;
}

export const initialState: Shape = {
  isLoading: true,
  searchTerm: '',
};

export const reducer = createReducer(
  initialState,
  on(LightZonesPageActions.enterAction, (state): Shape => {
    return { ...initialState, searchTerm: state.searchTerm };
  }),
  on(LightZonesPageActions.searchAction, (state, action): Shape => {
    return { ...state, searchTerm: action.searchTerm };
  }),
  on(LightZonesApiActions.loadLightZonesPageModelsSuccessAction, (state): Shape => {
    return { ...state, isLoading: false };
  }),
  on(
    LightZonesApiActions.loadLightZonesPageModelsFailureAction,
    (state, action): Shape => {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectSearchTerm = (state: Shape) => state.searchTerm;
