import { EquipmentInternalModel } from '@spog-ui/shared/models/resource-groups';
import {
  CreateAssetInput,
  DefinedAssetTypeEnum,
  UpdateAssetInput,
  EquipmentModelFragment,
  RunningState,
  DispositionLevel,
  AssetDispositionChangeModelFragment,
} from '@spog-ui/graphql/types';
import { toEnergyConsumptionInternalModelFromGQL } from '@spog-ui/shared/models/energy-consumptions';
import { ResourceGroupType } from '@spog-ui/shared/models/resource-groups';
import { DateTime } from 'luxon';

export type EquipmentGQLModel = EquipmentModelFragment;

export function toEquipmentInternalModelFromGQL(
  gql: EquipmentGQLModel,
): EquipmentInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    type: ResourceGroupType.Asset,
    assetType: gql.type.__typename === 'DefinedAssetType' ? gql.type.type : gql.type.name,
    make: gql.make !== undefined ? gql.make : null,
    model: gql.model !== undefined ? gql.model : null,
    imageUrl: gql.imageUrl !== undefined ? gql.imageUrl : null,
    runningState: gql.runningState,
    dispositionChanges: gql.dispositionChanges,
    disposition: gql.disposition,
    industrialSensorIds: gql.industrialSensors.map(sensor => sensor.id),
    resourceGroupIds: gql.resourceGroups.map(resourceGroup => resourceGroup.id),

    ...(gql.floorPlanLocation
      ? {
          floorPlanId: gql.floorPlanLocation.floorPlan.id,
          floorPlanX: gql.floorPlanLocation.x,
          floorPlanY: gql.floorPlanLocation.y,
        }
      : {}),

    ...(gql.energyConsumption
      ? {
          energyConsumption: toEnergyConsumptionInternalModelFromGQL(
            gql.energyConsumption,
          ),
        }
      : {}),
  };
}

export interface EquipmentFormModel {
  id?: string;
  name: string;
  make: string | null;
  model: string | null;
  imageUrl: string | null;
  image?: string;
  existingTypeValue: string | null;
  newTypeValue: string | null;
  resourceGroupIds: string[];
  industrialSensorIds: string[];
}

/**
 * Converts equipment internal model into a form model
 * @param asset
 */
export function toAssetFormModel(asset: EquipmentInternalModel): EquipmentFormModel {
  return {
    id: asset.id,
    name: asset.name,
    make: asset.make,
    model: asset.model,
    imageUrl: asset.imageUrl,
    existingTypeValue: asset.assetType,
    newTypeValue: null,
    resourceGroupIds: asset.resourceGroupIds,
    industrialSensorIds: asset.industrialSensorIds,
  };
}

/**
 * Converts equipment internal model into a form model
 * @param equipment
 */
export function toEquipmentFormModel(
  equipment: EquipmentInternalModel,
): EquipmentFormModel {
  return {
    id: equipment.id,
    name: equipment.name,
    make: equipment.make,
    model: equipment.model,
    imageUrl: equipment.imageUrl,
    existingTypeValue: equipment.assetType,
    newTypeValue: null,
    resourceGroupIds: equipment.resourceGroupIds,
    industrialSensorIds: equipment.industrialSensorIds,
  };
}

/**
 * Equipment state / status models
 */

export interface EquipmentRunningState {
  state: RunningState;
  timestamp: string;
}

export interface EquipmentDisposition {
  level: DispositionLevel;
  timestamp: string;
}
export interface EquipmentDispositionChange {
  timestamp: string;
  message: string;
  level: DispositionLevel;
}

export interface EquipmentTriggerDispositionChange extends EquipmentDispositionChange {
  triggerId: string;
}

/**
 * Equipment model for profile page
 */
export interface EquipmentProfileViewModel {
  id: string;
  name: string;
  assetType: DefinedAssetTypeEnum | string;
  make: string | null;
  model: string | null;
  parentId?: string;
  resourceGroupIds: string[];
  industrialSensorIds: string[];
  imageUrl: string | null;
  runningState: EquipmentRunningState;
  dispositionChanges: EquipmentTriggerDispositionChange[];
  disposition: EquipmentDisposition;
}

/**
 * Equipment from our WebSocket API
 */
export interface EquipmentWSModel {
  id: string;
  name: string;
  make: string | null;
  model: string | null;
  imageUrl: string | null;
  siteId: string;
  type: DefinedAssetTypeEnum | string;
  resourceGroupIds: string[];
  industrialSensorIds: string[];

  // @TODO: David Pierce - Enable once websocket updates begin returning these fields
  // runningState: AssetRunningStateModel;
  // dispositionChanges: AssetDispositionChangeModel[];
  // disposition: AssetDispositionModel;
}

/**
 * Converts equipment form model into one ready to be used
 * by the GraphQL create asset operation
 */
export function toCreateEquipmentInputFromEquipmentForm(
  form: EquipmentFormModel,
  siteId: string,
): CreateAssetInput {
  const hasDefinedType = isDefinedType(form.existingTypeValue);
  let definedType, customType;

  if (hasDefinedType) {
    definedType = { type: <DefinedAssetTypeEnum>form.existingTypeValue };
  } else {
    customType = { name: <string>form.newTypeValue || <string>form.existingTypeValue };
  }

  return {
    siteId,
    name: form.name,
    make: form.make,
    model: form.model,
    definedType,
    customType,
    industrialSensorIds: form.industrialSensorIds,
    resourceGroupIds: form.resourceGroupIds,
    image: form.image,
  };
}

export function isDefinedType(value: DefinedAssetTypeEnum | string | null): boolean {
  return Object.values(DefinedAssetTypeEnum).some(val => val === value);
}

/**
 * Converts equipment form model into one ready to be used
 * by the GraphQL asset update operation
 */
export function toUpdateEquipmentInputFromEquipmentForm(
  form: EquipmentFormModel,
  id: string,
): UpdateAssetInput {
  const hasDefinedType = isDefinedType(form.existingTypeValue);
  let definedType, customType;

  if (hasDefinedType) {
    definedType = { type: <DefinedAssetTypeEnum>form.existingTypeValue };
  } else {
    customType = { name: <string>form.newTypeValue || <string>form.existingTypeValue };
  }

  const updatedEquipment: UpdateAssetInput = {
    id,
    name: form.name,
    make: form.make,
    model: form.model,
    definedType,
    customType,
    industrialSensorIds: form.industrialSensorIds,
    resourceGroupIds: form.resourceGroupIds,
  };

  if (form.image !== null && form.image !== undefined) {
    updatedEquipment.image = form.image;
  }

  return updatedEquipment;
}

/**
 * Normalizes an equipment model sent to us from our WebSocket API
 * into a model ready to be put in the store
 */
export function toEquipmentInternalModelFromWS(
  ws: EquipmentWSModel,
): EquipmentInternalModel {
  return {
    id: ws.id,
    name: ws.name,
    make: ws.make,
    model: ws.model,
    imageUrl: ws.imageUrl,
    assetType: ws.type,
    resourceGroupIds: ws.resourceGroupIds,
    industrialSensorIds: ws.industrialSensorIds,

    // @TODO: Temp
    runningState: { state: RunningState.UNKNOWN, timestamp: '' },
    dispositionChanges: [],
    disposition: { level: DispositionLevel.UNKNOWN, timestamp: '' },
    // @TODO: Temp

    // @TODO: David Pierce - Enable once websocket updates begin returning these fields
    // runningState: ws.runningState,
    // dispositionChanges: ws.dispositionChanges,
    // disposition: ws.disposition,
  };
}

export interface EquipmentMapViewModel {
  id: string;
  name: string;
  floorPlanX: number;
  floorPlanY: number;
  disposition: DispositionLevel;
  runningState: RunningState;
}

export function toEquipmentMapViewModelFromInternal(
  internal: EquipmentInternalModel,
): EquipmentMapViewModel {
  if (
    typeof internal.floorPlanX !== 'number' ||
    typeof internal.floorPlanY !== 'number'
  ) {
    throw Error(
      'Attempted to view a piece of equipment on the map that has no position.',
    );
  }
  return {
    id: internal.id,
    name: internal.name,
    floorPlanX: internal.floorPlanX,
    floorPlanY: internal.floorPlanY,
    disposition: internal.disposition
      ? internal.disposition.level
      : DispositionLevel.UNKNOWN,
    runningState: internal.runningState
      ? internal.runningState.state
      : RunningState.UNKNOWN,
  };
}

export interface EquipmentTreeNode {
  id: string;
  name: string;
  runningState: RunningState;
  disposition: DispositionLevel;
  children?: EquipmentTreeNode[];
}

export function toEquipmentTreeNodeFromEquipment(
  equipment: EquipmentInternalModel,
): EquipmentTreeNode {
  return {
    id: equipment.id,
    name: equipment.name,
    runningState: equipment.runningState.state,
    disposition: equipment.disposition.level,
  };
}

export function createMockEquipmentTreeNode(
  updates?: Partial<EquipmentTreeNode>,
): EquipmentTreeNode {
  return {
    id: 'shadow-hedgehog',
    name: 'the ultimate lifeform',
    disposition: DispositionLevel.CRITICAL,
    runningState: RunningState.RUNNING,
    ...updates,
  };
}

export function createMockChildEquipmentTreeNodes(): EquipmentTreeNode[] {
  return [
    {
      id: '1',
      name: 'First',
      runningState: RunningState.STOPPED,
      disposition: DispositionLevel.WARNING,
      children: [
        {
          id: '1a',
          name: 'First Child',
          runningState: RunningState.RUNNING,
          disposition: DispositionLevel.NORMAL,
        },
        {
          id: '1b',
          name: 'Second Child',
          runningState: RunningState.STOPPED,
          disposition: DispositionLevel.WARNING,
        },
      ],
    },
    {
      id: '2',
      name: 'Second',
      runningState: RunningState.RUNNING,
      disposition: DispositionLevel.CRITICAL,
    },
  ];
}

export interface EquipmentLegendViewModel {
  normal: number;
  warning: number;
  critical: number;
  info: number;
  unknown: number;
}

export interface EquipmentLegendFilterModel {
  filteringNormal: boolean;
  filteringWarning: boolean;
  filteringCritical: boolean;
  filteringInfo: boolean;
  filteringUnknown: boolean;
}

export function getEquipmentDispositionChangeTimeDiff(
  timeZone: string,
  siteTime: DateTime,
  state?: AssetDispositionChangeModelFragment,
): {
  date: string;
  diff: string;
} {
  if (!state) return { date: '', diff: '' };

  const timestamp = DateTime.fromISO(state.timestamp, { zone: timeZone });
  const { minutes } = timestamp.diff(siteTime, 'minutes');
  let timeDiff = '';

  if (Math.abs(minutes) < 1) {
    timeDiff = 'moments ago';
  } else {
    const relativeDiff = timestamp.toRelative({
      base: siteTime,
    });

    timeDiff = relativeDiff || 'moments ago';
  }

  const readingDateTime = timestamp.toLocaleString(DateTime.DATETIME_FULL);

  return {
    date: readingDateTime,
    diff: timeDiff,
  };
}

export function sortDispositionChangesBySeverity(
  dispositionChanges: AssetDispositionChangeModelFragment[],
): AssetDispositionChangeModelFragment[] {
  const copyOfReadOnlyDispositionChanges = [...dispositionChanges];
  return copyOfReadOnlyDispositionChanges.sort(
    (a: AssetDispositionChangeModelFragment, b: AssetDispositionChangeModelFragment) => {
      const dispositionLevelIndexA = Object.keys(DispositionLevel).indexOf(a.level);
      const dispositionLevelIndexB = Object.keys(DispositionLevel).indexOf(b.level);

      if (dispositionLevelIndexA < dispositionLevelIndexB) {
        return 1;
      } else if (dispositionLevelIndexA > dispositionLevelIndexB) {
        return -1;
      } else {
        return 0;
      }
    },
  );
}
