import { GraphQLFormattedError } from 'graphql';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * TO DO: deprecate this interface after its replaced with ApiErrorRespose model
 * and retrieveErrorMessage function
 **/
export interface ApiErrorModel {
  readonly message: string;
  readonly location: Array<{ line: number; column: number }>;
  readonly path: string[];
  readonly extensions: { code: string; exception: { stacktrace: string[] } };
}

interface ApiSuccessResponse<T> {
  data: T;
}

export interface GraphQLErrorResponse {
  errors: GraphQLFormattedError[];
}

export type ApiErrorResponse = GraphQLErrorResponse | HttpErrorResponse;

export type ApiResponse<T> = ApiSuccessResponse<T> | ApiErrorResponse;

/**
 * Method retrieves error message from API error response
 * @param response
 * @returns the first error message contained in the response
 */
export function retrieveErrorMessage(response: ApiErrorResponse | Error): string {
  if (response instanceof HttpErrorResponse || response instanceof Error) {
    return response.message;
  } else {
    return response.errors[0].message;
  }
}
