import { Component, Directive, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {
  BigSelectOption,
  markAsTouched,
  provideFormErrors,
} from '@spog-ui/shared/components';
import {
  TakenLights,
  TakenPowerSensors,
  UtilityServiceFormModel,
  UtilityServiceInternalModel,
} from '@spog-ui/shared/models/utility-services';
import { LightInternalModel } from '@spog-ui/shared/models/lights';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';

@Component({
  selector: 'spog-utility-service-form',
  template: `
    <form [formGroup]="form" (submit)="onSubmit()">
      <mat-form-field data-form-id="name">
        <mat-label>Name</mat-label>
        <input matInput [formControl]="form.get('name')" />
    
        <mat-error
          data-form-id="nameError"
          [suiFormError]="form.get('name')?.errors"
        ></mat-error>
      </mat-form-field>
    
      <mat-form-field data-form-id="mains">
        <mat-label>Mains</mat-label>
        <mat-select
          multiple
          spogRequiresOneMainValidator
          [spogTakenPowerSensorsValidator]="takenPowerSensors"
          [formControl]="form.get('mainIds')"
          >
          @for (option of indieSensorOptions; track option) {
            <mat-option [value]="option.value">
              {{ option.name }}
            </mat-option>
          }
        </mat-select>
    
        <mat-error [suiFormError]="form.get('mainIds')?.errors"></mat-error>
      </mat-form-field>
    
      <div class="thingsWrapper">
        <sui-big-select title="Things">
          <sui-big-select-list
            title="Power Sensors"
            [options]="indieSensorOptions"
            [spogTakenPowerSensorsValidator]="takenPowerSensors"
            [spogDoublyAssignedSensorsValidator]="form.get('mainIds')?.value"
            [formControl]="form.get('indieSensorIds')"
            >
          </sui-big-select-list>
          <sui-big-select-list
            title="Lights"
            [options]="lightOptions"
            [spogTakenLightsValidator]="takenLights"
            [formControl]="form.get('lightIds')"
            >
          </sui-big-select-list>
        </sui-big-select>
        @if (form.get('indieSensorIds')?.invalid) {
          <mat-error
            [suiFormError]="form.get('indieSensorIds')?.errors"
          ></mat-error>
        }
        @if (form.get('lightIds')?.invalid) {
          <mat-error
            [suiFormError]="form.get('lightIds')?.errors"
          ></mat-error>
        }
      </div>
    
      <div class="actions">
        <a routerLink=".." mat-button> Cancel </a>
        <button type="submit" mat-raised-button color="accent" [disabled]="form.disabled">
          Save Utility Service
        </button>
      </div>
    </form>
    `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        padding: 24px;
      }

      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
      }

      mat-form-field {
        margin-bottom: 32px;
      }

      .thingsWrapper {
        position: relative;
        background-color: var(--color-background-card);
        border-radius: 2px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
        margin-bottom: 52px;
      }

      .thingsWrapper mat-error {
        display: none;
        position: absolute;
        bottom: 0;
        padding: 16px;
        background-color: var(--color-warn-500);
        color: var(--color-warn-contrast-500);
      }

      .thingsWrapper mat-error:first-of-type {
        display: block;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
      }
    `,
  ],
  providers: [provideFormErrors(getFormErrors)],
})
export class UtilityServiceFormComponent {
  @Input() set utilityService(utilityService: UtilityServiceInternalModel) {
    this.form.patchValue({
      id: utilityService.id,
      name: utilityService.name,
      mainIds: utilityService.mainIds,
      indieSensorIds: utilityService.indieSensorsIds,
      lightIds: utilityService.lightIds,
    });
    this.isEditMode = true;
    this.form.controls['mainIds'].disable();
  }
  @Input() set disabled(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();

    // this input may be processed after utilityService, so we
    // may need "re-disable" the mains after the form.enable()
    // 're-enables' it
    if (this.isEditMode) {
      this.form.controls['mainIds'].disable();
    }
  }
  @Input() takenPowerSensors: TakenPowerSensors = {};
  @Input() takenLights: TakenLights = {};
  @Output() save = new EventEmitter<UtilityServiceFormModel>();
  @Input() set indieSensors(indieSensors: IndieSensorInternalModel[]) {
    this.indieSensorOptions = indieSensors.map(indieSensor => {
      return {
        value: indieSensor.id,
        name: indieSensor.name,
        svgIcon: 'spog_power',
      };
    });
  }
  @Input() set lights(lights: LightInternalModel[]) {
    this.lightOptions = lights.map(light => {
      return {
        value: light.id,
        name: light.name,
        icon: 'lightbulb_outline',
      };
    });
  }

  isEditMode = false;
  indieSensorOptions: BigSelectOption[] = [];
  lightOptions: BigSelectOption[] = [];
  form = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    name: new UntypedFormControl('', [Validators.required]),
    mainIds: new UntypedFormControl([]),
    indieSensorIds: new UntypedFormControl([]),
    lightIds: new UntypedFormControl([]),
  });

  onSubmit() {
    markAsTouched(this.form);

    if (this.form.invalid) {
      return;
    }

    this.save.emit(this.form.value);
  }
}

@Directive({
  selector: '[spogRequiresOneMainValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RequiresOneMainValidatorDirective,
    },
  ],
})
export class RequiresOneMainValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const value: string[] = control.value;

    if (value.length === 0) {
      return { spogRequiresOneMain: true };
    }

    return null;
  }
}

@Directive({
  selector: '[spogTakenPowerSensorsValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TakenPowerSensorsValidatorDirective,
    },
  ],
})
export class TakenPowerSensorsValidatorDirective implements Validator {
  private onChange: () => void = () => void 0;
  private takenIds: TakenPowerSensors = {};

  @Input('spogTakenPowerSensorsValidator') set takenPowerSensors(
    value: TakenPowerSensors,
  ) {
    this.takenIds = value;

    this.onChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value: string[] = control.value;
    const invalidPowerSensorIds = value.filter(id => this.takenIds[id]);

    if (invalidPowerSensorIds.length > 0) {
      const invalidPowerSensors = invalidPowerSensorIds.map(id => this.takenIds[id]);

      return { spogPowerSensorsAlreadyTaken: invalidPowerSensors };
    }

    return null;
  }

  registerOnValidatorChange(onChange: () => void) {
    this.onChange = onChange;
  }
}

@Directive({
  selector: '[spogTakenLightsValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TakenLightsValidatorDirective,
    },
  ],
})
export class TakenLightsValidatorDirective implements Validator {
  private onChange: () => void = () => void 0;
  private takenIds: TakenLights = {};

  @Input('spogTakenLightsValidator') set takenLights(value: TakenLights) {
    this.takenIds = value;

    this.onChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value: string[] = control.value;
    const invalidLightIds = value.filter(id => this.takenIds[id]);

    if (invalidLightIds.length > 0) {
      const invalidLights = invalidLightIds.map(id => this.takenIds[id]);

      return { spogLightsAlreadyTaken: invalidLights };
    }

    return null;
  }

  registerOnValidatorChange(onChange: () => void) {
    this.onChange = onChange;
  }
}

@Directive({
  selector: '[spogDoublyAssignedSensorsValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DoublyAssignedSensorsValidatorDirective,
    },
  ],
})
export class DoublyAssignedSensorsValidatorDirective implements Validator {
  private onChange: () => void = () => void 0;
  private assignedIds: Set<string> = new Set();

  @Input('spogDoublyAssignedSensorsValidator') set assignedSensorIds(value: string[]) {
    this.assignedIds = new Set(value);

    this.onChange();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value: string[] = control.value;
    const invalidSensorIds = value.filter(id => this.assignedIds.has(id));

    if (invalidSensorIds.length > 0) {
      return { spogDoublyAssignedSensors: true };
    }

    return null;
  }

  registerOnValidatorChange(onChange: () => void) {
    this.onChange = onChange;
  }
}

export function getFormErrors() {
  return {
    default: {
      required: 'This field is required',
      spogRequiresOneMain:
        "You must select at least one power sensor that measures this utility service's total consumption",
      spogDoublyAssignedSensors:
        'Some of these power sensors are already assigned as mains',
      spogPowerSensorsAlreadyTaken: (indieSensors: IndieSensorInternalModel[]) => {
        const names = indieSensors.map(indieSensor => `"${indieSensor.name}"`).join(', ');

        return `Some of these power sensors have already been configured for other utility services: ${names}`;
      },
      spogLightsAlreadyTaken: (lights: LightInternalModel[]) => {
        const names = lights.map(light => `"${light.name}"`).join(', ');

        return `Some of these lights have already been configured for other utility services: ${names}`;
      },
    },
  };
}
