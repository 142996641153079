import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AlarmTypes } from '@shared/alarms';
import { toPlacedThingsFromBulkFloorPlanPlacementWS } from '@spog-ui/shared/models/floor-plans';
import { toIndieSensorInternalModelFromWS } from '@spog-ui/shared/models/indie-sensors';
import {
  AlarmStatusNotification,
  Bridge485MetricStateChangeNotification,
  BulkFloorPlanPlacementChangeNotification,
  ClimateZoneControlNotification,
  CrudNotification,
  LightControlNotification,
  MessageNotification,
  Notification,
  OpenNotification,
  Sense420StateChangeNotification,
  SocketNotification,
  SocketNotificationTypes,
  ThermostatAlarmStatusNotification,
  TriggerStateChangeNotification,
  UpdateEnergyBaselineNotification,
  VirtualIndustrialSensorStateChangeNotification,
  ZoneControlNotification,
  ActiveSequenceSceneStepNotification,
  ActiveSequenceSceneDoneNotification,
} from '@spog-ui/shared/models/websocket-notifications';
import * as CoreState from '@spog-ui/shared/state/core';
import { SocketActions } from '@spog-ui/socket/actions';
import { combineLatest, defer, EMPTY, Observable, throwError } from 'rxjs';
import {
  bufferTime,
  catchError,
  filter,
  map,
  retryWhen,
  share,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Backoff, SocketConnection } from '../services';
import { selectActiveOrgId } from '@spog-ui/shared/state/organizations';
import { CurrentUserService } from '@spog-ui/current-user/feature';

@Injectable()
export class SocketOptions {
  buffer = 200;
  scheduler = undefined;
}

const isSense420StateChangeNotification = (
  message: SocketNotification<Notification<unknown>>,
): message is MessageNotification<Sense420StateChangeNotification> =>
  message.type === SocketNotificationTypes.Message &&
  message.payload.type === 'Sense420Sensor';

const isVirtualIndustrialSensorStateChangeNotification = (
  message: SocketNotification<Notification<unknown>>,
): message is MessageNotification<VirtualIndustrialSensorStateChangeNotification> =>
  message.type === SocketNotificationTypes.Message &&
  message.payload.type === 'VirtualIndustrialSensor';

const isBridge485MetricStateChangeNotification = (
  message: SocketNotification<Notification<unknown>>,
): message is MessageNotification<Bridge485MetricStateChangeNotification> =>
  message.type === SocketNotificationTypes.Message &&
  message.payload.type === 'Bridge485Metric';

@Injectable()
export class SocketEffects {
  constructor(
    private store: Store,
    private currentUser: CurrentUserService,
    private actions$: Actions,
    private backoff: Backoff,
    private connection: SocketConnection,
    private options: SocketOptions,
    @Inject(DOCUMENT) readonly document: Document,
  ) {}

  socketMessages$ = combineLatest([
    defer(() => this.store.select(CoreState.selectSelectedSiteId)),
    defer(() => this.store.select(selectActiveOrgId)),
    defer(() => this.currentUser.isSuperAdmin$),
    this.actions$.pipe(ofType(SocketActions.socketCloseAction), startWith(null)),
  ]).pipe(
    switchMap(([siteId, orgId, isSuperAdmin, _]) => {
      if (!siteId && !orgId && !isSuperAdmin) return EMPTY;

      return this.connection.pipe(
        catchError(e => {
          return throwError(() => e);
        }),
        retryWhen(errors$ => this.backoff.viaFibonacci(errors$, 50, 2000)),
      );
    }),
    share(),
  );

  socketClose$ = createEffect(() =>
    this.connection.pipe(
      filter(
        socketNotification => socketNotification.type === SocketNotificationTypes.Close,
      ),
      map(() => SocketActions.socketCloseAction()),
    ),
  );

  isSocketOpen$: Observable<boolean> = this.socketMessages$.pipe(
    filter(
      (message): message is OpenNotification =>
        message.type !== SocketNotificationTypes.Message,
    ),
    map(message => message.type === SocketNotificationTypes.Open),
    startWith(false),
  );

  updateEntities$ = createEffect(() =>
    this.socketMessages$.pipe(
      filter(
        (message): message is MessageNotification<CrudNotification<unknown>> =>
          message.type === SocketNotificationTypes.Message &&
          (message.payload.type === 'delete' ||
            message.payload.type === 'upsert' ||
            message.payload.type === 'adminDelete' ||
            message.payload.type === 'adminUpsert' ||
            message.payload.type === 'orgAdminDelete' ||
            message.payload.type === 'orgAdminUpsert'),
      ),
      bufferTime(this.options.buffer, this.options.scheduler),
      withLatestFrom(this.isSocketOpen$),
      filter(([messages, isSocketOpen]) => messages.length > 0 && isSocketOpen),
      map(([messages]: [MessageNotification<CrudNotification<unknown>>[], unknown]) =>
        SocketActions.updateEntitiesAction(messages.map(message => message.payload)),
      ),
    ),
  );

  updateEnergyBaseline$ = createEffect(() =>
    this.socketMessages$.pipe(
      filter(
        (message): message is MessageNotification<UpdateEnergyBaselineNotification> =>
          message.type === SocketNotificationTypes.Message &&
          message.payload.type === 'update-energy-baseline',
      ),
      map(message =>
        SocketActions.updateEnergyBaselineAction(
          message.payload.baseline,
          message.payload.baselineType,
        ),
      ),
    ),
  );

  lightControl$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (message): message is MessageNotification<LightControlNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'lightControl',
        ),
        map(message =>
          SocketActions.lightControlAction(
            message.payload.lightId,
            message.payload.intendedLightLevel,
          ),
        ),
      ),
  );

  lightZoneControl$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (message): message is MessageNotification<ZoneControlNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'zoneControl',
        ),
        map(message =>
          SocketActions.zoneControlAction(
            message.payload.zoneId,
            message.payload.intendedLightLevel,
          ),
        ),
      ),
  );

  climateZoneControl$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (message): message is MessageNotification<ClimateZoneControlNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'climateZoneControl',
        ),
        map(message =>
          SocketActions.climateZoneControlAction(
            message.payload.zoneId,
            message.payload.controlMode,
          ),
        ),
      ),
  );

  updateAlarmStatus$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (message): message is MessageNotification<AlarmStatusNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'alarmStatus',
        ),
        map(message => {
          return SocketActions.updateAlarmsAction({
            type: message.payload.alarmType as AlarmTypes,
            id: message.payload.alarmId,
            siteControllerId: message.payload.gatewayId,
            description: message.payload.alarmDescription,
            triggerTime: message.payload.alarmTriggerTime,
            controllerId: message.payload.controllerId,
            snapaddr: message.payload.snapaddr,
            cleared: message.payload.alarmCleared,
          });
        }),
      ),
  );

  deleteAlarm$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (message): message is MessageNotification<AlarmStatusNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'alarmDelete',
        ),
        map(message => {
          return SocketActions.deleteAlarmAction(message.payload.alarmId);
        }),
      ),
  );

  updateActiveSequenceScene$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (
            message,
          ): message is MessageNotification<ActiveSequenceSceneStepNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'activeSequenceSceneStep',
        ),
        map(message => {
          return SocketActions.updateActiveSequenceSceneAction({
            id: message.payload.sequenceSceneId,
            currentStep: message.payload.currentStep,
            currentStepCompletionTime: message.payload.currentStepCompletionTime,
          });
        }),
      ),
  );

  deleteActiveSequenceScene$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (
            message,
          ): message is MessageNotification<ActiveSequenceSceneDoneNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'activeSequenceSceneDone',
        ),
        map(message => {
          return SocketActions.deleteActiveSequenceSceneAction(
            message.payload.sequenceSceneId,
          );
        }),
      ),
  );

  sense420StateChange$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(isSense420StateChangeNotification),
        map(message => {
          const sensor = toIndieSensorInternalModelFromWS(message.payload.sensor);
          return SocketActions.sense420StateChangeAction(sensor);
        }),
      ),
  );

  virtualIndustrialSensorStateChange$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(isVirtualIndustrialSensorStateChangeNotification),
        map(message => {
          const sensor = toIndieSensorInternalModelFromWS(message.payload.sensor);
          return SocketActions.virtualIndustrialSensorStateChangeAction(sensor);
        }),
      ),
  );

  bridge485MetricStateChange$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(isBridge485MetricStateChangeNotification),
        map(message => {
          const sensor = toIndieSensorInternalModelFromWS(message.payload.sensor);
          return SocketActions.bridge485MetricStateChangeAction(sensor);
        }),
      ),
  );

  triggerStateChange$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (message): message is MessageNotification<TriggerStateChangeNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'triggerStateChange',
        ),
        map(message => {
          return SocketActions.triggerStateChangeAction(message.payload);
        }),
      ),
  );

  bulkFloorPlanPlacementChange$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (
            message,
          ): message is MessageNotification<BulkFloorPlanPlacementChangeNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'bulkFloorPlanPlacement',
        ),
        map(message => {
          const placedThings = toPlacedThingsFromBulkFloorPlanPlacementWS(
            message.payload,
          );
          return SocketActions.bulkFloorPlanPlacementAction(placedThings);
        }),
      ),
  );

  thermostatAlarmStatusChange$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (message): message is MessageNotification<ThermostatAlarmStatusNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'thermostatAlarmStatus',
        ),
        map(message => {
          return SocketActions.thermostatAlarmStatusAction({
            siteControllerId: message.payload.siteControllerId,
            id: message.payload.alarmId,
            triggerAt: message.payload.triggerAt,
            reason: message.payload.reason,
            thermostatId: message.payload.thermostatId,
            alarmCleared: message.payload.alarmCleared,
          });
        }),
      ),
  );

  deleteThermostatAlarm$ = createEffect(
    () => () =>
      this.socketMessages$.pipe(
        filter(
          (message): message is MessageNotification<ThermostatAlarmStatusNotification> =>
            message.type === SocketNotificationTypes.Message &&
            message.payload.type === 'thermostatAlarmDelete',
        ),
        map(message => {
          return SocketActions.deleteThermostatAlarmAction(message.payload.alarmId);
        }),
      ),
  );
}
