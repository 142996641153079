import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, switchMap, filter, withLatestFrom, map } from 'rxjs/operators';
import { SiteGuardActions } from '@spog-ui/shared/state/site/site-actions';
import * as CoreState from '@spog-ui/shared/state/core';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';
import { AuthGuard } from '@auth0/auth0-angular';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class SiteGuardService implements CanActivate {
  constructor(
    private authGuard: AuthGuard,
    private store: Store,
    private router: Router,
    private actions: Actions,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.authGuard.canActivate(route, state).pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          // Not this guard's problem
          return of(true);
        } else {
          return this.checkForSiteId(route.params['activeSiteId']);
        }
      }),
    );
  }

  checkForSiteId(siteId: string): Observable<boolean | UrlTree> {
    return this.store.select(CoreState.selectSitesLoading).pipe(
      filter(isLoading => !isLoading),
      withLatestFrom(this.store.select(CoreState.selectSiteEntities)),
      switchMap(([, sites]) => {
        const siteFromUrlId = sites[siteId];

        // If the URL's siteId is not present or doesn't match a site this user
        // is allowed to access, send them to site selection
        if (siteFromUrlId === undefined) {
          this.store.dispatch(SiteGuardActions.invalidatedSiteAction());
          // Why the actions stream? See note at bottom of file
          return this.actions.pipe(
            ofType(SiteGuardActions.invalidatedSiteAction),
            take(1),
            map(() => {
              return this.router.parseUrl('/user/select-site');
            }),
          );
        }

        return this.checkForSiteIdChangeViaURL(siteFromUrlId);
      }),
    );
  }

  checkForSiteIdChangeViaURL(
    siteFromUrlId: SiteInternalModel,
  ): Observable<boolean | UrlTree> {
    // If the URL had a valid siteId and it's different than selected site's,
    // let the app know
    return this.store.select(CoreState.selectActiveSiteIdDiffersFromSelectedSite).pipe(
      take(1),
      switchMap(isDifferent => {
        if (isDifferent) {
          this.store.dispatch(SiteGuardActions.changedSiteAction(siteFromUrlId));
          this.store.dispatch(SiteGuardActions.validatedSiteAction());

          // Why the actions stream? See note at bottom of file
          return this.actions.pipe(
            ofType(SiteGuardActions.changedSiteAction),
            take(1),
            map(() => true),
          );
        }

        // Let them through
        this.store.dispatch(SiteGuardActions.validatedSiteAction());
        return of(true);
      }),
    );
  }
}

/**
 * Note re: listening to the ngrx Actions stream within a CanActivate guard
 *
 * It initially might seem that we are mixing concerns here, but there is a reason this was
 * put in: ngrx `dispatch` calls are scheduled instead of synchronous in the case where
 * we are subscribing to a state change and then subsequently dispatching an action.
 *
 * This is there to ensure that the dipatched actions don't then go and loop back to that state change.
 *
 * In our case, this guard needs to return an observable but we have multiple dispatch calls to
 * set up the next route. As a fix, we've used the Actions stream to let us know that the
 * dispatched actions have gone through the reducers before we return.
 *
 * Had we not done this, the guard would return prior to the dispatch call getting fired since
 * it is scheduled. It will now wait until that dispatch call has gone out before returning the value.
 */
