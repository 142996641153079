import { createReducer, on } from '@ngrx/store';
import {
  IndieSensorDetailsAPIActions,
  IndieSensorDetailsPageActions,
} from '@spog-ui/indie-sensor-details-actions';

export interface Shape {
  error?: string;
  isLoading: boolean;
}

export const initialState: Shape = {
  isLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(IndieSensorDetailsPageActions.enterAction, () => {
    return { ...initialState };
  }),
  on(
    IndieSensorDetailsAPIActions.loadIndieSensorDetailsPageModelsSuccessAction,
    state => {
      return { ...state, isLoading: false };
    },
  ),
  on(
    IndieSensorDetailsAPIActions.loadIndieSensorDetailsPageModelsFailureAction,
    (state, { error }) => {
      return { ...state, error, isLoading: false };
    },
  ),
);

export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.isLoading;
