import { Component } from '@angular/core';

@Component({
  selector: 'spog-alarming-badge',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        background-color: var(--color-warn-500);
        color: var(--color-warn-contrast-500);
        border-radius: 2px;
        padding: 0px 7px;
        right: 8px;
        height: 22px;
        line-height: 22px;
        vertical-align: middle;
        font-size: 12px;
        font-weight: bold;
        top: 50%;
      }
    `,
  ],
})
export class AlarmingBadgeComponent {}
