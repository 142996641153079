import { createAction } from '@ngrx/store';

export const confirmAction = createAction('[Remove Floor Plan] Confirm');
export const cancelAction = createAction('[Remove Floor Plan] Cancel');

export type Union = ReturnType<typeof confirmAction> | ReturnType<typeof cancelAction>;

export const Types = {
  Confirm: confirmAction.type,
  Cancel: cancelAction.type,
};
