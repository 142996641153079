import { createAction } from '@ngrx/store';

export const checkForUpdateSuccess = createAction(
  '[App Updates / SW] Check for Update Success',
);

export const checkForUpdateFailure = createAction(
  '[App Updates / SW] Check for Update Failure',
  (error: any) => ({ error }),
);

export const updateAvailable = createAction('[App Updates / SW] Update Available');

export const downloadUpdateSuccess = createAction(
  '[App Updates / SW] Download Update Success',
);

export const downloadUpdateFailure = createAction(
  '[App Updates / SW] Download Update Failure',
  (error: any) => ({ error }),
);

export const updateSuccess = createAction('[App Updates / SW] Update Success');
