import { createReducer, on } from '@ngrx/store';
import {
  EditEquipmentApiActions,
  EditEquipmentPageActions,
} from '@spog-ui/equipment/actions';

export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  loadingError?: any;
  equipmentId: string | null;
}

export const initialState: Shape = {
  isLoading: false,
  isSubmitting: false,
  equipmentId: null,
};

export const reducer = createReducer(
  initialState,
  on(EditEquipmentPageActions.enterAction, (state, action): Shape => {
    return {
      ...initialState,
      equipmentId: action.equipmentId,
    };
  }),
  on(EditEquipmentPageActions.loadPageModelsFromGQL, (state): Shape => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(EditEquipmentApiActions.loadFormDataSuccessAction, (state): Shape => {
    return { ...state, isLoading: false, isSubmitting: false };
  }),
  on(EditEquipmentApiActions.loadFormDataFailureAction, (state, action): Shape => {
    return {
      ...state,
      isLoading: false,
      isSubmitting: false,
      loadingError: action.error,
    };
  }),
  on(EditEquipmentPageActions.editEquipmentSubmitAction, (state): Shape => {
    return { ...state, isLoading: false, isSubmitting: true };
  }),
  on(EditEquipmentApiActions.editEquipmentSuccess, (state): Shape => {
    return { ...state, isLoading: false, isSubmitting: false };
  }),
  on(EditEquipmentApiActions.editEquipmentFailure, (state): Shape => {
    return {
      ...state,
      isLoading: false,
      isSubmitting: false,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
export const selectLoadingError = (state: Shape) => state.loadingError;
export const selectActiveId = (state: Shape) => state.equipmentId;
