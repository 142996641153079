import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { interval, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { SharedClock } from '@spog-ui/shared/effects/actions';

@Injectable()
export class SharedClockEffects {
  tick$ = createEffect(() => () =>
    merge(of(true), interval(/* Every minute */ 60 * 1000)).pipe(
      map(() => SharedClock.tickAction(DateTime.local().toISO())),
    ),
  );
}
