import { filter, map, startWith, tap } from 'rxjs/operators';
import { defer, Observable, Subscription } from 'rxjs';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BehaviorInternalModel, BehaviorType } from '@spog-ui/shared/models/behaviors';
import {
  ZoneInternalModel,
  ZoneApplication,
  ZoneBehaviorFormModel,
} from '@spog-ui/shared/models/zones';

@Component({
  selector: 'scn-zone-behavior-control',
  template: `
    <div class="suiZoneBehaviorControlExpander"></div>
    <sui-card (click)="onExpand($event)" #card>
      <sui-card-header [ngClass]="{ open }" (click)="onToggle($event)">
        @if (open) {
          <h5 class="mat-subheading-2">
            Configure
            {{ application === eApplication.CLIMATE ? 'Control Mode' : 'Behavior' }}
          </h5>
        }
        <i class="material-icons">keyboard_arrow_down</i>
      </sui-card-header>
      <sui-card-body>
        @if (open) {
          <sui-behavior-control-container
            [optionsLabel]="
              application === eApplication.CLIMATE
                ? 'Control Mode Options'
                : 'Behavior Options'
            "
            >
            <sui-behavior-select
              [placeholder]="
                application === eApplication.CLIMATE ? 'Control Mode' : 'Behavior'
              "
              [behaviors]="behaviors"
              [formControl]="formGroup.get('behaviorId')"
              >
            </sui-behavior-select>
            @if (formGroup.get('behaviorId')?.errors?.license_required) {
              <sui-behavior-license-message
              ></sui-behavior-license-message>
            }
            <sui-behavior-form
              [behaviorId]="behaviorId$ | async"
              [formControl]="formGroup.get('behaviorParameters')"
              >
            </sui-behavior-form>
          </sui-behavior-control-container>
          <scn-zone-multiselect
            [zones]="zones"
            [formControl]="formGroup.get('zones')"
          ></scn-zone-multiselect>
        } @else {
          <div class="suiZoneBehaviorType">
            <h6 class="mat-subtitle-1">
              {{
              application === eApplication.CLIMATE
              ? 'Control Mode Type'
              : 'Behavior Type'
              }}
            </h6>
            <p class="mat-body-1 suiZoneBehaviorDescription">
              {{ formGroup?.value?.behaviorId | suiRemoveBehaviorIDToken }}
            </p>
          </div>
          <h6 class="mat-subtitle-1">Control Zones</h6>
          <p class="mat-body-1 suiZoneBehaviorDescription">
            <scn-zone-list [zones]="formGroup?.value?.zones"> </scn-zone-list>
          </p>
        }
      </sui-card-body>
      @if (open) {
        <sui-card-footer>
          <button
            type="button"
            suiButton
            dense
            color="primary"
            (click)="onClose($event)"
            [disabled]="formGroup.invalid"
            >
            Done
          </button>
          <button type="button" suiButton dense (click)="onRemove($event)">Remove</button>
        </sui-card-footer>
      }
    </sui-card>
    <div class="suiZoneBehaviorControlExpander"></div>
    `,
  styles: [
    `
      :host {
        display: block;
        position: relative;
        cursor: pointer;
      }

      sui-card {
        border-radius: 0;
      }

      sui-card-header {
        cursor: pointer;
        position: relative;
      }

      sui-card-header:not(.open) {
        padding: 0;
      }

      sui-card-header h3 {
        margin-top: 0;
      }

      sui-card-header i {
        position: absolute;
        top: 18px;
        right: 14px;
        transition: transform 150ms;
      }

      sui-card-body {
        padding-top: 12px;
      }

      scn-zone-multiselect {
        padding: 24px 0 0;
      }

      sui-behavior-control-container {
        padding: 16px 0;
      }

      .suiZoneBehaviorControlCollapsed {
        padding: 8px;
        cursor: pointer;
      }

      .suiZoneBehaviorControlExpander {
        display: block;
        width: 100%;
        height: 0px;
        transition: height 150ms;
      }

      h5 {
        margin: 0 0 8px;
      }

      h6 {
        margin: 0;
      }

      .suiZoneBehaviorType {
        display: block;
        margin-bottom: 12px;
      }

      .suiZoneBehaviorDescription {
        opacity: 0.67;
        padding: 0;
        margin: 0;
      }

      :host(.expanded) {
        cursor: default;
      }

      :host(.expanded) .suiZoneBehaviorControlExpander {
        height: 12px;
      }

      :host(.expanded) sui-card-header i {
        transform: rotate(-180deg);
      }
    `,
  ],
  animations: [
    trigger('expand', [
      transition('open <=> close', [
        query('sui-card-body', animate(150, style({ height: '*' }))),
      ]),
      transition('open => void', [
        animate(
          150,
          style({ transform: 'translate3d(0, -100px, 0)', opacity: 0, height: 0 }),
        ),
      ]),
      transition('void => open', [
        style({ transform: 'translate3d(0, 100px, 0)', opacity: 0 }),
        animate(150, style({ transform: 'translate3d(0, 0, 0)', opacity: 1 })),
      ]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZoneBehaviorControlComponent,
    },
  ],
})
export class ZoneBehaviorControlComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy, OnInit
{
  eApplication = ZoneApplication;

  @Input() application: ZoneApplication = ZoneApplication.LIGHTING;
  @HostBinding('class.expanded') @Input() open = false;
  @Input() zones: ZoneInternalModel[] = [];
  @Input() behaviors: BehaviorInternalModel[] = [];
  @Output() remove = new EventEmitter();
  @Output() expand = new EventEmitter();
  @Output() closed = new EventEmitter();
  @ViewChild('card', { read: ElementRef, static: true }) card: ElementRef;
  formGroup = createZoneBehaviorControl();
  behaviorId$: Observable<BehaviorType> = defer(() =>
    this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      map((value: ZoneBehaviorFormModel) => value.behaviorId),
      filter(value => Boolean(value)),
    ),
  );
  isBehaviorLicensed$ = this.behaviorId$.pipe(
    map(value => {
      return !this.behaviors.find(b => b.id === value)?.isLicensed;
    }),
    tap(unlicensed => {
      this.formGroup
        .get('behaviorId')
        ?.setErrors(unlicensed ? { license_required: true } : null);
    }),
  );
  onChangeSubscription: Subscription;
  onTouchedSubscription: Subscription;
  errorAnimationPlayer: AnimationPlayer;

  private subscriptions = new Subscription();

  constructor(private animationBuilder: AnimationBuilder) {}

  ngAfterViewInit() {
    this.errorAnimationPlayer = this.animationBuilder
      .build([
        style({ transform: 'translate3d(0, 0, 0)' }),
        animate(50, style({ transform: 'translate3d(-10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(-10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(-10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(0, 0, 0)' })),
      ])
      .create(this.card.nativeElement);
  }

  ngOnInit(): void {
    this.subscriptions = this.isBehaviorLicensed$.subscribe();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  writeValue(value: ZoneBehaviorFormModel): void {
    this.formGroup.setValue(value, { onlySelf: true, emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this.onChangeSubscription = this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedSubscription = this.formGroup.statusChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  onExpand($event: Event) {
    $event.stopImmediatePropagation();

    if (!this.open) {
      this.expand.emit();
    }
  }

  onClose($event: Event) {
    $event.stopImmediatePropagation();

    if (this.formGroup.valid) {
      this.closed.emit();
    } else {
      this.errorAnimationPlayer.play();
    }
  }

  onToggle($event: Event) {
    if (this.open) {
      this.onClose($event);
    } else {
      this.onExpand($event);
    }
  }

  onRemove($event: Event) {
    $event.stopImmediatePropagation();

    this.remove.emit();
  }

  @HostBinding('@expand')
  get expandState() {
    return this.open ? 'open' : 'close';
  }
}

export function createZoneBehaviorControl(
  model: ZoneBehaviorFormModel = {
    zones: [],
    behaviorId: BehaviorType.None,
    behaviorParameters: null,
  },
) {
  return new UntypedFormGroup({
    zones: new UntypedFormControl(model.zones),
    behaviorId: new UntypedFormControl(model.behaviorId),
    behaviorParameters: new UntypedFormControl(model.behaviorParameters),
  });
}
