import { createSelector } from '@ngrx/store';
import {
  STATE_KEY,
  selectIsLoading,
  selectError,
  selectIsSubmitting,
  selectActiveId,
} from './edit-organization-user-page.reducer';
import { selectFeature } from './constants';
import { toOrganizationUserFormModelFromInternal } from '@spog-ui/shared/models/organization-users';
import { selectAllOrgAdminUsers } from './organization-users.selectors';

export const selectEditUserPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsEditUserPageLoading = createSelector(
  selectEditUserPageFeatureState,
  selectIsLoading,
);

export const selectEditUserPageError = createSelector(
  selectEditUserPageFeatureState,
  selectError,
);

export const selectIsEditUserPageSubmitting = createSelector(
  selectEditUserPageFeatureState,
  selectIsSubmitting,
);

export const selectUserIdForEdit = createSelector(
  selectEditUserPageFeatureState,
  selectActiveId,
);

export const selectUserForEdit = createSelector(
  selectAllOrgAdminUsers,
  selectUserIdForEdit,
  (Users, id) => {
    const UserToEdit = Users.find(e => e.id === id);
    return UserToEdit ? toOrganizationUserFormModelFromInternal(UserToEdit) : null;
  },
);
