import { createReducer, on } from '@ngrx/store';
import { EquipmentMapLayerActions } from '@spog-ui/equipment-map-layer/actions';

export interface Shape {
  searchTerm: string;
}

export const initialState: Shape = { searchTerm: '' };

export const reducer = createReducer(
  initialState,

  on(EquipmentMapLayerActions.filterAction, (state, action) => ({
    ...state,
    searchTerm: action.searchTerm,
  })),
);

export const selectSearchTerm = (state: Shape) => state.searchTerm;
