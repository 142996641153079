import { map } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { Subscription } from 'rxjs';

@Component({
  selector: 'scn-zone-multiselect',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZoneMultiselectComponent,
    },
  ],
  template: `
    <mat-form-field>
      <mat-label>Control Zones</mat-label>
      <mat-select
        [formControl]="control"
        [disableOptionCentering]="true"
        (onClose)="onTouch()"
        name="zone"
        multiple="true"
      >
        <!--
        @todo Re-enable once SS-21086 lands
        <ngx-mat-select-search
          placeholderLabel="Search Zones"
          noEntriesFoundLabel="No Zones found"
          [formControl]="filterControl"
          >
        </ngx-mat-select-search> -->
        @for (zone of filteredZoneList; track getZoneId($index, zone)) {
        <mat-option [value]="zone.id">
          {{ zone.name }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  `,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      scn-zone-multiselect {
        display: block;
        width: 100%;
      }

      scn-zone-multiselect mat-form-field {
        width: 100%;
      }

      /**
       * The author of NgxMatSearchSelect chose some styles. They
       * weren't good styles. This overrides them.
       */
      .cdk-overlay-pane-select-search {
        transform: initial !important;
      }

      .mat-select-search-panel {
        min-width: 100% !important;
      }

      .mat-select-search-panel .mat-select-search-input {
        width: calc(100% - 64px) !important;
      }

      .mat-select-search-panel .mat-select-search-inner {
        width: 100% !important;
      }
    `,
  ],
})
export class ZoneMultiselectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input()
  set zones(zones: ZoneInternalModel[]) {
    this.zoneList = zones;
    this.zoneDictionary = zones.reduce((dictionary: ZoneDictionary, zone) => {
      dictionary[zone.id] = zone;

      return dictionary;
    }, {});

    this.filterZones();
  }

  get zones() {
    return this.zoneList;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void;
  onTouched: () => void;
  disabled = false;
  control = new UntypedFormControl([]);
  filterControl = new UntypedFormControl('');
  zoneList: ZoneInternalModel[] = [];
  filteredZoneList: ZoneInternalModel[] = [];
  zoneDictionary: ZoneDictionary = {};
  filterSubscription: Subscription;

  constructor() {
    this.control.valueChanges
      .pipe(
        map((values: string[]) =>
          values.map(id => this.zoneDictionary[id]).filter(Boolean),
        ),
      )
      .subscribe(value => {
        if (this.onChange) {
          this.onChange(value);
        }
      });
  }

  onTouch() {
    this.onTouched();
  }

  writeValue(value: ZoneInternalModel[]) {
    this.control.setValue(
      value.map(zone => zone.id),
      {
        onlySelf: true,
        emitEvent: false,
      },
    );
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnInit(): void {
    this.filterSubscription = this.filterControl.valueChanges.subscribe(() =>
      this.filterZones(),
    );
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  filterZones() {
    const searchTerm: string = this.filterControl.value;

    this.filteredZoneList = this.zoneList.filter(zone =>
      zone.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  getZoneId($index: any, zone: ZoneInternalModel) {
    return zone.id;
  }
}

interface ZoneDictionary {
  [id: string]: ZoneInternalModel;
}
