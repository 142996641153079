import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  ButtonComponent,
  SuiCardModule,
  SuiFormsModule,
} from '@spog-ui/shared/components';
import { ZoneBehaviorControlListComponentModule } from '../zone-behavior-control-list';
import { ZoneBehaviorControlComponentModule } from '../zone-behavior-control';
import { ZoneBehaviorErrorsComponentModule } from '../zone-behavior-errors';
import { ValidateSceneNameDirective } from './validate-scene-name.directive';
import { SceneFormComponent } from './scene-form.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SuiCardModule,
    SuiFormsModule,
    ButtonComponent,
    ZoneBehaviorControlComponentModule,
    ZoneBehaviorControlListComponentModule,
    ZoneBehaviorErrorsComponentModule,
  ],
  declarations: [ValidateSceneNameDirective, SceneFormComponent],
  exports: [SceneFormComponent],
})
export class SceneFormComponentModule {}
