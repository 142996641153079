import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {
  EditUserApiActions,
  SiteApiActions,
  SitesPageApiActions,
  UserApiActions,
} from '@spog-ui/admin/actions';
import { createReducer, on } from '@ngrx/store';
import { SiteControllerApiActions } from '@spog-ui/shared/effects/actions';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';
import * as RemoteAccessActions from '../actions/remote-access.actions';
import { SiteDetailsApiActions } from '@spog-ui/site-details/actions';

export type Shape = EntityState<SiteInternalModel>;

export const adapter = createEntityAdapter({
  selectId: (model: SiteInternalModel) => model.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    SitesPageApiActions.loadSuccessAction,
    UserApiActions.loadModelsSuccessAction,
    EditUserApiActions.loadModelsSuccessAction,
    RemoteAccessActions.loadAdminSitesSuccessAction,
    (state, action) => {
      return adapter.setAll(action.sites, state);
    },
  ),
  on(
    SiteControllerApiActions.loadAllAdminSuccessAction,
    SiteApiActions.loadModelsSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.sites, state);
    },
  ),
  on(SiteApiActions.loadModelsForEditSuccessAction, (state, action) => {
    return adapter.upsertOne(action.models.site, state);
  }),
  on(SiteControllerApiActions.loadModelsSuccessAction, (state, action) => {
    return adapter.setAll(action.models.sites, state);
  }),
  on(SiteDetailsApiActions.deleteSuccessAction, (state, action) => {
    return adapter.removeOne(action.siteId, state);
  }),
);

export const { selectEntities, selectIds, selectAll } = adapter.getSelectors();
