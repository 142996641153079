import { createReducer, on } from '@ngrx/store';
import { AdminOrgShellPageActions, AdminOrgShellApiActions } from '../actions';
export interface Shape {
  isLoading: boolean;
  error?: string;
}

export const STATE_KEY = 'admin-organization-shell-page';

export const initialState: Shape = {
  isLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(AdminOrgShellPageActions.enterAction, (): Shape => {
    return initialState;
  }),
  on(AdminOrgShellApiActions.loadPageSuccessAction, (state): Shape => {
    return { ...state, isLoading: false };
  }),
  on(AdminOrgShellApiActions.loadPageFailureAction, (state, action): Shape => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
