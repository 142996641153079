import { NgModule } from '@angular/core';
import { MainNavDrawerComponentModule } from './components/main-nav-drawer';
import { EffectsModule } from '@ngrx/effects';
import { MenuEffects } from './effects';

@NgModule({
  imports: [EffectsModule.forFeature([MenuEffects])],
  exports: [MainNavDrawerComponentModule],
  providers: [],
})
export class MenuModule {}

export * from './components/admin-link';
