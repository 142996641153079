import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoneBehaviorErrorsComponent } from './zone-behavior-errors.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ZoneBehaviorErrorsComponent],
  exports: [ZoneBehaviorErrorsComponent],
})
export class ZoneBehaviorErrorsComponentModule {}
