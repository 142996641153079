import { createReducer, on } from '@ngrx/store';
import { ScenesPageActions } from '@spog-ui/scenes/actions';
import { SceneStates } from '@spog-ui/shared/models/scenes';
import { SharedSceneApiActions } from '@spog-ui/shared/effects/actions';
import { NearbyResultsActions } from '@spog-ui/light-map-layer/actions';

export interface Shape {
  [sceneId: string]: SceneStates;
}

export const initialState: Shape = {};

export const reducer = createReducer(
  initialState,
  on(
    NearbyResultsActions.applySceneAction,
    ScenesPageActions.applySceneAction,
    (state, action): Shape => {
      return {
        ...state,
        [action.scene.id]: 'pending',
      };
    },
  ),
  on(SharedSceneApiActions.applySceneSuccessAction, (state, action): Shape => {
    return {
      ...state,
      [action.sceneId]: 'success',
    };
  }),
  on(SharedSceneApiActions.applySceneFailureAction, (state, action): Shape => {
    return {
      ...state,
      [action.scene.id]: 'error',
    };
  }),
  on(SharedSceneApiActions.readySceneAction, (state, action): Shape => {
    return {
      ...state,
      [action.sceneId]: 'ready',
    };
  }),
);
