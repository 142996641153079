import { createAction, union, props } from '@ngrx/store';

export const enterAction = createAction('[Alarms Page] Enter Alarms Page');

export const leaveAction = createAction('[Alarms Page] Leave Alarms Page');

export const filterAction = createAction('[Alarms Page] Filter', (searchTerm: string) => {
  return { searchTerm };
});

export const retryAction = createAction(
  '[Alarms Page] Retry Alarm',
  props<{ snapaddr: string }>(),
);

export const clearAction = createAction(
  '[Alarms Page] Clear Alarm',
  props<{ alarmId: string }>(),
);

export const confirmDeleteAction = createAction(
  '[Alarms Page] Confirm Delete Alarm',
  (alarmId: string) => {
    return { alarmId };
  },
);

export const cancelDeleteAction = createAction('[Alarms Page] Cancel Delete Alarm');

export const deleteAction = createAction(
  '[Alarms Page] Delete Alarm',
  props<{ alarmId: string }>(),
);

export const confirmRetryAllAction = createAction(
  '[Alarms Page] Confirm Retry All Alarms',
);

export const cancelRetryAllAction = createAction('[Alarms Page] Cancel Retry All Alarms');

export const retryAllAction = createAction('[Alarms Page] Retry All Alarms');

export const exportAction = createAction('[Alarms Page] Export Alarms');

export const remotelyAccessAction = createAction(
  '[Alarms Page] Remotely Access',
  props<{ siteControllerId: string }>(),
);

const actions = union({
  enterAction,
  leaveAction,
  filterAction,
  retryAction,
  clearAction,
  confirmDeleteAction,
  cancelDeleteAction,
  deleteAction,
  confirmRetryAllAction,
  cancelRetryAllAction,
  retryAllAction,
  exportAction,
  remotelyAccessAction,
});
export type Union = typeof actions;
