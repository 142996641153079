import { Injectable } from '@angular/core';
import { Observable, of, combineLatest, EMPTY } from 'rxjs';
import { SiteService } from '@spog-ui/shared/services';
import { environment } from '@spog-ui/shared/environments';
import {
  SocketNotification,
  Notification,
} from '@spog-ui/shared/models/websocket-notifications';
import { SocketConnectionFactory } from './socket-connection-factory.service';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectActiveOrgId } from '@spog-ui/shared/state/organizations';

/**
 * This is the actual WebSocket connection observable. You subscribe to the service
 * to open the connection and unsubscribe to close the connection. It doesn't have
 * a real message bus because for right now the UI only reads socket messages.
 */
@Injectable()
export class SocketConnection extends Observable<
  SocketNotification<Notification<unknown>>
> {
  constructor(
    auth: AuthService,
    site: SiteService,
    socketConnectionFactory: SocketConnectionFactory,
    store: Store,
  ) {
    super();

    // This observable is subscribed to when a websocket connection is opened.
    // A new websocket connection is opened when the current siteId changes.
    // Since this is defer'd, the code inside the of() will be evaluated at the time
    // of the subscription.
    const messages$ = combineLatest([
      auth.isAuthenticated$,
      site.observe(),
      store.select(selectActiveOrgId),
    ]).pipe(
      filter(([isAuthenticated]) => isAuthenticated),
      switchMap(([, site, orgId]) =>
        auth.getAccessTokenSilently().pipe(
          switchMap(authToken =>
            of({
              action: 'subscribe',
              authToken,
              siteId: site?.id ?? null,
              organizationId: orgId ?? null,
            }),
          ),
          catchError(() => EMPTY),
        ),
      ),
    );

    this.source = socketConnectionFactory.createConnection(
      environment.apis.ws,
      messages$,
    );
  }
}
