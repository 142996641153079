import { Injectable, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of, timer, Scheduler, zip, Observable } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { GraphQLClient } from '@spog-ui/graphql-client';
import { ApplySceneDocument, ApplySequenceSceneDocument } from '@spog-ui/graphql/types';
import { SharedSceneApiActions } from '@spog-ui/shared/effects/actions';
import { NearbyResultsActions } from '@spog-ui/light-map-layer/actions';
import { ScenesPageActions } from '@spog-ui/scenes/actions';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { LightZoneDetailsPageActions } from '@spog-ui/light-zone-details/actions';

export class SharedScenesApiEffectsConfig {
  readonly scheduler: Scheduler | undefined = undefined;
  readonly minApplyDuration: number = 110;
  readonly successAnimationDuration: number = 2500;
}

@Injectable()
export class SharedScenesApiEffects {
  private config = new SharedScenesApiEffectsConfig();

  constructor(
    private actions$: Actions,
    private client: GraphQLClient,
    private snackbar: MatSnackBar,
    @Optional() config: SharedScenesApiEffectsConfig,
  ) {
    if (config) {
      this.config = config;
    }
  }

  applyScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ScenesPageActions.applySceneAction,
        NearbyResultsActions.applySceneAction,
        LightZoneDetailsPageActions.applySceneAction,
      ),
      map(action => action.scene),
      mergeMap(scene =>
        zip(
          this.client.query(ApplySceneDocument, { sceneId: scene.id }),
          timer(this.config.minApplyDuration, this.config.scheduler),
        ).pipe(
          map(() => SharedSceneApiActions.applySceneSuccessAction(scene.id)),
          catchError(err => {
            return of(
              SharedSceneApiActions.applySceneFailureAction(
                scene,
                retrieveErrorMessage(err),
              ),
            );
          }),
        ),
      ),
    ),
  );

  applySequenceScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenesPageActions.applySequenceSceneAction),
      map(action => action.sequenceScene),
      mergeMap(sequenceScene =>
        zip(
          this.client.query(ApplySequenceSceneDocument, {
            applySceneSequenceInput: { id: sequenceScene.id },
          }),
          timer(this.config.minApplyDuration, this.config.scheduler ?? undefined),
        ).pipe(
          map(() =>
            SharedSceneApiActions.applySequenceSceneSuccessAction(sequenceScene.id),
          ),
          catchError(err => {
            return of(
              SharedSceneApiActions.applySequenceSceneFailureAction(
                sequenceScene,
                retrieveErrorMessage(err),
              ),
            );
          }),
        ),
      ),
    ),
  );

  readyScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedSceneApiActions.applySceneSuccessAction),
      map(action => action.sceneId),
      mergeMap(sceneId =>
        timer(this.config.successAnimationDuration, this.config.scheduler).pipe(
          map(() => SharedSceneApiActions.readySceneAction(sceneId)),
        ),
      ),
    ),
  );

  readySequenceScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedSceneApiActions.applySequenceSceneSuccessAction),
      map(action => action.sequenceSceneId),
      mergeMap(sequenceSceneId =>
        timer(
          this.config.successAnimationDuration,
          this.config.scheduler ?? undefined,
        ).pipe(
          map(() => SharedSceneApiActions.readySequenceSceneAction(sequenceSceneId)),
        ),
      ),
    ),
  );

  applySceneFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedSceneApiActions.applySceneFailureAction),
      map(action => action.scene),
      mergeMap(scene =>
        this.getRetryToast(
          `Failed to apply scene "${scene.name}" to one or more Site Controllers`,
          ScenesPageActions.applySceneAction(scene),
        ),
      ),
    ),
  );

  applySequenceSceneFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedSceneApiActions.applySequenceSceneFailureAction),
      map(action => action.sequenceScene),
      mergeMap(sequenceScene =>
        this.getRetryToast(
          `Failed to apply sequence scene "${sequenceScene.name}" to one or more Site Controllers`,
          ScenesPageActions.applySequenceSceneAction(sequenceScene),
        ),
      ),
    ),
  );

  getRetryToast<T extends Action>(message: string, retryAction: T): Observable<T> {
    return this.snackbar
      .open(message, 'Try Again', { duration: 12 * 1000 })
      .onAction()
      .pipe(map(() => retryAction));
  }
}
