import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '../current-user';

@Injectable({ providedIn: 'root' })
export class AdminGuardService implements CanActivate {
  constructor(private currentUserService: CurrentUserService) {}

  canActivate(): Observable<boolean> {
    return this.currentUserService.isSuperAdmin$;
  }
}
