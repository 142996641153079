import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CurrentUser } from '@spog-ui/shared/models/users';

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  user$: Observable<CurrentUser> = this.auth0.user$.pipe(
    filter((user): user is User => !!user),
    map((user: User) => ({
      id: user.sub ?? 'unknown',
      name: user.name ?? 'unknown',
      email: user.email ?? 'unknown',
      picture: user.picture ?? 'unknown',
    })),
  );

  isSuperAdmin$ = this.auth0.idTokenClaims$.pipe(
    map(claims => claims?.['https://synapsewireless.com/isSuperUser'] ?? false),
  );

  constructor(private auth0: AuthService) {}
}
