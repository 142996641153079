import { createAction } from '@ngrx/store';
import { SceneViewInternalModel } from '@spog-ui/shared/models/scene-views';

export const loadSuccessAction = createAction(
  '[Scene Views API] Load Success',
  (models: { sceneViews: SceneViewInternalModel[] }) => {
    return { models };
  },
);
export const loadFailureAction = createAction(
  '[Scene Views API] Load Failure',
  (error: string) => {
    return { error };
  },
);

export const deleteSceneViewSuccessAction = createAction(
  '[Scene Views API] Delete Scene View Success',
  (sceneViewId: string) => {
    return { sceneViewId };
  },
);
export const deleteSceneViewFailureAction = createAction(
  '[Scene Views API] Delete Scene View Failure',
  (sceneViewId: string, error: string) => {
    return { sceneViewId, error };
  },
);
export const addSceneViewSuccessAction = createAction(
  '[Scene Views API] Add Scene View Success',
  (sceneView: SceneViewInternalModel) => {
    return { sceneView };
  },
);
export const addSceneViewFailureAction = createAction(
  '[Scene Views API] Add Scene View Failure',
  (error: string) => {
    return { error };
  },
);
export const editSceneViewSuccessAction = createAction(
  '[Scene Views API] Edit Scene View Success',
  (sceneView: SceneViewInternalModel) => {
    return { sceneView };
  },
);
export const editSceneViewFailureAction = createAction(
  '[Scene Views API] Edit Scene View Failure',
  (error: string) => {
    return { error };
  },
);
