import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { MatInput } from '@angular/material/input';
import {
  MatFormField,
  MatLabel,
  MatPrefix,
  MatSuffix,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'sui-tree-select-filter',
  template: `
    <mat-form-field
      appearance="outline"
      [class.treeSelectExpandedFilter]="filterIsExpanded"
    >
      <mat-icon matPrefix>search</mat-icon>
      <mat-label><!-- Intentionally omitted --></mat-label>
      <input
        matInput
        type="text"
        placeholder="Filter"
        [value]="filter"
        (input)="onFilter($event.target.value)"
        (focus)="onFocusFilter()"
        (blur)="onBlurFilter()"
      />
      @if (filter) {
      <mat-icon class="clear" (click)="onFilter('')">close</mat-icon>
      }
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        padding-right: 48px;
        height: 64px;
      }

      mat-form-field {
        width: 16px;
        transition: width 300ms;
        position: relative;
        top: 2px;
      }

      mat-form-field input {
        color: var(--color-500) !important;
      }

      mat-form-field.treeSelectExpandedFilter {
        width: 100%;
      }

      ::ng-deep .mat-mdc-form-field-infix {
        display: flex !important;
      }

      mat-form-field mat-icon {
        font-size: 150%;
        position: relative;
        top: 2px;
      }

      ::ng-deep .mdc-notched-outline {
        display: none !important;
      }

      ::ng-deep .mat-mdc-text-field-wrapper {
        padding-right: 0px !important;
      }

      .clear {
        top: 0px;
      }
    `,
  ],
  standalone: true,
  imports: [
    MatFormField,
    MatButton,
    MatIcon,
    MatPrefix,
    MatSuffix,
    MatLabel,
    MatInput,
    AsyncPipe,
  ],
})
export class TreeSelectFilterComponent {
  @Input()
  filterIsExpanded = false;

  @Input()
  filter: string;

  @Output()
  filterChange = new EventEmitter<string>();

  @Output()
  filterFocused = new EventEmitter<boolean>();

  onFilter(filter: string) {
    this.filterChange.emit(filter);
  }

  onFocusFilter() {
    this.filterFocused.emit(true);
  }

  onBlurFilter() {
    this.filterFocused.emit(false);
  }
}
