import { createAction } from '@ngrx/store';

export const getEulaStatusSuccessAction = createAction(
  '[Eula/API] Get Eula Status Success',
  (eulaStatus: boolean) => {
    return { eulaStatus };
  },
);
export const getEulaStatusFailureAction = createAction(
  '[Eula/API] Get Eula Status Failure',
  (error: any) => {
    return { error };
  },
);
export const saveEulaStatusSuccessAction = createAction(
  '[Eula/API] Save Eula Status Success',
  (eulaStatus: boolean) => {
    return { eulaStatus };
  },
);
export const saveEulaStatusFailureAction = createAction(
  '[Eula/API] Save Eula Status Failure',
  (error: any) => {
    return { error };
  },
);
