import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-switch-only',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        <strong>Manual Only</strong> - Only the switch controls the lights in the zone.
      </p>
      <div class="bfForm">
        <div class="bfChunk">
          <p class="bfLabel">When switched on, set dimming level to:</p>
          <sui-dimmer-control formControlName="switchOnLevel"></sui-dimmer-control>

          <p class="bfLabel">When switched off, set dimming level to:</p>
          <sui-dimmer-control formControlName="switchOffLevel"></sui-dimmer-control>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      sui-dimmer-control {
        display: block;
      }
    `,
  ],
})
export class BFSwitchOnlyComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    switchOnLevel: new UntypedFormControl(80),
    switchOffLevel: new UntypedFormControl(0),
  });
}
