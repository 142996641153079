import { NgModule } from '@angular/core';
import { IndieSensorEffectsModule } from './effects';
import { IndieSensorsRoutingModule } from './indie-sensors-routing.module';
import { IndieSensorsPageComponentModule } from './components/indie-sensors-page';
import { AddIndieSensorPageComponentModule } from './components/add-indie-sensor-page';
import { EditIndieSensorPageComponentModule } from './components/edit-indie-sensor-page';

@NgModule({
  imports: [
    IndieSensorEffectsModule,
    IndieSensorsRoutingModule,
    IndieSensorsPageComponentModule,
    AddIndieSensorPageComponentModule,
    EditIndieSensorPageComponentModule,
  ],
})
export class IndieSensorsModule {}
