import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  BehaviorSubject,
  EMPTY,
  interval,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  AfterViewInit,
  Component,
  Injectable,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatInput } from '@angular/material/input';
import { ENTER, ESCAPE, TAB } from '@angular/cdk/keycodes';
import { MatButton } from '@angular/material/button';
import { SliderComponent } from '../slider/slider.component';
import { MatFormField } from '@angular/material/form-field';
import { NgIf, AsyncPipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class DimmerBlinkManager implements OnDestroy {
  private currentTarget$ = new BehaviorSubject<DimmerComponent | null>(null);
  private blinkSubscription: Subscription;

  constructor() {
    this.blinkSubscription = this.currentTarget$
      .pipe(
        distinctUntilChanged(),
        switchMap(instance => {
          if (!instance) return EMPTY;

          return interval(3000).pipe(
            map(i => (i % 2 ? 100 : 0)),
            startWith(100),
            tap(level => {
              instance.dim$.next(level);
              instance.level = level;
            }),
          );
        }),
      )
      .subscribe();
  }

  toggleBlinking(instance: DimmerComponent) {
    if (this.currentTarget$.value === instance) {
      this.currentTarget$.next(null);
    } else {
      this.currentTarget$.next(instance);
    }
  }

  stopBlinking(instance: DimmerComponent) {
    if (this.currentTarget$.value === instance) this.currentTarget$.next(null);
  }

  isBlinking(instance: DimmerComponent) {
    return this.currentTarget$
      .asObservable()
      .pipe(map(otherInstance => instance === otherInstance));
  }

  ngOnDestroy() {
    this.blinkSubscription?.unsubscribe();
  }
}

@Component({
  selector: 'sui-dimmer',
  template: `
    @if (!inputVisible || isDisabled) {
    <span (click)="onInputClick()">
      <em>{{ level }}%</em>
    </span>
    }@if (inputVisible && !isDisabled) {
    <mat-form-field>
      <input
        matInput
        type="number"
        min="0"
        max="100"
        (keydown)="onKeydown($event)"
        (blur)="onBlur()"
      />
    </mat-form-field>
    }
    <sui-slider
      [value]="level"
      (update)="onDim($event)"
      [min]="0"
      [max]="100"
      [isDisabled]="isDisabled"
    ></sui-slider>
    @if (canBlink) {
    <div class="suiSliderBlinkContainer">
      <button mat-flat-button (click)="onBlink()">
        {{ (isBlinking$ | async) ? 'Stop' : 'Blink' }}
      </button>
    </div>
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        position: relative;
        height: 48px;
      }

      span {
        font-size: 16px;
        color: var(--color-foreground-text);
        cursor: pointer;
        display: block;
        width: 40px;
        text-align: right;
        margin-right: 10px;
      }

      span em {
        font-style: normal;
      }

      span:hover em {
        border-bottom: 2px solid var(--color-500);
      }

      mat-form-field {
        margin-right: 10px;
        width: 50px;
      }

      .suiDimmerSuffix {
        width: auto !important;
      }

      sui-slider {
        flex-grow: 2;
        transition: width 150ms;
      }

      :host ::ng-deep .mat-mdc-form-field-infix {
        width: 50px;
        padding-top: 8px;
        padding-bottom: 0px;
      }

      :host ::ng-deep .mat-mdc-text-field-wrapper {
        height: 40px;
      }

      .suiSliderBlinkContainer {
        width: 70px;
        display: flex;
        justify-content: flex-end;
        margin-left: 16px;
      }

      .suiSliderBlinkContainer button {
        background-color: transparent;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }

      :host ::ng-deep .mdc-text-field {
        padding: 8px !important;
      }
    `,
  ],
  standalone: true,
  imports: [NgIf, MatFormField, MatInput, SliderComponent, MatButton, AsyncPipe],
})
export class DimmerComponent implements AfterViewInit, OnDestroy {
  dim$ = new Subject<number>();
  inputVisible = false;
  isBlinking$: Observable<boolean>;
  @Input() level: number;
  @Input() isDisabled = false;
  @Output() dim = this.dim$.pipe(debounceTime(150));
  @ViewChildren(MatInput) inputs: QueryList<MatInput>;
  @Input() canBlink = false;

  constructor(private blinkManager: DimmerBlinkManager) {
    this.isBlinking$ = blinkManager.isBlinking(this);
  }

  ngAfterViewInit(): void {
    this.inputs.changes.subscribe(() => {
      this.inputs.forEach(input => {
        input.focus();
      });
    });
  }

  onDim(newLevel: number): void {
    this.level = newLevel;
    this.inputVisible = false;
    this.dim$.next(newLevel);
    this.blinkManager.stopBlinking(this);
  }

  onInputClick(): void {
    this.inputVisible = true;
  }

  parseInput(value: string): void {
    const parsed = parseInt(value, 10);

    if (Number.isNaN(parsed)) {
      return;
    }

    const clamped = Math.max(Math.min(parsed, 100), 0);

    this.level = clamped;
    this.dim$.next(clamped);
    this.blinkManager.stopBlinking(this);
  }

  onKeydown($event: KeyboardEvent): void {
    if ($event.keyCode === ENTER) {
      $event.stopPropagation();
      this.inputVisible = false;
      this.parseInput(($event as any).target['value']);
    } else if ($event.keyCode === ESCAPE || $event.keyCode === TAB) {
      $event.stopPropagation();
      this.inputVisible = false;
    }
  }

  onBlur(): void {
    this.inputVisible = false;
  }

  onBlink(): void {
    this.blinkManager.toggleBlinking(this);
  }

  ngOnDestroy(): void {
    this.blinkManager.stopBlinking(this);
  }
}

export const DIMMER_DIRECTIVES = [DimmerComponent];
