import { createAction } from '@ngrx/store';

export const resizeAction = createAction(
  '[Browser] Resize',
  (width: number, height: number) => {
    return { width, height };
  },
);
export const currentSizeReadAction = createAction(
  '[Browser] Current Size Read',
  (width: number, height: number) => {
    return { width, height };
  },
);

export type Union =
  | ReturnType<typeof resizeAction>
  | ReturnType<typeof currentSizeReadAction>;

export const Types = {
  Resize: resizeAction.type,
  CurrentSizeRead: currentSizeReadAction.type,
};
