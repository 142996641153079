import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DimmerControlComponent } from '@spog-ui/shared/components';
import { BFFadeComponent } from './bf-fade.component';

@NgModule({
  imports: [ReactiveFormsModule, MatInputModule, DimmerControlComponent],
  declarations: [BFFadeComponent],
  exports: [BFFadeComponent],
})
export class BFFadeComponentModule {}
