import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { EditUtilityServicePageComponent } from './edit-utility-service-page.component';
import { UtilityServiceFormComponentModule } from '../utility-service-form';

@NgModule({
  imports: [CommonModule, SuiSpinnerModule, UtilityServiceFormComponentModule],
  declarations: [EditUtilityServicePageComponent],
  exports: [EditUtilityServicePageComponent],
})
export class EditUtilityServicePageComponentModule {}
