import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpriteLayerComponent } from './sprite-layer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SpriteLayerComponent],
  exports: [SpriteLayerComponent],
})
export class SpriteLayerComponentModule {}
