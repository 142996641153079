import { Component, Inject } from '@angular/core';
import { WINDOW_TOKEN } from '@spog-ui/shared/components';

@Component({
  selector: 'spog-refresh-button',
  template: `
    <button mat-icon-button (click)="refresh()" title="Refresh page">
      <mat-icon>refresh</mat-icon>
    </button>
  `,
  styles: [
    `
      :host {
        margin: -8px -8px -8px 16px;
      }
    `,
  ],
})
export class RefreshButtonComponent {
  constructor(@Inject(WINDOW_TOKEN) public readonly window: Window) {}

  refresh() {
    this.window.location.reload();
  }
}
