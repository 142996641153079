import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { ColorSlidersComponent } from './color-sliders.component';
import { ColorPickerComponent } from './color-picker.component';
import { HexInputComponent } from './hex-input.component';
import { ColorPreviewComponent } from './color-preview.component';
import { CANVAS_FACTORY_BROWSER_PROVIDERS } from './canvas-factory/canvas-factory-browser';
import { ColorPaletteComponent } from './color-palette.component';
import { ColorSwatchComponent } from './color-swatch.component';
import { SwatchRendererService } from './swatch-renderer.service';
import { RenderSwatchPipe } from './render-swatch.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [CommonModule, MatSliderModule, MatMenuModule, DragDropModule],
  declarations: [
    ColorSlidersComponent,
    ColorPickerComponent,
    HexInputComponent,
    ColorPreviewComponent,
    ColorPaletteComponent,
    ColorSwatchComponent,
    RenderSwatchPipe,
  ],
  providers: [CANVAS_FACTORY_BROWSER_PROVIDERS, SwatchRendererService],
  exports: [ColorPickerComponent],
})
export class ColorPickerModule {}
