import {
  Component,
  ContentChild,
  Directive,
  HostBinding,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TemplatePortalDirective, CdkPortalOutlet } from '@angular/cdk/portal';
import { CardComponent } from '../card/card.component';

@Directive({
    selector: '[suiEmptyStateTitle]',
    standalone: true,
})
export class EmptyStateTitleDirective extends TemplatePortalDirective {
  constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(templateRef, viewContainerRef);
  }
}

@Directive({
    selector: '[suiEmptyStateMessage]',
    standalone: true,
})
export class EmptyStateMessageDirective extends TemplatePortalDirective {
  constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(templateRef, viewContainerRef);
  }
}

@Directive({
    selector: '[suiEmptyStateActions]',
    standalone: true,
})
export class EmptyStateActionsDirective extends TemplatePortalDirective {
  constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef) {
    super(templateRef, viewContainerRef);
  }
}

@Component({
    selector: 'sui-empty-state',
    template: `
    <sui-card>
      <h3><ng-template [cdkPortalOutlet]="title"></ng-template></h3>
      <p><ng-template [cdkPortalOutlet]="message"></ng-template></p>
      <div class="suiEmptyStateActions">
        <ng-template [cdkPortalOutlet]="actions"></ng-template>
      </div>
    </sui-card>
  `,
    styles: [
        `
      :host {
        display: flex;
        width: 100%;
        padding: 30px 0;
        justify-content: center;
      }

      h3 {
        padding-bottom: 16px;
        font-size: 20px;
        margin: 0;
      }

      p {
        font-weight: 300;
      }

      sui-card {
        padding: 24px 16px 16px 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 600px;
      }

      .suiEmptyStateActions {
        display: flex;
        justify-content: flex-end;
        padding: 12px 0 0;
      }

      :host ::ng-deep [suiButton] span {
      }

      :host ::ng-deep [suiButton]:hover span {
      }

      /**
     * Primary Color Overrides
     */
      :host(.suiEmptyStatePrimary) h3,
      :host(.suiEmptyStatePrimary) p {
        color: var(--color-contrast-500);
      }

      :host(.suiEmptyStatePrimary) sui-card {
        background-color: var(--color-500);
      }

      :host(.suiEmptyStatePrimary) ::ng-deep [suiButton] span {
        background-color: var(--color-900);
        color: var(--color-contrast-900);
      }

      :host(.suiEmptyStatePrimary) ::ng-deep [suiButton]:hover span {
        background-color: var(--color-800);
      }

      :host(.suiEmptyStatePrimary) ::ng-deep [mat-raised-button] {
        background-color: var(--color-900);
        color: var(--color-contrast-900);
      }

      :host(.suiEmptyStatePrimary) ::ng-deep [mat-raised-button]:hover {
        background-color: var(--color-800);
      }

      /**
     * Accent Color Overrides
     */
      :host(.suiEmptyStateAccent) h3,
      :host(.suiEmptyStateAccent) p {
        color: var(--color-accent-contrast-500);
      }

      :host(.suiEmptyStateAccent) sui-card {
        background-color: var(--color-accent-500);
      }

      :host(.suiEmptyStateAccent) ::ng-deep [suiButton] span {
        background-color: var(--color-accent-900);
        color: var(--color-accent-contrast-900);
      }

      :host(.suiEmptyStateAccent) ::ng-deep [suiButton]:hover span {
        background-color: var(--color-accent-800);
      }

      /**
     * Warn Color Overrides
     */
      :host(.suiEmptyStateWarn) h3,
      :host(.suiEmptyStateWarn) p {
        color: var(--color-warn-contrast-500);
      }

      :host(.suiEmptyStateWarn) sui-card {
        background-color: var(--color-warn-500);
      }

      :host(.suiEmptyStateWarn) ::ng-deep [suiButton] span {
        background-color: var(--color-warn-900);
        color: var(--color-warn-contrast-900);
      }

      :host(.suiEmptyStateWarn) ::ng-deep [suiButton]:hover span {
        background-color: var(--color-warn-800);
      }
    `,
    ],
    standalone: true,
    imports: [CardComponent, CdkPortalOutlet],
})
export class EmptyStateComponent {
  @ContentChild(EmptyStateTitleDirective, { static: true })
  title: EmptyStateTitleDirective;
  @ContentChild(EmptyStateMessageDirective, { static: true })
  message: EmptyStateMessageDirective;
  @ContentChild(EmptyStateActionsDirective, { static: true })
  actions: EmptyStateActionsDirective;

  @Input()
  color: 'primary' | 'accent' | 'warn' | 'default' = 'primary';

  @HostBinding('class.suiEmptyStatePrimary')
  get isPrimaryColor() {
    return this.color === 'primary';
  }

  @HostBinding('class.suiEmptyStateAccent')
  get isAccentColor() {
    return this.color === 'accent';
  }

  @HostBinding('class.suiEmptyStateWarn')
  get isWarnColor() {
    return this.color === 'warn';
  }

  @HostBinding('class.suiEmptyStateDefault')
  get isDefaultColor() {
    return this.color === 'default';
  }
}

export const EMPTY_STATE_DIRECTIVES = [
  EmptyStateComponent,
  EmptyStateTitleDirective,
  EmptyStateMessageDirective,
  EmptyStateActionsDirective,
];
