import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import {
  generateMetricHistoryViewModel,
  toTriggerViewModelFromInternal,
} from '@spog-ui/shared/models/triggers';
import * as CoreState from '@spog-ui/shared/state/core';
import * as TriggersPageState from './triggers-page';
import * as AddTriggerPageState from './add-trigger-page';
import * as EditTriggerPageState from './edit-trigger-page';
import { selectSensorId } from './edit-trigger-page';

export const STATE_KEY = 'triggers';

export interface Shape {
  triggersPage: TriggersPageState.Shape;
  addTriggerPage: AddTriggerPageState.Shape;
  editTriggerPage: EditTriggerPageState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  triggersPage: TriggersPageState.reducer,
  addTriggerPage: AddTriggerPageState.reducer,
  editTriggerPage: EditTriggerPageState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Triggers list page
 */
export const selectTriggersPageState = createSelector(
  selectFeatureState,
  state => state.triggersPage,
);

export const selectTriggersPageIsLoading = createSelector(
  selectTriggersPageState,
  TriggersPageState.selectIsLoading,
);

export const selectTriggersPageError = createSelector(
  selectTriggersPageState,
  TriggersPageState.selectError,
);

export const selectTriggersPageSearchTerm = createSelector(
  selectTriggersPageState,
  TriggersPageState.selectSearchTerm,
);

export const selectTriggerViewModels = createSelector(
  CoreState.selectAllTriggers,
  CoreState.selectSelectedSiteTimeZone,
  (triggers, timezone) => {
    return triggers.map(trigger => toTriggerViewModelFromInternal(trigger, timezone));
  },
);

export const selectFilteredTriggerViewModels = createSelector(
  selectTriggerViewModels,
  selectTriggersPageSearchTerm,
  (triggers, searchTerm) => {
    return triggers.filter(trigger =>
      trigger.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  },
);

export const selectTriggersPageShouldShowGettingStartedCard = createSelector(
  selectTriggersPageIsLoading,
  CoreState.selectAllTriggers,
  (isLoading, triggers) => {
    return !isLoading && triggers.length === 0;
  },
);

export const selectTriggerNames = createSelector(
  CoreState.selectAllTriggers,
  triggers => {
    return triggers.map(trigger => trigger.name);
  },
);

/**
 * Add Trigger Page
 */
export const selectAddTriggerPageState = createSelector(
  selectFeatureState,
  state => state.addTriggerPage,
);

export const selectAddTriggerPageIsLoading = createSelector(
  selectAddTriggerPageState,
  AddTriggerPageState.selectIsLoading,
);

export const selectAddTriggerPageIsSubmitting = createSelector(
  selectAddTriggerPageState,
  AddTriggerPageState.selectIsSubmitting,
);

export const selectAddTriggerPageIsDisabled = createSelector(
  selectAddTriggerPageIsSubmitting,
  submitting => submitting,
);

export const selectAddTriggerPageLoadingError = createSelector(
  selectAddTriggerPageState,
  AddTriggerPageState.selectLoadingError,
);

export const selectAddTriggerPageSubmissionError = createSelector(
  selectAddTriggerPageState,
  AddTriggerPageState.selectSubmissionError,
);

export const selectAddTriggerPageSources = createSelector(
  CoreState.selectAllIndieSensors,
  indieSensors => {
    return { indieSensors };
  },
);

export const selectAddSensorId = createSelector(
  selectAddTriggerPageState,
  AddTriggerPageState.selectSensorId,
);

export const selectAddSource = createSelector(
  CoreState.selectAllMetricSources,
  selectAddSensorId,
  (sources, sensorId) => sources.find(source => source.id === sensorId),
);

export const selectAddSourceMetricHistory = createSelector(
  CoreState.selectAllMetricHistories,
  selectAddSensorId,
  (historyEntities, sensorId) => historyEntities.find(history => history.id === sensorId),
);

export const selectAddMetricHistoryViewModel = createSelector(
  selectAddSource,
  selectAddSourceMetricHistory,
  CoreState.selectSelectedSiteTimeZone,
  generateMetricHistoryViewModel,
);

/**
 * Edit Trigger Page
 */
export const selectEditTriggerPageState = createSelector(
  selectFeatureState,
  state => state.editTriggerPage,
);

export const selectEditTriggerPageIsLoading = createSelector(
  selectEditTriggerPageState,
  EditTriggerPageState.selectIsLoading,
);

export const selectEditTriggerPageIsSubmitting = createSelector(
  selectEditTriggerPageState,
  EditTriggerPageState.selectIsSubmitting,
);

export const selectEditTriggerPageLoadingError = createSelector(
  selectEditTriggerPageState,
  EditTriggerPageState.selectLoadingError,
);

export const selectEditTriggerPageSubmissionError = createSelector(
  selectEditTriggerPageState,
  EditTriggerPageState.selectSubmissionError,
);

export const selectEditTriggerPageSources = createSelector(
  CoreState.selectAllIndieSensors,
  indieSensors => {
    return { indieSensors };
  },
);

export const selectEditTriggerId = createSelector(
  selectEditTriggerPageState,
  EditTriggerPageState.selectTriggerId,
);

export const selectEditTrigger = createSelector(
  CoreState.selectAllTriggers,
  selectEditTriggerId,
  (triggers, triggerEditId) => triggers.find(trigger => trigger.id === triggerEditId),
);

export const selectEditSensorId = createSelector(
  selectEditTriggerPageState,
  EditTriggerPageState.selectSensorId,
);

export const selectEditSensor = createSelector(
  CoreState.selectAllMetricSources,
  selectEditSensorId,
  (sources, sensorId) => sources.find(source => source.id === sensorId),
);

export const selectEditSensorMetricHistory = createSelector(
  CoreState.selectAllMetricHistories,
  selectEditSensorId,
  (historyEntities, sensorId) => historyEntities.find(history => history.id === sensorId),
);

export const selectEditMetricHistoryViewModel = createSelector(
  selectEditSensor,
  selectEditSensorMetricHistory,
  CoreState.selectSelectedSiteTimeZone,
  generateMetricHistoryViewModel,
);
