import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { max } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import { DateTime } from 'luxon';
import * as CoreState from '@spog-ui/shared/state/core';
import { BehaviorType } from '@spog-ui/shared/models/behaviors';
import {
  DateDomainModel,
  PowerSourceModel,
  PowerUnits,
  SourceType,
  TimeInterval,
  SPECIAL_CASE_ALL_LIGHTS,
  ALL_LIGHTS,
  isAllLights,
  computeVariableBaseline,
  computeFlatBaseline,
  EnergyModel,
} from '@spog-ui/shared/models/power-source-comparisons';
import { ZONE_COMPARISON_GRAPH } from '@spog-ui/shared/models/graphs';
import * as BaselineState from './baseline';
import * as DateRangeState from './date-range';
import * as PowerState from './power';
import * as PowerSourceComparisonState from './power-source-comparison';
import { sortByName } from './power/power.reducer';
import { selectSelectedSiteLocationSettings } from '@spog-ui/shared/state/core';
import { BaselineType } from '@spog-ui/graphql/types';

export const STATE_KEY = 'power';

const ZONE_ALL_ID = 'id_builtin_zone_all';

export interface Shape {
  baseline: BaselineState.Shape;
  dateRange: DateRangeState.Shape;
  power: PowerState.Shape;
  powerSourceComparisons: PowerSourceComparisonState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  baseline: BaselineState.reducer,
  dateRange: DateRangeState.reducer,
  power: PowerState.reducer,
  powerSourceComparisons: PowerSourceComparisonState.reducer,
};

export const selectPowerFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Baseline
 */
export const selectBaselineState = createSelector(
  selectPowerFeatureState,
  state => state.baseline,
);
export const selectBaseline = createSelector(
  selectBaselineState,
  BaselineState.selectBaseline,
);
export const selectBaselineType = createSelector(
  selectBaselineState,
  BaselineState.selectBaselineType,
);
export const selectBaselineError = createSelector(
  selectBaselineState,
  BaselineState.selectError,
);
export const selectBaselineSkip = createSelector(
  selectBaselineState,
  BaselineState.selectSkip,
);
export const selectBaselineIsPresent = createSelector(
  selectBaseline,
  baseline => !!baseline,
);
export const selectShowBaselineForm = createSelector(
  selectBaselineIsPresent,
  selectBaselineSkip,
  (presentBaseline, skip) => (skip ? false : !presentBaseline),
);

/**
 * Date Range
 */
export const selectDateRangeState = createSelector(
  selectPowerFeatureState,
  state => state.dateRange,
);

export const selectDateRangeOption = createSelector(
  selectDateRangeState,
  DateRangeState.selectOption,
);
export const selectDateRange = createSelector(
  selectDateRangeState,
  DateRangeState.selectDateRange,
);
export const selectDateInterval = createSelector(selectDateRange, dates => {
  let interval: TimeInterval = TimeInterval.Hour;
  if (dates !== null && dates[0] !== null && dates[1] !== null) {
    /* if the difference between two dates is less than 25 hours,
      have the interval be hours. A difference of between 25 hours and
      768 hours (32 days), is in days, between 768 and 2016 (12 weeks) is in weeks,
      greater than that is in months.
      */
    const difference = dates[0].diff(dates[1], 'hours').toObject();
    if (difference.hours !== undefined) {
      interval = Math.abs(difference.hours) > 25 ? TimeInterval.Day : interval;
      interval = Math.abs(difference.hours) > 768 ? TimeInterval.Week : interval;
      interval = Math.abs(difference.hours) > 2016 ? TimeInterval.Month : interval;
    }
  }
  return interval;
});
/**
 * Power Source Comparisons
 */
export const selectPowerSourceComparisonsState = createSelector(
  selectPowerFeatureState,
  state => state.powerSourceComparisons,
);
export const selectPowerSourceComparisons = createSelector(
  selectPowerSourceComparisonsState,
  PowerSourceComparisonState.selectPowerSourceComparisons,
);
export const selectHasPowerSourceComparisons = createSelector(
  selectPowerSourceComparisons,
  comparisons => comparisons.length > 0,
);
export const selectAllEnergyLines = createSelector(
  selectPowerSourceComparisons,
  comparisons => comparisons.map(comparison => comparison.energyData),
);

export const selectTotalKWPower = createSelector(
  selectPowerSourceComparisons,
  comparisons =>
    comparisons.map(comparison => {
      const totalPower = comparison.powerData
        .slice(0, -1) //TODO Final power datapoint may be incomplete
        .reduce((total: number, next) => total + next.power, 0);

      return totalPower / (comparison.powerData.length - 1);
    }),
);
export const selectCanAddComparison = createSelector(
  selectPowerSourceComparisons,
  powerSourceComparisons => {
    return powerSourceComparisons.length < 5;
  },
);
export const selectCsvData = createSelector(
  selectPowerSourceComparisons,
  powerSourceComparisons => {
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const firstRow = ['Power Source Name', 'Power Source Type', 'Date', 'Time', 'Energy'];
    let csvContent: string;
    let powerSourceName: string;
    let powerSourceType: string;
    let row: string[];
    csvContent = firstRow.join(columnDelimiter) + lineDelimiter;
    if (powerSourceComparisons == null || !powerSourceComparisons.length) {
      return null;
    }

    powerSourceComparisons.forEach(comparison => {
      powerSourceName = comparison.powerSource.name;
      powerSourceType = comparison.powerSource.sourceType;
      comparison.energyData.forEach(data => {
        row = [
          powerSourceName,
          powerSourceType,
          data.date.toFormat('yyyy-MM-dd'),
          data.date.toFormat('HH:mm:ss'),
          data.energy.toString(),
        ];
        csvContent += row.join(columnDelimiter) + lineDelimiter;
      });
    });
    return csvContent;
  },
);

/**
 * Power State
 */

export const selectPowerState = createSelector(
  selectPowerFeatureState,
  state => state.power,
);
export const selectIsPowerLoadingZones = createSelector(
  selectPowerState,
  PowerState.selectIsLoadingZones,
);
export const selectIsPowerLoadingData = createSelector(
  selectPowerState,
  PowerState.selectIsLoadingData,
);
export const selectPowerSources = createSelector(
  CoreState.selectAllNonHiddenZones,
  (zones): PowerSourceModel[] => {
    return [
      // remove 'zone All' if present so we can add a special case for 'All Lights' instead
      ...zones
        .filter(zone => zone.id !== ZONE_ALL_ID)
        .map((zone): PowerSourceModel => {
          return {
            id: zone.id,
            name: zone.name,
            sourceType:
              zone.behaviorId === BehaviorType.DLH
                ? SourceType.DLHZone
                : SourceType.ControlZone,
          };
        }),
      {
        id: SPECIAL_CASE_ALL_LIGHTS,
        name: ALL_LIGHTS,
        sourceType: SourceType.AllLights,
      },
    ];
  },
);

export const selectAllPowerZones = createSelector(
  selectPowerSourceComparisonsState,
  PowerSourceComparisonState.selectPowerSourceComparisons,
);
export const selectAllPowerValues = createSelector(
  selectPowerSourceComparisonsState,
  PowerSourceComparisonState.selectAllPowerValues,
);
export const selectAllEnergyValues = createSelector(
  selectPowerSourceComparisonsState,
  PowerSourceComparisonState.selectAllEnergyValues,
);
export const selectAllDateValues = createSelector(
  selectPowerSourceComparisonsState,
  PowerSourceComparisonState.selectAllDateValues,
);

/**
 * Custom Power Selectors
 */
export const selectShouldShowBaseline = createSelector(
  selectBaselineIsPresent,
  selectPowerSourceComparisons,
  (baselineIsPresent, powerSourceComparisons) => {
    const allLightsIsBeingCompared = powerSourceComparisons.some(comparison =>
      isAllLights(comparison.powerSource),
    );

    return baselineIsPresent && allLightsIsBeingCompared;
  },
);

export const selectEnergyDataTimestamps = createSelector(
  selectAllEnergyLines,
  energyLines => {
    if (!energyLines.length) {
      return [];
    }
    return energyLines[0].map(energyLineDatum => energyLineDatum.date);
  },
);

export const selectBaselineInKWHForInterval = createSelector(
  selectBaseline,
  selectBaselineType,
  selectDateInterval,
  selectEnergyDataTimestamps,
  selectSelectedSiteLocationSettings,
  (
    baseline,
    baselineType,
    dateInterval,
    timestamps,
    siteLocationSettings,
  ): EnergyModel[] => {
    if (baseline === null) {
      return [];
    }

    if (baselineType === BaselineType.FLAT) {
      return computeFlatBaseline(baseline, dateInterval, timestamps);
    } else {
      return computeVariableBaseline(
        baseline,
        dateInterval,
        timestamps,
        siteLocationSettings.latitude,
        siteLocationSettings.longitude,
        siteLocationSettings.timeZone,
      );
    }
  },
);

export const selectEnergyDomain = createSelector(
  selectShouldShowBaseline,
  selectBaselineInKWHForInterval,
  selectAllEnergyValues,
  (shouldShowBaseline, baseline, energyValues): [number, number] => {
    const baselineValues = baseline
      ? baseline!.map(baselineDatum => baselineDatum.energy)
      : [];

    const energyValuesWithBaseline = shouldShowBaseline
      ? [...energyValues, ...baselineValues]
      : energyValues;

    const maxPowerValue =
      energyValuesWithBaseline.length > 0 ? max(energyValuesWithBaseline)! : 5; // Default value if no energy values are loaded

    const powerDomain = [0, maxPowerValue];

    return powerDomain as [number, number];
  },
);

export const selectDateDomain = createSelector(
  selectAllDateValues,
  (dates): DateDomainModel => {
    const fallbackMaxDate: DateTime = DateTime.local().set({ second: 0, millisecond: 0 });
    const fallbackMinDate: DateTime = fallbackMaxDate.minus({ days: 1 });

    const dateDomain =
      dates.length > 0
        ? [DateTime.min(...dates), DateTime.max(...dates)]
        : [fallbackMinDate, fallbackMaxDate];

    return dateDomain as DateDomainModel;
  },
);
export const selectEnergyScale = createSelector(selectEnergyDomain, energyDomain => {
  const graph = ZONE_COMPARISON_GRAPH;
  return scaleLinear()
    .range([graph.height - graph.margin.bottom, graph.margin.top])
    .domain(energyDomain)
    .nice();
});
export const selectDateScale = createSelector(selectDateDomain, dateDomain => {
  const graph = ZONE_COMPARISON_GRAPH;

  return scaleTime()
    .range([graph.margin.left, graph.width - graph.margin.right])
    .domain(dateDomain);
});

export const selectPowerSourceComparisonsWithTotalPower = createSelector(
  selectPowerSourceComparisons,
  selectDateDomain,
  (comparisons, [start, end]) =>
    comparisons.map(comparison => {
      const totalPower = comparison.powerData
        .slice(0, -1)
        .reduce((total, next) => total + next.power, 0);
      const totalKwPower = totalPower / (comparison.powerData.length - 1);
      const diff = end.diff(start, 'hours');

      return {
        ...comparison,
        totalPower: totalKwPower * diff.hours,
      };
    }),
);

export const selectTotalKWHPower = createSelector(
  selectTotalKWPower,
  selectDateDomain,
  (kwPerZone, [start, end]) => {
    const diff = end.diff(start, 'hours');
    return kwPerZone.map(kw => kw * diff.hours);
  },
);

export const selectBaselineInKWH = createSelector(
  selectBaselineInKWHForInterval,
  (baselineInKWHForInterval: EnergyModel[]): number =>
    baselineInKWHForInterval.reduce((totalBaseline, baselineDatum) => {
      return totalBaseline + baselineDatum.energy;
    }, 0),
);

export const selectShowGraph = createSelector(
  selectAllPowerValues,
  (powerValues): boolean => {
    return powerValues.length === 0 ? false : true;
  },
);

export const selectPowerUnit = createSelector(selectTotalKWHPower, totalKWHPower => {
  if (totalKWHPower.some(aggregate => aggregate >= 100_000_000)) {
    return PowerUnits.GigawattHour;
  } else if (totalKWHPower.some(aggregate => aggregate >= 100_000)) {
    return PowerUnits.MegawattHour;
  } else {
    return PowerUnits.KilowattHour;
  }
});

/**
 * Power Source Selection Modal helper selectors
 */
export const selectPowerSourcesNotInComparisons = createSelector(
  selectPowerSources,
  selectPowerSourceComparisons,
  (powerSources, comparisons): PowerSourceModel[] =>
    powerSources.filter(
      powerSource =>
        !comparisons.find(comparison => comparison.powerSource.id === powerSource.id),
    ),
);
export const selectPowerSourcesNotInComparisonsSortedByName = createSelector(
  selectPowerSourcesNotInComparisons,
  (powerSources): PowerSourceModel[] => powerSources.sort(sortByName),
);
export const selectSortedUncomparedLightingPowerSources = createSelector(
  selectPowerSourcesNotInComparisonsSortedByName,
  powerSources =>
    powerSources.filter(
      source =>
        source.sourceType === SourceType.ControlZone ||
        source.sourceType === SourceType.DLHZone ||
        source.sourceType === SourceType.AllLights,
    ),
);
