import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CurrentUserStore } from '@spog-ui/shared/state/current-user';
import { Store } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import { SiteSelectorPageActions } from '@spog-ui/site-selector/actions';

@Injectable({ providedIn: 'root' })
export class AnonymousGuardService implements CanActivate {
  currentUserStore: any;

  constructor(
    readonly store: Store,
    private authGuard: AuthGuard,
    private router: Router,
  ) {
    this.currentUserStore = inject(CurrentUserStore);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.store.select(CoreState.selectSitesLoading).pipe(
      filter(isLoading => !isLoading),
      withLatestFrom(
        this.authGuard.canActivate(route, state),
        this.store.select(CoreState.selectAllSites),
      ),
      map(([, isAuthenticated, sites]) => {
        if (isAuthenticated) {
          if (sites.length === 1) {
            this.store.dispatch(SiteSelectorPageActions.selectAction(sites[0]));
            return this.router.parseUrl(`/site/${sites[0].id}`);
          } else if (this.currentUserStore.selectSiteViaMap()) {
            return this.router.parseUrl('/user/site-map/select');
          } else {
            return this.router.parseUrl('/user/select-site');
          }
        } else {
          return true;
        }
      }),
    );
  }
}
