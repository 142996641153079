import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DimmerControlComponent } from '@spog-ui/shared/components';
import { BFDimmerComponent } from './bf-dimmer.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DimmerControlComponent],
  declarations: [BFDimmerComponent],
  exports: [BFDimmerComponent],
})
export class BFDimmerComponentModule {}
