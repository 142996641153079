import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as OrganizationsState from './organizations.state';

@NgModule({
  imports: [
    StoreModule.forFeature(OrganizationsState.STATE_KEY, OrganizationsState.reducers),
  ],
})
export class OrganizationsStateModule {}
