import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IfUserCanGuard } from '@spog-ui/current-user/permissions';
import {
  IndieSensorsPageComponent,
  IndieSensorsPageComponentModule,
} from './components/indie-sensors-page';
import {
  AddIndieSensorPageComponent,
  AddIndieSensorPageComponentModule,
} from './components/add-indie-sensor-page';
import {
  EditIndieSensorPageComponent,
  EditIndieSensorPageComponentModule,
} from './components/edit-indie-sensor-page';

export const routes: Routes = [
  {
    path: '',
    component: IndieSensorsPageComponent,
    children: [
      {
        path: 'industrial-sensor/details/:activeIndieSensorId',
        loadChildren: () =>
          import('@spog-ui/indie-sensor-details').then(m => m.IndieSensorDetailsModule),
      },
      {
        path: 'equipment/details/:activeEquipmentId',
        loadChildren: () =>
          import('@spog-ui/equipment-details').then(m => m.EquipmentDetailsModule),
      },
      {
        path: 'bridge-485/details/:activeBridge485Id',
        loadChildren: () =>
          import('@spog-ui/bridge-485-details').then(m => m.Bridge485DetailsModule),
      },
    ],
  },
  {
    path: 'add',
    component: AddIndieSensorPageComponent,
    canActivate: [IfUserCanGuard],
    data: { permissions: ['ssc:siteAdmin'] },
  },
  {
    path: ':id',
    component: EditIndieSensorPageComponent,
    canActivate: [IfUserCanGuard],
    data: { permissions: ['ssc:siteAdmin'] },
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild(routes),
    IndieSensorsPageComponentModule,
    AddIndieSensorPageComponentModule,
    EditIndieSensorPageComponentModule,
  ],
})
export class IndieSensorsRoutingModule {}
