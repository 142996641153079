import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { SequenceSceneFormComponentModule } from '../sequence-scene-form';
import { AddSequenceScenePageComponent } from './add-sequence-scene-page.component';

@NgModule({
  imports: [CommonModule, SequenceSceneFormComponentModule, SuiSpinnerModule],
  declarations: [AddSequenceScenePageComponent],
  exports: [AddSequenceScenePageComponent],
})
export class AddSequenceScenePageComponentModule {}
