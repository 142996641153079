import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  defaultMemoize,
} from '@ngrx/store';
import { LightMapLayerIconSize } from '@spog-shared/graphql-enums';
import { findBoundingBox } from '@spog-ui/map-tools';
import { BehaviorType } from '@spog-ui/shared/models/behaviors';
import {
  LightControlViewModel,
  LightViewModel,
  getLightLegendViewModel,
} from '@spog-ui/shared/models/lights';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import * as CoreState from '@spog-ui/shared/state/core';
import * as MapState from '@spog-ui/shared/state/map';
import { filterBy } from '@spog-ui/utils/filter';
import * as LightLayerState from './light-layer';
import * as PreviewState from './preview';

export const STATE_KEY = 'lightingMap';

export interface Shape {
  lightLayer: LightLayerState.Shape;
  preview: PreviewState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  lightLayer: LightLayerState.reducer,
  preview: PreviewState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Light Layer State State
 */
export const selectLightingMapLayerState = createSelector(
  selectFeatureState,
  state => state.lightLayer,
);
export const selectLightMapSearchTerm = createSelector(
  selectLightingMapLayerState,
  LightLayerState.selectSearchTerm,
);
export const selectLightMapLayerError = createSelector(
  selectLightingMapLayerState,
  LightLayerState.selectError,
);
export const selectLightMapLayerLoading = createSelector(
  selectLightingMapLayerState,
  LightLayerState.selectIsLoading,
);
export const selectLightMapLayerLoadingDeferredModels = createSelector(
  selectLightingMapLayerState,
  LightLayerState.selectIsDeferredModelsLoading,
);

/**
 * Active Light
 */
export const selectActiveLightId = createSelector(
  CoreState.selectRouterParams,
  params => params.lightId as string | undefined | null,
);
export const selectActiveLight = createSelector(
  CoreState.selectAllLightViews,
  selectActiveLightId,
  (views, id) => id && views.find(view => view.id === id),
);

/**
 * Active Light Alarm
 */
export const selectActiveLightAlarms = createSelector(
  selectActiveLight,
  CoreState.selectAlarmLookupTableByControllerId,
  (light, alarmsTablesByControllerId) => {
    return light ? alarmsTablesByControllerId[light.controllerId] ?? [] : [];
  },
);

/**
 * Active Light Properties
 */
export const selectActiveLightSnapAddr = createSelector(
  selectActiveLight,
  CoreState.selectControllerEntities,
  (activeLight, controllers) => {
    return activeLight ? controllers[activeLight.controllerId]?.snapaddr : '';
  },
);
export const selectZonesForActiveLight = createSelector(
  CoreState.selectAllLightViews,
  selectActiveLightId,
  CoreState.selectAllNonHiddenZones,
  (lights, activeLightId, zones) => {
    if (!activeLightId) {
      return [];
    }

    const activeLightView = lights.find(i => i.id === activeLightId);
    const zoneIds = activeLightView ? Object.keys(activeLightView.zones) : [];

    return zones.filter(zone => zoneIds.includes(zone.id));
  },
);

export const selectActiveLightHasZones = createSelector(
  selectZonesForActiveLight,
  zones => zones.length > 0,
);

export const selectScenesForActiveLight = createSelector(
  selectZonesForActiveLight,
  CoreState.selectSceneViews,
  CoreState.selectSceneZoneBehaviorViews,
  (activeLightZones, scenes, szbs) => {
    const zoneIds = activeLightZones.map(zone => (zone ? zone.id : null));
    const szbsForActiveLight = szbs.filter(szb => zoneIds.includes(szb.zoneId));

    return scenes.filter(scene =>
      szbsForActiveLight.map(activeSzb => activeSzb.sceneId).includes(scene.id),
    );
  },
);

/**
 * Preview State
 */
export const selectPreviewState = createSelector(
  selectFeatureState,
  state => state.preview,
);
export const selectPreviewZone = createSelector(
  selectPreviewState,
  PreviewState.selectPreviewZoneId,
);
export const selectPreviewLightId = createSelector(
  selectPreviewState,
  PreviewState.selectPreviewLightId,
);
export const selectPreviewZoneId = createSelector(
  selectPreviewState,
  PreviewState.selectPreviewZoneId,
);
export const selectPreviewSceneId = createSelector(
  selectPreviewState,
  PreviewState.selectPreviewSceneId,
);

/**
 * Custom Light Control View
 */
export const selectLightControlViewModel = createSelector(
  CoreState.selectLightEntities,
  selectActiveLightId,
  (lightEntities, lightId): LightControlViewModel | null => {
    return {
      id: lightEntities[lightId!]!.id,
      name: lightEntities[lightId!]!.name,
      level: lightEntities[lightId!]!.level!,
    };
  },
);

/**
 * Nearby Selectors
 */
export const selectNearbyResultsLightIds = createSelector(
  CoreState.selectRouterQueryParams,
  (queryParams): string[] => {
    const stringifiedLightIds = queryParams.lightIds;

    return stringifiedLightIds ? stringifiedLightIds.split(',') : [];
  },
);

export const selectMapLightsNearLastClick = createSelector(
  CoreState.selectAllLightViews,
  selectNearbyResultsLightIds,
  (lights, nearbyLightIdsSortedByDistanceToClick) => {
    const nearbyLights = [];
    for (let i = 0, len = nearbyLightIdsSortedByDistanceToClick.length; i < len; i++) {
      const lightId = nearbyLightIdsSortedByDistanceToClick[i];

      const foundLight = lights.find(light => light.id === lightId);

      if (foundLight) {
        nearbyLights.push(foundLight);
      }
    }

    return nearbyLights;
  },
);

export const selectNearbyLightsControllerIds = createSelector(
  selectNearbyResultsLightIds,
  CoreState.selectLightEntities,
  (lightIds, lightEntities) => {
    return lightIds.map(id => lightEntities[id]!.controllerId);
  },
);

export const selectNearbyLightsHaveAlarms = createSelector(
  selectNearbyLightsControllerIds,
  CoreState.selectAlarmLookupTableByControllerId,
  (controllerIds, alarmsTablesByControllerId) => {
    return controllerIds.some(id => alarmsTablesByControllerId[id]);
  },
);

export const selectMapZoneIdsNearLastClick = createSelector(
  CoreState.selectAllLightViews,
  selectNearbyResultsLightIds,
  (lights, nearbyLightIds) => {
    const uniqueZoneIdSet = new Set<string>();
    for (let i = 0, len = lights.length; i < len; i++) {
      const light = lights[i];
      if (nearbyLightIds.includes(light.id)) {
        for (const key in light.zones) {
          uniqueZoneIdSet.add(key);
        }
      }
    }
    const uniqueZoneIds = Array.from(uniqueZoneIdSet.values());

    return uniqueZoneIds;
  },
);

export const selectNearbyScenes = createSelector(
  CoreState.selectSceneViews,
  selectMapZoneIdsNearLastClick,
  (sceneViews, nearbyZoneIds) =>
    sceneViews.filter(sceneView =>
      sceneView.linkedBehaviorList.some(behavior =>
        behavior.zoneList.some(zone => nearbyZoneIds.some(zoneId => zoneId === zone.id)),
      ),
    ),
);

export const selectZoneIdsInNearbyScenes = createSelector(
  selectNearbyScenes,
  selectPreviewSceneId,
  (nearbyScenes, previewSceneId): null | string[] => {
    const previewScene = nearbyScenes.find(scene => scene.id === previewSceneId);

    if (!previewScene) {
      return null;
    }

    return previewScene.linkedBehaviorList.reduce(
      (zoneIds, linkedBehavior) =>
        zoneIds.concat(linkedBehavior.zoneList.map(zone => zone.id)),
      [] as string[],
    );
  },
);

export const selectMapNearbyZones = createSelector(
  CoreState.selectAllNonHiddenZones,
  selectMapZoneIdsNearLastClick,
  (entities, ids) => {
    /*
      There is a possibility of data inconsistency here where the
      zone might have been deleted but the light-zone record
      persists. We are filtering the results here to allow the
      UI to continue to function.
     */
    const parsedResults = ids.reduce<{
      foundZones: ZoneInternalModel[];
      inconsistentIds: string[];
    }>(
      (memo, id) => {
        const existingZone = entities.find(zone => zone.id === id);
        if (existingZone) {
          memo.foundZones.push(existingZone);
        } else {
          memo.inconsistentIds.push(id);
        }

        return memo;
      },
      { foundZones: [], inconsistentIds: [] },
    );

    if (parsedResults.inconsistentIds.length > 0) {
      console.log(
        `💥 Inconsistent zoneIds found in light-zones: [${parsedResults.inconsistentIds.join(
          ',',
        )}]`,
      );
    }

    return parsedResults.foundZones.sort(sortByName);
  },
);

/**
 * Light Map Selectors
 */

export const selectFilteredLightsForTheMap = createSelector(
  CoreState.selectAllLightViews,
  selectLightMapSearchTerm,
  (lights, searchTerm) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return lights.filter(light =>
      filterBy(lowerCaseSearchTerm, [
        light.name.toLowerCase(),
        light.snapaddr.toLowerCase(),
      ]),
    );
  },
);

export const selectLightsToRenderOnMap = createSelector(
  CoreState.selectAllLightViews,
  selectFilteredLightsForTheMap,
  selectPreviewZoneId,
  selectPreviewSceneId,
  (allLights, filteredLights, previewZoneId, previewSceneId) => {
    if (previewZoneId || previewSceneId) return allLights;

    return filteredLights;
  },
);

export const selectLightNames = createSelector(CoreState.selectAllLights, lights => {
  return lights.map(light => light.name);
});

export const selectLightLegendView = createSelector(
  CoreState.selectAllLightViews,
  lightViews => getLightLegendViewModel(lightViews),
);

/**
 * Lights positioning selectors
 */
export const selectPositionedLightNames = createSelector(
  CoreState.selectPositionedLights,
  positionedLights => positionedLights.map(light => light.name),
);

export const selectHasPositionedLights = createSelector(
  CoreState.selectPositionedLights,
  positionedLights => positionedLights.length > 0,
);

export const selectBoxAroundFilteredLights = createSelector(
  selectFilteredLightsForTheMap,
  CoreState.selectAllLightViews,
  (filteredLights, allLights) => {
    const lights = filteredLights.length > 0 ? filteredLights : allLights;

    return findBoundingBox(lights);
  },
);

export const selectPreviousLights = createSelector(CoreState.selectAllLights, lights => {
  return lights.map(light => {
    return {
      lightId: light.id,
      floorPlanX: light.floorPlanX,
      floorPlanY: light.floorPlanY,
    };
  });
});

/**
 * Active Light Zone Selectors
 */
export const selectActiveLightingZone = createSelector(
  CoreState.selectActiveLightingZone,
  zone => zone,
);

export const selectActiveLightZoneIsDlh = createSelector(
  CoreState.selectActiveLightingZone,
  zone => {
    return zone && zone.behaviorId === BehaviorType.DLH;
  },
);

export const selectActiveLightZoneBehaviors = createSelector(
  CoreState.selectAllVisibleBehaviors,
  selectActiveLightZoneIsDlh,
  (behaviors, isDlh) => {
    if (isDlh) return behaviors.filter(behavior => behavior.id === BehaviorType.DLH);

    return behaviors.filter(behavior => behavior.id !== BehaviorType.DLH);
  },
);

/**
 * Low Density Light selectors
 */

export const selectSortedLightViews = createSelectorFactory(projectorFn => {
  return defaultMemoize(
    projectorFn,
    (a: any, b: any) => a === b,
    (a: LightViewModel[], b: LightViewModel[]) => {
      return (
        a &&
        b &&
        a.length === b.length &&
        a.every((lightA, index) => {
          const lightB = b[index];

          return (
            lightA.floorPlanX === lightB.floorPlanX &&
            lightA.floorPlanY === lightB.floorPlanY
          );
        })
      );
    },
  );
})(selectLightsToRenderOnMap, (lightViewsToRender: LightViewModel[]) => {
  return lightViewsToRender.sort((a, b) => a.floorPlanX - b.floorPlanX);
});

export const selectShortestDistanceBetweenLights = createSelector(
  selectSortedLightViews,
  lightViews => {
    return closestUtil(lightViews);
  },
);

export const selectIsLowDensity = createSelector(
  CoreState.selectLightMapLayerIconSize,
  selectShortestDistanceBetweenLights,
  MapState.selectZoomAndPanTransform,
  (lightMapLayerIconSize, distance, transform) =>
    lightMapLayerIconSize === LightMapLayerIconSize.BIG_ICONS ||
    (lightMapLayerIconSize === LightMapLayerIconSize.AUTO && distance * transform.k > 34),
);

/**
 * Helper functions
 */

function sortByName<T extends { name: string }>(a: T, b: T): number {
  return a.name.localeCompare(b.name);
}

function closestUtil(points: LightViewModel[]) {
  if (points.length <= 3) {
    return bruteForce(points);
  }

  const mid = Math.floor(points.length / 2);
  const middle = points[mid];

  const dl = closestUtil(points.slice(0, mid));
  const dr = closestUtil(points.slice(mid + 1));

  const d = Math.min(dl, dr);

  const strip = new Array<LightViewModel>();

  let j = 0;

  for (let i = 0; i < points.length; i++) {
    if (Math.abs(points[i].floorPlanX - middle.floorPlanX) < d) {
      strip[j] = points[i];
      j++;
    }
  }
  return Math.min(d, stripClosest(strip, j, d));
}

function bruteForce(lightViewsToRender: LightViewModel[]) {
  let distance = Infinity;

  for (const light of lightViewsToRender) {
    for (const otherLight of lightViewsToRender) {
      if (light.id === otherLight.id) {
        continue;
      }
      distance = Math.min(distance, dist(light, otherLight));
    }
  }
  return distance;
}

function stripClosest(strip: LightViewModel[], size: number, d: number) {
  let min = d;

  strip = strip.sort((a, b) => a.floorPlanY - b.floorPlanY);

  for (let i = 0; i < size; ++i)
    for (let j = i + 1; j < size && strip[j].floorPlanY - strip[i].floorPlanY < min; ++j)
      if (dist(strip[i], strip[j]) < min) min = dist(strip[i], strip[j]);

  return min;
}

function dist(point1: LightViewModel, point2: LightViewModel) {
  return Math.sqrt(
    Math.pow(point2.floorPlanX - point1.floorPlanX, 2) +
      Math.pow(point2.floorPlanY - point1.floorPlanY, 2),
  );
}
