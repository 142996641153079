import { createAction } from '@ngrx/store';
import { SceneViewModel } from '@spog-ui/shared/models/scenes';

export const enterAction = createAction('[Lighting Map / Nearby Results] Enter');
export const leaveAction = createAction('[Lighting Map / Nearby Results] Leave');

export const previewZoneAction = createAction(
  '[Lighting Map / Nearby Results] Preview Zone',
  (zoneId: string) => {
    return { zoneId };
  },
);
export const unpreviewZoneAction = createAction(
  '[Lighting Map / Nearby Results] Unpreview Zone',
);
export const previewLightAction = createAction(
  '[Lighting Map / Nearby Results] Preview Light',
  (lightId: string) => {
    return { lightId };
  },
);
export const unpreviewLightAction = createAction(
  '[Lighting Map / Nearby Results] Unpreview Light',
);
export const previewSceneAction = createAction(
  '[Lighting Map / Nearby Results] Preview Scene',
  (sceneId: string) => {
    return { sceneId };
  },
);
export const unpreviewSceneAction = createAction(
  '[Lighting Map / Nearby Results] Unpreview Scene',
);
export const applySceneAction = createAction(
  '[Lighting Map / Nearby Results] Apply Scene',
  (scene: SceneViewModel) => {
    return { scene };
  },
);
