import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'sui-pending-button',
  template: `
    <button [class.suiShowButton]="!isPending && !isSuccess">
      <ng-content></ng-content>
    </button>

    @if (isPending) {
    <sui-spinner></sui-spinner>
    } @if (isSuccess) {
    <svg
      class="suiPendingButtonCheckmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle
        class="suiPendingButtonCheckmarkCircle"
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      <path
        class="suiPendingButtonCheckmarkCheck"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
    }
  `,
  styles: [
    `
      :host {
        position: relative;
        display: block;
      }

      button {
        background-color: transparent;
        border: 1px solid var(--color-foreground-divider);
        border-radius: 2px;
        cursor: pointer;
        display: block;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        min-width: 64px;
        opacity: 0;
        padding: 10px 16px;
        pointer-events: none;
        outline: none;
        text-transform: uppercase;
        transition: opacity 150ms, color 150ms, border-color 150ms;
      }

      button.suiShowButton {
        opacity: 1;
        pointer-events: auto;
      }

      sui-spinner {
        position: absolute;
        width: 24px;
        top: 50%;
        left: 50%;
        margin-left: -12px;
        margin-top: -12px;
      }

      .suiPendingButtonCheckmark {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -12px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: var(--color-contrast-500);
        stroke-miterlimit: 10;
        box-shadow: inset 0 0 0 var(--color-500);
        animation: sui-pending-button-fill 0.4s ease-in-out 0.4s forwards,
          sui-pending-button-scale 0.3s ease-in-out 0.9s both;
      }

      .suiPendingButtonCheckmarkCircle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: var(--color-500);
        fill: none;
        animation: sui-pending-button-stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      }

      .suiPendingButtonCheckmarkCheck {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        animation: sui-pending-button-stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s
          forwards;
      }

      @keyframes sui-pending-button-stroke {
        100% {
          stroke-dashoffset: 0;
        }
      }

      @keyframes sui-pending-button-scale {
        0%,
        100% {
          transform: none;
        }
        50% {
          transform: scale3d(1.1, 1.1, 1);
        }
      }

      @keyframes sui-pending-button-fill {
        100% {
          box-shadow: inset 0 0 0 24px var(--color-500);
        }
      }

      /* Colour variant */
      :host.orange button {
        color: var(--color-accent-500);
      }
      :host.orange button:hover {
        border-color: var(--color-accent-300);
        color: var(--color-accent-300);
      }
      :host.blue button {
        color: var(--color-500);
      }
      :host.blue button:hover {
        border-color: var(--color-300);
        color: var(--color-300);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, SpinnerComponent],
})
export class PendingButtonComponent {
  @Input() isPending = false;
  @Input() isSuccess = false;
  @HostBinding('class') @Input() colourVariant: 'orange' | 'blue' = 'orange';
}
