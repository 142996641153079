import { createAction } from '@ngrx/store';
import { ThermostatAlarmInternalModel } from '@spog-ui/shared/models/thermostats';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

export const loadClimateZonesPageModelsSuccessAction = createAction(
  '[Climate Zones API] Load Climate Zones Page Models Success',
  (controlZones: ZoneInternalModel[], alarms: ThermostatAlarmInternalModel[]) => ({
    models: {
      controlZones,
      alarms,
    },
  }),
);

export const loadClimateZonesPageModelsFailureAction = createAction(
  '[Climate Zones API] Load Climate Zones Page Models Failure',
  (error: any) => ({ error }),
);

export const createClimateZoneSuccess = createAction(
  '[Climate Zones API] Create Climate Zone Success',
);
export const createClimateZoneFailure = createAction(
  '[Climate Zones API] Create Climate Zone Failure',
  (errors: string[]) => ({ errors }),
);

export const editClimateZoneSuccess = createAction(
  '[Climate Zones API] Edit Climate Zone Success',
);
export const editClimateZoneFailure = createAction(
  '[Climate Zones API] Edit Climate Zone Failure',
  (errors: string[]) => ({ errors }),
);
