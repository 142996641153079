import { Knobs, getKnobs } from '@omni/knobs';
import { HttpMethod } from '@auth0/auth0-angular';

export const apiDomain = 'simplysnapcloud.com';
const authDomain = 'synapsewireless.com';
const mapGeneratorServiceUrl = 'https://maps.simplysnapcloud.com/api/get-map-image';

export const environment = {
  production: true,
  knobs: getKnobs(Knobs.None),
  apis: {
    gql: `https://api.${apiDomain}/graphql`,
    ws: `wss://ws.${apiDomain}`,
    mapGeneratorService: mapGeneratorServiceUrl,
  },
  auth: {
    clientId: '1x4SfRokWGk17qLNOPICKNRunzJleHQu',
    domain: `login.${authDomain}`,
    scope: 'openid profile email user_id',
    useRefreshTokens: true,
    redirectUri: `${location.origin}/user`,
    target: `${location.origin}/user/select-site`,
  },
  httpInterceptor: {
    allowedList: [
      `https://api.${apiDomain}/graphql`,
      {
        uri: mapGeneratorServiceUrl,
        httpMethod: HttpMethod.Post,
        allowAnonymous: true,
      },
    ],
  },
  firebase: {
    apiKey: 'AIzaSyBdj7uj5Fn1nY87M4UfhXXE2ZbM3n3inGc',
    authDomain: 'simplysnapcloud-prod.firebaseapp.com',
    projectId: 'simplysnapcloud-prod',
    storageBucket: 'simplysnapcloud-prod.appspot.com',
    messagingSenderId: '93175185659',
    appId: '1:93175185659:web:42a8c51999c33848acb05e',
    measurementId: 'G-P9VKSN7VDZ',
    functionHost: 'https://us-central1-simplysnapcloud-prod.cloudfunctions.net',
  },
  microFrontends: {
    energyOverview: 'https://energy-insights.simplysnapcloud.com',
  },
};
