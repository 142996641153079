import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';
import tinycolor from 'tinycolor2';

@Component({
  selector: 'sui-bf-sparkle',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        Lights in the zone randomly fade in and out, creating a sparkling effect.
      </p>
      <sui-speed-control formControlName="behaviorSpeed"></sui-speed-control>
      <p class="bfLabel">Color Selection:</p>
      <mat-checkbox formControlName="colorEnabled"> Enabled </mat-checkbox>
    
      @if (form.value.colorEnabled) {
        <div class="colorSelection">
          <sui-color-hue-control formControlName="colorHue"> </sui-color-hue-control>
          <sui-color-saturation-control
            [hueControl]="form.get('colorHue')"
            formControlName="colorSaturation"
            >
          </sui-color-saturation-control>
          <sui-color-luminance-control
            [hueControl]="form.get('colorHue')"
            [saturationControl]="form.get('colorSaturation')"
            formControlName="colorLuminance"
            >
          </sui-color-luminance-control>
          <sui-color-preview
            [hueControl]="form.get('colorHue')"
            [saturationControl]="form.get('colorSaturation')"
            [luminanceControl]="form.get('colorLuminance')"
            >
          </sui-color-preview>
          <p class="bfLabel">Amber Level:</p>
          <em>(not shown in preview)</em>
          <sui-dimmer-control formControlName="levelAmber"></sui-dimmer-control>
          <p class="bfLabel">White Level:</p>
          <em>(not shown in preview)</em>
          <sui-dimmer-control formControlName="levelWhite"></sui-dimmer-control>
        </div>
      }
    </div>
    `,
})
export class BFSparkleComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    behaviorSpeed: new UntypedFormControl('Normal'),
    colorHue: new UntypedFormControl(0),
    colorSaturation: new UntypedFormControl(100),
    colorLuminance: new UntypedFormControl(50),
    levelAmber: new UntypedFormControl(0),
    levelWhite: new UntypedFormControl(0),
    colorEnabled: new UntypedFormControl(false),
  });

  getBehaviorParameters() {
    let mode: string;
    let levelRed = 0;
    let levelGreen = 0;
    let levelBlue = 0;
    let levelAmber = 0;
    let levelWhite = 0;

    const { r, g, b } = tinycolor({
      h: this.form.value.colorHue,
      s: this.form.value.colorSaturation,
      l: this.form.value.colorLuminance,
    }).toRgb();

    if (this.form.value.colorEnabled) {
      mode = 'Color';
      levelRed = this.scaleToRange(r, 255, 100);
      levelGreen = this.scaleToRange(g, 255, 100);
      levelBlue = this.scaleToRange(b, 255, 100);
      levelAmber = this.form.value.levelAmber;
      levelWhite = this.form.value.levelWhite;
    } else {
      mode = 'White';
    }

    return {
      speed: this.form.value.behaviorSpeed,
      mode,
      levelRed,
      levelGreen,
      levelBlue,
      levelAmber,
      levelWhite,
    };
  }

  writeBehaviorParameters(parameters: any) {
    const { h, s, l } = tinycolor({
      r: this.scaleToRange(parameters.levelRed, 100, 255),
      g: this.scaleToRange(parameters.levelGreen, 100, 255),
      b: this.scaleToRange(parameters.levelBlue, 100, 255),
    }).toHsl();

    this.form.patchValue(
      {
        colorHue: h,
        colorSaturation: s * 100,
        colorLuminance: l * 100,
        levelAmber: parameters.levelAmber,
        levelWhite: parameters.levelWhite,
        behaviorSpeed: parameters.speed,
        colorEnabled: parameters.mode == 'Color',
      },
      {
        onlySelf: true,
        emitEvent: false,
      },
    );
  }

  scaleToRange(value: number, oldMax: number, newMax: number) {
    return Math.floor((value * newMax) / oldMax);
  }
}
