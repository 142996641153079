import { createAction } from '@ngrx/store';
import { ApiErrorModel } from '@spog-ui/shared/models/errors';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const loadSuccessAction = createAction(
  '[Admin / Sites / API] Load Success',
  (sites: SiteInternalModel[]) => {
    return { sites };
  },
);
export const loadFailureAction = createAction(
  '[Admin / Sites / API] Load Failure',
  (error: { errors: ApiErrorModel[] }) => {
    return { error };
  },
);
