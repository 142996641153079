import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  GetOrganizationByIdQueryResult,
  GraphQLAPIService,
} from '@spog-ui/graphql/types';
import {
  OrganizationInternalModel,
  toOrganizationInternalModelFromIdentityGQL,
} from '@spog-ui/shared/models/organizations';

@Injectable({ providedIn: 'root' })
export class AdminOrganizationShellApiService {
  constructor(readonly gql: GraphQLAPIService) {}

  getOrganizationById(organizationId: string): Observable<OrganizationInternalModel> {
    return this.gql.getOrganizationById({ id: organizationId }).pipe(
      map((response: GetOrganizationByIdQueryResult) => {
        if (!response.organization) {
          throw new Error('No organization for id');
        }

        return toOrganizationInternalModelFromIdentityGQL(response.organization);
      }),
    );
  }
}
