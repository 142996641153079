import { createAction } from '@ngrx/store';
import { DepartmentFormModel } from '@spog-ui/shared/models/department';

export const enterAction = createAction(
  '[Edit Department Page] Enter',
  (departmentId: string) => ({ departmentId }),
);

export const editDepartmentSubmitAction = createAction(
  '[Edit Department Page] Edit Department Submit',
  (id: string, department: DepartmentFormModel) => ({ id, department }),
);
