import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SiteService } from '@spog-ui/shared/services';
import { defer } from 'rxjs';
import { map, throttleTime, withLatestFrom } from 'rxjs/operators';
import { processUnhandledExceptionAction } from '@spog-ui/analytics';
import * as CoreState from '@spog-ui/shared/state/core';
import { unhandledExceptionAction } from '../actions';
import { CurrentUserService } from '@spog-ui/current-user/feature';

@Injectable()
export class UnhandledExceptionEffects {
  buildExceptionContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unhandledExceptionAction),
      throttleTime(250),
      withLatestFrom(
        defer(() => this.store.select(CoreState.selectSelectedSite)),
        defer(() => this.currentUser.user$),
        defer(() => this.store.select(CoreState.selectRouterUrl)),
      ),
      map(([action, site, user, activeRoute]) =>
        processUnhandledExceptionAction({
          error: action.error,
          siteId: site?.id,
          userId: user?.id,
          activeRoute,
        }),
      ),
    ),
  );

  constructor(
    readonly actions$: Actions,
    readonly store: Store,
    readonly site: SiteService,
    readonly currentUser: CurrentUserService,
  ) {}
}
