import { createAction } from '@ngrx/store';

export const openAdminPanel = createAction(
  '[Admin] Open Admin Panel',
  (redirectUrl: string) => ({
    redirectUrl,
  }),
);

export const closeAdminPanel = createAction('[Admin] Close Admin Panel');
