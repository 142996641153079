import {
  EditScheduledEventPageActions,
  ScheduleApiActions,
} from '@spog-ui/schedule/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  scheduledEventId: string | null;
  saving: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: Shape = {
  scheduledEventId: null,
  saving: false,
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(EditScheduledEventPageActions.enterAction, (state, action) => {
    return {
      ...state,
      scheduledEventId: action.scheduledEventId,
    };
  }),
  on(EditScheduledEventPageActions.editScheduledEventAction, state => {
    return {
      ...state,
      saving: true,
      error: null,
    };
  }),
  on(ScheduleApiActions.editScheduledEventSuccessAction, state => {
    return {
      ...state,
      saving: false,
      error: null,
    };
  }),
  on(ScheduleApiActions.editScheduledEventFailureAction, (state, action) => {
    return {
      ...state,
      saving: false,
      error: action.error,
    };
  }),
  on(ScheduleApiActions.loadSuccessAction, state => {
    return {
      ...state,
      error: null,
      saving: false,
      loading: false,
    };
  }),
  on(ScheduleApiActions.loadFailureAction, (state, action) => {
    return {
      ...state,
      error: action.error,
      saving: false,
      loading: false,
    };
  }),
);

export const selectEditing = (state: Shape) => state.saving;
export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
export const selectScheduledEventId = (state: Shape) => state.scheduledEventId;
