import { Injectable } from '@angular/core';

@Injectable()
export class CanvasFactory {
  createCanvas(width: number, height: number): HTMLCanvasElement {
    throw new Error(
      'Not implemented. You must provide either the browser or the Node implementation.',
    );
  }
}
