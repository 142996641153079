import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SuiPromptModule } from '@sui/prompt';
import { ScenesPageEffects } from './effects';
import {
  ScenesPageComponent,
  ScenesPageComponentModule,
  ScenesPageState,
} from './components/scenes-page';

@NgModule({
  imports: [SuiPromptModule, EffectsModule.forFeature([ScenesPageEffects])],
  exports: [ScenesPageComponentModule],
})
class ViewScenesFeatureModule {
  static connect(
    StateAdapterClass: Type<ScenesPageState>,
  ): ModuleWithProviders<ViewScenesFeatureModule> {
    return {
      ngModule: ViewScenesFeatureModule,
      providers: [
        {
          provide: ScenesPageState,
          useClass: StateAdapterClass,
        },
      ],
    };
  }
}

export { ScenesPageComponent, ScenesPageState, ViewScenesFeatureModule };
