import { createSelector } from '@ngrx/store';
import { STATE_KEY } from './bridge-485-details-pane.reducer';
import * as fromReducer from './bridge-485-details-pane.reducer';
import { selectFeature } from './constants';
import { selectBridge485Entities } from '@spog-ui/shared/state/bridge-485s';
import { selectAllIndieSensors } from '@spog-ui/shared/state/core';
import { toBridge485DetailsViewModel } from '../models';

export const selectBridge485DetailsFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectActiveBridge485Id = createSelector(
  selectBridge485DetailsFeatureState,
  fromReducer.selectActiveBridge485Id,
);

export const selectError = createSelector(
  selectBridge485DetailsFeatureState,
  fromReducer.selectError,
);

export const selectIsLoading = createSelector(
  selectBridge485DetailsFeatureState,
  fromReducer.selectIsLoading,
);

export const selectActiveBridge485 = createSelector(
  selectActiveBridge485Id,
  selectBridge485Entities,
  (id, bridge485s) => {
    if (!id) return null;

    const activeBridge485 = bridge485s[id];

    return activeBridge485 ? toBridge485DetailsViewModel(activeBridge485) : null;
  },
);

export const selectActiveBridge485Metrics = createSelector(
  selectActiveBridge485,
  selectAllIndieSensors,
  (parent, potentialChildren) =>
    parent
      ? potentialChildren.filter(indieSensor => parent.metricIds.includes(indieSensor.id))
      : [],
);
