import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAllLightingZones } from '@spog-ui/shared/state/core';
import * as LightingZoneDetailsPageState from './lighting-zone-details-page';
import * as LightingZonesPageState from './lighting-zones-page';

export const STATE_KEY = 'lighting-zones';

export interface Shape {
  lightingZoneDetailsPage: LightingZoneDetailsPageState.Shape;
  lightingZonesPage: LightingZonesPageState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  lightingZoneDetailsPage: LightingZoneDetailsPageState.reducer,
  lightingZonesPage: LightingZonesPageState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

export const selectLightingZoneDetailsPageState = createSelector(
  selectFeatureState,
  (state: Shape) => state.lightingZoneDetailsPage,
);

export const selectLightingZoneDetailsPageStateError = createSelector(
  selectLightingZoneDetailsPageState,
  LightingZoneDetailsPageState.selectError,
);

export const selectLightingZoneDetailsPageStateIsLoading = createSelector(
  selectLightingZoneDetailsPageState,
  LightingZoneDetailsPageState.selectIsLoading,
);

export const selectLightingZonesPageState = createSelector(
  selectFeatureState,
  state => state.lightingZonesPage,
);

export const selectLightingZonesPageStateIsLoading = createSelector(
  selectLightingZonesPageState,
  LightingZonesPageState.selectIsLoading,
);

export const selectLightingZonesPageStateError = createSelector(
  selectLightingZonesPageState,
  LightingZonesPageState.selectError,
);

export const selectLightingZonesPageStateSearch = createSelector(
  selectLightingZonesPageState,
  LightingZonesPageState.selectSearchTerm,
);

export const selectFilteredLightingZones = createSelector(
  selectLightingZonesPageStateSearch,
  selectAllLightingZones,
  (searchTerm, zones) => {
    const filter = searchTerm.trim().toLowerCase();
    return zones.filter(zone => zone.name.toLowerCase().includes(filter));
  },
);

export const selectHasFilteredLightingZones = createSelector(
  selectFilteredLightingZones,
  zones => zones.length > 0,
);
