import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RefreshButtonComponent } from './refresh-button.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [RefreshButtonComponent],
  exports: [RefreshButtonComponent],
})
export class RefreshButtonComponentModule {}
