import { Component, Inject, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteSelectorPageActions } from '@spog-ui/site-selector/actions';
import * as CoreState from '@spog-ui/shared/state/core';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

@Component({
  selector: 'spog-site-selector',
  template: `
    @if (hasSites$ | async) {
      <div class="wrapper">
        <spog-site-select-form
          [sites]="sites$ | async"
          (selectSite)="onSelectSite($event)"
          (cancel)="onCancel()"
          >
        </spog-site-select-form>
      </div>
    }
    `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 300px;
      }

      .wrapper {
        width: 100%;
      }

      sui-login-form {
        width: 100%;
        max-width: 420px;
      }
    `,
  ],
})
export class SiteSelectorComponent {
  // is this for site select or a site change
  forSiteChange = false;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) isChange: boolean,
    private store: Store,
  ) {
    this.forSiteChange = isChange;
  }

  hasSites$ = this.store.select(CoreState.selectHasSites);
  sites$ = this.store.select(CoreState.selectSitesForSiteSelector);

  onSelectSite(site: SiteInternalModel) {
    this.store.dispatch(SiteSelectorPageActions.selectAction(site));
  }

  onCancel() {
    if (this.forSiteChange) {
      this.store.dispatch(SiteSelectorPageActions.cancelSiteChangeAction());
    } else {
      this.store.dispatch(SiteSelectorPageActions.cancelSiteSelectAction());
    }
  }
}
