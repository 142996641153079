import { Component } from '@angular/core';
import { selectSiteURLPrefix } from '@spog-ui/shared/state/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'spog-page-not-found',
  template: `
    <sui-empty-state color="default">
      <ng-template suiEmptyStateTitle>404 Not Found</ng-template>
      <ng-template suiEmptyStateMessage>
        The page you are trying to access could not be found.
        <span class="mysteryAndIntrigue">That's all we know.</span>
      </ng-template>
      <ng-template suiEmptyStateActions>
        <a [routerLink]="(urlPrefix$ | async) + '/'" suiButton>Go Home</a>
      </ng-template>
    </sui-empty-state>
  `,
  styles: [
    `
      .mysteryAndIntrigue {
        opacity: 0.4;
      }
    `,
  ],
})
export class PageNotFoundComponent {
  urlPrefix$: Observable<string>;

  constructor(readonly store: Store) {
    this.urlPrefix$ = store.select(selectSiteURLPrefix);
  }
}
