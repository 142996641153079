import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  OrganizationUserApiEffects,
  OrganizationUserPageEffects,
  EditOrganizationUserApiEffects,
  PermissionGroupsPageEffects,
  EmailGroupsPageEffects,
  SitesPageEffects,
} from './lib/effects';
import { AddPermissionGroupApiEffects } from './lib/effects/add-permission-group-api.effects';
import { AddEmailGroupApiEffects } from './lib/effects/add-email-group-api.effects';
import { AdminOrganizationShellPageEffects } from './lib/effects/admin-organization-shell-page.effects';
import { AdminOrganizationShellApiEffects } from './lib/effects/admin-organization-shell-api.effects';
import { EditPermissionGroupApiEffects } from './lib/effects/edit-permission-group-api.effects';
import { PermissionGroupApiEffects } from './lib/effects/permission-group-api.effects';
import { EditEmailGroupApiEffects } from './lib/effects/edit-email-group-api.effects';
import { EmailGroupApiEffects } from './lib/effects/email-group-api.effects';
import { QuickSiteSetupEffects } from './lib/effects/quick-site-setup.effects';
import { orgStateFeatureKey } from './lib/state';
import * as AddOrgUserPageState from './lib/state/add-organization-user-page.reducer';
import * as EditOrgUserPageState from './lib/state/edit-organization-user-page.reducer';
import * as AddPermissionGroupPageState from './lib/state/add-permission-group-page.reducer';
import * as EditPermissionGroupPageState from './lib/state/edit-permission-group-page.reducer';
import * as AddEmailGroupPageState from './lib/state/add-email-group-page.reducer';
import * as EditEmailGroupPageState from './lib/state/edit-email-group-page.reducer';
import * as OrgUsersPageState from './lib/state/organization-users-page.reducer';
import * as OrgUsersState from './lib/state/organization-users.reducer';
import * as PermissionGroupsPageState from './lib/state/permission-groups-page.reducer';
import * as EmailGroupsPageState from './lib/state/email-groups-page.reducer';
import * as RedirectState from './lib/state/redirect.reducer';
import * as SitesPageState from './lib/state/sites-page.reducer';
import * as SitesState from './lib/state/sites.reducer';
import * as AdminOrgShellPageState from './lib/state/admin-organization-shell-page.reducer';
import * as QuickSiteSetupPageState from './lib/state/quick-site-setup-page.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([
      AdminOrganizationShellApiEffects,
      AdminOrganizationShellPageEffects,
      PermissionGroupsPageEffects,
      EmailGroupsPageEffects,
      SitesPageEffects,
      AddPermissionGroupApiEffects,
      EditPermissionGroupApiEffects,
      PermissionGroupApiEffects,
      AddEmailGroupApiEffects,
      EditEmailGroupApiEffects,
      EmailGroupApiEffects,
      OrganizationUserPageEffects,
      OrganizationUserApiEffects,
      EditOrganizationUserApiEffects,
      QuickSiteSetupEffects,
    ]),
    StoreModule.forFeature(orgStateFeatureKey, {
      [AddOrgUserPageState.STATE_KEY]: AddOrgUserPageState.reducer,
      [EditOrgUserPageState.STATE_KEY]: EditOrgUserPageState.reducer,
      [AddPermissionGroupPageState.STATE_KEY]: AddPermissionGroupPageState.reducer,
      [EditPermissionGroupPageState.STATE_KEY]: EditPermissionGroupPageState.reducer,
      [AddEmailGroupPageState.STATE_KEY]: AddEmailGroupPageState.reducer,
      [EditEmailGroupPageState.STATE_KEY]: EditEmailGroupPageState.reducer,
      [OrgUsersPageState.STATE_KEY]: OrgUsersPageState.reducer,
      [OrgUsersState.STATE_KEY]: OrgUsersState.reducer,
      [PermissionGroupsPageState.STATE_KEY]: PermissionGroupsPageState.reducer,
      [EmailGroupsPageState.STATE_KEY]: EmailGroupsPageState.reducer,
      [RedirectState.STATE_KEY]: RedirectState.reducer,
      [SitesPageState.STATE_KEY]: SitesPageState.reducer,
      [SitesState.STATE_KEY]: SitesState.reducer,
      [AdminOrgShellPageState.STATE_KEY]: AdminOrgShellPageState.reducer,
      [QuickSiteSetupPageState.STATE_KEY]: QuickSiteSetupPageState.reducer,
    }),
  ],
  exports: [],
})
export class AdminOrganizationFeatureStateModule {}
