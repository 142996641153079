import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component } from '@angular/core';
import { DimmerComponent } from '../dimmer/dimmer.component';

@Component({
    selector: 'sui-dimmer-control',
    template: `
    <sui-dimmer
      [level]="value"
      (dim)="onDim($event)"
      [isDisabled]="disabled"
    ></sui-dimmer>
  `,
    styles: [
        `
      sui-dimmer {
        padding-right: 16px;
      }
    `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: DimmerControlComponent,
        },
    ],
    standalone: true,
    imports: [DimmerComponent],
})
export class DimmerControlComponent implements ControlValueAccessor {
  /* ControlValueAccessor Implementation */
  onChange: (value: any) => any;
  onTouched: () => any;
  value = 0;
  disabled = false;

  onDim(value: number): void {
    if (this.onChange) {
      this.onChange(value);
      this.value = value;
    }
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}

export const DIMMER_CONTROL_DIRECTIVES = [DimmerControlComponent];
