import { Pipe, PipeTransform } from '@angular/core';
import { ColorSwatch } from './color-picker.models';
import { SwatchRendererService } from './swatch-renderer.service';

@Pipe({
  name: 'clrRenderSwatch',
})
export class RenderSwatchPipe implements PipeTransform {
  constructor(readonly swatchRendererService: SwatchRendererService) {}

  transform(value: ColorSwatch): string | null {
    return value ? this.swatchRendererService.renderToDataUrl(value) : null;
  }
}
