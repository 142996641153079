import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component } from '@angular/core';
import { ColorSliderComponent } from '../color-slider/color-slider.component';

@Component({
    selector: 'sui-color-hue-control',
    template: `
    <sui-color-slider
      [min]="0"
      [max]="360"
      [value]="value"
      [gradient]="hue"
      (update)="updateHue($event)"
    ></sui-color-slider>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ColorHueControlComponent,
        },
    ],
    standalone: true,
    imports: [ColorSliderComponent],
})
export class ColorHueControlComponent implements ControlValueAccessor {
  onChange: (value: any) => any;
  onTouched: () => any;
  value: number;
  disabled = false;
  hue = [
    [0, '#FF0000'],
    [0.16, '#FFFF00'],
    [0.33, '#00FF00'],
    [0.49, '#00FFFF'],
    [0.66, '#0000FF'],
    [0.82, '#FF00FF'],
    [1.0, '#FF0000'],
  ];

  updateHue(hue: number): void {
    this.value = hue;
    this.updateValue(this.value);
  }

  updateValue(value: number): void {
    this.onChange(this.value);
    this.value = value;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}

export const COLOR_HUE_CONTROL_DIRECTIVES = [ColorHueControlComponent];
