import { createAction } from '@ngrx/store';

export const enterAction = createAction(
  '[Bridge 485 Details] Enter',
  (bridge485Id: string) => ({ bridge485Id }),
);

export const closeAction = createAction('[Bridge 485 Details] Close');

export const pingAction = createAction(
  '[Bridge 485 Details] Ping Bridge 485',
  (snapaddr: string) => ({ snapaddr }),
);

export const copyIdAction = createAction(
  '[Bridge 485 Details] Copy ID',
  (bridge485Id: string) => ({ bridge485Id }),
);

export const previewSensorAction = createAction(
  '[Bridge 485 Details] Preview Sensor',
  (sensorId: string) => ({ sensorId }),
);

export const unpreviewSensorAction = createAction(
  '[Bridge 485 Details] Unpreview Sensor',
);

export const deleteAction = createAction(
  '[Bridge 485 Details] Delete',
  (bridge485Id: string) => ({ bridge485Id }),
);

export const confirmDeleteAction = createAction(
  '[Bridge 485 Details] Confirm Delete',
  (bridge485Id: string) => ({ bridge485Id }),
);

export const cancelDeleteAction = createAction('[Bridge 485 Details] Cancel Delete');
