import { createReducer, on } from '@ngrx/store';
import {
  EquipmentProfileApiActions,
  EquipmentProfilePageActions,
} from '@spog-ui/equipment/actions';

export interface Shape {
  isLoading: boolean;
  error?: any;
}

export const initialState: Shape = {
  isLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(EquipmentProfilePageActions.enterAction, (state): Shape => {
    return { ...initialState };
  }),
  on(EquipmentProfilePageActions.loadPageModelsFromGQL, (state): Shape => {
    return { ...state, isLoading: true };
  }),
  on(EquipmentProfileApiActions.loadSuccessAction, (state): Shape => {
    return { ...state, isLoading: false };
  }),
  on(EquipmentProfileApiActions.loadFailureAction, (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
