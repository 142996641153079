import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-pinwheel',
  template: `
    <p class="bfDescription">
      All lights in the zone "spin". For use with 4-segment light fixtures.
    </p>
  `,
})
export class BFPinwheelComponent implements BehaviorForm {
  form = new UntypedFormControl(null);
}
