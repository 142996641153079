import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SuiEmptyStateModule } from '@spog-ui/shared/components';
import { UtilityServiceLoadingErrorComponent } from './utility-service-loading-error.component';

@NgModule({
  imports: [CommonModule, SuiEmptyStateModule],
  declarations: [UtilityServiceLoadingErrorComponent],
  exports: [UtilityServiceLoadingErrorComponent],
})
export class UtilityServiceLoadingErrorComponentModule {}
