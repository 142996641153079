import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

export const PERMISSIONS = new InjectionToken<Observable<string[]>>('PERMISSIONS');

@Injectable({
  providedIn: 'root',
})
export class AvailablePermissionsService {
  constructor(
    @Optional()
    @Inject(PERMISSIONS)
    private permissions?: Observable<string[]>,
  ) {}

  includes(permissions: string[]) {
    if (this.hasRealPermissions()) {
      return this.withActualPermissions(permissions);
    }

    return this.withFakePermissions();
  }

  private hasRealPermissions() {
    return Boolean(this.permissions);
  }

  private withActualPermissions(permissions: string[]) {
    if (!this.permissions) throw new Error('permissions is not defined');
    return this.permissions.pipe(
      switchMap(loadedPermissions => {
        return of(
          loadedPermissions.some(permission => permissions.indexOf(permission) >= 0),
        );
      }),
    );
  }

  private withFakePermissions() {
    return of(true);
  }
}
