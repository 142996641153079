import { createAction } from '@ngrx/store';
import { QuickSiteSetupFormModel } from '@spog-ui/shared/models/organizations';
import { OrganizationSiteInternalModel } from '@spog-ui/shared/models/sites';
import { OrganizationUserInternalModel } from '@spog-ui/shared/models/organization-users';

export const enterAction = createAction('[Admin / Quick Site Setup Page] Enter');

export const quickSiteSetupAction = createAction(
  '[Quick Site Setup] Quick Site Setup',
  (quickSiteSetup: QuickSiteSetupFormModel) => ({ quickSiteSetup }),
);

export const loadPageSuccessAction = createAction('[Quick Site Setup] Load Page Success');

export const loadPageFailureAction = createAction(
  '[Quick Site Setup] Load Page Failure',
  (error: string) => ({ error }),
);
