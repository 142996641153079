import { createAction } from '@ngrx/store';
import { BehaviorInternalModel } from '@spog-ui/shared/models/behaviors';
import { LightZoneInternalModel } from '@spog-ui/shared/models/light-zones';
import { LightInternalModel } from '@spog-ui/shared/models/lights';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

export const loadLightZoneDetailsPageModelsSuccessAction = createAction(
  '[Light Zone Details API] Load Light Zone Details Models Success',
  (models: {
    controlZone: ZoneInternalModel;
    lightZones: LightZoneInternalModel[];
    lights: LightInternalModel[];
    scenes: SceneInternalModel[];
    behaviors: BehaviorInternalModel[];
  }) => ({
    models,
  }),
);

export const loadLightZoneDetailsPageModelsFailureAction = createAction(
  '[Light Zone Details API] Load Light Zone Details Models Failure',
  (error: string) => ({
    error,
  }),
);
