import { createReducer, on } from '@ngrx/store';
import {
  UtilityServicesApiActions,
  UtilityServicesPageActions,
} from '@spog-ui/utility-services/actions';

export interface Shape {
  isLoading: boolean;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(
    UtilityServicesPageActions.enterAction,
    (): Shape => {
      return initialState;
    },
  ),
  on(
    UtilityServicesApiActions.loadSuccessAction,
    (): Shape => {
      return { isLoading: false };
    },
  ),
  on(UtilityServicesApiActions.loadFailureAction, (state, action) => {
    return { isLoading: false, error: action.error };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
