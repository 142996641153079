import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SPINNER_DIRECTIVES } from './spinner.component';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, SPINNER_DIRECTIVES],
  exports: [SPINNER_DIRECTIVES],
})
export class SuiSpinnerModule {}

export * from './spinner.component';
