import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[suiButton], a[suiButton]',
  template: `
    <span>
      <ng-content></ng-content>
    </span>
  `,
})
export class ButtonComponent {}

export const BUTTON_DIRECTIVES = [ButtonComponent];
