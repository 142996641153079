import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { ScheduleUtilityRateFormModule } from '../schedule-utility-rate-form';
import { ScheduleUtilityRateModalComponent } from './schedule-utility-rate-modal.component';

@NgModule({
  imports: [CommonModule, MatDialogModule, ScheduleUtilityRateFormModule],
  declarations: [ScheduleUtilityRateModalComponent],
  exports: [ScheduleUtilityRateModalComponent],
})
export class ScheduleUtilityRateModalComponentModule {}
