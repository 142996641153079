import { Component, Input } from '@angular/core';
import { ActiveSequenceSceneViewModel } from '@spog-ui/shared/models/active-sequence-scenes';
import { DateTime } from 'luxon';

@Component({
  selector: 'scn-active-sequence-scenes',
  template: `
    <sui-accordion id="accordionHeader" [open]="isOpen" (toggle)="toggleOpen()">
      <ng-template suiAccordionHeader>
        <div>Active Sequence Scenes</div>
      </ng-template>
    
      <ng-template suiAccordionBody>
        @for (activeSequenceScene of activeSequenceScenes; track activeSequenceScene) {
          <div
            class="activeSequenceScene"
            >
            <span class="activeSceneName">{{ activeSequenceScene.sequenceSceneName }}</span>
            @if (
              isTimestampAfterNow(activeSequenceScene.nextStepTimestamp)) {
              <span
                class="timeAndNextScene"
                >applies {{ activeSequenceScene.nextStepSceneName }} in
                <sui-countdown
                  [timestamp]="activeSequenceScene.nextStepTimestamp"
                  ></sui-countdown
                ></span>
              } @else {
                <span class="timeAndNextScene"> Completed </span>
              }
            </div>
          }
    
          @if (!activeSequenceScenes.length) {
            <p>There are no active sequence scenes</p>
          }
        </ng-template>
      </sui-accordion>
    `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }

      :host sui-accordion ::ng-deep .suiAccordionHeader.open {
        color: var(--color-contrast-500);
      }

      :host sui-accordion ::ng-deep .suiAccordionBody {
        padding-bottom: 0px;
      }

      :host sui-accordion ::ng-deep .suiAccordionFooter {
        padding-bottom: 0px;
      }

      :host sui-accordion ::ng-deep .suiAccordionHeader:after {
        border-left: 6px solid var(--color-foreground-secondary-text);
      }

      .activeSequenceScene {
        font-size: 14px;
        display: flex;
        flex-direction: column;
      }

      .activeSceneName {
        padding-left: 26px;
      }

      .timeAndNextScene {
        padding-left: 26px;
        padding-bottom: 16px;
        color: var(--color-foreground-secondary-text);
      }

      sui-accordion {
        display: block;
        margin-bottom: 16px;
        background-color: var(--color-background-card);
      }

      @media screen and (max-width: 500px) {
        :host {
        }
      }

      p {
        font-size: 14px;
        font-style: italic;
        margin: 0;
        color: var(--color-foreground-secondary-text);
        padding-left: 26px;
      }
    `,
  ],
})
export class ActiveSequenceScenesComponent {
  public isOpen: boolean;

  @Input()
  activeSequenceScenes: ActiveSequenceSceneViewModel[];

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  isTimestampAfterNow(isoTimestamp: string): boolean {
    return isoTimestamp > DateTime.utc().toISO();
  }
}
