import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { OrganizationUserInternalModel } from '@spog-ui/shared/models/organization-users';
import { OrgAdminUsersStateActions } from '../actions';
import { EntityState } from '@ngrx/entity';

export type Shape = EntityState<OrganizationUserInternalModel>;

export const STATE_KEY = 'organization-users';

export const adapter = createEntityAdapter({
  selectId: (model: OrganizationUserInternalModel) => model.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(OrgAdminUsersStateActions.loadUsersSuccessAction, (state, action) => {
    return adapter.setAll(action.users, state);
  }),
  on(OrgAdminUsersStateActions.removeOrganizationUserSuccessAction, (state, action) => {
    return adapter.removeOne(action.userId, state);
  }),
);

export const { selectEntities, selectIds, selectAll } = adapter.getSelectors();
