import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import { findBoundingBox } from '@spog-ui/map-tools';
import {
  getClimateLegendViewModel,
  isThermostatAlarmed,
  isThermostatPrecommissioned,
  PositionedThermostatModel,
} from '@spog-ui/shared/models/thermostats';
import * as FilterState from './filter';
import * as StatusTrayState from './status-tray';
import * as ClimateLayerState from './climate-layer';

export const STATE_KEY = 'climateMap';

export interface Shape {
  filter: FilterState.Shape;
  statusTray: StatusTrayState.Shape;
  climateLayer: ClimateLayerState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  filter: FilterState.reducer,
  statusTray: StatusTrayState.reducer,
  climateLayer: ClimateLayerState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Map State
 */
export const selectPositionedThermostats = createSelector(
  CoreState.selectAllThermostats,
  thermostats =>
    thermostats.filter<PositionedThermostatModel>(
      (thermostat): thermostat is PositionedThermostatModel =>
        thermostat.floorPlanId !== null &&
        thermostat.floorPlanX !== null &&
        thermostat.floorPlanY !== null,
    ),
);

export const selectHasPositionedThermostats = createSelector(
  selectPositionedThermostats,
  positionedThermostats => positionedThermostats.length > 0,
);

export const selectClimateLegendView = createSelector(
  selectPositionedThermostats,
  CoreState.selectThermostatAlarmsLookupTableByThermostatId,
  (thermostatViews, alarms) => getClimateLegendViewModel(thermostatViews, alarms),
);

export const selectPositionedThermostatNames = createSelector(
  selectPositionedThermostats,
  thermostats => {
    return thermostats.map(thermostat => thermostat.name);
  },
);

/**
 * Climate Map Layer State
 */
export const selectClimateMapLayerState = createSelector(
  selectFeatureState,
  state => state.climateLayer,
);

export const selectClimateMapLayerError = createSelector(
  selectClimateMapLayerState,
  ClimateLayerState.selectError,
);

export const selectClimateMapLayerLoading = createSelector(
  selectClimateMapLayerState,
  ClimateLayerState.selectIsLoading,
);

/**
 * Filter State
 */
export const selectClimateMapFilterState = createSelector(
  selectFeatureState,
  state => state.filter,
);
export const selectClimateMapSearchTerm = createSelector(
  selectClimateMapFilterState,
  FilterState.selectSearchTerm,
);
export const selectClimateMapZoneThermostatIdsForPreview = createSelector(
  selectClimateMapFilterState,
  FilterState.selectPreviewZoneThermostatsIds,
);
export const selectClimateMapZoneThermostatIdForPreview = createSelector(
  selectClimateMapFilterState,
  FilterState.selectPreviewZoneThermostatId,
);
export const selectClimateMapSceneIdForPreview = createSelector(
  selectClimateMapFilterState,
  FilterState.selectPreviewSceneId,
);

export const selectClimateMapSceneThermostatIdsForPreview = createSelector(
  selectPositionedThermostats,
  CoreState.selectAllClimateZones,
  CoreState.selectAllScenes,
  selectClimateMapSceneIdForPreview,
  (thermostats, zones, scenes, sceneId) => {
    const scene = scenes.find(scene => scene.id === sceneId);

    if (!scene) return [];

    const zoneIdsInScene = scene.zoneBehaviors.map(
      sceneZoneBehavior => sceneZoneBehavior.zoneId,
    );
    const thermostatIdsInScene = zones
      .filter(zone => zoneIdsInScene.includes(zone.id))
      .flatMap(zone => zone.deviceIds);
    const positionedThermostatsInScene = thermostats
      .filter(thermostat => thermostatIdsInScene.includes(thermostat.id))
      .map(thermostat => thermostat.id);

    return positionedThermostatsInScene;
  },
);
export const selectClimateMapThermostatIdsForPreview = createSelector(
  selectClimateMapZoneThermostatIdsForPreview,
  selectClimateMapSceneThermostatIdsForPreview,
  (zoneThermostatIds, sceneThermostatIds) => {
    console.assert(
      !(zoneThermostatIds.length > 0 && sceneThermostatIds.length > 0),
      'Zone and scene both had preview IDs; this should not be possible. Arbitrarily preferring zone preview.',
    );

    if (zoneThermostatIds.length > 0) return zoneThermostatIds;
    if (sceneThermostatIds.length > 0) return sceneThermostatIds;
    return [];
  },
);

/**
 * Status Tray State
 */
export const selectClimateMapStatusTrayState = createSelector(
  selectFeatureState,
  state => state.statusTray,
);

export const selectClimateStatusTrayIsFilteringOk = createSelector(
  selectClimateMapStatusTrayState,
  StatusTrayState.selectIsFilteringOk,
);

export const selectClimateStatusTrayIsFilteringAlarmed = createSelector(
  selectClimateMapStatusTrayState,
  StatusTrayState.selectIsFilteringAlarmed,
);

export const selectClimateStatusTrayIsFilteringPrecommissioned = createSelector(
  selectClimateMapStatusTrayState,
  StatusTrayState.selectIsFilteringPrecommissioned,
);

export const selectFilteredThermostatsForTheMap = createSelector(
  selectPositionedThermostats,
  selectClimateStatusTrayIsFilteringOk,
  selectClimateStatusTrayIsFilteringAlarmed,
  selectClimateStatusTrayIsFilteringPrecommissioned,
  selectClimateMapSearchTerm,
  selectClimateMapThermostatIdsForPreview,
  CoreState.selectThermostatAlarmsLookupTableByThermostatId,
  (
    thermostats,
    filteringOk,
    filteringAlarmed,
    filteringPrecommissioned,
    searchTerm,
    selectedThermostatsForPreview,
    thermostatAlarms,
  ) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return thermostats.filter(thermostat => {
      const isPrecommissioned = isThermostatPrecommissioned(thermostat);
      const isAlarming = isThermostatAlarmed(thermostatAlarms[thermostat.id]);

      // if thermostat is not included in the thermostats that were selected for preview, skip this one
      if (
        selectedThermostatsForPreview.length > 0 &&
        !selectedThermostatsForPreview.some(t => t === thermostat.id)
      ) {
        return false;
      }

      // if filtering for OK status
      if ((isPrecommissioned || isAlarming) && filteringOk) {
        return false;
        // if filtering for precommissioned status
      } else if (!isPrecommissioned && filteringPrecommissioned) {
        return false;
        // if filtering for alarmed status
      } else if (!isAlarming && filteringAlarmed) {
        return false;
        // if filtering by search term
      } else if (!thermostat.name.toLowerCase().includes(lowerCaseSearchTerm)) {
        return false;
      }

      return true;
    });
  },
);

export const selectBoxAroundFilteredClimateDevices = createSelector(
  selectFilteredThermostatsForTheMap,
  selectPositionedThermostats,
  (filteredDevices, allDevices) => {
    const devices = filteredDevices.length > 0 ? filteredDevices : allDevices;

    // we are taking devices with floorPlanX and floorPlanY as inputs, but findBoundingBox doesn't know that
    const deviceLocations = devices
      .map(device => ({ floorPlanX: device.floorPlanX, floorPlanY: device.floorPlanY }))
      .filter(
        (floorPlans): floorPlans is { floorPlanX: number; floorPlanY: number } =>
          !!floorPlans.floorPlanX && !!floorPlans.floorPlanY,
      );

    return findBoundingBox(deviceLocations);
  },
);
