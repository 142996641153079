import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OrganizationApiService } from '../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, map, switchMap, tap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import {
  OrganizationsPageActions,
  OrganizationApiActions,
  AddOrganizationPageActions,
  EditOrganizationPageActions,
} from '../actions';
import { RemoteAccessStateActions } from '@spog-ui/shared/state/remote-access';
import { OrganizationStateActions } from '../actions';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  toCreateOrganizationInputFromOrganizationForm,
  toUpdateOrganizationInputFromOrganizationForm,
  toOrganizationInternalModelFromGQL,
} from '@spog-ui/shared/models/organizations';
import { Location } from '@angular/common';
import { PromptService } from '@sui/prompt';
import { toSiteInternalModelFromGQL } from '@spog-ui/shared/models/sites';

@Injectable()
export class OrganizationApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly organizationApiService: OrganizationApiService,
    readonly gql: GraphQLAPIService,
    readonly snackbar: MatSnackBar,
    readonly location: Location,
    readonly promptService: PromptService,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsPageActions.enterAction),
      switchMap(() =>
        this.gql.getAllOrganizationsWithSitesAsAdmin().pipe(
          map(data => {
            const organizations = data.allOrganizations.map(
              toOrganizationInternalModelFromGQL,
            );
            const sites = data.allOrganizations.flatMap(organization =>
              organization.sites.edges.map(edge => toSiteInternalModelFromGQL(edge.node)),
            );

            return OrganizationsPageActions.loadPageSuccessAction(organizations, sites);
          }),
          catchError((response: HttpErrorResponse) =>
            of(
              OrganizationsPageActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  loadOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsPageActions.loadPageSuccessAction),
      map(action => {
        return OrganizationStateActions.loadOrganizationsForAdminSectionSuccessAction(
          action.organizations,
        );
      }),
    ),
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsPageActions.loadPageSuccessAction),
      map(action => {
        return RemoteAccessStateActions.loadAdminSitesSuccessAction(action.sites);
      }),
    ),
  );

  createOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddOrganizationPageActions.addOrganizationAction),
      mergeMap(action => {
        const input = toCreateOrganizationInputFromOrganizationForm(action.organization);

        return this.gql.createOrganization({ input }).pipe(
          map(() => OrganizationApiActions.createOrganizationSuccessAction()),
          catchError(error => {
            return of(OrganizationApiActions.createOrganizationFailureAction(error));
          }),
        );
      }),
    ),
  );

  redirectToOrganizationsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          OrganizationApiActions.createOrganizationSuccessAction,
          OrganizationApiActions.updateOrganizationSuccessAction,
        ),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  createOrganizationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationApiActions.createOrganizationFailureAction),
        tap(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );

  editOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditOrganizationPageActions.editOrganizationAction),
      switchMap(action => {
        const input = toUpdateOrganizationInputFromOrganizationForm(action.organization);
        return this.gql.updateOrganization({ input }).pipe(
          map(() => OrganizationApiActions.updateOrganizationSuccessAction()),
          catchError(error => {
            return of(OrganizationApiActions.updateOrganizationFailureAction(error));
          }),
        );
      }),
    ),
  );

  editOrganizationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationApiActions.updateOrganizationFailureAction),
        tap(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationsPageActions.confirmDeleteAction),
      mergeMap(({ organization }) =>
        this.organizationApiService.delete(organization.id).pipe(
          map(() =>
            OrganizationStateActions.deleteOrganizationForAdminSectionAction(
              organization,
            ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              OrganizationApiActions.deleteOrganizationFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  notifyOnDeleteError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationApiActions.deleteOrganizationFailureAction),
        tap(() =>
          this.snackbar.open(
            'Failed to delete the organization. Please try again',
            'Dismiss',
            {
              duration: 7_500,
            },
          ),
        ),
      ),
    { dispatch: false },
  );
}
