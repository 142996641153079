import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppUpdatesActions } from '@spog-ui/app-updates/actions';
import * as CoreState from '@spog-ui/shared/state/core';

@Component({
  selector: 'spog-app-update',
  template: `
    @if (isUpdateAvailable$ | async) {
      <div
        class="appUpdateStatus"
        id="downloadUpdateBtn"
        mat-flat-button
        (click)="downloadUpdate()"
        >
        <span>New Update Available</span>
        <mat-icon svgIcon="download"></mat-icon>
      </div>
    }
    
    @if (isDownloadingUpdate$ | async) {
      <div
        class="appUpdateStatus"
        mat-flat-button
        id="downloadingUpdateBtn"
        >
        <span>Downloading Update...</span>
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
    }
    
    @if (isUpdateDownloaded$ | async) {
      <div
        id="reloadBtn"
        mat-flat-button
        (click)="reload()"
        class="appUpdateStatus"
        >
        <span>Refresh to Apply Update</span>
        <span>
          <mat-icon>refresh</mat-icon>
        </span>
      </div>
    }
    `,
  styles: [
    `
      .appUpdateStatus {
        display: flex;
        width: 100%;
        font-size: 12px;
        color: var(--color-100);
        border: none;
        outline: none;
        border-radius: 4px;
        padding: 0 12px;
        line-height: 42px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        bottom: 55px;
        left: 15px;
      }

      .appUpdateStatus:before {
        content: '';
        background-color: var(--color-500);
        border-radius: 4px;
        opacity: 0.25;
        position: absolute;
        left: 15px;
        width: 248px;
        height: 42px;
        z-index: -1;
      }

      svg {
        margin-left: 6px;
      }

      .mat-spinner::ng-deep circle {
        stroke: var(--color-100);
      }

      mat-icon {
        display: inline-flex;
        vertical-align: middle;
        height: auto;
        width: auto;
      }
    `,
  ],
})
export class AppUpdateComponent {
  isUpdateAvailable$: Observable<boolean>;
  isDownloadingUpdate$: Observable<boolean>;
  isUpdateDownloaded$: Observable<boolean>;

  constructor(private store: Store) {
    this.isUpdateAvailable$ = store.select(CoreState.selectIsUpdateAvailable);
    this.isDownloadingUpdate$ = store.select(CoreState.selectIsDownloadingUpdate);
    this.isUpdateDownloaded$ = store.select(CoreState.selectIsUpdateDownloaded);
  }

  downloadUpdate() {
    this.store.dispatch(AppUpdatesActions.downloadUpdate());
  }

  reload() {
    this.store.dispatch(AppUpdatesActions.reloadApp());
  }
}
