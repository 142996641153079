import { NgModule } from '@angular/core';
import { Backoff } from './backoff.service';
import {
  SocketConnectionFactory,
  SOCKET_FACTORY,
} from './socket-connection-factory.service';
import { SocketConnection } from './socket-connection.service';

@NgModule({
  providers: [
    Backoff,
    SocketConnectionFactory,
    { provide: SOCKET_FACTORY, useValue: WebSocket },
    SocketConnection,
  ],
})
export class SocketServicesModule {}

export * from './backoff.service';
export * from './socket-connection-factory.service';
export * from './socket-connection.service';
