import { createReducer, on } from '@ngrx/store';
import {
  DepartmentsApiActions,
  EditDepartmentPageActions,
} from '@spog-ui/departments/actions';

export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  loadingError?: any;
  departmentId: string | null;
}

export const initialState: Shape = {
  isLoading: true,
  isSubmitting: false,
  departmentId: null,
};

export const reducer = createReducer(
  initialState,
  on(
    EditDepartmentPageActions.enterAction,
    (state, action): Shape => {
      return {
        ...initialState,
        departmentId: action.departmentId,
      };
    },
  ),
  on(
    DepartmentsApiActions.loadFormDataSuccessAction,
    (state): Shape => {
      return { ...state, isLoading: false, isSubmitting: false };
    },
  ),
  on(
    DepartmentsApiActions.loadFormDataFailureAction,
    (state, action): Shape => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
        loadingError: action.error,
      };
    },
  ),
  on(
    EditDepartmentPageActions.editDepartmentSubmitAction,
    (state): Shape => {
      return { ...state, isLoading: false, isSubmitting: true };
    },
  ),
  on(
    DepartmentsApiActions.editDepartmentFailure,
    (state): Shape => {
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
      };
    },
  ),
  on(
    DepartmentsApiActions.editDepartmentSuccess,
    (state): Shape => {
      return { ...state, isLoading: false, isSubmitting: false };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
export const selectLoadingError = (state: Shape) => state.loadingError;
export const selectActiveId = (state: Shape) => state.departmentId;
