import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DimmerControlComponent } from '@spog-ui/shared/components';
import { TimeoutControlComponentModule } from '../timeout-control';
import { BFPhotocellOnlyComponent } from './bf-photocell-only.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DimmerControlComponent,
    TimeoutControlComponentModule,
  ],
  declarations: [BFPhotocellOnlyComponent],
  exports: [BFPhotocellOnlyComponent],
})
export class BFPhotocellOnlyComponentModule {}
