import { createAction } from '@ngrx/store';
import { BehaviorInternalModel } from '@spog-ui/shared/models/behaviors';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

export const loadLightZonesPageModelsSuccessAction = createAction(
  '[Light Zones API] Load Light Zones Page Models Success',
  (models: { controlZones: ZoneInternalModel[]; behaviors: BehaviorInternalModel[] }) => {
    return { models };
  },
);

export const loadLightZonesPageModelsFailureAction = createAction(
  '[Light Zones API] Load Light Zones Page Models Failure',
  (error: string) => ({ error }),
);
