import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { LightZoneDetailsAPIActions } from '@spog-ui/light-zone-details/actions';
import { ScheduleApiActions } from '@spog-ui/schedule/actions';
import { ScenesApiActions } from '@spog-ui/scenes/actions';
import { LightMapLayerApiActions } from '@spog-ui/light-map-layer/actions';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';
import { ThermostatDetailsApiActions } from '@spog-ui/thermostat-details/actions';

export type Shape = EntityState<SceneInternalModel>;

export const adapter = createEntityAdapter({
  sortComparer: sortByName,
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    LightZoneDetailsAPIActions.loadLightZoneDetailsPageModelsSuccessAction,
    LightMapLayerApiActions.loadLightMapLayerDeferredModelsSuccessAction,
    ScenesApiActions.loadSuccessAction,
    ScheduleApiActions.loadSuccessAction,
    ThermostatDetailsApiActions.loadThermostatDetailsModelsSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.scenes, state);
    },
  ),
  on(
    ScenesApiActions.addSceneSuccessAction,
    ScenesApiActions.duplicateSceneSuccessAction,
    (state, action) => {
      return adapter.addOne(action.scene, state);
    },
  ),
  on(ScenesApiActions.deleteSceneSuccessAction, (state, action) => {
    return adapter.removeOne(action.sceneId, state);
  }),
);

export function sortByName(a: SceneInternalModel, b: SceneInternalModel) {
  return a.name.localeCompare(b.name);
}

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
