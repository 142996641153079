import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BehaviorsModule } from '@spog-ui/behaviors';
import { ButtonComponent, SuiCardModule } from '@spog-ui/shared/components';
import { RemoveBehaviorIdTokenPipeModule } from '@spog-ui/shared/pipes/remove-behavior-id-token';
import { ZoneMultiselectComponentModule } from '../zone-multiselect';
import { ZoneListComponentModule } from '../zone-list';
import { ZoneBehaviorControlValidatorDirective } from './zone-behavior-control-validator.directive';
import { ZoneBehaviorControlComponent } from './zone-behavior-control.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RemoveBehaviorIdTokenPipeModule,
    BehaviorsModule,
    SuiCardModule,
    ButtonComponent,
    ZoneMultiselectComponentModule,
    ZoneListComponentModule,
  ],
  declarations: [ZoneBehaviorControlValidatorDirective, ZoneBehaviorControlComponent],
  exports: [ZoneBehaviorControlValidatorDirective, ZoneBehaviorControlComponent],
})
export class ZoneBehaviorControlComponentModule {}
