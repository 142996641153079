import { createReducer, on } from '@ngrx/store';
import {
  EquipmentMapLayerActions,
  EquipmentMapLayerApiActions,
} from '@spog-ui/equipment-map-layer/actions';

export interface Shape {
  error: any;
  loading: boolean;
}

export const initialState: Shape = {
  error: null,
  loading: false,
};

export const reducer = createReducer(
  initialState,

  on(EquipmentMapLayerActions.loadInitialModelsFromGQL, state => ({
    ...state,
    loading: true,
  })),

  on(EquipmentMapLayerApiActions.loadInitialModelsSuccessAction, state => ({
    ...state,
    loading: false,
    error: null,
  })),

  on(EquipmentMapLayerApiActions.loadInitialModelsFailureAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
);

export const selectError = (state: Shape) => state.error;
export const selectLoading = (state: Shape) => state.loading;
