import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as ClimateState from './climate.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(ClimateState.STATE_KEY, ClimateState.reducers),
  ],
})
export class ClimateStateModule {}
