import { createAction } from '@ngrx/store';
import { Bridge485InternalModel } from '@spog-ui/shared/models/bridge-485s';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';
import { Bridge485TemplateInternalModel } from '@spog-ui/shared/models/bridge-485-templates';

export const loadAddPageModelsSuccessAction = createAction(
  '[Add Bridge 485 Page / API] Load Add Models Success',
  (
    bridge485s: Bridge485InternalModel[],
    indieSensors: IndieSensorInternalModel[],
    siteControllers: SiteControllerInternalModel[],
    templates: Bridge485TemplateInternalModel[],
  ) => ({
    models: { bridge485s, indieSensors, siteControllers, templates },
  }),
);

export const loadAddPageModelsFailureAction = createAction(
  '[Add Bridge 485 Page / API] Load Add Models Failure',
  (error: string) => ({ error }),
);

export const createSuccessAction = createAction(
  '[Add Bridge 485 Page / API] Create Bridge 485 Success',
  (bridge485: Bridge485InternalModel) => ({ bridge485 }),
);

export const createFailureAction = createAction(
  '[Add Bridge 485 Page / API] Create Bridge 485 Failure',
  (error: string) => ({ error }),
);

export const loadEditPageModelsSuccessAction = createAction(
  '[Edit Bridge 485 Page / API] Load Edit Models Success',
  (
    bridge485s: Bridge485InternalModel[],
    indieSensors: IndieSensorInternalModel[],
    siteControllers: SiteControllerInternalModel[],
  ) => ({
    models: { bridge485s, indieSensors, siteControllers },
  }),
);

export const loadEditPageModelsFailureAction = createAction(
  '[Edit Bridge 485 Page / API] Load Edit Models Failure',
  (error: string) => ({ error }),
);

export const updateSuccessAction = createAction(
  '[Edit Bridge 485 Page / API] Update Bridge 485 Success',
  (bridge485: Bridge485InternalModel) => ({ bridge485 }),
);

export const updateFailureAction = createAction(
  '[Edit Bridge 485 Page / API] Update Bridge 485 Failure',
  (error: string) => ({ error }),
);
