import { createReducer, on } from '@ngrx/store';
import {
  ClimateZonesApiActions,
  ClimateZonesPageActions,
} from '@spog-ui/climate-zones/actions';

export interface Shape {
  isLoading: boolean;
  searchTerm: string;
  isEditingOrAdding: boolean;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
  isEditingOrAdding: false,
  searchTerm: '',
};

export const reducer = createReducer(
  initialState,
  on(
    ClimateZonesPageActions.enterAction,
    (state): Shape => {
      return {
        ...initialState,
        searchTerm: state.searchTerm,
      };
    },
  ),
  on(
    ClimateZonesApiActions.loadClimateZonesPageModelsSuccessAction,
    (state): Shape => {
      return { ...state, isLoading: false };
    },
  ),
  on(
    ClimateZonesApiActions.loadClimateZonesPageModelsFailureAction,
    (state, action): Shape => {
      return { ...state, isLoading: false, error: action.error };
    },
  ),
  on(
    ClimateZonesPageActions.searchAction,
    (state, action): Shape => {
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectSearchTerm = (state: Shape) => state.searchTerm;
