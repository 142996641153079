import { createSelector } from '@ngrx/store';
import { filterBy } from '@spog-ui/utils/filter';
import { selectFeature } from './constants';
import {
  selectError,
  selectIsLoading,
  selectPageIndex,
  selectPageSize,
  selectSearchTerm,
  STATE_KEY,
} from './organization-users-page.reducer';
import { selectAllOrgAdminUsers } from './organization-users.selectors';

export const selectFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsOrganizationUsersPageLoading = createSelector(
  selectFeatureState,
  selectIsLoading,
);

export const selectOrganizationUsersPageError = createSelector(
  selectFeatureState,
  selectError,
);

export const selectOrganizationUsersPageFilter = createSelector(
  selectFeatureState,
  selectSearchTerm,
);

export const selectOrganizationUsersPageIndex = createSelector(
  selectFeatureState,
  selectPageIndex,
);

export const selectOrganizationUsersPageSize = createSelector(
  selectFeatureState,
  selectPageSize,
);

export const selectAllUsersForOrgAdminUsersPage = createSelector(
  selectAllOrgAdminUsers,
  users => users,
);

export const selectShowOrganizationUsersPageGettingStartedCard = createSelector(
  selectAllUsersForOrgAdminUsersPage,
  users => users.length === 0,
);

export const selectFilteredOrganizationUsers = createSelector(
  selectAllOrgAdminUsers,
  selectOrganizationUsersPageFilter,
  (users, filter) => {
    if (!filter) return users;
    return users.filter(user => {
      const searchTerm = filter.trim().toLowerCase();
      return filterBy(searchTerm, [user.name.toLowerCase(), user.email.toLowerCase()]);
    });
  },
);

export const selectOrganizationUsersPageLength = createSelector(
  selectFilteredOrganizationUsers,
  users => users.length,
);

export const selectPaginatedOrganizationUsers = createSelector(
  selectFilteredOrganizationUsers,
  selectOrganizationUsersPageIndex,
  selectOrganizationUsersPageSize,
  (users, index, size) => {
    return users.slice(index * size, index * size + size);
  },
);
