import { createSelector } from '@ngrx/store';
import * as AddOrgPageState from './add-organization-page.reducer';
import { selectAddOrgsPage } from './admin-section-organizations.feature-selector';

export const selectIsAddOrgPageLoading = createSelector(
  selectAddOrgsPage,
  AddOrgPageState.selectIsLoading,
);

export const selectAddOrgPageError = createSelector(
  selectAddOrgsPage,
  AddOrgPageState.selectError,
);

export const selectIsAddOrgPageSubmitting = createSelector(
  selectAddOrgsPage,
  AddOrgPageState.selectIsSubmitting,
);
