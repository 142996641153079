import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';
import { DEFAULT_COLOR } from '@sui/color-picker';

@Component({
  selector: 'sui-bf-basic-color',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">Set all lights in a zone to a specified color.</p>

      <div class="bfForm">
        <clr-color-picker
          [formControl]="form.controls['color']"
          mode="bytes"
        ></clr-color-picker>
      </div>
    </div>
  `,
})
export class BFBasicColorComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    color: new UntypedFormControl(DEFAULT_COLOR),
  });
  get currentColor() {
    return this.form.value.color;
  }

  getBehaviorParameters() {
    return {
      bytesRed: this.currentColor[0],
      bytesGreen: this.currentColor[1],
      bytesBlue: this.currentColor[2],
      bytesAmber: this.currentColor[3],
      bytesWhite: this.currentColor[4],
    };
  }

  writeBehaviorParameters(parameters: any) {
    this.form.setValue({
      color: [
        parameters.bytesRed,
        parameters.bytesGreen,
        parameters.bytesBlue,
        parameters.bytesAmber,
        parameters.bytesWhite,
      ],
    });
  }
}
