import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { ApplySceneButtonComponentModule } from '@spog-ui/scenes';
import { LightScenesCardComponent } from './light-scenes-card.component';

@NgModule({
  imports: [
    CommonModule,
    ApplySceneButtonComponentModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    PermissionsModule,
  ],
  declarations: [LightScenesCardComponent],
  exports: [LightScenesCardComponent],
})
export class LightScenesCardComponentModule {}
