import { Type } from '@angular/core';
import { BehaviorForm, BehaviorType } from '@spog-ui/shared/models/behaviors';
import { BFBasicColorComponent } from './bf-basic-color';
import { BFColorPaparazziComponent } from './bf-color-paparazzi';
import { BFColorRandomComponent } from './bf-color-random';
import { BFColorSparkleComponent } from './bf-color-sparkle';
import { BFDimmerComponent } from './bf-dimmer';
import { BFDLHComponent } from './bf-dlh';
import { BFNoneComponent } from './bf-none';
import { BFOccupancyOnlyComponent } from './bf-occupancy-only';
import { BFOffComponent } from './bf-off';
import { BFOnComponent } from './bf-on';
import { BFPaparazziComponent } from './bf-paparazzi';
import { BFPhotocellAndOccupancyComponent } from './bf-photocell-and-occupancy';
import { BFPhotocellAndSwitchComponent } from './bf-photocell-and-switch';
import { BFPhotocellOnlyComponent } from './bf-photocell-only';
import { BFPinwheelComponent } from './bf-pinwheel';
import { BFPinwheel5Component } from './bf-pinwheel-5';
import { BFRandomComponent } from './bf-random';
import { BFSparkleComponent } from './bf-sparkle';
import { BFSwitchAndOccupancyComponent } from './bf-switch-and-occupancy';
import { BFSwitchAndVacancyComponent } from './bf-switch-and-vacancy';
import { BFSwitchOnlyComponent } from './bf-switch-only';
import { BFSwitchPhotocellAndOccupancyComponent } from './bf-switch-photocell-and-occupancy';
import { BFSwitchPhotocellAndVacancyComponent } from './bf-switch-photocell-and-vacancy';
import { BFSwitchWithBlinkComponent } from './bf-switch-with-blink';
import { BFPaparazziMonochromeComponent } from './bf-paparazzi-monochrome';
import { BFSparkleMonochromeComponent } from './bf-sparkle-monochrome';
import { BFRandomMonochromeComponent } from './bf-random-monochrome';
import { BFStaticClimateComponent } from './bf-static-climate';
import { BFFadeComponent } from './bf-fade';
import { BFColorFadeComponent } from './bf-color-fade';

export function getFormForBehavior(behavior: BehaviorType): Type<BehaviorForm> {
  switch (behavior) {
    case BehaviorType.BasicColor:
      return BFBasicColorComponent;

    case BehaviorType.ColorPaparazzi:
      return BFColorPaparazziComponent;

    case BehaviorType.ColorRandom:
      return BFColorRandomComponent;

    case BehaviorType.ColorSparkle:
      return BFColorSparkleComponent;

    case BehaviorType.Dimmer:
      return BFDimmerComponent;

    case BehaviorType.DLH:
      return BFDLHComponent;

    case BehaviorType.None:
      return BFNoneComponent;

    case BehaviorType.OccupancyOnly:
      return BFOccupancyOnlyComponent;

    case BehaviorType.Off:
      return BFOffComponent;

    case BehaviorType.On:
      return BFOnComponent;

    case BehaviorType.Paparazzi:
      return BFPaparazziComponent;

    case BehaviorType.PhotocellAndOccupancy:
      return BFPhotocellAndOccupancyComponent;

    case BehaviorType.PhotocellAndSwitch:
      return BFPhotocellAndSwitchComponent;

    case BehaviorType.PhotocellOnly:
      return BFPhotocellOnlyComponent;

    case BehaviorType.Pinwheel:
      return BFPinwheelComponent;

    case BehaviorType.Pinwheel5:
      return BFPinwheel5Component;

    case BehaviorType.Random:
      return BFRandomComponent;

    case BehaviorType.Sparkle:
      return BFSparkleComponent;

    case BehaviorType.SwitchAndOccupancy:
      return BFSwitchAndOccupancyComponent;

    case BehaviorType.SwitchAndVacancy:
      return BFSwitchAndVacancyComponent;

    case BehaviorType.SwitchOnly:
      return BFSwitchOnlyComponent;

    case BehaviorType.SwitchPhotocellAndVacancy:
      return BFSwitchPhotocellAndVacancyComponent;

    case BehaviorType.SwitchPhotocellAndOccupancy:
      return BFSwitchPhotocellAndOccupancyComponent;

    case BehaviorType.SwitchWithBlink:
      return BFSwitchWithBlinkComponent;

    case BehaviorType.MonoPaparazzi:
      return BFPaparazziMonochromeComponent;

    case BehaviorType.MonoSparkle:
      return BFSparkleMonochromeComponent;

    case BehaviorType.MonoRandom:
      return BFRandomMonochromeComponent;

    case BehaviorType.StaticClimate:
      return BFStaticClimateComponent;

    case BehaviorType.Fade:
      return BFFadeComponent;

    case BehaviorType.ColorFade:
      return BFColorFadeComponent;

    default:
      throw assertNever(behavior);
  }
}

function assertNever(input: never) {
  return new Error(`Unknown Behavior Detected: "${input}"`);
}
