import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DimmerControlComponent } from '@spog-ui/shared/components';
import { TimeoutControlComponentModule } from '../timeout-control';
import { BFOccupancyOnlyComponent } from './bf-occupancy-only.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DimmerControlComponent,
    TimeoutControlComponentModule,
  ],
  declarations: [BFOccupancyOnlyComponent],
  exports: [BFOccupancyOnlyComponent],
})
export class BFOccupancyOnlyComponentModule {}
