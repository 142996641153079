import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-sparkle-monochrome',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        Lights in the zone randomly fade in and out, creating a sparkling effect.
      </p>
      <div class="bfForm">
        <sui-speed-control formControlName="speed"></sui-speed-control>
      </div>
    </div>
  `,
})
export class BFSparkleMonochromeComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    speed: new UntypedFormControl('Normal'),
  });
}
