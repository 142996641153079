import { filter, map } from 'rxjs/operators';
import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { BehaviorModel } from '@spog-ui/shared/models/behaviors';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { SceneZoneApiModel } from '@spog-ui/shared/models/scene-zone-behaviors';
import {
  SceneInternalModel,
  SceneApplication,
  SceneFormModel,
} from '@spog-ui/shared/models/scenes';
import { EditScenePageActions } from '@spog-ui/scenes/actions';

@Injectable()
export abstract class EditScenePageState {
  abstract application$: Observable<SceneApplication>;
  abstract ready$: Observable<boolean>;
  abstract scenes$: Observable<SceneInternalModel[]>;
  abstract controlZones$: Observable<ZoneInternalModel[]>;
  abstract behaviors$: Observable<BehaviorModel[]>;
  abstract saving$: Observable<boolean>;
  abstract sceneToEdit$: Observable<SceneFormModel>;
  abstract dispatch(action: EditScenePageActions.Union): void;
}

@Component({
  selector: 'scn-edit-scene-page',
  template: `
    @if (state.ready$ | async) {
      <scn-scene-form
        [value]="state.sceneToEdit$ | async"
        [scenes]="state.scenes$ | async"
        [zones]="state.controlZones$ | async"
        [behaviors]="state.behaviors$ | async"
        [disabled]="state.saving$ | async"
        [application]="state.application$ | async"
        (save)="onSave($event)"
        >
      </scn-scene-form>
    } @else {
      <sui-spinner></sui-spinner>
    }
    
    `,
  styles: [
    `
      sui-spinner {
        display: block;
        width: 50px;
        margin: 0 auto;
      }
    `,
  ],
})
export class EditScenePageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(public state: EditScenePageState, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscriptions = combineLatest([
      this.route.paramMap.pipe(
        map(param => param.get('id')),
        filter((id): id is string => !!id && typeof id === 'string'),
      ),
      // Application type is resolved from the route definition
      this.route.data,
    ]).subscribe(([id, data]: [string, any]) =>
      this.state.dispatch(EditScenePageActions.enterAction(id, data.application)),
    );
  }

  ngOnDestroy(): void {
    this.state.dispatch(EditScenePageActions.leaveAction());
    this.subscriptions.unsubscribe();
  }

  onSave(scene: SceneZoneApiModel): void {
    this.state.dispatch(EditScenePageActions.editSceneAction(scene));
  }
}
