import { Component, Input } from '@angular/core';

@Component({
  selector: 'lit-communication-status',
  template: `
    <div class="alarmIndicator">
      @if (!hasCommunicationAlarm && !hasLowSignalAlarm) {
        <mat-icon>wifi</mat-icon>
      }
      @if (hasCommunicationAlarm) {
        <mat-icon>signal_wifi_off</mat-icon>
      }
      @if (hasLowSignalAlarm) {
        <mat-icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            >
            <path
              fill-opacity=".3"
              d="M9.01 15.99l8.8-10.96C17.47 4.77 14.08 2 9 2S.53 4.77.19 5.03l8.8 10.96h.02z"
              />
            <path
              d="M5.24 11.31L9 16l3.76-4.68C12.57 11.17 11.14 10 9 10c-2.14 0-3.57 1.17-3.76 1.31z"
              />
          </svg>
        </mat-icon>
      }
    </div>
    `,
  styles: [
    `
      mat-icon {
        font-size: 18px;
      }

      .alarmIndicator {
        height: 28px;
        text-align: center;
        padding-top: 5px;
      }
    `,
  ],
})
export class CommunicationStatusComponent {
  @Input()
  hasCommunicationAlarm = false;

  @Input()
  hasLowSignalAlarm = false;
}
