import { createReducer, on } from '@ngrx/store';
import {
  AddThermostatPageActions,
  EditThermostatPageActions,
  ThermostatsApiActions,
  ThermostatsPageActions,
} from '@spog-ui/thermostats/actions';

export interface Shape {
  isLoading: boolean;
  searchTerm: string;
  isEditingOrAdding: boolean;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
  isEditingOrAdding: false,
  searchTerm: '',
};

export const reducer = createReducer(
  initialState,
  on(
    ThermostatsPageActions.enterAction,
    (state): Shape => {
      return {
        ...initialState,
        searchTerm: state.searchTerm,
      };
    },
  ),
  on(
    ThermostatsApiActions.loadThermostatsPageModelsSuccessAction,
    (state): Shape => {
      return { ...state, isLoading: false };
    },
  ),
  on(ThermostatsApiActions.loadThermostatsPageModelsFailureAction, (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  }),
  on(
    ThermostatsPageActions.filterAction,
    (state, action): Shape => {
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    },
  ),
  on(
    EditThermostatPageActions.enterAction,
    AddThermostatPageActions.enterAction,
    (state): Shape => {
      return {
        ...state,
        isEditingOrAdding: true,
      };
    },
  ),

  on(
    EditThermostatPageActions.leaveAction,
    AddThermostatPageActions.leaveAction,
    (state): Shape => {
      return {
        ...state,
        isEditingOrAdding: true,
      };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectSearchTerm = (state: Shape) => state.searchTerm;
export const selectUserIsEditingOrAdding = (state: Shape) => state.isEditingOrAdding;
