import { createAction, union } from '@ngrx/store';
import { ScheduledEventFormModel } from '@spog-ui/shared/models/scheduled-events';

export const enterAction = createAction('[Add Scheduled Event Page] Enter');
export const leaveAction = createAction('[Add Scheduled Event Page] Leave');

export const addScheduledEventAction = createAction(
  '[Add Scheduled Event Page] Add Scheduled Event',
  (scheduledEvent: ScheduledEventFormModel) => {
    return { scheduledEvent };
  },
);
export const cancelAddScheduledEventAction = createAction(
  '[Add Scheduled Event Page] Cancel Add Scheduled Event',
);

export const confirmCancelAddScheduledEventAction = createAction(
  '[Add Scheduled Event Page] Confirm Cancel Add Scheduled Event',
);

export const dismissCancelAddScheduledEventAction = createAction(
  '[Add Scheduled Event Page] Dismiss Cancel Add Scheduled Event',
);

const all = union({
  enterAction,
  leaveAction,
  addScheduledEventAction,
  cancelAddScheduledEventAction,
  confirmCancelAddScheduledEventAction,
  dismissCancelAddScheduledEventAction,
});
export type Union = typeof all;
