import { createAction } from '@ngrx/store';
import {
  ClimateZoneControlModeInternalModel,
  ClimateZoneViewModel,
  ZoneInternalModel,
} from '@spog-ui/shared/models/zones';
import { ThermostatInternalModel } from '@spog-ui/shared/models/thermostats';

export const loadClimateZoneDetailsSuccessAction = createAction(
  '[Climate Zone Details API] Load Climate Zone Details Success',
  (climateZone: ZoneInternalModel) => ({
    climateZone,
  }),
);

export const loadClimateZoneDetailsFailureAction = createAction(
  '[Climate Zone Details API] Load Climate Zone Details Failure',
  (error: any) => ({ error }),
);

export const deleteClimateZoneSuccess = createAction(
  '[Climate Zone Details API] Delete Climate Zone Success',
);
export const deleteClimateZoneFailure = createAction(
  '[Climate Zone Details API] Delete Climate Zone Failure',
  (error?: any) => ({ error }),
);

export const controlClimateZoneSuccessAction = createAction(
  '[Climate Zone Details API] Control Climate Zone Success',
);

export const controlClimateZoneFailureAction = createAction(
  '[Climate Zone Details API] Control Climate Zone Failure',
  (
    climateZoneId: string,
    controlMode: ClimateZoneControlModeInternalModel,
    error?: any,
  ) => ({
    climateZoneId,
    controlMode,
    error,
  }),
);

export const loadClimateZoneDetailsThermostatsSuccessAction = createAction(
  '[Climate Zone Details API] Load Climate Zone Details Thermostats Success',
  (thermostats: ThermostatInternalModel[]) => ({
    thermostats,
  }),
);

export const loadClimateZoneDetailsThermostatsFailureAction = createAction(
  '[Climate Zone Details API] Load Climate Zone Details Thermostats Failure',
  (error: any) => ({ error }),
);
