export interface PlacedThing {
  id: string;
  floorPlanId: string;
  floorPlanX: number;
  floorPlanY: number;
}

export type BulkFloorPlanPlacementWSModel = {
  floorPlanId: string;
  placedThings: {
    id: string;
    floorPlanX: number;
    floorPlanY: number;
  }[];
};

export function toPlacedThingsFromBulkFloorPlanPlacementWS(
  ws: BulkFloorPlanPlacementWSModel,
): PlacedThing[] {
  return ws.placedThings.map(thing => ({
    id: thing.id,
    floorPlanX: thing.floorPlanX,
    floorPlanY: thing.floorPlanY,
    floorPlanId: ws.floorPlanId,
  }));
}

export interface FloorPlanModel {
  id: string;
  name: string;
  imageUrl: string;
  siteId: string;
}

