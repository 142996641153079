import { createReducer, on } from '@ngrx/store';
import { SiteDetailsActions, SiteDetailsApiActions } from '@spog-ui/site-details/actions';

export interface Shape {
  isLoading: boolean;
  siteUsers: { name: string; email: string }[];
}

export const initialState: Shape = {
  isLoading: true,
  siteUsers: [],
};

export const reducer = createReducer(
  initialState,
  on(SiteDetailsActions.enterAction, state => {
    return { ...initialState };
  }),
  on(SiteDetailsApiActions.loadSiteDetailsSuccessAction, state => {
    return { ...state, isLoading: false };
  }),
  on(SiteDetailsApiActions.loadSiteDetailsFailureAction, (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  }),
  on(SiteDetailsActions.closeSiteUsersPreviewAction, (state, action) => {
    return { ...state, siteUsers: [] };
  }),
  on(SiteDetailsApiActions.getSiteUsersSuccessAction, (state, action) => {
    return { ...state, siteUsers: action.users };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectSiteUsers = (state: Shape) => state.siteUsers;