import { Component, Input } from '@angular/core';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

@Component({
  selector: 'scn-zone-list',
  template: `
    @if (zones.length === 0) {
      No zones selected.
    } @else {
      @for (zone of zones; track zone; let isLast = $last) {
        <span class="mat-body-1">
          {{ zone.name }}{{ isLast ? '' : ', ' }}
        </span>
      }
    }
    `,
})
export class ZoneListComponent {
  @Input()
  zones: ZoneInternalModel[] = [];
}
