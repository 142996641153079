import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CardSelectComponentModule } from '@spog-ui/shared/components';

import { HardwareTypeSelectorComponent } from './hardware-type-selector.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    CardSelectComponentModule,
  ],
  declarations: [HardwareTypeSelectorComponent],
  exports: [HardwareTypeSelectorComponent],
})
export class HardwareTypeSelectorComponentModule {}
