import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as ScenesState from './scenes.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(ScenesState.STATE_KEY, ScenesState.reducers),
  ],
})
export class ScenesStateModule {}
