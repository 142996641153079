import {
  Bridge485ModelFragment as Bridge485GQLModel,
  CreateBridge485Input,
  CreateBridge485MetricInput,
  SerialSettingsParity,
  UpdateBridge485Input,
} from '@spog-ui/graphql/types';
import { Bridge485TemplateInternalModel } from '@spog-ui/shared/models/bridge-485-templates';
import { isIndustrialSensorDefinedDataType } from '@spog-ui/shared/models/indie-sensors';

type BaudRate = 9600 | 19200 | 38400 | 57600 | 76800 | 115200;

export { Bridge485GQLModel };
export interface Bridge485InternalModel {
  id: string;
  name: string;

  metricIds: string[];
  /* asset: ??? */

  serialSettings: {
    baudRate: BaudRate;
    stopBits: 1 | 2;
    parity: SerialSettingsParity;
  };

  samplePeriod: number; // in minutes

  networkSettings?: {
    siteControllerId?: string;
    snapAddress?: string;
  };
}

export interface Bridge485WSModel {
  id: string;
  name: string;
  samplePeriod: number;
  metricIds: string[];
  serialSettings: { baudRate: BaudRate; stopBits: 1 | 2; parity: SerialSettingsParity };
  network?: { snapAddress?: string; siteControllerId?: string };
}

export interface Bridge485FormOutputModel {
  name: string;

  samplePeriod: number; // in minutes

  baudRate: BaudRate;
  stopBits: 1 | 2;
  parity: SerialSettingsParity;

  template: string;

  siteController?: string;
  snapaddr?: string;
}

export interface Bridge485FormInputModel {
  name: string;

  samplePeriod: number; // in minutes

  baudRate: BaudRate;
  stopBits: 1 | 2;
  parity: SerialSettingsParity;

  siteController?: string;
  snapaddr?: string;
}

export function toBridge485FormInputModelFromInternal(
  internal: Bridge485InternalModel,
): Bridge485FormInputModel {
  return {
    name: internal.name,
    samplePeriod: internal.samplePeriod,
    baudRate: internal.serialSettings.baudRate,
    stopBits: internal.serialSettings.stopBits,
    parity: internal.serialSettings.parity,

    ...(internal.networkSettings && internal.networkSettings.siteControllerId
      ? { siteController: internal.networkSettings.siteControllerId }
      : {}),
    ...(internal.networkSettings && internal.networkSettings.snapAddress
      ? { snapaddr: internal.networkSettings.snapAddress }
      : {}),
  };
}

export function toBridge485InternalModelFromGQL(
  gql: Bridge485GQLModel,
): Bridge485InternalModel {
  return {
    id: gql.id,
    name: gql.name,
    metricIds: gql.metrics.map(metric => metric.id),
    samplePeriod: gql.samplePeriod,
    serialSettings: {
      ...gql.serialSettings,
      baudRate: gql.serialSettings.baudRate as BaudRate,
      stopBits: gql.serialSettings.stopBits as 1 | 2,
    },
    ...(gql.network
      ? {
          networkSettings: {
            ...(gql.network.siteController
              ? { siteControllerId: gql.network.siteController.id }
              : {}),
            ...(gql.network.snapAddress ? { snapAddress: gql.network.snapAddress } : {}),
          },
        }
      : {}),
  };
}

export function toBridge485InternalModelFromWS(
  ws: Bridge485WSModel,
): Bridge485InternalModel {
  console.log(ws);
  return {
    id: ws.id,
    name: ws.name,
    metricIds: ws.metricIds,
    samplePeriod: ws.samplePeriod,
    serialSettings: ws.serialSettings,
    networkSettings: ws.network,
  };
}

function toMetricsFromTemplate(
  template: Bridge485TemplateInternalModel,
  b485Name: string,
): CreateBridge485MetricInput[] {
  return template.metrics.map(metric => ({
    conversion: metric.conversion,
    dataAddress: metric.dataAddress,
    dataFormat: metric.dataFormat,
    dataType: {
      ...(isIndustrialSensorDefinedDataType(metric.dataType)
        ? { type: metric.dataType.type }
        : { custom: metric.dataType }),
    },
    functionCode: metric.functionCode,
    name: `${b485Name}: ${metric.name}`,
    unitId: metric.unitId,
  }));
}

export function toCreateBridge485Input(
  formOutput: Bridge485FormOutputModel,
  siteId: string,
  template: Bridge485TemplateInternalModel,
): CreateBridge485Input {
  return {
    siteId,
    name: formOutput.name,
    samplePeriod: formOutput.samplePeriod,
    metrics: toMetricsFromTemplate(template, formOutput.name),
    serialSettings: {
      baudRate: formOutput.baudRate,
      stopBits: formOutput.stopBits,
      parity: formOutput.parity,
    },
    ...(formOutput.siteController || formOutput.snapaddr
      ? {
          network: {
            ...(formOutput.siteController
              ? { siteControllerId: formOutput.siteController }
              : {}),
            ...(formOutput.snapaddr ? { snapAddress: formOutput.snapaddr } : {}),
          },
        }
      : {}),
  };
}

/**
 * This DOES NOT touch metrics. To achieve that, a
 * sufficiently advanced user is expected to use the API.
 */
export function toUpdateBridge485Input(
  formOutput: Bridge485FormOutputModel & { id: string },
): UpdateBridge485Input {
  const network =
    formOutput.siteController || formOutput.snapaddr
      ? {
          ...(formOutput.siteController
            ? { siteControllerId: formOutput.siteController }
            : {}),
          ...(formOutput.snapaddr ? { snapAddress: formOutput.snapaddr } : {}),
        }
      : {};

  return {
    id: formOutput.id,
    name: formOutput.name,
    samplePeriod: formOutput.samplePeriod,

    serialSettings: {
      baudRate: formOutput.baudRate,
      stopBits: formOutput.stopBits,
      parity: formOutput.parity,
    },

    network,
  };
}
