import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as PermissionGroupsState from './permission-groups.state';

@NgModule({
  imports: [
    StoreModule.forFeature(
      PermissionGroupsState.STATE_KEY,
      PermissionGroupsState.reducers,
    ),
  ],
})
export class PermissionGroupsStateModule {}
