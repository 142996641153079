import { createFeatureSelector } from '@ngrx/store';
import * as RedirectState from './redirect.reducer';
import * as AddOrgUserPageState from './add-organization-user-page.reducer';
import * as EditOrgUserPageState from './edit-organization-user-page.reducer';
import * as AddPermissionGroupPageState from './add-permission-group-page.reducer';
import * as EditPermissionGroupPageState from './edit-permission-group-page.reducer';
import * as AddEmailGroupPageState from './add-email-group-page.reducer';
import * as EditEmailGroupPageState from './edit-email-group-page.reducer';
import * as OrgUsersPageState from './organization-users-page.reducer';
import * as OrgUsersState from './organization-users.reducer';
import * as PermissionGroupsPageState from './permission-groups-page.reducer';
import * as EmailGroupsPageState from './email-groups-page.reducer';
import * as SitesPageState from './sites-page.reducer';
import * as SitesState from './sites.reducer';
import * as AdminOrganizationShellPageState from './admin-organization-shell-page.reducer';
import * as QuickSiteSetupPageState from './quick-site-setup-page.reducer';

export const orgStateFeatureKey = 'feature-manageOrganizations';

export const selectFeature = createFeatureSelector<{
  [AddOrgUserPageState.STATE_KEY]: AddOrgUserPageState.Shape;
  [EditOrgUserPageState.STATE_KEY]: EditOrgUserPageState.Shape;
  [AddPermissionGroupPageState.STATE_KEY]: AddPermissionGroupPageState.Shape;
  [EditPermissionGroupPageState.STATE_KEY]: EditPermissionGroupPageState.Shape;
  [AddEmailGroupPageState.STATE_KEY]: AddEmailGroupPageState.Shape;
  [EditEmailGroupPageState.STATE_KEY]: EditEmailGroupPageState.Shape;
  [OrgUsersPageState.STATE_KEY]: OrgUsersPageState.Shape;
  [OrgUsersState.STATE_KEY]: OrgUsersState.Shape;
  [PermissionGroupsPageState.STATE_KEY]: PermissionGroupsPageState.Shape;
  [EmailGroupsPageState.STATE_KEY]: EmailGroupsPageState.Shape;
  [RedirectState.STATE_KEY]: RedirectState.Shape;
  [SitesPageState.STATE_KEY]: SitesPageState.Shape;
  [SitesState.STATE_KEY]: SitesState.Shape;
  [AdminOrganizationShellPageState.STATE_KEY]: AdminOrganizationShellPageState.Shape;
  [QuickSiteSetupPageState.STATE_KEY]: QuickSiteSetupPageState.Shape;
}>(orgStateFeatureKey);
