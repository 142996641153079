import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { DimmerComponent } from '../dimmer/dimmer.component';

type Light = { id: string; level: number; dlhZoneId: null | any };

@Component({
  selector: 'sui-light-controls',
  template: `
    <sui-dimmer
      [level]="light.level"
      [canBlink]="true"
      (dim)="control.emit($event)"
    ></sui-dimmer>
    <div class="suiLightControlsDLHIconWrapper">
      @if (belongsToDLHZone) {
      <mat-icon
        matTooltip="Belongs to a Daylight Harvesting Zone"
        matTooltipPosition="above"
        [class.suiLightControlsCanViewDLHZone]="canViewDLHZone"
        [attr.aria-role]="canViewDLHZone ? 'button' : 'textbox'"
        (click)="onViewDLHZone()"
      >
        brightness_high
      </mat-icon>
      } @else {
      <div class="suiNoDLHIcon"></div>
      }
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }

      .suiLightControlsDLHIconWrapper {
        flex-grow: 0;
        flex-basis: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: var(--color-foreground-secondary-text);
      }

      .suiLightControlsCanViewDLHZone {
        cursor: pointer;
      }

      sui-dimmer {
        flex-grow: 1;
      }

      .suiNoDLHIcon {
        width: 24px;
        height: 24px;
      }
    `,
  ],
  standalone: true,
  imports: [DimmerComponent, NgIf, MatIcon, MatTooltip],
})
export class LightControlsComponent {
  canViewDLHZone = false;
  viewDLHZoneEmitter = new EventEmitter<string>();
  @Input()
  light: Light;
  @Output()
  control = new EventEmitter<number>();
  @Output()
  get viewDLHZone() {
    this.canViewDLHZone = true;
    return this.viewDLHZoneEmitter;
  }

  get belongsToDLHZone() {
    return this.light.dlhZoneId !== null && this.light.dlhZoneId !== undefined;
  }

  onViewDLHZone() {
    if (this.canViewDLHZone) {
      this.viewDLHZoneEmitter.emit(this.light.dlhZoneId);
    }
  }
}
