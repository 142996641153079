import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiIconModule } from '@spog-ui/shared/components';
import { RemoveBehaviorIdTokenPipeModule } from '@spog-ui/shared/pipes/remove-behavior-id-token';
import { BehaviorDescriptionComponent } from './behavior-description.component';

@NgModule({
  imports: [SuiIconModule, RemoveBehaviorIdTokenPipeModule, CommonModule],
  declarations: [BehaviorDescriptionComponent],
  exports: [BehaviorDescriptionComponent],
})
export class BehaviorDescriptionComponentModule {}
