import { Component, Input, OnChanges } from '@angular/core';
import {
  getIndieSensorLastReadingDiff,
  getIndieSensorUnits,
  IndieSensorDetailsViewModel,
  isIndieSensorAlarmed,
  isIndieSensorUpgradePending,
  isIndieSensorUpgrading,
} from '@spog-ui/shared/models/indie-sensors';
import { isPowerSensor } from '@spog-ui/shared/state/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'spog-indie-sensor',
  template: `
    <mat-card>
      <mat-card-title>
        <div class="sensorIconBackground">
          @if (isPowerSensor) {
            <mat-icon
              class="sensorIcon"
              svgIcon="spog_power"
            ></mat-icon>
          }
          @if (!isPowerSensor) {
            <mat-icon class="sensorIcon"
              >settings_input_antenna</mat-icon
              >
            }
          </div>
    
          <div class="sensorName">
            {{ sensor.name }}
            <div class="secondLine">
              @if (sensor.state) {
                {{ sensor.state.lastValue | number }} {{ units }} read
                <span [matTooltip]="readingDateTime">{{ readingDiff }}</span>
              } @else {
                Waiting for first reading
              }
            </div>
          </div>
    
          @if (hasAlert) {
            <div class="alert">
              <mat-icon color="{{ alertColor }}" svgIcon="spog_clipboard_alert"></mat-icon>
            </div>
          }
    
          <div class="arrowIcon">
            <mat-icon> keyboard_arrow_right </mat-icon>
          </div>
        </mat-card-title>
      </mat-card>
    `,
  styles: [
    `
      mat-card {
        margin-bottom: 16px;
        padding: 16px;
      }

      mat-card-title {
        display: grid;
        grid-template-columns: 48px 1fr auto auto;
        grid-template-areas: 'icon name alert arrow';
        font-size: 16px;
      }

      .sensorIconBackground {
        background-color: var(--color-foreground-text);
        opacity: 0.82;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 34px;
        margin-top: 4.5px;
      }

      .sensorIcon {
        color: var(--color-background-card);
        border-radius: 50%;
        display: flex;
        height: 24px;
        width: 24px;
        grid-area: icon;
        align-items: center;
      }

      .sensorName {
        grid-area: name;
        line-height: 19px;
        font-size: 16px;
        display: flex;
        flex-direction: column;
      }

      .sensorName > span {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.0015em;
      }

      .secondLine {
        line-height: 16px;
        opacity: 0.72;
        font-size: 14px;
        padding-top: 8px;
      }

      .alert {
        align-self: center;
        grid-area: alert;
        height: 24px;
      }

      .arrowIcon {
        justify-content: center;
        grid-area: arrow;
        cursor: pointer;
        margin: auto 0;
      }

      .arrowIcon mat-icon {
        position: relative;
        top: 2px;
      }
    `,
  ],
})
export class IndieSensorComponent implements OnChanges {
  indieSensor: IndieSensorDetailsViewModel;
  isPowerSensor = false;
  hasAlert = false;
  alertColor = '';
  readingDiff = '';
  readingDateTime = '';
  units: string | undefined = undefined;

  @Input()
  set sensor(indieSensor: IndieSensorDetailsViewModel) {
    this.indieSensor = indieSensor;
    this.isPowerSensor = isPowerSensor(indieSensor);
    this.units = getIndieSensorUnits(indieSensor.dataType);

    if (isIndieSensorAlarmed(this.indieSensor)) {
      this.hasAlert = true;
      this.alertColor = 'warn';
    } else if (isIndieSensorUpgradePending(this.indieSensor)) {
      this.hasAlert = true;
      this.alertColor = 'primary';
    } else if (isIndieSensorUpgrading(this.indieSensor)) {
      this.hasAlert = true;
      this.alertColor = 'accent';
    }
  }

  get sensor() {
    return this.indieSensor;
  }

  @Input()
  timezone: string;

  @Input()
  siteTime: DateTime;

  ngOnChanges() {
    const { diff, date } = getIndieSensorLastReadingDiff(
      this.timezone,
      this.siteTime,
      this.sensor.state,
    );

    this.readingDiff = diff;
    this.readingDateTime = date;
  }
}
