import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Bridge485TemplateInternalModel } from '@spog-ui/shared/models/bridge-485-templates';
import * as Bridge485TemplatesState from './bridge-485-templates.reducer';

export const STATE_KEY = 'bridge485templates-core';

export type Shape = EntityState<Bridge485TemplateInternalModel>;

export const selectBridge485TemplatesState = createFeatureSelector<Shape>(STATE_KEY);

export const selectAllBridge485Templates = createSelector(
  selectBridge485TemplatesState,
  Bridge485TemplatesState.selectAll,
);

export const selectBridge485TemplateEntities = createSelector(
  selectBridge485TemplatesState,
  Bridge485TemplatesState.selectEntities,
);
