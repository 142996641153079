import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import {
  ThermostatScenesListComponent,
  ThermostatScenesListState,
} from './thermostat-scenes-list.component';
import { ApplySceneButtonComponentModule } from '../apply-scene-button';

@NgModule({
  imports: [
    CommonModule,
    ApplySceneButtonComponentModule,
    PermissionsModule,
    MatIconModule,
    MatListModule,
    RouterModule,
  ],
  declarations: [ThermostatScenesListComponent],
  exports: [ThermostatScenesListComponent],
})
export class ThermostatScenesListComponentModule {
  static connect(
    StateAdapterClass: Type<ThermostatScenesListState>,
  ): ModuleWithProviders<ThermostatScenesListComponentModule> {
    return {
      ngModule: ThermostatScenesListComponentModule,
      providers: [
        {
          provide: ThermostatScenesListState,
          useClass: StateAdapterClass,
        },
      ],
    };
  }
}
