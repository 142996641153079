import {
  ActivatedRouteSnapshot,
  Data,
  Params,
  RouterStateSnapshot,
} from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  routeData: Data;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const rootRoute = routerState.root;
    const params: Params = {};
    const queryParams: Params = {};
    const routeData: Data = {};

    this.serializeRouteParams(rootRoute, params);
    this.serializeQueryParams(rootRoute, queryParams);
    this.serializeRouteData(rootRoute, routeData);

    return { url: routerState.url, params, queryParams, routeData };
  }

  private serializeRouteParams(route: ActivatedRouteSnapshot, params: Params) {
    Object.assign(params, route.params);

    for (const child of route.children) {
      this.serializeRouteParams(child, params);
    }
  }

  private serializeQueryParams(route: ActivatedRouteSnapshot, params: Params) {
    Object.assign(params, route.queryParams);

    for (const child of route.children) {
      this.serializeRouteParams(child, params);
    }
  }

  private serializeRouteData(route: ActivatedRouteSnapshot, params: Params) {
    Object.assign(params, route.data);

    for (const child of route.children) {
      this.serializeRouteData(child, params);
    }
  }
}
