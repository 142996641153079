import {
  OrganizationModelFragment,
  CreateOrganizationInput,
  PermissionGroupSiteRole,
  UpdateOrganizationInput,
  OrganizationIdentityModelFragment,
  OrganizationRole,
  Capabilities,
  QuickSiteSetupInput,
} from '@spog-ui/graphql/types';
import { parsePermissionGroupSiteRole } from '@spog-ui/shared/models/permission-groups';
import { OrganizationAdminUpsert as OrganizationWSModel } from '@spog-shared/events/organization';
import { DateTime } from 'luxon';

export { OrganizationWSModel };

export interface OrganizationInternalModel {
  id: string;
  name: string;
  defaultSiteRole: PermissionGroupSiteRole;
  capabilities: Capabilities[];
  siteIds: string[];
  userIds: string[];
  temporary: boolean;
}

export type OrganizationGQLModel = OrganizationModelFragment;
export type OrganizationIdentityGQLModel = OrganizationIdentityModelFragment;

export interface OrganizationFormModel {
  id?: string;
  name: string;
  defaultSiteRole: PermissionGroupSiteRole;
  siteIds: string[];
  capabilities: Capabilities[];
  addBasicPermissionGroups: boolean;
}

export interface QuickSiteSetupFormModel {
  name: string;
  lat: number;
  lon: number;
  siteControllerMac: string;
}

export function toOrganizationInternalModelFromGQL(
  gql: OrganizationGQLModel,
): OrganizationInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    defaultSiteRole: gql.defaultSiteRole,
    capabilities: gql.capabilities,
    siteIds: gql.sites?.edges ? gql.sites.edges.map(edge => edge.node.id) : [],
    userIds: gql.users?.edges ? gql.users.edges.map(edge => edge.node.id) : [],
    temporary: gql.temporary,
  };
}

export function toOrganizationInternalModelFromIdentityGQL(
  gql: OrganizationIdentityGQLModel,
): OrganizationInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    defaultSiteRole: gql.defaultSiteRole,
    capabilities: gql.capabilities,
    siteIds: [],
    userIds: [],
    temporary: gql.temporary,
  };
}

export function toOrganizationInternalModelFromWS(
  ws: OrganizationWSModel,
): OrganizationInternalModel {
  return {
    id: ws.id,
    name: ws.name,
    defaultSiteRole: parsePermissionGroupSiteRole(ws.defaultSiteRole),
    siteIds: ws.siteIds,
    capabilities: ws.capabilities,
    userIds: ws.users.map(user => user.userId),
    temporary: ws.temporary,
  };
}

/**
 * Converts organization form model into one ready to be used
 * by the GraphQL create organization operation
 */
export function toCreateOrganizationInputFromOrganizationForm(
  form: OrganizationFormModel,
): CreateOrganizationInput {
  return {
    name: form.name,
    defaultSiteRole: form.defaultSiteRole,
    siteIds: form.siteIds,
    users: [],
    capabilities: form.capabilities,
    addBasicPermissionGroups: form.addBasicPermissionGroups,
  };
}

export function toCreateElevatedPrivilegesOrganization(
  siteId: string,
  userId: string,
): CreateOrganizationInput {
  // We add seconds to the name to prevent name collisions
  const epochSeconds = DateTime.utc().set({ millisecond: 0 }).toSeconds();
  return {
    name: `Elevated_Privileges_${userId}_${siteId}_${epochSeconds}`,
    defaultSiteRole: PermissionGroupSiteRole.MEMBER,
    siteIds: [siteId],
    users: [
      {
        organizationRole: OrganizationRole.MEMBER,
        userId,
      },
    ],
    temporary: true,
  };
}

/**
 * Converts organization form model into one ready to be used
 * by the GraphQL organization update operation
 */
export function toUpdateOrganizationInputFromOrganizationForm(
  form: OrganizationFormModel,
): UpdateOrganizationInput {
  return {
    id: form.id!,
    name: form.name,
    defaultSiteRole: form.defaultSiteRole,
    siteIds: form.siteIds,
    capabilities: form.capabilities,
  };
}

export function toOrganizationFormModelFromInternal(
  organization: OrganizationInternalModel,
): OrganizationFormModel {
  return {
    id: organization.id,
    name: organization.name,
    defaultSiteRole: organization.defaultSiteRole,
    siteIds: organization.siteIds,
    capabilities: organization.capabilities,
    addBasicPermissionGroups: false,
  };
}

export function toQuickSiteSetupInputFromQuickSiteSetupForm(
  form: QuickSiteSetupFormModel,
  organizationId: string,
): QuickSiteSetupInput {
  return {
    organizationId,
    siteName: form.name,
    geoLocation: {
      latitude: form.lat,
      longitude: form.lon,
    },
    macAddress: form.siteControllerMac,
  };
}
