import { Component, Input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { LinkedBehavior, SceneViewModel } from '@spog-ui/shared/models/scenes';

@Component({
  selector: 'scn-scene-zone-behaviors',
  template: `
    @for (behaviorGroup of scene.linkedBehaviorList; track behaviorId($index, behaviorGroup)) {
      <scn-behavior-description
        [behaviorGroup]="behaviorGroup"
        @shrinkIn
        >
      </scn-behavior-description>
    }
    @if (!scene.linkedZoneCount) {
      <p @shrinkIn>
        There are no zones configured for this scene
      </p>
    }
    `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        padding-left: 64px;
        padding-right: 64px;
      }

      @media screen and (max-width: 500px) {
        :host {
          padding-left: 26px;
          padding-right: 26px;
        }
      }

      p {
        font-size: 12px;
        font-style: italic;
        margin: 0;
        color: var(--color-foreground-secondary-text);
        padding-bottom: 12px;
      }
    `,
  ],
  animations: [
    trigger('shrinkIn', [
      transition('void => *', [
        style({ height: 0, opacity: 0 }),
        animate(100, style({ height: '*', opacity: 1.0 })),
      ]),
    ]),
  ],
})
export class SceneZoneBehaviorsComponent {
  @Input()
  scene: SceneViewModel;

  behaviorId(index: number, linkedBehavior: LinkedBehavior): string {
    return linkedBehavior.id;
  }
}
