import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  toPermissionGroupInternalModelFromGQL,
  PermissionGroupInternalModel,
} from '@spog-ui/shared/models/permission-groups';

@Injectable({ providedIn: 'root' })
export class PermissionGroupsApiService {
  constructor(readonly gql: GraphQLAPIService) {}

  getPermissionGroupsByOrganization(
    organizationId: string,
  ): Observable<PermissionGroupInternalModel[]> {
    return this.gql.getPermissionGroupsByOrganization({ organizationId }).pipe(
      map(response => {
        if (!response.organization) {
          throw new Error('No organization for id');
        }

        return response.organization!.permissionGroups.map(
          toPermissionGroupInternalModelFromGQL,
        );
      }),
    );
  }

  delete(id: string): Observable<string | undefined | null> {
    return this.gql
      .deletePermissionGroup({ input: { id } })
      .pipe(map(response => response.deletePermissionGroup));
  }
}
