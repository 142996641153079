import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  SiteSelectorPageComponent,
  SiteSelectorPageComponentModule,
  SiteSelectorShellComponent,
  SiteSelectorShellComponentModule,
} from './components';
import { AuthGuard } from '@auth0/auth0-angular';
import { AnonymousGuardService } from '@spog-ui/current-user/feature';
import { SiteMapPageComponent } from './components/site-map-page';

export const routes: Routes = [
  {
    path: '',
    component: SiteSelectorShellComponent,
    children: [
      {
        // This path is being used as the Auth0 redirect URL.
        // After the user logs in, they will be redirected to user/select-site.
        path: '',
        component: SiteSelectorShellComponent,
        pathMatch: 'full',
        canActivate: [AnonymousGuardService],
      },
      {
        path: 'select-site',
        component: SiteSelectorPageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'site-map/select',
    component: SiteMapPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'details/:activeSiteId',
        loadChildren: () =>
          import('@spog-ui/site-details/feature').then(m => m.SiteDetailsModule),
        data: {
          admin: false,
        },
      },
    ],
  },
  {
    path: 'site-map/change',
    component: SiteMapPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'details/:activeSiteId',
        loadChildren: () =>
          import('@spog-ui/site-details/feature').then(m => m.SiteDetailsModule),
        data: {
          admin: false,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    SiteSelectorShellComponentModule,
    SiteSelectorPageComponentModule,
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class SiteSelectorRoutingModule {}
