import { Component, Inject, NgModule, ViewEncapsulation } from '@angular/core';
import { CommonModule, NgIf, AsyncPipe } from '@angular/common';
import { SafeResourceUrl } from '@angular/platform-browser';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatIconModule, MatIcon } from '@angular/material/icon';
import { MatTooltipModule, MatTooltip } from '@angular/material/tooltip';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SpinnerComponent } from '@spog-ui/shared/components';
import { SiteControllerViewModel } from '@spog-ui/shared/models/site-controllers';
import { RemoteAccessURLService } from './remote-access-url.service';
import { CurrentUserService } from '@spog-ui/current-user/feature';

@Component({
  selector: 'spog-remote-access',
  template: `
    <div class="bar">
      <span>{{ data.siteController.name }}</span>
      <div class="actions">
        @if (isSuperAdmin$ | async) {
        <button
          mat-icon-button
          matTooltip="Toggle Debug Mode"
          data-test-id="toggleDebugMode"
          [color]="debugButtonColor$ | async"
          (click)="toggleDebugMode()"
        >
          <mat-icon>pest_control</mat-icon>
        </button>
        } @if (isSuperAdmin$ | async) {
        <button
          mat-icon-button
          matTooltip="Toggle Legacy Debug Mode"
          data-test-id="toggleLegacyDebugMode"
          [color]="legacyDebugButtonColor$ | async"
          (click)="toggleLegacyDebugMode()"
        >
          <mat-icon>bug_report</mat-icon>
        </button>
        }
        <button mat-icon-button mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="spinner">
      <sui-spinner></sui-spinner>
    </div>

    <iframe [class.ready]="ready" (load)="ready = true" [attr.src]="url$ | async">
    </iframe>
  `,
  styles: [
    `
      .remoteAccessPanel {
        max-width: 100% !important;
        height: 100%;
      }

      .remoteAccessPanel mat-dialog-container {
        padding: 0;
        width: 100vw;
        height: 100%;
        border-radius: 0;
        position: relative;
        background-color: var(--color-background-app-bar);
      }

      .remoteAccessPanel .spinner {
        position: absolute;
        top: 40px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: calc(100% - 40px);
        background-color: var(--color-background-background);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
      }

      .remoteAccessPanel sui-spinner {
        width: 50px;
      }

      .remoteAccessPanel iframe {
        position: absolute;
        border: none;
        outline: none;
        top: 40px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: calc(100% - 40px);
        z-index: 5;
        opacity: 0;
        transition: opacity 750ms;
      }

      .remoteAccessPanel iframe.ready {
        opacity: 1;
      }

      .remoteAccessPanel .bar {
        display: flex;
        justify-content: space-between;
        height: 40px;
        padding: 0 6px 0 25px;
        align-items: center;
        opacity: 0.7;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatIconButton,
    MatTooltip,
    MatIcon,
    MatDialogClose,
    SpinnerComponent,
    AsyncPipe,
  ],
})
export class RemoteAccessComponent {
  ready = false;
  isDebugMode$ = new BehaviorSubject(false);
  isLegacyDebugMode$ = new BehaviorSubject(false);
  debugButtonColor$ = this.isDebugMode$.pipe(
    map(isDebugMode => (isDebugMode ? 'primary' : 'default')),
  );
  legacyDebugButtonColor$ = this.isLegacyDebugMode$.pipe(
    map(isLegacyDebugMode => (isLegacyDebugMode ? 'primary' : 'default')),
  );
  url$: Observable<SafeResourceUrl | null>;
  isSuperAdmin$ = this.currentUser.isSuperAdmin$;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly data: { siteController: SiteControllerViewModel; routeParam?: string },
    remoteAccessUrlService: RemoteAccessURLService,
    readonly currentUser: CurrentUserService,
  ) {
    this.url$ = this.isDebugMode$.pipe(
      withLatestFrom(this.isLegacyDebugMode$),
      switchMap(([isDebugMode, isLegacyDebugMode]) =>
        remoteAccessUrlService.buildURL(
          data.siteController,
          {
            debug: isDebugMode,
            legacyDebug: isLegacyDebugMode,
          },
          data.routeParam,
        ),
      ),
    );
  }

  toggleDebugMode() {
    this.isDebugMode$.next(!this.isDebugMode$.value);
    this.isLegacyDebugMode$.next(false);
  }

  toggleLegacyDebugMode() {
    this.isLegacyDebugMode$.next(!this.isLegacyDebugMode$.value);
    this.isDebugMode$.next(false);
  }
}
