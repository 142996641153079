import { createAction } from '@ngrx/store';
import {
  CreateScheduledUtilityRateInput,
  ScheduledUtilityRateViewModel,
} from '@spog-ui/shared/models/scheduled-utility-rates';
import { UtilityServiceViewModel } from '@spog-ui/shared/models/utility-services';

export const enterAction = createAction('[Utility Services Page] Enter');
export const leaveAction = createAction('[Utility Services Page] Leave');
export const scheduleUtilityRateAction = createAction(
  '[Utility Services Page] Schedule Utility Rate',
  (utilityService: UtilityServiceViewModel) => ({ utilityService }),
);
export const cancelSchedulingUtilityRateAction = createAction(
  '[Utility Services] Cancel Scheduling Utility Rate',
);
export const saveScheduledUtilityRateAction = createAction(
  '[Utility Services Page] Save Scheduled Utility Rate',
  (scheduledUtilityRate: CreateScheduledUtilityRateInput) => ({ scheduledUtilityRate }),
);
export const deleteScheduledUtilityRateAction = createAction(
  '[Utility Services Page] Delete Scheduled Utility Rate',
  (scheduledUtilityRate: ScheduledUtilityRateViewModel) => ({
    scheduledUtilityRate,
  }),
);
export const confirmDeleteAction = createAction(
  '[Utility Services Page] Confirm Delete',
  (scheduledUtilityRate: ScheduledUtilityRateViewModel) => ({ scheduledUtilityRate }),
);
export const cancelDeleteAction = createAction(
  '[Utility Services Page] Cancel Delete',
  (scheduledUtilityRate: ScheduledUtilityRateViewModel) => ({ scheduledUtilityRate }),
);
export const deleteUtilityServiceAction = createAction(
  '[Utility Services Page] Delete Utility Service',
  (utilityService: UtilityServiceViewModel) => {
    return { utilityService };
  },
);
