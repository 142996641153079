import { Component, Input } from '@angular/core';
import { SceneApplication } from '@spog-ui/shared/models/scenes';

@Component({
  selector: 'scn-no-scenes-for-application',
  template: `
    <sui-empty-state>
      <ng-template suiEmptyStateTitle>Get Started</ng-template>
      <ng-template suiEmptyStateMessage>{{
        application === eApplication.CLIMATE
          ? 'Routines allow you to apply behaviors to up to 20 zones. Use the routines page to manually apply routines or use the calendar to schedule when routines should be run.'
          : 'Scenes allow you to apply behaviors to up to 20 zones. Use the scenes page to manually apply scenes or use the calendar to schedule when scenes should be applied.'
      }}</ng-template>
      <ng-template suiEmptyStateActions>
        <a
          suiButton
          *suiIfUserCan="['ssc:siteAdmin']"
          class="suiAddScene"
          [routerLink]="'./add'"
          >{{ application === eApplication.CLIMATE ? 'Add Routine' : 'Add Scene' }}</a
        >
      </ng-template>
    </sui-empty-state>
  `,
})
export class NoScenesForApplicationComponent {
  eApplication = SceneApplication;

  @Input() application: SceneApplication = SceneApplication.LIGHTING;
}
