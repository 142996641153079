import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import {
  toSiteControllerViewModelFromInternal,
  SiteControllerViewModel,
  toSiteControllerFormModel,
} from '@spog-ui/shared/models/site-controllers';
import { toSiteFormModelFromInternal } from '@spog-ui/shared/models/sites';
import { toUserFormModelFromInternal } from '@spog-ui/shared/models/users';
import * as CoreState from '@spog-ui/shared/state/core';
import { toSiteControllerDetailsViewModelFromInternal } from '@spog-ui/site-controller-details/state';
import { filterBy } from '@spog-ui/utils/filter';
import * as AddSiteControllerPageState from './add-site-controller-page';
import * as AddSitePageState from './add-site-page';
import * as AddUserPageState from './add-user-page';
import * as EditSiteControllerPageState from './edit-site-controller-page';
import * as EditSitePageState from './edit-site-page';
import * as EditUserPageState from './edit-user-page';
import * as SiteControllerDetailsPaneState from './site-controller-details-pane';
import * as SiteControllersAdminPageState from './site-controllers-admin-page';
import * as SiteControllersPageState from './site-controllers-page';
import * as SitesState from './sites';
import * as SitesPageState from './sites-page';
import * as UsersState from './users';
import * as UsersPageState from './users-page';

export const STATE_KEY = 'remoteAccess';

export interface Shape {
  addSitePage: AddSitePageState.Shape;
  editSitePage: EditSitePageState.Shape;
  siteControllerDetailsPane: SiteControllerDetailsPaneState.Shape;
  siteControllersPage: SiteControllersPageState.Shape;
  siteControllersAdminPage: SiteControllersAdminPageState.Shape;
  addSiteControllersPage: AddSiteControllerPageState.Shape;
  editSiteControllersPage: EditSiteControllerPageState.Shape;
  sites: SitesState.Shape;
  sitesPage: SitesPageState.Shape;
  users: UsersState.Shape;
  usersPage: UsersPageState.Shape;
  addUserPage: AddUserPageState.Shape;
  editUserPage: EditUserPageState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  addSitePage: AddSitePageState.reducer,
  editSitePage: EditSitePageState.reducer,
  siteControllerDetailsPane: SiteControllerDetailsPaneState.reducer,
  siteControllersPage: SiteControllersPageState.reducer,
  siteControllersAdminPage: SiteControllersAdminPageState.reducer,
  addSiteControllersPage: AddSiteControllerPageState.reducer,
  editSiteControllersPage: EditSiteControllerPageState.reducer,
  sites: SitesState.reducer,
  sitesPage: SitesPageState.reducer,
  users: UsersState.reducer,
  usersPage: UsersPageState.reducer,
  addUserPage: AddUserPageState.reducer,
  editUserPage: EditUserPageState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Add Site Page
 */
export const selectAddSitePageState = createSelector(
  selectFeatureState,
  state => state.addSitePage,
);
export const selectIsAddSitePageLoading = createSelector(
  selectAddSitePageState,
  AddSitePageState.selectIsLoading,
);
export const selectIsAddSitePageSaving = createSelector(
  selectAddSitePageState,
  AddSitePageState.selectIsSaving,
);

/**
 * Edit Site Page
 */
export const selectEditSitePageState = createSelector(
  selectFeatureState,
  state => state.editSitePage,
);
export const selectEditSitePageSiteId = createSelector(
  selectEditSitePageState,
  EditSitePageState.selectSiteId,
);
export const selectIsEditSitePageLoading = createSelector(
  selectEditSitePageState,
  EditSitePageState.selectIsLoading,
);
export const selectIsEditSitePageSaving = createSelector(
  selectEditSitePageState,
  EditSitePageState.selectIsSaving,
);

/**
 * Site Controllers Page
 */
export const selectSiteControllersPageState = createSelector(
  selectFeatureState,
  state => state.siteControllersPage,
);

export const selectSiteControllersPageIsLoading = createSelector(
  selectSiteControllersPageState,
  SiteControllersPageState.selectIsLoading,
);

export const selectSiteControllersPageSize = createSelector(
  selectSiteControllersPageState,
  SiteControllersPageState.selectPageSize,
);

export const selectSiteControllersPageIndex = createSelector(
  selectSiteControllersPageState,
  SiteControllersPageState.selectPageIndex,
);

export const selectSiteControllersPageFilter = createSelector(
  selectSiteControllersPageState,
  SiteControllersPageState.selectFilter,
);

export const selectFilteredSiteControllers = createSelector(
  selectSiteControllersPageFilter,
  CoreState.selectSiteControllerViewsForSelectedSite,
  (filter, siteControllers) => {
    return siteControllers.filter(siteController => {
      const searchTerm = filter.trim().toLowerCase();

      return (
        // Name
        filterBy(searchTerm, siteController.name.toLowerCase()) ||
        // Mac
        filterBy(searchTerm, siteController.macAddress.toLowerCase()) ||
        // ID prefixed with 'id:'
        filterBy(searchTerm, siteController.id.toLowerCase(), 'id:') ||
        // Status prefixed with 'status:'
        filterBy(searchTerm, siteController.status.toLowerCase(), 'status:') ||
        // Version prefixed with 'version:'
        filterBy(searchTerm, siteController.systemVersion ?? '', 'version:')
      );
    });
  },
);

export const selectSiteControllersLength = createSelector(
  selectFilteredSiteControllers,
  siteControllers => siteControllers.length,
);

export const selectPaginatedSiteControllers = createSelector(
  selectFilteredSiteControllers,
  selectSiteControllersPageIndex,
  selectSiteControllersPageSize,
  (siteControllers, index, size) => {
    return siteControllers.slice(index * size, index * size + size);
  },
);

export const selectSiteControllersPaginationData = createSelector(
  selectSiteControllersLength,
  selectSiteControllersPageIndex,
  selectSiteControllersPageSize,
  (length, pageIndex, pageSize) => {
    return { length, pageIndex, pageSize }; //TODO Cast to PaginationData
  },
);

/**
 * Sites
 */
export const selectAdminSitesState = createSelector(
  selectFeatureState,
  state => state.sites,
);
export const selectAllAdminSites = createSelector(
  selectAdminSitesState,
  SitesState.selectAll,
);
export const selectAdminSiteEntities = createSelector(
  selectAdminSitesState,
  SitesState.selectEntities,
);

/**
 * Site Controllers Admin Page
 */
export const selectSiteControllersAdminPageState = createSelector(
  selectFeatureState,
  state => state.siteControllersAdminPage,
);

export const selectSiteControllersAdminPageIsLoading = createSelector(
  selectSiteControllersAdminPageState,
  SiteControllersAdminPageState.selectIsLoading,
);

export const selectSiteControllersAdminPageSize = createSelector(
  selectSiteControllersAdminPageState,
  SiteControllersAdminPageState.selectPageSize,
);

export const selectSiteControllersAdminPageIndex = createSelector(
  selectSiteControllersAdminPageState,
  SiteControllersAdminPageState.selectPageIndex,
);

export const selectSiteControllersAdminPageFilter = createSelector(
  selectSiteControllersAdminPageState,
  SiteControllersPageState.selectFilter,
);

export const selectAllAdminSiteControllerViews = createSelector(
  CoreState.selectAllSiteControllers,
  selectAllAdminSites,
  (siteControllers, sites) =>
    siteControllers.map(siteController => {
      const matchingSite = sites.find(site => site.id === siteController.siteId);

      return toSiteControllerViewModelFromInternal(siteController, matchingSite);
    }),
);

export const selectFilteredAdminSiteControllers = createSelector(
  selectSiteControllersAdminPageFilter,
  selectAllAdminSiteControllerViews,
  (filter: string, siteControllers: SiteControllerViewModel[]) => {
    return siteControllers.filter(siteController => {
      const searchTerm = filter.trim().toLowerCase();

      return (
        // Name
        filterBy(searchTerm, siteController.name.toLowerCase()) ||
        // Mac
        filterBy(searchTerm, siteController.macAddress.toLowerCase()) ||
        // ID prefixed with 'id:'
        filterBy(searchTerm, siteController.id.toLowerCase(), 'id:') ||
        // Status prefixed with 'status:'
        filterBy(searchTerm, siteController.status.toLowerCase(), 'status:') ||
        // Version prefixed with 'version:'
        filterBy(searchTerm, siteController.systemVersion ?? '', 'version:')
      );
    });
  },
);

export const selectSiteControllersAdminLength = createSelector(
  selectFilteredAdminSiteControllers,
  siteControllers => siteControllers.length,
);

export const selectPaginatedAdminSiteControllers = createSelector(
  selectFilteredAdminSiteControllers,
  selectSiteControllersAdminPageIndex,
  selectSiteControllersAdminPageSize,
  (siteControllers, index, size) => {
    return siteControllers.slice(index * size, index * size + size);
  },
);

export const selectSiteControllersAdminPaginationData = createSelector(
  selectSiteControllersAdminLength,
  selectSiteControllersAdminPageIndex,
  selectSiteControllersAdminPageSize,
  (length, pageIndex, pageSize) => {
    return { length, pageIndex, pageSize }; //TODO Cast to PaginationData
  },
);

/**
 * Sites Page
 */
export const selectSitesPageState = createSelector(
  selectFeatureState,
  state => state.sitesPage,
);
export const selectSitesPagePending = createSelector(
  selectSitesPageState,
  SitesPageState.selectPending,
);

/**
 * Note: This may require retry logic.
 */
export const selectSitesPageIsReady = createSelector(
  selectSitesPagePending,
  pending => !pending,
);
export const selectSitesPageError = createSelector(
  selectSitesPageState,
  SitesPageState.selectError,
);
export const selectSitesPageFilter = createSelector(
  selectSitesPageState,
  SitesPageState.selectFilter,
);
export const selectFilteredSites = createSelector(
  selectAllAdminSites,
  selectSitesPageFilter,
  (sites, filter) =>
    sites.filter(site => {
      const searchTerm = filter.trim().toLowerCase();
      return (
        // Name
        filterBy(searchTerm, site.name.toLowerCase()) ||
        // ID prefixed with 'id:'
        filterBy(searchTerm, site.id.toLowerCase(), 'id:')
      );
    }),
);
export const selectSitesPageLength = createSelector(
  selectFilteredSites,
  sites => sites.length,
);
export const selectSitesPageIndex = createSelector(
  selectSitesPageState,
  SitesPageState.selectPageIndex,
);
export const selectSitesPageSize = createSelector(
  selectSitesPageState,
  SitesPageState.selectPageSize,
);
export const selectPaginatedSites = createSelector(
  selectFilteredSites,
  selectSitesPageIndex,
  selectSitesPageSize,
  (sites, index, size) => sites.slice(index * size, index * size + size),
);

/**
 * Add Site Controller Page
 */
export const selectAddSiteControllerPageState = createSelector(
  selectFeatureState,
  state => state.addSiteControllersPage,
);

export const selectIsAddSiteControllerPageReady = createSelector(
  selectAddSiteControllerPageState,
  state => !state.isLoading,
);

export const selectIsAddSiteControllerPageLoading = createSelector(
  selectAddSiteControllerPageState,
  AddSiteControllerPageState.selectIsLoading,
);

export const selectSiteControllerNames = createSelector(
  CoreState.selectAllSiteControllers,
  siteControllers => siteControllers.map(siteController => siteController.name),
);
export const selectSiteControllerMacAddresses = createSelector(
  CoreState.selectAllSiteControllers,
  sc => sc.map(siteController => siteController.macAddress),
);

/**
 * Edit Site Controller Page
 */
export const selectEditSiteControllerPageState = createSelector(
  selectFeatureState,
  state => state.editSiteControllersPage,
);

export const selectIsEditSiteControllerPageReady = createSelector(
  selectEditSiteControllerPageState,
  state => !state.isLoading,
);

export const selectIsEditSiteControllerPageSaving = createSelector(
  selectEditSiteControllerPageState,
  state => state.isSaving,
);

export const selectEditSiteControllerId = createSelector(
  selectEditSiteControllerPageState,
  EditSiteControllerPageState.selectSiteControllerId,
);

export const selectEditSiteController = createSelector(
  CoreState.selectAllSiteControllers,
  selectEditSiteControllerId,
  (siteControllers, siteControllerEditId) => {
    const siteController = siteControllers.find(
      siteController => siteController.id === siteControllerEditId,
    );
    return siteController ? siteController : null;
  },
);

export const selectEditSiteControllerFormModel = createSelector(
  selectEditSiteController,
  siteController => (siteController ? toSiteControllerFormModel(siteController) : null),
);

/**
 * Users State
 */
export const selectUsersState = createSelector(selectFeatureState, state => state.users);

export const selectAllUsers = createSelector(selectUsersState, UsersState.selectAll);

export const selectUserEntities = createSelector(
  selectUsersState,
  UsersState.selectEntities,
);

/**
 * Users Page State
 */
export const selectUsersPageState = createSelector(
  selectFeatureState,
  state => state.usersPage,
);
export const selectIsUsersPageLoading = createSelector(
  selectUsersPageState,
  UsersPageState.selectIsLoading,
);
export const selectIsUsersPageReady = createSelector(
  selectIsUsersPageLoading,
  isLoading => !isLoading,
);
export const selectUsersPageFilter = createSelector(
  selectUsersPageState,
  UsersPageState.selectFilter,
);
export const selectFilteredUsers = createSelector(
  selectAllUsers,
  selectUsersPageFilter,
  (users, filter) =>
    users.filter(user => {
      const searchTerm = filter.trim().toLowerCase();
      const userId = user.id.toLowerCase();
      const userSource = userId.startsWith('waad')
        ? 'azure ad'
        : userId.startsWith('auth0')
        ? 'auth0'
        : 'synapse';

      return (
        // Name
        filterBy(searchTerm, user.name.toLowerCase()) ||
        // Email
        filterBy(searchTerm, user.email.toLowerCase()) ||
        // ID prefixed with 'id:'
        filterBy(searchTerm, userId, 'id:') ||
        // Source prefixed with 'source:'
        filterBy(searchTerm, userSource, 'source:')
      );
    }),
);
export const selectUsersPageLength = createSelector(
  selectFilteredUsers,
  users => users.length,
);
export const selectUsersPageIndex = createSelector(
  selectUsersPageState,
  UsersPageState.selectPageIndex,
);
export const selectUsersPageSize = createSelector(
  selectUsersPageState,
  UsersPageState.selectPageSize,
);
export const selectPaginatedUsers = createSelector(
  selectFilteredUsers,
  selectUsersPageIndex,
  selectUsersPageSize,
  (users, index, size) => users.slice(index * size, index * size + size),
);
export const selectUserSites = createSelector(
  selectUsersPageState,
  UsersPageState.selectUserSites,
);

/**
 * Add User Page State
 */
export const selectAddUserPageState = createSelector(
  selectFeatureState,
  state => state.addUserPage,
);
export const selectIsAddUserPageLoading = createSelector(
  selectAddUserPageState,
  AddUserPageState.selectIsLoading,
);
export const selectIsAddUserPageReady = createSelector(
  selectIsAddUserPageLoading,
  isLoading => !isLoading,
);
export const selectIsAddUserPageSaving = createSelector(
  selectAddUserPageState,
  state => state.isSaving,
);

/**
 * Edit User Page State
 */
export const selectEditUserPageState = createSelector(
  selectFeatureState,
  state => state.editUserPage,
);
export const selectIsEditUserPageLoading = createSelector(
  selectEditUserPageState,
  EditUserPageState.selectIsLoading,
);
export const selectIsEditUserPageSaving = createSelector(
  selectEditUserPageState,
  EditUserPageState.selectIsSaving,
);
export const selectEditUserId = createSelector(
  selectEditUserPageState,
  EditUserPageState.selectUserId,
);
export const selectEditUser = createSelector(
  selectUserEntities,
  selectEditUserId,
  (usersById, id) => {
    if (!id) return null;

    const user = usersById[id];

    if (!user) return null;

    return toUserFormModelFromInternal(user);
  },
);

/**
 * Custom Edit Site Page Selectors
 */
export const selectEditSitePageSite = createSelector(
  selectEditSitePageSiteId,
  selectAdminSiteEntities,
  (siteId, siteEntities) => {
    if (!siteId) return null;

    const site = siteEntities[siteId] || null;

    if (!site) return null;

    return toSiteFormModelFromInternal(site);
  },
);

/**
 * Site Controller Details Pane Selectors
 */
export const selectActiveSiteControllerId = createSelector(
  CoreState.selectRouterParams,
  params => params.activeSiteControllerId as string | undefined | null,
);

export const selectActiveSiteControllerView = createSelector(
  CoreState.selectAllSiteControllerViews,
  selectActiveSiteControllerId,
  (siteControllers, id) => siteControllers.find(sc => sc.id === id),
);

export const selectActiveSiteControllerDetailsViewModel = createSelector(
  selectActiveSiteControllerView,
  CoreState.selectSiteEntities,
  (activeSiteController, sites) => {
    if (activeSiteController) {
      const site = activeSiteController.siteId
        ? sites[activeSiteController.siteId] || null
        : null;

      return toSiteControllerDetailsViewModelFromInternal(activeSiteController, site);
    } else {
      return null;
    }
  },
);

/**
 * Site Controller Details Pane State
 */
export const selectSiteControllerDetailsPaneState = createSelector(
  selectFeatureState,
  state => state.siteControllerDetailsPane,
);
export const selectIsSiteControllerDetailsPaneLoading = createSelector(
  selectSiteControllerDetailsPaneState,
  SiteControllerDetailsPaneState.selectIsLoading,
);
