import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { LightInfoCardComponent } from './light-info-card.component';

@NgModule({
  imports: [CommonModule, MatCardModule],
  declarations: [LightInfoCardComponent],
  exports: [LightInfoCardComponent],
})
export class LightInfoCardComponentModule {}
