import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { LightAssetDataCardComponent } from './light-asset-data-card.component';

@NgModule({
  imports: [CommonModule, MatCardModule],
  declarations: [LightAssetDataCardComponent],
  exports: [LightAssetDataCardComponent],
})
export class LightAssetDataCardComponentModule {}
