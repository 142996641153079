import { createAction } from '@ngrx/store';
import {
  EquipmentInternalModel,
  ResourceGroupInternalModel,
} from '@spog-ui/shared/models/resource-groups';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';
import { TriggerInternalModel } from '@spog-ui/shared/models/triggers';

export const loadInitialModelsSuccessAction = createAction(
  '[Equipment Map Layer / API] Load Initial Models Success',
  (equipment: EquipmentInternalModel[]) => {
    return { equipment };
  },
);
export const loadInitialModelsFailureAction = createAction(
  '[Equipment Map Layer / API] Load Initial Models Failure',
  (error: string) => ({ error }),
);

export const loadDeferredModelsSuccessAction = createAction(
  '[Equipment Map Layer / API] Load Deferred Models Success',
  (models: {
    resourceGroups: ResourceGroupInternalModel[];
    industrialSensors: IndieSensorInternalModel[];
    triggers: TriggerInternalModel[];
  }) => {
    return { models };
  },
);

export const loadDeferredModelsFailureAction = createAction(
  '[Equipment Map Layer / API] Load Deferred Models Failure',
  (error: string) => ({ error }),
);
