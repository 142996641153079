import {
  ModbusFunctionCode,
  Bridge485TemplateModelFragment as Bridge485TemplateGQLModel,
} from '@spog-ui/graphql/types';
import { IndustrialSensorDataType } from '@spog-ui/shared/models/indie-sensors';

export { Bridge485TemplateGQLModel };

export function toBridge485TemplateInternalModelFromGQL(
  gql: Bridge485TemplateGQLModel,
): Bridge485TemplateInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    metrics: gql.template.map(metric => ({
      conversion: { polynomial: metric.conversion.polynomial ?? [] },
      dataAddress: metric.dataAddress,
      dataFormat: metric.dataFormat,
      dataType: metric.dataType,
      functionCode: metric.functionCode,
      name: metric.name,
      unitId: metric.unitId,
    })),
  };
}

export interface Bridge485TemplateInternalModel {
  id: string;
  name: string;
  metrics: {
    name: string;
    conversion: { polynomial: number[] };
    /**
     * dataAddress
     * Modbus data address to read. Valid range is 0-65535.
     *
     * Note: This is the Modbus memory address, not the data object number. For example,
     *  the data address of register 1 is 0.
     */
    dataAddress: number;
    dataFormat: string;
    dataType: IndustrialSensorDataType;
    functionCode: ModbusFunctionCode;
    /**
     * unitId
     * Unit ID of Modbus device that is being read. Valid range is 1-247.
     */
    unitId: number;
  }[];
}
