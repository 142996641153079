import { createAction } from '@ngrx/store';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';

export const loadSenseMapLayerModelsSuccessAction = createAction(
  '[Sense Map Layer API] Load Sense Map Layer Models Success',
  (
    indieSensors: IndieSensorInternalModel[],
    siteControllers: SiteControllerInternalModel[],
  ) => ({
    indieSensors: indieSensors,
    siteControllers: siteControllers,
  }),
);

export const loadSenseMapLayerModelsFailureAction = createAction(
  '[Sense Map Layer API] Load Sense Map Layer Models Failure',
  (error: any) => ({ error }),
);
