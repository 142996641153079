import { createReducer, on } from '@ngrx/store';
import { PermissionGroupsPageActions } from '../actions';

export interface Shape {
  isLoading: boolean;
  searchTerm: string;
  pageIndex: number;
  pageSize: number;
  error?: any;
}

export const STATE_KEY = 'permissions-group-page';

export const initialState: Shape = {
  isLoading: true,
  searchTerm: '',
  pageIndex: 0,
  pageSize: 50,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(PermissionGroupsPageActions.loadPageSuccessAction, (state): Shape => {
    return { ...state, isLoading: false, error: null };
  }),
  on(PermissionGroupsPageActions.loadPageFailureAction, (state, action): Shape => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
  on(PermissionGroupsPageActions.filterAction, (state, action) => {
    return { ...state, searchTerm: action.term, pageIndex: 0 };
  }),
  on(PermissionGroupsPageActions.paginateAction, (state, action) => {
    return { ...state, pageIndex: action.pageIndex, pageSize: action.pageSize };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectSearchTerm = (state: Shape) => state.searchTerm;
export const selectPageIndex = (state: Shape) => state.pageIndex;
export const selectPageSize = (state: Shape) => state.pageSize;
