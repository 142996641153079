import { createAction } from '@ngrx/store';
import { EquipmentInternalModel } from '@spog-ui/shared/models/resource-groups';

export const enterAction = createAction('[Equipment Details Page] Enter');

export const loadPageModelsFromGQL = createAction(
  '[Equipment Details Page] Load Page Models',
);

export const closeAction = createAction('[Equipment Details Page] Close');

export const deleteEquipmentAction = createAction(
  '[Equipment Details Page] Delete Equipment',
  (equipment: EquipmentInternalModel) => ({ equipment }),
);

export const confirmDeleteEquipmentAction = createAction(
  '[Equipment Details Page] Confirm Delete Equipment',
  (equipmentId: string) => ({ equipmentId }),
);

export const cancelDeleteEquipmentAction = createAction(
  '[Equipment Details Page] Cancel Delete Equipment',
);

export const leaveAction = createAction('[Equipment Details Page] Leave');
