import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckbox } from '@angular/material/checkbox';
import { TreeSelectOption, TreeSelectOptionView } from './tree-select.models';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  MatTreeNestedDataSource,
  MatTree,
  MatTreeNodeDef,
  MatTreeNode,
  MatTreeNodeToggle,
  MatNestedTreeNode,
  MatTreeNodeOutlet,
} from '@angular/material/tree';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatRipple } from '@angular/material/core';
import { NgIf, NgPlural, NgPluralCase, AsyncPipe } from '@angular/common';
import {
  CdkVirtualScrollViewport,
  CdkFixedSizeVirtualScroll,
} from '@angular/cdk/scrolling';

@Component({
  selector: 'sui-tree-select-options',
  template: `
    <cdk-virtual-scroll-viewport itemSize="64">
      @if ( dataSource.data.length == 0) {
      <span class="noSources">No sources found</span>
      } @if (hiddenCount) {
      <div class="treeSelectHiddenSelectionsWarning" [ngPlural]="hiddenCount">
        <ng-template ngPluralCase="=1">
          1 selected option is hidden behind your filter
        </ng-template>
        <ng-template ngPluralCase="other">
          {{ hiddenCount }} selected options are hidden behind your filter
        </ng-template>
      </div>
      }
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="option-tree">
        <!-- this is the tree node template for leaf nodes -->
        <mat-tree-node
          *matTreeNodeDef="let node"
          matTreeNodeToggle
          [style.display]="!node.shown ? 'none' : 'block'"
        >
          <div
            class="treeSelectOption"
            role="button"
            [attr.aria-pressed]="node.checked"
            [attr.data-option-name]="node.name"
            [attr.data-option-value]="node.value"
            [class.treeSelectOptionChecked]="node.checked"
            (click)="onToggleOption(node)"
          >
            @if (node.svgIcon) {
            <mat-icon class="leafIcon" [svgIcon]="node.svgIcon"></mat-icon>
            } @else {
            <mat-icon class="leafIcon">{{ node.icon }}</mat-icon>
            }
            <span class="name" [innerHTML]="highlight(node.name)"></span>
            <mat-checkbox
              color="primary"
              [disabled]="node.disabled"
              [checked]="node.checked"
            ></mat-checkbox>
          </div>
        </mat-tree-node>

        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node
          *matTreeNodeDef="let node; when: hasChildren"
          [style.display]="!node.shown ? 'none' : 'block'"
        >
          <div class="mat-tree-node" (click)="onToggleExpand(node)">
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name"
              (click)="onToggleExpand(node)"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            <span class="name" [innerHTML]="highlight(node.name)"></span>
          </div>
          <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
          <div
            [@slideVertical]="treeControl.isExpanded(node) ? 'show' : null"
            [class.option-tree-invisible]="!treeControl.isExpanded(node)"
            role="group"
          >
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </cdk-virtual-scroll-viewport>
  `,
  styles: [
    `
      cdk-virtual-scroll-viewport {
        height: 600px;
        border-bottom: 1px solid var(--color-foreground-divider);
      }

      ::ng-deep .highlight {
        background-color: var(--color-500);
      }

      .treeSelectOption {
        display: grid;
        grid-template-columns: 48px 1fr 48px;
        position: relative;
        width: 100%;
        height: 56px;
        align-items: center;
        cursor: pointer;
      }

      .treeSelectOptionChecked {
      }

      .treeSelectOptionChecked:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-500);
        opacity: 0.1;
      }

      .noSources {
        font-style: italic;
        padding-left: 16px;
        padding-top: 16px;
        display: block;
      }

      mat-checkbox {
        margin-left: auto;
        padding-right: 8px;
      }

      mat-icon {
        justify-self: center;
      }

      .leafIcon {
        margin-right: 16px;
        margin-left: 16px;
      }

      .treeSelectHiddenSelectionsWarning {
        display: flex;
        width: calc(100% - 16px);
        margin: 8px auto;
        height: 48px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-style: italic;
        background-color: var(--color-background-selected-button);
        border-radius: 2px;
        opacity: 0.82;
      }

      .mat-tree-node {
        cursor: pointer;
      }

      .option-tree-invisible {
        display: none;
      }

      .option-tree ul,
      .option-tree li {
        margin-top: 0;
        margin-bottom: 0;
        list-style-type: none;
      }

      /*
      * This padding sets alignment of the nested nodes.
      */
      .option-tree .mat-nested-tree-node div[role='group'] {
        padding-left: 24px;
      }

      /*
      * Leaf nodes need to have padding so as to align with other non-leaf nodes
      * under the same parent.
      */
      .option-tree div[role='group'] > .mat-tree-node {
      }

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        width: 100%;
      }
    `,
  ],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } },
  ],
  standalone: true,
  imports: [
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    NgIf,
    NgPlural,
    NgPluralCase,
    MatTree,
    MatTreeNodeDef,
    MatTreeNode,
    MatTreeNodeToggle,
    MatRipple,
    MatIcon,
    MatCheckbox,
    MatNestedTreeNode,
    MatIconButton,
    MatTreeNodeOutlet,
    AsyncPipe,
  ],
})
export class TreeSelectOptionsComponent {
  treeControl = new NestedTreeControl<TreeSelectOptionView>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeSelectOptionView>();

  @Input()
  set options(value: TreeSelectOptionView[]) {
    this.dataSource.data = value;

    this.expandExpandedOptions(value);
  }

  @Input()
  hiddenCount: number;

  @Input()
  filter: string;

  @Output()
  toggleOption = new EventEmitter<TreeSelectOption>();

  @Output()
  toggleExpand = new EventEmitter<TreeSelectOption>();

  expandExpandedOptions(options: TreeSelectOptionView[]) {
    options.forEach(option => {
      if (option.expanded && !this.treeControl.isExpanded(option)) {
        this.treeControl.toggle(option);
      }

      if (option.children) {
        this.expandExpandedOptions(option.children);
      }
    });
  }

  hasChildren = (_: number, node: TreeSelectOptionView) => node.children.length > 0;

  onToggleOption(option: TreeSelectOption) {
    this.toggleOption.emit(option);
  }

  onToggleExpand(option: TreeSelectOption) {
    this.toggleExpand.emit(option);
  }

  highlight(text: string) {
    if (this.filter === '') {
      return text;
    } else {
      const regex = new RegExp(this.filter, 'gi');
      const newText = text.replace(regex, match => {
        return `<mark class="highlight">${match}</mark>`;
      });
      return newText;
    }
  }
}
