import { createReducer, on } from '@ngrx/store';
import {
  EditSiteControllerPageActions,
  EditSiteControllerPageApiActions,
} from '@spog-ui/admin/actions';
import { SiteControllerApiActions } from '@spog-ui/shared/effects/actions';

export interface Shape {
  isLoading: boolean;
  isSaving: boolean;
  siteControllerId: string;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
  isSaving: false,
  siteControllerId: '',
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(EditSiteControllerPageActions.enterAction, (state, action) => ({
    ...initialState,
    siteControllerId: action.siteControllerId,
  })),
  on(SiteControllerApiActions.loadModelsSuccessAction, state => ({
    ...state,
    isLoading: false,
    isSaving: false,
    error: null,
  })),
  on(SiteControllerApiActions.loadModelsFailureAction, (state, action) => ({
    ...state,
    isLoading: false,
    isSaving: false,
    error: action.error,
  })),
  on(EditSiteControllerPageActions.saveAction, state => {
    return {
      ...state,
      isLoading: false,
      isSaving: true,
      error: null,
    };
  }),
  on(EditSiteControllerPageApiActions.saveSuccessAction, state => {
    return {
      ...state,
      isLoading: false,
      isSaving: false,
      error: null,
    };
  }),
  on(EditSiteControllerPageApiActions.saveFailureAction, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isSaving: false,
      error: action.error,
    };
  }),
);

export const selectSaving = (state: Shape) => state.isSaving;
export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectSiteControllerId = (state: Shape) => state.siteControllerId;
