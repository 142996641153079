import { createAction } from '@ngrx/store';

export const enterAction = createAction('[Equipment Map Layer] Enter');

export const loadInitialModelsFromState = createAction(
  '[Equipment Map Layer] Load Initial Models From State',
);

export const loadInitialModelsFromGQL = createAction(
  '[Equipment Map Layer] Load Initial Models From GQL',
);

export const loadDeferredModelsFromState = createAction(
  '[Equipment Map Layer] Load Deferred Models From State',
);

export const loadDeferredModelsFromGQL = createAction(
  '[Equipment Map Layer] Load Deferred Models From GQL',
);

export const filterAction = createAction(
  '[Equipment Map Layer] Filter',
  (searchTerm: string) => ({ searchTerm }),
);

export const toggleStatusTrayFilterAction = createAction(
  '[Equipment Map Page] Toggle Status Tray Filter',
  (
    filteringNormal: boolean,
    filteringCritical: boolean,
    filteringWarning: boolean,
    filteringInfo: boolean,
    filteringUnknown: boolean,
  ) => {
    return {
      filteringNormal,
      filteringCritical,
      filteringWarning,
      filteringInfo,
      filteringUnknown,
    };
  },
);
