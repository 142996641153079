import { EMPTY as empty, fromEvent as observableFromEvent, Observable } from 'rxjs';
import { Provider } from '@angular/core';
import { PlatformService } from '../platform';

export abstract class BodyService {
  abstract addClass(className: string): void;
  abstract removeClass(className: string): void;
  abstract observeEvent(eventName: string): Observable<any>;
  abstract getCssVariable(name: string, fallback: string): string;
}

export class BrowserBodyService implements BodyService {
  addClass(className: string): void {
    document.body.classList.add(className);
  }

  removeClass(className: string): void {
    document.body.classList.remove(className);
  }

  observeEvent(eventName: string): Observable<any> {
    return observableFromEvent(document.body, eventName);
  }

  getCssVariable(name: string, fallback: string): string {
    const computedStyles = window.getComputedStyle(document.documentElement);

    return (computedStyles.getPropertyValue(name) || fallback).trim();
  }
}

export class NodeBodyService implements BodyService {
  addClass(className: string): void {
    return void 0;
  }

  removeClass(className: string): void {
    return void 0;
  }

  observeEvent(eventName: string): Observable<any> {
    return empty;
  }

  getCssVariable(name: string, fallback: string): string {
    return fallback;
  }
}

export function createBodyService(platform: PlatformService): BodyService {
  if (platform.isBrowser()) {
    return new BrowserBodyService();
  }

  return new NodeBodyService();
}

export const BODY_SERVICE_PROVIDER: Provider = {
  provide: BodyService,
  deps: [PlatformService],
  useFactory: createBodyService,
};
