import { createSelector } from '@ngrx/store';
import { toEmailGroupFormModelFromInternal } from '@spog-ui/shared/models/email-groups';
import { selectAllEmailGroups } from '@spog-ui/shared/state/email-groups';
import { selectFeature } from './constants';
import {
  STATE_KEY,
  selectIsLoading,
  selectError,
  selectIsSubmitting,
  selectActiveId,
} from './edit-email-group-page.reducer';

export const selectEditEmailGroupsPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsEditEmailGroupPageLoading = createSelector(
  selectEditEmailGroupsPageFeatureState,
  selectIsLoading,
);
export const selectEditEmailGroupPageError = createSelector(
  selectEditEmailGroupsPageFeatureState,
  selectError,
);
export const selectIsEditEmailGroupPageSubmitting = createSelector(
  selectEditEmailGroupsPageFeatureState,
  selectIsSubmitting,
);
export const selectEmailGroupIdForEdit = createSelector(
  selectEditEmailGroupsPageFeatureState,
  selectActiveId,
);

export const selectEmailGroupForEdit = createSelector(
  selectAllEmailGroups,
  selectEmailGroupIdForEdit,
  (emailGroups, id) => {
    const emailGroupToEdit = emailGroups.find(e => e.id === id);
    return emailGroupToEdit ? toEmailGroupFormModelFromInternal(emailGroupToEdit) : null;
  },
);
