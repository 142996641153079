import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  SuiExtendedFabModule,
  SuiSearchModule,
  SuiSpinnerModule,
} from '@spog-ui/shared/components';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { NoUtilityServicesComponentModule } from '../no-utility-services';
import { UtilityServiceLoadingErrorComponentModule } from '../utility-service-loading-error';
import { UtilityServiceListComponentModule } from '../utility-service-list/utility-service-list.module';
import { UtilityServicesPageComponent } from './utility-services-page.component';

@NgModule({
  imports: [
    CommonModule,
    PermissionsModule,
    RouterModule,
    SuiExtendedFabModule,
    SuiSearchModule,
    SuiSpinnerModule,
    NoUtilityServicesComponentModule,
    PermissionsModule,
    UtilityServiceLoadingErrorComponentModule,
    UtilityServiceListComponentModule,
  ],
  declarations: [UtilityServicesPageComponent],
  exports: [UtilityServicesPageComponent],
})
export class UtilityServicesPageComponentModule {}
