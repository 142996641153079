import { createAction } from '@ngrx/store';
import { TriggerFormModel } from '@spog-ui/shared/models/triggers';

export const enterAction = createAction('[Add Trigger Page] Enter');

export const cancelAction = createAction('[Add Trigger Page] Cancel');

export const selectSourceAction = createAction(
  '[Add Trigger Page] Select Source',
  (sensorId: string) => ({ sensorId }),
);

export const addTriggerAction = createAction(
  '[Add Trigger Page] Add Trigger',
  (trigger: TriggerFormModel) => ({ trigger }),
);
