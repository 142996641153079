import { createAction } from '@ngrx/store';
import { BehaviorInternalModel } from '@spog-ui/shared/models/behaviors';
import { SceneInternalModel, SceneApplication } from '@spog-ui/shared/models/scenes';
import { SceneZoneBehaviorInternalModel } from '@spog-ui/shared/models/scene-zone-behaviors';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';
import { SequenceSceneInternalModel } from '@spog-ui/shared/models/sequence-scenes';
import { ActiveSequenceSceneInternalModel } from '@spog-ui/shared/models/active-sequence-scenes';

export const loadSuccessAction = createAction(
  '[Scenes API] Load Success',
  (models: {
    behaviors: BehaviorInternalModel[];
    scenes: SceneInternalModel[];
    sequenceScenes: SequenceSceneInternalModel[];
    sceneZoneBehaviors: SceneZoneBehaviorInternalModel[];
    zones: ZoneInternalModel[];
    activeSequenceScenes: ActiveSequenceSceneInternalModel[];
  }) => {
    return { models };
  },
);
export const loadFailureAction = createAction(
  '[Scenes API] Load Failure',
  (error: string) => {
    return { error };
  },
);
export const deleteSceneSuccessAction = createAction(
  '[Scenes API] Delete Scene Success',
  (sceneId: string) => {
    return { sceneId };
  },
);
export const deleteSceneFailureAction = createAction(
  '[Scenes API] Delete Scene Failure',
  (application: SceneApplication, sceneId: string, error: string) => {
    return { application, sceneId, error };
  },
);
export const deleteSequenceSceneSuccessAction = createAction(
  '[Scenes API] Delete Sequence Scene Success',
  (sequenceSceneId: string) => {
    return { sequenceSceneId };
  },
);
export const deleteSequenceSceneFailureAction = createAction(
  '[Scenes API] Delete Sequence Scene Failure',
  (sequenceSceneId: string, error: string) => {
    return { sequenceSceneId, error };
  },
);
export const addSceneSuccessAction = createAction(
  '[Scenes API] Add Scene Success',
  (scene: SceneInternalModel) => {
    return { scene };
  },
);
export const addSceneFailureAction = createAction(
  '[Scenes API] Add Scene Failure',
  (error: string) => {
    return { error };
  },
);
export const addSequenceSceneSuccessAction = createAction(
  '[Scenes API] Add Sequence Scene Success',
  (sequenceScene: SequenceSceneInternalModel) => {
    return { sequenceScene };
  },
);
export const addSequenceSceneFailureAction = createAction(
  '[Scenes API] Add Sequence Scene Failure',
  (error: string) => {
    return { error };
  },
);
export const duplicateSceneSuccessAction = createAction(
  '[Scenes API] Duplicate Scene Success',
  (scene: SceneInternalModel) => {
    return { scene };
  },
);
export const duplicateSceneFailureAction = createAction(
  '[Scenes API] Duplicate Scene Failure',
  (error: string) => {
    return { error };
  },
);
export const duplicateSequenceSceneSuccessAction = createAction(
  '[Scenes API] Duplicate Sequence Scene Success',
  (sequenceScene: SequenceSceneInternalModel) => {
    return { sequenceScene };
  },
);
export const duplicateSequenceSceneFailureAction = createAction(
  '[Scenes API] Duplicate Sequence Scene Failure',
  (error: string) => {
    return { error };
  },
);
export const editSceneSuccessAction = createAction(
  '[Scenes API] Edit Scene Success',
  (scene: SceneInternalModel) => {
    return { scene };
  },
);
export const editSceneFailureAction = createAction(
  '[Scenes API] Edit Scene Failure',
  (error: string) => {
    return { error };
  },
);
export const editSequenceSceneSuccessAction = createAction(
  '[Scenes API] Edit Sequence Scene Success',
  (scene: SequenceSceneInternalModel) => {
    return { scene };
  },
);
export const editSequenceSceneFailureAction = createAction(
  '[Scenes API] Edit Sequence Scene Failure',
  (error: string) => {
    return { error };
  },
);
