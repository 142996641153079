import { createReducer, on } from '@ngrx/store';
import {
  ClimateZoneDetailsApiActions,
  ClimateZoneDetailsPaneActions,
} from '@spog-ui/climate-zone-details/actions';

export interface Shape {
  isLoading: boolean;
}

export const initialState: Shape = {
  isLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(
    ClimateZoneDetailsPaneActions.enterAction,
    (): Shape => {
      return {
        ...initialState,
      };
    },
  ),
  on(
    ClimateZoneDetailsApiActions.loadClimateZoneDetailsSuccessAction,
    (state): Shape => {
      return { ...state, isLoading: false };
    },
  ),
  on(
    ClimateZoneDetailsApiActions.loadClimateZoneDetailsFailureAction,
    (state): Shape => {
      return { ...state, isLoading: false };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
