import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  toOrganizationInternalModelFromGQL,
  OrganizationInternalModel,
} from '@spog-ui/shared/models/organizations';

@Injectable({ providedIn: 'root' })
export class OrganizationApiService {
  constructor(readonly gql: GraphQLAPIService) {}

  getAll(): Observable<OrganizationInternalModel[]> {
    return this.gql
      .getAllOrganizationsAsAdmin()
      .pipe(
        map(response =>
          response.allOrganizations.map(toOrganizationInternalModelFromGQL),
        ),
      );
  }

  delete(id: string): Observable<string | undefined | null> {
    return this.gql
      .deleteOrganization({ input: { id } })
      .pipe(map(response => response.deleteOrganization));
  }
}
