import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Bridge485TemplateInternalModel } from '@spog-ui/shared/models/bridge-485-templates';
import { Bridge485TemplatesStateActions } from '../actions';

export type Shape = EntityState<Bridge485TemplateInternalModel>;

export const adapter = createEntityAdapter<Bridge485TemplateInternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    Bridge485TemplatesStateActions.loadBridge485TemplatesAction,
    (state, action): Shape => {
      return adapter.upsertMany(action.templates, state);
    },
  ),
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
