import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { SceneViewInternalModel } from '@spog-ui/shared/models/scene-views';
import { SceneViewsApiActions } from '@spog-ui/scene-views/actions';

export type Shape = EntityState<SceneViewInternalModel>;

export const adapter = createEntityAdapter({
  sortComparer: sortByName,
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(SceneViewsApiActions.loadSuccessAction, (state, action) => {
    return adapter.setAll(action.models.sceneViews, state);
  }),
  on(SceneViewsApiActions.addSceneViewSuccessAction, (state, action) => {
    return adapter.addOne(action.sceneView, state);
  }),
  on(SceneViewsApiActions.deleteSceneViewSuccessAction, (state, action) => {
    return adapter.removeOne(action.sceneViewId, state);
  }),
);

export function sortByName(a: SceneViewInternalModel, b: SceneViewInternalModel) {
  return a.name.localeCompare(b.name);
}

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
