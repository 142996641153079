import {
  Component,
  HostBinding,
  Inject,
  InjectionToken,
  OnDestroy,
  Optional,
} from '@angular/core';
import { CdkPortal } from '@angular/cdk/portal';
import { asyncScheduler, Observable, SchedulerLike, Subscription } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { SuiPanelState } from '../panel';
import { CharmsService } from './charms.service';

export const CHARMS_OUTLET_SCHEDULER = new InjectionToken<SchedulerLike>(
  '@sui/charms-outlet/scheduler',
);

@Component({
  selector: 'sui-charms-outlet',
  template: `
    <ng-container *ngFor="let charm of charms$ | async">
      <ng-template [cdkPortalOutlet]="charm"></ng-template>
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        position: relative;
        right: 0;
        transition: right 200ms;
      }

      :host(.suiCharmsOutletPushed) {
        right: 360px;
      }
    `,
  ],
})
export class CharmsOutletComponent implements OnDestroy {
  isPushedSubscription: Subscription;
  charms$: Observable<Set<CdkPortal>>;
  @HostBinding('class.suiCharmsOutletPushed') pushed = false;

  constructor(
    charmsService: CharmsService,
    panelState: SuiPanelState,
    @Inject(CHARMS_OUTLET_SCHEDULER) @Optional() scheduler: SchedulerLike,
  ) {
    this.charms$ = charmsService.charms$.pipe(observeOn(scheduler ?? asyncScheduler));
    this.isPushedSubscription = panelState.isLayoutPushed$.subscribe(
      isPushed => (this.pushed = isPushed),
    );
  }

  ngOnDestroy() {
    this.isPushedSubscription?.unsubscribe();
  }
}
