import {
  CommissioningStatus,
  CreateThermostatInput,
  ThermostatControlModeInput,
  ThermostatControlModeModelFragment,
  ThermostatFanMode,
  ThermostatModelFragment,
  UpdateThermostatInput,
  ThermostatAlarmModelFragment,
  ThermostatAlarmReason,
} from '@spog-ui/graphql/types';
import { DateTime } from 'luxon';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';

export { ThermostatFanMode };
export type ThermostatGQLModel = ThermostatModelFragment;

export interface FloorPlanLocationInternalModel {
  x: number;
  y: number;
  floorPlanId: string;
}

export interface ThermostatControlModeInternalModel {
  coolF: number;
  heatF: number;
  fanMode: ThermostatFanMode;
}

export interface ThermostatStateInternalModel {
  temperatureF: number;
  humidity: number;
  lastMessageReceived: string;
}

/**
 * Normalized Thermostat appropriate for keeping in the store
 */
interface PreCommissionedThermostat {
  commissioningStatus: CommissioningStatus.PRE_COMMISSIONED;
  snapaddr?: string | null;
  siteControllerId?: string | null;
  state?: null;
  make?: null;
  model?: null;
}

interface CommissionedThermostat {
  commissioningStatus: CommissioningStatus.COMMISSIONED;
  snapaddr: string;
  siteControllerId: string;
  state?: null;
  make?: null;
  model?: null;
}

interface ConnectedThermostat {
  commissioningStatus: CommissioningStatus.CONNECTED;
  snapaddr: string;
  siteControllerId: string;
  state: ThermostatStateInternalModel;
  make: string;
  model: string;
}

export type ThermostatInternalModel = {
  id: string;
  name: string;
  controlMode: ThermostatControlModeInternalModel;
  fallbackControlMode: ThermostatControlModeInternalModel;
  floorPlanId: string | null;
  floorPlanX: number | null;
  floorPlanY: number | null;
  totalAlarms?: number;
} & (PreCommissionedThermostat | CommissionedThermostat | ConnectedThermostat);

export type PositionedThermostatModel = {
  floorPlanId: string;
  floorPlanX: number;
  floorPlanY: number;
} & ThermostatInternalModel;

/**
 * Thermostat Appropriate for Displaying in the View
 */
export interface ThermostatViewModel {
  id: string;
  name: string;
  make: string;
  model: string;
  siteControllerId: string | null;
  siteControllerName: string | null;
  snapaddr: string | null;
  commissioningStatus: CommissioningStatus;
  controlMode: ThermostatControlModeInternalModel;
  fallbackControlMode: ThermostatControlModeInternalModel;
  state?: {
    temperatureF: number;
    humidity: number;
    lastMessageReceived: DateTime;
  };
}

export enum ThermostatStatus {
  ok = 0b00000,
  communicationFailure = 0b00001,
  configurationFailure = 0b00010,
  linkQualityWarning = 0b00100,
  missingScriptFailure = 0b01000,
  censusFailure = 0b10000,
}

/**
 * Thermostat from our WebSocket API
 */

// Types of WS messages
// basic config ws when created or updated
// setpoint has changed
// new temp reading or humidity reading

export interface ThermostatWSModel {
  id: string;
  name: string;
  snapaddr: string | null;
  siteControllerId: string | null;
  controlMode: ThermostatControlModeInternalModel;
  fallbackControlMode: ThermostatControlModeInternalModel;
  commissioningStatus: CommissioningStatus;
  floorPlanLocation: FloorPlanLocationInternalModel | null;
  state: {
    temperatureF: number;
    humidity: number;
    lastMessageReceived: string; // ISO-formatted datetime
  } | null;
  make: string | null;
  model: string | null;
}

/**
 * Thermostat State Change from our WebSocket API
 */
export interface ThermostatStateChangeWSModel {
  type: 'thermostatStateChange';
  siteId: string;
  thermostatId: string;
  controlMode: ThermostatControlModeInternalModel;
  temperatureF: number;
  humidity: number;
  lastMessageReceived: string; // ISO-formatted datetime
}

export type ThermostatFormModel = {
  id?: string;
  name: string;
  fallbackControlMode: ThermostatControlModeInternalModel;
  snapaddr: string;
  siteControllerId: string;
};

export interface ClimateLegendViewModel {
  ok: number;
  precommissioned: number;
  alarmed: number;
}

export interface ClimateLegendFilterModel {
  filteringOk: boolean;
  filteringAlarmed: boolean;
  filteringPrecommissioned: boolean;
}

export function toThermostatControlModeFromGQL(
  gql: ThermostatControlModeModelFragment,
): ThermostatControlModeInternalModel {
  return {
    coolF: gql.cool.F,
    heatF: gql.heat.F,
    fanMode: gql.fanMode,
  };
}

export function toThermostatInternalModelFromGQL(
  gql: ThermostatGQLModel,
): ThermostatInternalModel {
  const thermostat = {
    id: gql.id,
    name: gql.name,
    controlMode: toThermostatControlModeFromGQL(gql.controlMode),
    fallbackControlMode: toThermostatControlModeFromGQL(gql.fallbackControlMode),
    floorPlanId: gql.floorPlanLocation?.floorPlan.id ?? null,
    floorPlanX: gql.floorPlanLocation?.x ?? null,
    floorPlanY: gql.floorPlanLocation?.y ?? null,
  };

  switch (gql.commissioningStatus) {
    default:
    case CommissioningStatus.PRE_COMMISSIONED:
      return {
        ...thermostat,
        siteControllerId: gql.siteController?.id,
        snapaddr: gql.snapaddr,
        commissioningStatus: CommissioningStatus.PRE_COMMISSIONED,
      };
    case CommissioningStatus.COMMISSIONED:
      return {
        ...thermostat,
        commissioningStatus: CommissioningStatus.COMMISSIONED,
        snapaddr: gql.snapaddr!,
        siteControllerId: gql.siteController!.id,
      };
    case CommissioningStatus.CONNECTED:
      return {
        ...thermostat,
        commissioningStatus: CommissioningStatus.CONNECTED,
        snapaddr: gql.snapaddr!,
        siteControllerId: gql.siteController!.id,
        state: {
          temperatureF: gql.state!.temperature.F,
          humidity: gql.state!.humidity,
          lastMessageReceived: gql.state!.lastMessageReceived,
        },
        make: gql.make!,
        model: gql.model!,
      };
  }
}

export interface ThermostatAlarmInternalModel {
  id: string;
  reason: ThermostatAlarmReason;
  triggerAt: string;
  siteControllerId: string;
  thermostatId: string;
}

export interface ThermostatAlarmWSModel {
  id: string;
  reason: ThermostatAlarmReason;
  triggerAt: string;
  siteControllerId: string;
  thermostatId: string;
  alarmCleared: boolean;
}

export function toThermostatInternalAlarmModelFromGQL(
  gql: ThermostatAlarmModelFragment,
  thermostatId: string,
): ThermostatAlarmInternalModel {
  return {
    id: gql.id,
    reason: gql.reason,
    triggerAt: gql.triggerAt,
    siteControllerId: gql.siteController.id,
    thermostatId: thermostatId,
  };
}

export function toThermostatInternalModelFromWS(
  ws: ThermostatWSModel,
): ThermostatInternalModel {
  const thermostat = {
    id: ws.id,
    name: ws.name,
    controlMode: ws.controlMode,
    fallbackControlMode: ws.fallbackControlMode,
    floorPlanId: ws.floorPlanLocation?.floorPlanId ?? null,
    floorPlanX: ws.floorPlanLocation?.x ?? null,
    floorPlanY: ws.floorPlanLocation?.y ?? null,
  };

  switch (ws.commissioningStatus) {
    default:
    case CommissioningStatus.PRE_COMMISSIONED:
      return {
        ...thermostat,
        commissioningStatus: ws.commissioningStatus,
      };
    case CommissioningStatus.COMMISSIONED:
      return {
        ...thermostat,
        commissioningStatus: ws.commissioningStatus,
        snapaddr: ws.snapaddr!,
        siteControllerId: ws.siteControllerId!,
      };
    case CommissioningStatus.CONNECTED:
      return {
        ...thermostat,
        commissioningStatus: ws.commissioningStatus,
        snapaddr: ws.snapaddr!,
        siteControllerId: ws.siteControllerId!,
        state: ws.state!,
        make: ws.make!,
        model: ws.model!,
      };
  }
}

export function toThermostatStateInternalModelFromWS(
  ws: ThermostatStateChangeWSModel,
): ThermostatStateInternalModel {
  return {
    temperatureF: ws.temperatureF,
    humidity: ws.humidity,
    lastMessageReceived: ws.lastMessageReceived,
  };
}

/**
 * Inflates a thermastat in the store into a model that is
 * ready to be displayed in the UI
 */
export function toThermostatViewModelFromInternal(
  internal: ThermostatInternalModel,
  timezone: string,
  siteController?: SiteControllerInternalModel,
): ThermostatViewModel {
  return {
    id: internal.id,
    name: internal.name ?? '',
    make: internal.make ?? '',
    model: internal.model ?? '',
    siteControllerId: siteController?.id ?? null,
    siteControllerName: siteController?.name ?? null,
    snapaddr: internal.snapaddr ?? '',
    controlMode: internal.controlMode,
    fallbackControlMode: internal.fallbackControlMode,
    commissioningStatus: internal.commissioningStatus,
    state: internal.state
      ? {
          ...internal.state,
          lastMessageReceived: DateTime.fromISO(internal.state.lastMessageReceived, {
            zone: timezone,
          }),
        }
      : undefined,
  };
}

/**
 * Converts a Thermostat form model into one ready to be used
 * by the GraphQL create operation
 */
export function toCreateThermostatInputFromForm(
  form: ThermostatFormModel,
  siteId: string,
): CreateThermostatInput {
  return {
    name: form.name,
    siteId,
    fallbackControlMode: {
      cool: {
        F: form.fallbackControlMode.coolF,
      },
      heat: {
        F: form.fallbackControlMode.heatF,
      },
      fanMode: form.fallbackControlMode.fanMode,
    },
    snapaddr: form.snapaddr,
    siteControllerId: form.siteControllerId,
  };
}

/**
 * Converts a Thermostat form model into one ready to be used
 * by GraphQL update operation
 */
export function toUpdateThermostatInputFromForm(
  form: ThermostatFormModel,
): UpdateThermostatInput {
  return {
    id: form.id!,
    name: form.name,
    fallbackControlMode: {
      cool: { F: form.fallbackControlMode.coolF },
      heat: { F: form.fallbackControlMode.heatF },
      fanMode: form.fallbackControlMode.fanMode,
    },
    snapaddr: form.snapaddr,
    siteControllerId: form.siteControllerId,
  };
}

export function isThermostatPrecommissioned(thermostat: ThermostatInternalModel) {
  return thermostat.commissioningStatus === CommissioningStatus.PRE_COMMISSIONED;
}

export function isThermostatAlarmed(alarm: number) {
  return (
    alarm &
    (ThermostatStatus.communicationFailure |
      ThermostatStatus.configurationFailure |
      ThermostatStatus.linkQualityWarning |
      ThermostatStatus.missingScriptFailure)
  );
}

export function getClimateLegendViewModel(
  thermostats: ThermostatInternalModel[],
  alarmedThermostats: { [thermostatId: string]: number },
): ClimateLegendViewModel {
  return thermostats.reduce(
    (counts, thermostat) => {
      if (thermostat.floorPlanId) {
        const isPrecommissioned =
          thermostat.commissioningStatus === CommissioningStatus.PRE_COMMISSIONED;

        const isAlarmed =
          alarmedThermostats[thermostat.id] &
          (ThermostatStatus.communicationFailure |
            ThermostatStatus.configurationFailure |
            ThermostatStatus.linkQualityWarning |
            ThermostatStatus.missingScriptFailure);

        if (isPrecommissioned) {
          ++counts.precommissioned;
        } else if (isAlarmed) {
          ++counts.alarmed;
        } else {
          ++counts.ok;
        }
      }

      return counts;
    },
    { alarmed: 0, ok: 0, precommissioned: 0 },
  );
}

export function toThermostatControlModeInputFromInternal(
  internal: ThermostatControlModeInternalModel,
): ThermostatControlModeInput {
  return {
    cool: { F: internal.coolF },
    heat: { F: internal.heatF },
    fanMode: internal.fanMode,
  };
}
