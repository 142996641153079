import { createSelector } from '@ngrx/store';
import { STATE_KEY } from './add-permission-group-page.reducer';
import { selectFeature } from './constants';

import {
  selectIsLoading,
  selectError,
  selectIsSubmitting,
} from './add-permission-group-page.reducer';

export const selectAddPermissionGroupsPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsAddPermissionGroupPageLoading = createSelector(
  selectAddPermissionGroupsPageFeatureState,
  selectIsLoading,
);

export const selectAddPermissionGroupPageError = createSelector(
  selectAddPermissionGroupsPageFeatureState,
  selectError,
);

export const selectIsAddPermissionGroupPageSubmitting = createSelector(
  selectAddPermissionGroupsPageFeatureState,
  selectIsSubmitting,
);
