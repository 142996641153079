import { Pipe, PipeTransform } from '@angular/core';

/* The purpose of this pipe is to remove Behavior ID tokens that
 * are not intended to be visible in the UI.
 *
 * These tokens exist because, in some circumstances, behaviors exist
 * that should appear to have the same name in the UI as others we
 * have already defined. Due to the Behavior ID acting as both a backend
 * ID and a user-facing display name, these tokens ensure back-end ID
 * uniqueness while also allowing the display name to be the same as
 * another behavior.
 *
 * For example, there exists a Paparazzi behavior, as well as a Monochrome
 * Paparazzi behavior. Due to licensing, the user will only ever have one of
 * these Paparazzi variants available to them at a time, and they should
 * appear under the same name in the UI.
 *
 * Tokens are part of the Behavior Type string and always begin with '$' and
 * end with a trailing whitespace.
 *
 * Example: "$MONO Paparazzi" will appear as "Paparazzi" in the UI when using
 * this pipe.
 */

@Pipe({
    name: 'suiRemoveBehaviorIDToken',
    standalone: true
})
export class RemoveBehaviorIDTokenPipe implements PipeTransform {
  transform(input: string) {
    if (!input) return null;

    // Match words that start with $, including trailing whitespace.
    // i.e. $MONO
    const matchedToken = input.match(/\$\w+\s/g);
    return matchedToken && matchedToken.length > 0
      ? input.replace(matchedToken[0], '')
      : input;
  }
}
