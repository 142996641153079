import { Injectable, Provider } from '@angular/core';
import { ImageLoader } from '../../services';

@Injectable()
export class ImageLoaderBrowser implements ImageLoader {
  async loadImage(src: string): Promise<HTMLImageElement> {
    const img = document.createElement('img');
    img.src = src;

    await new Promise(resolve => (img.onload = resolve));

    return img;
  }
}

export const IMAGE_LOADER_BROWSER_PROVIDERS: Provider[] = [
  { provide: ImageLoader, useClass: ImageLoaderBrowser },
];
