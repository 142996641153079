import { createReducer, on } from '@ngrx/store';
import { TriggersApiActions, TriggersPageActions } from '@spog-ui/triggers/actions';

export interface Shape {
  isLoading: boolean;
  searchTerm: string;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
  searchTerm: '',
};

export const reducer = createReducer(
  initialState,
  on(TriggersPageActions.enterAction, () => initialState),
  on(TriggersApiActions.loadTriggersPageModelsSuccessAction, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(TriggersApiActions.loadTriggersPageModelsFailureAction, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
  on(TriggersPageActions.filterAction, (state, action) => {
    return {
      ...state,
      searchTerm: action.searchTerm,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectSearchTerm = (state: Shape) => state.searchTerm;
