import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BehaviorInternalModel } from '@spog-ui/shared/models/behaviors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sui-behavior-select',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BehaviorSelectComponent,
      multi: true,
    },
  ],
  template: `
    <mat-form-field>
      <mat-label>{{ placeholder }}</mat-label>
      @if (behaviors?.length > 1) {
        <mat-select
          [attr.placeholder]="placeholder"
          [formControl]="control"
          >
          <mat-select-trigger>
            {{ control.value | suiRemoveBehaviorIDToken }}
          </mat-select-trigger>
          @for (behaviors of behaviorGroups; track behaviors; let lastGroup = $last) {
            <div
              class="suiBehaviorGroup"
              >
              @for (behavior of behaviors; track behavior) {
                <mat-option
                  [value]="behavior.id"
                  [attr.data-behavior-id]="behavior.id"
                  [class.suiBehaviorUnlicensed]="!behavior.isLicensed"
                  >
                  <div class="suiBehaviorOption">
                    <span>{{ behavior.id | suiRemoveBehaviorIDToken }}</span>
                    @if (!behavior.isLicensed) {
                      <span class="warning">No license found</span>
                    }
                  </div>
                </mat-option>
              }
            </div>
          }
        </mat-select>
      } @else {
        <input
          matInput
          [formControl]="control"
          [placeholder]="placeholder"
          readonly=""
          [value]="readOnlyControlValue"
          />
      }
    </mat-form-field>
    `,
  styles: [
    `
      mat-form-field {
        width: 100%;
      }

      .suiBehaviorGroup {
        padding-bottom: 20px;
        position: relative;
      }

      .suiBehaviorGroup:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 10px;
        left: 16px;
        right: 16px;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.12);
      }

      .suiBehaviorGroup:last-child {
        padding-bottom: 0;
      }

      .suiBehaviorGroup:last-child:after {
        display: none;
      }

      .suiBehaviorUnlicensed {
        color: var(--color-foreground-disabled-text);
      }

      .suiBehaviorOption {
        display: grid;
        grid-template-columns: 2fr 1fr;
        border: none;
      }

      .warning {
        font-size: 0.7em;
        color: var(--color-foreground-disabled-text);
      }
    `,
  ],
})
export class BehaviorSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  control = new UntypedFormControl();
  controlSubscriptions = new Subscription();
  onChange: (value: any) => any;
  onTouched: () => any;
  behaviorGroups: BehaviorInternalModel[][];
  _behaviors: BehaviorInternalModel[];

  @Input() placeholder = 'Behavior Type';

  @Input()
  set behaviors(behaviors: BehaviorInternalModel[]) {
    if (behaviors === this._behaviors) {
      return;
    }

    this._behaviors = behaviors;
    const seenGroup: { [group: number]: boolean } = {};
    const groups: number[] = [];

    for (const { displayGroup } of behaviors) {
      if (!seenGroup[displayGroup]) {
        seenGroup[displayGroup] = true;
        groups.push(displayGroup);
      }
    }

    this.behaviorGroups = groups
      .sort()
      .map(group =>
        behaviors
          .filter(({ displayGroup }) => displayGroup === group)
          .sort((first, second) => first.displayOrder - second.displayOrder),
      );
  }

  get behaviors() {
    return this._behaviors;
  }

  get readOnlyControlValue() {
    return this.behaviors && this.behaviors.length === 1
      ? this.behaviors[0].id
      : 'No Behaviors Found';
  }

  ngOnInit() {
    this.controlSubscriptions.add(
      this.control.valueChanges.subscribe(value => {
        if (this.onChange) {
          this.onChange(value);
        }
      }),
    );

    this.controlSubscriptions.add(
      this.control.statusChanges.subscribe(() => {
        if (this.onTouched) {
          this.onTouched();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.controlSubscriptions.unsubscribe();
  }

  writeValue(value: string) {
    this.control.patchValue(value, { emitEvent: false, onlySelf: true });
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.control.disable() : this.control.enable();
  }
}
