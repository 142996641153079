import { createFeatureSelector } from '@ngrx/store';
import * as AddBridge485PageState from './add-bridge-485-page.reducer';
import * as EditBridge485PageState from './edit-bridge-485-page.reducer';

export const bridge485sStateFeatureKey = 'bridge485s-feature';

export const selectFeature = createFeatureSelector<{
  [AddBridge485PageState.STATE_KEY]: AddBridge485PageState.Shape;
  [EditBridge485PageState.STATE_KEY]: EditBridge485PageState.Shape;
}>(bridge485sStateFeatureKey);
