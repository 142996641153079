import { createAction } from '@ngrx/store';
import { EquipmentFormModel } from '@spog-ui/shared/models/equipment';

export const enterAction = createAction('[Add Equipment Page] Enter');

export const loadPageModelsFromGQL = createAction(
  '[Add Equipment Page] Load Page Models',
);

export const addEquipmentAction = createAction(
  '[Add Equipment Page] Add Equipment',
  (equipment: EquipmentFormModel) => ({ equipment }),
);
