import { NgModule } from '@angular/core';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { EffectsModule } from '@ngrx/effects';
import { AnalyticsEffects } from './effects';

@NgModule({
  imports: [
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    EffectsModule.forFeature([AnalyticsEffects]),
  ],
})
export class AnalyticsModule {}
