import { createAction } from '@ngrx/store';
import { AreaModel } from '@spog-ui/shared/models/map-positioners';

export const enterAction = createAction('[Map Positioner / Step Two Grid] Enter');
export const leaveAction = createAction('[Map Positioner / Step Two Grid] Leave');
export const selectAreaAction = createAction(
  '[Map Positioner / Step Two Grid] Select Area',
  (area: AreaModel) => ({ area }),
);
export const moveAreaAction = createAction(
  '[Map Positioner / Step Two Grid] Move Area',
  (offset: { x: number; y: number }) => ({ offset }),
);
export const pickPanAndZoomToolAction = createAction(
  '[Map Positioner / Step Two Grid] Pick Pan & Zoom Tool',
);
export const pickAreaSelectToolAction = createAction(
  '[Map Positioner / Step Two Grid] Pick Area Select Tool',
);
export const pickRows = createAction(
  '[Map Positioner / Step Two Grid] Pick Rows',
  (rows: number) => ({ rows }),
);
export const pickCols = createAction(
  '[Map Positioner / Step Two Grid] Pick Columns',
  (cols: number) => ({ cols }),
);
