import {
  AstroEvent,
  CreateScheduledEventInput,
  ScheduledEventModelFragment,
  UpdateScheduledEventInput,
} from '@spog-ui/graphql/types';
import { LocationSettings } from '@spog-ui/shared/models/location';
import {
  AstroTime,
  Day,
  findRRULESet,
  Frequency,
  getDateRange,
  parseRRULE,
  RecurrenceModel,
  ScheduledSceneModel,
  serializeRRULE,
} from '@spog-ui/utils/rrule';
import { DateTime } from 'luxon';

export interface ScheduledEventInternalModel {
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  id: string;
  name: string;
  rrule: string | null;
  sceneIds: string[];
  sequenceSceneIds: string[];
  start: string;
}

export type ScheduledEventGQLModel = ScheduledEventModelFragment;
type AstroEventStartTime = { startsAt: string; event: AstroEvent; offset: number };

export interface ScheduledEventFormModel {
  id: string;
  name: string;
  sceneIds: string[];
  sequenceSceneIds: string[];
  start: DateTime;
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  recurrence: RecurrenceModel | null;
}

export interface ScheduledEventViewModel {
  id: string;
  name: string;
  sceneIds: string[];
  sequenceSceneIds: string[];
  start: DateTime;
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  recurrence: RecurrenceModel | null;
}

export interface ScheduledEventWSModel {
  id: string;
  sceneIds: string[];
  name: string;
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  start: string;
  rrule: string | null;
}

export interface ScheduledEventOccurrenceModel {
  id: string;
  name: string;
  sceneIds: string[];
  sequenceSceneIds: string[];
  start: DateTime;
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  recurrence: RecurrenceModel | null;
  occurrenceDateTime: DateTime;
}

export interface OccurrenceViewModel {
  id: string;
  name: string;
  sceneIds: string[];
  sequenceSceneIds: string[];
  start: DateTime;
  astroTime: AstroTime | null;
  astroTimeOffset: number | null;
  recurrence: RecurrenceModel | null;
  occurrenceDateTime: DateTime;
  sceneNames: string[];
  sceneZones: { id: string; name: string }[];
}

export function toScheduledEventViewFromOccurrenceView(
  occurrence: OccurrenceViewModel,
): ScheduledEventViewModel {
  const { sceneNames, occurrenceDateTime, sceneZones, ...viewModel } = occurrence;

  return {
    ...viewModel,
    recurrence: viewModel.recurrence ? { ...viewModel.recurrence } : null,
  };
}

export function toScheduledEventInternalModelFromGQL(
  gql: ScheduledEventGQLModel,
): ScheduledEventInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    astroTime:
      toAstroTimeFromAstroEvent((gql.start as AstroEventStartTime).event) ?? null,
    astroTimeOffset: (gql.start as AstroEventStartTime).offset ?? null,
    start: gql.start.startsAt,
    rrule: gql.recurrence?.rrule ?? null,
    ...(gql.schedulables
      ? {
          sceneIds: gql.schedulables
            .filter(schedulable => schedulable.__typename === 'Scene')
            .map(scene => scene.id),
          sequenceSceneIds: gql.schedulables
            .filter(schedulable => schedulable.__typename === 'SceneSequence')
            .map(scene => scene.id),
        }
      : { sceneIds: [], sequenceSceneIds: [] }),
  };
}

function toAstroTimeFromAstroEvent(astroEvent: AstroEvent): AstroTime {
  switch (astroEvent) {
    case AstroEvent.DAWN:
      return AstroTime.Dawn;
    case AstroEvent.DUSK:
      return AstroTime.Dusk;
    case AstroEvent.SUNRISE:
      return AstroTime.Sunrise;
    case AstroEvent.SUNSET:
      return AstroTime.Sunset;
  }
}

export function toScheduledEventFormModelFromInternal(
  scheduledEvent: ScheduledEventInternalModel,
): ScheduledEventFormModel {
  return {
    id: scheduledEvent.id,
    name: scheduledEvent.name,
    sceneIds: scheduledEvent.sceneIds,
    sequenceSceneIds: scheduledEvent.sequenceSceneIds,
    astroTime:
      scheduledEvent.astroTime === null ? null : (scheduledEvent.astroTime as AstroTime),
    astroTimeOffset: scheduledEvent.astroTimeOffset
      ? scheduledEvent.astroTimeOffset
      : null,
    start: DateTime.fromISO(scheduledEvent.start, { setZone: true }),
    recurrence: scheduledEvent.rrule ? parseRRULE(scheduledEvent.rrule) : null,
  };
}

export function toScheduledEventViewModelFromInternal(
  scheduledEvent: ScheduledEventInternalModel,
): ScheduledEventViewModel {
  return {
    id: scheduledEvent.id,
    name: scheduledEvent.name,
    sceneIds: scheduledEvent.sceneIds,
    sequenceSceneIds: scheduledEvent.sequenceSceneIds,
    start: DateTime.fromISO(scheduledEvent.start, { setZone: true }),
    astroTime:
      scheduledEvent.astroTime === null ? null : (scheduledEvent.astroTime as AstroTime),
    astroTimeOffset: scheduledEvent.astroTimeOffset
      ? scheduledEvent.astroTimeOffset
      : null,
    recurrence: scheduledEvent.rrule ? parseRRULE(scheduledEvent.rrule) : null,
  };
}

export function toCreateScheduledEventInputFromForm(
  scheduledEvent: ScheduledEventFormModel,
  siteId: string,
): CreateScheduledEventInput {
  return {
    name: scheduledEvent.name,
    siteId,
    schedulableIds: [...scheduledEvent.sceneIds, ...scheduledEvent.sequenceSceneIds],
    astroStartTime:
      scheduledEvent.astroTime !== null && scheduledEvent.astroTimeOffset !== null
        ? {
            startsAt: scheduledEvent.start.toISO(),
            event: toAstroEventFromAstroTime(scheduledEvent.astroTime),
            offset: scheduledEvent.astroTimeOffset,
          }
        : null,
    customStartTime:
      scheduledEvent.astroTime === null
        ? { startsAt: scheduledEvent.start.toISO() }
        : null,
    rrule: serializeRRULE(scheduledEvent.recurrence),
  };
}

export function toUpdateScheduledEventInputFromForm(
  scheduledEvent: ScheduledEventFormModel,
): UpdateScheduledEventInput {
  return {
    id: scheduledEvent.id,
    name: scheduledEvent.name,
    schedulableIds: [...scheduledEvent.sceneIds, ...scheduledEvent.sequenceSceneIds],
    astroStartTime:
      scheduledEvent.astroTime !== null && scheduledEvent.astroTimeOffset !== null
        ? {
            startsAt: scheduledEvent.start.toISO(),
            event: toAstroEventFromAstroTime(scheduledEvent.astroTime),
            offset: scheduledEvent.astroTimeOffset,
          }
        : null,
    customStartTime:
      scheduledEvent.astroTime === null
        ? { startsAt: scheduledEvent.start.toISO() }
        : null,
    rrule: serializeRRULE(scheduledEvent.recurrence),
  };
}

function toAstroEventFromAstroTime(astroTime: AstroTime): AstroEvent {
  switch (astroTime) {
    case AstroTime.Dawn:
      return AstroEvent.DAWN;
    case AstroTime.Dusk:
      return AstroEvent.DUSK;
    case AstroTime.Sunrise:
      return AstroEvent.SUNRISE;
    case AstroTime.Sunset:
      return AstroEvent.SUNSET;
  }
}

export function toScheduledEventInternalModelFromView(
  view: ScheduledEventViewModel,
): ScheduledEventInternalModel {
  const startString = view.start.toFormat('yyyy-MM-dd HH:mm:ss');

  return {
    id: view.id,
    name: view.name,
    sceneIds: view.sceneIds,
    sequenceSceneIds: view.sequenceSceneIds,
    astroTime: view.astroTime,
    astroTimeOffset: view.astroTimeOffset,
    start: startString,
    rrule: serializeRRULE(view.recurrence),
  };
}

export function getScheduledEventOccurrences(
  scheduledEvents: ScheduledEventViewModel[],
  startDateTime: DateTime,
  endDateTime: DateTime,
  locationSettings: LocationSettings,
): ScheduledEventOccurrenceModel[] {
  /**
   *  Converting the astroTime to be lowercase instead since the API
   *  is giving back uppercase and the rrule lib is using lowercase
   */
  const transformedScheduledEvents: ScheduledEventViewModel[] = scheduledEvents.map(
    scheduledEvent => ({
      ...scheduledEvent,
      astroTime: scheduledEvent.astroTime
        ? (scheduledEvent.astroTime.toLowerCase() as AstroTime)
        : null,
    }),
  );
  const range = getDateRange(startDateTime, endDateTime, locationSettings);
  return transformedScheduledEvents.flatMap(scheduledEvent =>
    findRRULESet(scheduledEvent, range, locationSettings).map(occurrenceDateTime => ({
      ...scheduledEvent,
      occurrenceDateTime,
    })),
  );
}

export function createMockRecurringScheduledEventViewModel(
  updates: Partial<ScheduledEventViewModel> = {},
): ScheduledEventViewModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneIds: ['mock-scene-id'],
    sequenceSceneIds: [],
    start: DateTime.fromISO('2018-07-28T03:24:00'),
    astroTime: AstroTime.Dawn,
    astroTimeOffset: 10,
    recurrence: {
      frequency: Frequency.Weekly,
      days: [Day.Monday, Day.Tuesday],
      until: null,
      excludedDates: [DateTime.fromISO('2018-07-28T03:24:00')],
    },
    ...updates,
  };
}

// Mocks
export function createMockScheduledEventInternalModel(
  updates: Partial<ScheduledEventInternalModel> = {},
): ScheduledEventInternalModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneIds: ['mock-scene-id'],
    sequenceSceneIds: [],
    start: '2018-07-28T19:45:36+00:00',
    astroTime: AstroTime.Dawn,
    astroTimeOffset: 10,
    rrule: null,
    ...updates,
  };
}

export function createMockScheduledEventGQLModel(
  updates: Partial<ScheduledEventGQLModel> = {},
): ScheduledEventGQLModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    schedulables: [{ __typename: 'Scene', id: 'mock-scene-id' }],
    start: {
      __typename: 'AstroEventStartTime',
      startsAt: '2018-07-28T19:45:36+00:00',
      event: (<unknown>AstroTime.Dawn) as AstroEvent,
      offset: 10,
    },
    ...updates,
  };
}

export function createMockScheduledEventFormModel(
  updates: Partial<ScheduledEventFormModel> = {},
): ScheduledEventFormModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneIds: ['mock-scene-id'],
    sequenceSceneIds: [],
    start: DateTime.fromISO('2018-07-28T03:24:00'),
    astroTime: AstroTime.Dawn,
    astroTimeOffset: 10,
    recurrence: null,
    ...updates,
  };
}

export function createMockScheduledEventFormModelWithRecurrence(
  updates: Partial<ScheduledEventFormModel> = {},
): ScheduledEventFormModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneIds: ['mock-scene-id'],
    sequenceSceneIds: [],
    start: DateTime.fromISO('2018-07-28T03:24:00'),
    astroTime: AstroTime.Dawn,
    astroTimeOffset: 10,
    recurrence: {
      frequency: Frequency.Weekly,
      days: [Day.Monday, Day.Tuesday],
      until: null,
      excludedDates: [DateTime.fromISO('2018-07-28T03:24:00')],
    },
    ...updates,
  };
}

export function createMockScheduledEventViewModel(
  updates: Partial<ScheduledEventViewModel> = {},
): ScheduledEventViewModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneIds: ['mock-scene-id'],
    sequenceSceneIds: [],
    start: DateTime.fromISO('2018-07-28T03:24:00'),
    astroTime: AstroTime.Dawn,
    astroTimeOffset: 10,
    recurrence: null,
    ...updates,
  };
}

export function createMockScheduledEventWSModel(
  updates: Partial<ScheduledEventWSModel> = {},
): ScheduledEventWSModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneIds: ['mock-scene-id'],
    start: '2018-07-28T19:45:36+00:00',
    astroTime: AstroTime.Dawn,
    astroTimeOffset: 10,
    rrule: null,
    ...updates,
  };
}

export function createMockScheduledEventViewModelWithSpecificTime(
  updates: Partial<ScheduledEventViewModel> = {},
): ScheduledEventViewModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneIds: ['mock-scene-id'],
    sequenceSceneIds: [],
    start: DateTime.fromISO('2018-07-28T03:24:00'),
    astroTime: null,
    astroTimeOffset: null,
    recurrence: null,
    ...updates,
  };
}

export function createMockOccurrenceViewModel(
  updates: Partial<OccurrenceViewModel> = {},
): OccurrenceViewModel {
  return {
    id: 'mock-scheduled-scene-id',
    name: 'Mock Scheduled Scene',
    sceneIds: ['mock-scene-id'],
    sequenceSceneIds: [],
    sceneZones: [
      {
        id: 'id_builtin_zone_all',
        name: 'All',
      },
    ],
    sceneNames: ['Mock Scene Name'],
    start: DateTime.fromISO('2018-07-28T07:24:00.000Z').toUTC(),
    astroTime: null,
    astroTimeOffset: null,
    recurrence: null,
    occurrenceDateTime: DateTime.fromISO('2018-07-28T07:24:00.000Z').toUTC(),
    ...updates,
  };
}
