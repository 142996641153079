import {
  Component,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { markAsTouched, provideFormErrors } from '@spog-ui/shared/components';
import {
  CreateScheduledUtilityRateInput,
  ScheduledUtilityRateFormModel,
  toCreateScheduledUtilityRateInputFromForm,
} from '@spog-ui/shared/models/scheduled-utility-rates';
import { UtilityServiceViewModel } from '@spog-ui/shared/models/utility-services';

@Component({
  selector: 'spog-schedule-utility-rate-form',
  template: `
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="scheduleUtilityRateForm">
      <mat-form-field data-form-id="startDate">
        <mat-label>Billing Start Date</mat-label>
        <input
          matInput
          [min]="now"
          [matDatepicker]="picker"
          [matDatepickerFilter]="startDateFilter"
          [spogScheduledStartDateValidator]="startDates"
          [siteTimeZone]="siteTimeZone"
          formControlName="startDate"
          />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error [suiFormError]="form.get('startDate')?.errors"></mat-error>
      </mat-form-field>
    
      <label class="utilityRateLabel">Utility Rate Type</label>
      <mat-radio-group
        aria-label="Utility Rate Type"
        formControlName="energyUtilityRateType"
        class="utilityRateTypeRadioGroup"
        >
        <mat-radio-button value="OpenEI">Complex Rate</mat-radio-button>
        <mat-radio-button value="Flat">Flat Rate</mat-radio-button>
      </mat-radio-group>
    
      @if (form.get('energyUtilityRateType')?.value === 'OpenEI') {
        <div
          class="complexRateInput"
          >
          <mat-form-field data-form-id="openEIUrl">
            <mat-label>OpenEI URL</mat-label>
            <input
              placeholder="https://apps.openei.org/USURDB/..."
              matInput
              formControlName="openEIUrl"
              />
            <button mat-icon-button matSuffix type="button" (click)="launchOpenEIUrl()">
              <mat-icon>open_in_new</mat-icon>
            </button>
            <mat-error [suiFormError]="form.get('openEIUrl')?.errors"></mat-error>
          </mat-form-field>
        </div>
      }
    
      @if (form.get('energyUtilityRateType')?.value === 'Flat') {
        <div
          class="flatRateInput"
          >
          <mat-form-field data-form-id="costOfDemand">
            <mat-label>Cost of Demand</mat-label>
            <input matInput formControlName="costOfDemand" type="number" />
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>per kW</span>
            <mat-error [suiFormError]="form.get('costOfDemand')?.errors"></mat-error>
          </mat-form-field>
          <mat-form-field data-form-id="costOfEnergy">
            <mat-label>Cost of Energy</mat-label>
            <input matInput formControlName="costOfEnergy" type="number" />
            <span matPrefix>$&nbsp;</span>
            <span matSuffix>per kWh</span>
            <mat-error [suiFormError]="form.get('costOfEnergy')?.errors"></mat-error>
          </mat-form-field>
        </div>
      }
    
      <div class="actions">
        <button
          class="cancelButton"
          type="button"
          mat-button
          color="warn"
          (click)="onCancel()"
          >
          Cancel
        </button>
        <button
          type="submit"
          [disabled]="form.disabled"
          mat-stroked-button
          color="accent"
          >
          Save
        </button>
      </div>
    </form>
    `,
  styles: [
    `
      .scheduleUtilityRateForm {
        width: 340px;
      }

      mat-form-field {
        width: 100%;
        margin: 15px 0;
      }

      .actions {
        display: flex;
        justify-content: flex-end;
      }

      .cancelButton {
        margin-right: 8px;
      }

      .utilityRateTypeRadioGroup {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
      }
    `,
  ],
  providers: [provideFormErrors(getFormErrors)],
})
export class ScheduleUtilityRateFormComponent implements OnDestroy {
  @Input() siteTimeZone = 'UTC';
  @Input() now?: DateTime;
  @Input() set disabled(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }
  @Input('utilityService') set utilityServiceSetter(
    utilityService: UtilityServiceViewModel,
  ) {
    this.utilityService = utilityService;
    this.startDates = utilityService.scheduledUtilityRates.map(rate => rate.startDate);
  }

  @Output() save = new EventEmitter<CreateScheduledUtilityRateInput>();
  @Output() cancel = new EventEmitter();

  openEIUrlValidatorSubscription: Subscription;
  startDates: DateTime[] = [];
  utilityService: UtilityServiceViewModel;
  form: UntypedFormGroup = new UntypedFormGroup({
    startDate: new UntypedFormControl(null, Validators.required),
    energyUtilityRateType: new UntypedFormControl('OpenEI', Validators.required),
    costOfDemand: new UntypedFormControl('0.00', [
      Validators.min(0),
      Validators.max(1000000),
    ]),
    costOfEnergy: new UntypedFormControl('0.00', [
      Validators.min(0),
      Validators.max(1000000),
    ]),
    openEIUrl: new UntypedFormControl(''),
  });

  constructor() {
    const utilityRateTypeControl = this.form.get('energyUtilityRateType')!;
    const openEIUrlControl = this.form.get('openEIUrl')!;

    this.openEIUrlValidatorSubscription = utilityRateTypeControl.valueChanges
      .pipe(startWith(utilityRateTypeControl.value), distinctUntilChanged())
      .subscribe(utilityRateType => {
        if (utilityRateType === 'Flat') {
          openEIUrlControl.clearValidators();
        } else {
          openEIUrlControl.setValidators(validateOpenEIUrl);
        }
      });
  }

  startDateFilter = (date: Date | null): boolean => {
    if (!this.now) return true;
    if (!date) return true;

    const startDate = DateTime.fromJSDate(date, { zone: this.siteTimeZone });
    const today = this.now.startOf('day');

    return startDate.startOf('day') > today.startOf('day');
  };

  onSubmit() {
    markAsTouched(this.form);

    if (this.form.invalid) {
      return;
    }

    const formModel: ScheduledUtilityRateFormModel = this.form.value;
    const input = toCreateScheduledUtilityRateInputFromForm(
      formModel,
      this.utilityService.id,
      this.siteTimeZone,
    );

    this.save.emit(input);
  }

  onCancel() {
    this.cancel.emit();
  }

  launchOpenEIUrl() {
    const defaultUrl = 'https://apps.openei.org/USURDB/';
    const urlControl = this.form.get('openEIUrl')!;
    const url = urlControl.value;

    if (urlControl.valid) {
      window.open(url);
    } else {
      window.open(defaultUrl);
    }
  }

  ngOnDestroy() {
    this.openEIUrlValidatorSubscription?.unsubscribe();
  }
}

@Directive({
  selector: '[spogScheduledStartDateValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ScheduledStartDateValidatorDirective,
    },
  ],
})
export class ScheduledStartDateValidatorDirective implements Validator, OnChanges {
  private onChange: () => void = () => void 0;

  @Input() siteTimeZone = 'UTC';
  @Input('spogScheduledStartDateValidator') startDates: DateTime[] = [];

  validate(control: AbstractControl): ValidationErrors | null {
    const maybeJSDate: Date | null = control.value;

    if (!maybeJSDate) return null;

    const date = DateTime.fromJSDate(maybeJSDate, { zone: this.siteTimeZone }).startOf(
      'day',
    );

    if (this.startDates.some(otherDate => otherDate.equals(date))) {
      return {
        spogScheduledStartDate: true,
      };
    }

    return null;
  }

  registerOnValidatorChange(onChange: () => void) {
    this.onChange = onChange;
  }

  ngOnChanges() {
    this.onChange();
  }
}

function isValidOpenEIRateUrl(url: URL) {
  return url.host === 'apps.openei.org' && url.pathname.startsWith('/USURDB/rate/view/');
}

function validateOpenEIUrl(control: AbstractControl) {
  try {
    const value: string = control.value || '';

    if (isValidOpenEIRateUrl(new URL(value))) {
      return null;
    }
  } catch (e) {
    // console.log(e);
  }

  return { spogOpenEIUrl: true };
}

export function getFormErrors() {
  return {
    default: {
      required: 'Start date must be selected',
      min: 'The cost cannot be a negative number',
      spogScheduledStartDate: 'A utility rate is already scheduled for this date',
      spogOpenEIUrl: 'You must provide a valid OpenEI Utility Rate URL',
    },
  };
}
