import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { unhandledExceptionAction } from './actions';

@Injectable()
export class LogUnhandledExceptionsErrorHandler implements ErrorHandler {
  constructor(readonly injector: Injector) {}

  handleError(error: Error) {
    /**
     * We are not injecting the store through the usual constructor
     * method because doing so introduces a DI circular dependency.
     */
    this.injector.get(Store).dispatch(unhandledExceptionAction({ error }));
    console.error(error);
  }
}
