import { createAction } from '@ngrx/store';
import { SiteFormModel } from '@spog-ui/shared/models/sites';

export const enterAction = createAction(
  '[Admin / Edit Site Page] Enter',
  (siteId: string) => ({ siteId }),
);

export const saveSiteAction = createAction(
  '[Admin / Edit Site Page] Save Site',
  (siteId: string, changes: SiteFormModel) => ({ siteId, changes }),
);

export const saveSiteActionWithDisabledFeatures = createAction(
  '[Admin / Edit Site Page] Save Site With Disabled Features',
  (siteId: string, changes: SiteFormModel, featuresChangedToDisabled: string[]) => ({
    siteId,
    changes,
    featuresChangedToDisabled,
  }),
);

export const confirmSaveSiteAction = createAction(
  '[Admin / Edit Site Page] Confirm Save Site',
  (siteId: string, changes: SiteFormModel) => ({ siteId, changes }),
);

export const cancelSaveSiteAction = createAction(
  '[Admin / Edit Site Page] Cancel Save Site',
);
