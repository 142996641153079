import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AddBridge485PageEffects, EditBridge485PageEffects } from './lib/effects';
import { bridge485sStateFeatureKey } from './lib/state';
import * as AddBridge485PageState from './lib/state/add-bridge-485-page.reducer';
import * as EditBridge485PageState from './lib/state/edit-bridge-485-page.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(bridge485sStateFeatureKey, {
      [AddBridge485PageState.STATE_KEY]: AddBridge485PageState.reducer,
      [EditBridge485PageState.STATE_KEY]: EditBridge485PageState.reducer,
    }),
    EffectsModule.forFeature([AddBridge485PageEffects, EditBridge485PageEffects]),
  ],
})
export class Bridge485sFeatureStateModule {}
