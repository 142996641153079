import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  Shape as OrganizationsShape,
  STATE_KEY as ORGANIZATIONS_SHAPE_STATE_KEY,
} from './organizations.reducer';
import {
  Shape as AddOrganizationsPageShape,
  STATE_KEY as ADD_ORGANIZATIONS_PAGE_SHAPE_STATE_KEY,
} from './add-organization-page.reducer';
import {
  Shape as EditOrganizationsPageShape,
  STATE_KEY as EDIT_ORGANIZATIONS_PAGE_SHAPE_STATE_KEY,
} from './edit-organization-page.reducer';
import {
  Shape as OrganizationsPageShape,
  STATE_KEY as ORGANIZATIONS_PAGE_SHAPE_STATE_KEY,
} from './organizations-page.reducer';

export const featureKey = 'superUser-organizations';

export type FeatureShape = {
  [ORGANIZATIONS_SHAPE_STATE_KEY]: OrganizationsShape;
  [ADD_ORGANIZATIONS_PAGE_SHAPE_STATE_KEY]: AddOrganizationsPageShape;
  [EDIT_ORGANIZATIONS_PAGE_SHAPE_STATE_KEY]: EditOrganizationsPageShape;
  [ORGANIZATIONS_PAGE_SHAPE_STATE_KEY]: OrganizationsPageShape;
};

export const featureSelector = createFeatureSelector<FeatureShape>(featureKey);

export const selectOrganizations = createSelector(
  featureSelector,
  state => state[ORGANIZATIONS_SHAPE_STATE_KEY],
);

export const selectAddOrgsPage = createSelector(
  featureSelector,
  state => state[ADD_ORGANIZATIONS_PAGE_SHAPE_STATE_KEY],
);

export const selectEditOrgsPage = createSelector(
  featureSelector,
  state => state[EDIT_ORGANIZATIONS_PAGE_SHAPE_STATE_KEY],
);
export const selectOrgsPage = createSelector(
  featureSelector,
  state => state[ORGANIZATIONS_PAGE_SHAPE_STATE_KEY],
);
