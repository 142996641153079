import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilityServiceComponentModule } from '../utility-service/utility-service.module';
import { UtilityServiceListComponent } from './utility-service-list.component';

@NgModule({
  imports: [CommonModule, UtilityServiceComponentModule],
  declarations: [UtilityServiceListComponent],
  exports: [UtilityServiceListComponent],
})
export class UtilityServiceListComponentModule {}
