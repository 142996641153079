import { createSelector } from '@ngrx/store';
import { STATE_KEY } from './quick-site-setup-page.reducer';
import { selectFeature } from './constants';

import {
  selectIsLoading,
  selectError,
  selectIsSubmitting,
} from './quick-site-setup-page.reducer';

export const selectQuickSiteSetupPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsQuickSiteSetupPageLoading = createSelector(
  selectQuickSiteSetupPageFeatureState,
  selectIsLoading,
);

export const selectQuickSiteSetupPageError = createSelector(
  selectQuickSiteSetupPageFeatureState,
  selectError,
);

export const selectIsQuickSiteSetupPageSubmitting = createSelector(
  selectQuickSiteSetupPageFeatureState,
  selectIsSubmitting,
);
