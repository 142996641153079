import { createAction } from '@ngrx/store';

export const eulaAcceptedAction = createAction(
  '[Eula/User] EULA Accepted',
  (eulaStatus: boolean) => {
    return { eulaStatus };
  },
);

export const showEulaAction = createAction(
  '[Eula/User] Show EULA form',
  (showAccept: boolean) => {
    return { showAccept };
  },
);

export const showLandingPageEulaAction = createAction(
  '[Eula/User] Show Landing Page EULA form',
);
