import { createAction } from '@ngrx/store';
import { DateTime } from 'luxon';

export const selectDateRangeOptionAction = createAction(
  '[Date Range] Select Date Range Option',
  (option: string) => {
    return { option };
  },
);
export const selectCustomDateRangeAction = createAction(
  '[Date Range] Select Custom Date Range',
  (dates: [DateTime, DateTime]) => {
    return { dates };
  },
);
export const calculateLastDayAction = createAction('[Date Range] Calculate Last Day');
export const calculateLastWeekAction = createAction('[Date Range] Calculate Last Week');
export const calculateLastMonthAction = createAction('[Date Range] Calculate Last Month');
export const calculateLastYearAction = createAction('[Date Range] Calculate Last Year');
export const calculateLastTwoYearsAction = createAction(
  '[Date Range] Calculate Last Two Years',
);
