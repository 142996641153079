import { createAction } from '@ngrx/store';
import { TriggerViewModel } from '@spog-ui/shared/models/triggers';

export const enterAction = createAction('[Triggers Page] Enter');

export const filterAction = createAction(
  '[Triggers Page] Filter',
  (searchTerm: string) => ({ searchTerm }),
);

export const createTriggerAction = createAction('[Triggers Page] Create Trigger');

export const deleteTriggerAction = createAction(
  '[Triggers Page] Delete Trigger',
  (trigger: TriggerViewModel) => ({ trigger }),
);
export const confirmDeleteTriggerAction = createAction(
  '[Triggers Page] Confirm Delete Trigger',
  (triggerId: string) => ({ triggerId }),
);

export const cancelDeleteTriggerAction = createAction(
  '[Triggers Page] Cancel Delete Trigger',
);
