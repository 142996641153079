import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EulaLinkComponent } from './eula-link.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EulaLinkComponent],
  exports: [EulaLinkComponent],
})
export class EulaLinkComponentModule {}
