import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '@spog-ui/shared/components';
import {
  StepperActionsDirective,
  StepperComponent,
  StepperContentDirective,
} from './stepper.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, ButtonComponent],
  declarations: [StepperComponent, StepperContentDirective, StepperActionsDirective],
  exports: [StepperComponent, StepperContentDirective, StepperActionsDirective],
})
export class StepperComponentModule {}
