import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Products } from '@spog-ui/graphql/types';
import * as CoreState from '@spog-ui/shared/state/core';
import { filter, map, withLatestFrom } from 'rxjs/operators';

export interface FeatureGuardData {
  requiredProducts: Products[];
}

@Injectable({ providedIn: 'root' })
export class FeatureGuardService implements CanActivate {
  constructor(readonly store: Store, readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.store.select(CoreState.selectSitesLoading).pipe(
      filter(isLoading => !isLoading),
      withLatestFrom(this.store.select(CoreState.selectSiteEntities)),
      map(([, sites]) => {
        const siteId = route.parent?.params['activeSiteId'];
        if (!siteId) {
          return false;
        }

        const site = sites[siteId];
        if (!site) {
          return false;
        }

        const requiredProducts = route.data['requiredProducts'] as Products[] | undefined;
        if (!requiredProducts) {
          return false;
        }

        return requiredProducts.some(product => site.enabledProducts.includes(product));
      }),
    );
  }
}
