import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of, forkJoin, defer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { OrgAdminEditUserPageActions } from '../actions';
import { selectActiveOrgId } from '@spog-ui/shared/state/organizations';
import { OrganizationUsersApiService } from '../services/';
import { OrgAdminUsersStateActions } from '../actions/';
import { Store } from '@ngrx/store';

@Injectable()
export class EditOrganizationUserApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly organizationUsersApiService: OrganizationUsersApiService,
    readonly store: Store,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminEditUserPageActions.enterAction),
      withLatestFrom(defer(() => this.store.select(selectActiveOrgId))),
      switchMap(([, activeOrgId]) =>
        this.organizationUsersApiService.getUsersByOrganization(activeOrgId!).pipe(
          map(users => OrgAdminEditUserPageActions.loadPageSuccessAction(users)),
          catchError((response: HttpErrorResponse) =>
            of(
              OrgAdminEditUserPageActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminEditUserPageActions.loadPageSuccessAction),
      map(action => {
        return OrgAdminUsersStateActions.loadUsersSuccessAction(action.users);
      }),
    ),
  );
}
