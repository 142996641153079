import {
  signalStore,
  withMethods,
  withState,
  patchState,
  withHooks,
  withComputed,
} from '@ngrx/signals';
import { withEntities, setAllEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { computed, inject } from '@angular/core';
import {
  GetSourcesQueryResult,
  GraphQLAPIService,
} from '@spog-ui/graphql/types';
import { SiteService } from '@spog-ui/shared/services';
import {
  SourceInternalModel,
  toOptionsFromSources,
  toSourceInternalModelFromGQL,
} from '../models/source.model';
import { of, pipe, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/component-store';

type SourcesState = {
  loadingSources: boolean;
  error: string | null;
};

const initialState: SourcesState = {
  loadingSources: true,
  error: null,
};

export const SourcesStore = signalStore(
  withState(initialState),
  withEntities<SourceInternalModel>(),
  withComputed(({ entities, error, loadingSources }) => {
    const sourcesAsOptions = computed(() => toOptionsFromSources(entities()));

    return {
      sourcesAsOptions,
    };
  }),
  withMethods(
    (store, siteService = inject(SiteService), gql = inject(GraphQLAPIService)) => ({
      loadSources: rxMethod<void>(
        pipe(
          tap(() => patchState(store, { loadingSources: true })),
          switchMap(() => {
            return gql.getSources({ siteId: siteService.getId() ?? 'not-set' }).pipe(
              tapResponse({
                next: (result: GetSourcesQueryResult) => {
                  if (!result.site) {
                    return;
                  }

                  patchState(
                    store,
                    setAllEntities(result.site.sources.map(toSourceInternalModelFromGQL)),
                  );
                },
                error: (error: any) => patchState(store, { error: error }),
                finalize: () => patchState(store, { loadingSources: false }),
              }),
            );
          }),
        ),
      ),
    }),
  ),
  withHooks({ onInit: store => store.loadSources() }),
);
