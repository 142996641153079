import { createAction } from '@ngrx/store';
import { ApiErrorModel } from '@spog-ui/shared/models/errors';

export const saveSuccessAction = createAction(
  '[Add Site Controller Page / API] Save Success',
);
export const saveFailureAction = createAction(
  '[Add Site Controller Page / API] Save Failure',
  (error: string) => ({ error }),
);
