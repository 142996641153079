import {
  signalStore,
  withMethods,
  withComputed,
  withState,
  patchState,
} from '@ngrx/signals';
import {
  TreeSelectOption,
  TreeSelectOptionView,
  populateChildren,
} from './tree-select.models';
import { computed } from '@angular/core';
import { groupBy } from 'lodash';
import { setAllEntities, updateEntity, withEntities } from '@ngrx/signals/entities';

type TreeSelectState = {
  filter: string;
  filterIsFocused: boolean;
};

const initialState: TreeSelectState = {
  filter: '',
  filterIsFocused: false,
};

export const TreeSelectStore = signalStore(
  withState(initialState),
  withEntities<TreeSelectOption>(),
  withComputed(({ filter, entities, filterIsFocused }) => {
    const filteredOptionViews = computed(() => {
      // Transform the options into a tree structure
      const groups = groupBy(entities(), 'ancestor');

      const loweredFilter = filter().toLowerCase();

      const rootsGroup = groups[''];

      // Note: double-equal is intentional to also match undefined
      if (rootsGroup == null) {
        return {
          selected: [],
          options: [],
        };
      }

      const rootViews: TreeSelectOptionView[] = rootsGroup.map(root => {
        return {
          ...root,
          children: [],
          shown: root.name.toLowerCase().includes(loweredFilter),
        };
      });

      const selected: TreeSelectOptionView[] = [];

      rootViews.forEach(root => {
        root.shown = populateChildren(root, groups, loweredFilter, selected);
      });

      return {
        selected,
        options: rootViews,
      };
    });

    const numberRejectedByFilter = computed(() =>
      filteredOptionViews().selected.reduce((count, option) => {
        return option.checked && !option.shown ? count + 1 : count;
      }, 0),
    );

    return {
      filterIsExpanded: computed(() => filter().length > 0 || filterIsFocused()),
      filteredOptionViews,
      combinedList: computed(() => [
        ...filteredOptionViews().selected,
        ...filteredOptionViews().options,
      ]),
      numberRejectedByFilter,
      checkedValues: computed(() =>
        entities()
          .filter(option => option.checked)
          .map(option => option.value),
      ),
    };
  }),
  withMethods(store => ({
    setOptions(options: TreeSelectOption[]): void {
      patchState(store, setAllEntities(options, { idKey: 'value' }));
    },
    setFilter(filter: string): void {
      patchState(store, state => ({ filter }));
    },
    setIsFocused(filterIsFocused: boolean): void {
      patchState(store, state => ({ filterIsFocused }));
    },
    toggleOption(toggledOption: TreeSelectOption): void {
      const currentValue = store.entityMap()[toggledOption.value].checked;

      patchState(
        store,
        updateEntity({ id: toggledOption.value, changes: { checked: !currentValue } }),
      );
    },
    toggleExpand(expandedOption: TreeSelectOption): void {
      const currentValue = store.entityMap()[expandedOption.value].expanded;

      patchState(
        store,
        updateEntity({ id: expandedOption.value, changes: { expanded: !currentValue } }),
      );
    },
  })),
);
