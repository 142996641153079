import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';
import { OrganizationStateActions } from '../actions';

export type Shape = EntityState<OrganizationInternalModel>;

export const adapter = createEntityAdapter<OrganizationInternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(OrganizationStateActions.loadOrganizationsSuccessAction, (state, action): Shape => {
    return adapter.upsertMany(action.organizations, state);
  }),
  on(OrganizationStateActions.deleteOrganizationAction, (state, action) => {
    return adapter.removeOne(action.organization.id, state);
  }),
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
