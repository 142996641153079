import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SuiCharmsModule } from '../charms';
import {
  CharmFilterComponent,
  CharmFilterInternalComponent,
} from './charm-filter.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        SuiCharmsModule,
        CharmFilterInternalComponent, CharmFilterComponent,
    ],
    exports: [CharmFilterComponent],
})
export class SuiCharmFilterModule {}
