import { NgModule, Provider, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { ColorPalette } from './color-picker.models';

export class ColorPaletteService {
  load(): Observable<ColorPalette> {
    throw new Error('Method not implemented.');
  }

  save(colorPalette: ColorPalette): Observable<ColorPalette> {
    throw new Error('Method not implemented.');
  }

  update(): Observable<ColorPalette> {
    throw new Error('Method not implemented.');
  }
}

/**
 * Provides the ColorPaletteService to injectable services.
 *
 * @param type The type of the ColorPaletteService to inject.
 * @returns The provider for the ColorPaletteService.
 */
export function provideColorPaletteService(type: Type<ColorPaletteService>): Provider {
  return {
    provide: ColorPaletteService,
    useClass: type,
  };
}
