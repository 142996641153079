import { createAction } from '@ngrx/store';
import {
  ClimateZoneControlModeInternalModel,
} from '@spog-ui/shared/models/zones';

export const enterAction = createAction('[Climate Zone Details Pane] Enter');

export const closeAction = createAction('[Climate Zone Details Pane] Close');

export const deleteClimateZoneAction = createAction(
  '[Climate Zone Details Pane] Delete Climate Zone',
  (climateZoneId: string) => ({ climateZoneId }),
);

export const confirmDeleteClimateZoneAction = createAction(
  '[Climate Zone Details Pane] Confirm Delete Climate Zone',
  (climateZoneId: string) => ({ climateZoneId }),
);

export const cancelDeleteClimateZoneAction = createAction(
  '[Climate Zone Details Pane] Cancel Delete Climate Zone',
);

export const setControlModeAction = createAction(
  '[Climate Zones Detail Pane] Set Control Mode',
  (
    climateZoneId: string,
    controlMode: ClimateZoneControlModeInternalModel,
  ) => ({
    climateZoneId,
    controlMode,
  }),
);

export const retrySetControlModeAction = createAction(
  '[Climate Zones Details Page] Retry Set Control Mode',
  (
    climateZoneId: string,
    controlMode: ClimateZoneControlModeInternalModel,
  ) => ({
    climateZoneId,
    controlMode,
  }),
);

export const applyClimateSceneAction = createAction(
  '[Climate Zone Details Pane] Apply Climate Scene',
  (climateSceneId: string) => ({ climateSceneId }),
);
