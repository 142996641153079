import {
  BillingFrequency,
  CreateScheduledUtilityRateInput,
  UpdateScheduledUtilityRateInput,
  DeleteScheduledUtilityRateInput,
  ScheduledUtilityRateModelFragment,
} from '@spog-ui/graphql/types';
import { DateTime } from 'luxon';
import { Dictionary } from '@ngrx/entity';

export const OPEN_EI_BASE_URL = 'https://apps.openei.org/USURDB/rate/view/';

export interface OpenEIUtilityRate {
  openEIUtilityRateId: string;
  rateName: string;
  companyName: string;
}

export interface FlatEnergyUtilityRate {
  /**
   * Represents a float with a dollar sign in front
   * of it, i.e. $0.012317
   */
  costOfDemand: string;
  costOfEnergy: string;
}

export type EnergyUtilityRate = OpenEIUtilityRate | FlatEnergyUtilityRate;

/**
 * Scheduled Utility Rate returned to us from GraphQL,
 * where the top level "id" is actually the id of the
 * Utility Service
 */
export type ScheduledUtilityRateGQLModel = ScheduledUtilityRateModelFragment;

/**
 * Scheduled Utility Rate returned to us from our WS
 * connection
 */
export interface ScheduledUtilityRateWSModel {
  id: string;
  siteId: string;
  utilityServiceId: string;
  billingFrequency: BillingFrequency.MONTHLY;
  startDate: string;
  openEIUtilityRate?: OpenEIUtilityRate;
  flatEnergyUtilityRate?: FlatEnergyUtilityRate;
}

/**
 * Normalized Scheduled Utility Rate appropriate for
 * keeping in the store
 */
export interface ScheduledUtilityRateInternalModel {
  id: string;
  utilityServiceId: string;
  startDate: string;
  utilityRate: EnergyUtilityRate;
}

/**
 * Scheduled Utility Rate ready to be displayed in the view
 */
export interface ScheduledUtilityRateViewModel {
  id: string;
  startDate: DateTime;
  utilityServiceId: string;
  openEIUtilityRate?: OpenEIUtilityRate;
  flatEnergyUtilityRate?: FlatEnergyUtilityRate;
}

/**
 * Inputs for deleting, updating, and creating Scheduled
 * Utility Services. These are imported from the generated
 * GraphQL types.
 */
export {
  BillingFrequency,
  CreateScheduledUtilityRateInput,
  UpdateScheduledUtilityRateInput,
  DeleteScheduledUtilityRateInput,
};

/**
 * Scheduled Utility Rate ready to be operated on in a form
 */
export interface ScheduledUtilityRateFormModel {
  startDate: Date;
  energyUtilityRateType: 'Flat' | 'OpenEI';
  openEIUrl: string;
  /**
   * Just a float in a string, no dollar sign
   */
  costOfDemand: string;
  costOfEnergy: string;
}

/**
 * Normalizes a Scheduled Utility Rate returned from our GraphQL
 * API into a model ready to be put into the store
 */
export function toScheduledUtilityRateInternalModelFromGQL(
  gql: ScheduledUtilityRateGQLModel,
): ScheduledUtilityRateInternalModel[] {
  return gql.scheduledUtilityRates.map(
    (scheduledRate): ScheduledUtilityRateInternalModel => {
      return {
        ...scheduledRate,
        utilityServiceId: gql.id,
      };
    },
  );
}

/**
 * Normalizes a Scheduled Utility Rate returned from our WebSocket
 * Connection into a model ready to be put into the store
 */
export function toScheduledUtilityRateInternalModelFromWS(
  ws: ScheduledUtilityRateWSModel,
): ScheduledUtilityRateInternalModel {
  console.assert(
    Boolean(ws.openEIUtilityRate || ws.flatEnergyUtilityRate),
    'Scheduled utility rates must have either a flat energy rate or an OpenEI utility rate',
  );

  return {
    id: ws.id,
    utilityServiceId: ws.utilityServiceId,
    startDate: ws.startDate,
    utilityRate: (ws.openEIUtilityRate || ws.flatEnergyUtilityRate)!,
  };
}

/**
 * Converts a Scheduled Utility Rate Form Model into one ready to be
 * consumed by the GraphQL create operation
 */
export function toCreateScheduledUtilityRateInputFromForm(
  form: ScheduledUtilityRateFormModel,
  utilityServiceId: string,
  siteTimeZone: string,
): CreateScheduledUtilityRateInput {
  const localizedStartDate = DateTime.fromJSDate(form.startDate, { zone: siteTimeZone })
    .startOf('day')
    .toUTC()
    .toISO();

  if (form.energyUtilityRateType === 'Flat') {
    return {
      utilityServiceId,
      billingFrequency: BillingFrequency.MONTHLY,
      startDate: localizedStartDate,
      flatEnergyUtilityRate: {
        costOfDemand: '$' + form.costOfDemand,
        costOfEnergy: '$' + form.costOfEnergy,
      },
    };
  } else if (form.energyUtilityRateType === 'OpenEI') {
    return {
      utilityServiceId,
      billingFrequency: BillingFrequency.MONTHLY,
      startDate: localizedStartDate,
      openEIUtilityRate: {
        openEIUtilityRateId: new URL(form.openEIUrl).pathname.replace(
          '/USURDB/rate/view/',
          '',
        ),
      },
    };
  } else {
    throw new Error('Unexpected energy utility rate type: ' + form.energyUtilityRateType);
  }
}

/**
 * Converts an internal scheduled utility rate into a utility rate
 * appropriate for the view
 */
export function toScheduledUtilityRateViewModelFromInternal(
  internal: ScheduledUtilityRateInternalModel,
  siteTimeZone: string,
): ScheduledUtilityRateViewModel {
  const startDate = DateTime.fromISO(internal.startDate, { zone: siteTimeZone });

  if ('costOfEnergy' in internal.utilityRate) {
    return {
      id: internal.id,
      flatEnergyUtilityRate: internal.utilityRate,
      utilityServiceId: internal.utilityServiceId,
      startDate,
    };
  } else {
    return {
      id: internal.id,
      openEIUtilityRate: internal.utilityRate,
      utilityServiceId: internal.utilityServiceId,
      startDate,
    };
  }
}

/**
 * Groups scheduled utility rates together by their associated utility services
 */
export function groupScheduledUtilityRatesByUtilityService<
  T extends { utilityServiceId: string },
>(items: T[]): Dictionary<T[]> {
  return items.reduce((lookupTable, item) => {
    if (lookupTable[item.utilityServiceId]) {
      lookupTable[item.utilityServiceId]!.push(item);
    } else {
      lookupTable[item.utilityServiceId] = [item];
    }

    return lookupTable;
  }, {} as Dictionary<T[]>);
}
