import { createAction } from '@ngrx/store';
import { SceneViewModel } from '@spog-ui/shared/models/scenes';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

export const enterAction = createAction('[Light Zone Details Page] Enter');

export const closeAction = createAction('[Light Zone Details Page] Close');

export const applySceneAction = createAction(
  '[Light Zone Details Page] Apply Scene',
  (scene: SceneViewModel) => ({
    scene,
  }),
);

export const displayZoneBehaviorForm = createAction(
  '[Light Zone Details Page] Display Zone Behavior Form',
  (zone: ZoneInternalModel) => {
    return { zone };
  },
);

export const previewLightAction = createAction(
  '[Light Zone Details Page] Preview Light',
  (lightId: string) => {
    return { lightId };
  },
);

export const unpreviewLightAction = createAction(
  '[Light Zone Details Page] Unpreview Light',
);
