import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as RemoteAccessState from '@spog-ui/shared/state/remote-access';
import * as SiteDetailsPaneState from './reducers';
import { toSiteDetailsViewModelFromInternal } from '@spog-ui/shared/models/sites';

export const STATE_KEY = 'site-details';

export interface Shape {
  siteDetailsPane: SiteDetailsPaneState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  siteDetailsPane: SiteDetailsPaneState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Site Details Pane Selectors
 */
export const selectActiveSiteId = createSelector(CoreState.selectRouterParams, params => {
  return params.activeSiteId as string | undefined | null;
});

export const selectActiveSite = createSelector(
  CoreState.selectAllSites,
  RemoteAccessState.selectAllAdminSites,
  selectActiveSiteId,
  CoreState.selectRouterData,
  (userSites, adminSites, id, routeData) => {
    if (routeData.admin) {
      return adminSites.find(sc => sc.id === id);
    } else {
      return userSites.find(sc => sc.id === id);
    }
  },
);

export const selectActiveSiteDetailsViewModel = createSelector(
  selectActiveSite,
  CoreState.selectAllSiteControllerViews,
  (activeSite, siteControllerViews) => {
    if (!activeSite) return null;

    const matchingSiteControllers = siteControllerViews.filter(
      siteControllerView => siteControllerView.siteId === activeSite.id,
    );

    return toSiteDetailsViewModelFromInternal(activeSite, matchingSiteControllers);
  },
);

/**
 * Site Details Pane State
 */
export const selectSiteDetailsPaneState = createSelector(
  selectFeatureState,
  state => state.siteDetailsPane,
);
export const selectIsSiteDetailsPaneLoading = createSelector(
  selectSiteDetailsPaneState,
  SiteDetailsPaneState.selectIsLoading,
);
export const selectSiteUsers = createSelector(
  selectSiteDetailsPaneState,
  SiteDetailsPaneState.selectSiteUsers,
);

export const selectSiteControllerViewsForSite = createSelector(
  selectActiveSite,
  CoreState.selectAllSiteControllerViews,
  (activeSite, siteControllerViews) =>
    siteControllerViews.filter(
      siteControllerView => siteControllerView.siteId === activeSite?.id,
    ),
);
