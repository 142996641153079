import { createAction, union } from '@ngrx/store';
import { BehaviorType } from '@spog-ui/shared/models/behaviors';
import { SceneViewModel } from '@spog-ui/shared/models/scenes';

export const filterAction = createAction(
  '[Light Map Layer] Filter',
  (searchTerm: string) => ({ searchTerm }),
);

export const enterAction = createAction('[Light Map Layer] Enter');

export const toggleStatusTrayFilterAction = createAction(
  '[Light Map Layer] Toggle Status Tray Filter Ok',
  (filteringOk: boolean, filteringAlarmed: boolean) => {
    return { filteringOk, filteringAlarmed };
  },
);

export enum BehaviorFilterTypes {
  Include = 'include',
  Exclude = 'exclude',
}

export const closeNearbyDevicesAction = createAction(
  '[Light Map Layer] Close Nearby Devices',
);
export const closeZoneListAction = createAction('[Light Map Layer] Close Zone List');
export const controlZoneAction = createAction(
  '[Light Map Layer] Control Zone',
  (
    zoneId: string,
    payload?: { behaviorFilter: BehaviorType; filterType: BehaviorFilterTypes },
  ) => {
    return { zoneId, payload };
  },
);
export const previewZoneAction = createAction(
  '[Light Map Layer] Preview Zone',
  (zoneId: string) => {
    return { zoneId };
  },
);
export const unpreviewZoneAction = createAction('[Light Map Layer] Unpreview Zone');

export const previewLightAction = createAction(
  '[Light Map Layer] Preview Light',
  (lightId: string) => {
    return { lightId };
  },
);
export const unpreviewLightAction = createAction('[Light Map Layer] Unpreview Light');
export const previewSceneAction = createAction(
  '[Light Map Layer] Preview Scene',
  (sceneId: string) => {
    return { sceneId };
  },
);
export const unpreviewSceneAction = createAction('[Light Map Layer] Unpreview Scene');
export const applySceneAction = createAction(
  '[Light Map Layer] Apply Scene',
  (scene: SceneViewModel) => {
    return { scene };
  },
);
export const selectNearbyDevicesTabAction = createAction(
  '[Light Map Layer] Select Nearby Devices Tab',
  (selectedTab: 'Zones' | 'Lights' | 'Scenes') => {
    return { selectedTab };
  },
);
export const clickOnLightAction = createAction(
  '[Light Map Layer] Click On Light',
  (spriteId: string | null) => {
    return { spriteId };
  },
);
export const clickNearLightsAction = createAction(
  '[Light Map Layer] Click Near Lights',
  (nearbySpriteIds: string[]) => {
    return { nearbySpriteIds };
  },
);

const actions = union({
  applySceneAction,
  clickNearLightsAction,
  clickOnLightAction,
  closeNearbyDevicesAction,
  closeZoneListAction,
  controlZoneAction,
  enterAction,
  filterAction,
  previewLightAction,
  previewSceneAction,
  previewZoneAction,
  selectNearbyDevicesTabAction,
  toggleStatusTrayFilterAction,
  unpreviewLightAction,
  unpreviewSceneAction,
  unpreviewZoneAction,
});
export type Union = typeof actions;
