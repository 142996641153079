import { createAction } from '@ngrx/store';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const enterAction = createAction('[Site Details] Enter');
export const closeAction = createAction('[Site Details] Close');

export const editAction = createAction('[Site Details] Edit', () => {
  return { redirectUrl: '/admin/sites' };
});
export const copyIDAction = createAction('[Site Details] Copy ID', (siteId: string) => ({
  siteId,
}));
export const deleteAction = createAction('[Site Details] Delete', (siteId: string) => ({
  siteId,
}));
export const deleteConfirmAction = createAction(
  '[Site Details] Confirm Delete',
  (siteId: string) => ({ siteId }),
);
export const deleteCancelAction = createAction('[Site Details] Cancel Delete');

export const selectSiteAction = createAction(
  '[Site Details] Select Site',
  (siteId: string | null | undefined) => ({ siteId }),
);
export const logInToSiteAction = createAction(
  '[Site Details] AdminLog In To Site',
  (site: SiteInternalModel) => {
    return { site };
  },
);
export const requestElevatedAccessForSiteAction = createAction(
  '[Site Details] Admin Request Elevated Access For Site',
  (site: SiteInternalModel) => {
    return { site };
  },
);
export const requestElevatedAccessForSiteFailureAction = createAction(
  '[Site Details] Request Elevated Access For Site Failure',
  (error: any) => ({ error }),
);
export const logInToSiteWithElevatedAccessAction = createAction(
  '[Site Details] Log In To Site With Elevated Access',
  (site: SiteInternalModel) => {
    return { site };
  },
);
export const showSiteUsersPreviewAction = createAction(
  '[Site Details] Get Users For Site',
  (siteId: string) => ({ siteId }),
);
export const closeSiteUsersPreviewAction = createAction(
  '[Site Details] Close Site Users Preview',
);