export interface TransformModel {
  k: number;
  x: number;
  y: number;
}

export function unapplyTransform(
  direction: 'x' | 'y',
  coordinate: number,
  transform: TransformModel,
): number {
  return coordinate * transform.k + transform[direction];
}

export function applyTransform(
  direction: 'x' | 'y',
  coordinate: number,
  transform: TransformModel,
): number {
  return (coordinate - transform[direction]) * (1 / transform.k);
}

export function createMockTransformModel(): TransformModel {
  return {
    k: 0.5,
    x: 100,
    y: 100,
  };
}
