import { Component, HostListener, TrackByFunction } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Color,
  ColorPalette,
  ColorSwatch,
  NUMBER_OF_FAST_COLORS,
  toHexStringFromColor,
} from './color-picker.models';
import { ColorPickerStore } from './color-picker.store';
import * as ColorPickerActions from './color-picker.actions';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'clr-color-palette',
  template: `
    <div class="list-wrapper" *ngIf="colorPalette$ | async as colorPalette">
      <clr-color-swatch
        *ngFor="let swatch of colorPalette; let i = index; trackBy: trackBySwatch"
        [imageUrl]="swatch | clrRenderSwatch"
        [isSelected]="i === colorIndex"
        (apply)="onApply(swatch)"
        (add)="onAdd()"
        (replace)="onReplace(i)"
        (remove)="onRemove(i)"
      >
        /></clr-color-swatch
      >
    </div>
    <hr />
  `,
  styles: [
    `
      .list-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
      }

      clr-color-swatch {
        flex-shrink: 0;
      }

      :host {
        position: relative;
      }

      clr-color-swatch:nth-of-type(1),
      clr-color-swatch:nth-of-type(2),
      clr-color-swatch:nth-of-type(3),
      clr-color-swatch:nth-of-type(4) {
        margin-bottom: 4px;
      }

      clr-color-swatch:nth-of-type(5),
      clr-color-swatch:nth-of-type(6),
      clr-color-swatch:nth-of-type(7),
      clr-color-swatch:nth-of-type(8) {
        margin-top: 5px;
      }

      hr {
        background-color: var(--color-background-background);
        position: absolute;
        top: 16px;
        left: 3px;
        flex-shrink: 0;
        /**
         * Full width of the container minus 2px on each side
         * to account for the margin on the swatches, minus 1px
         * on each side to account for the width of the border
         * (since it counts corners).
         */
        width: calc(100% - 6px);
        margin: 4px 0;
        border: none;
        border-top: 1px solid rgba(255, 255, 255, 0.12);
      }
    `,
  ],
})
export class ColorPaletteComponent {
  NUMBER_OF_FAST_COLORS = NUMBER_OF_FAST_COLORS;
  colorPalette$: Observable<ColorPalette>;

  constructor(readonly store: ColorPickerStore) {
    this.colorPalette$ = store.colorPalette$;
  }

  get colorIndex(): number {
    return this.store.colorIndex;
  }

  onApply(color: Color) {
    this.store.dispatch(ColorPickerActions.userAppliesColorSwatch(color));
  }

  onAdd() {
    this.store.dispatch(ColorPickerActions.userCreatesColorSwatch());
  }

  onReplace(index: number) {
    this.store.dispatch(ColorPickerActions.userStartsReplacingColorSwatch(index));
  }

  onRemove(index: number) {
    this.store.dispatch(ColorPickerActions.userStartsDeletingColorSwatch(index));
  }

  onReorder(event: CdkDragDrop<Color>) {
    this.store.dispatch(
      ColorPickerActions.userReordersColorInColorSwatch(
        event.previousIndex,
        event.currentIndex,
      ),
    );
  }

  trackBySwatch: TrackByFunction<ColorSwatch> = (_, swatch) =>
    swatch ? toHexStringFromColor(swatch) : null;
}
