import {
  AfterViewInit,
  Directive,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { CdkPortal } from '@angular/cdk/portal';
import { CharmsService } from './charms.service';

@Directive({
    selector: '[suiCharm]',
    standalone: true,
})
export class CharmDirective extends CdkPortal implements AfterViewInit, OnDestroy {
  constructor(
    private charmsService: CharmsService,
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
  ) {
    super(templateRef, viewContainerRef);
  }

  ngAfterViewInit() {
    this.charmsService.addCharm(this);
  }

  ngOnDestroy() {
    this.charmsService.removeCharm(this);
  }
}
