import { MetaReducer } from '@ngrx/store';
import { resetStateMetaReducer } from './reset-state.metareducer';
import { socketMessagesMetareducer } from './socket-messages.metareducer';

/**
 * Order matters.
 */
export const metaReducers: MetaReducer[] = [
  resetStateMetaReducer,
  socketMessagesMetareducer,
];
