import { createAction } from '@ngrx/store';
import { SceneZoneBehaviorInternalModel } from '@spog-ui/shared/models/scene-zone-behaviors';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';
import {
  OccurrenceViewModel,
  ScheduledEventInternalModel,
} from '@spog-ui/shared/models/scheduled-events';
import { SequenceSceneInternalModel } from '@spog-ui/shared/models/sequence-scenes';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

export const loadSuccessAction = createAction(
  '[Schedule API] Load Success',
  (models: {
    scheduledEvents: ScheduledEventInternalModel[];
    scenes: SceneInternalModel[];
    sceneZoneBehaviors: SceneZoneBehaviorInternalModel[];
    sequenceScenes: SequenceSceneInternalModel[];
    zones: ZoneInternalModel[];
  }) => {
    return { models };
  },
);
export const loadFailureAction = createAction(
  '[Schedule API] Load Failure',
  (error: string) => {
    return { error };
  },
);
export const deleteScheduledEventSuccessAction = createAction(
  '[Schedule API] Delete Scheduled Event Success',
  (scheduledEventId: string) => {
    return { scheduledEventId };
  },
);
export const deleteScheduledEventFailureAction = createAction(
  '[Schedule API] Delete Scheduled Event Failure',
  (scheduledEventId: string, error: string) => {
    return { scheduledEventId, error };
  },
);
export const deleteScheduledEventOccurrenceSuccessAction = createAction(
  '[Schedule API] Delete Scheduled Event Occurrence Success',
  (occurrence: OccurrenceViewModel) => {
    return { occurrence };
  },
);
export const deleteScheduledEventOccurrenceFailureAction = createAction(
  '[Schedule API] Delete Scheduled Event Occurrence Failure',
  (occurrence: OccurrenceViewModel, error: string) => {
    return { occurrence, error };
  },
);
export const addScheduledEventSuccessAction = createAction(
  '[Schedule API] Add Scheduled Event Success',
  (scheduledEvent: ScheduledEventInternalModel) => {
    return { scheduledEvent };
  },
);
export const addScheduledEventFailureAction = createAction(
  '[Schedule API] Add Scheduled Event Failure',
  (error: string) => {
    return { error };
  },
);
export const editScheduledEventSuccessAction = createAction(
  '[Schedule API] Edit Scheduled Event Success',
  (scheduledEvent: ScheduledEventInternalModel) => {
    return { scheduledEvent };
  },
);
export const editScheduledEventFailureAction = createAction(
  '[Schedule API] Edit Scheduled Event Failure',
  (error: string) => {
    return { error };
  },
);
