import { createAction } from '@ngrx/store';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';

export const enterAction = createAction('[Indie Sensors Page] Enter');
export const searchAction = createAction(
  '[Indie Sensors Page] Search',
  (searchTerm: string) => {
    return { searchTerm };
  },
);

