import { IndieSensorsPage, IndieSensorsApi } from '@spog-ui/indie-sensors/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  isLoading: boolean;
  searchTerm: string;
}

export const initialState: Shape = {
  isLoading: true,
  searchTerm: '',
};

export const reducer = createReducer(
  initialState,
  on(
    IndieSensorsPage.enterAction,
    (state): Shape => {
      return { ...initialState, searchTerm: state.searchTerm };
    },
  ),
  on(IndieSensorsPage.searchAction, (state, action) => {
    return {
      ...state,
      searchTerm: action.searchTerm,
    };
  }),
  on(IndieSensorsApi.loadSuccessAction, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectSearchTerm = (state: Shape) => state.searchTerm;
