import {
  Component,
  Input,
  ViewChildren,
  QueryList,
  SecurityContext,
} from '@angular/core';
import { startWith } from 'rxjs';
import { LinkedBehavior } from '@spog-ui/shared/models/scenes';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'scn-behavior-description',
  template: `
    <span class="suiBehaviorDescriptionLabel">{{
      description | suiRemoveBehaviorIDToken
    }}</span>
    <div class="suiBehaviorDescriptionContent" [style]="columnWidth">
      @for (name of names; track name) {
        <span #names class="name"> {{ name }}</span>
      }
    </div>
    `,
  styles: [
    `
      :host {
        display: block;
        font-size: 12px;
        line-height: initial;
      }

      .suiBehaviorDescriptionLabel {
        display: block;
        color: var(--color-foreground-text);
        margin-bottom: 8px;
      }

      .name {
        display: block;
        width: max-content;
      }

      .suiBehaviorDescriptionContent {
        display: block;
        color: var(--color-foreground-secondary-text);
        margin-bottom: 16px;
        /* column-width: auto; */
        /* column-count: 4; */
        /* column-gap: 80px; */
        /* column-rule: 1px solid lightblue; */
      }

      /* @media screen and (max-width: 500px) {
        .suiBehaviorDescriptionContent {
          column-count: 2;
          column-gap: 20px;
        }
      } */
    `,
  ],
})
export class BehaviorDescriptionComponent {
  columnWidth: string | null;

  @Input() behaviorGroup: LinkedBehavior | null = null;

  @ViewChildren('names') nameElements: QueryList<any>;

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterViewInit() {
    const maxWidth = Math.max(
      ...this.nameElements.map(element => element.nativeElement.clientWidth as number),
    );

    this.nameElements.changes.pipe(startWith([undefined])).subscribe(r => {
      this.columnWidth = this.sanitizer.sanitize(
        SecurityContext.STYLE,
        `column-width: ${maxWidth}px;`,
      );
    });
  }

  get names(): string[] {
    if (!this.behaviorGroup) return [];
    return this.behaviorGroup.zoneList
      .filter(zone => !zone.id.startsWith('hidden_'))
      .map(zone => zone.name.trim());
  }

  get description(): string {
    if (!this.behaviorGroup) return '';
    const { id, behaviorParameters } = this.behaviorGroup;
    if (!id) {
      return 'No Behavior';
    } else if (id === 'Dimmer') {
      const parsedParameters = JSON.parse(behaviorParameters || 'null');
      return `Dimmer - ${parsedParameters.level}%`;
    } else {
      return id;
    }
  }
}
