import { createSelector } from '@ngrx/store';
import * as PermissionGroupState from '@spog-ui/shared/state/permission-groups';
import { filterBy } from '@spog-ui/utils/filter';
import { selectFeature } from './constants';
import {
  selectError,
  selectIsLoading,
  selectPageIndex,
  selectPageSize,
  selectSearchTerm,
  STATE_KEY,
} from './permission-groups-page.reducer';

export const selectOrgAdminPermissionsGroupPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsPermissionGroupsPageLoading = createSelector(
  selectOrgAdminPermissionsGroupPageFeatureState,
  selectIsLoading,
);

export const selectPermissionGroupsPageError = createSelector(
  selectOrgAdminPermissionsGroupPageFeatureState,
  selectError,
);

export const selectPermissionGroupsPageFilter = createSelector(
  selectOrgAdminPermissionsGroupPageFeatureState,
  selectSearchTerm,
);

export const selectPermissionGroupsPageIndex = createSelector(
  selectOrgAdminPermissionsGroupPageFeatureState,
  selectPageIndex,
);

export const selectPermissionGroupsPageSize = createSelector(
  selectOrgAdminPermissionsGroupPageFeatureState,
  selectPageSize,
);

export const selectPermissionGroupsShowGettingStartedCard = createSelector(
  PermissionGroupState.selectAllPermissionGroups,
  permissionGroups => permissionGroups.length === 0,
);

export const selectFilteredPermissionGroups = createSelector(
  PermissionGroupState.selectAllPermissionGroups,
  selectPermissionGroupsPageFilter,
  (permissionGroups, filter) =>
    permissionGroups.filter(permissionGroup => {
      const searchTerm = filter.trim().toLowerCase();
      return (
        // Name
        filterBy(searchTerm, permissionGroup.name.toLowerCase()) ||
        // ID prefixed with 'id:'
        filterBy(searchTerm, permissionGroup.id.toLowerCase(), 'id:')
      );
    }),
);

export const selectPermissionGroupsPageLength = createSelector(
  selectFilteredPermissionGroups,
  permissionGroups => permissionGroups.length,
);

export const selectPaginatedPermissionGroups = createSelector(
  selectFilteredPermissionGroups,
  selectPermissionGroupsPageIndex,
  selectPermissionGroupsPageSize,
  (permissionGroups, index, size) => {
    return permissionGroups.slice(index * size, index * size + size);
  },
);
