import { createAction } from '@ngrx/store';
import { EquipmentInternalModel } from '@spog-ui/shared/models/resource-groups';

export const enterAction = createAction('[Equipment Page] Enter');

export const loadInitialModelsFromGQL = createAction(
  '[Equipment Page] Load Initial Models From GQL',
);

export const loadDeferredModelsFromState = createAction(
  '[Equipment Page] Load Deferred Models From State',
);

export const loadDeferredModelsFromGQL = createAction(
  '[Equipment Page] Load Deferred Models From GQL',
);

export const deleteEquipmentAction = createAction(
  '[Equipment Page] Delete Equipment',
  (equipment: EquipmentInternalModel) => ({ equipment }),
);

export const confirmDeleteEquipmentAction = createAction(
  '[Equipment Page] Confirm Delete Equipment',
  (equipmentId: string) => ({ equipmentId }),
);

export const cancelDeleteEquipmentAction = createAction(
  '[Equipment Page] Cancel Delete Equipment',
);

export const searchAction = createAction(
  '[Equipment Page] Search',
  (searchTerm: string) => {
    return { searchTerm };
  },
);
