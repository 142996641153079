import { createAction } from '@ngrx/store';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';

export const loadOrganizationsSuccessAction = createAction(
  '[Organizations State] Load Organizations Success',
  (organizations: OrganizationInternalModel[]) => ({ organizations }),
);

export const deleteOrganizationAction = createAction(
  '[Organizations State] Delete Organization',
  (organization: OrganizationInternalModel) => ({ organization }),
);
