import { createSelector } from '@ngrx/store';
import { STATE_KEY } from './add-email-group-page.reducer';
import { selectFeature } from './constants';

import {
  selectIsLoading,
  selectError,
  selectIsSubmitting,
} from './add-email-group-page.reducer';

export const selectAddEmailGroupsPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectIsAddEmailGroupPageLoading = createSelector(
  selectAddEmailGroupsPageFeatureState,
  selectIsLoading,
);

export const selectAddEmailGroupPageError = createSelector(
  selectAddEmailGroupsPageFeatureState,
  selectError,
);

export const selectIsAddEmailGroupPageSubmitting = createSelector(
  selectAddEmailGroupsPageFeatureState,
  selectIsSubmitting,
);
