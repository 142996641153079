import { createAction } from '@ngrx/store';
import { OrganizationUserFormModel } from '@spog-ui/shared/models/organization-users';

export const enterAction = createAction(
  '[Admin Organization / Edit User] Enter',
  (userId: string) => ({
    userId,
  }),
);

export const editUserAction = createAction(
  '[Admin Organization / Edit User] Edit User',
  (user: OrganizationUserFormModel) => ({ user }),
);

export const loadPageSuccessAction = createAction(
  '[Admin Organization / Edit User] Load Edit Page Success',
  (users: OrganizationUserFormModel[]) => ({ users }),
);

export const loadPageFailureAction = createAction(
  '[Admin Organization / Edit User] Load Edit Page Failure',
  (error: string) => ({ error }),
);
