import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { LightControlActions } from '@spog-ui/light-control/actions';
import { LightMapLayerApiActions } from '@spog-ui/light-map-layer/actions';
import { LightZoneDetailsAPIActions } from '@spog-ui/light-zone-details/actions';
import { LightInternalModel } from '@spog-ui/shared/models/lights';
import { SocketActions } from '@spog-ui/socket/actions';
import { UtilityServicesApiActions } from '@spog-ui/utility-services/actions';

export type Shape = EntityState<LightInternalModel>;

export function sortByName(a: LightInternalModel, b: LightInternalModel): number {
  return a.name.localeCompare(b.name, undefined, { numeric: true });
}

export const adapter = createEntityAdapter<LightInternalModel>({
  sortComparer: sortByName,
});

export const initialState = adapter.getInitialState();

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const reducer = createReducer(
  initialState,
  on(
    LightZoneDetailsAPIActions.loadLightZoneDetailsPageModelsSuccessAction,
    (state, action) => {
      return adapter.addMany(action.models.lights, state);
    },
  ),
  on(LightMapLayerApiActions.loadLightMapLayerModelsSuccessAction, (state, action) => {
    const stateWithLights = adapter.setAll(action.models.lights, state);
    return adapter.updateMany(
      selectAll(stateWithLights)
        .filter(light => light.level === undefined || light.level === null)
        .map(light => ({ id: light.id, changes: { level: 0 } })),
      stateWithLights,
    );
  }),
  on(LightControlActions.dimAction, (state, action) => {
    const update = { id: action.light.id, changes: { level: action.level } };

    return adapter.updateOne(update, state);
  }),
  on(SocketActions.lightControlAction, (state, action) => {
    const update = {
      id: action.lightId,
      changes: { level: action.intendedLightLevel },
    };

    return adapter.updateOne(update, state);
  }),
  on(
    UtilityServicesApiActions.loadUtilityServicesAndThingsSuccessAction,
    (state, action) => {
      return adapter.setAll(action.lights, state);
    },
  ),
  on(SocketActions.bulkFloorPlanPlacementAction, (state, action) => {
    const updates = action.placedThings.map(thing => ({
      id: thing.id,
      changes: {
        floorPlanId: thing.floorPlanId,
        floorPlanX: thing.floorPlanX,
        floorPlanY: thing.floorPlanY,
      },
    }));
    return adapter.updateMany(updates, state);
  }),
);
