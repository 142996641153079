import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Bridge485InternalModel } from '@spog-ui/shared/models/bridge-485s';
import { isBridge485Metric } from '@spog-ui/shared/models/indie-sensors';
import { selectActiveIndieSensor } from '@spog-ui/shared/state/core';
import * as Bridge485sState from './bridge-485s.reducer';

export const STATE_KEY = 'bridge485s-core';

export type Shape = EntityState<Bridge485InternalModel>;

export const selectBridge485sState = createFeatureSelector<Shape>(STATE_KEY);

export const selectAllBridge485s = createSelector(
  selectBridge485sState,
  Bridge485sState.selectAll,
);

export const selectBridge485Entities = createSelector(
  selectBridge485sState,
  Bridge485sState.selectEntities,
);

export const selectActiveMetricBridge485 = createSelector(
  selectActiveIndieSensor,
  selectBridge485Entities,
  (sensor, bridge485s) => {
    if (!sensor) return null;
    if (!isBridge485Metric(sensor)) return null;

    return bridge485s[sensor.source.id] ?? null;
  },
);
