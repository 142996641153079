import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClipboardService } from '@spog-ui/shared/services';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { map, tap, catchError, switchMap } from 'rxjs/operators';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { OrgAdminSitesPageActions } from '../actions';
import { OrgAdminSitesStateActions } from '../actions';
import { SitesApiService } from '../services/sites-api.service';
import { toOrgSiteInternalModelFromOrgSiteWithUsersGqlModel } from '@spog-ui/shared/models/sites';
import { GetSitesWithUsersByOrganizationQueryResult } from '@spog-ui/graphql/types';

@Injectable()
export class SitesPageEffects {
  constructor(
    private actions$: Actions,
    readonly clipboardService: ClipboardService,
    readonly snackbar: MatSnackBar,
    readonly sitesApiService: SitesApiService,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminSitesPageActions.enterAction),
      switchMap(action =>
        this.sitesApiService.getSitesWithUsersByOrganization(action.organizationId).pipe(
          map((response: GetSitesWithUsersByOrganizationQueryResult) => {
            if (!response.organization) {
              throw new Error('No organization found');
            }
            return OrgAdminSitesPageActions.loadPageSuccessAction(
              response.organization.sites.edges.map(
                toOrgSiteInternalModelFromOrgSiteWithUsersGqlModel,
              ),
            );
          }),
          catchError((response: HttpErrorResponse) =>
            of(
              OrgAdminSitesPageActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  loadSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAdminSitesPageActions.loadPageSuccessAction),
      map(action => {
        return OrgAdminSitesStateActions.loadSitesSuccessAction(action.sites);
      }),
    ),
  );

  copySiteId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgAdminSitesPageActions.copyIDAction),
        tap(action => {
          this.clipboardService.copy(action.site.id);
          this.snackbar.open('Copied ID to the clipboard', 'Dismiss', {
            duration: 6_000,
          });
        }),
      ),
    { dispatch: false },
  );
}
