import { createAction } from '@ngrx/store';
import {
  EmailGroupFormModel,
  EmailGroupInternalModel,
} from '@spog-ui/shared/models/email-groups';
import { OrganizationSiteInternalModel } from '@spog-ui/shared/models/sites';
import { OrganizationUserInternalModel } from '@spog-ui/shared/models/organization-users';

export const enterAction = createAction(
  '[Admin / Edit Email Group Page] Enter',
  (emailGroupId: string) => ({
    emailGroupId,
  }),
);

export const editEmailGroupAction = createAction(
  '[Edit Email Group] Edit Email Group',
  (emailGroup: EmailGroupFormModel) => ({ emailGroup }),
);

export const loadPageSuccessAction = createAction(
  '[Edit Email Group] Load Edit Page Success',
  (
    users: OrganizationUserInternalModel[],
    sites: OrganizationSiteInternalModel[],
    emailGroups: EmailGroupInternalModel[],
  ) => ({
    users,
    sites,
    emailGroups,
  }),
);

export const loadPageFailureAction = createAction(
  '[Edit Email Group] Load Edit Page Failure',
  (error: string) => ({ error }),
);
