import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DimmerControlComponent } from '@spog-ui/shared/components';
import { BFSwitchOnlyComponent } from './bf-switch-only.component';

@NgModule({
  imports: [ReactiveFormsModule, DimmerControlComponent],
  declarations: [BFSwitchOnlyComponent],
  exports: [BFSwitchOnlyComponent],
})
export class BFSwitchOnlyComponentModule {}
