import { createSelector } from '@ngrx/store';
import { selectFeature } from './constants';
import {
  STATE_KEY,
  selectAll,
  selectEntities,
  selectIds,
} from './organization-users.reducer';

export const selectOrgAdminUsersState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectUserEntities = createSelector(
  selectOrgAdminUsersState,
  selectEntities,
);
export const selectUserIds = createSelector(selectOrgAdminUsersState, selectIds);
export const selectAllOrgAdminUsers = createSelector(selectOrgAdminUsersState, selectAll);
