import { BehaviorParameters, BehaviorType } from '@spog-ui/shared/models/behaviors';
import {
  ZoneModelFragment,
  ControlZoneModelFragment,
  Application,
} from '@spog-ui/graphql/types';
import { SourceType } from '@spog-ui/shared/models/power-source-comparisons';

export { Application as ZoneApplication };

export const ZONE_ALL_ID = 'id_builtin_zone_all';

export interface ZoneModel {
  id: string;
  name: string;
}

export interface ZoneWSModel {
  id: string;
  behavior_id: string;
  behavior_parameters: string | null;
  description: string;
  name: string;
  ota_id: number;
  removable: boolean;
}

export function createMockZone(zoneOpts: Partial<ZoneModel> = {}): ZoneModel {
  return {
    id: ZONE_ALL_ID,
    name: 'All',
    ...zoneOpts,
  };
}

/**
 * @todo Jake Harris
 * Rename these as LightingZones, as we're introducing
 * the Climate Zone epic.
 */

/**
 * Lighting zone from our GraphQL API
 */
export type DeprecatedZoneGQLModel = ZoneModelFragment;

/**
 * Newest control zone
 */
export type ControlZoneGraphQLModel = ControlZoneModelFragment;

/**
 * Normalized lighting zone appropriate for
 * keeping in the store
 */
export interface ZoneInternalModel {
  id: string;
  name: string;
  behaviorId: BehaviorType;
  behaviorParameters: BehaviorParameters;
  application: Application;
  deviceIds: string[];
}

/**
 * A zone model, ready for use in a form. Currently
 * only used when applying behaviors, so does not
 * allow the user to edit the zone's name.
 */
export interface ZoneFormModel {
  id: string;
  behaviorId: BehaviorType;
  behaviorParameters: BehaviorParameters;
}

export interface ZoneBehaviorFormModel {
  zones: ZoneInternalModel[];
  behaviorId: BehaviorType;
  behaviorParameters: BehaviorParameters;
}

/**
 * A view model that contains display information
 * for a zone -- namely, its name, and its source
 * type, which is used to identify the appropriate
 * list item icon for this zone.
 *
 * Context: Resource Groups
 */
export interface ZoneSourceViewModel {
  id: string;
  name: string;
  sourceType: SourceType;
}

export function toZoneInternalModelFromGQL(
  gqlModel: DeprecatedZoneGQLModel,
): ZoneInternalModel {
  return {
    id: gqlModel.id,
    name: gqlModel.name,
    behaviorId: gqlModel.behaviorId as BehaviorType,
    behaviorParameters: gqlModel.behaviorParameters!,
    application: Application.LIGHTING,
    deviceIds: [],
  };
}

export function toControlZoneInternalModelFromGQL(
  gqlModel: ControlZoneGraphQLModel,
): ZoneInternalModel {
  return {
    id: gqlModel.id,
    name: gqlModel.name,
    behaviorId: gqlModel.behavior.node.id as BehaviorType,
    behaviorParameters: gqlModel.behavior.parameters!,
    application: gqlModel.application,
    deviceIds: gqlModel.thermostats
      ? gqlModel.thermostats.map(t => t.id)
      : gqlModel.lights
      ? gqlModel.lights.map(l => l.id)
      : [],
  };
}

export function toZoneSourceViewModelFromInternal(
  zone: ZoneInternalModel,
): ZoneSourceViewModel {
  return {
    id: zone.id,
    name: zone.name,
    sourceType:
      zone.behaviorId === BehaviorType.DLH ? SourceType.DLHZone : SourceType.ControlZone,
  };
}

/**
 * Control zone from our GraphQL API
 */

export type BehaviorGQLModel = {
  id: string;
  name: string;
  application?: Application;
  parameterSchema: string | null;
};

export type ControlZoneBehaviorEdgeGQLModel = {
  node: BehaviorGQLModel;
  parameters: string | null;
};

/**
 * Zone from Websockets
 */
export interface ZoneWSModel {
  id: string;
  behavior_id: string;
  behavior_parameters: string | null;
  description: string;
  name: string;
  ota_id: number;
  removable: boolean;
  zone_application?: Application;
  thermostats?: string[];
}

export function toZoneInternalModelFromWS(ws: ZoneWSModel): Partial<ZoneInternalModel> {
  return {
    id: ws.id,
    name: ws.name,
    behaviorId: ws.behavior_id as BehaviorType,
    behaviorParameters: ws.behavior_parameters,
    application: ws.zone_application
      ? (ws.zone_application.toUpperCase() as Application)
      : Application.LIGHTING,
    // if there is a thermostats property on the websocket message update thermostats else do nothing
    ...(ws.thermostats ? { deviceIds: ws.thermostats } : {}),
  };
}
