import { createReducer, on } from '@ngrx/store';
import { MpStepThreeActions } from '@spog-ui/map-positioner/actions';

export interface Shape {
  activePositionId: string | null;
  searchTerm: string;
}

export const initialState: Shape = {
  activePositionId: null,
  searchTerm: '',
};

export const reducer = createReducer(
  initialState,
  on(
    MpStepThreeActions.selectPositionAction,
    (state, action): Shape => {
      return {
        ...state,
        activePositionId: action.positionId,
      };
    },
  ),
  on(
    MpStepThreeActions.closeAssignerAction,
    MpStepThreeActions.assignThingToPositionAction,
    (state): Shape => {
      return {
        ...state,
        activePositionId: null,
      };
    },
  ),
  on(
    MpStepThreeActions.filterThingsAction,
    (state, action): Shape => {
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    },
  ),
);

export const selectActivePositionId = (state: Shape) => state.activePositionId;
export const selectSearchTerm = (state: Shape) => state.searchTerm;
