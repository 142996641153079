import { createReducer, on } from '@ngrx/store';
import {
  ClimateZoneModalActions,
  ClimateZoneModalApiActions,
} from '@spog-ui/climate-zones/actions';

export interface Shape {
  isLoading: boolean;
}

export const initialState: Shape = {
  isLoading: true,
};

export const reducer = createReducer(
  initialState,
  on(
    ClimateZoneModalActions.enterAction,
    (): Shape => {
      return {
        ...initialState,
      };
    },
  ),
  on(
    ClimateZoneModalApiActions.loadClimateZoneModalModelsSuccessAction,
    ClimateZoneModalApiActions.loadClimateZoneModalModelsFailureActions,
    (state): Shape => {
      return { ...state, isLoading: false };
    },
  ),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
