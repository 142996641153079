import { createAction } from '@ngrx/store';
import { LightUpdateProps } from '../models';

export const enterAction = createAction(
  '[Edit Light Page] Enter | @sui/light',
  (lightId: string) => {
    return { lightId };
  },
);
export const saveAction = createAction(
  '[Edit Light Page] Save | @sui/light',
  (light: LightUpdateProps) => {
    return { light };
  },
);
export const cancelAction = createAction('[Edit Light Page] Cancel | @sui/light');

export type Union =
  | ReturnType<typeof enterAction>
  | ReturnType<typeof saveAction>
  | ReturnType<typeof cancelAction>;

export const Types = {
  Enter: enterAction.type,
  Save: saveAction.type,
  Cancel: cancelAction.type,
};
