import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as MapState from './map.state';

@NgModule({
  imports: [
    CoreState.CoreStateModule,
    StoreModule.forFeature(MapState.STATE_KEY, MapState.reducers),
  ],
})
export class MapStateModule {}
