import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { NoSitesActions } from '@spog-ui/site-selector/actions';
import * as CoreState from '@spog-ui/shared/state/core';

@Component({
  selector: 'spog-no-sites',
  template: `
    @if (showCard$ | async) {
    <sui-empty-state>
      <ng-template suiEmptyStateMessage>
        No Sites are associated with your account.
        <br />
        <br />
        Please call Synapse at:
        <br />
        (877) 982-7888
      </ng-template>
      <ng-template suiEmptyStateActions>
        <button suiButton (click)="onLogout()">Logout</button>
      </ng-template>
    </sui-empty-state>
    }
  `,
})
export class NoSitesComponent {
  showCard$ = this.store.select(CoreState.selectHasNoSites);

  constructor(private store: Store) {}

  onLogout() {
    this.store.dispatch(NoSitesActions.logoutAction());
  }
}
