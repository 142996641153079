import { Route } from '@angular/router';
import { AddScenePageComponent } from './add';
import { EditScenePageComponent } from './edit';
import {
  ScenesPageComponent,
  AddSequenceScenePageComponent,
  EditSequenceScenePageComponent,
} from './components';
import { IfUserCanGuard } from '@spog-ui/current-user/permissions';

export const routes: Route[] = [
  {
    path: '',
    component: ScenesPageComponent,
  },
  {
    path: 'add',
    component: AddScenePageComponent,
    canActivate: [IfUserCanGuard],
    data: { permissions: ['ssc:siteAdmin', 'ssc:siteViewer', 'ssc:schedule-and-scene'] },
  },
  {
    path: 'addSequenceScene',
    component: AddSequenceScenePageComponent,
    canActivate: [IfUserCanGuard],
    data: { permissions: ['ssc:siteAdmin', 'ssc:siteViewer', 'ssc:schedule-and-scene'] },
  },
  {
    path: ':id',
    component: EditScenePageComponent,
    canActivate: [IfUserCanGuard],
    data: { permissions: ['ssc:siteAdmin', 'ssc:siteViewer', 'ssc:schedule-and-scene'] },
  },
  {
    path: 'sequenceScene/:id',
    component: EditSequenceScenePageComponent,
    canActivate: [IfUserCanGuard],
    data: { permissions: ['ssc:siteAdmin', 'ssc:siteViewer', 'ssc:schedule-and-scene'] },
  },
];
