import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'spog-site-selector-shell',
  template: `
    <div class="container">
      <sui-card>
        @if (loggingIn$ | async) {
          <sui-progress-bar></sui-progress-bar>
        }
    
        <img src="/assets/logo.svg" />
    
        <router-outlet></router-outlet>
      </sui-card>
    
      <spog-admin-link></spog-admin-link>
      <spog-eula-link></spog-eula-link>
    </div>
    `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        min-height: 500px;
        padding: 16px;
      }

      .container {
        align-self: center;
        justify-content: center;
      }

      sui-card {
        width: 100%;
        min-height: 460px;
        max-width: 360px;
        padding: 32px;
        position: relative;
        overflow: hidden;
        margin-bottom: 12px;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      sui-progress-bar {
        position: absolute;
        width: 100%;
        top: -3px;
        left: 0px;
      }

      spog-eula-link {
        float: right;
      }
    `,
  ],
})
export class SiteSelectorShellComponent {
  loggingIn$ = this.auth.isLoading$;

  constructor(private auth: AuthService) {}
}
