import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';
import { SitesStateActions } from './actions';
import { SiteControllerDetailsApiActions } from '@spog-ui/site-controller-details/state';
import { SiteDetailsActions } from '@spog-ui/site-details/actions';

export type Shape = EntityState<SiteInternalModel>;

export function sortByName(a: SiteInternalModel, b: SiteInternalModel): number {
  return a.name.localeCompare(b.name);
}

export const adapter = createEntityAdapter<SiteInternalModel>({
  sortComparer: sortByName,
});

const initialState: Shape = adapter.getInitialState();

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const reducer = createReducer(
  initialState,
  on(SitesStateActions.loadSitesSuccessAction, (state, action) => {
    return adapter.setAll(action.sites, state);
  }),
  on(
    SiteDetailsActions.logInToSiteAction,
    SiteDetailsActions.logInToSiteWithElevatedAccessAction,
    (state, action) => {
      return adapter.addOne(action.site, state);
    },
  ),
  on(
    SiteControllerDetailsApiActions.loadSiteControllerDetailsSuccessAction,
    (state, action) => {
      if (action.site) {
        return adapter.upsertOne(action.site, state);
      } else {
        return state;
      }
    },
  ),
);
