import { createReducer, on } from '@ngrx/store';
import { Bridge485Api, EditBridge485Page } from '../actions';

export interface Shape {
  bridge485Id: string | null;
  isLoading: boolean;
  isSaving: boolean;
  error?: any;
}

export const STATE_KEY = 'edit-bridge-485-page';

export const initialState: Shape = {
  bridge485Id: null,
  isLoading: true,
  isSaving: false,
};

export const reducer = createReducer(
  initialState,
  on(EditBridge485Page.enterAction, (state, action) => {
    return {
      ...state,
      bridge485Id: action.bridge485Id,
    };
  }),
  on(Bridge485Api.loadEditPageModelsSuccessAction, state => {
    return {
      ...state,
      isLoading: false,
      error: null,
    };
  }),
  on(Bridge485Api.loadEditPageModelsFailureAction, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
  on(EditBridge485Page.saveAction, state => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(EditBridge485Page.cancelSaveAction, state => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(Bridge485Api.updateSuccessAction, (state, action) => {
    return {
      ...state,
      isSaving: false,
      error: null,
    };
  }),
  on(Bridge485Api.updateFailureAction, (state, action) => {
    return {
      ...state,
      isSaving: false,
      error: action.error,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSaving = (state: Shape) => state.isSaving;
export const selectBridge485Id = (state: Shape) => state.bridge485Id;
export const selectError = (state: Shape) => state.error;
