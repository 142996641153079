import { createSelector } from '@ngrx/store';
import { selectFeature } from './constants';
import { STATE_KEY, selectRedirectUrl } from './redirect.reducer';

export const selectRedirectFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectOrgAdminRedirectUrl = createSelector(
  selectRedirectFeatureState,
  selectRedirectUrl,
);
