import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { Capabilities } from '@spog-ui/graphql/types';
import { selectRouterParams } from '@spog-ui/shared/state/core';
import * as OrgState from './organizations.reducer';

export const STATE_KEY = 'organizations';

export interface Shape {
  organizationsList: OrgState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  organizationsList: OrgState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

export const selectOrganizationsState = createSelector(
  selectFeatureState,
  state => state.organizationsList,
);

export const selectAllOrganizations = createSelector(
  selectOrganizationsState,
  OrgState.selectAll,
);

export const selectNonTemporaryOrganizations = createSelector(
  selectAllOrganizations,
  orgs => orgs.filter(org => !org.temporary),
);

export const selectOrganizationEntities = createSelector(
  selectOrganizationsState,
  OrgState.selectEntities,
);

export const selectActiveOrgId = createSelector(
  selectRouterParams,
  params => params['activeOrgId'] as string | undefined | null,
);

export const selectActiveOrganization = createSelector(
  selectAllOrganizations,
  selectActiveOrgId,
  (allOrgs, activeOrgId) => {
    if (activeOrgId) {
      return allOrgs.find(org => org.id === activeOrgId);
    }
    return undefined;
  },
);

export const selectCanActiveOrganizationDoQuickSiteSetup = createSelector(
  selectActiveOrganization,
  activeOrg => {
    if (activeOrg) {
      return activeOrg.capabilities.includes(Capabilities.QUICK_SITE_SETUP);
    }

    return false;
  },
);
