import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { CustomDateSelectComponent } from './custom-date-select.component';
import { MatSelectChange } from '@angular/material/select';
import {
  DatePickerSelection,
  getDateRangeFromSelection,
} from '../../models/date-range.model';

@Component({
  selector: 'spog-date-control',
  template: `
    <mat-form-field>
      <mat-label>Date Range</mat-label>
      <mat-select
        #select
        [(value)]="selectedDateRange"
        (selectionChange)="onDateSelected($event)"
        matTooltip="Select Date Range"
      >
        @for (selectionOption of selectionOptions; track selectionOption) {
        <mat-option [value]="selectionOption" (click)="onClick(select.value)">
          {{ selectionOption }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  `,
  styles: [
    `
      :host ::ng-deep .mat-mdc-form-field {
        width: 172px;
        font-size: 16px;
      }
      :host ::ng-deep .mat-mdc-form-field-infix {
        border-top: 0px;
      }

      :host ::ng-deep .mat-mdc-select-arrow-wrapper {
        padding-right: 8px;
      }
    `,
  ],
})
export class DateControlComponent {
  @Input()
  selectionOptions: string[] = [
    'Last Day',
    'Last Week',
    'Last Month',
    'Last Year',
    'Custom',
  ];

  selectedDateRange = 'Last Day';

  @Output()
  dateRangeSelected = new EventEmitter<[DateTime, DateTime]>();

  constructor(public dialog: MatDialog) {}

  onDateSelected(event: MatSelectChange) {
    if (event.value == 'Custom') return;

    this.dateRangeSelected.emit(getDateRangeFromSelection(event.value));
  }

  onClick(value: any) {
    if (value === null || !value.includes('Custom')) return;

    const dialogRef = this.dialog.open(CustomDateSelectComponent, {});
    dialogRef.afterClosed().subscribe((result: DatePickerSelection) => {
      if (!result) return;

      let startDate;
      let endDate;
      if (result.startDate < result.endDate) {
        startDate = result.startDate;
        endDate = result.endDate;
      } else {
        endDate = result.startDate;
        startDate = result.endDate;
      }

      this.dateRangeSelected.emit([
        DateTime.fromJSDate(startDate),
        DateTime.fromJSDate(endDate),
      ]);
    });
  }
}
