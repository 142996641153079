import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  toEmailGroupInternalModelFromGQL,
  EmailGroupInternalModel,
} from '@spog-ui/shared/models/email-groups';

@Injectable({ providedIn: 'root' })
export class EmailGroupsApiService {
  constructor(readonly gql: GraphQLAPIService) {}

  getEmailGroupsByOrganization(
    organizationId: string,
  ): Observable<EmailGroupInternalModel[]> {
    return this.gql.getEmailGroupsByOrganization({ organizationId }).pipe(
      map(response => {
        if (!response.organization) {
          throw new Error('No organization for id');
        }

        return response.organization!.emailGroups.edges.map(edge =>
          toEmailGroupInternalModelFromGQL(edge.node),
        );
      }),
    );
  }

  delete(id: string): Observable<string | undefined | null> {
    return this.gql
      .deleteEmailGroup({ input: { id } })
      .pipe(map(response => response.deleteEmailGroup));
  }
}
