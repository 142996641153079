import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { AddIndieSensorPage } from '@spog-ui/indie-sensors/actions';
import * as SenseState from '@spog-ui/shared/state/sense';
import * as CoreState from '@spog-ui/shared/state/core';
import {
  CreatableHardwareType,
  Sense420IndustrialSensorRequiredProps,
} from '@spog-ui/shared/models/indie-sensors';

@Component({
  selector: 'spog-add-indie-sensor-page',
  template: `
    @if (!(hardwareType$ | async); as hardwareType) {
      <div>
        <spog-hardware-type-selector
          (select)="onSelect($event)"
          (cancel)="onCancel()"
        ></spog-hardware-type-selector>
      </div>
    } @else {
      @if (isLoading$ | async) {
        <div class="loading">
          <sui-spinner></sui-spinner>
        </div>
      } @else {
        @switch (hardwareType$ | async) {
          @case ('Sense420Sensor') {
            <spog-sense-420-form
              [pending]="isSaving$ | async"
              [snapaddrs]="snapaddrs$ | async"
              (save)="onSaveSense420($event)"
              (cancel)="onUnselectHardwareType()"
              >
            </spog-sense-420-form>
          }
        }
      }
    }
    
    `,
  styles: [
    `
      sui-spinner {
        display: block;
        width: 40px;
        margin: 100px auto 0;
      }
    `,
  ],
})
export class AddIndieSensorPageComponent implements OnInit, OnDestroy {
  hardwareType$ = this.store.select(SenseState.selectAddIndieSensorPageHardwareType);
  isLoading$ = this.store.select(SenseState.selectAddIndieSensorPageIsLoading);
  isSaving$ = this.store.select(SenseState.selectSavingIndieSensor);
  snapaddrs$ = this.store.select(CoreState.selectUsedSnapaddrs);

  constructor(public store: Store, readonly location: Location) {}

  onSelect($event: CreatableHardwareType) {
    this.store.dispatch(AddIndieSensorPage.selectHardwareTypeAction($event));
  }

  onSaveSense420($event: {
    isSnapAddrChanged: boolean;
    model: Sense420IndustrialSensorRequiredProps;
  }) {
    this.store.dispatch(AddIndieSensorPage.addSense420IndieSensorAction($event.model));
  }

  onCancel() {
    this.location.back();
  }

  onUnselectHardwareType() {
    this.store.dispatch(AddIndieSensorPage.unselectHardwareTypeAction());
  }

  ngOnInit() {
    this.store.dispatch(AddIndieSensorPage.enterAction());
  }

  ngOnDestroy() {
    this.store.dispatch(AddIndieSensorPage.leaveAction());
  }
}
