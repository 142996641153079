import { createSelector } from '@ngrx/store';
import {
  STATE_KEY,
  selectError,
  selectIsSubmitting,
} from './add-organization-user-page.reducer';
import { selectFeature } from './constants';

export const selectAddUserPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectAddUserPageError = createSelector(
  selectAddUserPageFeatureState,
  selectError,
);
export const selectIsAddUserPageSubmitting = createSelector(
  selectAddUserPageFeatureState,
  selectIsSubmitting,
);
