import {
  Inject,
  Injectable,
  InjectionToken,
  Optional,
  PLATFORM_ID,
  Provider,
} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

export const NAVIGATOR = new InjectionToken<Navigator>('Browser navigator');

@Injectable()
export class PlatformService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional()
    @Inject(NAVIGATOR)
    private nav: typeof navigator,
  ) {}

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  isServer() {
    return isPlatformServer(this.platformId);
  }

  isSafari() {
    if (this.nav) {
      return (
        this.nav.userAgent.indexOf('Safari') !== -1 &&
        this.nav.userAgent.indexOf('Chrome') === -1
      );
    }

    return false;
  }
}

export function createNavigator() {
  return typeof window.navigator === 'undefined' ? undefined : navigator;
}

export const PLATFORM_SERVICE_PROVIDERS: Provider[] = [
  PlatformService,
  {
    provide: NAVIGATOR,
    deps: [],
    useFactory: createNavigator,
  },
];
