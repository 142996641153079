import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-switch-and-occupancy',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        <strong>Auto ON to Manual or Override OFF</strong> - The switch and the occupancy
        sensor control the lights in the zone. Upon sensing motion, all lights in the zone
        are set to a user-defined dimming level between 0 and 100%. Upon vacancy
        detection, two levels of timeout and dimming are provided.
      </p>
      <div class="bfForm">
        <div class="bfChunk">
          <p class="bfLabel">When switched on, set dimming level to:</p>
          <sui-dimmer-control formControlName="switchOnLevel"></sui-dimmer-control>

          <p class="bfLabel">When switched off, set dimming level to:</p>
          <sui-dimmer-control formControlName="switchOffLevel"></sui-dimmer-control>
        </div>
        <div class="bfChunk">
          <p class="bfLabel">If vacant for a period of:</p>
          <sui-timeout-control formControlName="vacancyTimeout"></sui-timeout-control>

          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="vacancyLevel"></sui-dimmer-control>
        </div>
        <div class="bfChunk">
          <p class="bfLabel">After an additional vacancy period of:</p>
          <sui-timeout-control
            formControlName="extendedVacancyTimeout"
          ></sui-timeout-control>

          <p class="bfLabel">set the dimming level to:</p>
          <sui-dimmer-control formControlName="extendedVacancyLevel"></sui-dimmer-control>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      sui-dimmer-control {
        display: block;
      }

      sui-dimmer-control:last-child {
        margin-bottom: -13px;
      }

      .bfChunk:last-child sui-dimmer-control:last-child {
        margin-bottom: 0;
      }
    `,
  ],
})
export class BFSwitchAndOccupancyComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    switchOnLevel: new UntypedFormControl(80),
    switchOffLevel: new UntypedFormControl(0),
    vacancyTimeout: new UntypedFormControl(30),
    vacancyLevel: new UntypedFormControl(50),
    extendedVacancyTimeout: new UntypedFormControl(15),
    extendedVacancyLevel: new UntypedFormControl(0),
  });
}
