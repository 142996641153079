import { createAction } from '@ngrx/store';
import { OrganizationUserInternalModel } from '@spog-ui/shared/models/organization-users';

export const enterAction = createAction('[Admin Organization / Users ] Enter');

export const loadPageSuccessAction = createAction(
  '[Admin Organization / Users / API] Load Page Success',
  (users: OrganizationUserInternalModel[]) => ({
    users,
  }),
);

export const loadPageFailureAction = createAction(
  '[Admin Organization / Users / API] Load Page Failure',
  (error: string) => ({ error }),
);

export const removeOrganizationUserAction = createAction(
  '[Admin Organization / Users / Page] Remove Organization User',
  (user: OrganizationUserInternalModel) => ({ user }),
);

export const confirmRemoveOrganizationUserAction = createAction(
  '[Admin Organization / Users / Page] Confirm Remove Organization User',
  (user: OrganizationUserInternalModel) => ({ user }),
);

export const cancelRemoveOrganizationUserAction = createAction(
  '[Admin Organization / Users / Page] Cancel Remove Organization User',
);

export const filterAction = createAction(
  '[Admin Organization / Users / Page] Filter',
  (term: string) => {
    return { term };
  },
);

export const paginateAction = createAction(
  '[Admin Organization / Users / Page] Paginate',
  (pageIndex: number, pageSize: number) => {
    return { pageIndex, pageSize };
  },
);

export const resetPasswordAction = createAction(
  '[Admin Organization / Users / Page] Reset Password',
  (user: OrganizationUserInternalModel) => ({ user }),
);

export const resetPasswordConfirmAction = createAction(
  '[Admin Organization / Users / Page] Confirm Reset Password',
  (user: OrganizationUserInternalModel) => ({ user }),
);

export const resetPasswordCancelAction = createAction(
  '[Admin Organization / Users / Page] Cancel Reset Password',
);
