/**
 * This is a global set of knobs that control access to features
 * across our applications. Please take care adding new knobs
 * and be sure to thoroughly read through the knobs guide
 * on Confluence:
 */

export enum Knobs {
  All = 0b1111_1111,
  None = 0b0000_0000,

  SceneViews = 0b0000_0001,
}

export function getKnobs(defaultKnobs: number): number {
  if (typeof window === 'undefined') {
    return defaultKnobs;
  }

  const overrides = sessionStorage?.getItem('knobs');

  if (overrides) {
    return JSON.parse(overrides);
  }

  return defaultKnobs;
}
