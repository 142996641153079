import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EulaUserActions } from '@spog-ui/current-user/eula-actions';
import { Observable } from 'rxjs';
import * as CoreState from '@spog-ui/shared/state/core';

@Component({
  selector: 'spog-eula-modal',
  template: `
    <sui-dialog class="eulaModal">
      <sui-dialog-title>End User License Agreement</sui-dialog-title>
      <sui-dialog-content>
        <spog-eula-form
          (accepted)="onSubmit()"
          [showAccept]="showAccept"
          [eula]="eula"
          [error]="error$ | async"
        >
        </spog-eula-form>
      </sui-dialog-content>
    </sui-dialog>
  `,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      :host {
        padding: 24px;
      }

      .eulaModal {
        display: block;
        width: 100%;
        max-width: 900px;
        padding: 20px;
        margin: 4px auto;
      }

      spog-eula-form ::ng-deep sui-card {
        margin-bottom: 20px;
        padding: 16px;
      }
    `,
  ],
})
export class EulaModalComponent {
  showAccept: boolean;

  constructor(
    private store: Store,
    private dialog: MatDialogRef<EulaModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: { showAccept: boolean },
  ) {
    this.showAccept = data.showAccept;
  }

  error$: Observable<any> = this.store.select(CoreState.selectEulaError);
  eula = `<article>
  <p class="intro">
    YOU MUST CAREFULLY READ THESE USE TERMS AND THE TERMS OF THE SYNAPSE PRODUCT LICENSE AGREEMENT(THE “AGREEMENT”),
    AVAILABLE AT
    <a target="_blank" href="HTTP://WWW.SYNAPSE-WIRELESS.COM/UPL/DOWNLOADS/TERMS-AND-CONDTIONS/SYNAPSE-SNAP-AND-SNAP-PORTAL-SOFTWARE-LICENSE-2101C442.PDF">
      HTTP://WWW.SYNAPSE-WIRELESS.COM/UPL/DOWNLOADS/TERMS-AND-CONDTIONS/SYNAPSE-SNAP-AND-SNAP-PORTAL-SOFTWARE-LICENSE-2101C442.PDF
    </a>
    AND INCORPORATED HEREIN BY REFERENCE IN ITS ENTIRETY (COLLECTIVELY, THE “TERMS”) BEFORE USING THE SIMPLYSNAP PRODUCTS AND
    SIMPLYSNAP SOFTWARE (AS THESE TERMS ARE DEFINED BELOW) RECEIVED BY YOU FROM SYNAPSE OR AUTHORIZED DISTRIBUTORS OF SYNAPSE.
    IN CASE OF A CONFLICT BETWEEN THE AGREEMENT AND THESE USE TERMS, THESE USE TERMS SHALL CONTROL.
    BY INSTALLING OR USING ALL OR ANY PORTION OF THE SIMPLYSNAP PRODUCTS OR SIMPLYSNAP SOFTWARE,
    YOU ACCEPT ALL THE TERMS.
    YOU FURTHER AGREE THAT THE TERMS ARE ENFORCEABLE LIKE ANY WRITTEN NEGOTIATED AGREEMENT SIGNED BY YOU.
    THE TERMS ARE ENFORCEABLE AGAINST YOU AND ANY INDIVIDUAL OR LEGAL ENTITY THAT OBTAINS THE SIMPLYSNAP PRODUCTS OR
    SIMPLYSNAP SOFTWARE AND ON WHOSE BEHALF THEY ARE USED.
    IF YOU DO NOT AGREE TO THE FOREGOING AND THE FOLLOWING ADDITIONAL USE TERMS, DO NOT USE,
    AND IMMEDIATELY RETURN TO SYNAPSE, THE SIMPLYSNAP PRODUCTS AND SIMPLYSNAP SOFTWARE.</p>
  <ol class="legal">
    <li>
      <strong>Definitions.</strong> Capitalized terms used herein but not otherwise defined have the meanings ascribed thereto in the Agreement.
      For SimplySnap Products only, the following terms have the following meanings:
      <ol>
        <li><strong>“SimplySnap Product”</strong> means a module, gateway, controller, or other product sold or otherwise
          provided by Synapse or an authorized distributor of Synapse and storing or otherwise containing an instance of the
          SimplySnap Software and other SNAP Software.</li>

        <li><strong>“SimplySnap Software”</strong> means software developed by Synapse that:
          (1) monitors, controls and manages functions of a SNAP Network of lighting controllers,
          (2) generates a user interface for displaying lighting usage and statistical information from the SNAP Network and
          receiving user inputs for communicating with lighting controllers via the SNAP Network,
          such as on a computer or mobile device, and
          (3) is marketed by Synapse under the trademarks “SimplySnap&trade;,” “SimplySnap Remote Access&trade;,” and “Synapse Lighting Installer&trade;.” </li>

        <li><strong>“SNAP Network”</strong> means a collection of SNAP Nodes that
          (1) are able to communicate with each other directly or indirectly via the proprietary network communication
          protocol used by the SNAP Software, whether wirelessly, serially,
          over a Transmission Control Protocol/Internet Protocol (“TCP/IP”) connection, or otherwise or
          (2) act, behave, or otherwise operate in a coordinated fashion, including but not limited to operating under
          the control of or communicating with a common controller or coordinator.
          For clarification, SNAP Nodes capable of reaching each other via an established TCP/IP connection through the
          Internet or otherwise are part of the same SNAP Network for the purposes of these Terms, and
          SNAP Nodes that effectively communicate with each other through an intermediary or that operate based on
          information from one another are part of the same SNAP Network for the purposes of these Terms.
          Such communication could be direct or indirect via some other protocol besides the proprietary network protocol
          used by the SNAP Software, including but not limited to:  communicating through shared memory,
          communicating through a database, communicating through a serial interface,
          communicating through a parallel interface, or communicating through any other entity acting as an intermediary.</li>

        <li><strong>“SNAP Node”</strong> means an instance of the SimplySnap Software that is separately addressable in a SNAP Network.</li>

        <li><strong>“SNAP Software”</strong> means software developed by Synapse that:
          (1) implements a wireless mesh network platform on which application layer scripts can run and
          (2) is marketed by Synapse under the trademark SNAP®. For SimplySnap Products,
          the definition of SNAP Software includes the SimplySnap Software.</li>
      </ol>
    </li>

    <li>
      <strong>Maintenance and Support.</strong>
      <ol>
        <li><strong>Updates:</strong>
          <ol>
            <li>Synapse may, from time to time and in its sole discretion, develop and provide updates to the SimplySnap Software, which may include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including related documentation, “Updates”).  Updates may also modify or delete in their entirety certain features and functionality.  You agree that Synapse has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality.  Synapse may provide Updates: (1) on storage media, such as a USB Flash Drive; (2) by making Updates available on a website for download to a computer connected to the internet; (3) by automatically downloading and installing all available Updates to your mobile device when connected to the internet based on your mobile device settings; (4) by prompting you to download and install available Updates; or (5) otherwise.  You agree to promptly download and install all Updates, and acknowledge and agree that the SimplySnap Software or portions thereof may not properly operate should you fail to do so.  You further agree
              that all Updates will be deemed part of the SimplySnap Software and be subject to all terms and conditions
              of these Terms.</li>
            <li>You acknowledge that Synapse may, in its sole discretion, charge you a fee for each Update you download and install (an “Update Fee”), and you agree to pay such Update Fee when due.  Unless otherwise indicated by Synapse, Update Fees are due and payable within thirty (30) days of the date when Synapse provides the Update to you on storage media,
              when the Update automatically downloads to your computer or mobile device, or when you download the Update.</li>
          </ol>
        </li>

        <li><strong>Software Support Services:</strong>
          <ol>
            <li>Synapse may provide you with an option to purchase a one-time upgrade to the then-current version or
              release of the SimplySnap Software in exchange for an associated one-time Update Fee.
              Synapse also may provide you an opportunity to subscribe to basic software maintenance and support services for
              the SimplySnap Software, including: (1) providing Updates to ensure compatibility with future versions of the
              SimplySnap Software; and (2) providing access to tiered technical support (the “Services”).
              The Services are not available until 2017.
              Except as otherwise provided in this Section 2, you have the option to subscribe to the Services:</li>

            <li>i.	For a period of one (1) year following the date that you purchase a SimplySnap Product with
              embedded SimplySnap Software or download a copy of the SimplySnap Software to a mobile device; and</li>

            <li>ii.	thereafter, the Services will renew automatically ninety (90) days before the date on which the
              then-current one (1) year period ends unless you sooner notify Synapse in writing to cancel your
              subscription or Synapse notifies you in writing prior to the
              commencement of the next one (1) year term that it will not renew the Services.</li>

            <li>
              <p>
                Synapse has no obligation to provide the Services, including Updates:
                (1) for any but the most current or immediately preceding version or release of the SimplySnap Software;
                (2) for any copy of SimplySnap Software for which all previously issued Updates have not been installed;
                (3) if you are in breach under these Terms; or (4) for any SimplySnap Software that has been modified other than
                by Synapse, or that is being used with any hardware, software, configuration or operating system other than
                the SimplySnap Products that is not expressly authorized by Synapse in writing.
              </p>
              <p>
                You acknowledge that Synapse may, in its sole discretion, and by notice to you at least ninety (90) days
                in advance of the date on which any such fee shall be become due, charge you a subscription fee for
                the Services (a “Service Fee”), and you agree to pay such Service Fee when due.
                Synapse reserves the right to provide you such notice fewer than ninety (90) days in advance of the date on
                which any Service Fee shall be become due.  Unless otherwise indicated by Synapse,
                Service Fees are due and payable within thirty (30) days of: (1) the commencement of Services and
                (2) the date on which your subscription to the Services for each additional one (1) year period renews.
                Any renewal of the Services hereunder shall not be effective until the Service Fees for such renewal
                have been paid in full.
                Your subscription to the Services may be canceled without terminating these Terms.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </li>

    <li><strong>Collection and Use of Information.</strong>
      <p>
        You acknowledge that when you download, install, and use the SimplySnap Software, or at other times as determined by
        Synapse, Synapse may collect and store information about your use and configuration of SimplySnap Products and
        SimplySnap Software, including information about maintenance and support services, security measures, page view
        analytics, web page behavior, application exception reports, and system log files.
        You also may be required to provide certain information about yourself as a condition to downloading, installing, or
        using the SimplySnap Software or certain of its features or functionality.
        Synapse will collect such information anonymously, and will store such information in a way that does not identify you.
        You acknowledge that Synapse may use such information for any purpose related to your use of Synapse Products,
        SimplySnap Products and SimplySnap Software, including: (1) improving performance of the SimplySnap Software or
        developing Updates; (2) verifying your compliance with these Terms and enforcing Synapse’s rights in the Synapse
        Products, SimplySnap Products and SimplySnap Software; and
        (3) providing maintenance and support services for the Synapse Products, SimplySnap Products and SimplySnap Software.
      </p>
      <p>
        During installation, setup, configuration or download of the SimplySnap Products, SimplySnap Software, and any Updates, or at other times as determined by Synapse, versions of the SimplySnap Software and any Updates that perform collection of the above information may provide you with a notification of such collection and an opt out feature.  If you do not want Synapse to collect this information, you may opt out using the opt out feature provided in the notification.  For more information, see the Synapse Privacy Policy, as may be amended from time to time, available at http://www.synapse-wireless.com/privacy-policy.  Note, however, that opting out of collection of this information may limit or disable some features or functionality of the Synapse Products or SimplySnap Software.  Opting out of collection of location information will cause any location-based features to be disabled.
      </p>
      <p>
        All information Synapse collects through or in connection with the SimplySnap Software is subject to the Synapse Privacy Policy, a copy of which is found at http://www.synapse-wireless.com/privacy-policy.  By downloading, installing, using, and providing information to or through the SimplySnap Software, you consent to all actions taken by Synapse with respect to your information in compliance with the Synapse Privacy Policy.
      </p>
      <p>
        You also acknowledge that, to access SimplySnap Software or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the SimplySnap Software that all the information you provide is correct, current and complete. You agree that all information you provide to register SimplySnap Software is governed by the Synapse Privacy Policy, and you consent to all actions Synapse takes with respect to your information consistent with the Synapse Privacy Policy.
      </p>
      <p>
        Synapse has the right to disable any user name, password or other identifier, whether chosen by you or provided by Synapse, at any time in our sole discretion for any or no reason, including if, in Synapse’s opinion, you have violated any provision of these Terms or the Privacy Policy.
      </p>
    </li>

    <li><strong>Indemnification and Limitation of Use.</strong>
      <ol>
        <li>You agree to indemnify, defend, and hold harmless Synapse and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including  attorneys’ fees and expenses, arising from or relating to your use or misuse of the SimplySnap Products and the SimplySnap Software or your breach of these Terms.</li>
        <li>The SimplySnap Products and the SimplySnap Software are not designed, manufactured, or intended for use or resale in hazardous environments or applications requiring fail-safe performance, such as the operation of nuclear facilities, aircraft navigation, air traffic control, life support machines, medical monitoring, medication delivery, weapons systems, remote control or any industry or application in which technology failure could lead to death, personal injury, severe physical or environmental damage or damage to property.  You agree to indemnify, defend and hold Synapse and its distributors harmless from and against any and all loss, cost, damage, judgment or expense (including reasonable attorneys’ fees and expenses) arising out of your use of the SimplySnap Products or the SimplySnap Software in such hazardous environments or applications.</li>
      </ol>
    </li>

    <li><strong>Termination</strong>
      <p>These Terms are effective until terminated pursuant to this Section.
      You may terminate these Terms at any time by destroying or returning to Synapse the
      SimplySnap Products and the SimplySnap Software in your possession or control, including all computer programs
      and documentation, and erasing any copies residing on computer equipment in your possession or control.
      These Terms also will terminate if you do not comply with any terms or conditions of these Terms, including timely
      payment of any fees due.
      Upon such termination, you agree to destroy or return to Synapse the SimplySnap Products and the
      SimplySnap Software in your possession or control and erase all copies residing on computer equipment in your possession or
      control.  No refund shall be due for any SimplySnap Products or SimplySnap Software used by you prior to termination.
      Sections 1-4 and 6-7 shall survive termination.  Use of the SimplySnap Software licensed under these Terms is not
      permitted after termination.</p>
    </li>

    <li><strong>Severability</strong>
      <p>If any provision of these Terms is held by a court of competent jurisdiction to be contrary to law, the remaining provisions of these Terms will remain in full force and effect and will be interpreted, to the extent possible, to achieve its purposes without the invalid, illegal, or unenforceable provision.</p>
    </li>

    <li><strong>Complete Agreement and Binding Effect.</strong>
      <p>These Terms constitute the complete agreement between the parties and supersedes any prior agreements or understandings, verbal or written, with respect to the subject matter hereof.  These Terms shall be binding upon and inure to the benefit of the parties hereto, their successors and assigns.</p>
    </li>
  </ol>
</article>`;

  onSubmit() {
    this.store.dispatch(EulaUserActions.eulaAcceptedAction(true));
    this.dialog.close();
  }
}
