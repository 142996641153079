import { createAction } from '@ngrx/store';
import { EquipmentInternalModel } from '@spog-ui/shared/models/resource-groups';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';
import { ResourceGroupInternalModel } from '@spog-ui/shared/models/resource-groups';

export const loadEquipmentDetailsModelsSuccessAction = createAction(
  '[Equipment Details API] Load Page Models Success',
  (
    resourceGroups: ResourceGroupInternalModel[],
    indieSensors: IndieSensorInternalModel[],
  ) => ({
    resourceGroups,
    indieSensors,
  }),
);

export const loadEquipmentDetailsModelsFailureAction = createAction(
  '[Equipment Details API] Load Page Models Failure',
  (error: string) => ({ error }),
);

export const deleteEquipmentSuccess = createAction(
  '[Equipment Details API] Delete Equipment Success',
);

export const deleteEquipmentFailure = createAction(
  '[Equipment Details API] Delete Equipment Failure',
  (error: string) => ({ error }),
);
