import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AdminOrgShellPageActions, AdminOrgShellApiActions } from '../actions';
import {
  GetOrganizationByIdQueryResult,
  GraphQLAPIService,
} from '@spog-ui/graphql/types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { HttpErrorResponse } from '@angular/common/http';
import { toOrganizationInternalModelFromIdentityGQL } from '@spog-ui/shared/models/organizations';
import { AdminOrganizationShellApiService } from '../services';
import { OrganizationStateActions } from '@spog-ui/shared/state/organizations';

@Injectable()
export class AdminOrganizationShellApiEffects {
  constructor(
    readonly actions$: Actions,
    readonly gql: GraphQLAPIService,
    readonly snackbar: MatSnackBar,
    readonly adminOrganizationShellApiService: AdminOrganizationShellApiService,
  ) {}

  getOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrgShellPageActions.enterAction),
      switchMap(action => {
        return this.adminOrganizationShellApiService
          .getOrganizationById(action.organizationId)
          .pipe(
            map(organization =>
              AdminOrgShellApiActions.loadPageSuccessAction(organization),
            ),
            catchError((response: HttpErrorResponse) => {
              return of(
                AdminOrgShellApiActions.loadPageFailureAction(
                  retrieveErrorMessage(response),
                ),
              );
            }),
          );
      }),
    ),
  );

  loadOrganizationIntoState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrgShellApiActions.loadPageSuccessAction),
      map(action =>
        OrganizationStateActions.loadOrganizationsSuccessAction([action.organization]),
      ),
    ),
  );

  updateOrganizationDefaultSiteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminOrgShellPageActions.updateOrganizationDefaultSiteRoleAction),
      switchMap(action => {
        const input = {
          id: action.id!,
          defaultSiteRole: action.defaultSiteRole,
        };
        return this.gql.updateOrganization({ input }).pipe(
          map(() => AdminOrgShellApiActions.updateOrgDefaultSiteRoleSuccessAction()),
          catchError(error => {
            return of(
              AdminOrgShellApiActions.updateOrgDefaultSiteRoleFailureAction(error),
            );
          }),
        );
      }),
    ),
  );

  updateOrganizationDefaultSiteRoleFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AdminOrgShellApiActions.updateOrgDefaultSiteRoleFailureAction),
        tap(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );
}
