import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { SiteControllerApiActions } from '@spog-ui/shared/effects/actions';
import { SiteApiActions } from '@spog-ui/admin/actions';
import {
  AddThermostatApiActions,
  EditThermostatApiActions,
} from '@spog-ui/thermostats/actions';
import { ThermostatDetailsApiActions } from '@spog-ui/thermostat-details/actions';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';
import { SenseMapLayerApiActions } from '@spog-ui/sense-map-layer/actions';
import { IndieSensorDetailsAPIActions } from '@spog-ui/indie-sensor-details-actions';
import { IndieSensorsApi } from '@spog-ui/indie-sensors/actions';
import { SiteControllerDetailsApiActions } from '@spog-ui/site-controller-details/state';
import * as SiteControllersStateActions from './site-controllers.actions';
import { AlarmsApiActions } from '@spog-ui/alarms/actions';
import { SiteDetailsApiActions } from '@spog-ui/site-details/actions';
import { SiteSelectorApiActions } from '@spog-ui/site-selector/actions';

export type Shape = EntityState<SiteControllerInternalModel>;

export const adapter = createEntityAdapter<SiteControllerInternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    SiteControllerApiActions.loadAllSuccessAction,
    AddThermostatApiActions.loadAddThermostatPageModelsSuccessAction,
    EditThermostatApiActions.loadEditThermostatPageModelsSuccessAction,
    IndieSensorsApi.loadIndieSensorsAndControllersSuccessAction,
    AlarmsApiActions.loadAlarmsSuccess,
    SiteDetailsApiActions.loadSiteDetailsSuccessAction,
    SiteSelectorApiActions.getAllModelsSuccessFromLoginAction,
    (state, action) => {
      return adapter.upsertMany(action.siteControllers, state);
    },
  ),
  on(SiteControllerApiActions.loadModelsSuccessAction, (state, action) => {
    return adapter.upsertMany(action.models.siteControllers, state);
  }),
  on(SiteControllerApiActions.loadAllFailureAction, (state, action) => {
    return { ...state, isLoading: false, error: action.error };
  }),
  on(
    SiteControllerApiActions.loadAllAdminSuccessAction,
    SiteApiActions.loadModelsSuccessAction,
    SiteApiActions.loadModelsForEditSuccessAction,
    IndieSensorDetailsAPIActions.loadIndieSensorDetailsPageModelsSuccessAction,
    (state, action) => {
      return adapter.upsertMany(action.models.siteControllers, state);
    },
  ),
  on(
    ThermostatDetailsApiActions.loadThermostatDetailsSuccessAction,
    SiteControllerDetailsApiActions.loadSiteControllerDetailsSuccessAction,
    (state, action) => {
      if (action.siteController) {
        return adapter.upsertOne(action.siteController, state);
      } else {
        return state;
      }
    },
  ),
  on(SiteControllerApiActions.deleteSuccessAction, (state, action) => {
    return adapter.removeOne(action.siteController.id, state);
  }),
  on(SenseMapLayerApiActions.loadSenseMapLayerModelsSuccessAction, (state, action) => {
    return adapter.upsertMany(action.siteControllers, state);
  }),
  on(SiteControllersStateActions.loadAction, (state, action) => {
    return adapter.upsertMany(action.siteControllers, state);
  }),
);

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
