import { createAction } from '@ngrx/store';
import { BehaviorInternalModel } from '@spog-ui/shared/models/behaviors';
import { ControllerInternalModel } from '@spog-ui/shared/models/controllers';
import { LightZoneInternalModel } from '@spog-ui/shared/models/light-zones';
import { LightInternalModel } from '@spog-ui/shared/models/lights';
import { SceneZoneBehaviorInternalModel } from '@spog-ui/shared/models/scene-zone-behaviors';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

export const loadLightMapLayerModelsSuccessAction = createAction(
  '[Light Map Layer API] Load Light Map Layer Models Success',
  (models: { lights: LightInternalModel[] }) => {
    return { models };
  },
);

export const loadLightMapLayerModelsFailureAction = createAction(
  '[Light Map Layer API] Load Light Map Layer Models Failure',
  (error: any) => ({ error }),
);

export const loadLightMapLayerDeferredModelsSuccessAction = createAction(
  '[Light Map Layer API] Load Light Map Layer Deferred Models Success',
  (models: {
    lightZones: LightZoneInternalModel[];
    controlZones: ZoneInternalModel[];
    behaviors: BehaviorInternalModel[];
    scenes: SceneInternalModel[];
    sceneZoneBehaviors: SceneZoneBehaviorInternalModel[];
    controllers: ControllerInternalModel[];
  }) => {
    return { models };
  },
);

export const loadLightMapLayerDeferredModelsFailureAction = createAction(
  '[Light Map Layer API] Load Light Map Layer Deferred Models Failure',
  (error: any) => ({ error }),
);
