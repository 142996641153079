import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as SenseState from '@spog-ui/shared/state/sense';

@Component({
  selector: 'spog-indie-sensors-page-no-results',
  template: `
    @if (showNoResultsCard$ | async) {
      <sui-card>
        No industrial sensors found for the search term "{{ searchTerm$ | async }}"
      </sui-card>
    }
    `,
  styles: [
    `
      sui-card {
        opacity: 0.65;
        font-style: italic;
        padding: 16px;
      }
    `,
  ],
})
export class IndieSensorsPageNoResultsComponent {
  searchTerm$ = this.store.select(SenseState.selectIndieSensorsPageSearchTerm);
  showNoResultsCard$ = this.store.select(
    SenseState.selectIndieSensorsPageShowNoResultsCard,
  );

  constructor(public store: Store) {}
}
