import { createSelector } from '@ngrx/store';
import { selectOrgsPage } from './admin-section-organizations.feature-selector';
import * as OrgsPageState from './organizations-page.reducer';

export const selectIsOrgsPageLoading = createSelector(
  selectOrgsPage,
  OrgsPageState.selectIsLoading,
);

export const selectOrgsPageError = createSelector(
  selectOrgsPage,
  OrgsPageState.selectError,
);

export const selectOrgsPageFilter = createSelector(
  selectOrgsPage,
  OrgsPageState.selectSearchTerm,
);

export const selectOrgsPageIndex = createSelector(
  selectOrgsPage,
  OrgsPageState.selectPageIndex,
);
export const selectOrgsPageSize = createSelector(
  selectOrgsPage,
  OrgsPageState.selectPageSize,
);
