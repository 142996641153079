import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EditUtilityServicePageActions } from '@spog-ui/utility-services/actions';
import * as EnergyInfoSystemState from '@spog-ui/shared/state/energy-info-system';
import * as CoreState from '@spog-ui/shared/state/core';
import {
  TakenLights,
  TakenPowerSensors,
  UtilityServiceFormModel,
} from '@spog-ui/shared/models/utility-services';
import { LightInternalModel } from '@spog-ui/shared/models/lights';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';

@Component({
  selector: 'spog-edit-utility-service-page',
  template: `
    @if (isLoading$ | async) {
      <sui-spinner></sui-spinner>
    } @else {
      <spog-utility-service-form
        [utilityService]="activeUtilityService$ | async"
        [takenPowerSensors]="takenPowerSensors$ | async"
        [takenLights]="takenLights$ | async"
        [indieSensors]="indieSensors$ | async"
        [lights]="lights$ | async"
        (save)="onSave($event)"
        [disabled]="isSubmitting$ | async"
      ></spog-utility-service-form>
    }
    `,
  styles: [
    `
      sui-spinner {
        display: block;
        width: 40px;
        margin: 100px auto 0;
      }
    `,
  ],
})
export class EditUtilityServicePageComponent implements OnInit, OnDestroy {
  isLoading$: Observable<boolean>;
  takenPowerSensors$: Observable<TakenPowerSensors>;
  takenLights$: Observable<TakenLights>;
  indieSensors$: Observable<IndieSensorInternalModel[]>;
  lights$: Observable<LightInternalModel[]>;
  isSubmitting$: Observable<boolean>;

  constructor(readonly store: Store) {
    this.isLoading$ = this.store.select(
      EnergyInfoSystemState.selectEditUtilityServicePageIsLoading,
    );
    this.takenPowerSensors$ = this.store.select(
      EnergyInfoSystemState.selectTakenPowerSensors,
    );
    this.takenLights$ = this.store.select(EnergyInfoSystemState.selectTakenLights);
    this.indieSensors$ = this.store.select(CoreState.selectAllPowerSensors);
    this.lights$ = this.store.select(CoreState.selectAllLights);
    this.isSubmitting$ = this.store.select(
      EnergyInfoSystemState.selectEditUtilityServicePageIsSubmitting,
    );
  }

  activeUtilityService$ = this.store.select(
    EnergyInfoSystemState.selectActiveUtilityService,
  );

  onSave($event: UtilityServiceFormModel) {
    this.store.dispatch(EditUtilityServicePageActions.editUtilityServiceAction($event));
  }

  ngOnInit() {
    this.store.dispatch(EditUtilityServicePageActions.enterAction());
  }

  ngOnDestroy() {
    this.store.dispatch(EditUtilityServicePageActions.leaveAction());
  }
}
