import {
  SiteControllerStatus,
  SiteControllerViewModel,
} from '@spog-ui/shared/models/site-controllers';
import { DateTime } from 'luxon';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';
import {
  SiteControllerResourceType,
  SiteControllerResourceStatus,
} from '@spog-ui/graphql/types';

export interface SiteControllerResourceDetailsViewModel {
  type: SiteControllerResourceType;
  typeString: string;
  status: SiteControllerResourceStatus;
  statusString: string;
  lastUpdateReceived: DateTime | null;
}

export interface SiteControllerDetailsViewModel {
  id: string;
  name: string;
  isConnected: boolean;
  status: SiteControllerStatus;
  systemVersion: string;
  macAddress: string;
  resources: SiteControllerResourceDetailsViewModel[];
  site: {
    id: string;
    name: string;
  } | null;
  showStatus: boolean;
  tunnelPort?: number;
}

export const createMockSiteControllerDetailsViewModel = (
  updates?: Partial<SiteControllerDetailsViewModel>,
): SiteControllerDetailsViewModel => ({
  id: 'dummy-site-controller-id',
  name: 'Dummy Site Controller',
  isConnected: true,
  status: 'ONLINE',
  systemVersion: '1.0.0',
  macAddress: 'ABCDEF',
  resources: [],
  site: null,
  showStatus: true,
  tunnelPort: 12345,
  ...updates,
});

export function toSiteControllerDetailsViewModelFromInternal(
  internal: SiteControllerViewModel,
  site: SiteInternalModel | null,
): SiteControllerDetailsViewModel {
  return {
    id: internal.id,
    name: internal.name ?? '',
    isConnected: internal.uiProxyUrl !== null,
    status: internal.status,
    systemVersion: internal.systemVersion ?? 'UNKNOWN',
    macAddress: internal.macAddress ?? 'UNKNOWN',
    resources: internal.resources.map(
      toSiteControllerResourceDetailsViewModelFromInternal,
    ),
    site: site
      ? {
          id: site.id,
          name: site.name,
        }
      : null,
    showStatus: internal.showStatus,
    ...(internal.tunnelPort ? { tunnelPort: internal.tunnelPort } : {}),
  };
}

export function toSiteControllerResourceDetailsViewModelFromInternal(
  internal: SiteControllerViewModel['resources'][0],
): SiteControllerResourceDetailsViewModel {
  const resourceTypeMap = new Map([
    ['CONFIG_SYNC', 'Config Sync'],
    ['STATE_SYNC', 'State Sync'],
    ['REMOTE_ACCESS', 'Remote Access'],
  ]);

  const resourceStatusMap = new Map([
    ['DOWN', 'DOWN'],
    ['IN_PROGRESS', 'IN PROGRESS'],
    ['INIT_FAILED', 'INIT FAILED'],
    ['PENDING_ACK', 'PENDING ACK'],
    ['UNKNOWN', 'UNKNOWN'],
    ['UP', 'UP'],
  ]);

  return {
    type: SiteControllerResourceType[internal.type],
    typeString: resourceTypeMap.get(internal.type) ?? 'Unknown',
    status: SiteControllerResourceStatus[internal.status],
    statusString: resourceStatusMap.get(internal.status) ?? 'UNKNOWN',
    lastUpdateReceived: internal.lastUpdateReceived
      ? DateTime.fromISO(internal.lastUpdateReceived, {
          setZone: true,
        }) || null
      : null,
  };
}
