import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TransformModel } from '@spog-ui/map-tools/models';

export const MIN_ZOOM_SCALE = 1 / 8;
export const MAX_ZOOM_SCALE = 1;

@Injectable({ providedIn: 'root' })
export class TransformAnimator {
  private changes$ = new Subject<{ transform: TransformModel; duration: number }>();

  getMapDimensions(): { width: number; height: number } {
    const navbarHeight = 60;
    const width = window.innerWidth;
    const height = window.innerHeight - navbarHeight;

    return { width, height };
  }

  clampScale(k: number): number {
    return Math.max(MIN_ZOOM_SCALE, Math.min(MAX_ZOOM_SCALE, k));
  }

  transitionTo({ duration = 300, x, y, k }: TransformModel & { duration?: number }) {
    this.changes$.next({
      transform: {
        x,
        y,
        k: this.clampScale(k),
      },
      duration,
    });
  }

  transitionToRect(rect: {
    x: number;
    y: number;
    height: number;
    width: number;
    padding?: number;
    duration?: number;
  }) {
    const padding = rect.padding ?? 96;
    const x = rect.x - padding;
    const y = rect.y - padding;
    const rectWidth = rect.width + padding * 2;
    const rectHeight = rect.height + padding * 2;
    const { width: mapWidth, height: mapHeight } = this.getMapDimensions();

    const diffInWidths = rectWidth - mapWidth;
    const diffInHeights = rectHeight - mapHeight;

    const scale =
      diffInWidths > diffInHeights
        ? this.clampScale(mapWidth / rectWidth)
        : this.clampScale(mapHeight / rectHeight);

    const scaledWidth = rectWidth * scale;
    const scaledHeight = rectHeight * scale;
    const scaledX = x * scale;
    const scaledY = y * scale;
    const mapMidX = mapWidth / 2;
    const mapMidY = mapHeight / 2;
    const scaledRectMidX = scaledWidth / 2;
    const scaledRectMidY = scaledHeight / 2;
    const translateX = mapMidX - scaledRectMidX - scaledX;
    const translateY = mapMidY - scaledRectMidY - scaledY;

    return this.transitionTo({
      k: scale,
      x: translateX,
      y: translateY,
      duration: rect.duration,
    });
  }

  observe() {
    return this.changes$.asObservable();
  }
}
