import { createAction } from '@ngrx/store';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const loadAllSuccessAction = createAction(
  '[Site Controller API] Load All View Models Success',
  (siteControllers: SiteControllerInternalModel[]) => {
    return { siteControllers };
  },
);
export const loadAllAdminSuccessAction = createAction(
  '[Site Controller API] Load All Admin View Models Success',
  (models: {
    siteControllers: SiteControllerInternalModel[];
    sites: SiteInternalModel[];
  }) => {
    return { models };
  },
);
export const loadAllFailureAction = createAction(
  '[Site Controller API] Load All View Models Failure',
  (error: string) => {
    return { error };
  },
);
export const loadModelsSuccessAction = createAction(
  '[Site Controller API] Load All Add / Edit Models Success',
  (models: {
    siteControllers: SiteControllerInternalModel[];
    sites: SiteInternalModel[];
  }) => ({
    models,
  }),
);
export const loadModelsFailureAction = createAction(
  '[Site Controller API] Load All Add / Edit Models Failure',
  (error: string) => {
    return { error };
  },
);
export const deleteSuccessAction = createAction(
  '[Site Controller API] Delete Success',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);
export const deleteFailureAction = createAction(
  '[Site Controller API] Delete Failure',
  (error: string) => {
    return { error };
  },
);
export const initResourcesSuccessAction = createAction(
  '[Site Controller API] Init Resources Success',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);
export const initResourcesFailureAction = createAction(
  '[Site Controller API] Init Resources Failure',
  (error: string) => {
    return { error };
  },
);
