import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sui-behavior-control-container',
  template: `
    <div class="suiBehaviorControlSelect">
      <ng-content select="sui-behavior-select"></ng-content>
    </div>
    <ng-content select="sui-behavior-license-message"></ng-content>
    <span class="suiBehaviorControlLabel">{{ optionsLabel }}</span>
    <div class="suiBehaviorControlForm">
      <ng-content select="sui-behavior-form"></ng-content>
    </div>
  `,
  styles: [
    `
      :host ::ng-deep sui-behavior-select {
        width: 100%;
        margin-bottom: 20px;
      }

      :host ::ng-deep .bfDescription {
        margin: 0;
        padding: 0;
        line-height: 20px;
        color: var(--color-foreground-text);
      }

      :host ::ng-deep .bfLabel {
        margin: 0;
        margin-top: 16px;
        padding: 0;
        font-size: 12px;
        font-weight: 500;
        color: var(--color-foreground-secondary-text);
      }

      :host ::ng-deep .bfForm {
        padding: 16px 0 0;
      }

      :host ::ng-deep .bfChunk {
        padding: 24px 0;
      }

      :host ::ng-deep .bfChunk:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
      }

      :host ::ng-deep .bfChunk .bfLabel {
        margin: 0;
        padding: 0;
      }

      .suiBehaviorControlLabel {
        display: block;
        padding: 8px 0;
        font-size: 12px;
        color: var(--color-foreground-secondary-text);
      }

      .suiBehaviorControlForm {
        background-color: var(--color-background-background);
        padding: 16px;
        border: 1px solid var(--color-foreground-divider);
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BehaviorControlContainerComponent {
  @Input() optionsLabel = 'Behavior Options';
}
