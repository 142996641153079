import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { mapValues } from 'lodash';
import { SocketActions } from '@spog-ui/socket/actions';
import { ThermostatsApiActions } from '@spog-ui/thermostats/actions';
import { ThermostatAlarmReason } from '@spog-ui/graphql/types';
import {
  ThermostatAlarmInternalModel,
  ThermostatStatus,
} from '@spog-ui/shared/models/thermostats';
import { ClimateMapPageApiActions } from '@spog-ui/climate-map-layer/actions';
import { ClimateZonesApiActions } from '@spog-ui/climate-zones/actions';

export type Shape = EntityState<ThermostatAlarmInternalModel>;

export const adapter = createEntityAdapter<ThermostatAlarmInternalModel>();

export const initialState = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    ThermostatsApiActions.loadThermostatsPageModelsSuccessAction,
    (state, action): Shape => {
      return adapter.setAll(action.alarms, state);
    },
  ),
  on(
    ClimateZonesApiActions.loadClimateZonesPageModelsSuccessAction,
    (state, action): Shape => {
      return adapter.setAll(action.models.alarms, state);
    },
  ),
  on(
    ClimateMapPageApiActions.loadClimateMapPageModelsSuccessAction,
    (state, action): Shape => {
      return adapter.setAll(action.models.thermostatAlarms, state);
    },
  ),
  on(SocketActions.thermostatAlarmStatusAction, (state, action) => {
    if (action.alarmStatus.alarmCleared) {
      return adapter.removeOne(action.alarmStatus.thermostatId, state);
    } else {
      return adapter.upsertOne(action.alarmStatus, state);
    }
  }),
  on(SocketActions.deleteThermostatAlarmAction, (state, action) => {
    return adapter.removeOne(action.id, state);
  }),
);

export const { selectEntities, selectIds, selectAll } = adapter.getSelectors();

/**
 * Returns a map of thermostat ID to thermostat alarm reasons.
 */
export const selectThermostatAlarmTypeByThermostatId = createSelector(
  selectAll,
  alarms => {
    return alarms.reduce<{ [thermostatId: string]: string[] }>(
      (thermostatAlarmTypesDictionary, { thermostatId, reason }) => {
        if (thermostatId) {
          if (thermostatAlarmTypesDictionary[thermostatId]) {
            thermostatAlarmTypesDictionary[thermostatId].push(reason);
          } else {
            thermostatAlarmTypesDictionary[thermostatId] = [reason];
          }
        }
        return thermostatAlarmTypesDictionary;
      },
      {},
    );
  },
);

/**
 * Returns a map of thermostat ID to "status" (a bitfield representation of
 * the thermostat alarm reasons).
 */
export const selectThermostatAlarmsLookupTableByThermostatId = createSelector(
  selectThermostatAlarmTypeByThermostatId,
  (thermostatAlarmTypeByThermostatId): { [thermostatId: string]: any } => {
    return mapValues(thermostatAlarmTypeByThermostatId, alarmTypes => {
      return alarmTypes.reduce((status: number, alarmType): number => {
        switch (alarmType) {
          case ThermostatAlarmReason.COMMUNICATION_FAILURE: {
            return status | ThermostatStatus.communicationFailure;
          }
          case ThermostatAlarmReason.CONFIGURATION_FAILURE: {
            return status | ThermostatStatus.configurationFailure;
          }
          case ThermostatAlarmReason.LINK_QUALITY_WARNING: {
            return status | ThermostatStatus.linkQualityWarning;
          }
          case ThermostatAlarmReason.MISSING_SCRIPT_FAILURE: {
            return status | ThermostatStatus.missingScriptFailure;
          }
          case ThermostatAlarmReason.CENSUS_FAILURE: {
            return status | ThermostatStatus.censusFailure;
          }
          default: {
            return status;
          }
        }
      }, ThermostatStatus.ok);
    });
  },
);
