import { createAction } from '@ngrx/store';

export const createPermissionGroupSuccessAction = createAction(
  '[Permission Group / API] Create Permission Group Success',
);

export const createPermissionGroupFailureAction = createAction(
  '[Permission Group / API] Create Permission Group Failure',
  (error?: any) => ({ error }),
);

export const deletePermissionGroupFailureAction = createAction(
  '[Permission Group / API] Delete Permission Group Failure',
  (error?: any) => ({ error }),
);

export const updatePermissionGroupSuccessAction = createAction(
  '[Permission Group / API] Update Permission Group Success',
);

export const updatePermissionGroupFailureAction = createAction(
  '[Permission Group / API] Update Permission Group Failure',
  (error?: any) => ({ error }),
);
