import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as EmailGroupsState from './email-groups.reducer';

export const STATE_KEY = 'email-groups';

export interface Shape {
  emailGroupsState: EmailGroupsState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  emailGroupsState: EmailGroupsState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

export const selectEmailGroupState = createSelector(
  selectFeatureState,
  state => state.emailGroupsState,
);

export const selectAllEmailGroups = createSelector(
  selectEmailGroupState,
  EmailGroupsState.selectAll,
);
