import {
  AssetDispositionChangeModelFragment,
  AssetDispositionModelFragment,
  AssetRunningStateModelFragment,
  CreateResourceGroupInput,
  DefinedAssetTypeEnum,
  ResourceGroupModelFragment,
  UpdateResourceGroupInput,
} from '@spog-ui/graphql/types';
import {
  EnergyConsumptionGQLModel,
  EnergyConsumptionInternalModel,
  toEnergyConsumptionInternalModelFromGQL,
} from '@spog-ui/shared/models/energy-consumptions';

export enum ResourceGroupType {
  LegacyResourceGroup,
  Asset,
  AssetCollection,
  Department,
}

export interface BaseResourceGroup {
  id: string;
  name: string;
  resourceGroupIds: string[];
  energyConsumption?: EnergyConsumptionInternalModel;
  type?: ResourceGroupType;
}

export interface LegacyResourceGroupInternalModel extends BaseResourceGroup {
  zoneIds: string[];
  industrialSensorIds: string[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AssetCollectionInternalModel extends BaseResourceGroup {}

export interface EquipmentInternalModel extends BaseResourceGroup {
  assetType: DefinedAssetTypeEnum | string;
  industrialSensorIds: string[];
  make: string | null;
  model: string | null;
  imageUrl: string | null;
  runningState: AssetRunningStateModelFragment;
  dispositionChanges: AssetDispositionChangeModelFragment[];
  disposition: AssetDispositionModelFragment;

  floorPlanId?: string;
  floorPlanX?: number;
  floorPlanY?: number;
}

export interface DepartmentInternalModel extends BaseResourceGroup {
  zoneIds: string[];
  energyProfileEquipmentIds: string[];
}

/**
 * Normalized Resource Group appropriate for
 * keeping in the store
 */
export type ResourceGroupInternalModel =
  | LegacyResourceGroupInternalModel
  | EquipmentInternalModel
  | AssetCollectionInternalModel
  | DepartmentInternalModel;

/**
 * ResourceGroups from our GraphQL API
 */
export type ResourceGroupGQLModel = ResourceGroupModelFragment & {
  energyConsumption?: EnergyConsumptionGQLModel;
};

/**
 * Normalizes a Resource Group returned from our GraphQL API
 * into a model ready to be put in the store
 */
export function toResourceGroupInternalModelFromGQL(
  gql: ResourceGroupGQLModel,
): ResourceGroupInternalModel {
  if (gql.__typename === 'LegacyResourceGroup') {
    return <LegacyResourceGroupInternalModel>{
      id: gql.id,
      name: gql.name,
      type: ResourceGroupType.LegacyResourceGroup,
      resourceGroupIds: gql.resourceGroups.map(group => group.id),
      industrialSensorIds: gql.industrialSensors.map(sensor => sensor.id),
      zoneIds: gql.zones.map(zone => zone.id),
      ...(gql.energyConsumption
        ? {
            energyConsumption: toEnergyConsumptionInternalModelFromGQL(
              gql.energyConsumption,
            ),
          }
        : {}),
    };
  } else if (gql.__typename === 'Asset') {
    const type = JSON.parse(JSON.stringify(gql.type));
    return <EquipmentInternalModel>{
      id: gql.id,
      name: gql.name,
      make: gql.make,
      model: gql.model,
      imageUrl: gql.imageUrl,
      type: ResourceGroupType.Asset,
      assetType: type.type ?? type.name ?? '',
      resourceGroupIds: gql.resourceGroups.map(group => group.id),
      industrialSensorIds: gql.industrialSensors.map(sensor => sensor.id),
      runningState: gql.runningState,
      dispositionChanges: gql.dispositionChanges,
      disposition: gql.disposition,
      ...(gql.energyConsumption
        ? {
            energyConsumption: toEnergyConsumptionInternalModelFromGQL(
              gql.energyConsumption,
            ),
          }
        : {}),
    };
  } else if (gql.__typename === 'AssetCollection') {
    return <AssetCollectionInternalModel>{
      id: gql.id,
      name: gql.name,
      type: ResourceGroupType.AssetCollection,
      resourceGroupIds: gql.resourceGroups.map(group => group.id),
      ...(gql.energyConsumption
        ? {
            energyConsumption: toEnergyConsumptionInternalModelFromGQL(
              gql.energyConsumption,
            ),
          }
        : {}),
    };
  } else if (gql.__typename === 'Department') {
    return {
      id: gql.id,
      name: gql.name,
      type: ResourceGroupType.Department,
      resourceGroupIds: gql.resourceGroups.map(group => group.id),
      zoneIds: gql.controlZones.map(controlZone => controlZone.id),
      energyProfileEquipmentIds: gql.energyProfileEquipmentIds,
      ...(gql.energyConsumption
        ? {
            energyConsumption: toEnergyConsumptionInternalModelFromGQL(
              gql.energyConsumption,
            ),
          }
        : {}),
    };
  } else {
    throw new Error(
      'Could not narrow the type of a ResourceGroup instance to a known implementation.',
    );
  }
}

/**
 * ResourceGroup from our Websocket API
 */
export interface ResourceGroupWSModel {
  id: string;
  name: string;
  siteId: string;
  resourceGroupIds: string[];
  industrialSensorIds: string[];
  zoneIds: string[];
  resourceGroupType: ResourceGroupType;
  assetType?: { type: DefinedAssetTypeEnum | string };
  runningState?: AssetRunningStateModelFragment;
  dispositionChanges?: AssetDispositionChangeModelFragment[];
  disposition?: AssetDispositionModelFragment;
}

export function toResourceGroupInternalModelFromWS(
  ws: ResourceGroupWSModel,
): ResourceGroupInternalModel {
  const resourceGroup: any = {
    id: ws.id,
    name: ws.name,
    resourceGroupIds: ws.resourceGroupIds,
    type: ResourceGroupType[ws.resourceGroupType],
  };

  if (ws.zoneIds) {
    resourceGroup.zoneIds = ws.zoneIds;
  }
  if (ws.industrialSensorIds) {
    resourceGroup.industrialSensorIds = ws.industrialSensorIds;
  }

  if (ws.runningState) {
    resourceGroup.runningState = ws.runningState;
  }

  if (ws.dispositionChanges) {
    resourceGroup.dispositionChanges = ws.dispositionChanges;
  }
  if (ws.disposition) {
    resourceGroup.disposition = ws.disposition;
  }

  if (ws.assetType) {
    resourceGroup.assetType = ws.assetType.type;
  }
  return resourceGroup;
}

/**
 * Resource Group ready to be operated on in a form
 */
export interface ResourceGroupFormModel {
  name: string;
  resourceGroupIds: string[];
  industrialSensorIds: string[];
  zoneIds: string[];
}

/**
 * Converts a Resource Group form model into one ready to be used
 * by the GraphQL create operation
 */
export function toCreateResourceGroupInputFromForm(
  form: ResourceGroupFormModel,
  siteId: string,
): CreateResourceGroupInput {
  return {
    siteId,
    name: form.name,
    resourceGroupIds: form.resourceGroupIds,
    industrialSensorIds: form.industrialSensorIds,
    zoneIds: form.zoneIds,
  };
}

/**
 * Converts a Resource Group form model into one ready to be used
 * by the GraphQL update operation
 */
export function toUpdateResourceGroupInputFromForm(
  form: ResourceGroupInternalModel,
): UpdateResourceGroupInput {
  return {
    ...form,
  };
}

export function isAssetCollection(
  resourceGroup: ResourceGroupInternalModel,
): resourceGroup is AssetCollectionInternalModel {
  return resourceGroup.type === ResourceGroupType.AssetCollection;
}

export function isDepartment(
  resourceGroup: ResourceGroupInternalModel,
): resourceGroup is DepartmentInternalModel {
  return resourceGroup.type === ResourceGroupType.Department;
}

export function isEquipment(
  resourceGroup: ResourceGroupInternalModel,
): resourceGroup is EquipmentInternalModel {
  return resourceGroup.type === ResourceGroupType.Asset;
}

export function isLegacyResourceGroup(
  resourceGroup: ResourceGroupInternalModel,
): resourceGroup is LegacyResourceGroupInternalModel {
  return resourceGroup.type === ResourceGroupType.LegacyResourceGroup;
}
