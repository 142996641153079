import { createReducer, createSelector, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import { PowerActions, PowerApiActions } from '@spog-ui/power/actions';
import { PowerSourceComparisonInternalModel } from '@spog-ui/shared/models/power-source-comparisons';

export interface Shape {
  powerSourceComparisons: PowerSourceComparisonInternalModel[];
}

export const initialState: Shape = {
  powerSourceComparisons: [],
};

export const reducer = createReducer(
  initialState,
  on(PowerApiActions.getPowerDataSuccessAction, (state, action) => {
    return {
      ...state,
      powerSourceComparisons: action.powerSourceComparisons,
    };
  }),
  on(PowerActions.removeComparisonAction, (state, action) => {
    return {
      ...state,
      powerSourceComparisons: state.powerSourceComparisons.filter(comparison => {
        return !(
          comparison.powerSource.id === action.comparison.powerSource.id &&
          comparison.powerSource.sourceType === action.comparison.powerSource.sourceType
        );
      }),
    };
  }),
);

export const selectPowerSourceComparisons = (state: Shape) =>
  state.powerSourceComparisons;

export const selectAllPowerValues = createSelector(
  selectPowerSourceComparisons,
  powerSourceComparisons => {
    return powerSourceComparisons.reduce((power: number[], nextPowerSource) => {
      const powerSourcePower = nextPowerSource.powerData.map(
        powerData => powerData.power,
      );

      return power.concat(powerSourcePower);
    }, []);
  },
);

export const selectAllEnergyValues = createSelector(
  selectPowerSourceComparisons,
  powerSourceComparisons => {
    return powerSourceComparisons.reduce((energy: number[], nextPowerSource) => {
      const powerSourceEnergy = nextPowerSource.energyData.map(
        energyData => energyData.energy,
      );

      return energy.concat(powerSourceEnergy);
    }, []);
  },
);

export const selectAllDateValues = createSelector(
  selectPowerSourceComparisons,
  powerSourceComparisons => {
    return powerSourceComparisons.reduce((dates: DateTime[], nextPowerSource) => {
      const powerSourceDates = nextPowerSource.powerData.map(powerData => powerData.date);

      return dates.concat(powerSourceDates);
    }, []);
  },
);
