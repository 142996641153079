import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';
import { DEFAULT_COLOR } from '@sui/color-picker';

@Component({
  selector: 'sui-bf-color-paparazzi',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">All lights in the zone emulate busy flash bulbs.</p>
      <div class="bfForm">
        <div class="bfChunk">
          <sui-speed-control formControlName="behaviorSpeed"></sui-speed-control>
          <clr-color-picker
            [formControl]="form.controls['color']"
            mode="bytes"
            warnIfNotUsingFastColor="true"
          ></clr-color-picker>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      sui-speed-control {
        display: block;
        padding-bottom: 16px;
      }
    `,
  ],
})
export class BFColorPaparazziComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    behaviorSpeed: new UntypedFormControl('Normal'),
    color: new UntypedFormControl(DEFAULT_COLOR),
  });
  get currentColor() {
    return this.form.value.color;
  }

  getBehaviorParameters() {
    return {
      speed: this.form.value.behaviorSpeed,
      bytesRed: this.currentColor[0],
      bytesGreen: this.currentColor[1],
      bytesBlue: this.currentColor[2],
      bytesAmber: this.currentColor[3],
      bytesWhite: this.currentColor[4],
    };
  }

  writeBehaviorParameters(parameters: any) {
    this.form.patchValue({
      color: [
        parameters.bytesRed,
        parameters.bytesGreen,
        parameters.bytesBlue,
        parameters.bytesAmber,
        parameters.bytesWhite,
      ],
      behaviorSpeed: parameters.speed,
    });
  }
}
