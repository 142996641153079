import { DateTime, Interval, IANAZone } from 'luxon';
import { PowerSourceComparisonModelFragment } from '@spog-ui/graphql/types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const astral = require('@sffjunkie/astral');

const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
const DAY_IN_MILLISECONDS = 24 * HOUR_IN_MILLISECONDS;

export interface PowerSourceComparisonInternalModel {
  powerSource: PowerSourceModel;
  powerData: PowerModel[];
  energyData: EnergyModel[];
  totalPower: number;
}

export type PowerSourceComparisonGQLModel = PowerSourceComparisonModelFragment;

export interface PowerSourceModel {
  id: string;
  name: string;
  sourceType: SourceType;
}

export type PowerModel = {
  date: DateTime;
  power: number; // in kW
};

export type EnergyModel = {
  date: DateTime;
  energy: number; // in kWh
};

export function getPowerReading(value: number): number {
  if (value > 999_999_999) {
    return value / 1_000_000_000;
  } else if (value > 999_999) {
    return value / 1_000_000;
  } else if (value > 999) {
    return value / 1_000;
  } else {
    return value;
  }
}

export function getPowerReadingUnits(value: number): string {
  if (value > 999_999_999) {
    return 'GWh';
  } else if (value > 999_999) {
    return 'MWh';
  } else if (value > 999) {
    return 'kWh';
  } else {
    return 'Wh';
  }
}

export type PowerDomainModel = [number, number];
export type EnergyDomainModel = [number, number];
export type DateDomainModel = [DateTime, DateTime];

export enum SourceType {
  ControlZone = 'Control Zone',
  DLHZone = 'Daylight Harvesting Zone',
  AllLights = 'All Lights',
}

export function isZoneSourceType(sourceType: SourceType) {
  return (
    sourceType === SourceType.ControlZone ||
    sourceType === SourceType.DLHZone ||
    sourceType === SourceType.AllLights
  );
}

export enum PowerUnits {
  KilowattHour = 'kWh',
  MegawattHour = 'MWh',
  GigawattHour = 'GWh',
}

/**
 * Power values returned from SimplySnap Cloud are currently always reported
 * in kilowatts or kilowatt hours.
 */
export function toKilowattHours(kilowattHours: number) {
  return kilowattHours;
}

export function toMegawattHours(kilowattHours: number) {
  return kilowattHours / 1000;
}

export function toGigawattHours(kilowattHours: number) {
  return kilowattHours / 1_000_000;
}

export enum TimeInterval {
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
}

export const SPECIAL_CASE_ALL_LIGHTS = 'special_case_all_lights';
export const ALL_LIGHTS = 'All Lights';

export function isAllLights(powerSource: PowerSourceModel) {
  return powerSource.id === SPECIAL_CASE_ALL_LIGHTS;
}

export function intervalUnitToHours(
  intervalUnit: TimeInterval,
  intervalStart: DateTime,
): number {
  switch (intervalUnit) {
    case TimeInterval.Day:
      return 24;
    case TimeInterval.Week:
      return 168;
    case TimeInterval.Month:
      return intervalStart.daysInMonth * 24;
    default:
      return 1;
  }
}

export function wattsToKilowattHours(
  watts: number,
  intervalStart: DateTime,
  intervalUnit: TimeInterval,
): number {
  // NB: this math only works in this specific case bacause the power value
  // we are using is the average of each hourly reading over the interval.
  // This function is, essentially, "reversing" the average.
  const kilowatts = watts / 1000;

  const hoursInInterval = intervalUnitToHours(intervalUnit, intervalStart);

  const kilowattHours = kilowatts * hoursInInterval;

  return kilowattHours;
}

export function computeFlatBaseline(
  baseline: number,
  dateInterval: TimeInterval,
  timestamps: DateTime[],
): EnergyModel[] {
  return timestamps.map(timestamp => {
    return {
      date: timestamp,
      energy: baseline * intervalUnitToHours(dateInterval, timestamp),
    };
  });
}

function computeVariableBaselineForHour(
  baseline: number,
  timestamps: DateTime[],
  siteLocation: any,
): EnergyModel[] {
  /*
    For each timestamp (which is the start of an hour):
      - create an interval including the hour
      - determine the sunrise and sunset time for the day including the timestamp
      - use those to create a 'daylight interval'
      - if the daylight interval fully contains the hour (Interval.engulfs), then 
        assume the lights are off - Baseline: 0
      - if the daylight interval overlaps with the hour (Interval.overlaps), then
        figure out by how many minutes and divide by 60 to get an hour fraction.  
        Multiply by the baseline to get the "amount of baseline" used in that hour
        - Note: the check ordering is important, since an interval that is 'engulfed' 
          also 'overlaps'
      - if hte daylight interval has no overlap with hour, use the full baseline value
  */
  return timestamps.map(timestamp => {
    const timeStampInterval = Interval.fromDateTimes(timestamp, timestamp.endOf('hour'));
    const sunriseTime: DateTime = astral.sun.sunrise(siteLocation.observer, timestamp);
    const sunsetTime: DateTime = astral.sun.sunset(siteLocation.observer, timestamp);

    const daylightInterval = Interval.fromDateTimes(sunriseTime, sunsetTime);

    if (daylightInterval.engulfs(timeStampInterval)) {
      // the hour is fully in daylight, so baseline is zero
      return {
        date: timestamp,
        energy: 0,
      };
    } else if (timeStampInterval.overlaps(daylightInterval)) {
      // if the hour overlaps, then we need to prorate the baseline
      // for the amount of the hour not in daylight
      const intervalOfHourInDarkness = timeStampInterval.difference(daylightInterval);

      // Duration will only have milliseconds to start, so we convert
      // to minutes for easier math
      const durationOfInterval = intervalOfHourInDarkness[0].end
        .diff(intervalOfHourInDarkness[0].start)
        .shiftTo('minutes');

      const fractionOfHour = durationOfInterval.minutes / 60;

      const energy = baseline * fractionOfHour;

      return {
        date: timestamp,
        energy,
      };
    } else {
      // if there is no overlap, then this is night time, so we use the full
      // baseline value
      return {
        date: timestamp,
        energy: baseline,
      };
    }
  });
}

function computeVariableBaselineForNonHour(
  baseline: number,
  dateInterval: TimeInterval,
  timestamps: DateTime[],
  siteLocation: any,
): EnergyModel[] {
  /*
    For each timestamp (which is the start of an day/week/month):
      - create an interval including the start/end of the day/week/month
      - derive a list of 'day' timestamps included in the interval (using Interval.splitBy)
      - for each 'day' in the interval:
        - find the start and end of 'daylight' for the day
        - find how much daylight there was by diff-ing the start from the end
        - subtrack that from 24 to get the (fractional) hours of 'darkness' in each day
        - multiply by the baseline to get the amount of baseline used for that day
      - add up all the day's baseline values
      - return an EnergyModel with the original timestamp and the total baseline
  */
  return timestamps.map(timestamp => {
    const intervalStart: DateTime = timestamp;
    const intervalEnd: DateTime = intervalStart.plus({
      hours: intervalUnitToHours(dateInterval, timestamp),
    });

    const days = Interval.fromDateTimes(intervalStart, intervalEnd)
      .splitBy({ days: 1 })
      .map(day => day.start);

    // Astral can compute the hours of darkness, but it starts on the given
    // day and ends on the next, which makes iteration harder.
    // Thus, we get daylight and just subtract from 24 to get the darkness
    const hoursOfDarkness = days.reduce((totalHours, day) => {
      const daylightTimes = astral.sun.daylight(siteLocation.observer, {
        date: day,
        tzinfo: 'utc',
      });
      const daylightDuration = daylightTimes[1].diff(daylightTimes[0]);

      const darknessInMillis = DAY_IN_MILLISECONDS - daylightDuration.valueOf();
      const darknessInHours = darknessInMillis / HOUR_IN_MILLISECONDS;

      return totalHours + darknessInHours;
    }, 0);

    const energy = baseline * hoursOfDarkness;

    return {
      date: timestamp,
      energy,
    };
  });
}

export function computeVariableBaseline(
  baseline: number,
  dateInterval: TimeInterval,
  timestamps: DateTime[],
  siteLatitude: number,
  siteLongitude: number,
  siteTimezone: IANAZone,
): EnergyModel[] {
  // Setup location for astral reference
  // Note: name and region don't matter for custom locations
  const siteLocation = new astral.LocationInfo(
    'name',
    'region',
    siteTimezone,
    siteLatitude,
    siteLongitude,
  );

  // For Hour, we have to consider whether daylight ends or begins in a given hour,
  // so the baseline is calculated diferently
  if (dateInterval === TimeInterval.Hour) {
    return computeVariableBaselineForHour(baseline, timestamps, siteLocation);
  } else {
    return computeVariableBaselineForNonHour(
      baseline,
      dateInterval,
      timestamps,
      siteLocation,
    );
  }
}
