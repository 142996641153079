import { createAction } from '@ngrx/store';
import { ControlZoneFormModel } from '@spog-ui/shared/models/zones';

export const enterClimateZoneFormAction = createAction('[Climate Zone Form] Enter');

export const createClimateZoneAction = createAction(
  '[Climate Zone Form] Create Climate Zone',
  (climateZone: ControlZoneFormModel) => ({ climateZone }),
);

export const updateClimateZoneAction = createAction(
  '[Climate Zone Form] Update Climate Zone',
  (climateZone: ControlZoneFormModel) => ({ climateZone }),
);

export const cancelClimateZoneFormAction = createAction('[Climate Zone Form] Cancel');
