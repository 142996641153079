import { SitesPageActions, SitesPageApiActions } from '@spog-ui/admin/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  pending: boolean;
  error: any;
  pageIndex: number;
  pageSize: number;
  filter: string;

}

export const initialState: Shape = {
  pending: true,
  error: null,
  pageIndex: 0,
  pageSize: 50,
  filter: ''
};

export const reducer = createReducer(
  initialState,
  on(SitesPageApiActions.loadSuccessAction, state => {
    return { ...state, pending: false };
  }),
  on(SitesPageApiActions.loadFailureAction, (state, action) => {
    return { ...state, error: action.error, pending: false };
  }),
  on(SitesPageActions.filterAction, (state, action) => {
    return { ...state, filter: action.term, pageIndex: 0 };
  }),
  on(SitesPageActions.paginateAction, (state, action) => {
    return { ...state, pageIndex: action.pageIndex, pageSize: action.pageSize };
  }),
);

export const selectPending = (state: Shape) => state.pending;
export const selectError = (state: Shape) => state.error;
export const selectPageIndex = (state: Shape) => state.pageIndex;
export const selectPageSize = (state: Shape) => state.pageSize;
export const selectFilter = (state: Shape) => state.filter;

