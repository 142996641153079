import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CurrentUserActions } from '@spog-ui/current-user/auth-actions';
import { LandingPageActions } from '../../actions';

@Component({
  selector: 'spog-landing-page',
  template: `
    <div class="landingPage">
      <div class="login">
        <div class="loginContent">
          <img src="/assets/logo.svg" />
        </div>
        <div class="loginContent">
          <button (click)="goToLogin()" class="loginButton">login</button>
        </div>
      </div>
      <div class="footer">
        <spog-landing-page-footer (showEula)="onShowEula()"></spog-landing-page-footer>
      </div>
    </div>
  `,
  styles: [
    `
      .landingPage {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        position: fixed;
        top: 0;
        width: 100%;
      }

      .landingPage:after {
        content: '';
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 8px;
        display: block;
        background-image: url('/assets/stripe.svg');
        background-size: cover;
      }

      .login {
        flex-direction: column;
        max-width: 50%;
      }

      .loginContent {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
      }

      .loginButton {
        color: var(--color-500);
        background-color: transparent;
        border: 1px solid var(--color-500);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 900;
        padding: 16px 0px;
        text-transform: uppercase;
        width: 160px;
        outline: none;
        transform: scale(1);
        transition: transform 350ms;
      }

      .loginButton:hover {
        transform: scale(1.05);
      }

      .footer {
        align-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        position: fixed;
        min-height: 72px;
        width: 100%;
        padding-bottom: 8px;
      }

      spog-landing-page-footer {
        width: 75%;
      }
    `,
  ],
})
export class LandingPageComponent {
  constructor(private store: Store) {}

  goToLogin() {
    this.store.dispatch(CurrentUserActions.requestLoginAction());
  }

  onShowEula() {
    this.store.dispatch(LandingPageActions.showEulaAction());
  }
}
