import {
  Directive,
  Injectable,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CdkPortal } from '@angular/cdk/portal';

@Injectable({ providedIn: 'root' })
export class MapLayerOutletsService {
  canvasElements$ = new BehaviorSubject(new Set<CdkPortal>());
  svgElements$ = new BehaviorSubject(new Set<CdkPortal>());
  projectedSvgElements$ = new BehaviorSubject(new Set<CdkPortal>());

  private add(list$: BehaviorSubject<Set<CdkPortal>>, portal: CdkPortal) {
    const nextSet = new Set(list$.value);

    nextSet.add(portal);

    list$.next(nextSet);
  }

  private remove(list$: BehaviorSubject<Set<CdkPortal>>, portal: CdkPortal) {
    const nextSet = new Set(list$.value);

    nextSet.delete(portal);

    list$.next(nextSet);
  }

  addCanvasElement(portal: CdkPortal) {
    this.add(this.canvasElements$, portal);
  }

  removeCanvasElement(portal: CdkPortal) {
    this.remove(this.canvasElements$, portal);
  }

  addSvgElement(portal: CdkPortal) {
    this.add(this.svgElements$, portal);
  }

  removeSvgElement(portal: CdkPortal) {
    this.remove(this.svgElements$, portal);
  }

  addProjectedSvgElement(portal: CdkPortal) {
    this.add(this.projectedSvgElements$, portal);
  }

  removeProjectedSvgElement(portal: CdkPortal) {
    this.remove(this.projectedSvgElements$, portal);
  }
}

@Directive({ selector: '[mapCanvas]' })
export class MapCanvasDirective extends CdkPortal implements OnDestroy {
  constructor(
    readonly layers: MapLayerOutletsService,
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
  ) {
    super(templateRef, viewContainerRef);

    this.layers.addCanvasElement(this);
  }

  ngOnDestroy() {
    this.layers.removeCanvasElement(this);
  }
}

@Directive({ selector: '[mapSvg]' })
export class MapSvgDirective extends CdkPortal implements OnDestroy {
  constructor(
    readonly layers: MapLayerOutletsService,
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
  ) {
    super(templateRef, viewContainerRef);

    this.layers.addSvgElement(this);
  }

  ngOnDestroy() {
    this.layers.removeSvgElement(this);
  }
}

@Directive({ selector: '[mapProjectedSvg]' })
export class MapProjectedSvgDirective extends CdkPortal implements OnDestroy {
  constructor(
    readonly layers: MapLayerOutletsService,
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
  ) {
    super(templateRef, viewContainerRef);

    this.layers.addProjectedSvgElement(this);
  }

  ngOnDestroy() {
    this.layers.removeProjectedSvgElement(this);
  }
}
