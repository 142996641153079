import { Component } from '@angular/core';

@Component({
  selector: 'lit-alarm-indicator',
  template: ` <div class="alarm"></div> `,
  styles: [
    `
      .alarm {
        background-color: var(--color-warn-500);
        width: 12px;
        height: 12px;
        border-radius: 12px;
        border: 1px solid #000000;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      }
    `,
  ],
})
export class AlarmIndicatorComponent {}
