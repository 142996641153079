import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiSpinnerModule } from '@spog-ui/shared/components';
import { SequenceSceneFormComponentModule } from '../sequence-scene-form';
import { EditSequenceScenePageComponent } from './edit-sequence-scene-page.component';

@NgModule({
  imports: [CommonModule, SuiSpinnerModule, SequenceSceneFormComponentModule],
  declarations: [EditSequenceScenePageComponent],
  exports: [EditSequenceScenePageComponent],
})
export class EditSequenceScenePageComponentModule {}
