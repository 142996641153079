import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { v4 as uuidv4 } from 'uuid';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SiteControllerViewModel } from '@spog-ui/shared/models/site-controllers';
import { HmacSHA256, enc as Encoder } from 'crypto-js';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({ providedIn: 'root' })
export class RemoteAccessURLService {
  constructor(private auth0: AuthService, readonly sanitizer: DomSanitizer) {}

  createNonce() {
    return uuidv4();
  }

  createSsraToken(secretKey: string, nonce: string) {
    return HmacSHA256(nonce + '???', secretKey).toString(Encoder.Base64);
  }

  buildURL(
    { uiProxyUrl, secretKey }: SiteControllerViewModel,
    { debug = false, legacyDebug = false }: { debug?: boolean, legacyDebug?: boolean } = {},
    routeParam?: string,
  ): Observable<SafeResourceUrl | null> {
    if (uiProxyUrl && secretKey) {
      const nonce = this.createNonce();
      const ssraToken = this.createSsraToken(secretKey, nonce);
      const nonceParam = `nonce=${encodeURIComponent(nonce)}`;
      const tokenParam = `token=${encodeURIComponent(ssraToken)}`;

      return this.auth0.getAccessTokenSilently().pipe(
        map(token => {

          let newUrl = `https://${uiProxyUrl}/${
            debug ? '#/debug' : legacyDebug ? 'debug' : ''
          }?${nonceParam}&${tokenParam}&access_token=${encodeURIComponent(token)}`;

          if (routeParam) {
            newUrl = `${newUrl}${routeParam}`;
          }

          return newUrl;
        }),
        map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url)),
        catchError(() => of(null)),
      );
    } else {
      return of(null);
    }
  }
}
