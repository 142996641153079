import { createReducer, on } from '@ngrx/store';
import { AddBridge485Page, Bridge485Api } from '../actions';

export interface Shape {
  isLoading: boolean;
  isSaving: boolean;
  error?: any;
}

export const STATE_KEY = 'add-bridge-485-page';

export const initialState: Shape = {
  isLoading: true,
  isSaving: false,
};

export const reducer = createReducer(
  initialState,
  on(AddBridge485Page.enterAction, () => initialState),
  on(Bridge485Api.loadAddPageModelsSuccessAction, state => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(Bridge485Api.loadAddPageModelsFailureAction, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
  on(AddBridge485Page.saveAction, state => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(Bridge485Api.createSuccessAction, state => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(Bridge485Api.createFailureAction, (state, action) => {
    return {
      ...state,
      isSaving: false,
      error: action.error,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSaving = (state: Shape) => state.isSaving;
export const selectError = (state: Shape) => state.error;
