import { createAction } from '@ngrx/store';
import { ResourceGroupInternalModel } from '@spog-ui/shared/models/resource-groups';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';

export const loadFormDataSuccessAction = createAction(
  '[Edit Equipment API] Load Form Data Success',
  (
    resourceGroups: ResourceGroupInternalModel[],
    indieSensors: IndieSensorInternalModel[],
  ) => ({
    resourceGroups,
    indieSensors,
  }),
);

export const loadFormDataFailureAction = createAction(
  '[Edut Equipment API] Load Form Data Failure',
  (error: string) => ({ error }),
);

export const editEquipmentSuccess = createAction(
  '[Equipment API] Edit Equipment Success',
);

export const editEquipmentFailure = createAction(
  '[Equipment API] Edit Equipment Failure',
  (error: string) => ({ error }),
);
