import { createAction } from '@ngrx/store';
import {
  ThermostatAlarmInternalModel,
  ThermostatInternalModel,
} from '@spog-ui/shared/models/thermostats';
import { ApiErrorModel } from '@spog-ui/shared/models/errors';

export const loadClimateMapPageModelsSuccessAction = createAction(
  '[Climate Map API] Load Climate Map Page Models Success',
  (models: {
    thermostats: ThermostatInternalModel[];
    thermostatAlarms: ThermostatAlarmInternalModel[];
  }) => {
    return { models };
  },
);

export const loadClimateMapPageModelsFailureAction = createAction(
  '[Climate Map API] Load Climate Map Page Models Failure',
  (error: ApiErrorModel) => ({ error }),
);
