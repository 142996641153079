import { NgModule } from '@angular/core';

import { BFPhotocellAndSwitchComponent } from './bf-photocell-and-switch.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DimmerControlComponent } from '@spog-ui/shared/components';
import { TimeoutControlComponentModule } from '../timeout-control';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DimmerControlComponent,
    TimeoutControlComponentModule,
  ],
  declarations: [BFPhotocellAndSwitchComponent],
  exports: [BFPhotocellAndSwitchComponent],
})
export class BFPhotocellAndSwitchComponentModule {}
