import { createAction } from '@ngrx/store';

export const enterAction = createAction('[Light Zones Page] Enter');

export const searchAction = createAction(
  '[Light Zones Page] Search',
  (searchTerm: string) => {
    return { searchTerm };
  },
);
