import { createSelector } from '@ngrx/store';
import { toBridge485FormInputModelFromInternal } from '@spog-ui/shared/models/bridge-485s';
import { selectBridge485Entities } from '@spog-ui/shared/state/bridge-485s';
import { selectFeature } from './constants';
import {
  selectBridge485Id,
  selectError,
  selectIsLoading,
  selectIsSaving,
  STATE_KEY,
} from './edit-bridge-485-page.reducer';

export const selectEditPageFeatureState = createSelector(
  selectFeature,
  state => state[STATE_KEY],
);

export const selectEditPageError = createSelector(
  selectEditPageFeatureState,
  selectError,
);

export const selectEditPageIsLoading = createSelector(
  selectEditPageFeatureState,
  selectIsLoading,
);

export const selectEditPageIsSaving = createSelector(
  selectEditPageFeatureState,
  selectIsSaving,
);

export const selectEditPageIdToEdit = createSelector(
  selectEditPageFeatureState,
  selectBridge485Id,
);

export const selectEditPageBridge485ToEdit = createSelector(
  selectBridge485Entities,
  selectEditPageIdToEdit,
  (bridge485s, id) => {
    if (!id) return null;

    const bridge485ToEdit = bridge485s[id];

    if (!bridge485ToEdit) return null;

    return toBridge485FormInputModelFromInternal(bridge485ToEdit);
  },
);
