import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';

@Component({
  selector: 'spog-site-selector-page',
  template: `
    @if (loading$ | async) {
      <div class="loading">
        <sui-spinner></sui-spinner>
      </div>
    } @else {
      <h2 class="mat-title">Welcome</h2>
      <spog-no-sites></spog-no-sites>
      <spog-site-selector></spog-site-selector>
    }
    `,
  styles: [
    `
      h2 {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid var(--color-foreground-divider);
      }

      sui-spinner {
        width: 50px;
        display: block;
        margin: 60px auto 0;
      }
    `,
  ],
})
export class SiteSelectorPageComponent {
  constructor(private store: Store) {}

  loading$ = this.store.select(CoreState.selectSitesLoading);
}
