import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { EulaUserActions } from '@spog-ui/current-user/eula-actions';

@Component({
  selector: 'spog-eula-link',
  template: `
    <a id="eulaLink" routerLink="/eula" routerLinkActive="active" (click)="onEulaOpen()">
      Eula
    </a>
  `,
  styles: [
    `
      a {
        color: var(--color-500);
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
      }
    `,
  ],
})
export class EulaLinkComponent {
  constructor(private store: Store) {}

  onEulaOpen() {
    this.store.dispatch(EulaUserActions.showEulaAction(false));
  }
}
