import { createAction, union } from '@ngrx/store';
import { ScheduledEventFormModel } from '@spog-ui/shared/models/scheduled-events';

export const enterAction = createAction(
  '[Edit Scheduled Event Page] Enter',
  (scheduledEventId: string) => {
    return { scheduledEventId };
  },
);

export const leaveAction = createAction('[Edit Scheduled Event Page] Leave');

export const editScheduledEventAction = createAction(
  '[Edit Scheduled Event Page] Save Edit Scheduled Event',
  (id: string, scheduledEvent: ScheduledEventFormModel) => {
    return { id, scheduledEvent };
  },
);

export const cancelEditScheduledEventAction = createAction(
  '[Edit Scheduled Event Page] Cancel Edit Scheduled Event',
);

export const confirmCancelEditScheduledEventAction = createAction(
  '[Edit Scheduled Event Page] Confirm Cancel Edit Scheduled Event',
);

export const dismissCancelEditScheduledEventAction = createAction(
  '[Edit Scheduled Event Page] Dismiss Cancel Edit Scheduled Event',
);

const all = union({
  enterAction,
  leaveAction,
  editScheduledEventAction,
  cancelEditScheduledEventAction,
  confirmCancelEditScheduledEventAction,
  dismissCancelEditScheduledEventAction,
});
export type Union = typeof all;
