import { NgModule } from '@angular/core';
import { DimmerControlComponent } from '@spog-ui/shared/components';
import { BFPhotocellAndOccupancyComponent } from './bf-photocell-and-occupancy.component';
import { TimeoutControlComponentModule } from '../timeout-control';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [ReactiveFormsModule, TimeoutControlComponentModule, DimmerControlComponent],
  declarations: [BFPhotocellAndOccupancyComponent],
  exports: [BFPhotocellAndOccupancyComponent],
})
export class BFPhotocellAndOccupancyComponentModule {}
