import { LightSuiModel as LightModel } from '@spog-ui/shared/models/lights';
import { createAction } from '@ngrx/store';

export const pingLightFailureAction = createAction(
  '[Light API] Ping Light Failure',
  (light: LightModel, error?: any) => {
    return { light, error };
  },
);
export const pingLightSuccessAction = createAction('[Light API] Ping Light Success');
export const checkSignalStrengthStartsAction = createAction(
  '[Light API] Check Signal Strength Starts',
);
export const checkSignalStrengthCompletesAction = createAction(
  '[Light API] Check Signal Strength Completes',
);
export const checkSignalStrengthFailureAction = createAction(
  '[Light API] Check Signal Strength Failure',
  (light: LightModel, error?: any) => {
    return { light, error };
  },
);
export const replaceSuccessAction = createAction('[Light Details API] Replace Success');
export const replaceFailureAction = createAction(
  '[Light Details API] Replace Failure',
  (error: string) => {
    return { error };
  },
);
