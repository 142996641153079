import { Injectable } from '@angular/core';
import { GraphQLAPIService, UserQueryInput } from '@spog-ui/graphql/types';
import {
  CreateUserFormModel,
  toUserCreateInput,
  toUserInternalModelFromGQL,
  toUserUpdateInput,
  UserFormModel,
  UserInternalModel,
} from '@spog-ui/shared/models/users';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserApiService {
  constructor(readonly gql: GraphQLAPIService) {}

  getAll(): Observable<UserInternalModel[]> {
    return this.gql
      .getAllUsersAsAdmin()
      .pipe(map(response => response.allUsers.map(toUserInternalModelFromGQL)));
  }

  getUser(input: UserQueryInput): Observable<UserInternalModel | null> {
    return this.gql
      .getUserAsAdmin({ input: input })
      .pipe(
        map(response =>
          response.user ? toUserInternalModelFromGQL(response.user) : null,
        ),
      );
  }

  getUserSites(id: string): Observable<string[]> {
    return this.gql.getUserSites({ id: id }).pipe(
      map(response => {
        const userSites = response.user?.sites.edges.map(edges => edges.site.name);
        return userSites ? userSites.sort() : [];
      }),
    );
  }

  create(form: CreateUserFormModel): Observable<string> {
    return this.gql
      .createUserAsAdmin({ input: toUserCreateInput(form) })
      .pipe(map(response => response.createUser.id));
  }

  update(form: UserFormModel): Observable<string> {
    return this.gql
      .updateUserAsAdmin({ input: toUserUpdateInput(form) })
      .pipe(map(response => response.updateUser.id));
  }

  delete(id: string): Observable<string | undefined | null> {
    return this.gql
      .deleteUserAsAdmin({ input: { id } })
      .pipe(map(response => response.deleteUser));
  }

  resetPassword(id: string): Observable<string | undefined | null> {
    return this.gql
      .triggerResetPasswordAsAdmin({ input: { id } })
      .pipe(map(response => response.triggerPasswordReset));
  }
}
