/**
 * A child can hook into the rendering loop by providing a function that takes
 * a context and does some work with that context.
 *
 * The map is responsible for instantiating a canvas and passing a corresponding
 * rendering context to the render functions.
 */
export type MapRenderFn = (ctx: CanvasRenderingContext2D) => void;

export interface ChildRenderer {
  markForRender(): void;
  teardown(): void;
}

export interface ChildRendererOptions {
  order: number;
  shouldRender: () => boolean;
}

export class RootRenderer {
  createChildRenderer(
    render: MapRenderFn,
    options?: Partial<ChildRendererOptions>,
  ): ChildRenderer {
    throw new Error('Not implemented');
  }
}

export interface IRenderingLoop {
  start(tick: () => void): () => void;
}
