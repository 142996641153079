import { DateTime } from 'luxon';
import { LocationSettings } from '@spog-ui/shared/models/location';
import { AstroTime } from './parser.model';

const SUN_RISING = 1;
const SUN_SETTING = -1;

class AstralError extends Error {}

/**
 * Find the same number of days between two dates as Excel does
 *
 * The 2 is a magic number.
 */
function excelDateDiff(startDate: DateTime, endDate: DateTime) {
  return endDate.ordinal - startDate.ordinal + 2;
}

const enum SolarDepressionTypes {
  Civil = 'civil',
  Nautical = 'nautical',
  Astronomical = 'astronomical',
}

export class Astral {
  _depression = 6;

  get solarDepression() {
    //         """The number of degrees the sun must be below the horizon for the
    //         dawn/dusk calculation.

    //         Can either be set as a number of degrees below the horizon or as
    //         one of the following strings

    //         ============= =======
    //         String        Degrees
    //         ============= =======
    //         civil            6.0
    //         nautical        12.0
    //         astronomical    18.0
    //         ============= =======
    return this._depression;
  }

  set solarDepression(solarDepression: number | string) {
    if (typeof solarDepression === 'string') {
      switch (solarDepression) {
        case SolarDepressionTypes.Civil:
          this._depression = 6;
          break;
        case SolarDepressionTypes.Nautical:
          this._depression = 12;
          break;
        case SolarDepressionTypes.Astronomical:
          this._depression = 18;
          break;
        default:
          throw new Error(
            `Solar Depression '${solarDepression}' must either be a number or one of 'civil', 'nautical' or 'astronomical'`,
          );
      }
    } else {
      this._depression = solarDepression;
    }
  }

  sunUTC(
    localDate: DateTime,
    latitude: number,
    longitude: number,
  ): {
    dawn: DateTime;
    sunrise: DateTime;
    noon: DateTime;
    sunset: DateTime;
    dusk: DateTime;
  } {
    //     def sun_utc(self, date, latitude, longitude):
    //         """Calculate all the info for the sun at once.
    //         All times are returned in the UTC timezone.

    //         :param date:       Date to calculate for.
    //         :type date:        :class:`datetime.date`
    //         :param latitude:   Latitude - Northern latitudes should be positive
    //         :type latitude:    float
    //         :param longitude:  Longitude - Eastern longitudes should be positive
    //         :type longitude:   float

    //         :returns: Dictionary with keys ``dawn``, ``sunrise``, ``noon``,
    //             ``sunset`` and ``dusk`` whose values are the results of the
    //             corresponding `_utc` methods.
    //         :rtype: dict
    //         """

    const dawn = this.dawn(localDate, latitude, longitude);
    const sunrise = this.sunrise(localDate, latitude, longitude);
    const noon = this.noon(localDate, longitude);
    const sunset = this.sunset(localDate, latitude, longitude);
    const dusk = this.dusk(localDate, latitude, longitude);

    return {
      dawn,
      sunrise,
      noon,
      sunset,
      dusk,
    };
  }

  dawn(date: DateTime, latitude: number, longitude: number, depression = 0) {
    //         """Calculate dawn time in the UTC timezone.

    //         :param date:       Date to calculate for.
    //         :type date:        :class:`datetime.date`
    //         :param latitude:   Latitude - Northern latitudes should be positive
    //         :type latitude:    float
    //         :param longitude:  Longitude - Eastern longitudes should be positive
    //         :type longitude:   float
    //         :param depression: Override the depression used
    //         :type depression:  float

    //         :return: The UTC date and time at which dawn occurs.
    //         :rtype: :class:`~datetime.datetime`
    //         """

    if (depression === 0) {
      depression = this._depression;
    }
    depression += 90;

    try {
      return this.calcTime(depression, SUN_RISING, date, latitude, longitude);
    } catch (exc: any) {
      if (exc.name == 'math domain error') {
        throw new AstralError(
          'Sun never reaches the horizon on this day, at this location.',
        );
      } else {
        throw exc;
      }
    }
  }

  sunrise(date: DateTime, latitude: number, longitude: number) {
    //     def sunrise_utc(self, date, latitude, longitude):
    //         """Calculate sunrise time in the UTC timezone.

    //         :param date:       Date to calculate for.
    //         :type date:        :class:`datetime.date`
    //         :param latitude:   Latitude - Northern latitudes should be positive
    //         :type latitude:    float
    //         :param longitude:  Longitude - Eastern longitudes should be positive
    //         :type longitude:   float

    //         :return: The UTC date and time at which sunrise occurs.
    //         :rtype: :class:`~datetime.datetime`
    //         """

    try {
      return this.calcTime(90 + 0.833, SUN_RISING, date, latitude, longitude);
    } catch (exc: any) {
      if (exc.name == 'math domain error') {
        throw new AstralError(
          'Sun never reaches the horizon on this day, at this location.',
        );
      } else {
        throw exc;
      }
    }
  }

  noon(date: DateTime, longitude: number) {
    //     def solar_noon_utc(self, date, longitude):
    //         """Calculate solar noon time in the UTC timezone.

    //         :param date:       Date to calculate for.
    //         :type date:        :class:`datetime.date`
    //         :param longitude:  Longitude - Eastern longitudes should be positive
    //         :type longitude:   float

    //         :return: The UTC date and time at which noon occurs.
    //         :rtype: :class:`~datetime.datetime`
    //         """

    const jc = this.jdayToJcentury(this.julianDay(date));
    const eqtime = this.eqOfTime(jc);
    const timeUTC = (720.0 - 4 * longitude - eqtime) / 60.0;

    let hour = Math.round(timeUTC);
    let minute = (timeUTC - hour) * 60;
    let second = ((timeUTC - hour) * 60 - minute) * 60;

    if (second > 59) {
      second -= 60;
      minute += 1;
    } else if (second < 0) {
      second += 60;
      minute -= 1;
    }

    if (minute > 59) {
      minute -= 60;
      hour += 1;
    } else if (minute < 0) {
      minute += 60;
      hour -= 1;
    }
    if (hour > 23) {
      hour -= 24;
      date = date.plus({ days: 1 });
    } else if (hour < 0) {
      hour += 24;
      date = date.minus({ days: 1 });
    }

    const noon = DateTime.fromObject(
      {
        year: date.year,
        month: date.month,
        day: date.day,
        hour,
        minute,
        second,
        millisecond: 0,
      },
      {
        zone: 'utc',
      },
    );

    return noon;
  }

  sunset(date: DateTime, latitude: number, longitude: number) {
    // """Calculate sunset time in the UTC timezone.

    // :param date:       Date to calculate for.
    // :type date:        :class:`datetime.date`
    // :param latitude:   Latitude - Northern latitudes should be positive
    // :type latitude:    float
    // :param longitude:  Longitude - Eastern longitudes should be positive
    // :type longitude:   float

    // :return: The UTC date and time at which sunset occurs.
    // :rtype: :class:`~datetime.datetime`
    // """

    try {
      return this.calcTime(90 + 0.833, SUN_SETTING, date, latitude, longitude);
    } catch (exc: any) {
      if (exc.name == 'math domain error') {
        throw new AstralError(
          'Sun never reaches the horizon on this day, at this location.',
        );
      } else {
        throw exc;
      }
    }
  }

  dusk(date: DateTime, latitude: number, longitude: number, depression = 0) {
    // """Calculate dusk time in the UTC timezone.

    // :param date:       Date to calculate for.
    // :type date:        :class:`datetime.date`
    // :param latitude:   Latitude - Northern latitudes should be positive
    // :type latitude:    float
    // :param longitude:  Longitude - Eastern longitudes should be positive
    // :type longitude:   float
    // :param depression: Override the depression used
    // :type depression:   float

    // :return: The UTC date and time at which dusk occurs.
    // :rtype: :class:`~datetime.datetime`
    // """

    if (depression === 0) {
      depression = this._depression;
    }
    depression += 90;

    try {
      return this.calcTime(depression, SUN_SETTING, date, latitude, longitude);
    } catch (exc: any) {
      if (exc.args[0] == 'math domain error') {
        throw new AstralError(
          `Sun never reaches ${
            depression - 90
          } degrees below the horizon, at this location.`,
        );
      } else {
        throw exc;
      }
    }
  }

  julianDay(utcDateTime: DateTime) {
    let hour = 0;
    let minute = 0;
    let second = 0;
    const hourOffset = 0;

    // if isinstance(utcdatetime, datetime.datetime) {
    const endDate: DateTime = utcDateTime;
    hour = utcDateTime.hour;
    minute = utcDateTime.minute;
    second = utcDateTime.second;

    // startDate = datetime.date(1900, 1, 1)
    const startDate: DateTime = DateTime.fromObject(
      {
        year: 1900,
        month: 1,
        day: 1,
        second: 0,
        millisecond: 0,
      },
      {
        zone: 'utc',
      },
    );
    const timeFraction = (hour * 3600.0 + minute * 60.0 + second) / (24.0 * 3600.0);
    const dateDiff = excelDateDiff(startDate, endDate);
    const jd = dateDiff + 2415018.5 + timeFraction - hourOffset / 24;

    return jd;
  }

  jdayToJcentury(julianday: number) {
    return (julianday - 2451545.0) / 36525.0;
  }

  jcenturyToJday(juliancentury: number) {
    return juliancentury * 36525.0 + 2451545.0;
  }

  geomMeanLongSun(juliancentury: number) {
    const l0 = 280.46646 + juliancentury * (36000.76983 + 0.0003032 * juliancentury);
    return l0 % 360.0;
  }

  geomMeanAnomalySun(juliancentury: number) {
    return 357.52911 + juliancentury * (35999.05029 - 0.0001537 * juliancentury);
  }

  eccentrilocationEarthOrbit(juliancentury: number) {
    return 0.016708634 - juliancentury * (0.000042037 + 0.0000001267 * juliancentury);
  }

  sunEqOfCenter(juliancentury: number) {
    const m = this.geomMeanAnomalySun(juliancentury);

    const mrad = this.radians(m);
    const sinm = Math.sin(mrad);
    const sin2m = Math.sin(mrad + mrad);
    const sin3m = Math.sin(mrad + mrad + mrad);

    const c =
      sinm * (1.914602 - juliancentury * (0.004817 + 0.000014 * juliancentury)) +
      sin2m * (0.019993 - 0.000101 * juliancentury) +
      sin3m * 0.000289;

    return c;
  }

  sunTrueLong(juliancentury: number) {
    const l0 = this.geomMeanLongSun(juliancentury);
    const c = this.sunEqOfCenter(juliancentury);

    return l0 + c;
  }

  sunTrueAnomoly(juliancentury: number) {
    const m = this.geomMeanAnomalySun(juliancentury);
    const c = this.sunEqOfCenter(juliancentury);

    return m + c;
  }

  sunApparentLong(juliancentury: number) {
    const true_long = this.sunTrueLong(juliancentury);

    const omega = 125.04 - 1934.136 * juliancentury;
    return true_long - 0.00569 - 0.00478 * Math.sin(this.radians(omega));
  }

  meanObliquityOfEcliptic(juliancentury: number) {
    const seconds =
      21.448 -
      juliancentury * (46.815 + juliancentury * (0.00059 - juliancentury * 0.001813));
    return 23.0 + (26.0 + seconds / 60.0) / 60.0;
  }

  obliquityCorrection(juliancentury: number) {
    const e0 = this.meanObliquityOfEcliptic(juliancentury);

    const omega = 125.04 - 1934.136 * juliancentury;
    return e0 + 0.00256 * Math.cos(this.radians(omega));
  }

  sunDeclination(juliancentury: number) {
    const e = this.obliquityCorrection(juliancentury);
    const lambd = this.sunApparentLong(juliancentury);

    const sint = Math.sin(this.radians(e)) * Math.sin(this.radians(lambd));
    return this.degrees(Math.asin(sint));
  }

  varY(juliancentury: number) {
    const epsilon = this.obliquityCorrection(juliancentury);
    const y = Math.tan(this.radians(epsilon) / 2.0);
    return y * y;
  }

  eqOfTime(juliancentury: number) {
    const l0 = this.geomMeanLongSun(juliancentury);
    const e = this.eccentrilocationEarthOrbit(juliancentury);
    const m = this.geomMeanAnomalySun(juliancentury);

    const y = this.varY(juliancentury);

    const sin2l0 = Math.sin(2.0 * this.radians(l0));
    const sinm = Math.sin(this.radians(m));
    const cos2l0 = Math.cos(2.0 * this.radians(l0));
    const sin4l0 = Math.sin(4.0 * this.radians(l0));
    const sin2m = Math.sin(2.0 * this.radians(m));

    const Etime =
      y * sin2l0 -
      2.0 * e * sinm +
      4.0 * e * y * sinm * cos2l0 -
      0.5 * y * y * sin4l0 -
      1.25 * e * e * sin2m;

    return this.degrees(Etime) * 4.0;
  }

  hourAngle(latitude: number, declination: number, depression: number) {
    const latitude_rad = this.radians(latitude);
    const declination_rad = this.radians(declination);
    const depression_rad = this.radians(depression);

    const n = Math.cos(depression_rad);
    const d = Math.cos(latitude_rad) * Math.cos(declination_rad);
    const t = Math.tan(latitude_rad) * Math.tan(declination_rad);
    const h = n / d - t;

    const HA = Math.acos(h);
    return HA;
  }

  calcTime(
    depression: number,
    direction: any,
    date: DateTime,
    latitude: number,
    longitude: number,
  ) {
    if (typeof latitude !== 'number' || typeof longitude !== 'number') {
      throw new Error(
        `Latitude '${latitude}' and longitude '${longitude}' must be a numbers.`,
      );
    }

    const julianday = this.julianDay(date);
    if (latitude > 89.8) {
      latitude = 89.8;
    }
    if (latitude < -89.8) {
      latitude = -89.8;
    }

    const t = this.jdayToJcentury(julianday);
    const eqtime = this.eqOfTime(t);
    const solarDec = this.sunDeclination(t);

    let hourangle = this.hourAngle(latitude, solarDec, depression);
    if (direction === SUN_SETTING) {
      hourangle = -hourangle;
    }

    const delta = -longitude - this.degrees(hourangle);
    const timeDiff = 4.0 * delta;
    let timeUTC = 720.0 + timeDiff - eqtime;

    timeUTC = timeUTC / 60.0;
    let hour = pythonicInt(timeUTC);
    let minute = pythonicInt((timeUTC - hour) * 60);
    let second = pythonicInt(((timeUTC - hour) * 60 - minute) * 60);

    if (second > 59) {
      second -= 60;
      minute += 1;
    } else if (second < 0) {
      second += 60;
      minute -= 1;
    }

    if (minute > 59) {
      minute -= 60;
      hour += 1;
    } else if (minute < 0) {
      minute += 60;
      hour -= 1;
    }

    if (hour > 23) {
      hour -= 24;
      date = date.plus({ days: 1 });
    } else if (hour < 0) {
      hour += 24;
      date = date.minus({ days: 1 });
    }

    return DateTime.fromObject(
      {
        year: date.year,
        month: date.month,
        day: date.day,
        hour,
        minute,
        second,
        millisecond: 0,
      },
      {
        zone: 'utc',
      },
    );
  }

  radians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }

  degrees(radians: number) {
    return (radians * 180) / Math.PI;
  }
}

const astral = new Astral();
export function getSolarEvents(
  localDate: DateTime,
  type: AstroTime,
  settings: LocationSettings,
) {
  return astral[type](localDate, settings.latitude, settings.longitude);
}

/**
 * Python's `int()` function rounds toward zero, not
 * to the floor of the value.
 */
function pythonicInt(value: number) {
  if (value >= 0) {
    return Math.floor(value);
  }

  return Math.ceil(value);
}
