import { createAction } from '@ngrx/store';
import { AreaModel } from '@spog-ui/shared/models/map-positioners';
import { EditedPositionModel, MapEditorResult } from '@spog-ui/shared/models/map-editor';

export const enterAction = createAction('[Map Editor Page] Enter');
export const pickPanAndZoomToolAction = createAction(
  '[Map Editor Page] Pick Pan & Zoom Tool',
);
export const pickSelectToolAction = createAction('[Map Editor Page] Pick Select Tool');
export const pickScaleToolAction = createAction('[Map Editor Page] Pick Scale Tool');
export const selectAction = createAction(
  '[Map Editor Page] Select',
  (area: AreaModel) => ({ area }),
);
export const scaleAction = createAction('[Map Editor Page] Scale', (area: AreaModel) => ({
  area,
}));
export const alignVerticallyAction = createAction('[Map Editor Page] Align Vertically');
export const alignHorizontallyAction = createAction(
  '[Map Editor Page] Align Horizontally',
);
export const distributeVerticallyAction = createAction(
  '[Map Editor Page] Distribute Vertically',
);
export const distributeHorizontallyAction = createAction(
  '[Map Editor Page] Distribute Horizontally',
);
export const saveAction = createAction(
  '[Map Editor Page] Save',
  (result: MapEditorResult) => ({ result }),
);
