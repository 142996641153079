import { Observable, Subscription } from 'rxjs';
import { Directive, OnDestroy, OnInit, Provider, Type } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive()
export class UniqueNameBaseDirective implements Validator, OnInit, OnDestroy {
  private latestNames: string[] = [];
  protected excludedNames: string[] = [];
  private subscription: Subscription;
  private onValidatorChange: () => void;

  constructor(private names$: Observable<string[]>) {}

  ngOnInit(): void {
    this.names$.subscribe(latestNames => {
      this.latestNames = latestNames;

      if (this.onValidatorChange) {
        this.onValidatorChange();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const value = c.value;

    /**
     * @note Jake Harris
     * This returns an error only if BOTH of these conditions are met:
     *   latestNames contains the value
     *   excludedNames does not contain the value
     */
    return this.latestNames.indexOf(value) === -1 ||
      this.excludedNames.indexOf(value) !== -1
      ? null
      : { suiUniqueName: value };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }
}

export function provideValidator<T>(type: Type<T>): Provider[] {
  return [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: type,
    },
  ];
}
