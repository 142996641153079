import { FloorPlanModel } from '@spog-ui/shared/models/floor-plans';
import { createAction } from '@ngrx/store';
import { SuiAlarmModel } from '@shared/alarms';
import { LightMapLayerIconSize } from '@spog-shared/graphql-enums';

export const loadEssentialModelsSuccessAction = createAction(
  '[Map API] Load Essential Models Success',
  (models: {
    alarms: SuiAlarmModel[];
    floorPlans: FloorPlanModel[];
    lightMapLayerIconSize: LightMapLayerIconSize;
  }): {
    models: {
      alarms: SuiAlarmModel[];
      floorPlans: FloorPlanModel[];
      lightMapLayerIconSize: LightMapLayerIconSize;
    };
  } => {
    return { models };
  },
);
export const loadEssentialModelsFailureAction = createAction(
  '[Map API] Load Essential Models Failure',
  (error: any) => {
    return { error };
  },
);
export const loadEssentialModelsNetworkFailureAction = createAction(
  '[Map API] Load Essential Models Network Failure',
  (error: any) => {
    return { error };
  },
);
