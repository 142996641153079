import { createReducer, on } from '@ngrx/store';
import { AddPermissionGroupPageActions, PermissionGroupApiActions } from '../actions';
export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  error?: any;
}

export const STATE_KEY = 'add-permission-group-page';

export const initialState: Shape = {
  isLoading: true,
  isSubmitting: false,
};

export const reducer = createReducer(
  initialState,
  on(AddPermissionGroupPageActions.enterAction, (): Shape => {
    return initialState;
  }),
  on(AddPermissionGroupPageActions.loadPageSuccessAction, (state): Shape => {
    return { ...state, isLoading: false };
  }),
  on(AddPermissionGroupPageActions.loadPageFailureAction, (state, action): Shape => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
  on(AddPermissionGroupPageActions.addPermissionGroupAction, (state): Shape => {
    return {
      ...state,
      isSubmitting: true,
    };
  }),
  on(PermissionGroupApiActions.createPermissionGroupFailureAction, (state): Shape => {
    return { ...state, isSubmitting: false };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
