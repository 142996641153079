import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { MatCardModule } from '@angular/material/card';
import { SuiDialogModule } from '@spog-ui/shared/components';
import {
  EulaEffects,
  EulaFormComponentModule,
  EulaService,
  EulaModalComponent,
} from './lib';

@NgModule({
  declarations: [EulaModalComponent],
  imports: [
    CommonModule,
    EffectsModule.forFeature([EulaEffects]),
    MatCardModule,
    SuiDialogModule,
    EulaFormComponentModule,
  ],
  providers: [EulaService],
  exports: [EulaModalComponent],
})
export class EulaModule {}
