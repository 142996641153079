import { createAction } from '@ngrx/store';
import { MapPositionerResult } from '@spog-ui/shared/models/map-positioners';

export const enterAction = createAction('[Map Positioner / Step Three] Enter');

export const selectPositionAction = createAction(
  '[Map Positioner / Step Three] Select Position',
  (positionId: string | null) => ({ positionId }),
);
export const assignThingToPositionAction = createAction(
  '[Map Positioner / Step Three] Assign Thing to Position',
  (positionId: string, thingId: string) => ({ positionId, thingId }),
);
export const closeAssignerAction = createAction(
  '[Map Positioner / Step Three] Close Assigner',
);
export const filterThingsAction = createAction(
  '[Map Positioner / Step Three] Filter Things',
  (searchTerm: string) => ({ searchTerm }),
);
export const savePositionsAction = createAction(
  '[Map Positioner / Step Three] Save Positions',
  (result: MapPositionerResult) => ({ result }),
);
