import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { IndieSensorsApi } from '@spog-ui/indie-sensors/actions';
import { createReducer, on } from '@ngrx/store';
import { ControllerInternalModel } from '@spog-ui/shared/models/controllers';
import { LightMapLayerApiActions } from '@spog-ui/light-map-layer/actions';

export type Shape = EntityState<ControllerInternalModel>;

export const adapter = createEntityAdapter<ControllerInternalModel>();

export const initialState = adapter.getInitialState();

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const reducer = createReducer(
  initialState,
  on(
    LightMapLayerApiActions.loadLightMapLayerDeferredModelsSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.controllers, state);
    },
  ),
  on(IndieSensorsApi.loadIndieSensorsAndControllersSuccessAction, (state, action) => {
    return adapter.setAll(action.controllers, state);
  }),
);
