import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  ButtonComponent,
  SuiFormsModule,
  SuiCardModule,
} from '@spog-ui/shared/components';
import { SequenceSceneFormComponent } from './sequence-scene-form.component';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { ValidateSequenceSceneNameDirective } from './validate-sequence-scene-name.directive';
import { SequenceSceneStepListComponentModule } from '../sequence-scene-step-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSelectModule,
    SuiFormsModule,
    SuiCardModule,
    ButtonComponent,
    SequenceSceneStepListComponentModule,
  ],
  declarations: [SequenceSceneFormComponent, ValidateSequenceSceneNameDirective],
  exports: [SequenceSceneFormComponent],
})
export class SequenceSceneFormComponentModule {}
