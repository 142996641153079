import { map, startWith } from 'rxjs/operators';

import { Subscription } from 'rxjs';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  ViewContainerRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from '@angular/forms';
import {
  BehaviorForm,
  BehaviorType,
  parseParameters,
  serializeParameters,
} from '@spog-ui/shared/models/behaviors';
import { getFormForBehavior } from '../get-behavior-form.util';

@Component({
  selector: 'sui-behavior-form',
  template: ``,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BehaviorFormComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: BehaviorFormComponent,
    },
  ],
})
export class BehaviorFormComponent implements ControlValueAccessor, Validator {
  componentRef: ComponentRef<BehaviorForm> | null = null;
  valueSubscription: Subscription | null = null;
  validitySubscription: Subscription | null = null;
  onChangeFn: any;
  onTouchedFn: any;
  onValidatorChangeFn: any;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  @Input()
  set behaviorId(id: BehaviorType) {
    this.compileComponent(id);
  }

  get controlOrGroup(): UntypedFormControl | UntypedFormGroup | null {
    if (this.componentRef) {
      return this.componentRef.instance.form;
    }

    return null;
  }

  compileComponent(id: BehaviorType) {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    const component = getFormForBehavior(id);
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);

    // We are waiting for the next tick
    setTimeout(() => {
      this.registerChanges();
      this.registerValidityChanges();
    });
  }

  registerChanges() {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
      this.valueSubscription = null;
    }

    if (this.controlOrGroup) {
      this.valueSubscription = this.controlOrGroup.valueChanges
        .pipe(
          startWith(this.controlOrGroup.value),
          map(value => {
            const componentRef = this.componentRef as any;
            if (typeof componentRef.instance.getBehaviorParameters === 'function') {
              return componentRef.instance.getBehaviorParameters();
            }
            return value;
          }),
          map(serializeParameters),
        )
        .subscribe(
          value => {
            if (this.onChangeFn) {
              this.onChangeFn(value);
            }
          },
          error => console.error(error),
        );
    }
  }

  registerValidityChanges(): void {
    if (this.validitySubscription) {
      this.validitySubscription.unsubscribe();
      this.validitySubscription = null;
    }

    const controlOrGroup = this.controlOrGroup;
    if (!controlOrGroup) return;
    this.validitySubscription = controlOrGroup.statusChanges
      .pipe(
        startWith(controlOrGroup.valid),
        map(() => controlOrGroup.valid),
      )
      .subscribe(() => {
        if (this.onValidatorChangeFn) {
          this.onValidatorChangeFn();
        }
      });
  }

  writeValue(value: string | null): void {
    const parsedValue: any = parseParameters(value);
    const componentRef = this.componentRef;

    if (!componentRef) throw new Error('componentRef is not defined');

    if (typeof componentRef.instance.writeBehaviorParameters === 'function') {
      (componentRef as any).instance.writeBehaviorParameters(parsedValue);
    } else if (this.controlOrGroup && this.controlOrGroup instanceof UntypedFormControl) {
      this.controlOrGroup.patchValue(parsedValue, {
        onlySelf: true,
        emitEvent: false,
      });
    } else if (this.controlOrGroup && this.controlOrGroup instanceof UntypedFormGroup) {
      this.controlOrGroup.patchValue(parsedValue, {
        onlySelf: true,
        emitEvent: false,
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.controlOrGroup) {
      isDisabled ? this.controlOrGroup.disable() : this.controlOrGroup.enable();
    }
  }

  validate(c: AbstractControl): { [key: string]: any } | null {
    if (this.controlOrGroup) {
      return this.controlOrGroup.valid
        ? null
        : {
            suiBehaviorForm: true,
          };
    }

    return null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChangeFn = fn;
  }
}
