import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ScenesApiActions } from '@spog-ui/scenes/actions';
import { ActiveSequenceSceneInternalModel } from '@spog-ui/shared/models/active-sequence-scenes';
import { SocketActions } from '@spog-ui/socket/actions';

export type Shape = EntityState<ActiveSequenceSceneInternalModel>;

export const adapter = createEntityAdapter({
  sortComparer: sortById,
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(ScenesApiActions.loadSuccessAction, (state, action) => {
    return adapter.setAll(action.models.activeSequenceScenes, state);
  }),
  on(SocketActions.updateActiveSequenceSceneAction, (state, action) => {
    return adapter.upsertOne(action.activeSequenceScene, state);
  }),
  on(SocketActions.deleteActiveSequenceSceneAction, (state, action) => {
    return adapter.removeOne(action.activeSequenceSceneId, state);
  }),
);

export function sortById(
  a: ActiveSequenceSceneInternalModel,
  b: ActiveSequenceSceneInternalModel,
) {
  return a.id.localeCompare(b.id);
}

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
