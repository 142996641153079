import { createAction } from '@ngrx/store';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';
import { ResourceGroupInternalModel } from '@spog-ui/shared/models/resource-groups';
import { TriggerInternalModel } from '@spog-ui/shared/models/triggers';

export const loadSuccessAction = createAction(
  '[Equipment Profile API] Load Success',
  (models: {
    resourceGroups: ResourceGroupInternalModel[];
    indieSensors: IndieSensorInternalModel[];
    triggers: TriggerInternalModel[];
  }) => {
    return { models };
  },
);

export const loadFailureAction = createAction(
  '[Equipment Profile API] Load Failure',
  (error: string) => ({
    error,
  }),
);

export const deleteEquipmentSuccess = createAction(
  '[Equipment Profile API] Delete Equipment Success',
  id => ({ id }),
);

export const deleteEquipmentFailure = createAction(
  '[Equipment Profile API] Delete Equipment Failure',
  (error: string) => ({
    error,
  }),
);
