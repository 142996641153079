import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as CoreState from '@spog-ui/shared/state/core';
import { findBoundingBox } from '@spog-ui/map-tools';
import {
  toEquipmentMapViewModelFromInternal,
  EquipmentLegendViewModel,
  EquipmentMapViewModel,
} from '@spog-ui/shared/models/equipment';

import { FilterState } from './filter';
import { StatusTrayState } from './status-tray';
import { EquipmentLayerState } from './equipment-layer';
import { DispositionLevel } from '@spog-ui/graphql/types';

export const STATE_KEY = 'equipmentMap';

export interface Shape {
  filter: FilterState.Shape;
  statusTray: StatusTrayState.Shape;
  equipmentLayer: EquipmentLayerState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  filter: FilterState.reducer,
  statusTray: StatusTrayState.reducer,
  equipmentLayer: EquipmentLayerState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

export const selectEquipmentMapFilterState = createSelector(
  selectFeatureState,
  (state: Shape) => state.filter,
);
export const selectEquipmentMapSearchTerm = createSelector(
  selectEquipmentMapFilterState,
  FilterState.selectSearchTerm,
);

export const selectEquipmentMapStatusTrayState = createSelector(
  selectFeatureState,
  (state: Shape) => state.statusTray,
);

export const selectEquipmentMapLayerState = createSelector(
  selectFeatureState,
  (state: Shape) => state.equipmentLayer,
);
export const selectEquipmentMapLayerError = createSelector(
  selectEquipmentMapLayerState,
  EquipmentLayerState.selectError,
);
export const selectEquipmentMapLayerLoading = createSelector(
  selectEquipmentMapLayerState,
  EquipmentLayerState.selectLoading,
);

export const selectPositionedEquipment = createSelector(
  CoreState.selectNonchildEquipment,
  equipment => {
    return equipment
      .filter(
        pieceOfEquipment =>
          typeof pieceOfEquipment.floorPlanX === 'number' &&
          typeof pieceOfEquipment.floorPlanY === 'number',
      )
      .map(toEquipmentMapViewModelFromInternal);
  },
);

export const selectHasPositionedEquipment = createSelector(
  selectPositionedEquipment,
  positionedEquipment => positionedEquipment.length > 0,
);

export const selectEquipmentLegendView = createSelector(
  selectPositionedEquipment,
  equipmentViews => {
    return getEquipmentLegendViewModel(equipmentViews);
  },
);

export const selectPositionedEquipmentNames = createSelector(
  selectPositionedEquipment,
  equipment => equipment.map(pieceOfEquipment => pieceOfEquipment.name),
);

export const selectEquipmentStatusTrayIsFilteringNormal = createSelector(
  selectEquipmentMapStatusTrayState,
  StatusTrayState.selectIsFilteringNormal,
);

export const selectEquipmentStatusTrayIsFilteringInfo = createSelector(
  selectEquipmentMapStatusTrayState,
  StatusTrayState.selectIsFilteringInfo,
);

export const selectEquipmentStatusTrayIsFilteringWarning = createSelector(
  selectEquipmentMapStatusTrayState,
  StatusTrayState.selectIsFilteringWarning,
);

export const selectEquipmentStatusTrayIsFilteringCritical = createSelector(
  selectEquipmentMapStatusTrayState,
  StatusTrayState.selectIsFilteringCritical,
);

export const selectEquipmentStatusTrayIsFilteringUnknown = createSelector(
  selectEquipmentMapStatusTrayState,
  StatusTrayState.selectIsFilteringUnknown,
);

export const selectFilteredEquipmentForTheMap = createSelector(
  selectPositionedEquipment,
  selectEquipmentStatusTrayIsFilteringNormal,
  selectEquipmentStatusTrayIsFilteringInfo,
  selectEquipmentStatusTrayIsFilteringWarning,
  selectEquipmentStatusTrayIsFilteringCritical,
  selectEquipmentStatusTrayIsFilteringUnknown,
  selectEquipmentMapSearchTerm,
  (
    equipment,
    filteringNormal,
    filteringInfo,
    filteringWarning,
    filteringCritical,
    filteringUnknown,
    searchTerm,
  ) => {
    const lowerCaseSearchTerm = searchTerm.toLocaleLowerCase();

    return equipment.filter(pieceOfEquipment => {
      const isNormal = pieceOfEquipment.disposition === DispositionLevel.NORMAL;
      const isCritical = pieceOfEquipment.disposition === DispositionLevel.CRITICAL;
      const isWarning = pieceOfEquipment.disposition === DispositionLevel.WARNING;
      const isInfo = pieceOfEquipment.disposition === DispositionLevel.INFO;
      const isUnknown = pieceOfEquipment.disposition === DispositionLevel.UNKNOWN;

      if (!isNormal && filteringNormal) {
        return false;
      } else if (!isInfo && filteringInfo) {
        return false;
      } else if (!isWarning && filteringWarning) {
        return false;
      } else if (!isCritical && filteringCritical) {
        return false;
      } else if (!isUnknown && filteringUnknown) {
        return false;
      } else if (!pieceOfEquipment.name.toLowerCase().includes(lowerCaseSearchTerm)) {
        return false;
      }

      return true;
    });
  },
);

export const selectBoxAroundFilteredEquipment = createSelector(
  selectFilteredEquipmentForTheMap,
  selectPositionedEquipment,
  (filteredEquipment, allEquipment) => {
    const equipment = filteredEquipment.length > 0 ? filteredEquipment : allEquipment;

    return findBoundingBox(equipment);
  },
);

export function getEquipmentLegendViewModel(
  equipmentMapView: EquipmentMapViewModel[],
): EquipmentLegendViewModel {
  return equipmentMapView.reduce(
    (counts, equipment) => {
      let isNormal, isCritical, isWarning, isInfo, isUnknown;
      if (equipment.disposition) {
        isNormal = equipment.disposition === DispositionLevel.NORMAL;
        isCritical = equipment.disposition === DispositionLevel.CRITICAL;
        isWarning = equipment.disposition === DispositionLevel.WARNING;
        isInfo = equipment.disposition === DispositionLevel.INFO;
        isUnknown = equipment.disposition === DispositionLevel.UNKNOWN;
      }

      if (isNormal) {
        ++counts.normal;
      } else if (isWarning) {
        ++counts.warning;
      } else if (isCritical) {
        ++counts.critical;
      } else if (isInfo) {
        ++counts.info;
      } else if (isUnknown) {
        ++counts.unknown;
      }

      return counts;
    },
    { normal: 0, warning: 0, critical: 0, info: 0, unknown: 0 },
  );
}
