import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphQLClient } from '@spog-ui/graphql-client';
import { AcceptEulaDocument, MyEulaDocument } from '@spog-ui/graphql/types';

@Injectable()
export class EulaService {
  constructor(private gql: GraphQLClient) {}

  updateEulaStatus(userId: string) {
    const variables = {
      id: userId,
    };
    return this.gql.query(AcceptEulaDocument, variables).pipe(
      map(response => {
        return response.data.userAcceptedEula;
      }),
    );
  }

  getEulaStatus(): Observable<boolean> {
    return this.gql.query(MyEulaDocument).pipe(
      map(response => {
        return !!response.data.me.eulaAcceptedAt;
      }),
    );
  }
}
