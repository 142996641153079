import { createAction } from '@ngrx/store';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';
import { ThermostatInternalModel } from '@spog-ui/shared/models/thermostats';

export const loadEditThermostatPageModelsSuccessAction = createAction(
  '[Edit Thermostat API] Load Edit Thermostat Page Models Success',
  (
    thermostats: ThermostatInternalModel[],
    siteControllers: SiteControllerInternalModel[],
  ) => ({
    thermostats: thermostats,
    siteControllers: siteControllers,
  }),
);
export const loadEditThermostatPageModelsFailureAction = createAction(
  '[Edit Thermostat API] Load Edit Thermostat Page Models Failure',
  (error: any) => ({ error }),
);
export const editThermostatSuccess = createAction(
  '[Edit Thermostat API] Edit Thermostat Success',
);
export const editThermostatFailure = createAction(
  '[Edit Thermostat API] Edit Thermostat Failure',
  (error?: any) => ({ error }),
);
