import { createAction } from '@ngrx/store';
import { UserInternalModel } from '@spog-ui/shared/models/users';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const loadModelsSuccessAction = createAction(
  '[Users / API] Load Models Success',
  (users: UserInternalModel[], sites: SiteInternalModel[]) => ({ users, sites }),
);
export const loadModelsFailureAction = createAction(
  '[Users / API] Load Models Failure',
  (error: string) => ({ error }),
);

export const createUserSuccessAction = createAction(
  '[Users / API] Create User Success',
  (userId: string) => ({ userId }),
);
export const createUserFailureAction = createAction(
  '[Users / API] Create User Failure',
  (error: string) => ({ error }),
);

export const deleteUserSuccessAction = createAction(
  '[Users / API] Delete User Success',
  (user: UserInternalModel) => ({ user }),
);
export const deleteUserFailureAction = createAction(
  '[Users / API] Delete User Failure',
  (error: string) => ({ error }),
);

export const resetPasswordSuccessAction = createAction(
  '[Users / API] Reset Password Success',
  (user: UserInternalModel) => ({ user }),
);
export const resetPasswordFailureAction = createAction(
  '[Users / API] Reset Password Failure',
  (error: string) => ({ error }),
);
