import { createAction } from '@ngrx/store';
import { Color, ColorChannels, ColorPickerMode } from './color-picker.models';

export const setModeFromParentComponentInput = createAction(
  '[@sui/color-picker] Set Mode From Parent Component Input',
  (mode: ColorPickerMode) => ({ mode }),
);

export const updateColorChannelFromSliders = createAction(
  '[@sui/color-picker] Update Color Channel From Sliders',
  (channel: ColorChannels, value: number) => ({
    channel,
    value,
  }),
);

export const writeValueFromFormControl = createAction(
  '[@sui/color-picker] Write Value From Form Control',
  (color: Color) => ({ color }),
);

export const writeValueFromParentComponentInput = createAction(
  '[@sui/color-picker] Write Value From Parent Component Input',
  (color: Color) => ({ color }),
);

export const setDisabledStateFromFormControl = createAction(
  '[@sui/color-picker] Set Disabled State From Form Control',
  (isDisabled: boolean) => ({ isDisabled }),
);

export const setDisabledStateFromParentComponentInput = createAction(
  '[@sui/color-picker] Set Disabled State From Parent Component Input',
  (isDisabled: boolean) => ({ isDisabled }),
);

export const userInputsHexValue = createAction(
  '[@sui/color-picker] User Inputs Hex Value',
  (value: string) => ({ value }),
);

export const userBlursHexInput = createAction('[@sui/color-picker] User Blurs Hex Input');

export const userAppliesColorSwatch = createAction(
  '[@sui/color-picker] User Applies Color Swatch',
  (color: Color) => ({ color }),
);

export const userCreatesColorSwatch = createAction(
  '[@sui/color-picker] User Creates Color Swatch',
);

export const userStartsReplacingColorSwatch = createAction(
  '[@sui/color-picker] User Starts Replacing Color Swatch',
  (index: number) => ({ index }),
);

export const userStartsDeletingColorSwatch = createAction(
  '[@sui/color-picker] User Starts Deleting Color Swatch',
  (index: number) => ({ index }),
);

export const userReplacesColorInColorSwatch = createAction(
  '[@sui/color-picker] User Replaces Color In Color Swatch',
  (index: number) => ({ index }),
);

export const userChangesFastColorInColorSwatch = createAction(
  '[@sui/color-picker] User Changes Fast Color In Color Swatch',
  (index: number, change: string) => ({ index, change }),
);

export const userDeletesColorInColorSwatch = createAction(
  '[@sui/color-picker] User Deletes Color In Color Swatch',
  (index: number) => ({ index }),
);

export const userReordersColorInColorSwatch = createAction(
  '[@sui/color-picker] User Reorders Color In Color Swatch',
  (oldIndex: number, newIndex: number) => ({ oldIndex, newIndex }),
);
