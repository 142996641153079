import { IndieSensorsApi } from '@spog-ui/indie-sensors/actions';
import { EditIndieSensorPage } from '@spog-ui/indie-sensors/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  error?: any;
  saving: boolean;
  loading: boolean;
  indieSensorId: string | null;
  editing: boolean;
}

export const initialState: Shape = {
  saving: false,
  loading: true,
  indieSensorId: null,
  editing: false,
};

export const reducer = createReducer(
  initialState,
  on(EditIndieSensorPage.enterAction, (state, action) => {
    return {
      ...state,
      indieSensorId: action.indieSensorId,
    };
  }),
  on(EditIndieSensorPage.editSense420IndieSensorAction, state => {
    return {
      ...state,
      error: null,
      saving: true,
      loading: false,
      editing: true,
    };
  }),
  on(
    IndieSensorsApi.editSense420IndieSensorSuccessAction,
    IndieSensorsApi.loadIndieSensorsAndControllersSuccessAction,
    state => {
      return {
        ...state,
        error: null,
        saving: false,
        loading: false,
        editing: false,
      };
    },
  ),
  on(
    IndieSensorsApi.editSense420IndieSensorFailureAction,
    IndieSensorsApi.loadFailureAction,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        saving: false,
        loading: false,
        editing: false,
      };
    },
  ),
);

export const selectSaving = (state: Shape) => state.saving;
export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
export const selectEditing = (state: Shape) => state.editing;
export const selectIndieSensorId = (state: Shape) => state.indieSensorId;
