import { createReducer, on } from '@ngrx/store';
import { EditSitePageActions, SiteApiActions } from '@spog-ui/admin/actions';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

export interface Shape {
  siteId: string | null;
  isLoading: boolean;
  isSaving: boolean;
}

export const initialState: Shape = {
  siteId: null,
  isLoading: true,
  isSaving: false,
};

export const reducer = createReducer(
  initialState,
  on(EditSitePageActions.enterAction, (state, action) => {
    return { ...initialState, siteId: action.siteId };
  }),
  on(SiteApiActions.loadModelsForEditSuccessAction, (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(EditSitePageActions.saveSiteAction, state => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(SiteApiActions.addSiteFailureAction, state => {
    return {
      ...state,
      isSaving: false,
    };
  }),
);

export const selectSiteId = (state: Shape) => state.siteId;
export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSaving = (state: Shape) => state.isSaving;
