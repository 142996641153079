import { Component, Input, Optional } from '@angular/core';
import { DrawerComponent } from '../drawer';

@Component({
  selector: 'spog-drawer-link',
  template: `
    <a
      [routerLink]="to"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="onClose()"
      >
      @if (svgIcon) {
        <mat-icon [svgIcon]="svgIcon"></mat-icon>
      } @else {
        <mat-icon>{{ icon }}</mat-icon>
      }
      <ng-content></ng-content>
    </a>
    `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        padding: 2px 12px;
      }

      a {
        display: block;
        width: 100%;
        height: 42px;
        line-height: 42px;
        vertical-align: middle;
        position: relative;
        padding: 0 0 0 66px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        color: var(--color-foreground-text);
        text-decoration: none;
        transition: color 250ms;
      }

      a:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0.08;
        background-color: transparent;
        transition: background-color 250ms;
      }

      a.active,
      a:hover {
        color: var(--color-500);
      }

      a.active:before,
      a:hover:before {
        background-color: var(--color-300);
      }

      mat-icon {
        position: absolute;
        top: 8px;
        left: 8px;
      }
    `,
  ],
})
export class DrawerLinkComponent {
  @Input() to = '/';
  @Input() icon = '';
  @Input() svgIcon?: string;

  constructor(@Optional() public drawer: DrawerComponent) {}

  onClose(): void {
    if (this.drawer) {
      this.drawer.onClose();
    }
  }
}
