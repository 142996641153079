import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { OrganizationSiteInternalModel } from '@spog-ui/shared/models/sites';
import { OrgAdminSitesStateActions } from '../actions';

export type Shape = EntityState<OrganizationSiteInternalModel>;

export const STATE_KEY = 'sites';

export const adapter = createEntityAdapter<OrganizationSiteInternalModel>({
  selectId: (model: OrganizationSiteInternalModel) => model.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(OrgAdminSitesStateActions.loadSitesSuccessAction, (state, action) => {
    return adapter.setAll(action.sites, state);
  }),
);

export const { selectEntities, selectIds, selectAll } = adapter.getSelectors();
