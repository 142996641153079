import { createAction } from '@ngrx/store';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';

export const enterAction = createAction('[Site Map] Enter');

export const selectAction = createAction(
  '[Site Map] Select Site',
  (site: SiteInternalModel) => {
    return { site };
  },
);

export const cancelSiteSelectAction = createAction('[Site Map] Cancel Site Select');

export const cancelSiteChangeAction = createAction('[Site Map] Cancel Site Change');
