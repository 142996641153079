import { ScenesPageActions } from '@spog-ui/scenes/actions';
import { trim } from 'lodash';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  term: string;
}

export const initialState = {
  term: '',
};

export const reducer = createReducer(
  initialState,
  on(ScenesPageActions.enterAction, state => {
    return { ...initialState, term: state.term };
  }),
  on(ScenesPageActions.searchAction, (state, action) => {
    return { term: action.searchTerm };
  }),
);

export const selectTerm = (state: Shape) => trim(state.term);
