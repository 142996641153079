import {
  PermissionGroupModelFragment,
  PermissionGroupSiteRole,
  UpdatePermissionGroupInput,
  CreatePermissionGroupInput,
} from '@spog-ui/graphql/types';
import { PermissionGroupOrgAdminUpsert as PermissionGroupWSModel } from '@spog-shared/events/organization';

export { PermissionGroupWSModel };

export interface PermissionGroupInternalModel {
  id: string;
  name: string;
  siteRole: PermissionGroupSiteRole;
  siteIds: string[];
  userIds: string[];
}

export type PermissionGroupGQLModel = PermissionGroupModelFragment;

export function toPermissionGroupInternalModelFromGQL(
  gql: PermissionGroupGQLModel,
): PermissionGroupInternalModel {
  return {
    id: gql.id,
    name: gql.name,
    siteRole: gql.siteRole,
    siteIds: gql.sites.map(site => site.id),
    userIds: gql.users.map(user => user.id),
  };
}

export interface PermissionGroupFormModel {
  id?: string;
  name: string;
  siteRole: PermissionGroupSiteRole;
  siteIds: string[];
  userIds: string[];
}

export function toCreatePermissionGroupInputFromPermissionGroupForm(
  form: PermissionGroupFormModel,
  organizationId: string,
): CreatePermissionGroupInput {
  return {
    name: form.name,
    siteRole: form.siteRole,
    siteIds: form.siteIds,
    userIds: form.userIds,
    organizationId,
  };
}

export function toUpdatePermissionGroupInputFromPermissionGroupForm(
  form: PermissionGroupFormModel,
): UpdatePermissionGroupInput {
  return {
    id: form.id!,
    name: form.name,
    siteRole: form.siteRole,
    siteIds: form.siteIds,
    userIds: form.userIds,
  };
}

export function toPermissionGroupFormModelFromInternal(
  permissionGroup: PermissionGroupInternalModel,
): PermissionGroupFormModel {
  return {
    id: permissionGroup.id,
    name: permissionGroup.name,
    siteRole: permissionGroup.siteRole,
    siteIds: permissionGroup.siteIds,
    userIds: permissionGroup.userIds,
  };
}

export function parsePermissionGroupSiteRole(siteRole: string): PermissionGroupSiteRole {
  switch (siteRole) {
    case 'MEMBER': {
      return PermissionGroupSiteRole.MEMBER;
    }
    case 'SCENE_AND_SCHEDULE_MEMBER': {
      return PermissionGroupSiteRole.SCENE_AND_SCHEDULE_MEMBER;
    }
    case 'SCENE_USER': {
      return PermissionGroupSiteRole.SCENE_USER;
    }
    case 'VIEWER': {
      return PermissionGroupSiteRole.VIEWER;
    }
    case 'NONE': {
      return PermissionGroupSiteRole.NONE;
    }
    default: {
      throw new Error(`Could not parse site role: "${siteRole}".`);
    }
  }
}

export function toPermissionGroupInternalModelFromWS(
  ws: PermissionGroupWSModel,
): PermissionGroupInternalModel {
  return {
    id: ws.id,
    name: ws.name,
    siteRole: parsePermissionGroupSiteRole(ws.siteRole),
    siteIds: ws.siteIds,
    userIds: ws.userIds,
  };
}
