import { Component, Input } from '@angular/core';
import { SuiAlarmModel, AlarmTypes } from '@shared/alarms';

@Component({
  selector: 'lit-light-status-tray',
  template: `
    <div class="lightStatusTray" [class.hasAlarms]="hasAlarms">
      <lit-communication-status
        [hasCommunicationAlarm]="hasCommunicationAlarm"
        [hasLowSignalAlarm]="hasLowSignalAlarm"
      ></lit-communication-status>
    </div>
  `,
  styles: [
    `
      .lightStatusTray {
        background-color: var(--color-700);
        color: var(--color-contrast-700);
        width: 66px;
        height: 28px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hasAlarms {
        background-color: var(--color-warn-500);
      }
    `,
  ],
})
export class LightStatusTrayComponent {
  hasAlarms = false;
  hasLowSignalAlarm = false;
  hasCommunicationAlarm = false;

  @Input()
  set alarms(alarms: SuiAlarmModel[]) {
    this.hasAlarms = alarms.length > 0;
    this.hasLowSignalAlarm = alarms.some(
      alarm => alarm.type === AlarmTypes.DeviceLinkQualityWarning,
    );
    this.hasCommunicationAlarm = alarms.some(
      alarm => alarm.type === AlarmTypes.DeviceCommunicationFailure,
    );
  }
}
