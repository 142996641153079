import { createAction } from '@ngrx/store';
import { OrganizationSiteInternalModel } from '@spog-ui/shared/models/sites';

export const enterAction = createAction(
  '[Admin / Organizations / Sites] Enter',
  (organizationId: string) => ({ organizationId }),
);

export const loadPageSuccessAction = createAction(
  '[Admin Organization Sites / API] Load Page Success',
  (sites: OrganizationSiteInternalModel[]) => ({
    sites,
  }),
);

export const loadPageFailureAction = createAction(
  '[Admin Organization Sites / API] Load Page Failure',
  (error: string) => ({ error }),
);

export const deleteAction = createAction(
  '[Admin Organization / Sites] Delete',
  (site: OrganizationSiteInternalModel) => ({ site }),
);

export const confirmDeleteAction = createAction(
  '[Admin Organization / Sites] Confirm Delete',
  (site: OrganizationSiteInternalModel) => ({ site }),
);

export const cancelDeleteAction = createAction(
  '[Admin Organization / Sites] Cancel Delete',
  (site: OrganizationSiteInternalModel) => ({ site }),
);

export const filterAction = createAction(
  '[Admin Organization / Sites] Filter',
  (term: string) => {
    return { term };
  },
);
export const paginateAction = createAction(
  '[Admin Organization / Sites] Paginate',
  (pageIndex: number, pageSize: number) => {
    return { pageIndex, pageSize };
  },
);

export const copyIDAction = createAction(
  '[Admin Organization / Sites] Copy Org ID',
  (site: OrganizationSiteInternalModel) => {
    return { site };
  },
);
