import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentUser } from '@spog-ui/shared/models/users';
import { SiteInternalModel } from '@spog-ui/shared/models/sites';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';

@Component({
  selector: 'spog-drawer-account',
  template: `
    <div
      class="grid-container"
      [matMenuTriggerFor]="accountMenu"
      (menuOpened)="opened = true"
      (menuClosed)="opened = false"
      >
      <div class="userName">{{ user?.name }}</div>
      <div class="siteName">{{ site?.name }}</div>
      <button class="accountMenuButton" id="accountMenuButton">
        <mat-icon [class.opened]="opened">arrow_drop_down</mat-icon>
      </button>
    
      <mat-menu #accountMenu="matMenu" yPosition="below">
        <button mat-menu-item (click)="onChangeSite()">Change Site</button>
        @if (organizations.length > 0) {
          <button
            mat-menu-item
            (click)="onManageOrganization()"
            >
            Manage Organization
          </button>
        }
        <button mat-menu-item (click)="onLogout()" id="logoutButton">Logout</button>
      </mat-menu>
    </div>
    `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        position: relative;
        height: 64px;
      }

      .grid-container {
        display: grid;
        grid-template-areas:
          'name menu'
          'site menu';
        grid-template-columns: auto 24px;
        cursor: pointer;
        width: 100%;
      }

      .userName {
        font-weight: 500;
        color: var(--color-foreground-text);
        font-size: 18px;
        grid-area: name;
      }

      .siteName {
        font-size: 12px;
        color: var(--color-foreground-secondary-text);
        grid-area: site;
      }

      .accountMenuButton {
        background-color: transparent;
        outline: none;
        border: none;
        width: 100%;
        grid-area: menu;
      }

      .accountMenuButton mat-icon {
        color: white;
        transform-origin: center;
        transition: transform 250ms;
      }

      .accountMenuButton mat-icon.opened {
        transform: rotate(180deg);
      }
    `,
  ],
})
export class DrawerAccountComponent {
  opened = false;

  @Input()
  user: CurrentUser;
  @Input()
  site: SiteInternalModel;
  @Input() organizations: OrganizationInternalModel[];
  @Output()
  logout = new EventEmitter();
  @Output()
  changeSite = new EventEmitter();
  @Output() navigateToManageOrg = new EventEmitter();
  @Output() openOrganizationsMenu = new EventEmitter();

  onLogout() {
    this.logout.emit();
  }

  onChangeSite() {
    this.changeSite.emit();
  }

  onManageOrganization() {
    //  If only one organization available, route to manage organization page
    if (this.organizations.length === 1) {
      this.navigateToManageOrg.emit(this.organizations[0].id);

      //  Otherwise, open a menu ist of organizations
    } else {
      this.openOrganizationsMenu.emit(this.organizations);
    }
  }
}
