import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavFooterComponentModule } from '../nav-footer';
import { DrawerComponent } from './drawer.component';

@NgModule({
  imports: [CommonModule, NavFooterComponentModule],
  declarations: [DrawerComponent],
  exports: [DrawerComponent],
})
export class DrawerComponentModule {}
