import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { race, of, timer } from 'rxjs';
import { AppUpdatesActions, AppUpdatesApiActions } from '@spog-ui/app-updates/actions';

@Injectable()
export class AppUpdatesEffects {
  constructor(
    readonly actions$: Actions,
    readonly swUpdate: SwUpdate,
    @Inject(DOCUMENT) readonly document: Document,
  ) {}

  checkForUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppUpdatesActions.checkForUpdate),
      filter(() => this.swUpdate.isEnabled),
      exhaustMap(() =>
        race([this.swUpdate.checkForUpdate(), timer(5000)]).pipe(
          map(() => AppUpdatesApiActions.checkForUpdateSuccess()),
          catchError(err => of(AppUpdatesApiActions.checkForUpdateFailure(err))),
        ),
      ),
    ),
  );

  downloadUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppUpdatesActions.downloadUpdate),
      filter(() => this.swUpdate.isEnabled),
      exhaustMap(() =>
        race([this.swUpdate.activateUpdate(), timer(5000)]).pipe(
          map(() => AppUpdatesApiActions.downloadUpdateSuccess()),
          catchError(err => of(AppUpdatesApiActions.downloadUpdateFailure(err))),
        ),
      ),
    ),
  );

  reloadApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppUpdatesActions.reloadApp),
        tap(() => {
          sessionStorage.setItem('appUpdated', 'true');
          this.document.location.reload();
        }),
      ),
    { dispatch: false },
  );
}
