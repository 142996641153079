import { createReducer, on } from '@ngrx/store';
import { AddSiteControllerPageActions } from '@spog-ui/admin/actions';
import { SiteControllerApiActions } from '@spog-ui/shared/effects/actions';

export interface Shape {
  isLoading: boolean;
  error?: any;
}

export const initialState: Shape = {
  isLoading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(AddSiteControllerPageActions.enterAction, () => initialState),
  on(SiteControllerApiActions.loadModelsSuccessAction, state => ({
    ...state,
    isLoading: false,
  })),
  on(SiteControllerApiActions.loadModelsFailureAction, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
