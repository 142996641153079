import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  withLatestFrom,
  tap,
  mergeMap,
} from 'rxjs/operators';
import { of, defer } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { toQuickSiteSetupInputFromQuickSiteSetupForm } from '@spog-ui/shared/models/organizations';
import {
  QuickSiteSetupApiActions,
  QuickSiteSetupPageActions,
} from '../actions';
import { selectActiveOrgId } from '@spog-ui/shared/state/organizations';
import { SitesApiService } from '../services/';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class QuickSiteSetupEffects {
  constructor(
    readonly actions$: Actions,
    readonly sitesApiService: SitesApiService,
    readonly gql: GraphQLAPIService,
    readonly location: Location,
    readonly snackbar: MatSnackBar,
    readonly store: Store,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickSiteSetupPageActions.enterAction),
      withLatestFrom(defer(() => this.store.select(selectActiveOrgId))),
      switchMap(([action, activeOrgId]) => {
        return this.sitesApiService.getSitesByOrganization(activeOrgId!).pipe(
          map(([sites, users]) => QuickSiteSetupPageActions.loadPageSuccessAction()),
          catchError((response: HttpErrorResponse) =>
            of(
              QuickSiteSetupPageActions.loadPageFailureAction(
                retrieveErrorMessage(response),
              ),
            ),
          ),
        );
      }),
    ),
  );

  quickSiteSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuickSiteSetupPageActions.quickSiteSetupAction),
      withLatestFrom(defer(() => this.store.select(selectActiveOrgId))),
      mergeMap(([action, activeOrgId]) => {
        const input = toQuickSiteSetupInputFromQuickSiteSetupForm(
          action.quickSiteSetup,
          activeOrgId!,
        );
        return this.gql.quickSiteSetup({ input }).pipe(
          map(() => QuickSiteSetupApiActions.quickSiteSetupSuccessAction()),
          catchError(error => {
            return of(QuickSiteSetupApiActions.quickSiteSetupFailureAction(error));
          }),
        );
      }),
    ),
  );

  redirectToSitesPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuickSiteSetupApiActions.quickSiteSetupSuccessAction),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  quickSiteSetupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuickSiteSetupApiActions.quickSiteSetupFailureAction),
        tap(action => {
          action.error.errors.forEach((error: any) => {
            this.snackbar.open(error.message, 'Dismiss', {});
          });
        }),
      ),
    { dispatch: false },
  );
}
