import { createAction } from '@ngrx/store';
import { PermissionGroupFormModel } from '@spog-ui/shared/models/permission-groups';
import { OrganizationSiteInternalModel } from '@spog-ui/shared/models/sites';
import { OrganizationUserInternalModel } from '@spog-ui/shared/models/organization-users';

export const enterAction = createAction('[Admin / Add Permission Group Page] Enter');

export const addPermissionGroupAction = createAction(
  '[Add Permission Group] Add Permission Group',
  (permissionGroup: PermissionGroupFormModel) => ({ permissionGroup }),
);

export const loadPageSuccessAction = createAction(
  '[Add Permission Group] Load Page Success',
  (users: OrganizationUserInternalModel[], sites: OrganizationSiteInternalModel[]) => ({
    users,
    sites,
  }),
);

export const loadPageFailureAction = createAction(
  '[Add Permission Group] Load Page Failure',
  (error: string) => ({ error }),
);
