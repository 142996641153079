import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MapErrorCardComponent } from './map-error-card.component';
import { ErrorCardComponentModule } from '@spog-ui/shared/components';

@NgModule({
    imports: [CommonModule, OverlayModule, PortalModule, ErrorCardComponentModule, MapErrorCardComponent],
    exports: [MapErrorCardComponent],
})
export class MapErrorCardComponentModule {}
