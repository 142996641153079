import { DateTime } from 'luxon';
import { MetricHistoryModelFragment as MetricHistoryGQLModel } from '@spog-ui/graphql/types';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';

export { MetricHistoryGQLModel };
export interface MetricHistoryInternalModel {
  id: string;
  history: {
    value: number;
    timestamp: string; // ISO 8601
  }[];
}

export interface MetricHistoryViewModel {
  units: string; // Derived from the IndieSensor
  min?: number; // Derived from the IndieSensor
  max?: number; // Derived from the IndieSensor
  history: {
    value: number;
    timestamp: DateTime;
  }[];
}

export function toMetricHistoryInternalModelsFromGQL(
  gql: MetricHistoryGQLModel,
): MetricHistoryInternalModel {
  return {
    id: gql.id,
    history: gql.history,
  };
}

export function generateMetricHistoryViewModel(
  source: IndieSensorInternalModel | undefined,
  metricHistory: MetricHistoryInternalModel | undefined,
  timezone: string,
): MetricHistoryViewModel | undefined {
  if (!source || !metricHistory) {
    return undefined;
  }

  const history = metricHistory.history.map(dataPoint => ({
    ...dataPoint,
    timestamp: DateTime.fromISO(dataPoint.timestamp, { zone: timezone }),
  }));

  return {
    min: source.minValue,
    max: source.maxValue,
    units: source.units,
    history,
  };
}
