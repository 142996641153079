import { createReducer, on } from '@ngrx/store';
import { SenseMapLayerActions } from '@spog-ui/sense-map-layer/actions';

export interface Shape {
  isFilteringOk: boolean;
  isFilteringAlarmed: boolean;
  isFilteringPending: boolean;
}

export const initialState: Shape = {
  isFilteringOk: false,
  isFilteringAlarmed: false,
  isFilteringPending: false,
};

export const reducer = createReducer(
  initialState,
  on(SenseMapLayerActions.enterAction, () => {
    return initialState;
  }),
  on(SenseMapLayerActions.toggleStatusTrayFilterAction, (state, action) => {
    return {
      ...state,
      isFilteringOk: action.filteringOk,
      isFilteringAlarmed: action.filteringAlarmed,
      isFilteringPending: action.filteringPending,
    };
  }),
);

export const selectIsFilteringOk = (state: Shape) => state.isFilteringOk;
export const selectIsFilteringAlarmed = (state: Shape) => state.isFilteringAlarmed;
export const selectIsFilteringPending = (state: Shape) => state.isFilteringPending;
