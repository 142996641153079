import { SiteService } from '@spog-ui/shared/services';
import { CurrentUserService } from '@spog-ui/current-user/feature';

export function getPermissions(site: SiteService) {
  return site.getPermissions();
}

export function getIsSuperUser(currentUser: CurrentUserService) {
  return currentUser.isSuperAdmin$;
}
