import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ApiErrorResponse } from './graphql.service';

export const processGraphQLErrorAction = createAction(
  '[Analytics] Process GraphQL Error',
  props<{ response: ApiErrorResponse<any>; correlationId: string | null }>(),
);

export const processGraphQLHTTPErrorAction = createAction(
  '[Analytics] Process GraphQL HTTP Error',
  props<{ response: HttpErrorResponse; correlationId: string | null }>(),
);
