import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { SuiPlatformModule, SuiBodyModule } from '@spog-ui/shared/components';
import { AppUpdatesModule } from '@spog-ui/app-updates';
import { PermissionsModule, PERMISSIONS } from '@spog-ui/current-user/permissions';
import { provideEnabledKnobs, provideGqlUrl } from '@spog-ui/graphql-client';
import { provideMapToolsForBrowser } from '@spog-ui/map-tools';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { ServicesModule, SiteService } from '@spog-ui/shared/services';
import { SharedClockModule, SharedScenesApiEffectsModule } from '@spog-ui/shared/effects';
import { LightingRoutingModule } from './routing';
import { SocketModule } from '@spog-ui/socket';
import { ToolbarComponentModule } from './components/toolbar';
import { LightingAppComponent } from './lighting-app.component';
import { LandingPageComponentModule } from '../lighting/components/landing-page';
import { environment } from '@spog-ui/shared/environments';
import { MenuModule } from '@spog-ui/menu';
import { CustomSerializer } from '@spog-ui/utils/router';
import { RemoteAccessComponent } from '@spog-ui/shared/effects';
import { ScheduleUtilityRateModalComponentModule } from '@spog-ui/utility-services';
import { RedirectModule } from '@spog-ui/shared/effects';
import { BrowserModule } from '@spog-ui/shared/effects';
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { getPermissions } from './permissions';
import { AnalyticsModule } from '@spog-ui/analytics';
import { AlarmsStateModule } from '@spog-ui/shared/state/alarms';
import { ClimateStateModule } from '@spog-ui/shared/state/climate';
import { ClimateMapStateModule } from '@spog-ui/shared/state/climate-map';
import { CoreStateModule } from '@spog-ui/shared/state/core';
import { EnergyInfoSystemStateModule } from '@spog-ui/shared/state/energy-info-system';
import { EquipmentMapStateModule } from '@spog-ui/shared/state/equipment-map';
import { LightingStateModule } from '@spog-ui/shared/state/lighting-map';
import { MapStateModule } from '@spog-ui/shared/state/map';
import { MapPositionerStateModule } from '@spog-ui/shared/state/map-positioner';
import { OrganizationsStateModule } from '@spog-ui/shared/state/organizations';
import { PermissionGroupsStateModule } from '@spog-ui/shared/state/permission-groups';
import { EmailGroupsStateModule } from '@spog-ui/shared/state/email-groups';
import { PowerStateModule } from '@spog-ui/shared/state/power';
import { RemoteAccessStateModule } from '@spog-ui/shared/state/remote-access';
import { SceneViewsStateModule } from '@spog-ui/shared/state/scene-views';
import { ScenesStateModule } from '@spog-ui/shared/state/scenes';
import { ScheduleStateModule } from '@spog-ui/shared/state/schedule';
import { SenseStateModule } from '@spog-ui/shared/state/sense';
import { SenseMapStateModule } from '@spog-ui/shared/state/sense-map';
import { TriggersStateModule } from '@spog-ui/shared/state/triggers';
import { metaReducers } from '@spog-ui/shared/state/root';
import { PageNotFoundComponentModule } from '@spog-ui/modules/page-not-found';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LightingZonesStateModule } from '@spog-ui/shared/state/lighting-zones';
import { UnhandledExceptionsModule } from '@spog-ui/modules/unhandled-exceptions';
import { ColorPaletteGQLService } from './services';
import { provideColorPaletteService } from '@sui/color-picker';
import { UserInteractionModule } from '@spog-ui/shared/state/user-interactions/core';
import { PageLoadTrackingModule } from '@spog-ui/shared/state/user-interactions/page-load-tracking';
import { SiteSelectorModule } from '@spog-ui/site-selector';
import { NetworkModule } from '@spog-ui/network';
import { CurrentUserModule } from '@spog-ui/current-user/feature';
import { OrganizationsFeatureStateModule } from '@spog-ui/modules/organizations/state';
import { SiteDetailsStateModule } from '@spog-ui/site-details/state';
import { AdminOrganizationFeatureStateModule } from '@spog-ui/admin-organization/state';
import { Bridge485DetailsStateModule } from '@spog-ui/bridge-485-details/state';
import { Bridge485sFeatureStateModule } from '@spog-ui/bridge-485s/state';
import { Bridge485sStateModule } from '@spog-ui/shared/state/bridge-485s';
import { Bridge485TemplatesStateModule } from '@spog-ui/shared/state/bridge-485-templates';
import { EulaModule } from '@spog-ui/current-user/eula';
import { ChartingModule } from '@spog-ui/charting';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';

export class CustomHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    let mc = new Hammer(element, {
      touchAction: 'pan-y',
    });
    return mc;
  }
}

@NgModule({
  declarations: [LightingAppComponent],
  imports: [
    LightingRoutingModule,
    AppUpdatesModule,
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
        ...environment.httpInterceptor,
      },
    }),
    SiteSelectorModule,
    NetworkModule.forRoot(),
    CurrentUserModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    EffectsModule.forRoot([]),
    MatSliderModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    StoreModule.forRoot({} as any, { metaReducers }),
    AnalyticsModule,
    ClimateMapStateModule,
    CoreStateModule,
    AlarmsStateModule,
    Bridge485DetailsStateModule,
    Bridge485sFeatureStateModule,
    Bridge485sStateModule,
    Bridge485TemplatesStateModule,
    ClimateStateModule,
    EnergyInfoSystemStateModule,
    EquipmentMapStateModule,
    LightingStateModule,
    LightingZonesStateModule,
    MapStateModule,
    MapPositionerStateModule,
    OrganizationsStateModule,
    AdminOrganizationFeatureStateModule,
    PermissionGroupsStateModule,
    EmailGroupsStateModule,
    PowerStateModule,
    RemoteAccessStateModule,
    SiteDetailsStateModule,
    SceneViewsStateModule,
    ScenesStateModule,
    ScheduleStateModule,
    SenseMapStateModule,
    SenseStateModule,
    TriggersStateModule,
    OrganizationsFeatureStateModule,
    StoreDevtoolsModule.instrument({ connectInZone: true }),
    SuiPlatformModule,
    SuiBodyModule,
    ToolbarComponentModule,
    HttpClientModule,
    ServicesModule,
    SocketModule,
    MenuModule,
    SharedClockModule,
    RedirectModule,
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    RemoteAccessComponent,
    ScheduleUtilityRateModalComponentModule,
    LandingPageComponentModule,
    PageNotFoundComponentModule,
    PermissionsModule,
    HammerModule,
    MatAutocompleteModule,
    UnhandledExceptionsModule,
    UserInteractionModule,
    PageLoadTrackingModule,
    EulaModule,
    ChartingModule,
    SharedScenesApiEffectsModule,
  ],
  providers: [
    {
      provide: PERMISSIONS,
      deps: [SiteService],
      useFactory: getPermissions,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    provideEnabledKnobs(environment.knobs),
    provideGqlUrl(environment.apis.gql),
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig,
    },
    provideMapToolsForBrowser(),
    provideColorPaletteService(ColorPaletteGQLService),
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'fill',
      },
    },
  ],
  bootstrap: [LightingAppComponent],
})
export class LightingAppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'spog_power',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/power-icon.svg'),
    );
    iconRegistry.addSvgIcon(
      'fan',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fan.svg'),
    );
    iconRegistry.addSvgIcon(
      'fan_off',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/fan-off.svg'),
    );
    iconRegistry.addSvgIcon(
      'leaf',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/leaf.svg'),
    );
    iconRegistry.addSvgIcon(
      'gauge',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/gauge.svg'),
    );
    iconRegistry.addSvgIcon(
      'file_tree',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/file-tree.svg'),
    );
    iconRegistry.addSvgIcon(
      'download',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/download.svg'),
    );
    iconRegistry.addSvgIcon(
      'file_download_outline',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/file-download-outline.svg'),
    );
    iconRegistry.addSvgIcon(
      'spog_trigger',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trigger.svg'),
    );
    iconRegistry.addSvgIcon(
      'spog_thermostat',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/thermostat.svg'),
    );
    iconRegistry.addSvgIcon(
      'spog_thermometer',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/thermometer.svg'),
    );
    iconRegistry.addSvgIcon(
      'spog_water_percent',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/water-percent.svg'),
    );
    iconRegistry.addSvgIcon(
      'spog_clipboard_alert',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/clipboard-alert-outline.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'peak',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/peak.svg'),
    );
    iconRegistry.addSvgIcon(
      'account_eye',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/account-eye-outline.svg'),
    );
  }
}
