import { filter } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'sui-timeout-control',
  template: `
    <mat-form-field>
      <input matInput type="number" [formControl]="control" />
      <span matSuffix>minutes</span>
      <mat-error> Timeout must be between 0.5 and 480 </mat-error>
    </mat-form-field>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: TimeoutControlComponent,
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimeoutControlComponent,
      multi: true,
    },
  ],
})
export class TimeoutControlComponent implements Validator, ControlValueAccessor, OnInit {
  control = new UntypedFormControl(0, [
    Validators.min(0.5),
    Validators.max(480),
    Validators.required,
  ]);

  onChangeFn: (value: number) => void;
  onTouchedFn: () => void;

  ngOnInit() {
    this.control.valueChanges
      .pipe(filter(() => Boolean(this.onChangeFn)))
      .subscribe(value => this.onChangeFn(value));

    this.control.statusChanges
      .pipe(filter(() => Boolean(this.onTouchedFn)))
      .subscribe(() => this.onTouchedFn());
  }

  writeValue(value: number): void {
    this.control.patchValue(value, { onlySelf: true, emitEvent: false });
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  validate() {
    return this.control.valid
      ? null
      : {
          suiTimeoutControlInvalid: true,
        };
  }
}
