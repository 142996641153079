import { createAction } from '@ngrx/store';
import { ResourceGroupGQLModel } from '@spog-ui/shared/models/resource-groups';
import { ZoneInternalModel } from '@spog-ui/shared/models/zones';

export const loadSuccessAction = createAction(
  '[Departments API] Load Success',
  (resourceGroups: ResourceGroupGQLModel[]) => ({
    resourceGroups,
  }),
);

export const loadFailureAction = createAction(
  '[Departments API] Load Failure',
  (error?: any) => ({ error }),
);

export const createDepartmentSuccess = createAction(
  '[Departments API] Create Department Success',
);

export const createDepartmentFailure = createAction(
  '[Departments API] Create Department Failure',
  (error: string) => ({ error }),
);

export const loadFormDataSuccessAction = createAction(
  '[Departments API] Load Form Data Success',
  (resourceGroups: ResourceGroupGQLModel[], zones: ZoneInternalModel[]) => ({
    resourceGroups,
    zones,
  }),
);
export const loadFormDataFailureAction = createAction(
  '[Departments API] Load Form Data Failure',
  (error?: any) => ({ error }),
);

export const deleteDepartmentSuccess = createAction(
  '[Departments API] Delete Department Success',
  id => ({ id }),
);

export const deleteDepartmentFailure = createAction(
  '[Departments API] Delete Department Failure',
  (error?: any) => ({ error }),
);

export const editDepartmentSuccess = createAction(
  '[Departments API] Edit Department Success',
);

export const editDepartmentFailure = createAction(
  '[Departments API] Edit Department Failure',
  (error?: any) => ({ error }),
);
