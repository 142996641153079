import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'sui-speed-control',
  template: `
    <mat-radio-group [formControl]="behaviorSpeed">
      <mat-radio-button value="Normal">Normal</mat-radio-button>
      <mat-radio-button value="Fast">Fast</mat-radio-button>
    </mat-radio-group>
  `,
  styles: [
    `
      mat-radio-button {
        padding-right: 10px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SpeedControlComponent,
      multi: true,
    },
  ],
})
export class SpeedControlComponent implements ControlValueAccessor, OnInit {
  behaviorSpeed = new UntypedFormControl(false);

  onChangeFn: (value: number) => void;
  onTouchedFn: () => void;

  ngOnInit() {
    this.behaviorSpeed.valueChanges
      .pipe(filter(() => Boolean(this.onChangeFn)))
      .subscribe(value => this.onChangeFn(value));

    this.behaviorSpeed.statusChanges
      .pipe(filter(() => Boolean(this.onTouchedFn)))
      .subscribe(() => this.onTouchedFn());
  }

  writeValue(value: number): void {
    this.behaviorSpeed.patchValue(value, { onlySelf: true, emitEvent: false });
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
}
