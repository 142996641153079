import { createAction } from '@ngrx/store';
import { UserInternalModel } from '@spog-ui/shared/models/users';
import { ApiErrorModel } from '@spog-ui/shared/models/errors';

export const loadModelsSuccessAction = createAction(
  '[Users Page / API] Load Models Success',
  (users: UserInternalModel[]) => ({ users }),
);
export const loadModelsFailureAction = createAction(
  '[Users Page / API] Load Models Failure',
  (error: { errors: ApiErrorModel[] }) => ({ error }),
);
export const getUserSitesSuccessAction = createAction(
  '[Admin / Users Page API] Get User Sites Success',
  (sites: string[]) => ({ sites }),
);
export const getUserSitesFailureAction = createAction(
  '[Admin / Users Page API] Get User Sites Failure',
  (error: any) => ({ error }),
);
