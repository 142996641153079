import { defer, Observable } from 'rxjs';
import { Injectable, Provider } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromptComponent } from './prompt.component';
import { defaultPromptOptions, PromptOptions } from './models';
import { map } from 'rxjs/operators';

@Injectable()
export class PromptService {
  constructor(private dialog: MatDialog) {}

  open(options?: Partial<PromptOptions>): Observable<boolean> {
    const resolvedOptions = resolvePromptConfig(options);

    return defer(() => {
      const ref = this.dialog.open(PromptComponent, { data: resolvedOptions });

      return ref.afterClosed().pipe(map(Boolean));
    });
  }
}

export function resolvePromptConfig(options: Partial<PromptOptions> = {}): PromptOptions {
  return { ...defaultPromptOptions, ...options };
}

export const PROMPT_SERVICE_PROVIDERS: Provider[] = [PromptService];
