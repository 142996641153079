import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  MpStepOneActions,
  MpStepThreeActions,
  MpStepTwoFreeformActions,
  MpStepTwoGridActions,
} from '@spog-ui/map-positioner/actions';
import { PositionModel } from '@spog-ui/shared/models/map-positioners';

export interface Shape extends EntityState<PositionModel> {
  nextId: number;
}

export const adapter = createEntityAdapter<PositionModel>();

export const initialState: Shape = adapter.getInitialState({
  nextId: 0,
});

export const reducer = createReducer(
  initialState,
  on(
    MpStepOneActions.enterAction,
    MpStepTwoGridActions.enterAction,
    MpStepThreeActions.savePositionsAction,
    (): Shape => {
      return initialState;
    },
  ),
  on(
    MpStepTwoFreeformActions.createPositionAction,
    (state, action): Shape => {
      return adapter.addOne(
        {
          id: `${state.nextId}`,
          floorPlanX: action.floorPlanX,
          floorPlanY: action.floorPlanY,
          thingId: null,
        },
        {
          ...state,
          nextId: state.nextId + 1,
        },
      );
    },
  ),
  on(
    MpStepTwoFreeformActions.removePositionAction,
    (state, action): Shape => {
      return adapter.removeOne(action.positionId, state);
    },
  ),
  on(
    MpStepThreeActions.assignThingToPositionAction,
    (state, action): Shape => {
      return adapter.updateOne(
        { id: action.positionId, changes: { thingId: action.thingId } },
        state,
      );
    },
  ),
);

export const { selectAll } = adapter.getSelectors();
