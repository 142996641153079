import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AvailablePermissionsService } from '../services/available-permissions.service';
import { switchMap, take, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectSiteURLPrefix } from '@spog-ui/shared/state/core';

@Injectable({
  providedIn: 'root',
})
export class IfUserCanGuard implements CanActivate {
  constructor(
    private readonly availablePermissions: AvailablePermissionsService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const permissions: string[] = route.data.permissions;

    if (!permissions || !permissions.length) {
      return of(true);
    }

    return this.availablePermissions.includes(permissions).pipe(
      switchMap(hasPermissions => {
        if (!hasPermissions) {
          return this.store.select(selectSiteURLPrefix).pipe(
            take(1),
            map(urlPrefix => {
              return this.router.createUrlTree([urlPrefix]);
            }),
          );
        }

        return of(true);
      }),
    );
  }
}
