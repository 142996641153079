import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  ButtonComponent,
  SuiCharmFilterModule,
  SuiExtendedFabModule,
  SuiSearchModule,
  SuiSpinnerModule,
  SuiPageModule,
} from '@spog-ui/shared/components';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { IndieSensorsPageNoResultsComponentModule } from '../indie-sensors-page-no-results';
import { NoIndieSensorsComponentModule } from '../no-indie-sensors';
import { IndieSensorsPageListComponentModule } from '../indie-sensors-page-list';
import { IndieSensorsPageComponent } from './indie-sensors-page.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SuiPageModule,
    SuiSearchModule,
    SuiSpinnerModule,
    ButtonComponent,
    SuiExtendedFabModule,
    SuiCharmFilterModule,
    IndieSensorsPageListComponentModule,
    IndieSensorsPageNoResultsComponentModule,
    NoIndieSensorsComponentModule,
    PermissionsModule,
  ],
  declarations: [IndieSensorsPageComponent],
})
export class IndieSensorsPageComponentModule {}
