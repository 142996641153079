import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NavFooterComponent } from './nav-footer.component';
import {
  AppUpdateCheckComponentModule,
  AppUpdateComponentModule,
} from '@spog-ui/app-updates';
import { AdminLinkComponentModule } from '../admin-link';
import { RefreshButtonComponentModule } from '../refresh-button';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    MatProgressSpinnerModule,
    AppUpdateComponentModule,
    AppUpdateCheckComponentModule,
    AdminLinkComponentModule,
    RefreshButtonComponentModule,
  ],
  declarations: [NavFooterComponent],
  exports: [NavFooterComponent],
})
export class NavFooterComponentModule {}
