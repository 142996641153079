import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoneListComponent } from './zone-list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ZoneListComponent],
  exports: [ZoneListComponent],
})
export class ZoneListComponentModule {}
