import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sui-behavior-license-message',
  template: `
    <div class="container">
      <div class="licenseLink">
        No subscription found for this feature.
        <p></p>
        Please submit a
        <a href="https://www.synapsewireless.com/software-license-request" target="_blank"
          >request</a
        >
        to obtain a subscription.
      </div>
    </div>
  `,
  styles: [
    `
      .container {
        display: flex;
        width: 100%;
        padding: 16px;
        background-color: var(--color-warn-500);
        color: var(--color-warn-contrast-500);
      }

      .licenseLink {
        padding-top: 16px;
      }

      a:active,
      a:hover,
      a:link,
      a:visited {
        color: var(--color-contrast-500);
        font-weight: 500px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BehaviorLicenseMessageComponent {}
