import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ClipboardService {
  private span: HTMLSpanElement;

  constructor() {
    this.span = document.createElement('span');
    this.span.style.whiteSpace = 'pre';
    this.span.style.position = 'fixed';
    this.span.style.top = '-9999px';
    this.span.style.left = '-9999px';

    document.body.appendChild(this.span);
  }

  copy(content: string): boolean {
    const selection = window.getSelection();
    const range = document.createRange();

    if (!selection) return false;

    this.span.textContent = content;
    selection.removeAllRanges();
    range.selectNode(this.span);
    selection.addRange(range);

    try {
      document.execCommand('copy');

      return true;
    } catch (e) {
      return false;
    }
  }
}
