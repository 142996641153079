import {
  getIndieSensorUnits,
  IndustrialSensorCustomDataType,
  IndustrialSensorDataType,
  IndustrialSensorDefinedDataType,
} from '@spog-ui/shared/models/indie-sensors';
import { Bridge485TemplateInternalModel } from '@spog-ui/shared/models/bridge-485-templates';

export interface Bridge485TemplateViewModel {
  id: string;
  name: string;
  sensors: {
    name: string;
    units: string;
    type: 'power' | 'other';
  }[];
}

export function toBridge485TemplateViewModel(
  internal: Bridge485TemplateInternalModel,
): Bridge485TemplateViewModel {
  return {
    id: internal.id,
    name: internal.name,
    sensors: internal.metrics.map(metric => ({
      name: metric.name,
      type: isPowerType(metric.dataType) ? 'power' : 'other',
      units: getIndieSensorUnits(metric.dataType),
    })),
  };
}

function isPowerType(type: IndustrialSensorDataType): boolean {
  return (
    (type as IndustrialSensorDefinedDataType).type === 'POWER' ||
    (type as IndustrialSensorCustomDataType).units === 'W'
  );
}
