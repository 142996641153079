export function findBoundingBox<
  V extends {
    floorPlanX: number;
    floorPlanY: number;
  },
>(items: V[]): { x: number; y: number; width: number; height: number } {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  items.forEach(item => {
    minX = Math.min(minX, item.floorPlanX);
    minY = Math.min(minY, item.floorPlanY);
    maxX = Math.max(maxX, item.floorPlanX);
    maxY = Math.max(maxY, item.floorPlanY);
  });

  const width = maxX - minX;
  const height = maxY - minY;

  return {
    x: minX,
    y: minY,
    width,
    height,
  };
}
