import { LightModelFragment as LightGQLModel } from '@spog-ui/graphql/types';
import { SuiAlarmModel } from '@shared/alarms';

export { LightGQLModel };

type PositionedLightFields = {
  floorPlanId: string;
  floorPlanX: number;
  floorPlanY: number;
};

type UnpositionedLightFields = {
  floorPlanId: null;
  floorPlanX: null;
  floorPlanY: null;
};

type AssetDatum = {
  key: string;
  value: string;
};

type AssetData = AssetDatum[];

export type LightInternalModel = {
  id: string;
  name: string;
  /**
   * @Todo: Why is it nullable? Is it really necessary?
   * https://synapse-wireless.atlassian.net/browse/SS-31751
   */
  level?: number | null;
  controllerId: string;
  snapaddr: string;
  controllerType: string;
  deviceAssetData: AssetData;
  siteControllerId: string;
  siteControllerName: string;
} & (PositionedLightFields | UnpositionedLightFields);

export interface LightLevel {
  id: string;
  level: number;
}

export type PositionedLightModel = LightInternalModel &
  PositionedLightFields & { level: number };

export interface LightViewModel {
  id: string;
  name: string;
  floorPlanId: string;
  floorPlanX: number;
  floorPlanY: number;
  zones: {
    [id: string]: boolean;
  };
  level: number;
  snapaddr: string;
  alarms: SuiAlarmModel[];
  controllerId: string;
  controllerType: string;
  deviceAssetData: AssetData;
  siteControllerId: string;
  siteControllerName: string;
}

export interface LightControlViewModel {
  id: string;
  name: string;
  level: number;
}

export interface LightLegendViewModel {
  ok: number;
  alarmed: number;
}

export interface LightLegendFilterModel {
  filteringOk: boolean;
  filteringAlarmed: boolean;
}

export interface LightWSModel {
  id: string;
  controller_id: string;
  demand_response_exclusion: boolean;
  description: string;
  floor_plan_id: any;
  floor_plan_x: any;
  floor_plan_y: any;
  high_end_trim: number;
  level: number;
  mode: string | null;
  name: string;
  power_on_level: number;
  removable: boolean;
}

export function toLightInternalModelFromWS(
  ws: LightWSModel,
): Partial<LightInternalModel> {
  return {
    id: ws.id,
    name: ws.name,
    controllerId: ws.controller_id,
    floorPlanId: ws.floor_plan_id,
    floorPlanX: ws.floor_plan_x ? ws.floor_plan_x : null,
    floorPlanY: ws.floor_plan_y ? ws.floor_plan_y : null,
  };
}

export function toLightInternalModelFromGQL(gql: LightGQLModel): LightInternalModel {
  const position = gql.floorPlanLocation;

  if (position) {
    return {
      id: gql.id,
      name: gql.name,
      level: gql.level,
      controllerId: gql.controller.id,
      snapaddr: gql.controller.snapaddr,
      controllerType: gql.controller.type,
      deviceAssetData: gql.controller.fixtureAssetData
        ? JSON.parse(gql.controller.fixtureAssetData)
        : [],
      floorPlanId: position.floorPlan.id,
      floorPlanX: position.x,
      floorPlanY: position.y,
      siteControllerId: gql.siteController!.id,
      siteControllerName: gql.siteController!.name,
    };
  }

  return {
    id: gql.id,
    name: gql.name,
    level: gql.level ?? 0,
    controllerId: gql.controller.id,
    snapaddr: gql.controller.snapaddr,
    controllerType: gql.controller.type,
    deviceAssetData: gql.controller.fixtureAssetData
      ? JSON.parse(gql.controller.fixtureAssetData)
      : [],
    floorPlanId: null,
    floorPlanX: null,
    floorPlanY: null,
    siteControllerId: gql.siteController!.id,
    siteControllerName: gql.siteController!.name,
  };
}

export function toLightGQLModelFromInternal(internal: LightInternalModel): LightGQLModel {
  const floorPlanLocation = internal.floorPlanId
    ? {
        floorPlan: { id: internal.floorPlanId },
        x: internal.floorPlanX,
        y: internal.floorPlanY,
      }
    : null;

  return {
    id: internal.id,
    name: internal.name,
    controller: {
      id: internal.controllerId,
      snapaddr: internal.snapaddr,
      type: internal.controllerType,
      fixtureAssetData: JSON.stringify(internal.deviceAssetData),
    },
    floorPlanLocation,
    level: internal.level,
    siteController: {
      id: internal.siteControllerId,
      name: internal.siteControllerName,
    },
  };
}

export function getLightLegendViewModel(lights: LightViewModel[]): LightLegendViewModel {
  return lights.reduce(
    (counts, light) => {
      if (light.alarms.length > 0) {
        ++counts.alarmed;
      } else {
        ++counts.ok;
      }

      return counts;
    },
    { alarmed: 0, ok: 0 },
  );
}

export function toLightControlViewModelFromSui(
  internal: LightSuiModel,
): LightControlViewModel {
  return {
    id: internal.id,
    name: internal.name,
    level: internal.level!,
  };
}

export function toLightViewModel(
  light: PositionedLightModel,
  alarms: SuiAlarmModel[],
  zoneSetTablesByLightId: { [lightId: string]: { [zoneId: string]: true } },
): LightViewModel {
  return {
    ...light,

    level: light.level,
    alarms,
    floorPlanId: light.floorPlanId,
    floorPlanX: light.floorPlanX,
    floorPlanY: light.floorPlanY,
    zones: zoneSetTablesByLightId[light.id] || {},
    controllerId: light.controllerId,
    snapaddr: light.snapaddr,
    controllerType: light.controllerType,
    deviceAssetData: light.deviceAssetData,
    siteControllerName: light.siteControllerName,
    siteControllerId: light.siteControllerId,
  };
}

export interface LightSuiModel {
  id: string;
  name: string;
  level: number;
}
