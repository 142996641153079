import { Observable, Scheduler, timer } from 'rxjs';
import { scan, delayWhen } from 'rxjs/operators';
import { Injectable, InjectionToken, Inject, Optional } from '@angular/core';

export const BACKOFF_SCHEDULER = new InjectionToken<Scheduler>('WebSocket Scheduler');

@Injectable()
export class Backoff {
  constructor(
    @Inject(BACKOFF_SCHEDULER)
    @Optional()
    private scheduler: Scheduler,
  ) {}

  viaFibonacci(
    errors$: Observable<{ a: number; b: number; delay: number }>,
    retryPeriod: number,
    maxDelay: number,
  ) {
    return errors$.pipe(
      scan<
        { a: number; b: number; delay: number },
        { a: number; b: number; delay: number }
      >(
        ({ a, b }) => {
          const next = a + b;
          const nextRetryDelay = next * retryPeriod;

          if (nextRetryDelay > maxDelay) {
            return { a, b, delay: maxDelay };
          }

          return {
            a: b,
            b: next,
            delay: nextRetryDelay,
          };
        },
        { a: 0, b: 1, delay: retryPeriod },
      ),
      delayWhen(({ delay }) => timer(delay, this.scheduler)),
    );
  }
}
