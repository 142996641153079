import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromptOptions } from './models';

@Component({
  selector: 'sui-prompt',
  template: `
    <h3 mat-dialog-title>{{ options.title }}</h3>

    <mat-dialog-content>
      {{ options.description }}
    </mat-dialog-content>

    <mat-dialog-actions>
      <button
        suiButton
        *ngIf="options.cancelLabel"
        [attr.color]="options.cancelColor"
        (click)="cancel()"
      >
        {{ options.cancelLabel }}
      </button>
      <button
        suiButton
        *ngIf="options.confirmLabel"
        [attr.data-testid]="options.confirmButtonTestId"
        [attr.color]="options.confirmColor"
        (click)="confirm()"
      >
        {{ options.confirmLabel }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        max-width: 500px;
        padding: 24px;
      }

      mat-dialog-actions {
        display: flex;
        justify-content: flex-end;
      }

      [suiButton] {
        padding: 0;
      }
    `,
  ],
})
export class PromptComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public options: PromptOptions,
    private ref: MatDialogRef<PromptComponent>,
  ) {}

  cancel() {
    this.ref.close(false);
  }

  confirm() {
    this.ref.close(true);
  }
}

export const PROMPT_DIRECTIVES = [PromptComponent];
