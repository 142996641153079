import { Subscription } from 'rxjs';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';
import { ZoneBehaviorControlListComponent } from './zone-behavior-control-list.component';

@Directive({
  selector: 'scn-zone-behavior-control-list',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ZoneBehaviorControlListValidatorDirective,
    },
  ],
})
export class ZoneBehaviorControlListValidatorDirective
  implements Validator, OnInit, OnDestroy
{
  onValidatorChange: () => void;
  isValid = true;
  subscription: Subscription;

  constructor(private control: ZoneBehaviorControlListComponent) {}

  ngOnInit() {
    this.subscription = this.control.formArray.statusChanges.subscribe(() => {
      this.isValid = this.control.formArray.valid;

      if (this.onValidatorChange) {
        this.onValidatorChange();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  validate(): { [key: string]: boolean } | null {
    return this.isValid
      ? null
      : {
          suiZoneBehaviorControlListInvalid: true,
        };
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }
}
