import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as CoreState from './core.state';

@NgModule({
  imports: [
    StoreModule.forFeature(CoreState.STATE_KEY, CoreState.reducers, {
      metaReducers: CoreState.metaReducers,
    }),
  ],
})
export class CoreStateModule {}
