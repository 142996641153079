import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BehaviorInternalModel, BehaviorType } from '@spog-ui/shared/models/behaviors';
import {
  ZoneBehaviorFormModel,
  ZoneInternalModel,
  ZoneApplication,
} from '@spog-ui/shared/models/zones';

@Component({
  selector: 'scn-zone-behavior-control-list',
  template: `
    @for (control of formArray.controls; track control; let i = $index) {
      <scn-zone-behavior-control
        [application]="application"
        [formControl]="control"
        [zones]="zones"
        [behaviors]="behaviors"
        [open]="expanded === i"
        (remove)="removeControl(i)"
        (expand)="expanded = i"
        (closed)="expanded = -1"
        >
      </scn-zone-behavior-control>
    }
    
    <sui-card>
      @if (formArray.length === 0) {
        <sui-card-body>
          <p class="mat-body-1">
            {{
            application === eApplication.CLIMATE
            ? 'Routines can apply control modes to zones when a routine is activated.'
            : 'Scenes can apply behaviors to zones when a scene is activated.'
            }}
          </p>
        </sui-card-body>
      }
      <sui-card-footer>
        <button
          suiButton
          color="primary"
          type="button"
          (click)="addControl()"
          [disabled]="cannotAddControl"
          >
          {{ application === eApplication.CLIMATE ? 'Add Control Mode' : 'Add Behavior' }}
        </button>
      </sui-card-footer>
      <ng-content></ng-content>
    </sui-card>
    `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZoneBehaviorControlListComponent,
    },
  ],
})
export class ZoneBehaviorControlListComponent implements ControlValueAccessor {
  eApplication = ZoneApplication;

  @Input() application: ZoneApplication = ZoneApplication.LIGHTING;
  @Input() zones: ZoneInternalModel[] = [];
  @Input() behaviors: BehaviorInternalModel[] = [];
  expanded = -1;
  formArray = new UntypedFormArray([]);

  removeControl(index: number) {
    this.formArray.removeAt(index);
    this.expanded = -1;
  }

  createControl() {
    const behaviorId =
      this.behaviors && this.behaviors.length === 1
        ? this.behaviors[0].id
        : BehaviorType.None;

    this.formArray.push(
      new UntypedFormControl({
        behaviorId,
        behaviorParameters: null,
        zones: [],
      }),
    );
  }

  addControl() {
    this.createControl();
    this.expanded = this.formArray.controls.length - 1;
  }

  writeValue(value: ZoneBehaviorFormModel[]): void {
    while (value.length > this.formArray.length) {
      this.createControl();
    }

    while (value.length < this.formArray.length) {
      this.removeControl(this.formArray.length - 1);
    }

    this.formArray.setValue(value, { onlySelf: true, emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this.formArray.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.formArray.statusChanges.subscribe(() => fn());
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formArray.disable() : this.formArray.enable();
  }

  get cannotAddControl() {
    return this.formArray.controls.some(control =>
      control.hasError('suiZoneBehaviorControlInvalid'),
    );
  }
}

export interface DuplicateZoneValidationError {
  suiNoDuplicateZoneInScene: ZoneInternalModel[];
}

type SceneZoneErrors = DuplicateZoneValidationError | null;

export function validateZoneConstraints(c: AbstractControl): SceneZoneErrors {
  const value: ZoneBehaviorFormModel[] = c.value;
  const uniqueZones = new Set<string>();
  const duplicateZones = new Set<ZoneInternalModel>();

  value
    .flatMap((zoneBehavior: ZoneBehaviorFormModel) => zoneBehavior.zones)
    .forEach((zone: ZoneInternalModel) => {
      if (uniqueZones.has(zone.id)) {
        duplicateZones.add(zone);
      } else {
        uniqueZones.add(zone.id);
      }
    });

  if (duplicateZones.size > 0) {
    return {
      suiNoDuplicateZoneInScene: Array.from(duplicateZones.values()),
    };
  }

  return null;
}
