import {
  catchError,
  concatMap,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PromptService } from '@sui/prompt';
import {
  selectAddScenePageApplication,
  selectEditScenePageApplication,
} from '@spog-ui/shared/state/scenes';
import { selectAllScenes, selectAllSequenceScenes } from '@spog-ui/shared/state/core';
import {
  AddScenePageActions,
  EditScenePageActions,
  ScenesApiActions,
  ScenesPageActions,
  AddSequenceScenePageActions,
  EditSequenceScenePageActions,
} from '@spog-ui/scenes/actions';

import { ScenesService } from '../services';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { defer, of } from 'rxjs';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { SceneApplication } from '@spog-ui/shared/models/scenes';

@Injectable()
export class ScenesPageEffects {
  addScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddScenePageActions.addSceneAction),
      withLatestFrom(defer(() => this.store.select(selectAddScenePageApplication))),
      /**
       * @TODO David Pierce
       * Consider refactoring `application` into the SceneZoneApiModel.
       */
      concatMap(([action, application]) => {
        return this.scenesService
          .addScene(action.scene, application ?? SceneApplication.LIGHTING)
          .pipe(
            map(scene => ScenesApiActions.addSceneSuccessAction(scene)),
            catchError(errorResponse => {
              return of(
                ScenesApiActions.addSceneFailureAction(
                  retrieveErrorMessage(errorResponse),
                ),
              );
            }),
          );
      }),
    ),
  );

  editScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditScenePageActions.editSceneAction),
      withLatestFrom(defer(() => this.store.select(selectEditScenePageApplication))),
      concatMap(([{ scene }, application]) =>
        this.scenesService.editScene(scene).pipe(
          map(scene => ScenesApiActions.editSceneSuccessAction(scene)),
          catchError(errorResponse =>
            of(
              ScenesApiActions.editSceneFailureAction(
                retrieveErrorMessage(errorResponse),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  redirectToScenesPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ScenesApiActions.addSceneSuccessAction,
          ScenesApiActions.editSceneSuccessAction,
          ScenesApiActions.addSequenceSceneSuccessAction,
          ScenesApiActions.editSequenceSceneSuccessAction,
        ),
        tap(() => this.location.back()),
      ),
    { dispatch: false },
  );

  duplicateScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenesPageActions.duplicateSceneAction),
      map(action => action.scene),
      withLatestFrom(defer(() => this.store.select(selectAllScenes))),
      concatMap(([scene, scenes]) =>
        this.scenesService.duplicateScene(scene, scenes).pipe(
          map(scene => ScenesApiActions.duplicateSceneSuccessAction(scene)),
          catchError(errorResponse =>
            of(
              ScenesApiActions.duplicateSceneFailureAction(
                retrieveErrorMessage(errorResponse),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions<ScenesPageActions.Union>,
    readonly promptService: PromptService,
    readonly router: Router,
    readonly scenesService: ScenesService,
    private store: Store,
    readonly location: Location,
  ) {}

  confirmDeleteScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenesPageActions.Types.DeleteScene),
      mergeMap(action => {
        let options = {
          title: 'Delete Scene?',
          description:
            'Deleting this scene will permanently delete all corresponding scheduled events.',
          cancelLabel: 'Cancel',
          confirmLabel: 'Delete Scene',
          confirmColor: 'warn',
        };
        if (action.application === SceneApplication.CLIMATE) {
          options = {
            title: 'Delete Routine?',
            description:
              'Deleting this routine will permanently delete all corresponding scheduled events.',
            cancelLabel: 'Cancel',
            confirmLabel: 'Delete Routine',
            confirmColor: 'warn',
          };
        }
        return this.promptService
          .open(options)
          .pipe(
            map(confirmed =>
              confirmed
                ? ScenesPageActions.deleteSceneConfirmAction(
                    action.application,
                    action.sceneId,
                  )
                : ScenesPageActions.deleteSceneCancelAction(),
            ),
          );
      }),
    ),
  );

  addSequenceScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddSequenceScenePageActions.addSequenceSceneAction),
      concatMap(action => {
        return this.scenesService.addSequenceScene(action.sequenceScene).pipe(
          map(sequenceScene =>
            ScenesApiActions.addSequenceSceneSuccessAction(sequenceScene),
          ),
          catchError(errorResponse => {
            return of(
              ScenesApiActions.addSequenceSceneFailureAction(
                retrieveErrorMessage(errorResponse),
              ),
            );
          }),
        );
      }),
    ),
  );

  editSequenceScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditSequenceScenePageActions.editSequenceSceneAction),
      concatMap(({ sequenceScene }) =>
        this.scenesService.editSequenceScene(sequenceScene).pipe(
          map(scene => ScenesApiActions.editSequenceSceneSuccessAction(sequenceScene)),
          catchError(errorResponse =>
            of(
              ScenesApiActions.editSequenceSceneFailureAction(
                retrieveErrorMessage(errorResponse),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  duplicateSequenceScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenesPageActions.duplicateSequenceSceneAction),
      map(action => action.sequenceScene),
      withLatestFrom(defer(() => this.store.select(selectAllSequenceScenes))),
      concatMap(([sequenceScene, sequenceScenes]) =>
        this.scenesService.duplicateSequenceScene(sequenceScene, sequenceScenes).pipe(
          map(scene =>
            ScenesApiActions.duplicateSequenceSceneSuccessAction(sequenceScene),
          ),
          catchError(errorResponse =>
            of(
              ScenesApiActions.duplicateSequenceSceneFailureAction(
                retrieveErrorMessage(errorResponse),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  confirmDeleteSequenceScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScenesPageActions.deleteSequenceSceneAction),
      mergeMap(action => {
        const options = {
          title: 'Delete Sequence Scene?',
          description:
            'Deleting this sequence scene will permanently delete all corresponding scheduled events.',
          cancelLabel: 'Cancel',
          confirmLabel: 'Delete',
          confirmColor: 'warn',
        };

        return this.promptService
          .open(options)
          .pipe(
            map(confirmed =>
              confirmed
                ? ScenesPageActions.deleteSequenceSceneConfirmAction(
                    action.sequenceSceneId,
                  )
                : ScenesPageActions.deleteSequenceSceneCancelAction(),
            ),
          );
      }),
    ),
  );
}
