import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'sui-icon',
    template: ` <mat-icon [attr.aria-label]="label" [inline]="true">{{ icon }}</mat-icon> `,
    standalone: true,
    imports: [MatIcon],
})
export class IconComponent {
  @Input()
  set = 'fa';
  @Input()
  icon = '';
  @Input()
  label: string | null | undefined;

  get fullIconClass(): string {
    return `${this.set} ${this.set}-${this.icon}`;
  }
}

export const ICON_DIRECTIVES = [IconComponent];
