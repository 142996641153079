import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AlarmsPageActions } from '@spog-ui/alarms/actions';
import { ClimateMapPageActions } from '@spog-ui/climate-map-layer/actions';
import { ClimateZonesPageActions } from '@spog-ui/climate-zones/actions';
import {
  AddDepartmentPageActions,
  DepartmentsPageActions,
  EditDepartmentPageActions,
} from '@spog-ui/departments/actions';
import {
  AddEquipmentPageActions,
  EditEquipmentPageActions,
  EquipmentPageActions,
  EquipmentProfilePageActions,
} from '@spog-ui/equipment/actions';
import {
  AddIndieSensorPage,
  EditIndieSensorPage,
  IndieSensorsPage,
} from '@spog-ui/indie-sensors/actions';
import { LightMapLayerActions } from '@spog-ui/light-map-layer/actions';
import { MapActions } from '@spog-ui/map/actions';
import { LightZonesPageActions } from '@spog-ui/modules/light-zones-actions';
import { PowerInsightsPageActions } from '@spog-ui/power-insights/actions';
import { PowerActions } from '@spog-ui/power/actions';
import {
  AddScenePageActions,
  EditScenePageActions,
  ScenesPageActions,
  AddSequenceScenePageActions,
  EditSequenceScenePageActions,
} from '@spog-ui/scenes/actions';
import {
  AddScheduledEventPageActions,
  EditScheduledEventPageActions,
  SchedulePageActions,
} from '@spog-ui/schedule/actions';
import { SenseMapLayerActions } from '@spog-ui/sense-map-layer/actions';
import {
  logEventAction,
  MetricsType,
} from '@spog-ui/shared/state/user-interactions/core';
import { SiteControllerPage } from '@spog-ui/site-controllers/actions';
import {
  AddThermostatPageActions,
  EditThermostatPageActions,
  ThermostatsPageActions,
} from '@spog-ui/thermostats/actions';
import {
  AddTriggerPageActions,
  EditTriggerPageActions,
  TriggersPageActions,
} from '@spog-ui/triggers/actions';
import {
  AddUtilityServicePageActions,
  EditUtilityServicePageActions,
  UtilityServicesPageActions,
} from '@spog-ui/utility-services/actions';
import {
  PermissionGroupsPageActions,
  EmailGroupsPageActions,
  OrgAdminSitesPageActions,
  OrgAdminUsersPageActions,
  AddPermissionGroupPageActions,
  EditPermissionGroupPageActions,
  AddEmailGroupPageActions,
  EditEmailGroupPageActions,
  QuickSiteSetupPageActions,
} from '@spog-ui/admin-organization/state';
import { ImportPageActions } from '@spog-ui/import/actions';
import { map } from 'rxjs/operators';
import { ChartingPageActions } from '@spog-ui/charting/actions';

@Injectable()
export class PageHitTrackingEffects {
  constructor(private actions$: Actions) {}

  logPageHits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AlarmsPageActions.enterAction,
        ClimateZonesPageActions.enterAction,
        DepartmentsPageActions.enterAction,
        AddDepartmentPageActions.enterAction,
        EditDepartmentPageActions.enterAction,
        EquipmentPageActions.enterAction,
        AddEquipmentPageActions.enterAction,
        EditEquipmentPageActions.enterAction,
        EquipmentProfilePageActions.enterAction,
        IndieSensorsPage.enterAction,
        AddIndieSensorPage.enterAction,
        EditIndieSensorPage.enterAction,
        LightZonesPageActions.enterAction,
        MapActions.enterAction,
        LightMapLayerActions.enterAction,
        SenseMapLayerActions.enterAction,
        ClimateMapPageActions.enterAction,
        PowerActions.enterAction,
        PowerInsightsPageActions.enterAction,
        ScenesPageActions.enterAction,
        AddScenePageActions.enterAction,
        EditScenePageActions.enterAction,
        AddSequenceScenePageActions.enterAction,
        EditSequenceScenePageActions.enterAction,
        SchedulePageActions.enterAction,
        AddScheduledEventPageActions.enterAction,
        EditScheduledEventPageActions.enterAction,
        SiteControllerPage.enterAction,
        ThermostatsPageActions.enterAction,
        AddThermostatPageActions.enterAction,
        EditThermostatPageActions.enterAction,
        TriggersPageActions.enterAction,
        AddTriggerPageActions.enterAction,
        EditTriggerPageActions.enterAction,
        UtilityServicesPageActions.enterAction,
        AddUtilityServicePageActions.enterAction,
        EditUtilityServicePageActions.enterAction,
        PermissionGroupsPageActions.enterAction,
        OrgAdminSitesPageActions.enterAction,
        OrgAdminUsersPageActions.enterAction,
        AddPermissionGroupPageActions.enterAction,
        EditPermissionGroupPageActions.enterAction,
        EmailGroupsPageActions.enterAction,
        AddEmailGroupPageActions.enterAction,
        EditEmailGroupPageActions.enterAction,
        QuickSiteSetupPageActions.enterAction,
        ImportPageActions.enterAction,
        ChartingPageActions.enterAction
      ),
      map(({ type }) =>
        logEventAction({
          message: `Page hit: ${type}`,
          detail: type,
          metricsType: MetricsType.pageHit,
        }),
      ),
    ),
  );
}
