import { createAction } from '@ngrx/store';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';

export const enterAction = createAction('[Site Controller Admin Page] Enter');
export const addAction = createAction('[Site Controllers Admin Page] Add', () => {
  return { redirectUrl: '/admin/site-controllers' };
});
export const editAction = createAction('[Site Controllers Admin Page] Edit', () => {
  return { redirectUrl: '/admin/site-controllers' };
});
export const deleteAction = createAction(
  '[Site Controllers Admin Page] Delete',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);
export const deleteConfirmAction = createAction(
  '[Site Controllers Admin Page] Confirm Delete',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);
export const deleteCancelAction = createAction('[Site Controllers Page] Cancel Delete');
export const initResourcesAction = createAction(
  '[Site Controllers Admin Page] Init Resources',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);
export const initResourcesConfirmAction = createAction(
  '[Site Controllers Admin Page] Confirm Init Resources',
  (siteController: SiteControllerInternalModel) => {
    return { siteController };
  },
);
export const initResourcesCancelAction = createAction(
  '[Site Controllers Admin Page] Cancel Init Resources',
);

export const paginateAction = createAction(
  '[Site Controllers Admin Page] Paginate',
  (pageIndex: number, pageSize: number) => {
    return { pageIndex, pageSize };
  },
);

export const filterAction = createAction(
  '[Site Controllers Admin Page] Filter',
  (searchTerm: string) => {
    return { searchTerm };
  },
);
