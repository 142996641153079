import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-photo-and-switch',
  template: `
    <div [formGroup]="form">
      <p class="bfDescription">
        <strong>Manual Only with Daylighting</strong> - The switch controls the lights in
        the zone only when the photocell detects darkness.
      </p>

      <div class="bfForm">
        <div class="bfChunk">
          <p class="bfLabel">If daylight for a period of:</p>
          <sui-timeout-control
            formControlName="photocellHighTimeout"
          ></sui-timeout-control>

          <p class="bfLabel">Disable switch control and set dimming level to:</p>
          <sui-dimmer-control formControlName="baselineLevel"></sui-dimmer-control>

          <p class="bfLabel">Enable switch if dark for a period of:</p>
          <sui-timeout-control
            formControlName="photocellLowTimeout"
          ></sui-timeout-control>
        </div>
        <div class="bfChunk">
          <p class="bfLabel">
            When switched on and photocell does not detect day, set dimming level to:
          </p>
          <sui-dimmer-control formControlName="switchOnLevel"></sui-dimmer-control>

          <p class="bfLabel">
            When switched off and photocell does not detect day, set dimming level to:
          </p>
          <sui-dimmer-control formControlName="switchOffLevel"></sui-dimmer-control>
        </div>
      </div>
    </div>
  `,
})
export class BFPhotocellAndSwitchComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    baselineLevel: new UntypedFormControl(0),
    photocellHighTimeout: new UntypedFormControl(0.5),
    photocellLowTimeout: new UntypedFormControl(0.5),
    switchOnLevel: new UntypedFormControl(80),
    switchOffLevel: new UntypedFormControl(0),
  });
}
