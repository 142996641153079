import { createAction } from '@ngrx/store';
import { SuiAlarmModel } from '@shared/alarms';
import { PlacedThing } from '@spog-ui/shared/models/floor-plans';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';
import {
  ThermostatAlarmWSModel,
  ThermostatControlModeInternalModel,
} from '@spog-ui/shared/models/thermostats';
import { ActiveSequenceSceneWSModel } from '@spog-ui/shared/models/active-sequence-scenes';
import { TriggerStateChangeWSModel } from '@spog-ui/shared/models/triggers';
import { CrudNotification } from '@spog-ui/shared/models/websocket-notifications';
import { BaselineType } from '@spog-ui/graphql/types';

export const socketCloseAction = createAction('[Socket] Close');

export const updateEntitiesAction = createAction(
  '[Socket] Update Entities',
  (updates: CrudNotification<unknown>[]) => {
    return { updates };
  },
);
export const updateEnergyBaselineAction = createAction(
  '[Socket] Update Energy Baseline',
  (baseline: number, baselineType: BaselineType) => {
    return {
      baseline,
      baselineType,
    };
  },
);
export const lightControlAction = createAction(
  '[Socket] Light Control',
  (lightId: string, intendedLightLevel: number) => {
    return { lightId, intendedLightLevel };
  },
);
export const zoneControlAction = createAction(
  '[Socket] Zone Control',
  (zoneId: string, level: number) => {
    return { zoneId, level };
  },
);
export const updateActiveSequenceSceneAction = createAction(
  '[Socket] Update Active Sequence Scene',
  (activeSequenceScene: ActiveSequenceSceneWSModel) => {
    return { activeSequenceScene };
  },
);
export const deleteActiveSequenceSceneAction = createAction(
  '[Socket] Delete Active Sequence Scene',
  (activeSequenceSceneId: string) => {
    return { activeSequenceSceneId };
  },
);
export const updateAlarmsAction = createAction(
  '[Socket] Update Alarms',
  (alarm: SuiAlarmModel) => {
    return { alarm };
  },
);
export const deleteAlarmAction = createAction('[Socket] Delete Alarm', (id: string) => {
  return { id };
});
export const sense420StateChangeAction = createAction(
  '[Socket] Sense 4-20 State Change',
  (sensor: IndieSensorInternalModel) => {
    return { sensor };
  },
);

export const virtualIndustrialSensorStateChangeAction = createAction(
  '[Socket] Virtual Industrial Sensor State Change',
  (sensor: IndieSensorInternalModel) => {
    return { sensor };
  },
);

export const bridge485MetricStateChangeAction = createAction(
  '[Socket] Bridge 485 Metric State Change',
  (sensor: IndieSensorInternalModel) => {
    return { sensor };
  },
);

export const triggerStateChangeAction = createAction(
  '[Socket] Trigger State Change',
  (triggerStateChange: TriggerStateChangeWSModel) => {
    return { triggerStateChange };
  },
);
export const climateZoneControlAction = createAction(
  '[Socket] Climate Zone Control',
  (climateZoneId: string, controlMode: ThermostatControlModeInternalModel) => {
    return { climateZoneId, controlMode };
  },
);
export const bulkFloorPlanPlacementAction = createAction(
  '[Socket] Bulk Floor Plan Placement',
  (placedThings: PlacedThing[]) => ({ placedThings }),
);
export const thermostatAlarmStatusAction = createAction(
  '[Socket] Thermostat Alarm Status',
  (alarmStatus: ThermostatAlarmWSModel) => {
    return { alarmStatus };
  },
);
export const deleteThermostatAlarmAction = createAction(
  '[Socket] Delete Thermostat Alarm',
  (id: string) => {
    return { id };
  },
);

export type Union =
  | ReturnType<typeof socketCloseAction>
  | ReturnType<typeof updateActiveSequenceSceneAction>
  | ReturnType<typeof deleteActiveSequenceSceneAction>
  | ReturnType<typeof updateEntitiesAction>
  | ReturnType<typeof updateEnergyBaselineAction>
  | ReturnType<typeof lightControlAction>
  | ReturnType<typeof zoneControlAction>
  | ReturnType<typeof climateZoneControlAction>
  | ReturnType<typeof updateAlarmsAction>
  | ReturnType<typeof deleteAlarmAction>
  | ReturnType<typeof sense420StateChangeAction>
  | ReturnType<typeof virtualIndustrialSensorStateChangeAction>
  | ReturnType<typeof bridge485MetricStateChangeAction>
  | ReturnType<typeof triggerStateChangeAction>
  | ReturnType<typeof bulkFloorPlanPlacementAction>
  | ReturnType<typeof thermostatAlarmStatusAction>
  | ReturnType<typeof deleteThermostatAlarmAction>;
