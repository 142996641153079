import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorForm } from '@spog-ui/shared/models/behaviors';

@Component({
  selector: 'sui-bf-dimmer',
  template: `
    <p class="bfDescription">
      All lights in the zone are set to a dimming level between 0 and 100%.
    </p>
    <div class="bfForm">
      <sui-dimmer-control [formControl]="form.get('level')"></sui-dimmer-control>
    </div>
  `,
})
export class BFDimmerComponent implements BehaviorForm {
  form = new UntypedFormGroup({
    level: new UntypedFormControl(0),
  });
}
