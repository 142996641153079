import { Subscription } from 'rxjs';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  SequenceSceneInternalModel,
  SequenceSceneStepFormModel,
  fromComponentsToPreviewText,
  getOrdinalString,
} from '@spog-ui/shared/models/sequence-scenes';

@Component({
  selector: 'scn-sequence-scene-step-control',
  template: `
    <sui-card (click)="onExpand($event)" #card>
      <sui-card-header (click)="onToggle($event)">
        <h5 class="mat-subheading-2">{{ getOrdinalString(index + 2) }} Step</h5>

        <i class="material-icons">keyboard_arrow_down</i>
      </sui-card-header>
      <sui-card-body>
        <ng-template [ngIf]="open" [ngIfElse]="closed">
          <mat-label class="waitLabel">Wait Before Step</mat-label>
          <div class="waitInputs">
            <mat-form-field>
              <mat-label>Hours</mat-label>
              <input
                type="number"
                matInput
                [formControl]="formGroup.get('waitHours')"
                min="0"
                max="23"
              />
              <mat-error *ngIf="formGroup.get('waitHours')?.invalid"
                >Must be between 0 and 23</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <mat-label>Minutes</mat-label>
              <input
                type="number"
                matInput
                [formControl]="formGroup.get('waitMinutes')"
                min="0"
                max="59"
              />
              <mat-error *ngIf="formGroup.get('waitMinutes')?.invalid"
                >Must be between 0 and 59</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <mat-label>Seconds</mat-label>
              <input
                type="number"
                matInput
                [formControl]="formGroup.get('waitSeconds')"
                min="0"
                max="59"
              />
              <mat-error *ngIf="formGroup.get('waitSeconds')?.invalid"
                >Must be between 0 and 459</mat-error
              >
            </mat-form-field>
          </div>

          <mat-label>Step Scene</mat-label>
          <mat-form-field class="sceneIdInput">
            <mat-select [formControl]="formGroup.get('sceneId')">
              <mat-option *ngFor="let scene of scenes" [value]="scene.id">
                {{ scene.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
        <ng-template #closed>
          <div class="preview">
            <h6 class="mat-subheading-1">Wait Before Step</h6>
            <span class="time">{{
              fromComponentsToPreviewText(
                formGroup.value.waitHours,
                formGroup.value.waitMinutes,
                formGroup.value.waitSeconds
              )
            }}</span>
            <h6 class="mat-subheading-1">Step Scene</h6>
            <span>{{ getSceneName(formGroup?.value?.sceneId) }}</span>
          </div>
        </ng-template>
      </sui-card-body>
      <ng-template [ngIf]="open">
        <sui-card-footer>
          <button
            type="button"
            id="doneBtn"
            suiButton
            dense
            color="primary"
            (click)="onClose($event)"
          >
            Done
          </button>
          <button type="button" suiButton dense (click)="onRemove($event)">Remove</button>
        </sui-card-footer>
      </ng-template>
    </sui-card>
  `,
  styles: [
    `
      :host {
        display: block;
        position: relative;
        cursor: pointer;
        padding-top: 8px;
        padding-bottom: 8px;
      }

      sui-card {
        border-radius: 0;
      }

      sui-card-header {
        cursor: pointer;
        position: relative;
      }

      sui-card-header i {
        position: absolute;
        top: 18px;
        right: 14px;
        transition: transform 150ms;
      }

      sui-card-body {
        padding-top: 0;
      }

      h5 {
        margin: 0 0 8px;
      }

      h6 {
        margin: 0;
      }

      .preview {
        display: block;
      }

      .preview span {
        font-size: 14px;
      }

      .time {
        display: block;
        padding-bottom: 8px;
      }

      :host(.expanded) {
        cursor: default;
      }

      :host(.expanded) sui-card-header i {
        transform: rotate(-180deg);
      }

      .waitInputs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      .waitInputs .mat-mdc-form-field {
        width: 100px;
      }

      .waitInputs .mat-mdc-form-field:not(:last-child) {
        margin-right: 16px;
      }

      .sceneIdInput {
        width: 100%;
      }
    `,
  ],
  animations: [
    trigger('expand', [
      transition('open <=> close', [
        query('sui-card-body', animate(150, style({ height: '*' }))),
      ]),
      transition('open => void', [
        animate(
          150,
          style({ transform: 'translate3d(0, -100px, 0)', opacity: 0, height: 0 }),
        ),
      ]),
      transition('void => open', [
        style({ transform: 'translate3d(0, 100px, 0)', opacity: 0 }),
        animate(150, style({ transform: 'translate3d(0, 0, 0)', opacity: 1 })),
      ]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SequenceSceneStepControlComponent,
    },
  ],
})
export class SequenceSceneStepControlComponent
  implements ControlValueAccessor, AfterViewInit
{
  fromComponentsToPreviewText = fromComponentsToPreviewText;
  getOrdinalString = getOrdinalString;

  @Input() index = 0;
  @HostBinding('class.expanded') @Input() open = false;
  @Input() scenes: SequenceSceneInternalModel[] = [];
  @Output() remove = new EventEmitter();
  @Output() expand = new EventEmitter();
  @Output() closed = new EventEmitter();
  @ViewChild('card', { read: ElementRef, static: true }) card: ElementRef;

  formGroup = new UntypedFormGroup({
    waitHours: new UntypedFormControl(0),
    waitMinutes: new UntypedFormControl(0),
    waitSeconds: new UntypedFormControl(0),
    sceneId: new UntypedFormControl(null),
  });

  onChangeSubscription: Subscription;
  onTouchedSubscription: Subscription;
  errorAnimationPlayer: AnimationPlayer;

  constructor(private animationBuilder: AnimationBuilder) {}

  ngAfterViewInit() {
    this.errorAnimationPlayer = this.animationBuilder
      .build([
        style({ transform: 'translate3d(0, 0, 0)' }),
        animate(50, style({ transform: 'translate3d(-10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(-10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(-10px, 0, 0)' })),
        animate(50, style({ transform: 'translate3d(0, 0, 0)' })),
      ])
      .create(this.card.nativeElement);
  }

  writeValue(value: SequenceSceneStepFormModel): void {
    this.formGroup.setValue(value, { onlySelf: true, emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChangeSubscription = this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedSubscription = this.formGroup.statusChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  onExpand($event: Event) {
    $event.stopImmediatePropagation();

    if (!this.open) {
      this.expand.emit();
    }
  }

  onClose($event: Event) {
    $event.stopImmediatePropagation();

    if (this.formGroup.valid) {
      this.closed.emit();
    } else {
      this.errorAnimationPlayer.play();
    }
  }

  onToggle($event: Event) {
    if (this.open) {
      this.onClose($event);
    } else {
      this.onExpand($event);
    }
  }

  onRemove($event: Event) {
    $event.stopImmediatePropagation();

    this.remove.emit();
  }

  @HostBinding('@expand')
  get expandState() {
    return this.open ? 'open' : 'close';
  }

  getSceneName(id: string) {
    const foundName = this.scenes.find(scene => scene.id === id)?.name;

    return foundName ?? 'Not Set';
  }
}
