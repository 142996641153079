import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CoreState from '@spog-ui/shared/state/core';
import * as SenseState from '@spog-ui/shared/state/sense';

@Component({
  selector: 'spog-indie-sensors-page-list',
  template: `
    @if (showPowerCategory$ | async) {
      <h2>Power Sensors</h2>
      @for (sensor of powerIndieSensors$ | async; track sensor) {
        <spog-indie-sensor
          [sensor]="sensor"
          [timezone]="timezone$ | async"
          [siteTime]="siteTime$ | async"
          class="clickable"
          routerLink="industrial-sensor/details/{{ sensor.id }}"
          >
        </spog-indie-sensor>
      }
    }
    
    @if (showCustomCategory$ | async) {
      <h2>Custom Sensors</h2>
      @for (sensor of customIndieSensors$ | async; track sensor) {
        <spog-indie-sensor
          [sensor]="sensor"
          [timezone]="timezone$ | async"
          [siteTime]="siteTime$ | async"
          routerLink="industrial-sensor/details/{{ sensor.id }}"
          class="clickable"
          >
        </spog-indie-sensor>
      }
    }
    `,
  styles: [
    `
      :host h2 {
        margin-left: 16px;
        margin-bottom: 16px;
        margin-top: 0px;
        font-size: 18px;
        font-weight: 400;
        color: var(--color-foreground-text);
      }

      spog-indie-sensor:not(:last-of-type) {
        margin-bottom: 16px;
      }

      h2:not(:first-of-type) {
        margin-top: 30px;
      }

      .clickable {
        cursor: pointer;
      }
    `,
  ],
})
export class IndieSensorsPageListComponent {
  showPowerCategory$ = this.store.select(
    SenseState.selectIndieSensorsPageShowPowerCategory,
  );
  showCustomCategory$ = this.store.select(
    SenseState.selectIndieSensorsPageShowCustomCategory,
  );
  powerIndieSensors$ = this.store.select(SenseState.selectIndieSensorsPagePowerSensors);
  customIndieSensors$ = this.store.select(SenseState.selectIndieSensorsPageCustomSensors);
  timezone$ = this.store.select(CoreState.selectSelectedSiteTimeZone);
  siteTime$ = this.store.select(CoreState.selectSiteTime);

  constructor(public store: Store) {}
}
