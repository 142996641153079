import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatTreeModule } from '@angular/material/tree';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TreeSelectFilterComponent } from './tree-select-filter.component';
import { TreeSelectOptionsComponent } from './tree-select-options.component';
import { TreeSelectComponent } from './tree-select.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatIconModule,
        ScrollingModule,
        MatTreeModule,
        TreeSelectFilterComponent,
        TreeSelectOptionsComponent,
        TreeSelectComponent,
    ],
    exports: [TreeSelectComponent],
})
export class TreeSelectModule {}
