export function filterBy(
  search: string,
  field: string | string[],
  prefix?: string,
): boolean {
  if (prefix && Array.isArray(field)) {
    return (
      search.startsWith(prefix.toLowerCase()) &&
      field.some(f => f.includes(search.slice(prefix.length).trim()))
    );
  } else if (prefix) {
    return (
      search.startsWith(prefix.toLowerCase()) &&
      field.includes(search.slice(prefix.length).trim())
    );
  } else if (Array.isArray(field)) {
    return field.some(f => f.includes(search)); // if any of the input fields contains the substring
  } else {
    return field.includes(search); // if the single input field contains the substring
  }
}
