import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BFBasicColorComponent } from './bf-basic-color.component';
import { ColorPickerModule } from '@sui/color-picker';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ColorPickerModule],
  declarations: [BFBasicColorComponent],
  exports: [BFBasicColorComponent],
})
export class BFBasicColorComponentModule {}
