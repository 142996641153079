import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonComponent, SuiCardModule } from '@spog-ui/shared/components';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { SceneZoneBehaviorsComponentModule } from '../scene-zone-behaviors';
import { ApplySceneButtonComponentModule } from '../apply-scene-button';
import { LightingSceneComponent } from './lighting-scene.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ButtonComponent,
    SuiCardModule,
    PermissionsModule,
    SceneZoneBehaviorsComponentModule,
    ApplySceneButtonComponentModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [LightingSceneComponent],
  exports: [LightingSceneComponent],
})
export class LightingSceneComponentModule {}
