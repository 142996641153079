import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClipboardService } from '@spog-ui/shared/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromptService } from '@sui/prompt';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { concatMap, map, tap, catchError, switchMap } from 'rxjs/operators';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { EmailGroupsPageActions } from '../actions';
import { EmailGroupStateActions } from '@spog-ui/shared/state/email-groups';
import { EmailGroupsApiService } from '../services';

@Injectable()
export class EmailGroupsPageEffects {
  constructor(
    private actions$: Actions,
    readonly clipboardService: ClipboardService,
    readonly snackbar: MatSnackBar,
    readonly prompt: PromptService,
    readonly emailGroupsApiService: EmailGroupsApiService,
  ) {}

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailGroupsPageActions.enterAction),
      switchMap(action =>
        this.emailGroupsApiService
          .getEmailGroupsByOrganization(action.organizationId)
          .pipe(
            map(emailGroups => EmailGroupsPageActions.loadPageSuccessAction(emailGroups)),
            catchError((response: HttpErrorResponse) =>
              of(
                EmailGroupsPageActions.loadPageFailureAction(
                  retrieveErrorMessage(response),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  loadEmailGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailGroupsPageActions.loadPageSuccessAction),
      map(action => {
        return EmailGroupStateActions.loadEmailGroupsSuccessAction(action.emailGroups);
      }),
    ),
  );

  copyEmailGroupId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailGroupsPageActions.copyIDAction),
        tap(action => {
          this.clipboardService.copy(action.emailGroup.id);
          this.snackbar.open('Copied ID to the clipboard', 'Dismiss', {
            duration: 6_000,
          });
        }),
      ),
    { dispatch: false },
  );

  promptForDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailGroupsPageActions.deleteAction),
      concatMap(action =>
        this.prompt
          .open({
            title: 'Delete email group?',
            description: '',
            cancelLabel: 'Cancel',
            confirmLabel: 'Delete',
            confirmColor: 'warn',
          })
          .pipe(
            map(confirmed =>
              confirmed
                ? EmailGroupsPageActions.confirmDeleteAction(action.emailGroup)
                : EmailGroupsPageActions.cancelDeleteAction(action.emailGroup),
            ),
          ),
      ),
    ),
  );
}
