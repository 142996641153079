import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PromptService } from '@sui/prompt';
import { AuthService } from '@auth0/auth0-angular';
import { MenuActions } from '@spog-ui/modules/menu-state';
import { CurrentUserActions } from '@spog-ui/current-user/auth-actions';
import { NoSitesActions, SiteMapPageActions, SiteSelectorPageActions } from '@spog-ui/site-selector/actions';

@Injectable()
export class CurrentUserEffects {
  requestLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CurrentUserActions.requestLoginAction),
        switchMap(() =>
          this.auth0.loginWithRedirect({
            appState: { target: `/user/select-site` },
          }),
        ),
      ),
    { dispatch: false },
  );

  requestLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MenuActions.logoutAction,
        SiteSelectorPageActions.cancelSiteSelectAction,
        SiteMapPageActions.cancelSiteSelectAction,
        NoSitesActions.logoutAction,
      ),
      mergeMap(() => {
        return this.promptService
          .open({
            title: 'Sign Out Confirmation',
            description: 'Are you sure you want to sign out?',
            cancelLabel: 'Cancel',
            confirmLabel: 'Sign Out',
            confirmButtonTestId: 'sign-out-button',
          })
          .pipe(
            map(didConfirm =>
              didConfirm
                ? CurrentUserActions.confirmLogoutAction()
                : CurrentUserActions.cancelLogoutAction(),
            ),
          );
      }),
    ),
  );

  confirmLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CurrentUserActions.confirmLogoutAction),
        tap(() => this.auth0.logout({ returnTo: window.location.origin })),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private auth0: AuthService,
    private promptService: PromptService,
  ) {}
}
