import { Component } from '@angular/core';

@Component({
  selector: 'spog-drawer-label',
  template: `
    <div class="label">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        border-top: 1px solid var(--color-foreground-divider);
        margin-top: 6px;
      }

      .label {
        display: block;
        width: 100%;
        padding: 8px 16px 0;
        line-height: 28px;
        font-size: 12px;
        color: var(--color-foreground-secondary-text);
        vertical-align: baseline;
      }
    `,
  ],
})
export class DrawerLabelComponent {}
