import { Component } from '@angular/core';
import {
  ANGLE_RANGE_MAX,
  ANGLE_RANGE_MIN,
  RADIUS,
  SVG_HEIGHT,
  SVG_WIDTH,
} from '../constants';

@Component({
  selector: 'svg:g[spog-thermodial-background]',
  template: `
    <svg:g opacity=".2">
      <svg:circle
        class="dialBgOutline"
        [attr.cx]="centerX"
        [attr.cy]="centerY"
        [attr.r]="radius"
        ></svg:circle>
        @for (notch of notches; track notch) {
          <svg:rect
            class="dialNotch"
            [attr.x]="centerX"
            [attr.y]="centerY"
            [attr.transform]="notch"
            ></svg:rect>
          }
          </svg:g>
    
          <svg:g class="dial-foreground">
            <svg:circle
              [attr.cx]="centerX"
              [attr.cy]="centerY"
              [attr.r]="radius"
              stroke-width="4"
              stroke="url(#temperatureGradient)"
              fill="none"
              clip-path="url(#dialForegroundMask)"
              ></svg:circle>
              <svg:rect
                class="dialCoolNotch"
                [attr.x]="centerX"
                [attr.y]="centerY"
                [attr.transform]="coolNotchTransform"
                ></svg:rect>
                <svg:rect
                  class="dialHeatNotch"
                  [attr.x]="centerX"
                  [attr.y]="centerY"
                  [attr.transform]="heatNotchTransform"
                  ></svg:rect>
                  </svg:g>
    
                  <svg:defs>
                    <svg:clipPath id="dialForegroundMask">
                      <svg:polygon [attr.points]="clippingMaskPoints"></svg:polygon>
                        </svg:clipPath>
                        </svg:defs>
    `,
  styles: [
    `
      .dialBgOutline {
        fill: none;
        stroke: white;
        stroke-width: 1;
      }

      .dialNotch {
        fill: white;
        height: 12px;
        width: 2px;
      }

      .dialCoolNotch {
        fill: var(--color-accent-500);
        height: 20px;
        width: 4px;
      }

      .dialHeatNotch {
        fill: var(--thermodial-cool-color);
        height: 20px;
        width: 4px;
      }
    `,
  ],
})
export class ThermodialBackgroundComponent {
  readonly height = SVG_HEIGHT;
  readonly width = SVG_WIDTH;
  readonly radius = RADIUS;

  readonly centerX = this.width / 2;
  readonly centerY = this.height / 2;

  readonly totalNotches = 35;
  readonly notches: string[] = calcNotchTransforms(
    this.centerX,
    this.centerY,
    this.radius,
    ANGLE_RANGE_MIN,
    this.totalNotches,
  );

  readonly clippingMaskPoints: string = calcClippingMaskPoints(
    this.centerX,
    this.centerY,
    this.radius,
    5,
  );

  readonly coolNotchTransform: string = `rotate(${ANGLE_RANGE_MIN} ${this.centerX} ${
    this.centerY
  }) translate(0 ${this.radius - 2})`;

  readonly heatNotchTransform: string = `rotate(${ANGLE_RANGE_MAX} ${this.centerX} ${
    this.centerY
  }) translate(0 ${this.radius - 2})`;
}

export function calcNotchTransforms(
  cx: number,
  cy: number,
  r: number,
  minAngleFromBottom: number,
  numNotches: number,
): string[] {
  const notches: string[] = [];
  const radialBand = (360 - minAngleFromBottom * 2) / (numNotches - 1); //

  for (let i = 0; i < numNotches; i++) {
    notches.push(
      `rotate(${i * radialBand + minAngleFromBottom} ${cx} ${cy}) translate(0 ${r + 5})`,
    );
  }

  return notches;
}

/** Returns points for a clipping mask to hide the bottom 100deg of the foreground arc */
export function calcClippingMaskPoints(
  cx: number,
  cy: number,
  r: number,
  buffer: number,
): string {
  const x1 = cx - r - buffer;
  const x2 = cx + r + buffer;
  const y1 = cy - r - buffer;
  const y2 = cy + r + buffer - 15; // accounts for the angle being slightly less than 270deg

  return `${x1},${y1} ${x2},${y1} ${x2},${y2} ${cx},${cy} ${x1},${y2}`;
}
