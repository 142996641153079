import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment } from '@spog-ui/shared/environments';
import { selectSelectedSite, selectRouterUrl } from '@spog-ui/shared/state/core';
import { defer } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { logEventAction } from './user-interaction.actions';
import { CurrentUserService } from '@spog-ui/current-user/feature';
import { SiteService } from '@spog-ui/shared/services';

@Injectable({
  providedIn: 'root',
})
export class UserInteractionEffects {
  logUITransactionMetrics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logEventAction),
        withLatestFrom(
          defer(() => this.store.select(selectSelectedSite)),
          defer(() => this.currentUser.user$),
          defer(() => this.currentUser.isSuperAdmin$),
          defer(() => this.store.select(selectRouterUrl)),
        ),
        tap(([action, site, user, isSuperAdmin, activeRoute]) => {
          const handlerURL = `${environment.firebase.functionHost}/logUITransactionMetrics`;

          const message = JSON.stringify({
            message: action.message,
            detail: action.detail,
            metricsType: action.metricsType,
            success: action.success,
            timeTaken: action.timeTaken,
            startTime: action.startTime,
            endTime: action.endTime,
            userId: user?.id,
            userRole: this.siteService.getRole(),
            isSuperAdmin,
            siteId: site?.id,
            siteProducts: site?.enabledProducts,
            activeRoute: activeRoute,
          });

          navigator?.sendBeacon?.(handlerURL, message);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    readonly actions$: Actions,
    readonly store: Store,
    readonly currentUser: CurrentUserService,
    readonly siteService: SiteService,
  ) {}
}
