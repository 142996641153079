import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SuiCardModule, SuiProgressBarModule } from '@spog-ui/shared/components';
import { AdminLinkComponentModule, EulaLinkComponentModule } from '@spog-ui/menu';
import { SiteSelectorShellComponent } from './site-selector-shell.component';

@NgModule({
  imports: [
    CommonModule,
    AdminLinkComponentModule,
    EulaLinkComponentModule,
    SuiCardModule,
    SuiProgressBarModule,
    RouterModule,
  ],
  declarations: [SiteSelectorShellComponent],
  exports: [SiteSelectorShellComponent],
})
export class SiteSelectorShellComponentModule {}
