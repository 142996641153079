import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class GraphqlErrorInterceptor implements HttpInterceptor {
  constructor(private snackbar: MatSnackBar) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(event => this.handleGraphqlResponse(event)));
  }

  // TODO: @alexmabry: Remove the GATEWAY error messages once the backend has been updated
  //                   to send the SITE_CONTROLLER versions
  handleGraphqlResponse(event: HttpEvent<any>) {
    if (event.type === HttpEventType.Response && event.status === 200 && event.body) {
      if (event.body.errors && event.body.errors.length > 0) {
        event.body.errors.forEach((error: any) => {
          if (
            error.extensions &&
            error.extensions.code === 'UNREACHABLE_SITE_CONTROLLERS'
          ) {
            if (error.extensions.exception) {
              const unreachableSiteControllerIds: string[] =
                error.extensions.siteControllerIds ?? [];

              const siteControllerNames = unreachableSiteControllerIds.map(
                siteControllerId => `Site Controller ${siteControllerId}`,
              );
              this.showErrorMessage(
                error.message + `: ${siteControllerNames.join(', ')}`,
              );
            } else {
              this.showErrorMessage(error.message);
            }
          } else if (
            error.extensions &&
            [
              'NO_SITE_CONTROLLERS',
              'NO_SITE_GATEWAYS',
              'INTERNAL_SITE_CONTROLLER_ERROR',
              'INTERNAL_GATEWAY_ERROR',
            ].includes(error.extensions.code)
          ) {
            this.showErrorMessage(error.message);
          }
        });
      }
    }
  }

  showErrorMessage(errorMessage: string) {
    this.snackbar.open(errorMessage, 'Dismiss', {});
  }
}
