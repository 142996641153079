import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'sui-panel-header',
  template: `
    <div class="suiPanelHeaderWrapper" [class.suiPanelCoveringPage]="covering">
      <h3 class="sui-subtitle-1">{{ title }}</h3>
      <button mat-icon-button (click)="closed.emit()">
        @if (covering) {
        <mat-icon>arrow_back</mat-icon>
        } @else {
        <mat-icon>close</mat-icon>
        }
      </button>
    </div>
  `,
  styles: [
    `
      .suiPanelHeaderWrapper {
        padding: 0 16px;
        height: 64px;
        display: grid;
        align-items: center;
        grid-template-areas: 'title close';
        grid-template-columns: 1fr 36px;
        column-gap: 24px;
        background-color: var(--color-background-status-bar);
      }

      .suiPanelHeaderWrapper.suiPanelCoveringPage {
        grid-template-areas: 'close title';
        grid-template-columns: 36px 1fr;
      }

      h3 {
        grid-area: title;
      }

      button {
        grid-area: close;
      }

      :host(.suiPanelHeaderColorPrimary) .suiPanelHeaderWrapper {
        background-color: var(--color-500);
        color: var(--color-contrast-500);
      }

      :host(.suiPanelHeaderColorAccent) .suiPanelHeaderWrapper {
        background-color: var(--color-accent-500);
        color: var(--color-accent-contrast-500);
      }

      :host(.suiPanelHeaderColorWarn) .suiPanelHeaderWrapper {
        background-color: var(--color-warn-500);
        color: var(--color-warn-contrast-500);
      }
    `,
  ],
  standalone: true,
  imports: [MatIconButton, NgIf, MatIcon],
})
export class PanelHeaderComponent {
  @Input() title = '';
  @Input() color = 'default';
  @Input() covering = false;
  @Output() closed = new EventEmitter();

  @HostBinding('class.suiPanelHeaderColorPrimary') get isPrimary(): boolean {
    return this.color === 'primary';
  }

  @HostBinding('class.suiPanelHeaderColorAccent') get isAccent(): boolean {
    return this.color === 'accent';
  }

  @HostBinding('class.suiPanelHeaderColorWarn') get isWarn(): boolean {
    return this.color === 'warn';
  }
}
