import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ScenesApiActions } from '@spog-ui/scenes/actions';
import { ScheduleApiActions } from '@spog-ui/schedule/actions';
import { SequenceSceneInternalModel } from '@spog-ui/shared/models/sequence-scenes';

export type Shape = EntityState<SequenceSceneInternalModel>;

export const adapter = createEntityAdapter({
  sortComparer: sortByName,
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    ScenesApiActions.loadSuccessAction,
    ScheduleApiActions.loadSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.sequenceScenes, state);
    },
  ),
  on(
    ScenesApiActions.addSequenceSceneSuccessAction,
    ScenesApiActions.duplicateSequenceSceneSuccessAction,
    (state, action) => {
      return adapter.addOne(action.sequenceScene, state);
    },
  ),
  on(ScenesApiActions.deleteSequenceSceneSuccessAction, (state, action) => {
    return adapter.removeOne(action.sequenceSceneId, state);
  }),
);

export function sortByName(a: SequenceSceneInternalModel, b: SequenceSceneInternalModel) {
  return a.name.localeCompare(b.name);
}

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
