import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  SuiCardModule,
  PendingButtonComponent,
} from '@spog-ui/shared/components';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { SequenceScenePreviewComponentModule } from '../sequence-scene-preview';
import { SequenceSceneComponent } from './sequence-scene.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ButtonComponent,
    SuiCardModule,
    PermissionsModule,
    SequenceScenePreviewComponentModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    PendingButtonComponent,
  ],
  declarations: [SequenceSceneComponent],
  exports: [SequenceSceneComponent],
})
export class SequenceSceneComponentModule {}
