import { createReducer, on } from '@ngrx/store';
import { UsersPageActions, UsersPageApiActions } from '@spog-ui/admin/actions';

export interface Shape {
  isLoading: boolean;
  error: any;
  pageIndex: number;
  pageSize: number;
  filter: string;
  userSites: string[] | undefined;
}

export const initialState: Shape = {
  isLoading: true,
  pageIndex: 0,
  pageSize: 50,
  filter: '',
  error: null,
  userSites: [],
};

export const reducer = createReducer(
  initialState,
  on(UsersPageActions.enterAction, () => initialState),
  on(UsersPageApiActions.loadModelsSuccessAction, state => ({
    ...state,
    isLoading: false,
  })),
  on(UsersPageApiActions.loadModelsFailureAction, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  on(UsersPageActions.paginateAction, (state, action) => ({
    ...state,
    pageIndex: action.pageIndex,
    pageSize: action.pageSize,
  })),
  on(UsersPageActions.filterAction, (state, action) => ({
    ...state,
    filter: action.searchTerm,
    pageIndex: 0,
  })),
  on(UsersPageApiActions.getUserSitesSuccessAction, (state, action) => {
    return { ...state, userSites: action.sites };
  }),
  on(UsersPageActions.closeUserSitesPreviewAction, (state, action) => {
    return { ...state, userSites: [] };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectPageIndex = (state: Shape) => state.pageIndex;
export const selectPageSize = (state: Shape) => state.pageSize;
export const selectFilter = (state: Shape) => state.filter;
export const selectUserSites = (state: Shape) => state.userSites;
