import { createAction } from '@ngrx/store';
import { EmailGroupInternalModel } from '@spog-ui/shared/models/email-groups';

export const enterAction = createAction(
  '[Admin / Organizations / Emails Group] Enter',
  (organizationId: string) => ({ organizationId }),
);

export const loadPageSuccessAction = createAction(
  '[Email Groups / API] Load Page Success',
  (emailGroups: EmailGroupInternalModel[]) => ({
    emailGroups,
  }),
);

export const loadPageFailureAction = createAction(
  '[Email Groups / API] Load Page Failure',
  (error: string) => ({ error }),
);

export const deleteAction = createAction(
  '[Admin Organization / Email Groups] Delete',
  (emailGroup: EmailGroupInternalModel) => ({ emailGroup }),
);

export const confirmDeleteAction = createAction(
  '[Admin Organization / Email Groups] Confirm Delete',
  (emailGroup: EmailGroupInternalModel) => ({ emailGroup }),
);

export const cancelDeleteAction = createAction(
  '[Admin Organization / Email Groups] Cancel Delete',
  (emailGroup: EmailGroupInternalModel) => ({ emailGroup }),
);

export const filterAction = createAction(
  '[Admin Organization / Email Groups] Filter',
  (term: string) => {
    return { term };
  },
);
export const paginateAction = createAction(
  '[Admin Organization / Email Groups] Paginate',
  (pageIndex: number, pageSize: number) => {
    return { pageIndex, pageSize };
  },
);

export const copyIDAction = createAction(
  '[Admin Organization / Email Groups] Copy Org ID',
  (emailGroup: EmailGroupInternalModel) => {
    return { emailGroup };
  },
);
