import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppUpdatesActions } from '@spog-ui/app-updates/actions';
import * as CoreState from '@spog-ui/shared/state/core';

@Component({
  selector: 'spog-app-update-check',
  template: `
    <span>
      @if (showCheckForUpdates$ | async) {
        <a
          id="checkForUpdatesLink"
          (click)="checkForUpdates()"
          >
          Check for Updates
        </a>
      }
      @if (isCheckingForUpdates$ | async) {
        <a id="checkingForUpdatesText"
          >Checking for Updates...
        </a>
      }
    </span>
    `,
  styles: [
    `
      a {
        color: var(--color-500);
        font-size: 12px;
        font-weight: 500;
        text-decoration: none;
      }

      svg {
        margin-left: 6px;
      }

      #checkForUpdatesLink {
        cursor: pointer;
      }
    `,
  ],
})
export class AppUpdateCheckComponent {
  isCheckingForUpdates$: Observable<boolean>;
  showCheckForUpdates$: Observable<boolean>;

  constructor(private store: Store) {
    this.isCheckingForUpdates$ = store.select(CoreState.selectIsCheckingForUpdate);
    this.showCheckForUpdates$ = store.select(CoreState.selectShowCheckForUpdates);
  }

  checkForUpdates() {
    this.store.dispatch(AppUpdatesActions.checkForUpdate());
  }
}
