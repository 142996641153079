import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ScheduleUtilityRateModalComponentModule } from './components/schedule-utility-rate-modal';
import { UtilityServicesApiEffects, UtilityServicesPageEffects } from './effects';
import { UtilityServicesRoutingModule } from './utility-services-routing.module';

@NgModule({
  imports: [
    ScheduleUtilityRateModalComponentModule,
    EffectsModule.forFeature([UtilityServicesApiEffects, UtilityServicesPageEffects]),
    UtilityServicesRoutingModule,
  ],
})
export class UtilityServicesModule {}
