import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { CARD_SELECT_DIRECTIVES } from './card-select.component';

@NgModule({
    imports: [MatIconModule, MatDividerModule, ...CARD_SELECT_DIRECTIVES],
    exports: CARD_SELECT_DIRECTIVES,
})
export class CardSelectComponentModule {}
