import { createAction } from '@ngrx/store';
import { SceneViewInternalModel } from '@spog-ui/shared/models/scene-views';

export const enterAction = createAction('[Scene Views Page] Enter | @spog-sui/scenes');

export const copyIDAction = createAction(
  '[Scene Views Page] Copy Scene View ID',
  (sceneView: SceneViewInternalModel) => {
    return { sceneView };
  },
);

export const useAction = createAction(
  '[Scene Views Page] Use',
  (sceneView: SceneViewInternalModel, redirectUrl: string) => {
    return { sceneView, redirectUrl };
  },
);

export const deleteAction = createAction(
  '[Scene Views Page] Delete',
  (sceneView: SceneViewInternalModel) => ({ sceneView }),
);

export const confirmDeleteAction = createAction(
  '[Scene Views Page] Confirm Delete',
  (sceneView: SceneViewInternalModel) => ({ sceneView }),
);

export const cancelDeleteAction = createAction(
  '[Scene Views Page] Cancel Delete',
  (sceneView: SceneViewInternalModel) => ({ sceneView }),
);
