import { createReducer, createSelector, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { FloorPlanModel } from '@spog-ui/shared/models/floor-plans';
import { AddFloorPlanActions, FloorPlanApiActions } from '@spog-ui/floor-plan/actions';
import { MapApiActions } from '@spog-ui/map/actions';

export interface Shape extends EntityState<FloorPlanModel> {
  pending: boolean;
  error: any;
}

export const adapter = createEntityAdapter({
  selectId: (model: FloorPlanModel) => model.id,
});

export const initialState: Shape = adapter.getInitialState({
  pending: false,
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(AddFloorPlanActions.saveAction, AddFloorPlanActions.saveMapAction, state => {
    return { ...state, pending: true, error: null };
  }),
  on(FloorPlanApiActions.saveSuccessAction, (state, action) => {
    return adapter.addOne(action.floorPlan, {
      ...state,
      error: null,
      pending: false,
    });
  }),
  on(FloorPlanApiActions.removeSuccessAction, () => {
    return initialState;
  }),
  on(FloorPlanApiActions.replaceSuccessAction, (state, action) => {
    return adapter.setAll([action.floorPlan], state);
  }),
  on(FloorPlanApiActions.saveFailureAction, (state, action) => {
    return { ...state, error: action.error, pending: false };
  }),
  on(AddFloorPlanActions.dismissAlertAction, state => {
    return { ...state, pending: false, error: null };
  }),
  on(MapApiActions.loadEssentialModelsSuccessAction, (state, action) => {
    return adapter.setAll(action.models.floorPlans, state);
  }),
);

export const { selectEntities, selectIds, selectAll } = adapter.getSelectors();
export const selectPending = (state: Shape) => state.pending;
export const selectError = (state: Shape) => state.error;
export const selectActive = createSelector(selectAll, ([floorPlan]) =>
  floorPlan ? floorPlan : null,
);
