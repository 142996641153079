import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
  ErrorCardComponentModule,
  GettingStartedCardComponent,
  SuiCharmFilterModule,
  SuiExtendedFabModule,
  SuiSpinnerModule,
} from '@spog-ui/shared/components';
import { ChartingPageComponent } from './charting-page.component';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { HighchartsChartModule } from 'highcharts-angular';
import { TreeSelectModule } from '@spog-ui/shared/components';
import { ReactiveFormsModule } from '@angular/forms';
import { DateControlComponentModule } from '../date-control';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SuiExtendedFabModule,
    SuiSpinnerModule,
    SuiCharmFilterModule,
    PermissionsModule,
    ErrorCardComponentModule,
    GettingStartedCardComponent,
    HighchartsChartModule,
    TreeSelectModule,
    ReactiveFormsModule,
    DateControlComponentModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSidenavModule,
    MatIconModule,
  ],
  declarations: [ChartingPageComponent],
  exports: [ChartingPageComponent],
})
export class ChartingPageComponentModule {}
