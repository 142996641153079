import { createAction } from '@ngrx/store';
import { UtilityServiceGQLModel } from '@spog-ui/shared/models/utility-services';
import { IndieSensorInternalModel } from '@spog-ui/shared/models/indie-sensors';
import { ControllerInternalModel } from '@spog-ui/shared/models/controllers';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';

export const loadSuccessAction = createAction(
  '[Indie Sensors API] Load Success',
  (
    indieSensors: IndieSensorInternalModel[],
    utilityServices: UtilityServiceGQLModel[],
  ) => {
    return { indieSensors, utilityServices };
  },
);
export const loadFailureAction = createAction(
  '[Indie Sensors API] Load Failure',
  (error?: any) => {
    return { error };
  },
);
export const loadIndieSensorsAndControllersSuccessAction = createAction(
  '[Indie Sensors API] Load Indie Sensors and Controllers Success',
  (
    indieSensors: IndieSensorInternalModel[],
    controllers: ControllerInternalModel[],
    siteControllers: SiteControllerInternalModel[],
  ) => {
    return { indieSensors, controllers, siteControllers };
  },
);
export const loadIndieSensorsAndControllersFailureAction = createAction(
  '[Indie Sensors API] Load Indie Sensors and Controllers Failure',
  (error?: any) => {
    return { error };
  },
);
export const addSense420IndieSensorSuccessAction = createAction(
  '[Indie Sensors API] Add Sense 420 Indie Sensor Success',
  (indieSensor: IndieSensorInternalModel) => {
    return { indieSensor };
  },
);
export const addSense420IndieSensorFailureAction = createAction(
  '[Indie Sensors API] Add Sense 420 Indie Sensor Failure',
  (error: any) => {
    return { error };
  },
);
export const editSense420IndieSensorSuccessAction = createAction(
  '[Indie Sensors API] Edit Sense 420 Indie Sensor Success',
  (indieSensor: IndieSensorInternalModel) => {
    return { indieSensor };
  },
);
export const editSense420IndieSensorFailureAction = createAction(
  '[Indie Sensors API] Edit Sense 420 Indie Sensor Failure',
  (error: any) => {
    return { error };
  },
);
