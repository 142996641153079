import {
  catchError,
  defer,
  EMPTY,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GraphQLAPIService } from '@spog-ui/graphql/types';
import {
  toBridge485InternalModelFromGQL,
  toCreateBridge485Input,
} from '@spog-ui/shared/models/bridge-485s';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';
import { toIndieSensorInternalModelFromGQL } from '@spog-ui/shared/models/indie-sensors';
import { SiteService } from '@spog-ui/shared/services';
import {
  IndieSensorsStateActions,
  SiteControllersStateActions,
} from '@spog-ui/shared/state/core';
import { Bridge485sStateActions } from '@spog-ui/shared/state/bridge-485s';
import { toSiteControllerInternalModelFromGQL } from '@spog-ui/shared/models/site-controllers';
import {
  Bridge485TemplatesStateActions,
  selectBridge485TemplateEntities,
} from '@spog-ui/shared/state/bridge-485-templates';
import { toBridge485TemplateInternalModelFromGQL } from '@spog-ui/shared/models/bridge-485-templates';
import { AddBridge485Page, Bridge485Api } from '../actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AddBridge485PageEffects {
  constructor(
    readonly actions$: Actions,
    readonly store: Store,
    readonly gql: GraphQLAPIService,
    readonly siteService: SiteService,
    readonly router: Router,
    private snackbar: MatSnackBar,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddBridge485Page.enterAction),
      mergeMap(() => {
        const siteId = this.siteService.getId();

        if (!siteId) return EMPTY;

        return this.gql.getAllBridge485Models({ siteId }).pipe(
          map(result => {
            const site = result.site;

            if (!site) throw new Error(`No site with id ${siteId} was found.`);

            const bridge485s = site.bridge485s.map(toBridge485InternalModelFromGQL);
            const indieSensors = site.industrialSensors.map(
              toIndieSensorInternalModelFromGQL,
            );
            const siteControllers = site.siteControllers.map(
              toSiteControllerInternalModelFromGQL,
            );
            const templates = result.configurationTemplates.map(
              toBridge485TemplateInternalModelFromGQL,
            );

            return Bridge485Api.loadAddPageModelsSuccessAction(
              bridge485s,
              indieSensors,
              siteControllers,
              templates,
            );
          }),
          catchError(error =>
            of(Bridge485Api.loadAddPageModelsFailureAction(retrieveErrorMessage(error))),
          ),
        );
      }),
    ),
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddBridge485Page.saveAction),
      withLatestFrom(defer(() => this.store.select(selectBridge485TemplateEntities))),
      switchMap(([action, templates]) => {
        const siteId = this.siteService.getId();
        const templateId = action.bridge485.template;

        if (!siteId) return EMPTY;
        if (!templateId) return EMPTY;

        const template = templates[templateId];

        if (!template) return EMPTY;

        const input = toCreateBridge485Input(action.bridge485, siteId, template);

        return this.gql.createBridge485({ input }).pipe(
          map(({ createBridge485: bridge485 }) =>
            Bridge485Api.createSuccessAction(toBridge485InternalModelFromGQL(bridge485)),
          ),
          catchError(error =>
            of(Bridge485Api.createFailureAction(retrieveErrorMessage(error))),
          ),
        );
      }),
    ),
  );

  cancel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AddBridge485Page.cancelAction),
        tap(() => {
          const siteId = this.siteService.getId();

          if (!siteId) throw new Error(`No site ID for session`);

          this.router.navigateByUrl(`/site/${siteId}/sense/industrial-sensors`);
        }),
      ),
    { dispatch: false },
  );

  redirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Bridge485Api.createSuccessAction),
        tap(() => {
          const siteId = this.siteService.getId();

          if (!siteId) throw new Error(`No site ID for session`);

          this.router.navigateByUrl(`/site/${siteId}/sense/industrial-sensors`);
        }),
      ),
    { dispatch: false },
  );

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          Bridge485Api.createFailureAction,
        ),
        tap(action => this.snackbar.open(action.error, 'Dismiss')),
      ),
    { dispatch: false },
  );

  pushBridge485sToState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485Api.loadAddPageModelsSuccessAction),
      map(action =>
        Bridge485sStateActions.loadBridge485sAction(action.models.bridge485s),
      ),
    ),
  );

  pushBridge485TemplatesToState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485Api.loadAddPageModelsSuccessAction),
      map(action =>
        Bridge485TemplatesStateActions.loadBridge485TemplatesAction(
          action.models.templates,
        ),
      ),
    ),
  );

  pushIndieSensorsToState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485Api.loadAddPageModelsSuccessAction),
      map(action => IndieSensorsStateActions.loadAction(action.models.indieSensors)),
    ),
  );

  pushSiteControllersToState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Bridge485Api.loadAddPageModelsSuccessAction),
      map(action =>
        SiteControllersStateActions.loadAction(action.models.siteControllers),
      ),
    ),
  );
}
