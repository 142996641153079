import { Component, HostBinding, Input } from '@angular/core';
import { IndefiniteArticlePipe } from './indefinite-article.pipe';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import { PermissionsModule } from '../../../../../modules/current-user/permissions/src/lib/permissions.module';
import { NgIf } from '@angular/common';
import {
  EmptyStateComponent,
  EmptyStateTitleDirective,
  EmptyStateMessageDirective,
  EmptyStateActionsDirective,
} from '../empty-state/empty-state.component';

@Component({
  selector: 'sui-getting-started-card',
  template: `
    <sui-empty-state>
      <ng-template suiEmptyStateTitle> Get Started </ng-template>

      <ng-template suiEmptyStateMessage>
        @if (!emptyStateMessage) {
        <div>
          No {{ item }}s have been configured. Please add
          {{ item | indefiniteArticle }} to get started.
        </div>
        } @else {
        {{ emptyStateMessage }}
        }
      </ng-template>

      <ng-template suiEmptyStateActions>
        @if (!hideActionButton) {
        <div>
          <a routerLink="{{ path }}" suiButton *suiIfUserCan="['ssc:siteAdmin']"
            >Add {{ item }}</a
          >
        </div>
        }
      </ng-template>
    </sui-empty-state>
  `,
  standalone: true,
  imports: [
    EmptyStateComponent,
    EmptyStateTitleDirective,
    EmptyStateMessageDirective,
    NgIf,
    EmptyStateActionsDirective,
    PermissionsModule,
    ButtonComponent,
    RouterLink,
    IndefiniteArticlePipe,
  ],
})
export class GettingStartedCardComponent {
  customMessage: string;
  @Input() path: string;
  @Input() item = 'item';
  @Input() emptyStateMessage: string;
  @Input() hideActionButton = false;

  @HostBinding('attr.role') role = 'status';
}
