import { Component, Input } from '@angular/core';
import { BehaviorType } from '@spog-ui/shared/models/behaviors';
import { DeprecatedZoneGQLModel as ZoneModel } from '@spog-ui/shared/models/zones';
import { LightDetailsActions } from '@spog-ui/light-details/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'lit-light-zones-card',
  template: `
    <mat-card class="zoneInfoCard">
      <mat-card-content>
        <mat-card-title>Zones</mat-card-title>
        @if (zones.length > 0) {
          <div>
            @for (zone of zones; track zone) {
              <div
                class="row"
                [routerLink]="'../../../zones/details/' + zone.id"
                (tap)="onControl(zone)"
                (mouseenter)="onPreview(zone.id)"
                (press)="onPreview(zone.id)"
                (mouseleave)="onUnPreview()"
                (pressup)="onUnPreview()"
                >
                @if (!isDlhZone(zone)) {
                  <mat-icon>blur_circular</mat-icon>
                }
                @if (isDlhZone(zone)) {
                  <mat-icon>brightness_high</mat-icon>
                }
                <span>{{ zone.name }}</span>
              </div>
            }
          </div>
        } @else {
          There are no zones that are associated with this light.
        }
      </mat-card-content>
    </mat-card>
    `,
  styles: [
    `
      .zoneInfoCard {
        border-radius: 2px;
      }

      mat-card-title {
        font-size: 16px;
        padding: 0px;
      }

      .row {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 40px auto 52px;
        align-items: center;
        padding-bottom: 16px;
        cursor: pointer;
        text-decoration: none;
      }
    `,
  ],
})
export class LightZonesCardComponent {
  constructor(public store: Store, readonly router: Router) {}

  @Input()
  zones: ZoneModel[];

  isDlhZone(zone: ZoneModel) {
    return zone && zone.behaviorId === BehaviorType.DLH;
  }

  onControl(zone: ZoneModel) {
    this.store.dispatch(LightDetailsActions.controlZoneAction(zone));
  }

  onPreview(zoneId: string) {
    this.store.dispatch(LightDetailsActions.previewZoneAction(zoneId));
  }

  onUnPreview() {
    this.store.dispatch(LightDetailsActions.unpreviewZoneAction());
  }
}
