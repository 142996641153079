import {
  SequenceSceneModelFragment,
  SceneSequenceStepModelFragment,
} from '@spog-ui/graphql/types';
import { SceneInternalModel } from '@spog-ui/shared/models/scenes';

export type SequenceSceneGQLModel = SequenceSceneModelFragment;
export type SequenceSceneStepGQLModel = SceneSequenceStepModelFragment;

export interface SequenceSceneStepInternalModel {
  stepNumber: number;
  applySceneId?: string;
  duration?: number;
}

export interface SequenceSceneInternalModel {
  id: string;
  name: string;
  steps: SequenceSceneStepInternalModel[];
  loop: boolean;
  loopWaitHours: number;
  loopWaitMinutes: number;
  loopWaitSeconds: number;
}

export interface SequenceSceneStepViewModel {
  stepNumber: number;
  applySceneId?: string;
  applySceneName?: string;
  duration?: number;
}

export interface SequenceSceneViewModel {
  id: string;
  name: string;
  state: SequenceSceneStates;
  steps: SequenceSceneStepViewModel[];
  loop: boolean;
  loopWaitHours: number;
  loopWaitMinutes: number;
  loopWaitSeconds: number;
}

export interface SequenceSceneStepWSModel {
  stepNumber: number;
  sceneId?: string;
  duration?: number;
}

export interface SequenceSceneWSModel {
  id: string;
  name: string;
  steps: SequenceSceneStepWSModel[];
  loop: boolean;
}

export function toSequenceSceneInternalModelFromWS(
  ws: SequenceSceneWSModel,
): SequenceSceneInternalModel {
  const steps = ws.steps.map(step => ({
    stepNumber: step.stepNumber,
    applySceneId: step.sceneId,
    duration: step.duration,
  }));

  let loopWaitStep;

  if (ws.loop) {
    loopWaitStep = steps.pop();
  }

  let loopWaitComponents;

  if (loopWaitStep) {
    loopWaitComponents = toComponentsFromSeconds(loopWaitStep?.duration ?? 0);
  }

  return {
    id: ws.id,
    name: ws.name,
    steps,
    loop: ws.loop,
    loopWaitHours: loopWaitComponents?.waitHours ?? 0,
    loopWaitMinutes: loopWaitComponents?.waitMinutes ?? 0,
    loopWaitSeconds: loopWaitComponents?.waitSeconds ?? 0,
  };
}

export type SequenceSceneStates = 'ready' | 'success' | 'pending' | 'error';

export interface SequenceSceneStepFormModel {
  sceneId: string;
  wait: number;
}

export interface SequenceSceneFormModel {
  id: string | null;
  name: string;
  startSceneId: string;
  steps: SequenceSceneStepFormModel[];
  loop: boolean;
  loopWaitHours: number;
  loopWaitMinutes: number;
  loopWaitSeconds: number;
}

export function toSequenceSceneInternalModelFromGQL(
  gql: SequenceSceneGQLModel,
): SequenceSceneInternalModel {
  const transformedSteps = gql.steps.map(step => ({
    stepNumber: step.stepNumber,
    ...(step.__typename === 'ApplySceneStep' ? { applySceneId: step.scene.id } : {}),
    ...(step.__typename === 'WaitStep' ? { duration: step.duration } : {}),
  }));

  let loopWaitStep;

  if (gql.loop) {
    loopWaitStep = transformedSteps.pop();
  }

  let loopWaitComponents;

  if (loopWaitStep) {
    loopWaitComponents = toComponentsFromSeconds(loopWaitStep?.duration ?? 0);
  }

  const internal = {
    id: gql.id,
    name: gql.name,
    steps: transformedSteps,
    loop: gql.loop,
    loopWaitHours: loopWaitComponents?.waitHours ?? 0,
    loopWaitMinutes: loopWaitComponents?.waitMinutes ?? 0,
    loopWaitSeconds: loopWaitComponents?.waitSeconds ?? 0,
  };

  return internal;
}

export function toSequenceSceneViewModelFromInternal(
  internal: SequenceSceneInternalModel,
  scenes: SceneInternalModel[],
  state: SequenceSceneStates,
) {
  const view = {
    id: internal.id,
    name: internal.name,
    state,
    steps: internal.steps.map(internalStep => {
      if (internalStep.applySceneId) {
        const applyScene = scenes.find(scene => scene.id === internalStep.applySceneId);

        return {
          stepNumber: internalStep.stepNumber,
          applySceneId: internalStep.applySceneId,
          applySceneName: applyScene!.name,
        };
      } else {
        return {
          stepNumber: internalStep.stepNumber,
          duration: internalStep.duration,
        };
      }
    }),
    loop: internal.loop,
    loopWaitHours: internal.loopWaitHours,
    loopWaitMinutes: internal.loopWaitMinutes,
    loopWaitSeconds: internal.loopWaitSeconds,
  };

  return view;
}

export function toSequenceSceneStepInputFromInternal(
  internal: SequenceSceneStepInternalModel,
) {
  return {
    stepNumber: internal.stepNumber,
    ...(internal.duration != null ? { duration: internal.duration } : {}),
    ...(internal.applySceneId ? { sceneId: internal.applySceneId } : {}),
  };
}

export function getCombinedStepsForPreview(
  scene: SequenceSceneViewModel,
): SequenceSceneStepViewModel[] {
  const otherSteps = scene.steps.slice(1);

  const steps: SequenceSceneStepViewModel[] = [];

  let stepNumber = 1;

  for (let i = 0; i < otherSteps.length; i += 2) {
    const waitStep = otherSteps[i];
    const sceneStep = otherSteps[i + 1];

    steps.push({
      stepNumber,
      duration: waitStep.duration!,
      applySceneName: sceneStep.applySceneName!,
    });

    stepNumber += 1;
  }

  return steps;
}

function fromHMSToPreviewText(h: number, m: number, s: number) {
  const hNeedsComma = m > 0 || s > 0;
  const hDisplay =
    h > 0 ? h + (h == 1 ? ' hour' : ' hours') + (hNeedsComma ? ', ' : '') : '';
  const mNeedsComma = s > 0;
  const mDisplay =
    m > 0 ? m + (m == 1 ? ' minute' : ' minutes') + (mNeedsComma ? ', ' : '') : '';
  const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
  return hDisplay + mDisplay + sDisplay;
}

export function fromSecondsToPreviewText(totalSeconds: number | undefined) {
  if (totalSeconds == undefined) {
    return 'N/A';
  }

  const h = Math.floor(totalSeconds / 3600);
  const m = Math.floor((totalSeconds % 3600) / 60);
  const s = Math.floor((totalSeconds % 3600) % 60);

  return fromHMSToPreviewText(h, m, s);
}

export function fromComponentsToPreviewText(
  hours: number | undefined,
  minutes: number | undefined,
  seconds: number | undefined,
) {
  const h = hours ?? 0;
  const m = minutes ?? 0;
  const s = seconds ?? 0;

  if (!h && !m && !s) {
    return 'Not Set';
  }

  return fromHMSToPreviewText(h, m, s);
}

export function toComponentsFromSeconds(totalSeconds: number | undefined) {
  const h = Math.floor((totalSeconds ?? 0) / 3600);
  const m = Math.floor(((totalSeconds ?? 0) % 3600) / 60);
  const s = Math.floor(((totalSeconds ?? 0) % 3600) % 60);

  return {
    waitHours: h,
    waitMinutes: m,
    waitSeconds: s,
  };
}

export function getOrdinalString(n: number) {
  let ord = 'th';

  if (n % 10 == 1 && n % 100 != 11) {
    ord = 'st';
  } else if (n % 10 == 2 && n % 100 != 12) {
    ord = 'nd';
  } else if (n % 10 == 3 && n % 100 != 13) {
    ord = 'rd';
  }

  return `${n}${ord}`;
}

export function fromInputsToSeconds(hours: number, minutes: number, seconds: number) {
  return seconds + 60 * minutes + 3600 * hours;
}
