import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SocketActions } from '@spog-ui/socket/actions';
import { TriggersApiActions } from '@spog-ui/triggers/actions';
import {
  applyTriggerStateChange,
  toTriggerInternalModelFromGQL,
  TriggerInternalModel,
} from '@spog-ui/shared/models/triggers';
import {
  EquipmentApiActions,
  EquipmentProfileApiActions,
} from '@spog-ui/equipment/actions';
import { EquipmentMapLayerApiActions } from '@spog-ui/equipment-map-layer/actions';

export type Shape = EntityState<TriggerInternalModel>;

export const adapter = createEntityAdapter<TriggerInternalModel>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const initialState: Shape = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(
    TriggersApiActions.loadTriggersPageModelsSuccessAction,
    TriggersApiActions.loadEditTriggersPageSuccessAction,
    EquipmentMapLayerApiActions.loadDeferredModelsSuccessAction,
    EquipmentApiActions.loadDeferredModelsSuccessAction,
    EquipmentProfileApiActions.loadSuccessAction,
    (state, action) => adapter.setAll(action.models.triggers, state),
  ),
  on(SocketActions.triggerStateChangeAction, (state, action) => {
    const trigger = selectEntities(state)[action.triggerStateChange.triggerId];

    if (!trigger) return state;

    const updatedTrigger = applyTriggerStateChange(action.triggerStateChange, trigger);
    const update = { id: trigger.id, changes: updatedTrigger };

    return adapter.updateOne(update, state);
  }),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
