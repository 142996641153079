import { SharedClock } from '@spog-ui/shared/effects/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  time: string;
}

export const initialState: Shape = {
  time: '2019-04-25T19:46:20+0000',
};

export const reducer = createReducer(
  initialState,
  on(SharedClock.tickAction, (state, action) => {
    return { time: action.time };
  }),
);

export const selectTimeString = (state: Shape) => state.time;
