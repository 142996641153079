import { ScheduleApiActions } from '@spog-ui/schedule/actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  ScheduledEventInternalModel,
  toScheduledEventInternalModelFromView,
  toScheduledEventViewFromOccurrenceView,
} from '@spog-ui/shared/models/scheduled-events';

export type Shape = EntityState<ScheduledEventInternalModel>;

export const adapter = createEntityAdapter({
  sortComparer: sortByName,
});

export const initialState: Shape = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    ScheduleApiActions.loadSuccessAction,
    (state, action) => {
      return adapter.setAll(action.models.scheduledEvents, state);
    },
  ),
  on(ScheduleApiActions.deleteScheduledEventSuccessAction, (state, action) => {
    return adapter.removeOne(action.scheduledEventId, state);
  }),
  on(ScheduleApiActions.deleteScheduledEventOccurrenceSuccessAction, (state, action) => {
    const id = action.occurrence.id;
    const scheduledEvent = toScheduledEventInternalModelFromView(
      toScheduledEventViewFromOccurrenceView(action.occurrence),
    );
    const updates = {
      rrule: scheduledEvent.rrule,
    };
    return adapter.updateOne(
      {
        id: id,
        changes: updates,
      },
      state,
    );
  }),
  on(ScheduleApiActions.addScheduledEventSuccessAction, (state, action) => {
    return adapter.addOne(action.scheduledEvent, state);
  }),
);

export function sortByName(
  a: ScheduledEventInternalModel,
  b: ScheduledEventInternalModel,
) {
  return a.name.localeCompare(b.name);
}

export const { selectIds, selectEntities, selectAll } = adapter.getSelectors();
