import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import {
  MIN_TEMPERATURE,
  MAX_TEMPERATURE,
  SVG_HEIGHT,
  SVG_WIDTH,
  RADIUS,
  mapTemperatureToDegrees,
} from '../constants';

@Component({
  selector: 'svg:g[spog-thermodial-arc]',
  template: `
    <svg:circle
      [attr.cx]="centerX"
      [attr.cy]="centerY"
      [attr.r]="radius"
      [attr.stroke-width]="strokeWidth"
    ></svg:circle>

    <svg:defs>
      <svg:clipPath id="arcForegroundMask">
        <svg:path [attr.d]="d" />
      </svg:clipPath>
    </svg:defs>
  `,
  styles: [
    `
      circle {
        stroke: url(#temperatureGradient);
        stroke-opacity: 0.2;
        fill: none;
        clip-path: url(#arcForegroundMask);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThermodialArcComponent implements OnChanges {
  @Input() heatSetpoint: number = MIN_TEMPERATURE;
  @Input() coolSetpoint: number = MAX_TEMPERATURE;

  readonly strokeWidth = 18;
  readonly radius: number = RADIUS + this.strokeWidth / 2;

  centerX: number;
  centerY: number;
  d = '';

  ngOnChanges() {
    /**
     * For simplicity, the rest of the thermodial measures
     * rotations starting clockwise from the southern
     * Y-axis. The point-on-a-circle math used to calculate
     * the positions of the arc requires this to be flipped
     * such that we are measuring from the southern Y-axis
     * but going counter-clockwise. Subtracting from
     * 360 inverts the direction.
     */
    const heatRotation = 360 - mapTemperatureToDegrees(this.heatSetpoint)!;
    const coolRotation = 360 - mapTemperatureToDegrees(this.coolSetpoint)!;

    this.centerX = SVG_WIDTH / 2;
    this.centerY = SVG_HEIGHT / 2;

    const radiusOfMask = this.radius + this.strokeWidth / 2;

    const heatX = calcXOnCircle(heatRotation, radiusOfMask) + this.centerX;
    const heatY = calcYOnCircle(heatRotation, radiusOfMask) + this.centerY;
    const coolX = calcXOnCircle(coolRotation, radiusOfMask) + this.centerX;
    const coolY = calcYOnCircle(coolRotation, radiusOfMask) + this.centerY;

    /**
     * The Arc command will not draw an arc wider than 180deg
     * unless the largeArcFlag is set to 1
     */
    const largeArcFlag = Math.abs(heatRotation - coolRotation) > 180 ? 1 : 0;

    const moveToCenter = `M ${this.centerX} ${this.centerY}`;
    const lineToHeatPoint = `L ${heatX} ${heatY}`;
    const arcToCoolPoint = `A ${radiusOfMask} ${radiusOfMask} 0 ${largeArcFlag} 1 ${coolX} ${coolY}`;
    const closePath = `Z`;

    this.d = `${moveToCenter} ${lineToHeatPoint} ${arcToCoolPoint} ${closePath}`;
  }
}

function calcXOnCircle(angle: number, radius: number) {
  return radius * Math.sin(degreesToRadians(angle));
}

function calcYOnCircle(angle: number, radius: number) {
  return radius * Math.cos(degreesToRadians(angle));
}

function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}
