import { createAction } from '@ngrx/store';
import { SequenceSceneInternalModel } from '@spog-ui/shared/models/sequence-scenes';

export const enterAction = createAction(
  '[Edit Sequence Scene Page] Enter | @spog-sui/scenes',
  (sequenceSceneId: string) => ({
    sequenceSceneId,
  }),
);
export const editSequenceSceneAction = createAction(
  '[Edit Sequence Scene Page] Edit Scene | @spog-sui/scenes',
  (sequenceScene: SequenceSceneInternalModel) => ({ sequenceScene }),
);

export type Union =
  | ReturnType<typeof enterAction>
  | ReturnType<typeof editSequenceSceneAction>;

export const Types = {
  Enter: enterAction.type,
  EditScene: editSequenceSceneAction.type,
};
