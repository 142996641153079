import { Dictionary } from '@ngrx/entity';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { BehaviorType } from '@spog-ui/shared/models/behaviors';
import { SceneViewModel, ZoneBehaviorInternalModel } from '@spog-ui/shared/models/scenes';
import { ZoneBehaviorFormModel, ZoneInternalModel } from '@spog-ui/shared/models/zones';
import * as CoreState from '@spog-ui/shared/state/core';
import { groupBy, values } from 'lodash';
import * as AddScenePageState from './add-scene-page';
import * as EditScenePageState from './edit-scene-page';
import * as AddSequenceScenePageState from './add-sequence-scene-page';
import * as EditSequenceScenePageState from './edit-sequence-scene-page';
import * as ScenesPageState from './scenes-page';
import * as ScenesSearchState from './scenes-search';
import { SequenceSceneViewModel } from '@spog-ui/shared/models/sequence-scenes';

export const STATE_KEY = 'scenes';

export interface Shape {
  addScenePage: AddScenePageState.Shape;
  editScenePage: EditScenePageState.Shape;
  addSequenceScenePage: AddSequenceScenePageState.Shape;
  editSequenceScenePage: EditSequenceScenePageState.Shape;
  scenesPage: ScenesPageState.Shape;
  scenesSearch: ScenesSearchState.Shape;
}

export const reducers: ActionReducerMap<Shape> = {
  addScenePage: AddScenePageState.reducer,
  editScenePage: EditScenePageState.reducer,
  addSequenceScenePage: AddSequenceScenePageState.reducer,
  editSequenceScenePage: EditSequenceScenePageState.reducer,
  scenesPage: ScenesPageState.reducer,
  scenesSearch: ScenesSearchState.reducer,
};

export const selectFeatureState = createFeatureSelector<Shape>(STATE_KEY);

/**
 * Add Scene Page State
 */
export const selectAddScenePageState = createSelector(
  selectFeatureState,
  state => state.addScenePage,
);

export const selectAddScenePageApplication = createSelector(
  selectAddScenePageState,
  AddScenePageState.selectApplication,
);

export const selectAddingScene = createSelector(
  selectAddScenePageState,
  AddScenePageState.selectAdding,
);

export const selectAddingSceneError = createSelector(
  selectAddScenePageState,
  AddScenePageState.selectError,
);

export const selectAddPageIsLoading = createSelector(
  selectAddScenePageState,
  AddScenePageState.selectIsLoading,
);

export const selectAddScenesPageIsReady = createSelector(
  selectAddPageIsLoading,
  isLoading => !isLoading,
);

/**
 * Edit Scene Page State
 */
export const selectEditScenePageState = createSelector(
  selectFeatureState,
  state => state.editScenePage,
);
export const selectEditScenePageApplication = createSelector(
  selectEditScenePageState,
  EditScenePageState.selectApplication,
);
export const selectEditingScene = createSelector(
  selectEditScenePageState,
  EditScenePageState.selectEditing,
);

export const selectEditingSceneError = createSelector(
  selectEditScenePageState,
  EditScenePageState.selectError,
);

export const selectEditPageIsLoading = createSelector(
  selectEditScenePageState,
  EditScenePageState.selectIsLoading,
);

export const selectEditScenesPageIsReady = createSelector(
  selectEditPageIsLoading,
  isLoading => !isLoading,
);

export const selectEditPageSceneId = createSelector(
  selectEditScenePageState,
  EditScenePageState.selectSceneId,
);

export const selectScenesWithoutSceneToEdit = createSelector(
  selectEditPageSceneId,
  CoreState.selectAllScenes,
  (id, scenes) => scenes.filter(scene => scene.id !== id),
);

export const selectSceneToEdit = createSelector(
  selectEditPageSceneId,
  CoreState.selectSceneEntities,
  CoreState.selectZoneEntities,
  (sceneId, sceneEntities, zoneEntities) => {
    const scene = sceneEntities[sceneId ?? ''];

    if (!scene) throw new Error(`Scene with id ${sceneId} not found`);

    return {
      id: scene.id,
      name: scene.name,
      zoneBehaviors: groupSceneZoneBehaviors(scene.zoneBehaviors, zoneEntities),
    };
  },
);

function groupSceneZoneBehaviors(
  sceneZoneBehaviors: ZoneBehaviorInternalModel[],
  zoneEntities: Dictionary<ZoneInternalModel>,
): ZoneBehaviorFormModel[] {
  const byId = values(
    groupBy(sceneZoneBehaviors, (szb: ZoneBehaviorInternalModel) => szb.behaviorId),
  );
  const byParameters = byId.flatMap((sceneZoneBehaviors: ZoneBehaviorInternalModel[]) =>
    values(
      groupBy(
        sceneZoneBehaviors,
        (szb: ZoneBehaviorInternalModel) => szb.behaviorParameters,
      ),
    ),
  );
  return byParameters.map((group: ZoneBehaviorInternalModel[]) =>
    group.reduce<{
      zones: ZoneInternalModel[];
      behaviorId: BehaviorType;
      behaviorParameters: string | null;
    }>(
      (group, next: ZoneBehaviorInternalModel) => {
        const zone = zoneEntities[next.zoneId];
        if (!zone) return group;

        group.zones.push(zone);
        group.behaviorId = next.behaviorId;
        group.behaviorParameters = next.behaviorParameters;
        return group;
      },
      {
        zones: [] as ZoneInternalModel[],
        behaviorId: BehaviorType.None,
        behaviorParameters: null as null | string,
      },
    ),
  );
}

/**
 * Add Sequence Scene Page State
 */
export const selectAddSequenceScenePageState = createSelector(
  selectFeatureState,
  state => state.addSequenceScenePage,
);

export const selectAddingSequenceScene = createSelector(
  selectAddSequenceScenePageState,
  AddSequenceScenePageState.selectAdding,
);

export const selectAddingSequenceSceneError = createSelector(
  selectAddSequenceScenePageState,
  AddSequenceScenePageState.selectError,
);

export const selectAddSequenceScenePageIsLoading = createSelector(
  selectAddSequenceScenePageState,
  AddSequenceScenePageState.selectIsLoading,
);

export const selectAddSequenceScenesPageIsReady = createSelector(
  selectAddSequenceScenePageIsLoading,
  isLoading => !isLoading,
);

/**
 * Edit Scene Page State
 */
export const selectEditSequenceScenePageState = createSelector(
  selectFeatureState,
  state => state.editSequenceScenePage,
);

export const selectEditingSequenceScene = createSelector(
  selectEditSequenceScenePageState,
  EditSequenceScenePageState.selectEditing,
);

export const selectEditingSequenceSceneError = createSelector(
  selectEditSequenceScenePageState,
  EditSequenceScenePageState.selectError,
);

export const selectEditSequenceScenePageIsLoading = createSelector(
  selectEditSequenceScenePageState,
  EditSequenceScenePageState.selectIsLoading,
);

export const selectEditSequenceScenesPageIsReady = createSelector(
  selectEditSequenceScenePageIsLoading,
  isLoading => !isLoading,
);

export const selectEditSequenceScenePageSequenceSceneId = createSelector(
  selectEditSequenceScenePageState,
  EditSequenceScenePageState.selectSequenceSceneId,
);

export const selectSequenceScenesWithoutSequenceSceneToEdit = createSelector(
  selectEditSequenceScenePageSequenceSceneId,
  CoreState.selectAllSequenceScenes,
  (id, sequenceScenes) => sequenceScenes.filter(sequenceScene => sequenceScene.id !== id),
);

export const selectSequenceSceneToEdit = createSelector(
  selectEditSequenceScenePageSequenceSceneId,
  CoreState.selectSequenceSceneEntities,
  (sequenceSceneId, sequenceSceneEntities) => {
    const sequenceScene = sequenceSceneEntities[sequenceSceneId ?? ''];

    if (!sequenceScene)
      throw new Error(`Sequence Scene with id ${sequenceSceneId} not found`);

    return sequenceScene;
  },
);

/**
 * Scenes Page State
 */
export const selectScenesPageState = createSelector(
  selectFeatureState,
  state => state.scenesPage,
);
export const selectScenesPageApplication = createSelector(
  selectScenesPageState,
  ScenesPageState.selectApplication,
);
export const selectScenesPageIsLoading = createSelector(
  selectScenesPageState,
  ScenesPageState.selectIsLoadingModels,
);
export const selectScenesPageError = createSelector(
  selectScenesPageState,
  ScenesPageState.selectError,
);

/**
 * Scenes Search Status State
 */
export const selectScenesSearchState = createSelector(
  selectFeatureState,
  state => state.scenesSearch,
);
export const selectScenesSearchTerm = createSelector(
  selectScenesSearchState,
  ScenesSearchState.selectTerm,
);

/**
 * Custom Scenes Page Selectors
 */
export const selectScenesPageIsReady = createSelector(
  selectScenesPageIsLoading,
  isLoading => !isLoading,
);

export const selectSceneViewsForApplication = createSelector(
  CoreState.selectSceneViews,
  selectScenesPageApplication,
  (sceneViews, application) =>
    sceneViews.filter(sceneView => sceneView.application === application),
);

export const selectSceneViewsFilteredBySearchTerm = createSelector(
  selectSceneViewsForApplication,
  selectScenesSearchTerm,
  (scenes: SceneViewModel[], searchTerm: string) =>
    scenes.filter(scene =>
      scene.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()),
    ),
);

export const selectSequenceSceneViewsFilteredBySearchTerm = createSelector(
  CoreState.selectSequenceSceneViews,
  selectScenesSearchTerm,
  (sequenceScenes: SequenceSceneViewModel[], searchTerm: string) =>
    sequenceScenes.filter(sequenceScene =>
      sequenceScene.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()),
    ),
);

export const selectNoSequenceScenesFound = createSelector(
  selectSequenceSceneViewsFilteredBySearchTerm,
  sequenceSceneViews => sequenceSceneViews.length === 0,
);

export const selectNoScenesFound = createSelector(
  selectSceneViewsFilteredBySearchTerm,
  sceneViews => sceneViews.length === 0,
);

export const selectNoScenesExist = createSelector(
  CoreState.selectSceneViews,
  sceneViews => sceneViews.length === 0,
);

export const selectNoSequenceScenesExist = createSelector(
  CoreState.selectSequenceSceneViews,
  sequenceSceneViews => sequenceSceneViews.length === 0,
);

export const selectNoScenesExistForApplication = createSelector(
  selectSceneViewsForApplication,
  sceneViews => sceneViews.length === 0,
);

export const selectAddScenePageBehaviorsForApplication = createSelector(
  CoreState.selectAllNonDLHBehaviors,
  selectAddScenePageApplication,
  (behaviors, application) =>
    behaviors.filter(behavior => behavior.application === application),
);

export const selectAddScenePageZonesForApplication = createSelector(
  CoreState.selectAllNonDLHZones,
  selectAddScenePageApplication,
  (zones, application) => {
    const filteredZones = zones.filter(zone => zone.application === application);
    const defaultZones = filteredZones.filter(zone => zone.name.includes('Default Zone'));

    return defaultZones.length > 0
      ? [
          ...defaultZones,
          ...filteredZones.filter(zone => !zone.name.includes('Default Zone')),
        ]
      : filteredZones;
  },
);

export const selectEditScenePageBehaviorsForApplication = createSelector(
  CoreState.selectAllNonDLHBehaviors,
  selectEditScenePageApplication,
  (behaviors, application) =>
    behaviors.filter(behavior => behavior.application === application),
);

export const selectEditScenePageZonesForApplication = createSelector(
  CoreState.selectAllNonDLHZones,
  selectEditScenePageApplication,
  (zones, application) => {
    const filteredZones = zones.filter(zone => zone.application === application);
    const defaultZones = filteredZones.filter(zone => zone.name.includes('Default Zone'));

    return defaultZones.length > 0
      ? [
          ...defaultZones,
          ...filteredZones.filter(zone => !zone.name.includes('Default Zone')),
        ]
      : filteredZones;
  },
);
