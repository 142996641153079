import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { ButtonComponent } from '../button';
import { AlarmCardComponent } from './alarm-card.component';

@NgModule({
    imports: [CommonModule, MatButtonModule, MatCardModule, ButtonComponent, AlarmCardComponent],
    exports: [AlarmCardComponent],
})
export class AlarmCardModule {}
