import { IndieSensorsApi } from '@spog-ui/indie-sensors/actions';
import { AddIndieSensorPage } from '@spog-ui/indie-sensors/actions';
import { createReducer, on } from '@ngrx/store';
import { CreatableHardwareType } from '@spog-ui/shared/models/indie-sensors';

export interface Shape {
  error?: any;
  saving: boolean;
  loading: boolean;
  hardwareType: CreatableHardwareType | null;
}

export const initialState: Shape = {
  saving: false,
  loading: true,
  hardwareType: null,
};

export const reducer = createReducer(
  initialState,
  on(AddIndieSensorPage.enterAction, () => {
    return initialState;
  }),
  on(AddIndieSensorPage.selectHardwareTypeAction, (state, action) => {
    return {
      ...state,
      hardwareType: action.hardwareType,
    };
  }),
  on(AddIndieSensorPage.unselectHardwareTypeAction, state => {
    return {
      ...state,
      hardwareType: null,
    };
  }),
  on(AddIndieSensorPage.addSense420IndieSensorAction, state => {
    return {
      ...state,
      error: null,
      saving: true,
      loading: false,
    };
  }),
  on(
    IndieSensorsApi.addSense420IndieSensorSuccessAction,
    IndieSensorsApi.loadIndieSensorsAndControllersSuccessAction,
    state => {
      return {
        ...state,
        error: null,
        saving: false,
        loading: false,
      };
    },
  ),
  on(
    IndieSensorsApi.addSense420IndieSensorFailureAction,
    IndieSensorsApi.loadIndieSensorsAndControllersFailureAction,
    (state, action) => {
      return {
        ...state,
        error: action.error,
        saving: false,
        loading: false,
      };
    },
  ),
);

export const selectSaving = (state: Shape) => state.saving;
export const selectError = (state: Shape) => state.error;
export const selectIsLoading = (state: Shape) => state.loading;
export const selectHardwareType = (state: Shape) => state.hardwareType;
