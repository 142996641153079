import { createAction } from '@ngrx/store';
import { ThermostatInternalModel } from '@spog-ui/shared/models/thermostats';

export const loadClimateZoneModalModelsSuccessAction = createAction(
  '[Climate Zone Modal API] Load Climate Zone Modal Models Success',
  (thermostats: ThermostatInternalModel[]) => ({ thermostats }),
);

export const loadClimateZoneModalModelsFailureActions = createAction(
  '[Climate Zone Modal API] Load Climate Zone Modal Models Failure',
  (error: any) => ({ error }),
);
