import { createAction } from '@ngrx/store';
import { SiteControllerInternalModel } from '@spog-ui/shared/models/site-controllers';
import { ThermostatInternalModel } from '@spog-ui/shared/models/thermostats';

export const loadAddThermostatPageModelsSuccessAction = createAction(
  '[Add Thermostat API] Load Add Thermostat Page Models Success',
  (
    thermostats: ThermostatInternalModel[],
    siteControllers: SiteControllerInternalModel[],
  ) => ({
    thermostats: thermostats,
    siteControllers: siteControllers,
  }),
);
export const loadAddThermostatPageModelsFailureAction = createAction(
  '[Add Thermostat API] Load Add Thermostat Page Models Failure',
  (error: any) => ({ error }),
);
