import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  BypassServiceWorkerInterceptor,
  CorrelationIdInterceptor,
  CrossSiteInterceptorService,
  GraphqlErrorInterceptor,
  HttpErrorInterceptor,
} from './lib';

@NgModule({})
export class NetworkModule {
  static forRoot() {
    return {
      ngModule: NetworkModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: GraphqlErrorInterceptor,
        },
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: HttpErrorInterceptor,
        },
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: CorrelationIdInterceptor,
        },
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: BypassServiceWorkerInterceptor,
        },
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: CrossSiteInterceptorService,
        },
      ],
    };
  }
}
