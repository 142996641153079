import { EditTriggerPageActions, TriggersApiActions } from '@spog-ui/triggers/actions';
import { createReducer, on } from '@ngrx/store';

export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  loadingError?: any;
  submissionError?: any;
  triggerId: string | null;

  sensorId: string | null;
}

export const initialState: Shape = {
  isLoading: true,
  isSubmitting: false,
  triggerId: null,
  sensorId: null,
};

export const reducer = createReducer(
  initialState,
  on(EditTriggerPageActions.enterAction, (state, action) => {
    return {
      ...initialState,
      triggerId: action.triggerId,
    };
  }),
  on(TriggersApiActions.loadEditTriggersPageSuccessAction, (state): Shape => {
    return { ...state, isLoading: false, isSubmitting: false };
  }),
  on(TriggersApiActions.loadEditTriggersPageFailureAction, (state, action): Shape => {
    return {
      ...state,
      isLoading: false,
      isSubmitting: false,
      loadingError: action.error,
    };
  }),
  on(EditTriggerPageActions.selectSourceAction, (state, action) => {
    return {
      ...state,
      sensorId: action.sensorId,
    };
  }),
  on(EditTriggerPageActions.editTriggerAction, state => {
    return {
      ...state,
      isLoading: false,
      isSubmitting: true,
    };
  }),
  on(TriggersApiActions.editTriggerFailureAction, (state, action): Shape => {
    return {
      ...state,
      isLoading: false,
      isSubmitting: false,
      submissionError: action.error,
    };
  }),
);

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
export const selectLoadingError = (state: Shape) => state.loadingError;
export const selectSubmissionError = (state: Shape) => state.submissionError;
export const selectTriggerId = (state: Shape) => state.triggerId;
export const selectSensorId = (state: Shape) => state.sensorId;
