import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  MpStepOneActions,
  MpStepThreeActions,
  MpStepTwoFreeformActions,
  MpStepTwoGridActions,
} from '@spog-ui/map-positioner/actions';
import { AreaModel, GridPositionModel } from '@spog-ui/shared/models/map-positioners';

export interface Shape extends EntityState<GridPositionModel> {
  rows: number;
  cols: number;
  isAreaSelectToolEnabled: boolean;
  isFreeformSelectToolEnabled: boolean;
  area: AreaModel | null;
}

const adapter = createEntityAdapter({
  selectId: (model: GridPositionModel) => model.id,
});

export const initialState: Shape = adapter.getInitialState({
  rows: 5,
  cols: 5,
  isAreaSelectToolEnabled: false,
  isFreeformSelectToolEnabled: false,
  area: null,
});

export const { selectAll } = adapter.getSelectors();

export const reducer = createReducer(
  initialState,
  on(
    MpStepOneActions.enterAction,
    MpStepThreeActions.savePositionsAction,
    (state): Shape => {
      return {
        ...initialState,
        rows: state.rows,
        cols: state.cols,
      };
    },
  ),
  on(MpStepTwoFreeformActions.enterAction, (state): Shape => {
    return {
      ...initialState,
      rows: state.rows,
      cols: state.cols,
      isFreeformSelectToolEnabled: true,
    };
  }),
  on(MpStepThreeActions.enterAction, (state): Shape => {
    return {
      ...state,
      isFreeformSelectToolEnabled: false,
    };
  }),
  on(MpStepTwoGridActions.enterAction, (state): Shape => {
    return {
      ...state,
      isAreaSelectToolEnabled: true,
    };
  }),
  on(MpStepTwoGridActions.selectAreaAction, (state, action): Shape => {
    return computeGrid({ ...state, area: action.area });
  }),
  on(MpStepTwoGridActions.moveAreaAction, (state, action): Shape => {
    const area = state.area;
    const offset = action.offset;

    return computeGrid({
      ...state,
      area: !area ? null : { ...area, x: area.x + offset.x, y: area.y + offset.y },
    });
  }),
  on(MpStepTwoGridActions.pickPanAndZoomToolAction, (state): Shape => {
    return {
      ...state,
      isAreaSelectToolEnabled: false,
    };
  }),
  on(MpStepTwoGridActions.pickAreaSelectToolAction, (state): Shape => {
    return {
      ...state,
      isAreaSelectToolEnabled: true,
    };
  }),
  on(MpStepTwoGridActions.pickRows, (state, action): Shape => {
    return computeGrid({
      ...state,
      rows: action.rows,
    });
  }),
  on(MpStepTwoGridActions.pickCols, (state, action): Shape => {
    return computeGrid({
      ...state,
      cols: action.cols,
    });
  }),
  on(MpStepThreeActions.assignThingToPositionAction, (state, action): Shape => {
    return adapter.updateOne(
      { id: action.positionId, changes: { thingId: action.thingId } },
      state,
    );
  }),
  on(MpStepTwoGridActions.leaveAction, (state): Shape => {
    return {
      ...state,
      isAreaSelectToolEnabled: false,
    };
  }),
);

/**
 * Grid Helpers
 */
function computeGrid(grid: Shape) {
  if (grid.area === null) {
    return grid;
  }

  const columnTick = grid.area.width / grid.cols;
  const halfColumnTick = columnTick / 2;
  const rowTick = grid.area.height / grid.rows;
  const halfRowTick = rowTick / 2;

  let id = 0;
  const gridPositions: GridPositionModel[] = [];

  for (let column = 1; column <= grid.cols; column++) {
    for (let row = 1; row <= grid.rows; row++) {
      gridPositions.push({
        id: `${id}`,
        floorPlanX: Math.round(grid.area.x + column * columnTick - halfColumnTick),
        floorPlanY: Math.round(grid.area.y + row * rowTick - halfRowTick),
        thingId: null,
        row,
        column,
      });

      ++id;
    }
  }

  return adapter.setAll(gridPositions, grid);
}

/**
 * Selectors
 */
export const selectRows = (state: Shape) => state.rows;
export const selectCols = (state: Shape) => state.cols;
export const selectIsAreaToolEnabled = (state: Shape) => state.isAreaSelectToolEnabled;
export const selectIsFreeformToolEnabled = (state: Shape) =>
  state.isFreeformSelectToolEnabled;
export const selectArea = (state: Shape) => state.area;
