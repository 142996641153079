import { CreateDepartmentInput, UpdateDepartmentInput } from '@spog-ui/graphql/types';
import { DepartmentInternalModel } from '@spog-ui/shared/models/resource-groups';

export interface DepartmentFormModel {
  id?: string;
  name: string;
  resourceGroupIds: string[];
  zoneIds: string[];
  energyProfileEquipmentIds: string[];
}

export function toDepartmentFormModel(
  department: DepartmentInternalModel,
): DepartmentFormModel {
  return {
    id: department.id,
    name: department.name,
    zoneIds: department.zoneIds,
    resourceGroupIds: department.resourceGroupIds,
    energyProfileEquipmentIds: department.energyProfileEquipmentIds,
  };
}

export interface DepartmentViewModel {
  id: string;
  name: string;
}

/**
 * Converts a Department form model into one ready to be used
 * by the GraphQL create operation
 */
export function toCreateDepartmentInputFromForm(
  form: DepartmentFormModel,
  siteId: string,
): CreateDepartmentInput {
  return {
    siteId,
    name: form.name,
    resourceGroupIds: form.resourceGroupIds,
    controlZoneIds: form.zoneIds,
    energyProfileEquipmentIds: form.energyProfileEquipmentIds,
  };
}

/**
 * Converts a Department form model into one ready to be used
 * by the GraphQL update operation
 */
export function toUpdateDepartmentInputFromForm(
  form: DepartmentFormModel,
  id: string,
): UpdateDepartmentInput {
  return {
    id,
    name: form.name,
    resourceGroupIds: form.resourceGroupIds,
    controlZoneIds: form.zoneIds,
    energyProfileEquipmentIds: form.energyProfileEquipmentIds,
  };
}

/**
 * Department from our WebSocket API
 */
export interface DepartmentWSModel {
  id: string;
  name: string;
  siteId: string;
  resourceGroupIds: string[];
  zoneIds: string[];
  energyProfileEquipmentIds: string[];
}

/**
 * Normalizes a Department sent to us from our WebSocket API
 * into a model ready to be put in the store
 */
export function toDepartmentInternalModelFromWS(
  ws: DepartmentWSModel,
): DepartmentInternalModel {
  return {
    id: ws.id,
    name: ws.name,
    resourceGroupIds: ws.resourceGroupIds,
    zoneIds: ws.zoneIds,
    energyProfileEquipmentIds: ws.energyProfileEquipmentIds,
  };
}
