import {
  signalStore,
  withMethods,
  withState,
  patchState,
  withComputed,
} from '@ngrx/signals';
import {
  withEntities,
  removeEntity,
  addEntity,
  updateEntity,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { computed, inject } from '@angular/core';
import {
  GetSourceDataQueryResult,
  GetSourceDataQueryVariables,
  GraphQLAPIService,
  Interval,
} from '@spog-ui/graphql/types';
import { SiteService } from '@spog-ui/shared/services';
import { SourceInternalModel } from '../models/source.model';
import { forkJoin, pipe, switchMap, tap } from 'rxjs';
import { tapResponse } from '@ngrx/component-store';
import {
  SelectedSourceInternalModel,
  deriveAxes,
  deriveSeries,
  deriveTitle,
  toSelectedSourceInternalModelFromSourceInternal,
} from '../models/selected-source.model';
import { DateTime } from 'luxon';
import { getInitialDateRange } from '../models/date-range.model';
import { retrieveErrorMessage } from '@spog-ui/shared/models/errors';

type SelectedSourcesState = {
  loadingSourceData: boolean;
  useLongTitle: boolean;
  error: string | null;
  interval: Interval;
  dateRange: [DateTime, DateTime];
};

const initialState: SelectedSourcesState = {
  loadingSourceData: true,
  useLongTitle: false,
  error: null,
  interval: Interval.FIFTEEN_MINUTES,
  dateRange: getInitialDateRange(),
};

// TODO: the query calls are repetitive.  Can they be DRY'd?

export const SelectedSourcesStore = signalStore(
  withState(initialState),
  withEntities<SelectedSourceInternalModel>(),
  withComputed(
    ({ entities, useLongTitle, error, loadingSourceData, interval, dateRange }) => {
      const yAxes = computed(() => deriveAxes(entities()));

      const series = computed((): any[] => deriveSeries(entities()));

      const title = computed(() => deriveTitle(dateRange(), interval(), useLongTitle()));

      return {
        yAxes,
        series,
        title,
      };
    },
  ),
  withMethods(
    (store, siteService = inject(SiteService), gql = inject(GraphQLAPIService)) => ({
      setUseLongTitle(useLongTitle: boolean): void {
        patchState(store, { useLongTitle });
      },
      removeSelectedSource(sourceId: string): void {
        patchState(store, removeEntity(sourceId));
      },
      setDateRange: rxMethod<[DateTime, DateTime]>(
        pipe(
          tap(() => patchState(store, { loadingSourceData: true })),
          switchMap((newDateRange: [DateTime, DateTime]) => {
            patchState(store, { dateRange: newDateRange });

            const dateRange = store.dateRange();

            // Build list of data query observables
            const dataQueries: { [sourceId: string]: any } = {};

            store.entities().forEach(selectedSource => {
              const input: GetSourceDataQueryVariables = {
                sourceId: selectedSource.id,
                siteId: siteService.getId() ?? 'not-set',
                start: dateRange[0].toISO(),
                end: dateRange[1].toISO(),
                interval: store.interval(),
                timezone: siteService.getTimezone(),
              };

              dataQueries[selectedSource.id] = gql.getSourceData(input);
            });

            // Send all the queries
            return forkJoin(dataQueries).pipe(
              tapResponse({
                next: (result: { [x: string]: any }) => {
                  Object.entries(result).forEach(([sourceId, queryResponse]) => {
                    const source = store.entityMap()[sourceId];

                    const updatedSource = toSelectedSourceInternalModelFromSourceInternal(
                      source,
                      queryResponse.querySource.buckets,
                    );

                    patchState(
                      store,
                      updateEntity({
                        id: sourceId,
                        changes: { data: updatedSource.data },
                      }),
                    );
                  });
                },
                error: (error: any) => {
                  patchState(store, { error: retrieveErrorMessage(error) });
                },
                finalize: () => patchState(store, { loadingSourceData: false }),
              }),
            );
          }),
        ),
      ),
      setInterval: rxMethod<Interval>(
        pipe(
          tap(() => patchState(store, { loadingSourceData: true })),
          switchMap((newInterval: Interval) => {
            patchState(store, { interval: newInterval });

            const dateRange = store.dateRange();

            // Build list of data query observables
            const dataQueries: { [sourceId: string]: any } = {};

            store.entities().forEach(selectedSource => {
              const input: GetSourceDataQueryVariables = {
                sourceId: selectedSource.id,
                siteId: siteService.getId() ?? 'not-set',
                start: dateRange[0].toISO(),
                end: dateRange[1].toISO(),
                interval: store.interval(),
                timezone: siteService.getTimezone(),
              };

              dataQueries[selectedSource.id] = gql.getSourceData(input);
            });

            // Send all the queries
            return forkJoin(dataQueries).pipe(
              tapResponse({
                next: (result: { [x: string]: any }) => {
                  Object.entries(result).forEach(([sourceId, queryResponse]) => {
                    const source = store.entityMap()[sourceId];

                    const updatedSource = toSelectedSourceInternalModelFromSourceInternal(
                      source,
                      queryResponse.querySource.buckets,
                    );

                    patchState(
                      store,
                      updateEntity({
                        id: sourceId,
                        changes: { data: updatedSource.data },
                      }),
                    );
                  });
                },
                error: (error: any) => {
                  patchState(store, { error: retrieveErrorMessage(error) });
                },
                finalize: () => patchState(store, { loadingSourceData: false }),
              }),
            );
          }),
        ),
      ),
      addSelectedSource: rxMethod<SourceInternalModel>(
        pipe(
          tap(() => patchState(store, { loadingSourceData: true })),
          switchMap(source => {
            const dateRange = store.dateRange();

            const input: GetSourceDataQueryVariables = {
              sourceId: source.id,
              siteId: siteService.getId() ?? 'not-set',
              start: dateRange[0].toISO(),
              end: dateRange[1].toISO(),
              interval: store.interval(),
              timezone: siteService.getTimezone(),
            };

            return gql.getSourceData(input).pipe(
              tapResponse({
                next: (result: GetSourceDataQueryResult) => {
                  patchState(
                    store,
                    addEntity(
                      toSelectedSourceInternalModelFromSourceInternal(
                        source,
                        result.querySource.buckets,
                      ),
                    ),
                  );
                },
                error: (error: any) => {
                  patchState(store, { error: retrieveErrorMessage(error) });
                },
                finalize: () => patchState(store, { loadingSourceData: false }),
              }),
            );
          }),
        ),
      ),
    }),
  ),
);
