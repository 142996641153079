import { createAction } from '@ngrx/store';
import { OrganizationInternalModel } from '@spog-ui/shared/models/organizations';

export const openAction = createAction('[Menu Component] Open');
export const closeAction = createAction('[Menu Component] Close');
export const changeSiteAction = createAction('[Menu Component] Change Site');
export const logoutAction = createAction('[Menu Component] Logout');
export const openOrganizationMenu = createAction(
  '[Menu Component] Open Organization Menu',
  (organizations: OrganizationInternalModel[]) => ({
    organizations,
  }),
);
export const closeOrganizationMenu = createAction(
  '[Menu Component] Close Organization Menu',
);
export const navigateToOrganization = createAction(
  '[Menu Component] Navigate To Organization',
  (organizationId: string, redirectUrl: string) => ({
    organizationId,
    redirectUrl,
  }),
);
