import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PermissionsModule } from '@spog-ui/current-user/permissions';
import { ButtonComponent, SuiEmptyStateModule } from '@spog-ui/shared/components';
import { NoUtilityServicesComponent } from './no-utility-services.component';

@NgModule({
  imports: [
    CommonModule,
    PermissionsModule,
    RouterModule,
    SuiEmptyStateModule,
    ButtonComponent,
  ],
  declarations: [NoUtilityServicesComponent],
  exports: [NoUtilityServicesComponent],
})
export class NoUtilityServicesComponentModule {}
