import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  GetSitesByOrganizationQueryResult,
  GetSitesWithUsersByOrganizationQueryResult,
  GraphQLAPIService,
} from '@spog-ui/graphql/types';
import {
  OrganizationSiteInternalModel,
  toOrganizationSiteInternalModelFromIdentityGQL,
} from '@spog-ui/shared/models/sites';

@Injectable({ providedIn: 'root' })
export class SitesApiService {
  constructor(readonly gql: GraphQLAPIService) {}

  getSitesByOrganization(
    organizationId: string,
  ): Observable<OrganizationSiteInternalModel[]> {
    return this.gql.getSitesByOrganization({ organizationId }).pipe(
      map((response: GetSitesByOrganizationQueryResult) => {
        if (!response.organization) {
          throw new Error('No organization for id');
        }

        return response.organization!.sites.edges.map(edge =>
          toOrganizationSiteInternalModelFromIdentityGQL(edge.node),
        );
      }),
    );
  }

  getSitesWithUsersByOrganization(
    organizationId: string,
  ): Observable<GetSitesWithUsersByOrganizationQueryResult> {
    return this.gql.getSitesWithUsersByOrganization({ organizationId });
  }
}
