import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SuiAnimatePanel } from '@spog-ui/shared/components';
import { LightViewModel } from '@spog-ui/shared/models/lights';

@Component({
  selector: 'lit-light-details-panel',
  template: `
    <sui-panel [title]="'Light Details'" color="primary" (closed)="closed.emit()">
      <div class="lightStatus">
        <mat-icon class="lightbulbIcon">lightbulb_outline</mat-icon>
        <div class="lines">
          <div class="name">{{ light.name }}</div>
          <div class="snapaddr">{{ light.snapaddr | uppercase }}</div>
        </div>
        <lit-light-status-tray [alarms]="light.alarms"></lit-light-status-tray>
        <mat-menu #contextMenu="matMenu">
          <a mat-menu-item (click)="onReplace()"> Replace Light </a>
        </mat-menu>
        <button
          *suiIfUserCan="['ssc:siteAdmin']"
          mat-icon-button
          [matMenuTriggerFor]="contextMenu"
          class="menuButton"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
      <div class="lightControl" *suiIfUserCan="['ssc:siteAdmin']">
        <sui-dimmer
          [level]="light.level"
          [canBlink]="true"
          (dim)="onDim(light, $event)"
        ></sui-dimmer>
      </div>

      <ng-content></ng-content>
    </sui-panel>
  `,
  styles: [
    `
      .lightStatus {
        background-color: var(--color-300);
        color: var(--color-contrast-300);
        height: 100px !important;
        width: 100%;
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        grid-template-columns: 40px auto 70px 32px;
        padding-left: 16px;
        padding-right: 16px;
      }

      .lines {
        display: block;
      }

      .name {
        color: var(--color-contrast-500);
        font-size: 18px !important;
        font-weight: bold;
      }

      .snapaddr {
        color: var(--color-contrast-500);
        font-size: 13px !important;
      }

      .lightbulbIcon {
        color: var(--color-contrast-500);
      }

      .menuButton {
        color: var(--color-contrast-500);
      }

      .lightControl {
        background-color: var(--color-background-card);
        border-radius: 2px;
        top: -10px;
        width: 328px;
        position: relative;
        margin: auto;
      }

      sui-dimmer {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
      }

      :host ::ng-deep sui-dimmer span {
        font-size: 15px;
        margin-right: 0px;
        text-align: left;
      }
    `,
  ],
  animations: [SuiAnimatePanel],
})
export class LightDetailsPanelComponent {
  @HostBinding('@suiAnimatePanel') animatePanel = true;

  @Input() light: LightViewModel;
  @Output() closed = new EventEmitter();
  @Output() dim = new EventEmitter();
  @Output() replace = new EventEmitter<string>();

  constructor() {}

  onDim($event: LightViewModel, level: number): void {
    this.dim.emit({
      light: $event,
      level: level,
    });
  }

  onReplace() {
    this.replace.emit(this.light.id);
  }
}
