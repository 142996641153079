import { createReducer, on } from '@ngrx/store';
import { EditOrganizationPageActions, OrganizationApiActions } from '../actions';

export interface Shape {
  isLoading: boolean;
  isSubmitting: boolean;
  error?: any;
  organizationId: string | null;
}

export const initialState: Shape = {
  isLoading: true,
  isSubmitting: false,
  organizationId: null,
};

export const reducer = createReducer(
  initialState,
  on(EditOrganizationPageActions.enterAction, (_state, action): Shape => {
    return {
      ...initialState,
      organizationId: action.organizationId,
    };
  }),
  on(EditOrganizationPageActions.loadPageSuccessAction, (state): Shape => {
    return { ...state, isLoading: false };
  }),
  on(EditOrganizationPageActions.loadPageFailureAction, (state, action): Shape => {
    return { ...state, isLoading: false, error: action.error };
  }),
  on(EditOrganizationPageActions.editOrganizationAction, (state): Shape => {
    return { ...state, isSubmitting: true };
  }),
  on(OrganizationApiActions.updateOrganizationFailureAction, (state): Shape => {
    return { ...state, isSubmitting: false };
  }),
);

export const STATE_KEY = 'editOrganizationPage';

export const selectIsLoading = (state: Shape) => state.isLoading;
export const selectError = (state: Shape) => state.error;
export const selectIsSubmitting = (state: Shape) => state.isSubmitting;
export const selectActiveId = (state: Shape) => state.organizationId;
